import { Color } from '@cycle-app/graphql-codegen';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { MouseEventHandler, FC, HTMLAttributes, MouseEvent, ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

import { WarningIcon } from '../../icons';
import { Tooltip } from '../Tooltip/Tooltip';
import {
  Action,
  Actions,
  Container,
  GrowingSpace,
  Hoverable,
  Icon,
  Name,
  NavigationShortcut,
  RightArrowIcon,
  TagWithCount,
  Warning,
  PostName,
} from './NavigationItem.styles';

import type { ContainerProps } from './NavigationItem.styles';

const MAX_NOTIF_DISPLAYED = 20;

export interface NavigationItemProps extends HTMLAttributes<HTMLElement> {
  label: ReactNode;
  icon?: ReactNode;
  iconColor?: Color;
  className?: string;
  onClick?: MouseEventHandler;
  linkTo?: LinkProps['to'];
  href?: string;
  isActive?: boolean;
  isChild?: boolean;
  isDragging?: boolean;
  isFocus?: boolean;
  isLoading?: boolean;
  isSidebarCollapsed?: boolean;

  warning?: ReactNode;
  contentPlus?: ReactNode;
  contentMore?: ReactNode;
  tagWithCount?: number;
  shortcut?: string[];
  shortcutAllwaysVisible?: boolean;
  isSidebarColor?: boolean;

  sortableListeners?: DraggableSyntheticListeners;
  isSortable?: boolean;
  asPlaceholder?: boolean;
  setNodeRef?: (node: HTMLElement | null) => void;
  placeholderScale?: [number, number];
  activeAlpha?: number;
  isDisabled?: boolean;
  hasArrowRight?: boolean;
  postName?: ReactNode;
  animate?: ContainerProps['$animate'];
  collapsed?: boolean;
}

export const NavigationItem: FC<React.PropsWithChildren<NavigationItemProps>> = ({
  label,
  icon = null,
  iconColor = null,
  onClick,
  className,
  linkTo,
  href,
  isActive,
  isChild,
  isDragging,
  isFocus,
  isLoading,
  isSidebarCollapsed,
  isSortable,
  warning,
  contentMore,
  contentPlus,
  tagWithCount,
  shortcut,
  shortcutAllwaysVisible = false,
  isSidebarColor: sidebarColor,
  sortableListeners,
  asPlaceholder,
  setNodeRef,
  placeholderScale,
  activeAlpha,
  isDisabled = false,
  hasArrowRight = false,
  postName,
  animate,
  collapsed,
  ...htmlProps
}) => {
  const containerProps = {
    // For god's sake, why there's $ in these props?
    // Because 👇
    // https://styled-components.com/docs/api#transient-props
    $isActive: isActive,
    $isChild: isChild,
    $isDragging: isDragging,
    $isFocus: isFocus,
    $isLoading: isLoading,
    $isSidebarCollapsed: isSidebarCollapsed,
    $shortcutAlwaysVisible: shortcutAllwaysVisible,
    $sidebarColor: sidebarColor,
    $asPlaceholder: asPlaceholder,
    $isSortable: isSortable,
    $placeholderScale: placeholderScale,
    className: `${className || ''} ${isFocus ? 'force-focus' : ''}`,
    $activeAlpha: activeAlpha,
    $isDisabled: isDisabled,
    ref: setNodeRef,
    onClick,
    ...(isSortable ? sortableListeners : {}),
  };

  const content = collapsed ? (
    <Icon
      $isActive={isActive}
      $color={iconColor}
    >
      {icon}
    </Icon>
  ) : (
    <>
      {icon && <Icon $isActive={isActive} $color={iconColor}>{icon}</Icon>}
      <Name>{label}</Name>
      {!!tagWithCount && (
        <TagWithCount $isSidebarCollapsed={isSidebarCollapsed}>
          {tagWithCount > MAX_NOTIF_DISPLAYED
            ? `${MAX_NOTIF_DISPLAYED}+`
            : `${tagWithCount}`}
        </TagWithCount>
      )}

      <PostName>
        {postName}
      </PostName>

      <GrowingSpace />
      {!tagWithCount && shortcut && (
        <NavigationShortcut
          keys={shortcut}
          onActive={isActive}
          colors="oposite"
        />
      )}
      {!!warning && (
        <Hoverable $isSidebarCollapsed={isSidebarCollapsed}>
          <Tooltip placement="top" content={warning}>
            <Warning>
              <WarningIcon />
            </Warning>
          </Tooltip>
        </Hoverable>
      )}
      {(!!contentPlus || !!contentMore) && (
        <Actions onClick={e => e.stopPropagation()}>
          {!!contentPlus && <Action>{contentPlus}</Action>}
          {!!contentMore && <Action>{contentMore}</Action>}
        </Actions>
      )}
      {hasArrowRight && <RightArrowIcon />}
    </>
  );

  if (href) {
    return (
      <Container
        {...containerProps}
        {...htmlProps}
        href={href}
        target="_blank"
        draggable={false}
        onDragStart={(e: MouseEvent) => e.preventDefault()}
        $animate={animate}
        onClick={e => {
          if (!onClick) return;
          e.preventDefault();
          onClick(e);
        }}
      >
        {content}
      </Container>
    );
  }

  if (linkTo) {
    return (
      <Container
        {...containerProps}
        {...htmlProps}
        draggable={false}
        onDragStart={(e: MouseEvent) => e.preventDefault()}
        onClick={(e: MouseEvent) => {
          if (!onClick) return;
          e.preventDefault();
          onClick(e);
        }}
        $animate={animate}
        {...typeof linkTo === 'string' ? {
          as: 'a',
          href: linkTo,
        } : {
          to: linkTo,
        }}
      >
        {content}
      </Container>
    );
  }

  return (
    <Container
      {...containerProps}
      {...htmlProps}
      as="div"
      tabIndex={-1}
      $animate={animate}
    >
      {content}
    </Container>
  );
};

export const NavigationItemContainer = Container;
