import {
  FilterPropertyRuleFragment,
  FilterValueInput,
  Operator,
  PropertyFragment,
  SwimlaneFilterPropertyRuleFragment,
} from '@cycle-app/graphql-codegen';
import { SelectOption, Placement } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import memoize from 'fast-memoize';
import { FC, useMemo } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { Layer } from 'src/types/layers.types';

import { LightButton, AddAnotherFilterBtn } from '../BoardConfigForm/BoardConfigForm.styles';
import Filter from './Filter/Filter';
import {
  Container,
  FiltersList,
} from './FilterRules.styles';

interface Props {
  className?: string;
  dropdownLayer?: Layer;
  dropdownPlacement?: Placement;
  filtersDropdownLayer?: Layer;
  visible: boolean;
  hide: VoidFunction;
  filters: Array<FilterPropertyRuleFragment | SwimlaneFilterPropertyRuleFragment>;
  filterableProperties: Array<PropertyFragment>;
  loadingChangeOperator: boolean;
  onChangeFilter: (filterId: string, attributeId: string) => void;
  onAddValue: (filterId: string, input: FilterValueInput) => void;
  onRemoveValue: (filterId: string, valueId: string) => void;
  onOperatorUpdated: (ruleId: string, operator: Operator) => void;
  onDeleteFilter: (filterId: string) => void;
  onAddFirstFilterAvailable: VoidFunction;
}
const FilterRules: FC<React.PropsWithChildren<Props>> = ({
  className,
  dropdownLayer,
  dropdownPlacement,
  filtersDropdownLayer,
  visible,
  hide,
  filters,
  filterableProperties,
  loadingChangeOperator,
  onChangeFilter,
  onAddValue,
  onRemoveValue,
  onOperatorUpdated,
  onDeleteFilter,
  onAddFirstFilterAvailable,
  children,
}) => {
  const onAttributeUpdated = useMemo(
    () => memoize((filterId: string) => (option: SelectOption) => onChangeFilter(filterId, option.value)),
    [onChangeFilter],
  );
  const onValueAdded = useMemo(
    () => memoize((filterId: string) => (input: FilterValueInput) => onAddValue(filterId, input)),
    [onAddValue],
  );
  const onValueRemoved = useMemo(
    () => memoize((filterId: string) => (valueId: string) => onRemoveValue(filterId, valueId)),
    [onRemoveValue],
  );
  const onOperatorUpdate = useMemo(
    () => memoize((filterId: string) => (operator: Operator) => onOperatorUpdated(filterId, operator)),
    [onOperatorUpdated],
  );
  const onFilterDeleted = useMemo(
    () => memoize((filterId: string) => () => onDeleteFilter(filterId)),
    [onDeleteFilter],
  );

  return (
    <DropdownLayer
      className={className}
      closingArea={false}
      visible={visible}
      layer={dropdownLayer}
      placement={dropdownPlacement}
      hide={hide}
      content={(
        <Container>
          {filters.length > 0 && (
            <FiltersList>
              {filters.map((filter) => (
                <Filter
                  key={filter.id}
                  filter={filter}
                  dropdownLayer={filtersDropdownLayer}
                  filterableProperties={filterableProperties}
                  loadingChangeOperator={loadingChangeOperator}
                  onAttributeUpdated={onAttributeUpdated(filter.id)}
                  onValueAdded={onValueAdded(filter.id)}
                  onValueRemoved={onValueRemoved(filter.id)}
                  onOperatorUpdated={onOperatorUpdate(filter.id)}
                  onDeleteFilter={onFilterDeleted(filter.id)}
                />
              ))}
            </FiltersList>
          )}

          <LightButton
            onClick={onAddFirstFilterAvailable}
            as={AddAnotherFilterBtn}
            iconStart={<AddIcon size={10} />}
          >
            Add filter
          </LightButton>
        </Container>
      )}
    >
      {children}
    </DropdownLayer>
  );
};

export default FilterRules;
