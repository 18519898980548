import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

type Props = SvgProps & {
  fill?: boolean;
};

export const SlackIcon: FC<React.PropsWithChildren<Props>> = ({
  fill, ...props
}) => (
  <Svg width={16} height={16} viewBox="0 0 2447.6 2452.5" {...!fill && { 'data-no-fill': true }} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path fill="#36c5f0" d="M897.4 0a245 245 0 0 0-244.7 245.2 245 245 0 0 0 244.8 245.2h244.8V245.3A245.2 245.2 0 0 0 897.4 0c.1 0 .1 0 0 0m0 654H244.8A245 245 0 0 0 0 899.2a245 245 0 0 0 244.7 245.3h652.7a245 245 0 0 0 244.8-245.2A245 245 0 0 0 897.4 654z" />
      <path fill="#2eb67d" d="M2447.6 899.2A245 245 0 0 0 2202.8 654 245 245 0 0 0 1958 899.2v245.3h244.8a245 245 0 0 0 244.8-245.3zm-652.7 0v-654A245 245 0 0 0 1550.2 0a245 245 0 0 0-244.8 245.2v654a245 245 0 0 0 244.7 245.3 245 245 0 0 0 244.8-245.3z" />
      <path fill="#ecb22e" d="M1550.1 2452.5a245 245 0 0 0 244.8-245.2 245 245 0 0 0-244.8-245.2h-244.8v245.2a245.1 245.1 0 0 0 244.8 245.2zm0-654.1h652.7a245 245 0 0 0 244.8-245.2 245 245 0 0 0-244.7-245.3h-652.7a245 245 0 0 0-244.8 245.2 245 245 0 0 0 244.7 245.3z" />
      <path fill="#e01e5a" d="M0 1553.2a245 245 0 0 0 244.8 245.2 245 245 0 0 0 244.8-245.2V1308H244.8A245 245 0 0 0 0 1553.2zm652.7 0v654a245 245 0 0 0 244.7 245.3 245 245 0 0 0 244.8-245.2v-653.9a244.8 244.8 0 1 0-489.5-.2s0 .1 0 0" />
    </g>
  </Svg>
);
