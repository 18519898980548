import { Emoji, Tooltip } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { PageId } from 'src/constants/routing.constant';
import { useParams } from 'src/hooks';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { isParentOfInsight } from 'src/utils/docType.util';

import { DocTypeTabs } from './SettingsDocType/DocTypeTabs';
import { SettingsDocTypeMenu } from './SettingsDocType/SettingsDocTypeMenu';
import { StyledAiIcon } from './SettingsRoadmaps.styles';
import {
  Container, Header, CloseButton, Title, Content, TitleContainer,
} from './SettingsRoadmapsDocType.styles';
import { SettingsRoadmapsLinkedTo } from './SettingsRoadmapsLinkedTo';

type SettingsRoadmapsDocTypeProps = {
  doctypeId?: string;
  onClose?: VoidFunction;
  noBorder?: boolean;
};

export const SettingsRoadmapsDocType = ({
  doctypeId: docTypeIdFromProps, onClose, noBorder = false,
}:SettingsRoadmapsDocTypeProps) => {
  const { navigate } = useNavigateToSettings();
  const { doctypeId: docTypeIdFromParams } = useParams();
  const docTypeId = docTypeIdFromProps || docTypeIdFromParams;
  const docType = useGetDocType(docTypeId);
  return (
    <Container
      $noBorder={noBorder}
      initial="exit"
      animate="enter"
      exit="exit"
      variants={{
        enter: {
          opacity: 1,
        },
        exit: {
          opacity: 0,
        },
      }}
      transition={{
        duration: 0.1,
      }}
    >
      <Header>
        <TitleContainer>
          <CloseButton onClick={() => {
            if (onClose) {
              onClose();
            } else {
              navigate(PageId.SettingsRoadmaps);
            }
          }}
          >
            <CloseIcon />
          </CloseButton>

          {docType && (
            <Tooltip
              disabled={!docType.description}
              content={docType.description}
              withWrapper={false}
              withPortal
            >
              <Title>
                <Emoji emoji={docType.emoji} size={14} />
                <p>{docType.name}</p>
              </Title>
            </Tooltip>
          )}

          {isParentOfInsight(docType) && (
            <Tooltip
              content="This doc type's name and description impact Cycle AI requests"
              placement="top"
              withWrapper={false}
            >
              <StyledAiIcon size={12} />
            </Tooltip>
          )}

          {docTypeId && <SettingsDocTypeMenu doctypeId={docTypeId} />}
        </TitleContainer>

        {docTypeId && <SettingsRoadmapsLinkedTo doctypeId={docTypeId} />}
      </Header>

      <Content>
        {docTypeId && <DocTypeTabs doctypeId={docTypeId} padding={16} />}
      </Content>
    </Container>
  );
};
