export const CHANGE_LOG = 'https://www.cycle.app/changelog';
export const HELP_CYCLE_API = 'https://docs.cycle.app/';
export const HELP_INTEGRATION = 'https://help.cycle.app/core-concepts/integrations';
export const HELP_URL = 'https://help.cycle.app/';
export const HELP_URL_ARTICLE_CAPTURE_FEEDBACK = 'https://help.cycle.app/core-concepts/integrations';
export const HELP_URL_ARTICLE_DOCUMENT_INITIATIVE = 'https://help.cycle.app/get-started/document-features-in-your-roadmap';
export const HELP_URL_ARTICLE_WHAT_IS_CYCLE = 'https://help.cycle.app/get-started/what-is-cycle';
export const HELP_URL_ARTICLE_WORKFLOWS = 'https://help.cycle.app/become-a-pro-cycler/workflows';
export const HELP_URL_CALL_RECORDING = 'https://help.cycle.app/advanced/call-recording';
export const HELP_URL_CREATE_VIEW = 'https://help.cycle.app/core-concepts/views#create-new-views';
export const HELP_URL_CUSTOMERS = 'https://help.cycle.app/core-concepts/customers';
export const HELP_URL_CUSTOMIZE_VIEW = 'https://help.cycle.app/core-concepts/views#customize-your-view';
export const HELP_URL_EDIT_VIEW = ' https://help.cycle.app/core-concepts/views#edit-a-view';
export const HELP_URL_INSIGHTS = 'https://help.cycle.app/core-concepts/insights';
export const HELP_URL_LINEAR = 'https://help.cycle.app/setup/integrations/linear';
export const HELP_URL_PROPERTIES = 'https://help.cycle.app/setup/properties';
export const HELP_URL_RELEASES = 'https://help.cycle.app/core-concepts/releases';
export const HELP_URL_SLACK = 'https://help.cycle.app/core-concepts/slack-integration';
export const HELP_URL_WORKFLOWS = 'https://help.cycle.app/advanced/workflows';
export const HELP_WEBHOOKS = 'https://docs.cycle.app/the-graphql-api/webhooks';
export const HELP_CORE_CONCEPTS_FEEDBACK = 'https://help.cycle.app/core-concepts/feedback';
export const HELP_URL_DROPZONE = 'https://help.cycle.app/core-concepts/feedback#feedback-dropzone';
export const HELP_URL_AUTOPILOT = 'https://help.cycle.app/advanced/ai-and-automations#feedback-on-autopilot';
