import { CodeIcon } from '@cycle-app/ui/icons';
import { indexBy, pickBy, keys } from 'ramda';
import { Fragment } from 'react';
import { isPresent } from 'ts-is-present';

import { COMMANDS } from 'src/constants/editor.constants';
import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useEditorContext } from 'src/contexts/editorContext';
import { useProductIntegrations } from 'src/hooks';
import { useGetPermission } from 'src/reactives/permission.reactive';

import { Action } from '../QuickActions/QuickActions.styles';
import { QuickIntegrationDropdown } from './QuickIntegrationDropdown';
import { IntegrationIcons } from './QuickIntegrations.styles';
import { integrationTypes, QuickIntegrationsByType, QuickIntegrationType, QuickIntegrationItem } from './QuickIntegrations.types';

export const QuickIntegrations = () => {
  const { canReadSettings } = useGetPermission();
  const { getIntegration } = useProductIntegrations();
  const editor = useEditorContext(ctx => ctx.editor);
  const isReadOnly = useEditorContext(ctx => ctx.isReadOnly);

  const integrations = indexBy<QuickIntegrationItem, QuickIntegrationType>(
    item => item.integration.type as QuickIntegrationType,
    integrationTypes
      .map(type => {
        const integration = getIntegration(type);
        if (!integration?.type) return null;
        const isInstalled = !!integration.provider;
        if (!isInstalled && !canReadSettings) return null;
        const data = integrationsDataMap[integration.type];
        if (!data) return null;
        return {
          integration,
          data,
          isInstalled,
        };
      })
      .filter(isPresent),
  );

  const installed = keys(pickBy<QuickIntegrationsByType, Partial<QuickIntegrationsByType>>(
    i => i.isInstalled,
    integrations,
  ));

  if (!installed.length && !canReadSettings) return null;

  if (!installed.length) {
    return (
      <QuickIntegrationDropdown integrations={integrations}>
        {props => (
          <Action disabled={isReadOnly} {...props} iconStart={<CodeIcon />}>
            Add integration
          </Action>
        )}
      </QuickIntegrationDropdown>
    );
  }

  if (installed.length < 3) {
    return (
      <>
        {installed.map(type => (
          <Action
            key={type}
            disabled={isReadOnly}
            onClick={() => {
              const { commandKey } = integrations[type].data;
              if (!commandKey) return;
              if (commandKey === 'LINEAR' || commandKey === 'LINEAR_PROJECT') {
                editor.chain().focus().insertContent('/Linear').run();
              } else {
                editor.chain().focus().insertContent(COMMANDS[commandKey]).run();
              }
            }}
          >
            <IntegrationIcons>
              {integrations[type].data.icon}
            </IntegrationIcons>
            {integrations[type].data.labelOne}
          </Action>
        ))}
      </>
    );
  }

  return (
    <QuickIntegrationDropdown integrations={integrations}>
      {props => (
        <Action disabled={isReadOnly} {...props}>
          <IntegrationIcons>
            {installed.map(type => (
              <Fragment key={type}>
                {integrationsDataMap[type].icon}
              </Fragment>
            ))}
          </IntegrationIcons>
          Integrations
        </Action>
      )}
    </QuickIntegrationDropdown>
  );
};
