import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Emoji, Tooltip } from '@cycle-app/ui';

import { PageId } from 'src/constants/routing.constant';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { useGetLastView } from 'src/reactives/lastView.reactive';
import { isParentOfInsight, isCustom } from 'src/utils/docType.util';

import { ListContainer, DocType } from './ViewDocTypes.styles';

type Props = {
  doctypeFilter: (docType: DoctypeFragment) => boolean;
};

const ViewDocTypes = ({ doctypeFilter }: Props) => {
  const { isNewInbox } = useGetLastView();
  const { docTypes } = useGetDocTypes();
  const docTypesList = Object.values(docTypes).filter(doctypeFilter);
  const { navigate } = useNavigateToSettings({ useLimitationModal: true });
  return (
    <ListContainer $isNewInbox={isNewInbox}>
      {docTypesList.map((docType) => (
        <Tooltip
          key={docType.id}
          withPortal
          withWrapper={false}
          content={`${docType.name} settings`}
          placement="bottom"
        >
          <DocType
            role="button"
            tabIndex={0}
            onClick={() => navigate(PageId.SettingsRoadmapsDocType, { doctypeId: docType.id })}
          >
            <Emoji emoji={docType.emoji} size={14} />
            {docTypesList.length === 1 && docType.name}
          </DocType>
        </Tooltip>
      ))}
    </ListContainer>
  );
};

export const RoadmapDocTypes = () => <ViewDocTypes doctypeFilter={isCustom} />;

export const InsightDocTypes = () => <ViewDocTypes doctypeFilter={isParentOfInsight} />;
