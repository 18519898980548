import { SelectPanel, SelectOption } from '@cycle-app/ui';
import { UnlinkIcon, EmbedIcon } from '@cycle-app/ui/icons';
import { useAsyncCallback } from 'react-async-hook';

import { useEditorContext } from 'src/contexts/editorContext';
import { addEmbed, isEmbedSupported } from 'src/utils/editor/iframely.util';
import { getCurrentLink } from 'src/utils/editor/link.util';

import { motionProps } from '../Bubble/Bubble.motion';
import { Container } from './LinkBubble.styles';

const LinkBubble = () => {
  const editor = useEditorContext(ctx => ctx.editor);
  const onError = useEditorContext(ctx => ctx.onError);

  const {
    execute: addEmbedAsync,
    loading,
  } = useAsyncCallback(addEmbed);

  const url = getCurrentLink(editor);

  return (
    <Container
      tabIndex={
        // elements should be considered as interactive,
        // unless the bubble menu will close on click.
        -1
      }
      {...motionProps}
      transition={{
        duration: 0.1,
        delay: 0.5,
      }}
    >
      <SelectPanel
        hideSearch
        onOptionChange={onOptionChange}
        options={[
          {
            label: 'Dismiss',
            value: 'unlink',
            icon: <UnlinkIcon />,
            onSelect: unlink,
          },
          ...(isEmbedSupported(url) ? [{
            label: 'Embed',
            value: 'embed',
            icon: <EmbedIcon />,
            onSelect: embed,
          }] : []),
        ]}
      />
    </Container>
  );

  function onOptionChange(option: SelectOption) {
    option.onSelect?.();
  }

  function unlink() {
    editor.chain().focus().unsetLink().run();
  }

  async function embed() {
    if (loading) {
      return;
    }

    await addEmbedAsync({
      url,
      editor,
      onError,
    });
  }
};

export default LinkBubble;
