import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { SlackIcon } from '@cycle-app/ui/icons';

import { ACTIVE_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { useInstallIntegration, useProductIntegrations } from 'src/hooks';
import { useGetIntegrationPermission, useGetPermission } from 'src/reactives';
import { openSourcesModal } from 'src/reactives/integrations.reactive';

import {
  Container, SlackCard, SourcesCard,
  AvailableSources, Title, Slack,
} from './SourcesEmptyState.styles';

export const SourcesEmptyState = () => {
  const {
    sourcesByStatus, getIntegration,
  } = useProductIntegrations();
  const { canReadSettings } = useGetPermission();
  const { canAddIntegration } = useGetIntegrationPermission();
  const install = useInstallIntegration();
  const slackIntegration = getIntegration(IntegrationType.Slack);

  const sources = sourcesByStatus.uninstalled.filter(source => ACTIVE_INTEGRATIONS.includes(source.integrationType));

  if (!canReadSettings || !canAddIntegration || !sources.length) return null;

  return (
    <Container>
      <SlackCard>
        <Slack>
          <SlackIcon />
        </Slack>

        <Title>Capture feedback from Slack</Title>

        <Button
          size="S"
          variant="primary"
          onClick={() => slackIntegration?.type && install(slackIntegration.type, slackIntegration)}
        >
          Install slack
        </Button>
      </SlackCard>

      <SourcesCard>
        <AvailableSources>
          {sources
            .filter(source => source.integrationType !== IntegrationType.Slack)
            .map(source => {
              const data = integrationsDataMap[source.integrationType];
              return <div key={source.integrationType}>{data.icon}</div>;
            })}
        </AvailableSources>

        <Title>No source installed</Title>

        <Button
          size="S"
          variant="primary"
          onClick={openSourcesModal}
        >
          Add sources
        </Button>
      </SourcesCard>
    </Container>
  );
};
