import { AskQuestionDocument } from '@cycle-app/graphql-codegen';
import { CloseIcon, AddIcon } from '@cycle-app/ui/icons';
import { useLayoutEffect, useRef, useState } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useSafeMutation } from 'src/hooks';
import {
  addAskItems, closeAskPanel, updateAskAnswer, useGetAskAnswers, useGetAskItems, useGetAskPanel,
} from 'src/reactives/ask.reactive';

import { AnswerDocs } from './AnswerDocs';
import { AnswerItem } from './AnswerItem';
import {
  Container, Content, Scrollable,
  Header, Title, Actions, Action,
  Footer, QuestionInput,
  Question,
} from './Ask.styles';
import { AskIcon } from './AskIcon';

export const AskPanel = () => {
  const defaultValue = useGetAskPanel().question;
  const { items } = useGetAskItems();
  const { answers } = useGetAskAnswers();
  const scrollableRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [withBorderBottom, setWithBorderBottom] = useState(false);
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [askQuestion] = useSafeMutation(AskQuestionDocument);

  useLayoutEffect(() => {
    inputRef.current?.focus();
  }, []);

  const [answerId, setAnswerId] = useState<string | null>(null);

  return (
    <Container>
      <Header>
        <Title>
          <AskIcon />
          <span>Ask Cycle</span>
        </Title>
        <Actions>
          <Action
            tooltip="New ask"
            tooltipPlacement="top"
            onClick={() => inputRef.current?.focus()}
          >
            <AddIcon size={13} />
          </Action>
          <Action onClick={closeAskPanel}>
            <CloseIcon size={12} />
          </Action>
        </Actions>
      </Header>

      <Content
        $withBorder={withBorderBottom && !answerId}
        $withGradient
      >
        <Scrollable
          style={{ opacity: answerId ? 0 : 1 }}
          ref={scrollableRef}
          onScroll={e => {
            const el = e.target as HTMLDivElement;
            if (el.scrollTop < 0 && !withBorderBottom) setWithBorderBottom(true);
            if (el.scrollTop === 0 && withBorderBottom) setWithBorderBottom(false);
          }}
        >
          {items.map(item => (item.type === 'question' ? (
            <Question key={item.id}>
              {item.content}
            </Question>
          ) : (
            <AnswerItem
              key={item.id}
              answer={answers[item.id]}
              openDocs={() => setAnswerId(item.id)}
            />
          )))}
        </Scrollable>

        {answerId && (
          <AnswerDocs
            docs={answers[answerId]?.content?.docs ?? []}
            hide={() => setAnswerId(null)}
          />
        )}
      </Content>

      <Footer>
        <QuestionInput
          ref={inputRef}
          defaultValue={defaultValue}
          placeholder="Ask a question to your feedback"
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              const question = e.currentTarget.value.trim();
              if (!question) return;
              e.currentTarget.value = '';
              scrollableRef.current?.scrollTo(0, 0);
              setAnswerId(null);

              const anwserId = crypto.randomUUID();

              addAskItems([
                {
                  id: anwserId,
                  type: 'answer',
                },
                {
                  id: crypto.randomUUID(),
                  type: 'question',
                  content: question,
                },
              ]);

              updateAskAnswer(anwserId, {
                id: anwserId,
                status: 'pending',
                content: null,
                uuid: null,
              });

              const result = await askQuestion({
                variables: {
                  productId,
                  question,
                },
              });

              const uuid = result?.data?.ask;
              if (typeof uuid !== 'string') return;

              updateAskAnswer(anwserId, {
                id: anwserId,
                status: 'loading',
                uuid,
                content: null,
              });
            }
          }}
        />
      </Footer>
    </Container>
  );
};
