import { Tooltip } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';

import { useExtractQuotesContext } from 'src/contexts/extractQuotesContext';
import { openVerifyQuotes } from 'src/reactives/docRightPanel.reactive';

import { Container, Content, Title, RetryButton } from './DocStateLoaderQuotes.styles';

export const DocStateLoaderQuotes = () => {
  const {
    status, extractQuotes, quotes,
  } = useExtractQuotesContext();

  if (status === 'loading') {
    return (
      <Container disabled $isLoading>
        <Content>
          <AiIcon hasGradient isAnimated />
          <Title $isLoading>
            Looking for quotes…
          </Title>
        </Content>
      </Container>
    );
  }

  if (status === 'verify') {
    return (
      <Container onClick={openVerifyQuotes}>
        <Content>
          <AiIcon hasGradient />
          <Title>
            {counterLabel(quotes.length)}
          </Title>
        </Content>
      </Container>
    );
  }

  if (status === 'verified') {
    return (
      <Tooltip
        withPortal
        withWrapper={false}
        placement="top"
        content="Remove existing AI-generated quotes if you want to extract new ones"
      >
        <Container $isDisabled>
          <Content>
            <AiIcon />
            <Title>
              No quote to verify
            </Title>
          </Content>
        </Container>
      </Tooltip>
    );
  }

  if (status === 'retry') {
    return (
      <Container $isDisabled>
        <Content>
          <AiIcon />
          <Title>
            No quote to verify
          </Title>
          <RetryButton onClick={extractQuotes}>
            Retry
          </RetryButton>
        </Content>
      </Container>
    );
  }

  if (status === 'extract') {
    return (
      <Container onClick={extractQuotes}>
        <Content>
          <AiIcon hasGradient />
          <Title>
            Extract quotes
          </Title>
        </Content>
      </Container>
    );
  }

  return null;
};

const counterLabel = (count = 0) => {
  if (count === 0) return 'No quote to verify';
  if (count === 1) return '1 quote to verify';
  return `${count} quotes to verify`;
};
