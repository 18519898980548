import { DoctypeFragment, StatusCategory, Feature } from '@cycle-app/graphql-codegen';

import featureStatusesPreview from 'src/assets/feature-preview-statuses.png';
import { FeatureEmptyState } from 'src/components/FeatureEmptyState';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { HELP_URL_WORKFLOWS } from 'src/constants/help.constants';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { setFeatureToEnable } from 'src/reactives/features.reactive';

import { SettingsCategory } from './SettingsCategory';
import { Page, Sections } from './SettingsWorkflows.styles';

export const SettingsWorkflows = () => {
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  return (
    <Page>
      <SettingsViewHeader
        title="Statuses"
        helpUrl={HELP_URL_WORKFLOWS}
        fillDescriptionSpace
      />
      {isRoadmapsEnabled
        ? <Workflows />
        : (
          <FeatureEmptyState
            onClick={() => setFeatureToEnable(Feature.Roadmap)}
            src={featureStatusesPreview}
            title="Enable roadmaps to set up statuses"
            // eslint-disable-next-line max-len
            description="Enable Roadmaps to unlock planning capability as well as a seamless project management experience. With Roadmaps, you will create multi-level planning views, customize your statuses, and integrate with Linear to keep feedback and issue tracking in sync"
          />
        )}
    </Page>
  );
};

export const Workflows = ({
  docType, hideBorder = false, hideActions = false,
}: { docType?: DoctypeFragment; hideBorder?: boolean; hideActions?: boolean }) => (
  <Sections $hideBorder={hideBorder}>
    <SettingsCategory
      label="Not started"
      category={StatusCategory.NotStarted}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
    <SettingsCategory
      label="Started"
      category={StatusCategory.Started}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
    <SettingsCategory
      label="Completed"
      category={StatusCategory.Completed}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
    <SettingsCategory
      label="Canceled"
      category={StatusCategory.Canceled}
      currentDocTypeId={docType?.id}
      hideActions={hideActions}
    />
  </Sections>
);
