import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AirtableIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M7.20311 1.18932L1.92631 3.37276C1.63295 3.49428 1.63591 3.91108 1.93127 4.02812L7.23007 6.1294C7.69563 6.31404 8.21411 6.31404 8.67967 6.1294L13.9785 4.02812C14.2737 3.91108 14.2769 3.49428 13.9833 3.37284L8.70671 1.18932C8.2253 0.990116 7.68451 0.990116 7.20311 1.18932Z" fill="#FCB400" />
    <path d="M8.42493 7.27686V12.5262C8.42493 12.7758 8.67669 12.9469 8.90877 12.8549L14.8132 10.563C14.8791 10.5369 14.9356 10.4916 14.9754 10.433C15.0152 10.3744 15.0365 10.3052 15.0364 10.2344V4.9851C15.0364 4.73542 14.7847 4.56446 14.5526 4.65646L8.64813 6.9483C8.58229 6.97442 8.52581 7.01971 8.48601 7.07831C8.44622 7.1369 8.42493 7.20602 8.42493 7.27686Z" fill="#18BFFF" />
    <path d="M7.04622 7.54762L5.2939 8.3937L5.11598 8.4797L1.41694 10.2521C1.18254 10.3652 0.883179 10.1943 0.883179 9.93386V5.00698C0.883179 4.91274 0.931499 4.83138 0.996299 4.77018C1.02288 4.74371 1.05304 4.72109 1.0859 4.70298C1.17422 4.64994 1.3003 4.63578 1.4075 4.67818L7.01678 6.90074C7.3019 7.01386 7.3243 7.4133 7.04622 7.54762Z" fill="#F82B60" />
    <path d="M7.04626 7.54762L5.29394 8.3937L0.996338 4.7701C1.02293 4.74366 1.05309 4.72107 1.08594 4.70298C1.17426 4.64994 1.30034 4.63578 1.40754 4.67818L7.01682 6.90074C7.30194 7.01386 7.32434 7.4133 7.04626 7.54762Z" fill="black" fillOpacity="0.25" />
  </Svg>
);
