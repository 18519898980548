import { Button, TextSecondary } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { useProductAddOn } from 'src/hooks';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';

import { AvatarList } from '../../AvatarList';
import { MeetingButtonInstall } from '../../MeetingButtonInstall';
import {
  Container, Content, Title, Header, HeaderImg, Body, BodyCta, AvatarContainer,
} from './RecordingTabEmpty.styles';

const light = '/images/recording-avatars-light.png';
const dark = '/images/recording-avatars-dark.png';

export const RecordingTabEmpty = () => {
  // List formatting: format "One, two, and three"
  const lf = useMemo(() => new Intl.ListFormat('en-US'), []);
  const theme = useTheme();
  const meetings = useProductAddOn('MEETINGS');
  const {
    integration, isInstalled,
  } = useMeetingsIntegration();
  const {
    request, isRequesting,
  } = useProductAddOnRequest();
  const others = useMemo(() => (
    nodeToArray(integration?.installations).filter(user => user.__typename === 'OtherUser')
  ), [integration?.installations]);
  const avatars = others.slice(0, 3);
  const diff = others.length - avatars.length;
  return (
    <Container>
      <Header>
        <HeaderImg src={theme.isDark ? dark : light} alt="" />
      </Header>
      <Content>
        <Body>
          <div>
            <Title>Call recording</Title>
            <TextSecondary $mt="2px">
              Record your calls and automatically fetch transcripts as Cycle feedback.
              <br />
              It works with Zoom, Google Meet & Microsoft Teams.
            </TextSecondary>
            {(!!avatars.length) && (
              <AvatarContainer>
                <AvatarList
                  isLoading={false}
                  avatars={avatars}
                  skeletonCount={3}
                />
                <div>
                  {lf.format([
                    ...avatars.map(u => u.firstName || u.email),
                    ...diff ? [`${diff} more`] : [],
                  ])}
                  {avatars.length > 1 ? ' are ' : ' is '}
                  already using it.
                </div>
              </AvatarContainer>
            )}
          </div>
          <BodyCta>
            {
              // Add-on not enabled
              !meetings.isEnabled && (
                <Button
                  isLoading={isRequesting}
                  full
                  size="M"
                  onClick={() => request('MEETINGS')}
                >
                  Request add-on
                </Button>
              )
            }
            {
              // Not installed at user level
              meetings.isEnabled && !isInstalled && <MeetingButtonInstall />
            }
          </BodyCta>
        </Body>
      </Content>
    </Container>
  );
};
