import { ReactNode } from 'react';

import {
  Actions,
  Body,
  Container,
  Description,
  Header,
  Logo,
  Name,
  Cta,
} from './IntegrationCardV2.styles';

export type IntegrationCardV2Props = {
  title: string;
  description: string;
  logo: ReactNode;
  cta: ReactNode;
  actions?: ReactNode;
};

export const IntegrationCardV2 = ({
  title,
  description,
  logo,
  cta,
  actions,
}: IntegrationCardV2Props) => (
  <Container>
    <Header>
      <Logo>{logo}</Logo>
      {!!actions && <Actions>{actions}</Actions>}
    </Header>
    <Body>
      <Name>{title}</Name>
      <Description>{description}</Description>
      <Cta>{cta}</Cta>
    </Body>
  </Container>
);
