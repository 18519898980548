import { ReleaseTagStyle } from '@cycle-app/graphql-codegen';
import { Flex, Tooltip } from '@cycle-app/ui';
import { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { isValidHexColor } from '../../utils/color.util';
import { ChangelogBuilderInputColor } from './ChangelogBuilderInputColor';
import { Section } from './ChangelogBuilderSidebar.styles';
import {
  List, StyledCheckboxInput, TagStyleContainer, TagStyleLabel, TagStyleValueDot, TagStyleValueBg,
} from './ChangelogBuilderTagsDesign.styles';
import { LabelWithReset } from './LabelWithReset';

export const ChangelogBuilderTagsDesign = () => {
  const {
    formState, control, setValue, watch, clearErrors, resetField,
  } = useChangelogBuilderFormContext();
  const { fields } = useFieldArray({
    name: 'tags',
    control,
    // Avoid conflicting with data id.
    keyName: '_id',
  });
  const [resetKey, setResetKey] = useState(0);
  const tags = watch('tags');
  const releaseTagColor = watch('releaseTagColor');
  const releaseTagStyle = watch('releaseTagStyle');

  // Compare only existing tags
  const isEqual = tags.filter(tag => tag.id).map(tag => {
    return formState.defaultValues?.tags?.find(defaultTag => defaultTag?.id === tag.id && defaultTag.color === tag.color);
  }).every(Boolean) &&
    releaseTagColor === formState.defaultValues?.releaseTagColor &&
    releaseTagStyle === formState.defaultValues?.releaseTagStyle;

  return (
    <Section>
      <LabelWithReset
        label="Release tags"
        reset={() => {
          fields.filter(field => field.id).forEach((field, i) => {
            const defaultColor = formState.defaultValues?.tags?.find(defaultTag => defaultTag?.id === field.id)?.color;
            if (defaultColor) {
              setValue(`tags.${i}.color`, defaultColor);
            }
          });
          clearErrors('tags');
          resetField('releaseTagColor');
          resetField('releaseTagStyle');
          // Each field won't update on reset.
          setResetKey(r => r + 1);
        }}
        showReset={!isEqual}
      />
      <Flex style={{ marginTop: '8px' }} $justify="space-between">
        <div>Style</div>
        <TagStyleContainer>
          <Controller
            control={control}
            name="releaseTagStyle"
            render={({ field }) => (
              <Flex>
                <StyledCheckboxInput
                  id={ReleaseTagStyle.Background}
                  value={ReleaseTagStyle.Background}
                  onChange={field.onChange}
                  checked={field.value === ReleaseTagStyle.Background}
                  label={(
                    <Tooltip
                      content="Background"
                      placement="top"
                    >
                      <TagStyleLabel $checked={field.value === ReleaseTagStyle.Background}>
                        <TagStyleValueBg $checked={field.value === ReleaseTagStyle.Background}>
                          <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.47193 1.01318H3.47726V5.48716C3.47726 5.76316 3.25326 5.98716 2.97726 5.98716C2.70126 5.98716 2.47726 5.76316 2.47726 5.48716V1.01318H0.529297C0.253297 1.01318 0.0292969 0.789184 0.0292969 0.513184C0.0292969 0.237184 0.253297 0.0131836 0.529297 0.0131836H5.47193C5.74793 0.0131836 5.97193 0.237184 5.97193 0.513184C5.97193 0.789184 5.74793 1.01318 5.47193 1.01318Z"
                              fill="currentColor"
                            />
                          </svg>
                        </TagStyleValueBg>
                      </TagStyleLabel>
                    </Tooltip>
                  )}
                />
                <StyledCheckboxInput
                  id={ReleaseTagStyle.Dot}
                  value={ReleaseTagStyle.Dot}
                  onChange={field.onChange}
                  checked={field.value === ReleaseTagStyle.Dot}
                  label={(
                    <Tooltip
                      content="Bullet"
                      placement="top"
                    >
                      <TagStyleLabel $checked={field.value === ReleaseTagStyle.Dot}>
                        <TagStyleValueDot $checked={field.value === ReleaseTagStyle.Dot} />
                      </TagStyleLabel>
                    </Tooltip>
                  )}
                />
              </Flex>
            )}
          />
        </TagStyleContainer>
      </Flex>
      <div style={{ marginTop: '8px' }}>
        <Controller
          control={control}
          name="releaseTagColor"
          render={({
            field, fieldState,
          }) => {
            const color = watch(field.name);
            return (
              <ChangelogBuilderInputColor
                color={color}
                error={fieldState.error?.message}
                label="Text color"
                onChange={value => {
                  clearErrors(field.name);
                  field.onChange(value);
                }}
              />
            );
          }}
        />
      </div>
      {!!fields.length && (
        <List key={resetKey}>
          {fields.map((item, i) => (
            <li key={item.id || item._id}>
              <Controller
                render={({
                  field, fieldState,
                }) => {
                  const color = watch(field.name);
                  return (
                    <ChangelogBuilderInputColor
                      color={color}
                      error={fieldState.error?.message}
                      label={item.value}
                      onChange={value => {
                        clearErrors(field.name);
                        // If the user manually edit the input, # can be missing.
                        setValue(field.name, value);
                      }}
                    />
                  );
                }}
                name={`tags.${i}.color`}
                rules={{
                  validate: (value) => {
                    return isValidHexColor(value) ? undefined : 'Invalid color';
                  },
                }}
                control={control}
              />
            </li>
          ))}
        </List>
      )}
    </Section>
  );
};
