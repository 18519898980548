import { ReleaseNoteBaseFragment, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import keyBy from 'lodash/keyBy';
import { FC, useMemo, useRef } from 'react';

import { useReleaseNotes } from 'src/hooks/releases/useReleaseNotes';

type ReleaseNotesProviderProps = {
  releaseId: string;
  isReadonly: boolean;
  publicStatus: ReleasePublicStatus | null;
  showBugAndImprovements: boolean | null;
};

export type GroupName = 'main' | 'other';

type ReleaseNotesContextValue = {
  isReadonly: boolean;
  releaseId: string;
  notesById: Record<string, ReleaseNoteBaseFragment>;
  getReleaseNote: (id: string) => ReleaseNoteBaseFragment | undefined;
  publicStatus: ReleasePublicStatus | null;
  showBugAndImprovements: boolean | null;
} & Record<GroupName, {
  isLoading: boolean;
  isLoadingMore: boolean;
  list: Array<ReleaseNoteBaseFragment>;
  hasNextPage: boolean;
  endCursor: string;
  loadMore: () => Promise<void>;
  refetch: (size: number) => Promise<Array<ReleaseNoteBaseFragment>>;
}>;

const ReleaseNotesContext = createContext<ReleaseNotesContextValue>({} as ReleaseNotesContextValue);

export const ReleaseNotesProvider: FC<React.PropsWithChildren<ReleaseNotesProviderProps>> = ({
  releaseId, children, isReadonly, showBugAndImprovements, publicStatus,
}) => {
  const main = useReleaseNotes(releaseId, false);
  const other = useReleaseNotes(releaseId, true);

  // Used to make sure that we don't lose the release notes when switching between main and other
  const prevNotesById = useRef<Record<string, ReleaseNoteBaseFragment>>({});

  const value = useMemo(() => {
    const newNotesById = keyBy([...main.list, ...other.list], 'id');

    const notesById = {
      ...prevNotesById.current,
      ...newNotesById,
    };

    prevNotesById.current = newNotesById;

    return {
      releaseId,
      notesById,
      getReleaseNote: (id: string) => notesById[id],
      main,
      other,
      isReadonly,
      showBugAndImprovements,
      publicStatus,
    };
  }, [main, other, releaseId, isReadonly, showBugAndImprovements, publicStatus]);

  return (
    <ReleaseNotesContext.Provider value={value}>
      {children}
    </ReleaseNotesContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useReleaseNotesContext = <T extends unknown = ReleaseNotesContextValue>(selector?: ContextSelector<ReleaseNotesContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(ReleaseNotesContext);
  if (!isWrappedWithContext) throw new Error('useReleaseNotesContext must be used within a ReleaseNotesProvider');
  return useContextSelector(ReleaseNotesContext, selector ?? (ctx => ctx as T));
};

export const useReleaseNoteBase = (id?: string) => useReleaseNotesContext(ctx => (id ? ctx.notesById[id] : null));
