import { typo, TextButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 1px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  overflow: hidden; 
`;

export const Title = styled.div`
  ${typo.body500}
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const StyledTextButton = styled(TextButton)`
  width: fit-content;
  gap: 6px;
  ${typo.caption400}
  color: ${p => p.theme.colors.releases.textButton.color};
  padding: 2px 6px;
  
  :hover, :focus {
    color: ${p => p.theme.colors.text.secondary};
    background: ${p => p.theme.colors.background.tertiary};
  }
`;

export { StyledTextButton as TextButton };

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
`;
