import { DocInsights, DocQuotes } from 'src/components/DocInsights';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

export const ReleaseDocInsights = ({
  noteId,
  isReadOnly = false,
}: {
  noteId: string;
  isReadOnly?: boolean;
}) => {
  const { releaseNote } = useReleaseNote(noteId);
  if (!releaseNote?.doc) return null;
  return quotesEnabled
    ? <DocQuotes doc={releaseNote.doc} context="doc-item" isReadOnly={isReadOnly} />
    : <DocInsights doc={releaseNote.doc} context="doc-item" isReadOnly={isReadOnly} />;
};
