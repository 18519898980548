import { FC } from 'react';

interface Props {
  className?: string;
}

export const AsideDraws: FC<React.PropsWithChildren<Props>> = ({ className }) => (
  <svg className={className} width="510" height="547" viewBox="0 0 510 547" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-36 294.863L-36 94.6022L175.68 94.6022C230.98 94.6022 275.81 139.432 275.81 194.733V194.733C275.81 250.033 230.98 294.863 175.68 294.863L-36 294.863Z" stroke="black" strokeMiterlimit="10" />
    <path d="M43.0503 294.864C43.0503 257.027 73.7234 226.354 111.561 226.354V226.354C149.398 226.354 180.071 257.027 180.071 294.864V294.864C180.071 332.701 149.398 363.374 111.561 363.374V363.374C73.7234 363.374 43.0503 332.701 43.0503 294.864V294.864Z" fill="#FFCE12" stroke="black" strokeMiterlimit="10" />
    <path d="M439.584 45.0556C463.836 3.05126 517.546 -11.3405 559.551 12.9107V12.9107C601.555 37.162 615.947 90.8727 591.696 132.877V132.877C567.444 174.881 513.734 189.273 471.729 165.022V165.022C429.725 140.771 415.333 87.06 439.584 45.0556V45.0556Z" fill="#F2BBFB" stroke="#020202" strokeMiterlimit="10" />
    <path d="M259.122 398.357V505.918C259.122 528.01 277.03 545.918 299.122 545.918H512.083" stroke="black" strokeMiterlimit="10" />
    <path d="M258.93 329.998L190.611 398.316L258.93 466.635L327.249 398.316L258.93 329.998Z" fill="#68D6EE" stroke="#020202" strokeWidth="0.903925" strokeMiterlimit="10" />
  </svg>
);
