import { typo, ActionButton, TextButton } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { motionProps } from './NestedChildren.motion';

export const Action = styled(ActionButton).attrs({ variant: 'tertiary' })`
  width: 20px;
  height: 20px;
  padding: 0;
`;

export const AddTextButton = styled(TextButton)`
  width: fit-content;
  height: 20px;
  padding: 4px 6px;
  gap: 6px;
  --colorHover: ${p => p.theme.colors.text.hover};
  ${typo.caption400}
`;

export const GroupsContainer = styled(motion.div).attrs(motionProps)``;

export const GroupContainer = styled.div`
  padding-left: 24px;
`;

export const GroupInner = styled.div`
  > * {
    margin-top: 8px;
  }
`;

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 6px;
  min-height: 32px;
  border-radius: 6px;
  background: ${p => p.theme.colors.group.bg};
  ${typo.body400}
`;

export const GroupTitle = styled.div`
  ${typo.body500}
`;

export const GroupCount = styled.div`
  color: ${p => p.theme.colors.text.disabled};
`;

export const ChildrenContainer = styled(motion.div).attrs(motionProps)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LoadButton = styled(TextButton)`
  width: fit-content;
  height: 20px;
  padding: 4px 6px;
  gap: 6px;
  --colorHover: ${p => p.theme.colors.text.hover};
  ${typo.caption500}
`;
