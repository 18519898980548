import { FC } from 'react';

import { Svg } from '../components/Svg/Svg.styles';
import { Props } from '../types/icon.types';

export const EnvelopeIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.77369 4.33971C3.4194 4.33971 3.13219 4.62692 3.13219 4.98121V11.0189C3.13219 11.3732 3.4194 11.6604 3.77369 11.6604H12.2264C12.5807 11.6604 12.8679 11.3732 12.8679 11.0189V4.98121C12.8679 4.62692 12.5807 4.33971 12.2264 4.33971H3.77369ZM2.00012 4.98121C2.00012 4.00169 2.79417 3.20764 3.77369 3.20764H12.2264C13.2059 3.20764 14 4.00169 14 4.98121V11.0189C14 11.9984 13.2059 12.7925 12.2264 12.7925H3.77369C2.79417 12.7925 2.00012 11.9984 2.00012 11.0189V4.98121Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.09513 4.66728C2.26853 4.40717 2.61996 4.33688 2.88007 4.51029L8 7.92357L13.1199 4.51029C13.38 4.33688 13.7315 4.40717 13.9049 4.66728C14.0783 4.92738 14.008 5.27882 13.7479 5.45222L8.31398 9.07483C8.12385 9.20158 7.87615 9.20158 7.68602 9.07483L2.25211 5.45222C1.99201 5.27882 1.92172 4.92738 2.09513 4.66728Z" />
  </Svg>
);
