import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GrainIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g>
      <path d="M6.96384 0.188219C7.61131 -0.0633092 8.32949 -0.0633092 8.97696 0.188219C9.70448 0.471035 10.4221 0.799967 11.127 1.17549C11.2734 1.2536 11.2508 1.47054 11.0941 1.52513C7.23913 2.87427 4.47449 6.54382 4.47449 10.8595C4.47449 12.2034 4.74272 13.4853 5.22882 14.6537C5.29753 14.8184 5.12483 14.9869 4.96624 14.9041C3.81356 14.3032 2.72257 13.5908 1.70891 12.7771C1.16733 12.3421 0.808241 11.7201 0.70235 11.0336C0.547816 10.0297 0.470415 9.01548 0.470827 7.99978C0.470827 6.96781 0.549884 5.95466 0.70235 4.96598C0.808362 4.27939 1.16763 3.65739 1.70938 3.22249C2.5013 2.58668 3.34075 2.01247 4.22038 1.50489C5.0999 0.996578 6.01707 0.5564 6.96384 0.188219Z" fill="#00B96C" />
      <path d="M14.2319 3.22296C14.1415 3.15033 14.0505 3.07848 13.9589 3.00743C13.8628 2.93212 13.7519 2.8779 13.6335 2.8483C13.515 2.81871 13.3917 2.81441 13.2714 2.83567C9.0833 3.60036 5.90833 7.26803 5.90833 11.6769C5.90833 13.0472 6.21467 14.346 6.76336 15.5083C6.84807 15.688 7.00524 15.8226 7.193 15.8885C7.78001 16.0595 8.40702 16.0322 8.97695 15.8109C9.91011 15.4485 10.8268 15.0109 11.7204 14.4947C12.6002 13.9871 13.4398 13.4129 14.2319 12.7771C14.7734 12.3422 15.1324 11.7204 15.2384 11.034C15.3909 10.0449 15.47 9.03175 15.47 8.00025C15.47 6.96827 15.3909 5.95512 15.2384 4.96644C15.1326 4.27993 14.7735 3.65794 14.2319 3.22296Z" fill="#00B96C" />
    </g>
  </Svg>
);
