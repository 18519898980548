/* eslint-disable no-nested-ternary */
import { useApolloClient } from '@apollo/client';
import { CustomerFragment, DocChildFragment, DocsByCustomerDocument } from '@cycle-app/graphql-codegen/generated';

import { getDocType } from 'src/reactives/docTypes.reactive';
import { extract } from 'src/types/graphql.types';
import { defaultCustomerDocsPagination } from 'src/utils/pagination.util';

interface Params {
  /* eslint-disable @typescript-eslint/indent */
  doc: Pick<DocChildFragment,
    'assignee' |
    'attributes' |
    'childrenCount' |
    'threads' |
    'createdAt' |
    'creator' |
    'customer' |
    'docTargetsCount' |
    'docTargetsAiCount' |
    'doctype' |
    'id' |
    'insightsCount' |
    'isDraft' |
    'source' |
    'status' |
    'title'>;
  /* eslint-enable @typescript-eslint/indent */
  customer?: CustomerFragment | null;
  doctypeId?: string;
  newCustomer?: CustomerFragment | null;
}

export const useCustomerDocFromCache = () => {
  const { cache } = useApolloClient();
  const updateDocCustomer = ({
    doc, customer: optionalCustomer, doctypeId: optionalDoctypeId,
  }: Params, add = true) => {
    if (doc.isDraft) return;

    const customer = optionalCustomer ?? doc.customer;
    const doctypeType = getDocType(optionalDoctypeId ?? doc.doctype.id)?.type;

    if (!customer || !doctypeType) return;

    const queryRef = cache.readQuery({
      query: DocsByCustomerDocument,
      variables: {
        id: customer.id,
        doctypeType,
        ...defaultCustomerDocsPagination,
      },
    });

    const cacheCustomer = extract('Customer', queryRef?.node);

    if (cacheCustomer?.docs) {
      const children = [...cacheCustomer.docs.edges ?? []];

      // Docs are sorted from most recent to older.
      if (add) {
        children.splice(0, 0, {
          __typename: 'DocEdge',
          cursor: '',
          node: {
            __typename: 'Doc',
            ...doc,
            customer,
          },
        });
      }
      if (!add) {
        const indexToRemove = children.findIndex(({ node }) => node.id === doc.id);
        if (indexToRemove >= 0) {
          children.splice(indexToRemove, 1);
        }
      }

      cache.writeQuery({
        query: DocsByCustomerDocument,
        variables: {
          id: customer.id,
          doctypeType,
          ...defaultCustomerDocsPagination,
        },
        data: {
          node: {
            ...cacheCustomer,
            docs: {
              ...cacheCustomer.docs,
              pageInfo: {
                ...cacheCustomer.docs.pageInfo,
                endCursor: children[children.length - 1]?.cursor ?? '',
              },
              edges: children,
            },
          },
        },
      });
    }

    cache.modify({
      id: cache.identify(customer),
      fields: {
        nbFeedbacks: (nbFeedbacks: number) => (doctypeType !== 'FEEDBACK' ? nbFeedbacks : add ? nbFeedbacks + 1 : nbFeedbacks - 1),
        nbInsights: (nbInsights: number) => (doctypeType !== 'INSIGHT' ? nbInsights : add ? nbInsights + 1 : nbInsights - 1),
      },
    });
  };

  const addCustomerDoc = ({
    doc, customer, doctypeId,
  }: Params) => {
    updateDocCustomer({
      doc,
      customer,
      doctypeId,
    });
  };

  const removeCustomerDoc = ({
    doc, customer, doctypeId,
  }: Params) => {
    updateDocCustomer({
      doc,
      customer,
      doctypeId,
    }, false);
  };

  const toggleCustomerDoc = ({
    doc, customer: optionalCustomer, doctypeId, newCustomer,
  }: Params) => {
    const customer = optionalCustomer ?? doc.customer;
    if (customer) {
      removeCustomerDoc({
        doc,
        customer,
        doctypeId,
      });
    }
    if (newCustomer) {
      addCustomerDoc({
        doc,
        customer: newCustomer,
        doctypeId,
      });
    }
  };

  return {
    addCustomerDoc,
    removeCustomerDoc,
    toggleCustomerDoc,
  };
};
