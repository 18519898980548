import { TextHighlighter, Button } from '@cycle-app/ui';

import { mapAddOnsData } from 'src/constants/addOn.constants';
import { setLimitationsModal } from 'src/reactives';

import { Container, Title, Description, StyledAddOnIcon, ButtonContainer } from './AddOnCard.styles';

import type { AddOn } from 'src/types/addOn.types';

type AddOnCardProps = {
  addOn: AddOn;
  search: string;
};

export const AddOnCard = ({
  addOn, search,
}: AddOnCardProps) => (
  <Container>
    <StyledAddOnIcon name={addOn.name} />
    <Title>
      <TextHighlighter
        searchWords={[search]}
        textToHighlight={mapAddOnsData[addOn.name].name}
        className="highlight"
      />
    </Title>
    <Description>
      {mapAddOnsData[addOn.name].description}
    </Description>
    <ButtonContainer>
      <Button onClick={() => setLimitationsModal({
        action: 'USE_ADD_ON',
        brand: addOn.name,
      })}
      >
        Request
      </Button>
    </ButtonContainer>
  </Container>
);
