import { Avatar, SelectLine, TextHighlighter } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { search } from 'fast-fuzzy';
import { FC, useEffect, useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useSearchUsers } from 'src/hooks/api/useUsers';
import { getUserLabel } from 'src/utils/users.util';

import { Container, StyledSelectLine } from './MentionUserDropdown.styles';

interface Props {
  query: string | null | undefined;
  command: (p: { id: string }) => void;
  hide: () => void;
}

const MentionUserDropdown: FC<React.PropsWithChildren<Props>> = ({
  query, command, hide,
}) => {
  const [searchText] = useDebounce(
    query?.toLowerCase(),
    INPUT_ONCHANGE_DEBOUNCE,
  );

  const { users } = useSearchUsers({
    searchText,
    paginationSize: 5,
  });

  const items = useMemo(() => {
    if (!searchText) return users;
    return search(searchText, users, {
      keySelector: p => [p.firstName, p.lastName, p.email],
      threshold: 1,
    });
  }, [searchText, users]);

  const {
    selected,
    listProps,
    itemProps,
    hoverDisabled,
    select,
  } = useListNav({
    optionsValues: items.map(item => item.id),
    autoFocus: true,
    value: null,
    onSelect: id => {
      if (id) command({ id });
    },
  });

  // Select first user when the list is updated with search
  useEffect(() => {
    const firstUser = items[0];
    if (firstUser) select(firstUser.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      e.stopPropagation();
      hide();
    };
    document.addEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [hide]);

  return (
    <Container {...listProps}>
      {items.length === 0 && (
        <SelectLine
          label="No team mate found"
          isDisabled
        />
      )}
      {items.map((item) => {
        const name = getUserLabel(item);
        return (
          <StyledSelectLine
            key={item.id}
            label={(
              <>
                {searchText ? (
                  <TextHighlighter
                    searchWords={[searchText]}
                    textToHighlight={name}
                    className="highlight"
                  />
                ) : (
                  <span>{name}</span>
                )}
                {item.__typename === 'Me' ? ' (me)' : ''}
              </>
            )}
            isSelected={item.id === selected}
            hoverDisabled={hoverDisabled}
            startSlot={(
              <Avatar user={item} />
          )}
            {...itemProps(item.id)}
          />
        );
      })}
    </Container>
  );
};

export default MentionUserDropdown;
