import { IntegrationType, IntegrationFullFragment, ThemeType } from '@cycle-app/graphql-codegen';
import { IntegrationCard, Button, Flex } from '@cycle-app/ui';
import { FC, useState } from 'react';
import { useTheme } from 'styled-components';

import { UninstallIntegrationModal } from 'src/app/Main/Settings/SettingsIntegrations/UninstallIntegrationModal';
import { CallRecordingIcons } from 'src/components/CallRecordingIcons';
import { GithubProjectDropdown } from 'src/components/GithubProjectDropdown/GithubProjectDropdown';
import { LearnMoreButton } from 'src/components/Integrations/LearnMoreButton';
import { MeetingButtonInstall } from 'src/components/MeetingButtonInstall';
import { ACTIVE_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { useInstallIntegration, useProductAddOn } from 'src/hooks';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';
import { useIsTablet } from 'src/reactives/responsive.reactive';
import { setUserPreferences } from 'src/reactives/userPreferences.reactive';
import { Integration, FrontEndIntegration } from 'src/types/integrations.types';

import {
  Container,
  SlotContainer,
  SlotActionBlock,
  StyledCloseIcon,
  HideButton,
} from './IntegrationItem.styles';
import { IntegrationItemMenu } from './IntegrationItemMenu';

type AlertModalData = {
  integrationType: IntegrationType;
  integrationId: string;
  action: 'remove' | 'update';
};

export type IntegrationItemProps = {
  className?: string;
  integration?: IntegrationFullFragment;
  integrationType: Integration;
  viewType?: 'block';
};

export const IntegrationItem: FC<React.PropsWithChildren<IntegrationItemProps>> = ({
  integration, integrationType, viewType, className,
}) => {
  const install = useInstallIntegration();
  const [alertModal, setAlertModal] = useState<AlertModalData | null>(null);
  const isTablet = useIsTablet();
  const theme = useTheme();
  const isDark = theme.themeType === ThemeType.Nightfall;
  const slackIntegration = useSlackIntegration();
  const { isEnabled: isMeetingsEnabled } = useProductAddOn('MEETINGS');
  const { isInstalled: isMettingsInstalled } = useMeetingsIntegration();
  const {
    request: requestAddon, isRequesting: isRequestingAddon,
  } = useProductAddOnRequest();

  const isSlack = integrationType === IntegrationType.Slack;
  const isChrome = integrationType === IntegrationType.Extension;
  const isZappier = integrationType === IntegrationType.Zapier;
  const isCycleApi = integrationType === FrontEndIntegration.CYCLE_API;
  const isCallRecording = integrationType === IntegrationType.Meeting;
  const isInstalled = integration?.provider?.id;

  return (
    <>
      <Container className={className}>
        <IntegrationCard
          description={integrationsDataMap[integrationType].description}
          logo={isCallRecording
            ? <CallRecordingIcons />
            : integrationsDataMap[integrationType].icon}
          title={integrationsDataMap[integrationType].label}
          slot={renderSlot()}
          actions={viewType === 'block' ? renderActionSlotBlock() : renderActionsSlot()}
          isSlack={isSlack && !integration?.provider}
          viewType={viewType}
        />
      </Container>
      <UninstallIntegrationModal
        hide={() => setAlertModal(null)}
        id={integration?.id || ''}
        isVisible={!!alertModal}
        type={integrationType}
      />
    </>
  );

  function renderSlot() {
    if (!integration?.provider) return null;

    switch (integrationType) {
      case IntegrationType.Github:
        return renderGithubSlot();
      default:
        return null;
    }
  }

  function renderActionSlotBlock() {
    if (
      viewType === 'block' &&
      !integration?.provider &&
      !isChrome && !isZappier && !isCycleApi
    ) {
      const isActive = ACTIVE_INTEGRATIONS.includes(integrationType);
      return (
        <SlotActionBlock>
          <LearnMoreButton type={integrationType} />
          <Button
            onClick={() => install(integrationType, integration)}
            variant={!isActive && isDark ? 'ternary' : 'primary'}
            disabled={!isActive}
          >
            {!isActive
              ? 'Coming soon'
              : `Install${!isTablet && isSlack ? ` ${integrationsDataMap[integrationType].label.toLocaleLowerCase()}` : ''}`}
          </Button>
        </SlotActionBlock>
      );
    }
    return renderActionsSlot();
  }

  function hideIntegration() {
    if (integrationType === IntegrationType.Slack) {
      setUserPreferences({
        slackHidden: true,
      });
    }
    if (integrationType === IntegrationType.Meeting) {
      setUserPreferences({
        callRecordingHidden: true,
      });
    }
  }

  function renderActionsSlot() {
    const showMenu =
      isCycleApi ||
      isChrome ||
      (
        isInstalled &&
        // In the design we dont have a menu when call recording is installed.
        // Settings can be opened from the "My meetings" widget.
        !isCallRecording
      );
    const hideButton = (
      <HideButton
        tooltip="Hide"
        tooltipPlacement="top"
        size="M"
        onClick={hideIntegration}
      >
        <StyledCloseIcon />
      </HideButton>
    );
    return (
      <>
        {isSlack && !isInstalled && (
          <Flex $align="center" $gap={8}>
            <Button
              onClick={() => install(integrationType, slackIntegration.integration)}
              variant="primary"
            >
              Install
            </Button>
            {hideButton}
          </Flex>
        )}
        {
          // Add-on enabled, but not installed for the user. Will show "Enable"
          isCallRecording && isMeetingsEnabled && !isMettingsInstalled && (
            <Flex $align="center" $gap={8}>
              <MeetingButtonInstall size="S" />
              {hideButton}
            </Flex>
          )
        }
        {
          isCallRecording && !isMeetingsEnabled && (
            <Flex $align="center" $gap={8}>
              <Button
                isLoading={isRequestingAddon}
                full
                size="S"
                onClick={() => requestAddon('MEETINGS')}
              >
                Install
              </Button>
              {hideButton}
            </Flex>
          )
        }
        {showMenu && (
          <IntegrationItemMenu
            email={integration?.provider?.__typename === 'Mail' ? integration.provider.mail : null}
            integrationId={integration?.id}
            integrationType={integrationType}
          />
        )}
      </>
    );
  }

  function renderGithubSlot() {
    return (
      <SlotContainer>
        <GithubProjectDropdown />
      </SlotContainer>
    );
  }
};
