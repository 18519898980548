import { IntegrationType } from '@cycle-app/graphql-codegen';
import { DropdownSelectProps } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { LearnMoreButton } from 'src/components/Integrations/LearnMoreButton';
import { ACTIVE_INTEGRATIONS, ADD_ON_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { useInstallIntegration } from 'src/hooks/useInstallIntegration';
import { executeIntegration } from 'src/utils/integrations.utils';

import { IntegrationItemProps } from './IntegrationItem/IntegrationItem';
import {
  AddButton,
  Dropdown,
  Info,
  IconContainer,
} from './SettingsIntegrations.styles';

interface Props extends Omit<DropdownSelectProps, 'options'> {
  integrations: IntegrationItemProps[];
  skipLinear?: boolean;
}

export const IntegrationsDropdown: FC<React.PropsWithChildren<Props>> = ({
  children, integrations, skipLinear = false, ...props
}) => {
  const install = useInstallIntegration();
  const actives = integrations.filter(i => ACTIVE_INTEGRATIONS.includes(i.integrationType) &&
    skipLinear &&
    i.integrationType !== IntegrationType.Linear);
  const inactives = integrations.filter(i => !ACTIVE_INTEGRATIONS.includes(i.integrationType) &&
    !ADD_ON_INTEGRATIONS.includes(i.integrationType) &&
    skipLinear &&
    i.integrationType !== IntegrationType.Linear);
  return (
    <Dropdown
      options={[
        ...actives.map(({
          integrationType, integration,
        }) => ({
          label: integrationsDataMap[integrationType].label,
          value: integrationType,
          icon: <IconContainer>{integrationsDataMap[integrationType].icon}</IconContainer>,
          onSelect: async () => {
            if ((integrationType === IntegrationType.Extension || integrationType === IntegrationType.Zapier)) {
              executeIntegration(integrationType, integration);
            } else {
              await install(integrationType, integration);
            }
          },
          end: <LearnMoreButton type={integrationType} />,
        })),
        ...inactives.map(({ integrationType }) => ({
          label: integrationsDataMap[integrationType].label,
          value: integrationType,
          icon: <IconContainer>{integrationsDataMap[integrationType].icon}</IconContainer>,
          disabled: true,
          end: <Info>Coming soon</Info>,
        })),
      ]}
      hideSearch
      placement="bottom-end"
      {...props}
    >
      {children || (
        <AddButton iconStart={<AddIcon />}>
          Add new
        </AddButton>
      )}
    </Dropdown>
  );
};
