import { GamificationStep, GamificationStepDetail } from 'src/types/gamification.types';

export const GAMIFICATION_STEPS: Record<GamificationStep, GamificationStepDetail> = {
  [GamificationStep.SetupAccount]: {
    label: 'Setup your account',
    order: 1,
  },
  [GamificationStep.CreateFeedback]: {
    label: 'Create your first feedback',
    order: 2,
  },
  [GamificationStep.ExtractInsight]: {
    label: 'Extract an insight',
    order: 3,
  },
  [GamificationStep.ProcessFeedback]: {
    label: 'Mark feedback as processed',
    order: 4,
  },
  [GamificationStep.BrowseView]: {
    label: 'Browse through two views',
    order: 5,
    link: 'https://help.cycle.app/core-concepts/navigate-views',
  },
  [GamificationStep.Search]: {
    label: 'Try the search',
    order: 6,
    link: 'https://help.cycle.app/core-concepts/home-and-search#search-with-command-+-k',
  },
  [GamificationStep.ChromeExtension]: {
    label: 'Install Chrome extension',
    order: 7,
    link: 'https://help.cycle.app/core-concepts/integrations/chrome-extension',
  },
  [GamificationStep.SyncCustomers]: {
    label: 'Sync customers',
    order: 8,
    link: 'https://help.cycle.app/core-concepts/customers#automatically-sync-and-merge-customers',
  },
  [GamificationStep.CreateView]: {
    label: 'Create your first view',
    order: 9,
    link: 'https://help.cycle.app/get-started/create-your-first-view',
  },
  [GamificationStep.BecomeExpert]: {
    label: 'Become a Cycle expert',
    order: 10,
    link: 'https://help.cycle.app/become-a-pro-cycler/custom-properties',
  },
};

export const GamificationAllSteps = [
  GamificationStep.SetupAccount,
  GamificationStep.CreateFeedback,
  GamificationStep.ExtractInsight,
  GamificationStep.ProcessFeedback,
  GamificationStep.BrowseView,
  GamificationStep.Search,
  GamificationStep.ChromeExtension,
  GamificationStep.SyncCustomers,
  GamificationStep.CreateView,
  GamificationStep.BecomeExpert,
];

export const GamificationCollaboratorSteps = [
  GamificationStep.SetupAccount,
  GamificationStep.CreateFeedback,
  GamificationStep.ExtractInsight,
  GamificationStep.ProcessFeedback,
  GamificationStep.BrowseView,
  GamificationStep.Search,
  GamificationStep.ChromeExtension,
];
