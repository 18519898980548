import { CycleLoader, Flex } from '@cycle-app/ui';
import { InfoIconOutline, SearchIcon, AddIcon, CloseIcon, BrushIcon } from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ReleaseCreateButton } from 'src/components/ReleaseCreateButton';
import { ReleaseNote } from 'src/components/ReleaseNote';
import { ReleasesEmpty } from 'src/components/ReleasesEmpty/';
import { EDITOR_MAX_CONTENT_WIDTH } from 'src/constants/editor.constants';
import { routing, PageId } from 'src/constants/routing.constant';
import { useReleasesContext } from 'src/contexts/releasesContext';
import { usePageId } from 'src/hooks/usePageId';
import { openCreateRelease, openReleasesProductTour } from 'src/reactives/releases.reactive';
import { useIsSmallScreen } from 'src/reactives/responsive.reactive';

import { INPUT_ONCHANGE_DEBOUNCE } from '../../constants/inputs.constant';
import { useFeatureFlag, useNavigate, useOptimizedBooleanState } from '../../hooks';
import { getPermission, setLimitationsModal, useGetPermission } from '../../reactives';
import { ReleaseDocTypes } from './ReleaseDocTypes';
import {
  PageContainer, Section, SectionContainer, Header, Title, Actions, Action, HeaderButton, SearchInput,
} from './ReleasesContent.styles';
import { ReleasesList } from './ReleasesList';

export const ReleasesContent: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isSmallScreen = useIsSmallScreen();
  const pageId = usePageId();
  const isLoading = useReleasesContext(ctx => ctx.isLoading);
  const releasesCount = useReleasesContext(ctx => ctx.releasesList.length);
  if (isLoading) return <CycleLoader />;
  if (releasesCount === 0) return <ReleasesEmpty />;
  return (
    <PageContainer>
      {(!isSmallScreen || pageId !== PageId.ReleaseNote) && <ReleasesSection>{children}</ReleasesSection>}
      <ReleaseNoteSection />
    </PageContainer>
  );
};

type SearchProps = {
  onSearch: (searchText: string) => void;
};

const Search = ({ onSearch }: SearchProps) => {
  const [searchText, setSearchText] = useState('');
  const [isSearching, {
    setTrueCallback: showSearch, setFalseCallback: hideSearch,
  }] = useOptimizedBooleanState(false);

  const handleSetSearchDebounced = useDebouncedCallback(onSearch, INPUT_ONCHANGE_DEBOUNCE);

  const reset = () => {
    onSearch('');
    setSearchText('');
    hideSearch();
  };

  return (
    <Flex>
      {isSearching && (
        <Flex $gap={4}>
          <SearchInput
            autoFocus
            onKeyDown={e => {
              if (e.key === 'Escape') {
                e.preventDefault();
                reset();
              }
            }}
            onChange={e => {
              setSearchText(e.currentTarget.value);
              handleSetSearchDebounced(e.currentTarget.value);
            }}
            value={searchText}
            iconBefore={<SearchIcon size={16} />}
            iconAfter={<CloseIcon size={12} />}
            onClickIcon={reset}
          />
        </Flex>
      )}
      {!isSearching && (
        <HeaderButton
          onClick={showSearch}
          tooltip="Search in releases"
          tooltipPlacement="bottom"
        >
          <SearchIcon size={16} />
        </HeaderButton>
      )}
    </Flex>
  );
};

const ReleasesSection: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [searchText, setSearchText] = useState('');
  const isChangelogEnabled = useFeatureFlag('changelog').isEnabled;
  const { navigate } = useNavigate();
  const { canUpdateRelease } = useGetPermission();
  return (
    <Section>
      <ErrorBoundary>
        <SectionContainer>
          <Header $borderless={isChangelogEnabled}>
            <Title>
              <h1>Releases</h1>

              {!isChangelogEnabled && (
                <Action
                  tooltip="Learn more"
                  tooltipPlacement="bottom"
                  onClick={openReleasesProductTour}
                >
                  <InfoIconOutline size={14} />
                </Action>
              )}
            </Title>

            {!isChangelogEnabled && (
              <Actions>
                <ReleaseDocTypes />
                <ReleaseCreateButton />
              </Actions>
            )}
            {isChangelogEnabled && (
              <Flex $gap={8}>
                <Search onSearch={setSearchText} />
                {canUpdateRelease && (
                  <HeaderButton
                    tooltip="Customize Changelog"
                    tooltipPlacement="bottom"
                    onClick={() => navigate(PageId.Changelog, undefined, {
                      changelogBuilder: {
                        previousPageId: PageId.Releases,
                      },
                    })}
                  >
                    <BrushIcon size={16} />
                  </HeaderButton>
                )}
                <HeaderButton
                  onClick={() => {
                    if (getPermission().canCreateRelease) {
                      openCreateRelease();
                    } else {
                      setLimitationsModal({ action: 'RELEASE_UPDATE' });
                    }
                  }}
                  tooltip="Create new release"
                  tooltipPlacement="bottom"
                >
                  <AddIcon size={16} />
                </HeaderButton>
              </Flex>
            )}
          </Header>

          <ReleasesList searchText={searchText}>
            {children}
          </ReleasesList>
        </SectionContainer>
      </ErrorBoundary>
    </Section>
  );
};

const ReleaseNoteSection = () => {
  const location = useLocation();
  const pageId = usePageId();
  const isSmallScreen = useIsSmallScreen();
  const isReleaseNoteOpen = [PageId.ReleaseNote, PageId.ReleaseDoc].includes(pageId);
  return (
    <AnimatePresence>
      <Switch location={location} key={String(isReleaseNoteOpen)}>
        <Route path={routing[PageId.ReleaseNote]}>
          <Section
            style={{
              flex: 'none',
              maxWidth: EDITOR_MAX_CONTENT_WIDTH,
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              visible: { width: isSmallScreen ? '100%' : '50%' },
              hidden: { width: 0 },
            }}
            transition={{ duration: isSmallScreen ? 0 : 0.2 }}
          >
            <ErrorBoundary>
              <ReleaseNote />
            </ErrorBoundary>
          </Section>
        </Route>
      </Switch>
    </AnimatePresence>
  );
};
