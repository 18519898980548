import { DocFullFragment } from '@cycle-app/graphql-codegen';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import React, { useCallback, useMemo, forwardRef } from 'react';
import { Placement } from 'tippy.js';
import { useDebouncedCallback } from 'use-debounce';

import DropdownLayer, { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { EditProperty, OnValueSelectedParams } from 'src/components/EditProperty';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { isAttributeScalar } from 'src/utils/attributes.util';

import {
  StyledDocAttributes,
  AddPropertyButton,
} from './DocPanelDocAttributes.styles';

interface Props {
  className?: string;
  doc: DocFullFragment;
  displayPrimaryAttributes?: boolean;
  dropdownPlacement?: Placement;
  layer?: Layer;
  limitSize?: boolean;
  readOnly?: boolean;
  readOnlyAttributeDefinitionIds?: string[];
  readOnlyStatus?: boolean;
  isDocTypeReadOnly?: boolean;
  showDoctype?: boolean;
  showDocId?: boolean;
  /**
   * show assignee in attributes
   */
  showAssignee?: boolean;
  showCustomer?: boolean;
  showStatus?: boolean;
  showLinear?: boolean;
  showAiState?: boolean;
  hideStatusLabel?: boolean;
  hideIncompatibleValues?: boolean;
  hiddenAttributeDefinitionIds?: string[];
  placement?: DropdownLayerProps['placement'];
  /**
   * if defined, show assignee in edit property dropdown choice
   */
  onAssigneePropertyUpdated?: (userId: string | null, notCompatible: boolean) => void;
  disableCustomerCreation?: boolean;
  compatibleStatusIds?: string[];
  showCustomAttributes?: boolean;
  context?: 'doc-panel' | 'doc-item';
  showLinearAutoCreate?: boolean;
  onWithLinearChange?: (checked: boolean) => void;
  withLinearChecked?: boolean;
  editor?: Editor;
}
const DocPanelDocAttributes = forwardRef<HTMLDivElement, Props>(({
  className,
  doc,
  displayPrimaryAttributes,
  dropdownPlacement,
  layer = Layer.Dropdown,
  limitSize,
  readOnly,
  readOnlyStatus,
  readOnlyAttributeDefinitionIds,
  isDocTypeReadOnly = false,
  showDoctype,
  showDocId = true,
  showAssignee,
  showCustomer,
  showStatus = true,
  showLinear = true,
  showAiState = true,
  hideStatusLabel,
  hideIncompatibleValues,
  hiddenAttributeDefinitionIds,
  placement,
  onAssigneePropertyUpdated,
  disableCustomerCreation,
  compatibleStatusIds,
  showCustomAttributes,
  context = 'doc-panel',
  showLinearAutoCreate,
  onWithLinearChange,
  withLinearChecked,
  editor,
}, ref) => {
  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const changeDocAttributeValueDebounced = useDebouncedCallback(changeDocAttributeValue, INPUT_ONCHANGE_DEBOUNCE);

  const [isEditPropertyShown, {
    setFalseCallback: hideEditProperty,
    toggleCallback: toggleShowEditProperty,
  }] = useOptimizedBooleanState(false);

  const onPropertyUpdated = useCallback(async ({
    attributeDefinition,
    propertyValue,
    isValueRemoved,
    notCompatible,
  }: OnValueSelectedParams) => {
    if (!propertyValue) return;
    if (isAttributeScalar(attributeDefinition)) {
      await changeDocAttributeValueDebounced({
        doc,
        attributeDefinition,
        value: propertyValue,
      });
    } else if (isValueRemoved) {
      await removeDocAttributeValue({
        doc,
        attributeDefinition,
        valueId: propertyValue,
        notCompatible,
      });
    } else {
      await changeDocAttributeValue({
        doc,
        attributeDefinition,
        value: propertyValue,
        notCompatible,
      });
    }
  }, [changeDocAttributeValue, changeDocAttributeValueDebounced, doc, removeDocAttributeValue]);

  const undefinedAttributeDefinitions = useMemo(() => {
    const docDefinedAttributeIds = nodeToArray(doc.attributes).map(a => a.definition.id);
    const docPotentialAttributes = nodeToArray(doc.doctype.attributeDefinitions)
      .filter(a => a && !docDefinedAttributeIds.includes(a.id) && !hiddenAttributeDefinitionIds?.includes(a.id));
    return docPotentialAttributes;
  }, [doc.attributes, doc.doctype.attributeDefinitions, hiddenAttributeDefinitionIds]);

  return (
    <StyledDocAttributes
      ref={ref}
      className={className}
      context={context}
      doc={doc}
      displayPrimaryAttributes={displayPrimaryAttributes}
      dropdownPlacement={dropdownPlacement}
      layer={layer}
      limitSize={limitSize}
      readOnly={readOnly}
      readOnlyAttributeDefinitionIds={readOnlyAttributeDefinitionIds}
      readOnlyStatus={readOnlyStatus}
      showStatus={showStatus}
      showDocType={showDoctype}
      showAssignee={showAssignee}
      showCustomer={showCustomer}
      showLinear={showLinear}
      showAiState={showAiState}
      isDocTypeReadOnly={isDocTypeReadOnly}
      enableStatusShortcut
      hideStatusLabel={hideStatusLabel}
      hideIncompatibleValues={hideIncompatibleValues}
      disableCustomerCreation={disableCustomerCreation}
      compatibleStatusIds={compatibleStatusIds}
      showCustomAttributes={showCustomAttributes}
      showDocId={showDocId}
      showLinearAutoCreate={showLinearAutoCreate}
      onWithLinearChange={onWithLinearChange}
      withLinearChecked={withLinearChecked}
      editor={editor}
    >
      {(undefinedAttributeDefinitions.length > 0 || (showAssignee && onAssigneePropertyUpdated)) && (
        <DropdownLayer
          layer={layer}
          visible={isEditPropertyShown}
          hide={hideEditProperty}
          placement={placement}
          content={(
            <EditProperty
              docId={doc.id}
              possibleAttributes={undefinedAttributeDefinitions}
              onValueUpdated={onPropertyUpdated}
              hideIncompatibleValues={hideIncompatibleValues}
              onAssigneeUpdated={onAssigneePropertyUpdated}
              compatibleStatusIds={compatibleStatusIds}
            />
          )}
        >
          <AddPropertyButton
            tooltip="Add property"
            tooltipPlacement="top"
            onClick={toggleShowEditProperty}
            forceFocus={isEditPropertyShown}
            size="L"
          >
            <AddIcon />
          </AddPropertyButton>
        </DropdownLayer>
      )}
    </StyledDocAttributes>
  );
});

export default DocPanelDocAttributes;
