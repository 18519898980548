import { TabPanel } from '@reach/tabs';

import { useIsRoadmapsEnabled } from 'src/hooks';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { isBuiltIn, isFeedback } from 'src/utils/docType.util';

import { Workflows } from '../SettingsWorkflows';
import { DocTypeProperties } from './DocTypeProperties';
import {
  HierarchyContainer,
  Tabs, TabList, Tab, TabPanels,
} from './SettingsDocType.styles';
import { SettingsDoctypeHierarchy } from './SettingsDoctypeHierarchy/SettingsDoctypeHierarchy';
import { SettingsDoctypeTemplate } from './SettingsDoctypeTemplate';

export const DocTypeTabs = ({
  doctypeId, isDraft = false, padding,
}: {
  doctypeId: string;
  isDraft?: boolean;
  padding?: number;
}) => {
  const { docTypes } = useGetDocTypes();
  const { isEnabled: isStatusEnabled } = useFeatureFlag('status');
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const doctype = docTypes[doctypeId];
  if (!doctype) return null;

  return (
    <Tabs>
      <TabList $padding={padding}>
        {!isBuiltIn(doctype) && <Tab>Content template</Tab>}
        <Tab>Properties</Tab>
        {isStatusEnabled && isRoadmapsEnabled && <Tab>Statuses</Tab>}
        {!isBuiltIn(doctype) && isRoadmapsEnabled && <Tab>Hierarchy</Tab>}
      </TabList>

      <TabPanels>
        {!isBuiltIn(doctype) && (
          <TabPanel>
            <SettingsDoctypeTemplate
              isBuiltIn={isBuiltIn(doctype)}
              isFeedback={isFeedback(doctype)}
              docTypeId={doctype.id}
              templateId={doctype.template?.id}
              templateTitle={doctype.template?.title}
            />
          </TabPanel>
        )}

        <TabPanel>
          <DocTypeProperties doctypeId={doctypeId} isDraft={isDraft} />
        </TabPanel>

        {isStatusEnabled && isRoadmapsEnabled && (
          <TabPanel>
            <Workflows docType={doctype} hideBorder />
          </TabPanel>
        )}

        {!isBuiltIn(doctype) && isRoadmapsEnabled && (
          <TabPanel>
            <HierarchyContainer>
              <SettingsDoctypeHierarchy docTypeId={doctype.id} />
            </HierarchyContainer>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};
