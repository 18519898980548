const CLOUDINARY_URL_REGEX = /https:\/\/res\.cloudinary\.com\/([^/]+)\/video\/upload\//;

export const getAppCloudNameFromUrl = (
  url: string,
): string | null => {
  const regex = CLOUDINARY_URL_REGEX;
  const match = url.match(regex);

  return match?.[1] || null;
};
