import { RessourceType } from '@cycle-app/graphql-codegen';
import { Button, Input, CheckboxInput } from '@cycle-app/ui';
import { Label } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { urlRegex } from '@cycle-app/utilities';
import { useForm } from 'react-hook-form';

import { useWebhook } from 'src/hooks/api/queries/api/useWebhooks';
import { closeEditWebhookModal } from 'src/reactives/settingsApi.reactive';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { KeyInput, CopyButton } from '../SettingsAPI.styles';
import { useCreateWebhook } from './useCreateWebhook';
import { useUpdateWebhook } from './useUpdateWebhook';
import { Form, FormFooter, Actions, FieldSet } from './WebhooksSection.styles';

type FormData = {
  label: string;
  url: string;
  ressources: RessourceType[];
};

export const WebhookForm = ({ webhookId }: { webhookId: string }) => {
  const { webhook } = useWebhook(webhookId);
  const createWebhook = useCreateWebhook();
  const updateWebhook = useUpdateWebhook();

  const {
    handleSubmit, register, formState,
  } = useForm<FormData>({
    defaultValues: {
      label: webhook?.label ?? '',
      url: webhook?.url ?? '',
      ressources: webhook?.ressources ?? [],
    },
  });

  return (
    <Form
      onSubmit={handleSubmit(variables => {
        closeEditWebhookModal();
        if (!webhookId) return createWebhook(variables);
        if (!webhook) return null;
        return updateWebhook(variables, webhook);
      })}
    >
      <Input
        label="Label"
        placeholder="My webhook label"
        autoFocus
        autoComplete="off"
        error={formState.errors.label?.message}
        {...register('label', {
          required: 'Label is required',
        })}
      />

      <Input
        label="URL"
        placeholder="https://mywebhookurl.com"
        error={formState.errors.url?.message}
        {...register('url', {
          required: 'URL is required',
          pattern: {
            value: urlRegex,
            message: 'URL must be a valid URL',
          },
        })}
      />

      <div>
        <Label>Resources</Label>
        <FieldSet>
          <CheckboxInput
            id="ressourcesComment"
            label="Comment created"
            value={RessourceType.CommentCreated}
            {...register('ressources')}
          />
          <CheckboxInput
            id="ressourcesDoc"
            label="Doc created"
            value={RessourceType.DocCreated}
            {...register('ressources')}
          />
          <CheckboxInput
            id="ressourcesStatus"
            label="Status changed"
            value={RessourceType.StatusChanged}
            {...register('ressources')}
          />
          <CheckboxInput
            id="ressourcesValue"
            label="Value changed"
            value={RessourceType.ValueChanged}
            {...register('ressources')}
          />
        </FieldSet>
      </div>

      {webhook?.secret && (
        <KeyInput
          label="API Key"
          value={webhook.secret}
          onChange={() => {}}
          iconAfter={<CopyButton size={16} />}
          onClickIcon={() => copyToClipboard({
            text: webhook.secret,
            notification: 'API key copied to clipboard',
          })}
        />
      )}

      <FormFooter>
        <Actions>
          <Button size="M" variant="secondary" onClick={closeEditWebhookModal}>
            Cancel
          </Button>
          <Button size="M" type="submit" disabled={!formState.isDirty}>
            {webhookId ? 'Update' : 'Create'}
          </Button>
        </Actions>
      </FormFooter>
    </Form>
  );
};
