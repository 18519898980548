import { typo, Flex, SelectPanel } from '@cycle-app/ui';
import styled from 'styled-components';

export const Title = styled.div`
  ${typo.body500}
  text-align: center;
`;

export const StyledSelectPanel = styled(SelectPanel)`
  padding-bottom: 16px;
`;

export const List = styled(Flex)`
  ${typo.body500}
  color: ${p => p.theme.colors.text.disabled};
  cursor: pointer;
`;
