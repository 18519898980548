import { ActionButton } from '@cycle-app/ui';
import { TrashIcon } from '@cycle-app/ui/icons';

import { useWebhooks } from 'src/hooks/api/queries/api/useWebhooks';
import { setSettingsApi } from 'src/reactives/settingsApi.reactive';

import { ItemList, ItemCard, ItemCardSkeleton, ItemTitle } from '../SettingsAPI.styles';

export const WebhooksList = () => {
  const query = useWebhooks();
  if (!query.loading && query.webhooks.length === 0) return null;
  return (
    <ItemList>
      {query.loading && (
        <>
          <ItemCardSkeleton />
          <ItemCardSkeleton />
        </>
      )}

      {query.webhooks.map(webhook => (
        <ItemCard
          key={webhook.id}
          onClick={() => setSettingsApi({ openedEditWebhookpModal: webhook.id })}
        >
          <ItemTitle>{webhook.label || webhook.url}</ItemTitle>

          <ActionButton
            tooltip={`Remove ${webhook.label || 'this webhook'}`}
            onClick={e => {
              e.stopPropagation();
              setSettingsApi({ openedRemoveWebhookModal: webhook.id });
            }}
          >
            <TrashIcon size={16} />
          </ActionButton>
        </ItemCard>
      ))}
    </ItemList>
  );
};
