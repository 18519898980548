import { Language, Tone } from '@cycle-app/graphql-codegen';
import {
  AiCompleteIcon,
  AiShortenIcon,
  AiExtendIcon,
  AiRephraseIcon,
  AiSummarizeIcon,
  AiSimplifyIcon,
  AiSpellingIcon,
  SmileyIcon,
  AiToneIcon,
  AiTranslateIcon,
} from '@cycle-app/ui/icons';
import { ReactNode, MutableRefObject, useRef, useEffect } from 'react';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { TONE_OPTIONS } from 'src/constants/editor.constants';
import { LANGUAGES_OPTIONS } from 'src/constants/languages.constants';
import { useEditorAi, useEditorAiValue } from 'src/reactives';
import { Layer } from 'src/types/layers.types';

import { ButtonsContainer, Button } from './EditorAiMenu.styles';

import type { EditorAiAction } from 'src/types/editor.types';

const mappingAiButton: Record<Exclude<EditorAiAction, 'translate' | 'adjust-tone' | 'prompt'>, { label: string; icon: ReactNode }> = {
  complete: {
    label: 'Continue writing',
    icon: <AiCompleteIcon />,
  },
  shorten: {
    label: 'Shorten',
    icon: <AiShortenIcon />,
  },
  extend: {
    label: 'Extend',
    icon: <AiExtendIcon />,
  },
  rephrase: {
    label: 'Rephrase',
    icon: <AiRephraseIcon />,
  },
  summarize: {
    label: 'Summarize',
    icon: <AiSummarizeIcon />,
  },
  simplify: {
    label: 'Simplify',
    icon: <AiSimplifyIcon />,
  },
  'fix-spelling-and-grammar': {
    label: 'Spelling & Grammar',
    icon: <AiSpellingIcon />,
  },
  emojify: {
    label: 'Emojify',
    icon: <SmileyIcon />,
  },
};

const mappingAiDropdown: Record<Extract<EditorAiAction, 'translate' | 'adjust-tone'>, { label: string; icon: ReactNode }> = {
  translate: {
    label: 'Translate',
    icon: <AiTranslateIcon />,
  },
  'adjust-tone': {
    label: 'Tone of Voice',
    icon: <AiToneIcon />,
  },
};

type EditorAiActionButtonsProps = {
  askAi: (
    actionParam: EditorAiAction,
    data?: Language | Tone | string
  ) => Promise<void>;
  lastDataUsed: MutableRefObject<Language | Tone | null>;
};

export const EditorAiActionButtons = ({
  askAi, lastDataUsed,
}: EditorAiActionButtonsProps) => {
  const [{
    suggestion, isPromptCommand, isLanguageDropdownOpen, isToneDropdownOpen,
  }, setEditorAi] = useEditorAi();

  if (isPromptCommand || !!suggestion) return null;

  return (
    <ButtonsContainer>
      {Object.keys(mappingAiButton).map(aiAction => (
        <AiActionButton
          key={aiAction}
          actionType={aiAction as AiActionButtonProps['actionType']}
          onClick={async () => { await askAi(aiAction as AiActionButtonProps['actionType']); }}
        />
      ))}
      {Object.keys(mappingAiDropdown).map(aiDropdown => (
        <AiActionDropdown
          key={aiDropdown}
          actionType={aiDropdown as AiActionDropdownProps['actionType']}
          isOpen={aiDropdown === 'translate' ? isLanguageDropdownOpen : isToneDropdownOpen}
          onSelect={async (newValue) => {
            // eslint-disable-next-line no-param-reassign
            lastDataUsed.current = newValue as Language | Tone;
            if (aiDropdown === 'translate') {
              setEditorAi({ isLanguageDropdownOpen: false });
              await askAi(aiDropdown as AiActionDropdownProps['actionType'], newValue as Language);
              return;
            }
            setEditorAi({ isToneDropdownOpen: false });
            await askAi(aiDropdown as AiActionDropdownProps['actionType'], newValue as Tone);
          }}
          onShow={() => {
            if (aiDropdown === 'translate') {
              setEditorAi({ isLanguageDropdownOpen: true });
              return;
            }
            setEditorAi({ isToneDropdownOpen: true });
          }}
          onHide={() => {
            if (aiDropdown === 'translate') {
              setEditorAi({ isLanguageDropdownOpen: false });
              return;
            }
            setEditorAi({ isToneDropdownOpen: false });
          }}
        />
      ))}
    </ButtonsContainer>
  );
};

type AiActionButtonProps = {
  actionType: Exclude<EditorAiAction, 'translate' | 'adjust-tone' | 'prompt'>;
  onClick: VoidFunction;
};

const AiActionButton = ({
  actionType, onClick,
}: AiActionButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const {
    action, isLoading,
  } = useEditorAiValue();

  useEffect(() => {
    if (!ref.current) return () => { };
    ref.current.addEventListener('click', onClick);
    return () => {
      if (!ref.current) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current.removeEventListener('click', onClick);
    };
    /**
     * Volunteer mount and unmount behavior
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Button
      ref={ref}
      isLoading={isLoading && actionType === action}
      disabled={isLoading}
    >
      {mappingAiButton[actionType].icon}
      {mappingAiButton[actionType].label}
    </Button>
  );
};

type AiActionDropdownProps = {
  actionType: Extract<EditorAiAction, 'translate' | 'adjust-tone'>;
  isOpen: boolean;
  onSelect: (newValue: string) => void;
  onShow: VoidFunction;
  onHide: VoidFunction;
};

const AiActionDropdown = ({
  actionType, isOpen, onSelect, onHide, onShow,
}: AiActionDropdownProps) => {
  const {
    isLoading, action,
  } = useEditorAiValue();

  return (
    <DropdownSelectLayer
      layer={Layer.DropdownModalZ4}
      withPortal
      visible={isOpen}
      hide={onHide}
      options={actionType === 'translate' ? LANGUAGES_OPTIONS : TONE_OPTIONS}
      onChange={async (selectedOption) => {
        if (actionType === 'translate') {
          onSelect(selectedOption.value as Language);
          return;
        }
        onSelect(selectedOption.value as Tone);
      }}
      placement="bottom-end"
      disabled={isLoading}
    >
      <Button
        forceFocus={isOpen}
        isLoading={isLoading && actionType === action}
        disabled={isLoading}
        onClick={onShow}
      >
        {mappingAiDropdown[actionType].icon}
        {mappingAiDropdown[actionType].label}
      </Button>
    </DropdownSelectLayer>
  );
};
