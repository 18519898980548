import { useQuery } from '@apollo/client';
import { ReleaseBaseDocument, ReleasePublicStatus } from '@cycle-app/graphql-codegen';

import { extract } from 'src/types/graphql.types';

import { useFeatureFlag } from '../useFeatureFlag';

export const useRelease = (releaseId?: string | null) => {
  const query = useQuery(ReleaseBaseDocument, {
    skip: !releaseId,
    variables: {
      id: releaseId as string,
    },
  });
  const isChangelogEnabled = useFeatureFlag('changelog').isEnabled;
  const release = extract('Release', query.data?.node);
  return {
    isReleaseLoading: query.loading,
    isReadonly: isChangelogEnabled && release?.publicStatus === ReleasePublicStatus.Published,
    release,
  };
};
