import { useQuery } from '@apollo/client';
import { AccessTokensDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useMaybeMeV2 } from 'src/hooks/api/useMe';
import { extract } from 'src/types/graphql.types';

const useAccessTokensQuery = () => {
  const { me } = useMaybeMeV2();
  return useQuery(AccessTokensDocument, {
    skip: !me?.id,
    variables: {
      userId: me?.id as string,
    },
  });
};

export const useAccessTokens = () => {
  const query = useAccessTokensQuery();
  return {
    ...query,
    accessTokens: nodeToArray(extract('Me', query.data?.node)?.accessTokens),
  };
};

export const useAccessToken = (tokenId: string) => {
  const query = useAccessTokensQuery();
  return {
    ...query,
    accessToken: extract('Me', query.data?.node)?.accessTokens.edges.find(edge => edge.node.id === tokenId)?.node,
  };
};
