import { StatusCategory } from '@cycle-app/graphql-codegen';
import { ComponentPropsWithRef, forwardRef } from 'react';

import { GroupCanceledIcon, GroupCompletedIcon, GroupStartedIcon, GroupNotStartedIcon } from '../../icons';
import { Container } from './StatusIcon.styles';

export type GroupStatusIconProps = ComponentPropsWithRef<'div'> & {
  category: StatusCategory;
  withBackground?: boolean;
  size?: 16 | 20;
};

export const StatusIcon = forwardRef<HTMLDivElement, GroupStatusIconProps>(({
  category, withBackground, size = 20, ...props
}, ref) => {
  const iconSize = size === 20 ? 14 : 12;
  return (
    <Container
      ref={ref}
      $category={category}
      $withBackground={withBackground}
      $size={size}
      {...props}
    >
      {category === StatusCategory.Canceled && <GroupCanceledIcon size={iconSize} />}
      {category === StatusCategory.Completed && <GroupCompletedIcon size={iconSize} />}
      {category === StatusCategory.NotStarted && <GroupNotStartedIcon size={iconSize} />}
      {category === StatusCategory.Started && <GroupStartedIcon size={iconSize} />}
    </Container>
  );
});
