import { AskIcon } from 'src/components/Ask';
import { Text, Truncate } from 'src/utils/css.styles';

import { Top } from './CommandAction.styles';

type Props = {
  search: string;
};

export const AskAction = ({ search }: Props) => {
  return (
    <Top>
      <AskIcon />
      <Truncate>
        {'Ask Cycle for '}
        <Text as="span" $font="500">
          {search}
        </Text>
      </Truncate>
    </Top>
  );
};
