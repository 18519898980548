import styled from 'styled-components';

import { caption400, caption500 } from '../../theme/typo';
import { truncate } from '../../utils/styles.util';
import { transitionCss } from '../../utils/transition.util';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  color: ${p => p.theme.colors.components.FreePlan.default.text.primary};
  background: ${p => p.theme.colors.components.FreePlan.default.bg};

  ${transitionCss(['background', 'color'])}

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const Text = styled.div`
  ${caption400}
  ${truncate}
  max-width: 100%;
  flex: none;
`;

export const TextSecondary = styled(Text)`
  color: ${p => p.theme.colors.components.FreePlan.default.text.secondary};
`;

export const Title = styled(Text)`
  ${caption500}
  margin-bottom: 8px;
`;
