import { ExportDocCustomersDocument } from '@cycle-app/graphql-codegen';

import DialogModal from 'src/components/DialogModal/DialogModal';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { hideExportQuotesModal, useGetExportQuotesModal } from 'src/reactives/docPanel.reactive';
import { addToaster } from 'src/utils/toasters.utils';

export const ExportQuotesModal = ({ docId }: { docId?: string }) => {
  const { isVisible } = useGetExportQuotesModal();
  if (!isVisible || !docId) return null;
  return <ExportQuotesModalVisible docId={docId} onHide={hideExportQuotesModal} />;
};

const ExportQuotesModalVisible = ({
  docId,
  onHide,
}: {
  docId: string;
  onHide: VoidFunction;
}) => {
  const [exportDocCustomers, { loading }] = useSafeMutation(ExportDocCustomersDocument, { variables: { docId } });

  return (
    <DialogModal
      hide={onHide}
      type="default"
      title="Export quotes in CSV"
      onConfirm={async () => {
        await exportDocCustomers();
        addToaster({
          title: "CSV export is in progress, you'll receive an email very soon",
          icon: '📬',
        });
      }}
      info={(
        <>
          Are you sure you want to export quotes into CSV?
          <br />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You'll receive an email from Cycle when it's ready.
        </>
      )}
      width="auto"
      loading={loading}
      variantSubmitButton="primary"
      variantCancelButton="secondary"
      confirmLabel="Export"
    />
  );
};
