import { Reference } from '@apollo/client';
import { RevokeAccessTokenDocument } from '@cycle-app/graphql-codegen';

import { useMaybeMeV2 } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useRevokeAccessToken = (tokenId: string) => {
  const { me } = useMaybeMeV2();
  const [deleteClient] = useSafeMutation(RevokeAccessTokenDocument);
  return () => deleteClient({
    variables: { id: tokenId },
    optimisticResponse: { revokeAccessToken: { id: tokenId } },
    update: (cache, { data }) => {
      if (!me || !data?.revokeAccessToken) return;
      cache.modify({
        id: cache.identify(me),
        fields: {
          accessTokens: (refs, { readField }) => ({
            ...refs,
            edges: refs.edges.filter(
              (edge: { node: Reference }) => readField('id', edge.node) !== data.revokeAccessToken?.id,
            ),
          }),
        },
      });
    },
  });
};
