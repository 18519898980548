import { AddIcon } from '@cycle-app/ui/icons';

import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openCreateRelease } from 'src/reactives/releases.reactive';

import { DefaultButton, SecondaryButton } from './ReleaseCreateButton.styles';

type ReleaseCreateButtonProps = {
  secondary?: boolean;
  label?: string;
  className?: string;
};

export const ReleaseCreateButton = ({
  secondary = false, label, className,
}: ReleaseCreateButtonProps) => {
  const { canCreateRelease } = useGetPermission();
  const Button = secondary ? SecondaryButton : DefaultButton;
  return (
    <Button
      className={className}
      iconStart={<AddIcon size={14} />}
      onClick={canCreateRelease ? openCreateRelease : () => setLimitationsModal({ action: 'RELEASE_UPDATE' })}
    >
      {label || 'New release'}
    </Button>
  );
};
