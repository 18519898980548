export enum Events {
  AttributeCreated = 'Attribute - Created',
  AttributeDeleted = 'Attribute - Deleted',
  AttributeDoctypeCreated = 'Attribute doctype - Created',
  AttributeDoctypeDeleted = 'Attribute doctype - Deleted',
  AttributeUpdated = 'Attribute - Updated',
  AttributeValueCreated = 'Attribute value - Created',
  AttributeValueDeleted = 'Attribute value - Deleted',
  AttributeValueUpdated = 'Attribute value - Updated',
  BillingUpgradeViewed = 'Billing - Upgrade Viewed',
  BoardCreated = 'Board - Created',
  BoardDeleted = 'Board - Deleted', // @TODO
  BoardSaved = 'Board - Saved',
  BoardUpdated = 'Board - Updated',
  BoardViewed = 'Board - Viewed',
  CommentSent = 'Comment - Sent',
  CustomerVoiceDashboardLeft = 'Customer Voice - Dashboard Left',
  CustomerVoiceDashboardViewed = 'Customer Voice - Dashboard Viewed',
  DemoVideoClosed = 'Demo - Video Closed',
  DemoVideoOpened = 'Demo - Video Opened',
  DocBulkDeleted = 'Doc - Bulk deleted',
  DocBulkUpdated = 'Doc - Bulk updated',
  DocBulkUrlCopied = 'Doc - Bulk url copied',
  DocDeleted = 'Doc - Deleted',
  DocMention = 'Doc - Mention',
  DocPreview = 'Doc - Preview',
  DocShared = 'Doc - Shared',
  DoctypeAttributeCreated = 'Doctype attribute - Created',
  DoctypeAttributeDeleted = 'Doctype attribute - Deleted',
  DocTypeCreated = 'Doc type - Created',
  DocTypeDeleted = 'Doc type - Deleted',
  DocTypeUpdated = 'Doc type - Updated',
  FileDropped = 'File - Dropped',
  FileUploaded = 'File - Uploaded',
  FileUploadFailed = 'File - Upload failed',
  GamificationChecklistCompleted = 'Gamification - Checklist Completed',
  HierarchyAdded = 'Hierarchy - Added',
  IntegrationInstalled = 'Integration - Installed',
  IntegrationIssueCreated = 'Integration - Issue Created',
  IntegrationIssueMentioned = 'Integration - Issue Mentioned',
  IntegrationIssuesSearched = 'Integration - Issues Searched',
  IntegrationRemoved = 'Integration - Removed',
  IntegrationUpdated = 'Integration - Updated',
  LimitationOccurred = 'Limitation - Occurred',
  LinkEmbeded = 'Link - Embeded',
  ProductUpdated = 'Product - Updated',
  SectionCreated = 'Section - Created',
  SectionDeleted = 'Section - Deleted', // @TODO
  SectionUpdated = 'Section - Updated', // @TODO
  SwimlaneAdded = 'Swimlane - Added',
  ThreadResolved = 'Thread - resolved',
  ThreadStarted = 'Thread - started',
  UserAccountUpdated = 'User - Account Update',
  UserDeleted = 'User - Deleted',
  UserInvited = 'User - Invited',
}

export enum Sources {
  Board = 'board',
  DocPanel = 'doc-panel',
  Sidebar = 'sidebar',
}

export enum Objects {
  BoardDescription = 'board-description',
  BoardEmoji = 'board-emoji',
  BoardName = 'board-name',
  DocAssignee = 'doc-assignee',
  DocAttributeValue = 'doc-attribute-value',
  DocBoardPosition = 'doc-board-position',
  DocDescription = 'doc-description',
  DocTitle = 'doc-title',
  DocType = 'doc-type',
  ProductKey = 'product-key',
  ProductLogo = 'product-logo',
  ProductName = 'product-name',
  ToolbarDisplayProperty = 'toolbar-display-property',
  ToolbarFilter = 'toolbar-filter',
  ToolbarGroupby = 'toolbar-groupby',
  ToolbarHideGroup = 'toolbar-hide-group',
  ToolbarViewType = 'toobar-viewtype',
  UserAvatar = 'user-avatar',
  UserColor = 'user-name', // @TODO
  UserName = 'user-name',
  UserTheme = 'user-theme', // @TODO
  GitHubProject = 'github-project',
}

export enum Methods {
  Shortcuts = 'shortcuts',
  UI = 'ui',
  /**
   * A `/` command inside editor
   */
  SlashCommand = 'slash-command',
  /**
   * A modal from a slach command
   */
  SlashCommandModal = 'slash-command-modal',
  Paste = 'paste',
}
