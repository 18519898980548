import { Tooltip, Flex } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';

import { useProductAddOn } from 'src/hooks/useProductAddOns';

export const UnlimitedAiQueries = () => {
  const {
    isEnabled, isLoading,
  } = useProductAddOn('UNLIMITED_AI_QUERIES');

  if (isLoading || !isEnabled) return null;

  return (
    <Flex $gap={8}>
      Unlimited AI queries
      <Tooltip
        withWrapper={false}
        placement="bottom-end"
        content={'The "Unlimited AI" add-on is activated. You can enjoy an unlimited use of Cycle’s AI features.'}
      >
        <InfoIconOutline />
      </Tooltip>
    </Flex>
  );
};
