import { CancelSubscriptionDocument } from '@cycle-app/graphql-codegen';
import { Button, Input, CheckboxInput } from '@cycle-app/ui';
import { WarningIcon, CloseIcon } from '@cycle-app/ui/icons';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  Title, Actions, PortalModalStyled, IconContainer, Header, CloseButtonStyled,
} from 'src/components/DialogModal/DialogModal.styles';
import { useProduct, useSafeMutation } from 'src/hooks';
import { addToaster } from 'src/utils/toasters.utils';

import { Form, Footer, CancelButton } from './CancelSubscription.styles';

type FormData = {
  reason: string;
  confirm: boolean;
};

export const CancelSubscription = () => {
  const { product } = useProduct();
  const [visible, setVisible] = useState(false);
  const [cancelSubscription] = useSafeMutation(CancelSubscriptionDocument);

  const {
    register, handleSubmit, reset, formState: {
      errors, isSubmitting, isDirty, isValid,
    },
  } = useForm<FormData>({
    defaultValues: {
      reason: '',
      confirm: false,
    },
  });

  const reasonField = register('reason', {
    required: 'This field is required',
  });

  const confirmField = register('confirm', {
    required: true,
  });

  const hide = useCallback(() => {
    reset();
    setVisible(false);
  }, [reset]);

  const submit = useCallback(async (data: { reason: string | null | undefined }) => {
    if (!product) return null;

    const result = await cancelSubscription({
      variables: {
        productId: product.id,
        reason: data.reason,
      },
    });

    if (result?.data?.cancelSubscription) {
      hide();
      addToaster({
        title: 'Successfully cancelled',
        message: 'Your subscription was successfully cancelled',
      });
    }

    return result;
  }, [cancelSubscription, hide, product]);

  return (
    <>
      <CancelButton
        onClick={() => setVisible(true)}
        iconStart={<CloseIcon size={10} />}
      >
        Cancel my subscription
      </CancelButton>

      {visible && (

        <PortalModalStyled
          animate
          width="auto"
          hide={hide}
        >
          <Header>
            <Title>
              <IconContainer>
                <WarningIcon />
              </IconContainer>
              Cancel my subscription
            </Title>
            <CloseButtonStyled size="L" onClick={hide}>
              <CloseIcon />
            </CloseButtonStyled>
          </Header>
          <Form onSubmit={handleSubmit(submit)}>
            {product?.name && (
              <p>
                {`You will no longer have access to your ${product.name} workspace.`}
              </p>
            )}

            <Input
              label="Why are you cancelling?"
              placeholder="Tell us why..."
              error={errors.reason?.message}
              {...reasonField}
            />

            <Footer>
              <CheckboxInput
                id="dialog-confirm-checkbox"
                label="Yes, cancel subscription"
                {...confirmField}
              />

              <Actions>
                <Button
                  variant="warning"
                  size="M"
                  isLoading={isSubmitting}
                  disabled={!isDirty || !isValid}
                  type="submit"
                >
                  Confirm
                </Button>
              </Actions>
            </Footer>
          </Form>
        </PortalModalStyled>
      )}
    </>
  );
};
