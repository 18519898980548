import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const BagIcon = forwardRef<SVGElement, SvgProps>((props, ref) => (
  <Svg
    {...props}
    ref={ref}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    data-no-fill
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      // eslint-disable-next-line max-len
      d="M15.75 9.75H9M9 9.75H2.25M9 9.75L9.00165 12M15.7516 9V13.5C15.7516 14.3284 15.0801 15 14.2516 15H3.75165C2.92322 15 2.25165 14.3284 2.25165 13.5V9M15.75 9V7.5C15.75 6.67157 15.0784 6 14.25 6H3.75C2.92157 6 2.25 6.67157 2.25 7.5V9M6.00165 6V4.125C6.00165 3.50368 6.50533 3 7.12665 3H10.8766C11.498 3 12.0016 3.50368 12.0016 4.125V6"
      stroke="currentcolor"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

  </Svg>
));
