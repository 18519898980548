import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  background-color: #63AE62;
  border-radius: 4px;
  color: #FFFFFF;
  display: flex;
  font-size: 6.5px;
  font-weight: 800;
  height: 16px;
  justify-content: center;
  line-height: 24px;
  width: 16px;
`;

export const CsvIcon: FC<React.PropsWithChildren<{ className?: string }>> = ({ className }) => (
  <Container className={className}>CSV</Container>
);
