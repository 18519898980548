import { produce } from 'immer';

import { useLocation } from 'src/hooks';
import { history } from 'src/providers';
import { Location } from 'src/types/routes.types';

const getState = (location: Location) => ({
  companyId: location.state?.companyModal?.companyId ?? '',
  isOpen: location.state?.companyModal?.isOpen ?? false,
});

export const useCompanyModal = () => getState(useLocation());

export const getCompanyModal = () => getState(history.location);

export const closeCompanyModal = () => {
  if (!getCompanyModal().isOpen) return;
  history.push(produce(history.location, draft => {
    // eslint-disable-next-line no-param-reassign
    delete draft.state?.companyModal;
  }));
};

export const openCompanyModal = (companyId: string) => {
  history.push(produce(history.location, draft => {
    /* eslint-disable no-param-reassign */
    delete draft.state?.isCommandBarOpen;
    delete draft.state?.customerModal;
    draft.state = {
      ...draft.state,
      companyModal: {
        isOpen: true,
        companyId,
      },
    };
  }));
};
