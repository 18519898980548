import { ChangelogUpdateDomainDocument } from '@cycle-app/graphql-codegen';
import { Input, Button, Flex } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { isUrl, getDomain } from '@cycle-app/utilities';
import { useId, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useSafeMutation } from '../../hooks';
import { addToaster } from '../../utils/toasters.utils';
import {
  Title, Body, PortalModalStyled, Header, CloseButtonStyled, Footer,
} from '../DialogModal/DialogModal.styles';

export type ChangelogModalDomainProps = {
  changelogId: string;
  domain: string;
  hide: VoidFunction;
  onRemove: VoidFunction;
  onComplete?: VoidFunction;
};

export const ChangelogModalDomain = ({
  domain, hide, changelogId, onRemove, onComplete,
}: ChangelogModalDomainProps) => {
  // Prevents seeing domain updated while the modal closes on save.
  const initialDomain = useRef(domain);
  const [updateMutation, updateState] = useSafeMutation(ChangelogUpdateDomainDocument);
  const {
    register, handleSubmit, formState,
  } = useForm({
    defaultValues: { domain },
  });

  const onSubmit = async (formData: { domain: string }) => {
    const value = getDomain(formData.domain);
    if (!value) {
      return;
    }
    const { data } = await updateMutation({
      variables: {
        id: changelogId,
        domain: value,
      },
    });
    if (data?.updateChangelog?.id) {
      onComplete?.();
      hide();
      addToaster({
        title: 'Custom domain added',
        message: 'It may take few hours to update.',
      });
    }
  };

  return (
    <PortalModalStyled
      hide={hide}
    >
      <Header>
        <Title>{initialDomain.current ? 'Edit custom domain' : 'Add your custom domain'}</Title>
        <CloseButtonStyled size="L" onClick={hide}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Body>
          <Input
            id={useId()}
            autoFocus
            error={formState.errors.domain?.message}
            placeholder="your.domain.com"
            label="Domain"
            {...register('domain', {
              validate: (value) => {
                if (!value.trim().length || !isUrl(value, { strict: false })) {
                  return 'A valid domain is required';
                }
                return undefined;
              },
            })}
          />
        </Body>
        <Footer>
          <Flex $justify="space-between" $widthFull>
            <div>
              {initialDomain.current && <Button size="M" variant="warning-secondary" onClick={onRemove}>Remove</Button>}
            </div>
            <Flex $gap={8}>
              <Button size="M" variant="secondary" onClick={hide}>Cancel</Button>
              <Button
                size="M"
                type="submit"
                isLoading={updateState.loading}
                disabled={!!formState.errors.domain}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </Footer>
      </form>
    </PortalModalStyled>
  );
};
