import { Input, Flex, Button } from '@cycle-app/ui';
import { CheckIcon, LinkIcon, CloseIcon, AddIcon } from '@cycle-app/ui/icons';
import { isUrl } from '@cycle-app/utilities';
import { useId, useState } from 'react';
import { Controller } from 'react-hook-form';

import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { Section } from './ChangelogBuilderSidebar.styles';
import { AddButton, StyledActionButton, StyledInput, StyledToggleDropdown } from './ChangelogBuilderSocialLink.styles';
import { Label, Title } from './LabelWithReset.styles';

export const ChangelogBuilderSocialLink = () => {
  const {
    register, control, watch, setValue,
  } = useChangelogBuilderFormContext();
  const id = useId();
  const url = watch('socialLinkURL');
  const text = watch('socialLinkText');
  const [{
    isEditing, autoFocus,
  }, setIsEditing] = useState({
    isEditing: false,
    // Autofocus only when we update the state.
    autoFocus: false,
  });
  const value = url || text;
  return (
    <>
      <Section>
        {(isEditing || value) && (
          <>
            <Label htmlFor={id}>Links</Label>
            <StyledInput
              {...register('socialLinkText')}
              id={id}
              autoFocus={autoFocus}
              iconPadding={60}
              endInput={(
                <Flex>
                  <StyledToggleDropdown
                    content={({ hide }) => (
                      <Controller
                        control={control}
                        name="socialLinkURL"
                        render={({ field }) => <UpdateSocialUrlDialog initialValue={field.value || ''} hide={hide} />}
                      />
                    )}
                    offsetY={20}
                    placement="top"
                    button={({
                      onClick, 'data-active': active,
                    }) => (
                      <StyledActionButton
                        forceFocus={active}
                        size="L"
                        tooltpTitle={url ? 'Update social URL' : undefined}
                        tooltip={url || 'Update social URL'}
                        tooltipPlacement="top"
                        onClick={onClick}
                      >
                        <LinkIcon size={14} />
                      </StyledActionButton>
                    )}
                  />
                  <StyledActionButton
                    size="L"
                    onClick={() => {
                      setValue('socialLinkText', '');
                      setValue('socialLinkURL', '');
                      setIsEditing({
                        isEditing: false,
                        autoFocus: false,
                      });
                    }}
                    tooltip="Remove"
                    tooltipPlacement="top"
                  >
                    <CloseIcon size={14} />
                  </StyledActionButton>
                </Flex>
            )}
            />
          </>
        )}
        {!isEditing && !value && (
          <>
            <Title>Links</Title>
            <AddButton
              onClick={() => setIsEditing({
                isEditing: true,
                autoFocus: true,
              })}
              variant="nospace"
              iconStart={<AddIcon />}
              size="M"
            >
              Add link
            </AddButton>
          </>
        )}
      </Section>
    </>
  );
};

const UpdateSocialUrlDialog = ({
  initialValue,
  hide,
}: { initialValue: string; hide: VoidFunction }) => {
  const [url, setUrl] = useState(initialValue);
  const [error, setError] = useState('');
  const { setValue } = useChangelogBuilderFormContext();
  const id = useId();
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (url) {
          if (isUrl(url, { strict: false })) {
            setValue('socialLinkURL', url);
            hide();
          } else {
            setError('You should provide a valid URL');
          }
        } else {
          // Not mandatory
          setValue('socialLinkURL', '');
          hide();
        }
      }}
    >
      <Flex
        $gap={8}
        $align="flex-start"
        style={{
          padding: '8px',
          width: '328px',
        }}
      >
        <div style={{ flex: '1' }}>
          <Input
            autoFocus
            error={error}
            id={id}
            onChange={e => {
              setError('');
              setUrl(e.currentTarget.value);
            }}
            placeholder="https://"
            value={url}
          />
        </div>
        <Button
          size="L"
          type="submit"
          variant="outlined-alt"
          style={{
            padding: 0,
            width: '40px',
            aspectRatio: '1',
            flex: 'none',
          }}
        >
          <CheckIcon size={16} />
        </Button>
      </Flex>
    </form>
  );
};
