import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { closeCustomerModal, useCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { Layer } from 'src/types/layers.types';

import { CustomerProfile } from './CustomerProfile';
import { Header, StyledPortalModal } from './CustomerProfileModal.styles';

export const CustomerProfileModal = () => {
  const {
    customerId, isOpen,
  } = useCustomerModal();

  if (!isOpen) return null;

  return (
    <StyledPortalModal placement="top" layer={Layer.ModalZ2} hide={closeCustomerModal}>
      <Header>
        <ActionButton tooltipPlacement="top" onClick={closeCustomerModal}><CloseIcon /></ActionButton>
      </Header>
      <BoardConfigContextProvider>
        {customerId && <CustomerProfile customerId={customerId} isHeaderCentered hideShareLink hideSeparator />}
      </BoardConfigContextProvider>
    </StyledPortalModal>
  );
};
