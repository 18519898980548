import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CaretIcon: FC<React.PropsWithChildren<SvgProps>> = ({
  direction = 'bottom', ...props
}) => (
  <Svg direction={direction} width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.4659 4.78779L8 9.38103L3.53407 4.78779C3.42938 4.68008 3.30921 4.59993 3.18042 4.55193C3.05163 4.50392 2.91675 4.489 2.78348 4.50801C2.65021 4.52702 2.52115 4.57959 2.40368 4.66273C2.28622 4.74586 2.18263 4.85792 2.09885 4.99252C2.01507 5.12712 1.95273 5.28162 1.91539 5.44719C1.87805 5.61276 1.86644 5.78617 1.88123 5.95751C1.89601 6.12885 1.93691 6.29476 2.00157 6.44579C2.06623 6.59681 2.1534 6.72998 2.25809 6.83769L7.36201 12.0871C7.54306 12.2735 7.76807 12.375 8 12.375C8.23193 12.375 8.45694 12.2735 8.63799 12.0871L13.7419 6.83769C13.9533 6.62015 14.0889 6.30354 14.1188 5.95751C14.1486 5.61147 14.0704 5.26435 13.9011 4.99252C13.8174 4.85792 13.7138 4.74586 13.5963 4.66273C13.4788 4.57959 13.3498 4.52702 13.2165 4.50801C12.9474 4.46962 12.6774 4.57026 12.4659 4.78779Z" />
  </Svg>
);
