import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import DialogModal, { DialogModalProps } from 'src/components/DialogModal/DialogModal';
import { DocTypeIconInline } from 'src/components/DocTypeIcon';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';
import { getDocTypeName } from 'src/utils/docType.util';

import {
  TagAttributeStyled,
  DocTypeLabel,
  AttributeTagIconContainer,
} from './AttributeUnlinkDoctypeModal.styles';

interface Props extends Omit<DialogModalProps, 'title'> {
  attribute: AttributeDefinitionsNode;
  doctype: DoctypeFragment;
}

export const AttributeUnlinkDoctypeModal: FC<React.PropsWithChildren<Props>> = ({
  attribute,
  doctype,
  ...props
}) => (
  <DialogModal
    title="Unlink property"
    info={(
      <>
        Are you sure you want to unlink
        <TagAttributeStyled
          limitSize={false}
          icon={attribute.__typename &&
            <AttributeTagIconContainer>{getCustomAttributeTypeData(attribute.__typename).icon}</AttributeTagIconContainer>}
        >
          {attribute.name}
        </TagAttributeStyled>
        {'from '}
        <DocTypeLabel>
          <DocTypeIconInline doctype={doctype} size={14} />
          {` ${getDocTypeName(doctype)}`}
        </DocTypeLabel>
        {' ? Some docs might lose their value for this property.'}
      </>
    )}
    confirmLabel="Unlink"
    {...props}
  />
);
