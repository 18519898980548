/* eslint-disable consistent-return */
import {
  PromptAiDocument,
  PromptAiMutationVariables,
  TranslateAiDocument,
  TranslateAiMutationVariables,
  AdjustToneAiDocument,
  AdjustToneAiMutationVariables,
  CorrectAiDocument,
  EmojifyAiDocument,
  ExtendAiDocument,
  SummarizeAiDocument,
  ShortenAiDocument,
  CompleteAiDocument,
  SimplifyAiDocument,
  RephraseAiDocument,
  Tone,
  Language,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useProduct, useSafeMutation } from 'src/hooks';

import type { EditorAiAction } from 'src/types/editor.types';

export type AskEditorAiParams = {
  action: Extract<EditorAiAction, 'prompt'>;
  language?: never;
  prompt: string;
  selection: string;
  text?: never;
  tone?: never;
} | {
  action: Extract<EditorAiAction, 'adjust-tone'>;
  language?: never;
  prompt?: never;
  selection?: never;
  text: string;
  tone: Tone;
} | {
  action: Extract<EditorAiAction, 'translate'>;
  language: Language;
  prompt?: never;
  selection?: never;
  text: string;
  tone?: never;
} | {
  action: Exclude<EditorAiAction, 'prompt' | 'adjust-tone' | 'translate'>;
  language?: never;
  prompt?: never;
  selection?: never;
  text: string;
  tone?: never;
};

export const useEditorAiActions = () => {
  const {
    sendPrompt, isPromptLoading,
  } = usePrompt();
  const {
    adjust, isAdjustLoading,
  } = useAdjustTone();
  const {
    translate, isTranslateLoading,
  } = useTranslate();
  const {
    askAi, isLoading,
  } = useAction();

  const askEditorAi = useCallback(async (params: AskEditorAiParams) => {
    const { action } = params;
    if (action === 'prompt') {
      const {
        prompt, selection,
      } = params;
      const result = await sendPrompt(prompt, selection);
      return result?.data?.customPrompt || '';
    }
    if (action === 'adjust-tone') {
      const {
        text, tone,
      } = params;
      const result = await adjust(text, tone);
      return result?.data?.adjustTone || '';
    }
    if (action === 'translate') {
      const {
        text, language,
      } = params;
      const result = await translate(text, language);
      return result?.data?.translateText || '';
    }
    const { text } = params;
    const response = await askAi(action, text);
    if (!response?.data) return '';
    const keyName = Object.keys(response.data)[0] as keyof typeof response['data'];
    return response.data?.[keyName];
  }, [adjust, askAi, sendPrompt, translate]);

  return {
    askEditorAi,
    isLoading: isPromptLoading || isAdjustLoading || isTranslateLoading || isLoading,
  };
};

const usePrompt = () => {
  const [prompt, { loading }] = useSafeMutation(PromptAiDocument);
  const { product } = useProduct();

  const sendPrompt = (promptText: PromptAiMutationVariables['prompt'], selection: string) => {
    if (!product) return;
    return prompt({
      variables: {
        productId: product.id,
        prompt: promptText,
        text: selection,
      },
    });
  };

  return {
    sendPrompt,
    isPromptLoading: loading,
  };
};

const useTranslate = () => {
  const [tr, { loading }] = useSafeMutation(TranslateAiDocument);
  const { product } = useProduct();

  const translate = (text: TranslateAiMutationVariables['text'], language: TranslateAiMutationVariables['language']) => {
    if (!product) return;
    return tr({
      variables: {
        productId: product.id,
        text,
        language,
      },
    });
  };

  return {
    translate,
    isTranslateLoading: loading,
  };
};

const useAdjustTone = () => {
  const [adjustTone, { loading }] = useSafeMutation(AdjustToneAiDocument);
  const { product } = useProduct();

  const adjust = (text: AdjustToneAiMutationVariables['text'], tone: AdjustToneAiMutationVariables['tone']) => {
    if (!product) return;
    return adjustTone({
      variables: {
        productId: product.id,
        text,
        tone,
      },
    });
  };

  return {
    adjust,
    isAdjustLoading: loading,
  };
};

const useAction = () => {
  const [correct, { loading: loadingCorrect }] = useSafeMutation(CorrectAiDocument);
  const [emojify, { loading: loadingEmojify }] = useSafeMutation(EmojifyAiDocument);
  const [extend, { loading: loadingExtend }] = useSafeMutation(ExtendAiDocument);
  const [summarize, { loading: loadingSummarize }] = useSafeMutation(SummarizeAiDocument);
  const [shorten, { loading: loadingShorten }] = useSafeMutation(ShortenAiDocument);
  const [complete, { loading: loadingComplete }] = useSafeMutation(CompleteAiDocument);
  const [simplify, { loading: loadingSimplify }] = useSafeMutation(SimplifyAiDocument);
  const [rephrase, { loading: loadingRephrase }] = useSafeMutation(RephraseAiDocument);
  const { product } = useProduct();
  const askAi = (
    action: Exclude<EditorAiAction, 'prompt' | 'adjust-tone' | 'translate'>,
    text: string,
  ) => {
    if (!product) return;

    const variables = {
      variables: {
        productId: product.id,
        text,
      },
    };

    if (action === 'fix-spelling-and-grammar') return correct(variables);
    if (action === 'emojify') return emojify(variables);
    if (action === 'extend') return extend(variables);
    if (action === 'summarize') return summarize(variables);
    if (action === 'shorten') return shorten(variables);
    if (action === 'complete') return complete(variables);
    if (action === 'simplify') return simplify(variables);
    if (action === 'rephrase') return rephrase(variables);
    // eslint-disable-next-line no-useless-return
    return;
  };

  const isLoading = loadingCorrect ||
    loadingEmojify ||
    loadingExtend ||
    loadingSummarize ||
    loadingShorten ||
    loadingComplete ||
    loadingSimplify ||
    loadingRephrase;

  return {
    askAi,
    isLoading,
  };
};
