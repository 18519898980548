import { rgba } from 'polished';

import { DeepPartial } from '../../types/partial.types';
import { SubColorTheme } from '../../types/theme.types';
import { Color } from '../baseColors';

const text = {
  primary: Color.Black,
  secondary: Color.Grey700,
  opposite: Color.White,
  disabled: Color.Grey500,
};

export const colors: SubColorTheme = {
  text,
  sidebar: {
    bg: Color.White,
    color: text.secondary,
    colorHover: Color.Black,
    border: Color.Grey200,
    separator: rgba(Color.Black, 0.1),
    header: {
      menus: {
        color: Color.Black,
        emailColor: Color.Grey700,
      },
      title: {
        color: text.primary,
      },
      item: {
        bgHover: Color.Grey200,
        caret: {
          colorHover: Color.Black,
        },
      },
      shortcut: {
        bg: Color.Grey100,
        color: Color.Grey700,
        bgHover: Color.Grey300,
      },
    },
    item: {
      bgHover: Color.Grey200,
      bgActive: Color.Grey300,
      bgPlaceholder: Color.Grey200,
      dotsMenu: {
        colorHover: Color.Black,
        bgHover: Color.Grey300,
      },
      warning: {
        color: Color.Grey700,
        colorHover: Color.Black,
        bgHover: Color.Grey300,
      },
    },
    boardItem: {
      color: Color.Black,
    },
    boardsSectionItem: {
      bg: Color.Grey100,
      color: Color.Grey500,
      bgHover: Color.Grey300,
      colorHover: Color.Grey700,
    },
    newSectionInput: {
      color: text.primary,
    },
    newBoard: {
      color: Color.Grey500,
      colorHover: Color.Grey700,
    },
    shortcut: {
      bg: Color.White,
      color: Color.Black,
      bgHover: Color.Grey300,
      colorHover: Color.Grey700,
      active: {
        colorHover: Color.White,
      },
    },
    card: {
      bg: Color.White,
      text: Color.Black,
    },
    dropdown: {
      bg: Color.White,
      text: Color.Black,
      separator: Color.Grey200,
    },
  },
};

export const sidebarColors: DeepPartial<typeof colors> = {
  shortcut: {
    bg: Color.White,
    color: Color.Black,
    active: {
      bg: Color.White,
    },
    oposite: {
      color: Color.White,
      bg: Color.Black,
    },
  },
  actionButton: {
    colorHover: text.primary,
    bgHover: Color.White,
  },
};
