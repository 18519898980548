import { Skeleton, typo, Button, boxShadowZ2 } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

const cardCss = css`
  width: 450px;
  height: auto;
  min-height: 192px;
  ${boxShadowZ2}
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 8px;
  padding: var(--s-6);
  margin: 0 auto;
`;

export const Body = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: var(--s-10) 0;
`;

export const DocCardPreview = styled.div`
  ${cardCss}
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DocCardPreviewSkeleton = styled(Skeleton)`
  ${cardCss}
  margin: var(--s-10) 0;
`;

export const DocTitle = styled.h2`
  ${typo.headerMedium};
  outline: none;
  width: 100%;
`;

export const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const CustomerName = styled.span`
  ${typo.body500};
  color:${p => p.theme.colors.text.primary};
  outline: none;
  width: 100%;
`;

export const AddInsightButton = styled(Button).attrs({ size: 'L' })`
  flex: 1;
`;

export const BodyContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Attributes = styled.div`
  display: flex;
  gap: 4px;
`;
