import { NavigationItem, NavigationItemProps, Tooltip } from '@cycle-app/ui';
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';
import { FC, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { useIsBoardActive } from 'src/hooks/useIsBoardActive';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { setMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';

type Props = NavigationItemProps & {
  linkId?: string;
  isStarredBoard?: boolean;
};

export const CollapsableNavigationItem: FC<React.PropsWithChildren<Props>> = memo(({
  linkId, isStarredBoard = false, ...props
}) => {
  const {
    label, linkTo, isActive, onClick,
  } = props;
  const collapsed = useSidebarCollapsed();
  const history = useHistory();
  const isBoardActive = useIsBoardActive({
    boardId: linkId,
    isStarred: isStarredBoard,
  });

  if (!linkTo || !linkId) {
    return (
      <NavigationItem
        {...props}
        isSidebarCollapsed={collapsed}
      />
    );
  }

  const item = (
    <NavigationItem
      {...props}
      isSidebarCollapsed={collapsed}
      isActive={isActive ?? isBoardActive}
      onClick={async (e) => {
        if (typeof linkTo === 'function') return;
        onClick?.(e);
        await nextFrame();
        if (e.metaKey) {
          if (typeof linkTo === 'object' && linkTo.pathname) {
            window.open(linkTo.pathname, '_blank');
            return;
          }
          window.open(linkTo.toString(), '_blank');
        } else {
          history.push(linkTo);
          setMobileAsideMenu({ isVisible: false });
        }
      }}
    />
  );

  // Conditional render because of CSS issue with Item embeded into Tooltip
  if (!collapsed) return item;

  return (
    <Tooltip
      content={label}
      placement="right"
      withWrapper={false}
      withPortal
    >
      {item}
    </Tooltip>
  );
});
