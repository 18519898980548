import { AddPictureIcon } from '@cycle-app/ui/icons';
import { readFileSrc } from '@cycle-app/utilities';

import { ACCEPTED_IMAGE_FORMAT } from '../../constants/upload.constant';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { ImageInput } from '../ImageInput';
import {
  Overlay, OverlayHover, Preview, UploadButton, UploadIconContainer, UploadPreview,
} from './ChangelogBuilderLogo.styles';
import { Section } from './ChangelogBuilderSidebar.styles';
import { LabelWithReset } from './LabelWithReset';

const Icon = (
  <UploadIconContainer>
    <AddPictureIcon />
  </UploadIconContainer>
);

const accept = {
  ...ACCEPTED_IMAGE_FORMAT,
  'image/svg+xml': [],
};

export const ChangelogBuilderLogo = () => {
  const {
    formState, watch, setValue,
  } = useChangelogBuilderFormContext();
  const logoUrl = watch('logo_url');
  return (
    <Section style={{ borderTopWidth: 0 }}>
      <LabelWithReset
        label="Logo"
        reset={() => {
          setValue('logo_url', formState.defaultValues?.logo_url || '');
          setValue('logo_file', null);
        }}
        showReset={logoUrl !== formState.defaultValues?.logo_url}
      />
      <ImageInput
        previewModalSubmitLabel="Update logo"
        onChange={async file => {
          const fileUrl = await readFileSrc(file);
          setValue('logo_file', file);
          setValue('logo_url', fileUrl);
        }}
        accept={accept}
        renderPreview={src => <UploadPreview src={src} />}
      >
        {(inputRef) => (
          <UploadButton
            type="button"
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            {!logoUrl && <Overlay>{Icon}</Overlay>}
            {logoUrl && <OverlayHover>{Icon}</OverlayHover>}
            {logoUrl && <Preview src={logoUrl} />}
          </UploadButton>
        )}
      </ImageInput>
    </Section>
  );
};
