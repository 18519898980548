import { PageId } from 'src/constants/routing.constant';
import { resetAuth } from 'src/reactives/auth.reactive';
import { resetCommandbar } from 'src/reactives/commandbar.reactive';
import { resetCreateDoc } from 'src/reactives/createDoc.reactive';
import { resetInitial } from 'src/reactives/initial.reactive';
import { resetLastInboxBoard, resetLastInsightBoard, resetLastRoadmapBoard, resetLastView } from 'src/reactives/lastView.reactive';
import { resetOnboarding as resetLightOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { resetOnboarding } from 'src/reactives/onboarding.reactive';
import { resetSidebar } from 'src/reactives/sidebar.reactive';
import { resetToasters } from 'src/reactives/toasters.reactive';

import { useNavigate } from './useNavigate';

export const useLogout = () => {
  const { navigate } = useNavigate();

  return () => {
    // Remove token and preferences
    resetAuth();
    resetInitial();
    resetLastView();
    resetLastInboxBoard();
    resetLastInsightBoard();
    resetLastRoadmapBoard();
    resetSidebar();
    resetCommandbar();
    resetOnboarding();
    resetCreateDoc();
    resetLightOnboarding();
    resetToasters();
    navigate(PageId.Login);
  };
};
