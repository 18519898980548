import { ToggleCollaboratorPrivacyDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';

import useSafeMutation from '../useSafeMutation';

export const useToggleCollaboratorPrivacy = (collaboratorPrivacy?: boolean) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [mutation] = useSafeMutation(ToggleCollaboratorPrivacyDocument);

  const toggleCollaboratorPrivacy = () => {
    if (collaboratorPrivacy === undefined) return null;
    return mutation({
      variables: {
        productId,
      },
      optimisticResponse: {
        toggleCollaboratorPrivacy: {
          id: productId,
          collaboratorPrivacy: !collaboratorPrivacy,
        },
      },
    });
  };

  return {
    toggleCollaboratorPrivacy,
  };
};
