import { Button } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

const mask = css`mask-image: radial-gradient(circle at -5px 16px, transparent 16px, white 16px);`;

export const Container = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Info = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  white-space: nowrap;

  &:empty {
    display: none;
  }
`;

export const StyledButtonMore = styled(Button)`
  --radius: 50%;
  width: 32px;
  height: 32px;
  color: ${p => p.theme.colors.text.secondary};
  ${mask}
`;

export const StyledInfoIconOutline = styled(InfoIconOutline)`
  color: ${p => p.theme.colors.text.disabled};
`;
