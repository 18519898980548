import { CustomerAvatar, Tooltip } from '@cycle-app/ui';
import { DuplicateIcon, LoadingIcon, InfoIconOutline, PenIcon } from '@cycle-app/ui/icons';

import { CustomerCompanyAction } from 'src/components/CustomerCompanyAction/CustomerCompanyAction';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { closeCompanyModal, openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { openCustomerModal } from 'src/hooks/modals/useCustomerModal';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import {
  DropdownContainer,
  Top,
  Title,
  TopContent,
  Bottom,
  AvatarContainer,
  InfoContainer,
  CompanyContainer,
  CTA,
  CustomerName,
  CustomerEmail,
  CompanyCta,
  UserInfo,
} from './DocCompanyCustomer.styles';

import type { DocCompanyCustomerProps } from './DocCompanyCustomer.types';
import type { MouseEvent } from 'react';

type DocCompanyCustomerModalContentProps = Pick<DocCompanyCustomerProps, 'doc' | 'onProfileOpen' | 'isReadOnly'> & {
  onClickUpdate?: VoidFunction;
};

export const DocCompanyCustomerModalContent = ({
  doc,
  onProfileOpen,
  isReadOnly = false,
  onClickUpdate,
}: DocCompanyCustomerModalContentProps) => {
  const onCustomerClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!doc.customer?.id) return;
    closeCompanyModal();
    onProfileOpen?.(e);
    openCustomerModal(doc.customer.id);
  };
  return (
    <DropdownContainer
      onClick={e => {
        // Safe, if for instance we include inside a card.
        e.stopPropagation();
      }}
    >
      <Top>
        <Title>
          Customer
          <Tooltip
            withPortal
            placement="top"
            content="Customers are people who give product feedback – they can be team members, users or prospects"
          >
            <InfoIconOutline size={14} />
          </Tooltip>
        </Title>
        <TopContent>
          <UserInfo
            onClick={onCustomerClick}
          >
            <AvatarContainer>
              <CustomerAvatar
                customer={{
                  id: '',
                  name: doc?.customer?.name,
                  email: doc?.customer?.email,
                  avatar: doc?.customer?.avatar,
                }}
                hideCompany
                size="S-M"
              />
            </AvatarContainer>
            <InfoContainer>
              {doc?.customer?.name && <CustomerName>{doc?.customer.name}</CustomerName>}
              {doc?.customer?.email && <CustomerEmail>{doc?.customer.email}</CustomerEmail>}
            </InfoContainer>
          </UserInfo>
          <CompanyContainer>
            <CompanyButton doc={doc} onProfileOpen={onProfileOpen} />
          </CompanyContainer>
        </TopContent>
      </Top>
      <Bottom>
        {!!doc?.customer?.email && (
          <CTA onClick={(e) => {
            e.stopPropagation();
            if (doc.customer?.email) {
              copyToClipboard({
                text: doc.customer.email,
                notification: 'Email copied to clipboard',
              });
            }
          }}
          >
            <DuplicateIcon />
            Copy email
          </CTA>
        )}
        {!isReadOnly && (
          <CTA onClick={onClickUpdate}>
            <PenIcon />
            Change customer
          </CTA>
        )}
      </Bottom>
    </DropdownContainer>
  );
};

type CompanyButtonProps = Pick<DocCompanyCustomerModalContentProps, 'doc' | 'onProfileOpen'>;

const CompanyButton = ({
  doc, onProfileOpen,
}: CompanyButtonProps) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    update, isLoading,
  } = useCustomerUpdate(doc.customer || undefined);

  // eslint-disable-next-line no-nested-ternary
  const content = isLoading
    ? <LoadingIcon />
    : doc.customer?.company
      ? (
        <>
          {doc.customer.company.logo && <img src={doc.customer.company.logo} alt={doc.customer.company.id} />}
          {doc.customer.company.name}
        </>
      )
      : 'Add company';

  const onUpdate = (companyId?: string) => {
    if (!doc.customer?.id) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    update({
      customerId: doc.customer.id,
      companyId: companyId || null,
    });
  };

  if (doc.customer?.company) {
    return (
      <Tooltip content="Open" placement="top">
        <CompanyCta
          $hasBg
          onClick={(e) => {
            e.stopPropagation();
            if (!doc.customer?.company?.id) return;
            onProfileOpen?.(e);
            openCompanyModal(doc.customer.company.id);
          }}
        >
          {content}
        </CompanyCta>
      </Tooltip>
    );
  }

  return (
    <CustomerCompanyAction
      productId={productId}
      appendTo="parent"
      onOptionChange={(option) => onUpdate(option.value)}
      onClearValue={() => onUpdate()}
      hasBg
    >
      <CompanyCta>
        {content}
      </CompanyCta>
    </CustomerCompanyAction>
  );
};
