import { Color } from '@cycle-app/ui/theme/baseColors';
import { getBrowser } from '@cycle-app/utilities';
import { useCallback, useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';

import { ErrorMessage } from 'src/constants/errors.constants';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import type { MutableRefObject } from 'react';

type UseRecorderParams = {
  container: MutableRefObject<HTMLDivElement | null>;
  listeners: {
    onRecordingReady: (recordUrl: string) => void;
    onRecordingStart: VoidFunction;
  };
  height?: number;
};

export const useRecorder = ({
  container, listeners, height = 52,
}: UseRecorderParams) => {
  const [status, setStatus] = useState<'recording' | 'pause' | null>(null);
  const instance = useRef<WaveSurfer | null>(null);
  const recorder = useRef<RecordPlugin | null>(null);

  const setRecorder = useCallback((callback?:VoidFunction) => {
    if (container.current) {
      instance.current = WaveSurfer.create({
        container: container.current,
        height,
        barWidth: 2,
        barGap: 3,
        barRadius: 10,
        cursorColor: Color.Cycle,
        cursorWidth: 0,
        waveColor: Color.Cycle,
        progressColor: Color.Cycle,
        audioRate: 1,
        autoplay: false,
      });
      recorder.current = instance.current.registerPlugin(RecordPlugin.create());
      recorder.current.on('record-end', (blob) => {
        const recordedUrl = URL.createObjectURL(blob);
        listeners.onRecordingReady(recordedUrl);
      });
      recorder.current.on('record-start', listeners.onRecordingStart);
      callback?.();
    }
  /**
   * Intended
   * When the callback is re-evaluated it makes DOM update when WaveSurfer
   * instance is created which is not what we want cause it created extra div's
   * and players
   * The instance works fine without re-evaluate the function each time the container
   * change
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRecorder();
  }, [setRecorder]);

  const startRecording = () => {
    setRecorder(() => {
      if (!recorder.current) return;
      recorder.current.startRecording().then(() => {
        setStatus('recording');
      }).catch(async (error) => {
        setStatus(null);
        if (error.message.includes('Error accessing the microphone')) {
          /**
           * It seems that depend of how the user to toggling on/off the browser
           * mic permission without restarting the browser, it can return a
           * GRANTED state but without having a real access
           */
          if (getBrowser() === 'safari') {
            const result = await navigator.permissions.query({ name: 'microphone' as PermissionName });
            if (result.state === 'granted') {
              // eslint-disable-next-line no-console
              console.warn('Try to restart completely your browser, is the error persist, contact support');
            }
          }
          addErrorToaster({ message: ErrorMessage.MICROPHONE_NOT_ACCESSIBLE });
        }
      });
    });
  };

  return {
    status,
    startRecording,
    stopRecording: () => {
      recorder.current?.stopRecording();
      recorder.current?.stopMic();
      setStatus('pause');
    },
    resetRecording: () => {
      recorder.current?.destroy();
      instance.current = null;
      recorder.current = null;
      setStatus(null);
    },
  };
};

export const useCustomTimer = () => {
  const [isRunning, setIsRunning] = useState(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (isRunning) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const startStopTimer = () => {
    setIsRunning(!isRunning);
  };

  const resetTimer = () => {
    setIsRunning(false);
    setSeconds(0);
  };

  return {
    startStopTimer,
    resetTimer,
    timer: seconds,
  };
};

export const useMicrophonePermission = () => {
  const [hasMicrophonePermission, setPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getPermission = async () => {
    const browserName = getBrowser();
    if (browserName === 'firefox') {
      navigator.mediaDevices
        .getUserMedia({
          video: false,
          audio: true,
        })
        .then(() => {
          setPermission(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      navigator.permissions
        .query({ name: 'microphone' as PermissionName })
        .then((result) => {
          // eslint-disable-next-line no-param-reassign
          result.onchange = () => setPermission(result.state === 'granted');
          if (result.state === 'granted' || result.state === 'prompt') {
            setPermission(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getPermission();
  }, []);

  return {
    hasMicrophonePermission,
    isLoading,
  };
};
