import { LocalKey } from 'src/types/localStorage.types';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';
import { make } from 'src/utils/reactives.util';

type OnboardingSlackState = {
  step?: OnboardingSlackStep;
  /**
   * Doc id will be the doc created from Slack during the onboarding
   */
  docId?: string;
};

const {
  getValue,
  hookState,
  setValue,
} = make<OnboardingSlackState>({
  defaultState: {
    step: undefined,
    docId: undefined,
  },
  localKey: LocalKey.OnboardingSlack,
  crossTabSync: true,
});

export const useOnboardingSlack = hookState;

export const startOnboardingSlack = () => setValue({ step: OnboardingSlackStep.Install });
export const stopOnboardingSlack = () => setValue({
  step: undefined,
  docId: undefined,
});

export const getOnboardingSlackStep = () => getValue().step;
export const getOnboardingSlackDocId = () => getValue().docId;
export const setOnboardingSlackDocId = (docId: string) => setValue({ docId });

export const setOnboardingSlackStep = (step: OnboardingSlackStep) => setValue({ step });
