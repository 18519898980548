import { ActionButton } from '@cycle-app/ui';
import { FlashIcon, TrashIcon } from '@cycle-app/ui/icons';

import { useOAuthClients } from 'src/hooks/api/queries/api/useOAuthClients';
import { setSettingsApi } from 'src/reactives/settingsApi.reactive';

import {
  Section, SectionTitle, ItemList, ItemCard, ItemTitle, AppIconContainer,
} from '../SettingsAPI.styles';
import { OAuthRevokeAppModal } from './OAuthRevokeAppModal';

export const AuthorizedAppSection = () => {
  const query = useOAuthClients({ installed: true });
  if (query.clients.length === 0) return null;
  return (
    <Section>
      <header>
        <SectionTitle>
          Authorized applications
        </SectionTitle>
      </header>

      <ItemList>
        {query.clients.map(client => (
          <ItemCard
            key={client.id}
          >
            <AppIconContainer>
              <FlashIcon size={11} />
            </AppIconContainer>

            <ItemTitle>{client.name}</ItemTitle>

            <ActionButton
              tooltip={`Revoke access to ${client.name}`}
              onClick={e => {
                e.stopPropagation();
                setSettingsApi({ openedRevokeAppModal: client.id });
              }}
            >
              <TrashIcon size={16} />
            </ActionButton>
          </ItemCard>
        ))}
      </ItemList>

      <OAuthRevokeAppModal />
    </Section>
  );
};
