import { getFileType } from '@cycle-app/utilities';

import { FILE_SIZE_LIMIT, FILE_SIZE_LIMIT_RESTRICTED, IMAGE_SIZE_LIMIT } from 'src/constants/upload.constant';

const MEGA_OCTET = 1000000;
const KILO_OCTET = 1000;

export const getFileSizeWithUnit = (size: number, isRound = false): string => {
  const divider = size > MEGA_OCTET ? MEGA_OCTET : KILO_OCTET;
  const unit = size > MEGA_OCTET ? 'MB' : 'KB';
  const total = size / divider;

  return isRound ? `${Math.floor(total)} ${unit}` : `${(total).toFixed(2)} ${unit}`;
};

export const isImageLink = (url: string): boolean => /\.(jpg|jpeg|png|webp|avif|gif|svg)/.test(url);

export const isPdfLink = (url: string): boolean => url.endsWith('.pdf');

export const isDocumentTranscribable = (url: string) => /\.(pdf|txt|vtt)/.test(url);

type FileErrorType = 'file-too-large' | 'file-empty';
type GetFileErrorReturn = { message:string; type: FileErrorType } | null;
export const getFileError = (file: File): GetFileErrorReturn => {
  const fileType = getFileType(file);
  const variantText = fileType === 'image' ? 'image' : 'file';
  const sizeLimit = getFileMaxSize(file);
  if (file.size > sizeLimit) {
    return {
      message: `🙀 Wow, this ${variantText} is huge, sorry we can’t handle it (max ${getFileSizeWithUnit(sizeLimit, true)})`,
      type: 'file-too-large',
    };
  }
  if (file.size <= 0) {
    return {
      message: `🤭 Oops, looks like your ${variantText} is empty so we can't upload it`,
      type: 'file-empty',
    };
  }

  return null;
};

export const getFileMaxSize = (file: File) => {
  const fileType = getFileType(file);
  return (fileType === 'image') || (fileType === 'file' && file.type.includes('pdf')) ? IMAGE_SIZE_LIMIT : FILE_SIZE_LIMIT;
};

type GetUrlErrorReturn = { message:string; type: 'wrong-url' } | null;
export const getUrlError = (url: string): GetUrlErrorReturn => {
  if (url.length && !isImageLink(url)) {
    return {
      message: '🤭 Oops, we can’t handle this link, are you sure it’s an image?',
      type: 'wrong-url',
    };
  }

  return null;
};

export const isUploadRestricted = (file: File, canUploadNotRestricted: boolean | undefined) => (
  !canUploadNotRestricted && file.size > FILE_SIZE_LIMIT_RESTRICTED
);

export const getFileExtension = (fileName?: string | null): string | null => {
  if (!fileName) return null;
  const parts = fileName.split('.');
  if (parts.length === 1) return null;
  return parts.at(-1) || null;
};

export const getFileBaseName = (fileName?: string | null): string | null => {
  if (!fileName) return null;
  const parts = fileName.split('.');
  if (parts.length === 1) return fileName;
  return parts.slice(0, -1).join('.');
};
