import { useQuery } from '@apollo/client';
import { addFakeAttributes } from '@cycle-app/front/tests/fakes/customerAttributes';
import { CompanyWithCustomersDocument } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useCallback, useMemo } from 'react';

export const useCompany = (companyId?: string) => {
  const {
    data, fetchMore, loading: isLoading,
  } = useQuery(CompanyWithCustomersDocument, {
    skip: !companyId,
    variables: {
      id: companyId ?? '',
      size: 10,
      cursor: '',
    },
  });

  const company = useMemo(() => {
    if (data?.node?.__typename !== 'Company') return null;
    if (addFakeAttributes) {
      return addFakeAttributes(data.node);
    }
    return data.node;
  }, [data]);

  const customers = useMemo(() => (data?.node?.__typename === 'Company' ? nodeToArray(data.node.customers) : []), [data]);
  const pageInfo = company?.customers?.pageInfo;

  const loadMoreCustomers = useCallback(async () => {
    if (!pageInfo?.hasNextPage) return;
    await fetchMore({
      variables: {
        cursor: pageInfo.endCursor,
      },
    });
  }, [fetchMore, pageInfo]);

  return {
    company,
    customersCount: company?.countCustomers ?? 0,
    customers,
    loadMoreCustomers,
    isLoading,
    pageInfo,
  };
};
