import { BillingPlan } from '@cycle-app/graphql-codegen';
import { SelectPanel, ButtonProps, Props as PopoverProps } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { BILLING_LIMIT_CUSTOM_DOC_TYPES } from 'src/constants/billing.constants';
import { useCurrentBilling, useProductAddOn } from 'src/hooks';
import useDoctypeParentsMutations from 'src/hooks/api/mutations/useDoctypeParentsMutations';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { getPotentialRelatives, findInsight, getDocTypeName, getCustomDocTypesCount } from 'src/utils/docType.util';

import { AddButton } from './LinkedToRoadmaps.styles';

type AddDocTypeLinkedToRoadmapsProps = {
  variant?: ButtonProps['variant'];
  placement?: PopoverProps['placement'];
};

export const AddDocTypeLinkedToRoadmaps = ({
  variant, placement = 'bottom-start',
}:AddDocTypeLinkedToRoadmapsProps) => {
  const { docTypes } = useGetDocTypes();
  const docTypesList = Object.values(docTypes);
  const insight = findInsight(docTypesList);
  const potentialParents = getPotentialRelatives(docTypesList, insight, 'parent');
  const doctypesAddOn = useProductAddOn('UNLIMITED_DOCTYPES');
  const currentBilling = useCurrentBilling();
  const { addDoctypeParent } = useDoctypeParentsMutations(insight);
  const [newDocTypeName, setNewDocTypeName] = useState<string | null>(null);
  return (
    <>
      <ToggleDropdown
        placement={placement}
        button={props => (
          <AddButton
            variant={variant || 'light'}
            size="S"
            iconStart={<AddIcon />}
            {...props}
          >
            Add
          </AddButton>
        )}
        content={props => (
          <SelectPanel
            options={potentialParents.map(d => ({
              value: d.id,
              label: getDocTypeName(d),
              icon: <DocTypeIcon doctype={d} />,
            }))}
            onOptionChange={async (option) => {
              props.hide();
              await addDoctypeParent(option.value);
            }}
            onCreateOption={async (text) => {
              props.hide();
              if (
                !doctypesAddOn?.isEnabled &&
                      currentBilling?.plan !== BillingPlan.BusinessPlus &&
                      getCustomDocTypesCount(docTypes) >= BILLING_LIMIT_CUSTOM_DOC_TYPES
              ) {
                setLimitationsModal({
                  action: 'USE_ADD_ON',
                  brand: 'UNLIMITED_DOCTYPES',
                });
                return;
              }
              setNewDocTypeName(text);
            }}
            showCreateOptionIfEmpty
            defaultCreateOptionLabel="Create new"
          />
        )}
      />

      {newDocTypeName !== null && (
        <DoctypesEditCommonModal
          initialName={newDocTypeName}
          onHide={() => setNewDocTypeName(null)}
          linkToInsights
        />
      )}
    </>
  );
};
