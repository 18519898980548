import { SelectOption } from '@cycle-app/ui';
import { TrashIcon, PenIcon, LinkIcon, UnlinkIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';

import { DoctypeDeleteModal } from 'src/components/DoctypeDeleteModal/DoctypeDeleteModal';
import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { PageId } from 'src/constants/routing.constant';
import { useOptimizedBooleanState } from 'src/hooks';
import useDoctypeParentsMutations from 'src/hooks/api/mutations/useDoctypeParentsMutations';
import useDoctypesMutations from 'src/hooks/api/mutations/useDoctypesMutations';
import { useInsightDocType } from 'src/hooks/api/useDocType';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useGetDocType, useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { hasInsightChildren, isParentOfInsight } from 'src/utils/docType.util';
import { getParams } from 'src/utils/routing.utils';

import { UnlinkInsightsModal } from './UnlinkInsightsModal';

export const SettingsDocTypeMenu = ({ doctypeId }: {
  doctypeId: string;
}) => {
  const { navigate } = useNavigateToSettings();
  const docTypes = Object.values(useGetDocTypes().docTypes);
  const docType = useGetDocType(doctypeId);
  const { deleteDoctype } = useDoctypesMutations(doctypeId);

  const insight = useInsightDocType();
  const insightParents = nodeToArray(insight?.parents);
  const isLastParentOfInsight = insightParents.length === 1 && insightParents[0]?.id === doctypeId;

  const [isEditModalVisible, { toggleCallback: toggleEditModal }] = useOptimizedBooleanState(false);
  const [isDeleteModalVisible, { toggleCallback: toggleDeleteModal }] = useOptimizedBooleanState(false);
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation, setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);

  const { addDoctypeParent } = useDoctypeParentsMutations(insight);

  const [showWarningModal, {
    setTrueCallback: setShowWarningModal,
    setFalseCallback: setHideWarningModal,
  }] = useOptimizedBooleanState(false);

  const parents = Object.values(docTypes).filter(hasInsightChildren);
  const isUnlinkable = parents.length > 1;

  if (!docType) return null;

  const onDelete = async () => {
    const firstDoctypeAvailable = docTypes.filter(d => d.id !== doctypeId)[0];
    if (getParams().doctypeId === doctypeId && firstDoctypeAvailable) {
      navigate(PageId.SettingsRoadmaps);
    }
    await deleteDoctype();
  };

  const isLastDocType = docTypes.length < 2;

  const deleteWarning =
  // eslint-disable-next-line no-nested-ternary
      isLastDocType
        ? 'Every workspace needs at least one doc type'
        : isLastParentOfInsight
          ? `${insight?.name} must be linked to at least one doc type`
          : undefined;

  const editOptions: SelectOption[] = [
    {
      value: 'edit',
      label: 'Edit doc type',
      onSelect: toggleEditModal,
      icon: <PenIcon />,
    },
    ...isParentOfInsight(docType) && isUnlinkable ? [{
      value: 'unlink',
      label: 'Unlink insights',
      onSelect: setShowWarningModal,
      icon: <UnlinkIcon size={14} />,
    }] : [],
    ...!isParentOfInsight(docType) ? [{
      value: 'link',
      label: 'Link to insights',
      onSelect: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        addDoctypeParent(docType.id);
      },
      icon: <LinkIcon size={14} />,
    }] : [],
    {
      value: 'delete',
      label: 'Delete',
      onSelect: toggleDeleteModal,
      icon: <TrashIcon />,
      variant: 'danger',
      disabled: isLastDocType || isLastParentOfInsight,
      tooltipContent: deleteWarning,
    },
  ];

  return (
    <>
      <DotsMenuLayer
        tooltip={`Edit ${docType.name}`}
        tooltipPlacement="bottom"
        options={editOptions}
        layer={Layer.DropdownModal}
      />

      {showWarningModal && (
        <UnlinkInsightsModal
          parent={docType}
          hide={setHideWarningModal}
        />
      )}

      {isEditModalVisible && (
        <DoctypesEditCommonModal
          doctype={docType}
          onHide={toggleEditModal}
        />
      )}

      {isDeleteModalVisible && (
        <DoctypeDeleteModal
          doctype={docType}
          hide={() => {
            setDeleteConfirmationFalse();
            toggleDeleteModal();
          }}
          onConfirm={onDelete}
          onToggleConfirmation={toggleDeleteConfirmation}
          isConfirmDisabled={!isDeleteConfirmed}
        />
      )}
    </>
  );
};
