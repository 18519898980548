import { SelectOption, CompanyLogo } from '@cycle-app/ui';
import { FC, useCallback } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { CompanyFromFilter, selectableCompanyToOption } from 'src/utils/boardConfig/filtersData.util';
import { Truncate } from 'src/utils/css.styles';

import { Placeholder } from '../../Filter.styles';
import { Container, MoreValues } from '../FilterElements.styles';
import CompanyFilterElementsDropdownContent from './CompanyFilterElementsDropdownContent';

interface Props {
  className?: string;
  dropdownLayer?: Layer;
  filterPropertyRuleId?: string;
  options: CompanyFromFilter[];
  onOptionsAdded: (newOptions: SelectOption[]) => void;
  onOptionRemoved: (optionValue: string) => void;
  panelTitle: string;
  placeholder: string;
  selectedOptions: CompanyFromFilter[];
  disabled?: boolean;
}
const CompanyFilterElements: FC<React.PropsWithChildren<Props>> = ({
  className = '',
  dropdownLayer = Layer.DropdownBoardConfig,
  filterPropertyRuleId,
  options,
  onOptionsAdded,
  onOptionRemoved,
  placeholder,
  selectedOptions,
  disabled = false,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  const onUnselectOption = useCallback((o: SelectOption) => onOptionRemoved(o.value), [onOptionRemoved]);
  const onSelectOption = useCallback((o: SelectOption) => onOptionsAdded([
    ...selectedOptions.map(selectableCompanyToOption),
    {
      ...o,
      selected: true,
    }]),
  [onOptionsAdded, selectedOptions]);

  return (
    <DropdownLayer
      layer={dropdownLayer}
      placement="bottom-start"
      visible={isDropdownVisible}
      hide={hideDropdown}
      width={490}
      disabled={disabled}
      withWrapper={false}
      content={(
        <CompanyFilterElementsDropdownContent
          defaultOptions={options}
          filterPropertyRuleId={filterPropertyRuleId}
          onSelectOption={onSelectOption}
          onUnselectOption={onUnselectOption}
          selectedCount={selectedOptions.length}
          selectedOptions={selectedOptions}
        />
      )}
    >
      <Container
        className={className}
        forceFocus={isDropdownVisible}
        onClick={toggleDropdown}
        tooltipProps={{
          content: selectedOptions.length > 1 ? selectedOptions.map(({ value }) => value.name).join(', ') : '',
          disabled: selectedOptions.length < 2,
          placement: 'top',
          withWrapper: false,
        }}
        disabled={disabled}
      >
        {selectedOptions?.[0]
          ? (
            <>
              <CompanyLogo size="S" company={selectedOptions[0].value} />
              <Truncate>{selectedOptions[0].value.name ?? ''}</Truncate>
              {selectedOptions.length > 1 && (
                <MoreValues>{`+${selectedOptions.length - 1}`}</MoreValues>
              )}
            </>
          )
          : (<Placeholder>{placeholder}</Placeholder>)}
      </Container>
    </DropdownLayer>
  );
};

export default CompanyFilterElements;
