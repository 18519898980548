/* eslint-disable no-param-reassign */
import { DRAG_ICON_SVG, DRAG_HANDLE_CLASS, PARAGRAPH_TYPE, HEADING_TYPE } from '../constants';
import { DraggingParams } from '../types/formatting.types';

type GetNodeViewParams = {
  className: string;
  el: string;
  instanceId: string;
  options: DraggingParams;
};
type GetNodeView = (params: GetNodeViewParams) => {
  dom: HTMLElement;
  contentDOM: HTMLDivElement;
};

const draggingNodeRefs: Element[] = [];
export const extensionReferences: Record<string, {
  el: HTMLDivElement;
  dragStartCallback: (event: DragEvent) => void;
  dragEndCallback: () => void;
}> = {};

export const getNodeView: GetNodeView = ({
  className,
  el,
  instanceId,
  options,
}) => {
  const dom = document.createElement(el);
  dom.className = className;
  /**
   * This make sure we can't drag from somewhere else than the drag handle
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  dom.style['-webkit-user-drag'] = 'none';
  const dragIcon = document.createElement('div');
  dragIcon.draggable = true;
  dragIcon.setAttribute('data-drag-handle', 'true');
  dragIcon.className = DRAG_HANDLE_CLASS;
  dragIcon.innerHTML = DRAG_ICON_SVG;
  dom.appendChild(dragIcon);

  const content = document.createElement('div');
  content.setAttribute('data-content', 'true');
  dom.appendChild(content);

  extensionReferences[instanceId] = {
    el: dragIcon,
    dragStartCallback: getDragStartCallback(content, options),
    dragEndCallback: getDragEndCallback(options),
  };

  const {
    el: element, dragEndCallback, dragStartCallback,
  } = extensionReferences[instanceId] || {};
  if (dragStartCallback) {
    element?.addEventListener('dragstart', dragStartCallback);
  }
  if (dragEndCallback) {
    element?.addEventListener('dragend', dragEndCallback);
  }

  return {
    dom,
    contentDOM: content,
  };
};

const getDragStartCallback = (el: HTMLSpanElement, options: DraggingParams) => (event: DragEvent) => {
  options?.onDragStart?.();
  const parent = el.parentElement;
  if (!parent || !event.dataTransfer) return;
  const insideElement = parent.querySelector('[data-content="true"]');
  if (!insideElement) return;
  const clone = insideElement.cloneNode(true);
  if (clone instanceof HTMLDivElement) { // Only for typing
    draggingNodeRefs.push(clone);
    styleCloneNode(clone, {
      width: insideElement.getBoundingClientRect().width,
      type: options.nodeType,
    });
    document.body.appendChild(clone);
    event.dataTransfer.setDragImage(clone, 10, 10);
  }
};
const getDragEndCallback = (options?: DraggingParams) => () => {
  options?.onDragEnd?.();
  draggingNodeRefs.forEach(nodeRef => {
    try {
      document.body.removeChild(nodeRef);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Fail to collect garbage');
    }
  });
};
const styleCloneNode = (
  clone: HTMLDivElement,
  options: {
    width: number;
    type: DraggingParams['nodeType'];
  },
) => {
  clone.style.paddingLeft = '18px';
  clone.style.width = `${options.width}px`;

  if (options.type === PARAGRAPH_TYPE) {
    clone.style.fontSize = '14px';
    clone.style.lineHeight = '1.5';
    clone.style.fontWeight = '400';
    clone.style.fontStyle = 'normal';
  }
  if (options.type === HEADING_TYPE) {
    clone.style.lineHeight = '1.5';
  }
};
