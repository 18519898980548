import { CollapseSidebarIcon } from '@cycle-app/ui/icons';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useGetDocPanel, setDocPanel, closePanel, getDocPanel, Content } from 'src/reactives/docRightPanel.reactive';
import { getInsightChildren, isFeedback } from 'src/utils/docType.util';

import { StyledActionButton } from './DocPanelHeader.styles';

export const ToggleRightPanel = () => {
  const docPanel = useGetDocPanel();
  const doctype = useDocPanelContext(ctx => ctx.doc?.doctype);

  const openPanel = () => {
    const { content } = getDocPanel();
    const showInsights = isFeedback(doctype) || getInsightChildren(doctype);
    const newContent = !showInsights && content === Content.Insights ? Content.Comments : content;
    setDocPanel({
      isExpanded: true,
      content: newContent ?? Content.Comments,
    });
  };

  return (
    <StyledActionButton
      onClick={() => {
        if (getDocPanel().isExpanded) {
          closePanel();
        } else {
          openPanel();
        }
      }}
    >
      <CollapseSidebarIcon size={18} direction={docPanel.isExpanded ? 'top' : 'bottom'} />
    </StyledActionButton>
  );
};
