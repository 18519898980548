import { Color, ContentSection, HeroSection, ReleaseTagStyle, Typography } from '@cycle-app/graphql-codegen';
import { CycleLogo, Tooltip } from '@cycle-app/ui';
import {
  FigmaIcon, AttioIcon, LinearIcon, JuneIcon, FramerIcon, FeyIcon, GumroadIcon, LoopsIcon,
} from '@cycle-app/ui/icons';
import { Controller } from 'react-hook-form';
import { useTheme } from 'styled-components';

import { ChangelogBuilderTemplateValue } from '../../hooks/releases/useChangelogBuilderForm';
import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { Grid, GridItem } from './ChangelogBuilderPreset.styles';
import { Section } from './ChangelogBuilderSidebar.styles';
import { templateCycleLight, templateCycleDark } from './ChangelogBuilderTopBar';
import { Title } from './LabelWithReset.styles';

export const changelogTemplates: ChangelogBuilderTemplateValue[] = [
  {
    value: 'cycle_light',
    label: 'Cycle',
    icon: <CycleLogo size={24} />,
    theme: templateCycleLight,
  },
  {
    value: 'fey',
    label: 'Fey',
    icon: <FeyIcon size={24} />,
    theme: {
      heroSection: HeroSection.M,
      contentSection: ContentSection.Uniform,
      backgroundColor: '#0B0B0F',
      headersColor: '#FFFFFF',
      linkColor: '#479FFA',
      bodyColor: '#868F97',
      dividerColor: '#2c2f32',
      shipBadgeColor: Color.F,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#E6E6E6',
      tagsColors: [
        '#1C2F2D',
        '#381F28',
        '#383C2E',
        '#3C302E',
        '#2E3C30',
        '#2E373C',
        '#362E3C',
        '#51384B',
      ],
    },
  },
  {
    value: 'figma',
    label: 'Figma',
    icon: <FigmaIcon size={24} />,
    theme: {
      heroSection: HeroSection.Xl,
      contentSection: ContentSection.ShowOff,
      backgroundColor: '#E5E5E5',
      headersColor: '#171717',
      linkColor: '#000000',
      bodyColor: '#495057',
      dividerColor: '#d2d2d2',
      shipBadgeColor: Color.J,
      releaseTagStyle: ReleaseTagStyle.Dot,
      releaseTagColor: '#495057',
      tagsColors: [
        '#0ACF83',
        '#1ABCFE',
        '#FF7262',
        '#A259FF',
        '#5960FF',
        '#FC59FF',
        '#FF5959',
        '#FFBD59',
      ],
    },
  },
  {
    value: 'loops',
    label: 'Loops',
    icon: <LoopsIcon size={24} />,
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#FFFFFF',
      headersColor: '#171717',
      linkColor: '#FC5200',
      bodyColor: '#000000',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.F,
      releaseTagStyle: ReleaseTagStyle.Dot,
      releaseTagColor: '#000000',
      tagsColors: [
        '#FD5D10',
        '#3F61D9',
        '#D93F3F',
        '#3FABD9',
        '#673FD9',
        '#BA3FD9',
        '#EEBF45',
        '#35CB71',
      ],
    },
  },
  {
    value: 'attio',
    label: 'Attio',
    icon: <AttioIcon size={24} />,
    theme: {
      heroSection: HeroSection.L,
      contentSection: ContentSection.Classic,
      backgroundColor: '#FFFFFF',
      headersColor: '#161B22',
      linkColor: '#245BC2',
      bodyColor: '#505967',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.K,
      releaseTagStyle: ReleaseTagStyle.Dot,
      releaseTagColor: '#505967',
      tagsColors: [
        '#266df0',
        '#F7CB6D',
        '#F06F26',
        '#7861F6',
        '#61AEF6',
        '#C661F6',
        '#F66161',
        '#60CA45',
      ],
    },
  },
  {
    value: 'june',
    label: 'June',
    icon: <JuneIcon size={24} />,
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#FFFFFF',
      headersColor: '#171717',
      linkColor: '#6868F7',
      bodyColor: '#495057',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.I,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#495057',
      tagsColors: [
        '#C9F0FD',
        '#FCEDCA',
        '#D7FED8',
        '#F9DDF9',
        '#FBEBE9',
        '#CCF7EF',
        '#CCE0F7',
        '#F7CCDF',
      ],
    },
  },
  {
    value: 'linear',
    label: 'Linear',
    icon: <LinearIcon size={24} />,
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#161618',
      headersColor: '#FFFFFF',
      linkColor: '#7185F1',
      bodyColor: '#F7F8F8',
      dividerColor: '#2c2f32',
      shipBadgeColor: Color.J,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#E6E6E6',
      tagsColors: [
        '#292C46',
        '#49392A',
        '#4B442C',
        '#46474A',
        '#4B322C',
        '#2C4B45',
        '#472C4B',
        '#2C4B37',
      ],
    },
  },
  {
    value: 'gumroad',
    label: 'Gumroad',
    icon: <GumroadIcon size={24} />,
    theme: {
      heroSection: HeroSection.S,
      contentSection: ContentSection.Discreet,
      backgroundColor: '#FFFFFF',
      headersColor: '#171717',
      linkColor: '#000000',
      bodyColor: '#495057',
      dividerColor: '#E6E6E6',
      shipBadgeColor: Color.H,
      releaseTagStyle: ReleaseTagStyle.Background,
      releaseTagColor: '#FFFFFF',
      tagsColors: [
        '#EE795B',
        '#59A7AC',
        '#F869BF',
        '#E68D4C',
        '#E64C4C',
        '#4CB8E6',
        '#4C65E6',
        '#AB4CE6',
      ],
    },
  },
  {
    value: 'framer',
    label: 'Framer',
    icon: <FramerIcon size={24} />,
    theme: {
      heroSection: HeroSection.L,
      contentSection: ContentSection.Sober,
      backgroundColor: '#000000',
      headersColor: '#FFFFFF',
      linkColor: '#FFFFFF',
      bodyColor: '#CCCCCC',
      dividerColor: '#2c2f32',
      shipBadgeColor: Color.C,
      releaseTagStyle: ReleaseTagStyle.Dot,
      releaseTagColor: '#CCCCCC',
      tagsColors: [
        '#00AAFF',
        '#0055FF',
        '#FF006B',
        '#FF8A00',
        '#FFE500',
        '#00F0FF',
        '#AD00FF',
        '#2BD565',
      ],
    },
  },
];

export const ChangelogBuilderPreset = () => {
  const {
    setValue, control, watch,
  } = useChangelogBuilderFormContext();
  const { isDark } = useTheme();
  const tags = watch('tags');
  return (
    <Section>
      <Title>Get inspired by the bests</Title>
      <Controller
        control={control}
        name="template"
        render={({ field }) => (
          <Grid>
            {changelogTemplates.map(v => (
              <Tooltip
                key={v.value}
                placement="top"
                content={v.label}
                withWrapper={false}
                offset={[0, -10]}
              >
                <GridItem
                  $isActive={(
                    !!field.value &&
                    (
                      v.value === field.value ||
                      // cycle_dark and cycle_light
                      (v.value.startsWith('cycle') && field.value?.startsWith('cycle'))
                    )
                  )}
                  type="button"
                  onClick={() => {
                    let { theme } = v;
                    if (v.value.startsWith('cycle')) {
                      // The default cycle template is the current user theme preference.
                      field.onChange(isDark ? 'cycle_dark' : 'cycle_light');
                      theme = isDark ? templateCycleDark : templateCycleLight;
                    } else {
                      field.onChange(v.value);
                    }
                    setValue('contentSection', theme.contentSection);
                    setValue('heroSection', theme.heroSection);
                    setValue('backgroundColor', theme.backgroundColor);
                    setValue('headersColor', theme.headersColor);
                    setValue('linkColor', theme.linkColor);
                    setValue('bodyColor', theme.bodyColor);
                    setValue('shipBadgeColor', theme.shipBadgeColor);
                    setValue('releaseTagStyle', theme.releaseTagStyle);
                    setValue('releaseTagColor', theme.releaseTagColor);
                    setValue('dividerColor', theme.dividerColor);
                    tags.forEach((_, tagIndex) => {
                      const color = theme.tagsColors[tagIndex] || theme.tagsColors.at(-1);
                      if (color) {
                        setValue(`tags.${tagIndex}.color`, color);
                      }
                    });
                    // Inter for all templates
                    setValue('typography', Typography.Inter);
                  }}
                >
                  {v.icon}
                </GridItem>
              </Tooltip>
            ))}
          </Grid>
        )}
      />
    </Section>
  );
};
