import { CloseIcon, SearchIcon } from '@cycle-app/ui/icons';
import { normalizeString } from '@cycle-app/utilities';
import { search } from 'fast-fuzzy';
import keyBy from 'lodash/keyBy';
import { ChangeEvent, useState, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { AddOnCard } from 'src/components/AddOnCard';
import { Modal, Header, HeaderActions, HeaderContent, Subtitle } from 'src/components/RoadmapTemplatesModal/RoadmapTemplatesModal.styles';
import { mapAddOnsData } from 'src/constants/addOn.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useProductAddOns } from 'src/hooks';
import { AddOn } from 'src/types/addOn.types';

import { Grid, Title, AddOnsInput, AddOnsClose } from './AddOns.styles';

export const AddOnsModal = ({ hide }: { hide: VoidFunction }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const onSearch = useDebouncedCallback((e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value), INPUT_ONCHANGE_DEBOUNCE);
  const addOns = useProductAddOns();

  const addOnItemsOff = useMemo(() => {
    const addOnByKey: Record<string, AddOn | undefined> = keyBy(addOns, 'name');
    return Object.entries(mapAddOnsData)
      .map(([key, data]) => ({
        data,
        flag: addOnByKey[key],
        searchKey: normalizeString(data.name),
      }))
      .filter(a => !a.flag?.isEnabled);
  }, [addOns]);

  const results = !searchQuery ? addOnItemsOff : search(searchQuery, addOnItemsOff, {
    keySelector: a => a.searchKey,
    threshold: 1,
  });

  return (
    <Modal hide={hide}>
      <Header>
        <HeaderContent>
          <Title>
            <p>Browse Add-ons</p>
          </Title>
          <div>
            <Subtitle>
              <p>Beef up your Pro plan with add-ons</p>
            </Subtitle>
          </div>
        </HeaderContent>
        <HeaderActions style={{ gap: '29px' }}>
          <AddOnsInput
            iconBefore={<SearchIcon size={16} />}
            placeholder="Search"
            onChange={onSearch}
            autoFocus
          />
          <AddOnsClose onClick={hide}>
            <CloseIcon />
          </AddOnsClose>
        </HeaderActions>
      </Header>
      <Grid>
        {results.map(a => (a.flag
          ? <AddOnCard key={a.flag.name} addOn={a.flag} search={searchQuery} />
          : null
        ))}
      </Grid>
    </Modal>
  );
};
