import {
  ReleaseDiscardChangesDocument,
  ReleaseEditPublishedDocument,
  ReleasePublicStatus,
  ReleasePublishDocument,
  ReleaseUnpublishDocument,
} from '@cycle-app/graphql-codegen';

import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';

import useSafeMutation from '../useSafeMutation';

export const useReleasePublicStatusUpdate = (releaseId: string) => {
  const [publishMutation] = useSafeMutation(ReleasePublishDocument);
  const [unpublishMutation] = useSafeMutation(ReleaseUnpublishDocument);
  const [editPublishedMutation] = useSafeMutation(ReleaseEditPublishedDocument);
  const [discardChangesMutation, discardState] = useSafeMutation(ReleaseDiscardChangesDocument);
  const { canUpdateRelease } = useGetPermission();

  const publish = () => {
    if (!canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return null;
    }
    return publishMutation({
      variables: {
        releaseId,
      },
      optimisticResponse: {
        publishRelease: {
          id: releaseId,
          publicStatus: ReleasePublicStatus.Published,
        },
      },
    });
  };

  const unpublish = () => {
    if (!canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return null;
    }
    return unpublishMutation({
      variables: {
        releaseId,
      },
      optimisticResponse: {
        unpublishRelease: {
          id: releaseId,
          publicStatus: ReleasePublicStatus.Unpublished,
        },
      },
    });
  };

  const editPublished = () => {
    if (!canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return null;
    }
    return editPublishedMutation({
      variables: {
        releaseId,
      },
      optimisticResponse: {
        editPublishedRelease: {
          id: releaseId,
          publicStatus: ReleasePublicStatus.Editing,
        },
      },
    });
  };

  const discardChanges = () => {
    if (!canUpdateRelease) {
      setLimitationsModal({ action: 'RELEASE_UPDATE' });
      return null;
    }
    return discardChangesMutation({
      variables: { releaseId },
    });
  };

  return {
    discardChanges,
    editPublished,
    publish,
    unpublish,
    isDiscardLoading: discardState.loading,
  };
};
