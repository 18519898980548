import DialogModal from 'src/components/DialogModal/DialogModal';
import { useWebhook } from 'src/hooks/api/queries/api/useWebhooks';
import { closeRemoveWebhookModal, useGetSettingsApi } from 'src/reactives/settingsApi.reactive';

import { useRemoveWebhook } from './useRemoveWebhook';

export const WebhookRemoveModal = () => {
  const { openedRemoveWebhookModal } = useGetSettingsApi();
  if (!openedRemoveWebhookModal) return null;
  return <WebhookRemoveModalVisible webhookId={openedRemoveWebhookModal} />;
};

const WebhookRemoveModalVisible = ({ webhookId }: { webhookId: string }) => {
  const { webhook } = useWebhook(webhookId);
  const removeClient = useRemoveWebhook(webhookId);
  return (
    <DialogModal
      title="Remove webhook"
      info={webhook ? `Are you sure you want to remove ${webhook.label}?` : undefined}
      hide={closeRemoveWebhookModal}
      confirmLabel="Remove"
      onConfirm={removeClient}
    />
  );
};
