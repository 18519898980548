import { ActionButton, SelectLine, ring2 } from '@cycle-app/ui';
import { CheckIcon } from '@cycle-app/ui/icons';
import { ImageSize } from '@cycle-app/utilities';
import { NodeViewWrapper } from '@tiptap/react';
import styled, { css } from 'styled-components';

import { DragHandle } from 'src/components/EditorDragMenu';

type ContainerProps = {
  $isSelected: boolean;
};

export const Container = styled(NodeViewWrapper) <ContainerProps>`
  ${p => p.$isSelected && css`
    ${ring2}
  `}

  border-radius: 8px;
  position: relative;

  li & {
    padding: 0;
  }

  &:hover ${DragHandle} {
    display: flex;
  }
`;

type ImageContainerProps = {
  $isSelected: boolean;
  $size: ImageSize;
};

export const VideoContainer = styled.div<ImageContainerProps>`
   border-radius: 8px;
   position: relative;
   cursor: ${p => (p.$isSelected ? 'grab' : 'pointer')};
`;

export const MenuContent = styled.div`
  display: flex;
`;

export const DragActionButton = styled(ActionButton)`
  cursor: grab;
`;

export const SelectLineItem = styled(SelectLine)`
  width: 150px;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  margin-top: 4px
`;

export const ErrorContainer = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  background-color: ${p => p.theme.colors.background.tertiary};
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
`;
