import { TippyProps } from '@tippyjs/react';
import styled from 'styled-components';

import DotsMenuLayer from '../../DotsMenuLayer/DotsMenuLayer';

export const StyledDotsMenuLayer = styled(DotsMenuLayer)`
  background: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
`;

export const RowMenuWrapper = styled.div<{ position: TippyProps['placement'] }>`
  transform: translateX(${p => (p.position === 'left' ? '4px' : '-4px')});
`;

export const ColumnMenuWrapper = styled.div<{ position: TippyProps['placement'] }>`
  transform: translateY(${p => (p.position === 'top' ? '4px' : '-4px')});
`;

export const AddButton = styled.button`
  background: pink;
  border-radius: 4px;
  background: ${p => p.theme.colors.background.hoverSoft};
  cursor: pointer;
  
  &:hover {
    background: ${p => p.theme.colors.background.hover};
  }
`;
