import { Popover, Props as PopoverProps } from '@cycle-app/ui';
import { AiIcon, CloseIcon, EditIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';

import { mappingZindex } from 'src/constants/zIndex.constant';
import { useDiscardQuote } from 'src/hooks/api/mutations/quotes/useDiscardQuote';
import { Layer } from 'src/types/layers.types';
import { requestInsightDelete } from 'src/utils/requestInsightDelete';
import { requestInsightUpdate } from 'src/utils/requestInsightUpdate';

import { PopoverContent, ButtonsList, ButtonItem } from './QuotesSummary.styles';

type QuotePopoverProps = Pick<PopoverProps, 'onMount' | 'onHide' | 'disabled'> & {
  children: ReactNode;
  buttons: ReactNode;
};

export const QuotePopover = ({
  buttons, ...props
}: QuotePopoverProps) => {
  return (
    <Popover
      {...props}
      controlled
      interactive
      animation
      withPortal
      withWrapper
      offset={[0, 0]}
      zIndex={mappingZindex[Layer.Dropdown]}
      placement="top-start"
      content={(
        <PopoverContent>
          <ButtonsList>
            {buttons}
          </ButtonsList>
        </PopoverContent>
      )}
    />
  );
};

export const VerifyQuote = ({ onClick }: { onClick: VoidFunction }) => {
  return (
    <ButtonItem
      onClick={() => {
        if (!requestInsightUpdate()) return;
        onClick();
      }}
    >
      <AiIcon hasGradient size={14} />
      Verify
    </ButtonItem>
  );
};

export const DiscardQuote = ({
  docId, quoteId,
}: { docId: string; quoteId: string }) => {
  const { discard } = useDiscardQuote();
  return (
    <ButtonItem
      onClick={() => {
        if (!requestInsightDelete()) return;
        discard(docId, quoteId);
      }}
    >
      <CloseIcon size={10} />
      Discard quote
    </ButtonItem>
  );
};

export const EditQuote = ({ onClick }: { onClick: VoidFunction }) => {
  return (
    <ButtonItem
      onClick={() => {
        if (!requestInsightUpdate()) return;
        onClick();
      }}
    >
      <EditIcon size={14} />
      Edit quote
    </ButtonItem>
  );
};

export const RemoveQuote = ({ onClick }: { onClick: VoidFunction }) => {
  return (
    <>
      <ButtonItem
        onClick={() => {
          if (!requestInsightDelete()) return;
          onClick();
        }}
      >
        <CloseIcon size={10} />
        Remove quote
      </ButtonItem>
    </>
  );
};
