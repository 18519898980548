import { LinearIssueFullFragment, IntegrationType, LinearProjectFragment } from '@cycle-app/graphql-codegen';
import { FC, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { LinearIssuesSearchList } from 'src/components/LinearIssuesSearchList/LinearIssuesSearchList';
import { Events, Methods } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useEditorContext } from 'src/contexts/editorContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import {
  Container,
  LoadingState,
} from 'src/editorExtensions/Github/GithubIssuesDropdown.styles';
import { useLinkLinearIssue } from 'src/hooks/api/mutations/integrations/useLinkLinearIssue';
import { useSearchLinearProjects } from 'src/hooks/api/queries/integrations/useLinearProjects';
import { useSearchLinearIssues } from 'src/hooks/api/queries/integrations/useSearchLinearIssues';
import { useCanAutomateLinear } from 'src/hooks/doc/useCanAutomateLinear';
import { useUpdateDocAutomation } from 'src/hooks/doc/useUpdateDocAutomation';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { LinearIssuesCommandAttributes } from 'src/types/editor.types';
import { IntegrationMentionRendererProps } from 'src/types/integrations.types';
import { Layer } from 'src/types/layers.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';

import { LinearProjectsSearchList } from '../LinearProjectsSearchList';

const MIN_LENGTH_QUERY = 3;

export const LinearSearchIssueDropdown: FC<React.PropsWithChildren<IntegrationMentionRendererProps>> = ({
  query, range, extensionName, type,
}) => {
  const {
    search,
    isSearchLoading,
    issues,
    isIntegrationLoading,
  } = useSearchLinearIssues();
  const {
    isLinearInstalled, linearIntegrationId,
  } = useWorkspaceContext();
  const {
    searchDebounced: searchProjects, projects, isSearchLoading: isSearchProjectsLoading,
  } = useSearchLinearProjects();
  const { link } = useLinkLinearIssue();
  const { doc } = useDocPanelProps();
  const editor = useEditorContext(ctx => ctx.editor);
  const canAutomateLinear = useCanAutomateLinear();
  const { updateDocAutomation } = useUpdateDocAutomation();

  const searchDebounced = useDebouncedCallback(search, INPUT_ONCHANGE_DEBOUNCE);

  useEffect(() => {
    if (query.length < MIN_LENGTH_QUERY || !linearIntegrationId) return;
    if (type === 'project') {
      searchProjects(query, linearIntegrationId);
    } else {
      searchDebounced(query);
    }
  }, [searchDebounced, query, linearIntegrationId, type, searchProjects]);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => editor.chain().focus().deleteRange(range).run(), {
    disableOnLayers: [
      Layer.DropdownBoardConfig, // team dropdown
      Layer.ModalZ2, // Linear Modal
    ],
  });

  const list = type === 'project' ? (
    <LinearProjectsSearchList
      docId={doc?.id}
      isIntegrationActive={isLinearInstalled}
      isLoading={isSearchProjectsLoading}
      onInsert={insertProject}
      results={projects}
      search={query}
    />
  ) : (
    <LinearIssuesSearchList
      docId={doc?.id}
      isIntegrationActive={isLinearInstalled}
      isLoading={isSearchLoading}
      onCreated={insertIssue}
      onLink={onLink}
      results={issues}
      search={query}
    />
  );

  return (
    <Container ref={ref}>
      {isIntegrationLoading
        ? <LoadingState />
        : list}
    </Container>
  );

  async function onLink(issue: LinearIssueFullFragment) {
    await Promise.all([
      link({
        variables: {
          docId: doc?.id || '',
          issueId: issue.id,
        },
      })?.then(() => {
        trackAnalytics(Events.IntegrationIssueMentioned, {
          type: IntegrationType.Linear,
          method: Methods.SlashCommand,
        });
      }),
      insertInEditor(issue),
    ]);
  }

  function insertProject(project: LinearProjectFragment) {
    return insertInEditor({
      id: project.id,
      icon: project.icon,
      iconColor: project.color,
      url: project.url,
      projectIssuesLength: project.issues.length,
      projectProgress: project.progress,
      title: project.name,
    });
  }

  function insertIssue(issue: LinearIssueFullFragment) {
    return insertInEditor({
      id: issue.id,
      url: issue.url,
      title: issue.title,
      publicId: issue.publicId,
      status: issue.status,
      teamKey: issue.team?.key,
      teamName: issue.team?.name,
      assignee: issue.assignee,
    });
  }

  async function insertInEditor(attrs: Partial<LinearIssuesCommandAttributes>) {
    editor
      .chain()
      .focus()
      .deleteRange({
        from: range?.from ?? 0,
        to: range?.to ?? 0,
      })
      .insertContent({
        type: extensionName,
        attrs: {
          ...attrs,
          type,
        },
      })
      .createParagraphNear()
      .run();
    if (canAutomateLinear && doc?.id && attrs.id && attrs.url) {
      await updateDocAutomation(doc.id, attrs.url, attrs.id);
    }
  }
};

export const LinearSearchProjectDropdown: FC<React.PropsWithChildren<IntegrationMentionRendererProps>> = props => {
  return <LinearSearchIssueDropdown {...props} type="project" />;
};
