import { ThemeType } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useIsWelcomePage } from 'src/hooks/useIsWelcomePage';
import { useNavigate } from 'src/hooks/useNavigate';
import { useGetPermission } from 'src/reactives';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { openSettingsAccount } from 'src/reactives/settingsModal.reactive';
import { getThemeConfig, setThemeConfig } from 'src/reactives/theme.reactive';
import { Layer } from 'src/types/layers.types';
import { Shortcut } from 'src/types/shortcuts.types';
import { getBoardSlug } from 'src/utils/slug.util';

import { openCommandBar } from '../modals/useCommandBarModal';
import { useIsRoadmapsEnabled } from '../roadmap';
import { useOnboardingPreviewBoard } from '../useOnboardingPreviewBoard';

export const useGlobalShortcutListener = () => {
  const isWelcomePage = useIsWelcomePage();
  const callbacks = useGlobalShortcutCallbacks();

  useHotkeyListener({
    callbacks,
    shortcuts: Object.values(Shortcut),
    enabled: () => !isWelcomePage,
    disableOnLayers: [Layer.Dropdown, Layer.Modal],
  });
};

export const useGlobalShortcutCallbacks = () => {
  const { navigate } = useNavigate();
  const boardInbox = useOnboardingPreviewBoard({ name: 'inbox' });
  const { isEnabled: isInboxEnabled } = useFeatureFlag('inbox');
  const { isEnabled: isInsightEnabled } = useFeatureFlag('insight-view');
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const isReleasesEnabled = useIsReleasesEnabled();
  const { canReadSettings } = useGetPermission();

  return useMemo(() => ({
    // Navigation
    ...canReadSettings && {
      [Shortcut.GoToSettings]: () => {
        if (getActiveProductTour()) return;

        openCommandBar('go-to-settings');
      },
    },
    [Shortcut.GoToHome]: () => {
      if (getActiveProductTour()) return;

      navigate(PageId.Main);
    },
    [Shortcut.GoToFeedback]: () => {
      if (getActiveProductTour()) return;

      if (isInboxEnabled) navigate(PageId.Inbox);
      else if (boardInbox) navigate(PageId.Board, { boardSlug: getBoardSlug(boardInbox) });
    },
    [Shortcut.GoToInsights]: () => {
      if (getActiveProductTour() || !isInsightEnabled) return;
      navigate(PageId.Insight);
    },
    [Shortcut.GoToRoadmap]: () => {
      if (getActiveProductTour() || !isRoadmapsEnabled) return;
      navigate(PageId.Roadmap);
    },
    [Shortcut.GoToReleases]: () => {
      if (getActiveProductTour() || !isReleasesEnabled) return;
      navigate(PageId.Releases);
    },
    // CommandBar
    [Shortcut.SwitchTheme]: () => {
      if (getActiveProductTour()) return;

      setThemeConfig({
        colorTheme: getThemeConfig().colorTheme === ThemeType.Nightfall
          ? ThemeType.Sunrise
          : ThemeType.Nightfall,
      });
    },
    [Shortcut.GoToWorkspace]: () => {
      if (getActiveProductTour()) return;

      openCommandBar('workspace');
    },
    [Shortcut.GoToMyAccount]: openSettingsAccount,
  }), [boardInbox, canReadSettings, isInboxEnabled, isInsightEnabled, isReleasesEnabled, isRoadmapsEnabled, navigate]);
};
