import { StatusCategory } from '@cycle-app/graphql-codegen';
import { CheckCircleIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { shortcuts, Shortcut } from 'src/constants/shortcuts.constants';
import { useToggleDocStatus } from 'src/hooks/doc/useToggleDocStatus';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { getDocItem } from 'src/reactives/docItem.reactive';
import { Layer } from 'src/types/layers.types';

import { ProcessButton, StyledShortcuts } from './DocProcessButton.styles';
import { DocProcessModal } from './DocProcessModal';

type DocProcessProps = {
  docId: string;
  docStatusCategory: StatusCategory;
  onCompleted?: VoidFunction;
  parent: 'doc-item' | 'doc-panel';
  quotesCount: number;
};

export const DocProcessButton: FC<React.PropsWithChildren<DocProcessProps>> = ({
  docId,
  docStatusCategory,
  onCompleted,
  parent,
  quotesCount,
}) => {
  const {
    isCompleted,
    toggleDocStatus,
    isDialogOpen,
    closeDialog,
    onConfirm,
  } = useToggleDocStatus({
    docId,
    docStatusCategory,
    onCompleted,
    quotesCount,
  });

  useHotkeyListener({
    callbacks: { [Shortcut.ProcessDoc]: toggleDocStatus },
    shortcuts: [Shortcut.ProcessDoc],
    disableOnLayers: [Layer.Dropdown],
    enabled: () => parent === 'doc-panel' || (parent === 'doc-item' && docId === getDocItem().hoverDocId),
  });

  return (
    <>
      <ProcessButton
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          toggleDocStatus();
        }}
        $isActive={isCompleted}
        $parent={parent}
        tooltipPlacement={parent === 'doc-panel' ? 'bottom' : 'top'}
        tooltip={(
          <StyledShortcuts
            colors="dark"
            shortcuts={[{
              label: isCompleted ? 'Mark to process' : 'Mark processed',
              keys: shortcuts[Shortcut.ProcessDoc],
            }]}
          />
      )}
      >
        <CheckCircleIcon />
      </ProcessButton>

      {isDialogOpen && (
        <DocProcessModal
          quotesCount={quotesCount}
          hide={closeDialog}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};
