import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji, SelectOption, Tooltip } from '@cycle-app/ui';
import { PenIcon, TrashIcon, InfoIconOutline, QuestionIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, useEffect, useMemo, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

import { DoctypeDeleteModal } from 'src/components/DoctypeDeleteModal/DoctypeDeleteModal';
import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { FeedbackLifecycle, FeedbackLifecycleStep } from 'src/components/FeedbackLifecycle';
import { SettingsPageId } from 'src/constants/routing.constant';
import useDoctypesMutations from 'src/hooks/api/mutations/useDoctypesMutations';
import { useInsightDocType } from 'src/hooks/api/useDocType';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { useFeedbackTour } from 'src/hooks/useFeedbackTour';
import { useInsightTour } from 'src/hooks/useInsightTour';
import { useNavigate } from 'src/hooks/useNavigate';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { usePageId } from 'src/hooks/usePageId';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import {
  getDocTypeName, isBuiltIn, isFeedback, isInsight, isCustom, getDocTypeInfo,
} from 'src/utils/docType.util';
import { getParams } from 'src/utils/routing.utils';

import { SavingLabelStyled } from '../Settings.styles';
import { Workflows } from '../SettingsWorkflows';
import { CustomersLink } from './CustomersLink';
import { DocTypeProperties } from './DocTypeProperties';
import { LinkedToRoadmaps } from './LinkedToRoadmaps';
import {
  PageContainer,
  HeaderStyled,
  Body,
  Title, SectionTitle,
  HeaderActions,
  Action,
  Separator,
  DoctypePropertiesContainer,
} from './SettingsDocType.styles';
import { SettingsDoctypeTemplate } from './SettingsDoctypeTemplate';

export const DRAFT_DOCTYPE = {
  id: 'new',
  sidebarName: 'Untitled',
  emoji: 'ok_hand',
};

export const SettingsFeedback = () => {
  const { docTypes } = useGetDocTypes();
  const feedbackId = Object.values(docTypes).find(d => d.type === DoctypeType.Feedback)?.id;
  if (!feedbackId) return null;
  return <SettingsDoctype doctypeId={feedbackId} step="feedback" />;
};

export const SettingsInsights = () => {
  const { docTypes } = useGetDocTypes();
  const insightId = Object.values(docTypes).find(d => d.type === DoctypeType.Insight)?.id;
  if (!insightId) return null;
  return <SettingsDoctype doctypeId={insightId} step="insight" />;
};

const SettingsDoctype = ({
  doctypeId, icon, isEditable = false, step,
}: {
  doctypeId?: string;
  icon?: ReactNode;
  isEditable?: boolean;
  step?: FeedbackLifecycleStep;
}) => {
  const { open: openFeedbackTour } = useFeedbackTour();
  const { open: openInsightTour } = useInsightTour();
  const pageId = usePageId() as SettingsPageId;

  const isDraft = doctypeId === DRAFT_DOCTYPE.id;
  const { doctypes } = useProductDoctypesFull();
  const { navigate } = useNavigateToSettings();

  const { deleteDoctype } = useDoctypesMutations(doctypeId);
  const [isEditModalVisible, { toggleCallback: toggleEditModal }] = useOptimizedBooleanState(false);
  const [isDeleteModalVisible, { toggleCallback: toggleDeleteModal }] = useOptimizedBooleanState(false);
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation, setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);
  const doctype = useMemo(() => doctypes.find(d => d.id === doctypeId), [doctypes, doctypeId]);
  const fallbackDoctype = doctypes[0];

  const insight = useInsightDocType();
  const insightParents = nodeToArray(insight?.parents);
  const isLastParentOfInsight = insightParents.length === 1 && insightParents[0]?.id === doctypeId;

  const editOptions: SelectOption[] = !isEditable ? [] : [
    {
      value: 'edit',
      label: 'Edit doc type',
      onSelect: toggleEditModal,
      icon: <PenIcon />,
    },
    {
      value: 'delete',
      label: 'Delete',
      onSelect: toggleDeleteModal,
      icon: <TrashIcon />,
      variant: 'danger',
      disabled: isBuiltIn(doctype) || isLastParentOfInsight,
      tooltipContent: `${insight?.name} must be linked to at least one doc type`,
    },
  ];

  // TODO: Fetch doctype's template when api ready

  const { getUrl } = useNavigate();

  const [name, setName] = useState(doctype?.name ?? '');
  const [emoji, setEmoji] = useState(doctype?.emoji ?? DRAFT_DOCTYPE.emoji);

  useEffect(() => {
    if (!isEditable) return;
    if (doctype) {
      setName(doctype.name);
      setEmoji(doctype.emoji);
    } else if (isDraft) {
      setName('');
      setEmoji(DRAFT_DOCTYPE.emoji);
    }
  }, [doctype, isDraft, isEditable]);

  if (!isDraft && !doctypeId) {
    return fallbackDoctype ? (
      <Redirect
        to={getUrl(pageId, { doctypeId: fallbackDoctype.id })}
      />
    ) : null;
  }

  return (
    <PageContainer>
      <HeaderStyled>
        {icon && isCustom(doctype) && <Emoji emoji={emoji} size={24} />}
        <Title>{getDocTypeName(doctype, true) || name}</Title>
        <HeaderActions>
          {isEditable && (
            <DotsMenuLayer
              options={editOptions}
              tooltip="Edit doc type"
            />
          )}

          {isFeedback(doctype) && (
            <Action
              tooltip="Learn more"
              tooltipPlacement="bottom"
              onClick={openFeedbackTour}
            >
              <InfoIconOutline size={14} />
            </Action>
          )}

          {isInsight(doctype) && (
            <Action
              tooltip="Learn more"
              tooltipPlacement="bottom"
              onClick={openInsightTour}
            >
              <InfoIconOutline size={14} />
            </Action>
          )}
        </HeaderActions>
        {isEditable && doctype && isEditModalVisible && (
          <DoctypesEditCommonModal
            doctype={doctype}
            onHide={toggleEditModal}
          />
        )}
        {isEditable && doctype && isDeleteModalVisible && (
          <DoctypeDeleteModal
            doctype={doctype}
            hide={() => {
              setDeleteConfirmationFalse();
              toggleDeleteModal();
            }}
            onConfirm={onDelete}
            onToggleConfirmation={toggleDeleteConfirmation}
            isConfirmDisabled={!isDeleteConfirmed}
          />
        )}
        <SavingLabelStyled />
        {isBuiltIn(doctype) && <CustomersLink />}
        <Separator />
        {step && <FeedbackLifecycle step={step} />}
      </HeaderStyled>

      <Body>
        {isInsight(doctype) && <LinkedToRoadmaps />}

        {isFeedback(doctype) && doctype && (
          <SettingsDoctypeTemplate
            isBuiltIn={isBuiltIn(doctype)}
            isFeedback={isFeedback(doctype)}
            docTypeId={doctype.id}
            templateId={doctype.template?.id}
            templateTitle={doctype.template?.title}
          />
        )}

        <DoctypePropertiesContainer>
          <SectionTitle>Properties</SectionTitle>
          <DocTypeProperties doctypeId={doctypeId} isDraft={isDraft} />
        </DoctypePropertiesContainer>

        <DoctypePropertiesContainer>
          <SectionTitle>
            Statuses
            {getDocTypeInfo(doctype) && (
              <Tooltip content={getDocTypeInfo(doctype)} placement="top">
                <QuestionIcon />
              </Tooltip>
            )}
          </SectionTitle>
          <Workflows docType={doctype} hideBorder hideActions />
        </DoctypePropertiesContainer>
      </Body>
    </PageContainer>
  );

  async function onDelete() {
    if (!doctype) return;
    await deleteDoctype();
    const availableDocs = doctypes.filter(({ id }) => id !== doctype.id);
    if (!availableDocs.length) {
      navigate(pageId);
      return;
    }
    const firstDoctypeAvailable = availableDocs[0];
    if (getParams().doctypeId === doctype.id && firstDoctypeAvailable) {
      navigate(pageId, { doctypeId: firstDoctypeAvailable.id });
    }
  }
};
