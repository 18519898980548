import { FC } from 'react';

import { SvgProps } from '../../components/Svg/Svg.styles';
import fs from './full-story.png';

export const FullStoryIcon: FC<React.PropsWithChildren<SvgProps>> = ({
  className, size,
}) => (
  <img className={className} width={size || 16} src={fs} alt="FullStory" />
);
