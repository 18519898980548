import { Svg } from '../../components/Svg/Svg.styles';
import { VIDEO_ICON_CLASS_NAME } from '../../constants/icons.constants';
import { Props } from '../../types/icon.types';

export const VolumeFullOutlineIcon = ({ className = '' }: Props) => (
  <Svg
    className={`${className} ${VIDEO_ICON_CLASS_NAME}`}
    width="16"
    height="12"
    viewBox="0 0 16 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path id="Icon" d="M12.7141 1.28644C13.9205 2.49287 14.6667 4.15954 14.6667 6.00049C14.6667 7.84144 13.9205 9.5081 12.7141 10.7145M10.5928 3.40753C11.2563 4.07107 11.6667 4.98774 11.6667 6.00026C11.6667 7.01278 11.2563 7.92945 10.5928 8.59298M2.66671 3.33332H3.51731C3.82922 3.33332 4.13127 3.22396 4.37089 3.02428L6.90659 0.911195C7.3408 0.549346 8.00004 0.858118 8.00004 1.42334V10.5766C8.00004 11.1418 7.3408 11.4506 6.90658 11.0888L4.37089 8.97569C4.13127 8.776 3.82922 8.66665 3.51731 8.66665H2.66671C1.93033 8.66665 1.33337 8.0697 1.33337 7.33332V4.66665C1.33337 3.93027 1.93033 3.33332 2.66671 3.33332Z" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
  </Svg>
);
