import styled from 'styled-components';

import { caption400, caption500, caption600 } from '../../theme/typo';
import { truncate } from '../../utils/styles.util';
import { Button } from '../Buttons/Button/Button';

interface StateProps {
  warning: boolean;
  danger: boolean;
}

export const Container = styled.div<StateProps>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px;
  border-radius: 10px;
  position: relative;

  color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.text.primary;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.text.primary;
    return p.theme.colors.components.FreePlan.default.text.primary;
  }};

  background: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.bg;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.bg;
    return p.theme.colors.components.FreePlan.default.bg;
  }};

  ${p => p.onClick && `
    cursor: pointer;
  `};

  ${p => p.onClick && !p.danger && !p.warning && `
    :hover {
      background: ${p.theme.colors.components.FreePlan.default.bgHover};
    }
  `}
  

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export const Text = styled.div`
  ${caption400}
  ${truncate}
  max-width: 100%;
  flex: none;
`;

export const ProgressTitle = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InfoIconContainer = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextSecondary = styled(Text) <StateProps>`
  color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.text.secondary;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.text.secondary;
    return p.theme.colors.components.FreePlan.default.text.secondary;
  }};
`;

export const Title = styled(Text)`
  ${caption500}
`;

export const UpgradeButton = styled(Button) <StateProps>`
  flex: none;
  width: auto;
  padding: 0 4px;
  max-width: 100%;
  gap: 4px;
  border-radius: 6px;
  padding-left: 8px;
  padding-right: 8px;
  ${caption600}
  ${truncate}

  background-color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.cta.bg;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.cta.bg;
    return p.theme.colors.components.FreePlan.default.cta.bg;
  }};
  color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.cta.text;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.cta.text;
    return p.theme.colors.components.FreePlan.default.cta.text;
  }};

  :hover, :focus {
    background-color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.cta.bgHover;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.cta.bgHover;
    return p.theme.colors.components.FreePlan.default.cta.bgHover;
  }};
  }

  :active {
    background-color: ${p => {
    if (p.danger) return p.theme.colors.components.FreePlan.danger.cta.bgActive;
    if (p.warning) return p.theme.colors.components.FreePlan.warning.cta.bgActive;
    return p.theme.colors.components.FreePlan.default.cta.bgActive;
  }};
  }
`;
