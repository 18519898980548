import { ViewType, DisplayablePropertiesConnectionFragment } from '@cycle-app/graphql-codegen';
import { SelectPanel, SelectOption, ButtonProps } from '@cycle-app/ui';
import { EyeClosedIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useCallback, useMemo, ReactNode } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { useIsRoadmapsEnabled } from 'src/hooks';
import useManageDisplayedProperties from 'src/hooks/api/mutations/boardConfig/useManageDisplayedProperties';
import { useIsCurrentViewInboxType } from 'src/hooks/inbox';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { ATTRIBUTE_ICON_MAPPING, getAttributeName } from 'src/utils/attributes.util';

import { Label, LightButton, DropdownContainer } from './BoardConfigForm.styles';

interface Props {
  boardConfigId: string;
  viewType: ViewType;
  properties: DisplayablePropertiesConnectionFragment;
  boardID?: string;
  className?: string;
  label?: ReactNode;
  variant?: ButtonProps['variant'];
  caret?: (isDropdownVisible: boolean) => ReactNode;
  isDisabled?: boolean;
}
const BoardConfigFormProperties: FC<React.PropsWithChildren<Props>> = ({
  boardConfigId,
  viewType,
  properties,
  boardID,
  className,
  label,
  variant,
  caret,
  isDisabled = false,
}) => {
  const {
    showBoardConfigProperty,
    hideBoardConfigProperty,
    showAllProperties,
    hideAllProperties,
  } = useManageDisplayedProperties(boardConfigId, boardID);
  const isCurrentViewInboxType = useIsCurrentViewInboxType();

  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDroprown,
  }] = useOptimizedBooleanState(false);

  const isReleasesEnabled = useIsReleasesEnabled();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const displayedPropertiesOptions: SelectOption[] = useMemo(
    () => nodeToArray(properties)
      // TODO: handle that sorting and filter in the api
      .filter(({ property }) => {
        if ([
          'DoctitleDefinition', 'ClapDefinition', 'CreatorDefinition', 'CreatedatDefinition',
        ].includes(property.__typename)) {
          return false;
        }
        if (viewType === ViewType.List && property.__typename === 'CoverDefinition') {
          return false;
        }
        if (!isReleasesEnabled && property.__typename === 'BuiltInReleaseDefinition') {
          return false;
        }
        if (
          !isRoadmapsEnabled &&
          boardID &&
          !isCurrentViewInboxType(boardID) &&
          property.__typename === 'StatusDefinition'
        ) {
          return false;
        }
        return true;
      })
      .map(({
        id: displayablePropertyId,
        displayed,
        property,
      }) => ({
        value: displayablePropertyId,
        label: getAttributeName(property),
        selected: displayed,
        icon: ATTRIBUTE_ICON_MAPPING[property.__typename],
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    [boardID, isCurrentViewInboxType, isReleasesEnabled, isRoadmapsEnabled, properties, viewType],
  );

  const hiddenPropertiesCount = useMemo(() => displayedPropertiesOptions.filter(o => !o.selected).length, [displayedPropertiesOptions]);

  const onSelectOption = useCallback((o: SelectOption) => showBoardConfigProperty(o.value), [showBoardConfigProperty]);
  const onUnselectOption = useCallback((o: SelectOption) => hideBoardConfigProperty(o.value), [hideBoardConfigProperty]);

  return (
    <>
      {Boolean(label) && <Label>{label}</Label>}

      <DropdownContainer>
        <DropdownLayer
          layer={Layer.DropdownModalZ1}
          visible={isDropdownVisible}
          hide={hideDropdown}
          placement="top-start"
          content={(
            <SelectPanel
              isMulti
              options={displayedPropertiesOptions}
              onSelectOption={onSelectOption}
              onUnselectOption={onUnselectOption}
              onSelectAll={showAllProperties}
              selectAllLabel="Show all"
              onUnselectAll={hideAllProperties}
              unselectAllLabel="Hide all"
            />
        )}
          disabled={isDisabled}
        >
          <LightButton
            onClick={isDisabled ? undefined : toggleDroprown}
            forceFocus={isDropdownVisible}
            className={className}
            variant={variant}
          >
            <EyeClosedIcon />
            <div>
              {hiddenPropertiesCount === 0
                ? 'Hide properties'
                : `${hiddenPropertiesCount} hidden propert${hiddenPropertiesCount === 1 ? 'y' : 'ies'}`}
            </div>
            {caret?.(isDropdownVisible)}
          </LightButton>
        </DropdownLayer>
      </DropdownContainer>
    </>
  );
};

export default BoardConfigFormProperties;
