import { AddNewDocDocument, DoctypeType } from '@cycle-app/graphql-codegen';
import { Button, Shortcuts, Tag, Emoji } from '@cycle-app/ui';
import { CloseIcon, ImageIcon, DownIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useReducer, useCallback, useEffect, useRef, useState } from 'react';

import DocPanelDocAttributes from 'src/app/Main/Board/DocPanel/DocPanelDocAttributes/DocPanelDocAttributes';
import CoverImageInputFile from 'src/components/CoverImageInputFile/CoverImageInputFile';
import { variantsContent } from 'src/components/DialogModal/DialogModal.motion';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocCustomer } from 'src/components/DocCustomer/DocCustomer';
import DocEditor from 'src/components/DocEditor/DocEditor';
import { DocStatus } from 'src/components/DocStatus';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { usePublishDoc } from 'src/hooks/api/mutations/usePublishDoc';
import { useProduct } from 'src/hooks/api/useProduct';
import { useDocPanelProps } from 'src/hooks/useDocPanelProps';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import useSafeMutation from 'src/hooks/useSafeMutation';
import {
  getCreateDoc, hideCreateDocModal, removeDraft, setDraftDocId, setDraftTitle, useGetCreateDoc,
} from 'src/reactives/createDoc.reactive';
import { useLastDoctypeIdUsed } from 'src/reactives/lastView.reactive';
import { useDefaultDocTypeStatus } from 'src/reactives/productStatus.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { ActionId } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';
import { ShortcutCreateModal } from 'src/types/shortcuts.types';
import { getDefaultFeedbackTitle } from 'src/utils/doc.util';

import { AddCoverAction } from '../DocPanel/DocPanelActions/DocPanelActions.styles';
import {
  Container,
  PortalModalStyled,
  Actions,
  BigActionButton,
  DocTop,
  Content,
  Toolbar,
  ContentEditableStyled,
  Attributes,
  Buttons,
  DocContainer,
  DocPanelCoverStyled,
  ScrollableContent,
  DocTypeLabel,
  SkeletonCustomer,
  SkeletonAvatar,
  SkeletonUsername,
  CustomerSelect,
  CustomerButton,
  Placeholder,
  StyledErrorPage,
  CustomerContainer,
} from './CreateDocModal.styles';
import { ToggleAutopilot } from './ToggleAutopilot';
import { useWithAutopilot } from './useWithAutopilot';

export const CreateDocModalWithBoardConfig = () => {
  const { modalVisible } = useGetCreateDoc();
  if (!modalVisible) return null;
  return (
    <BoardConfigContextProvider>
      <ModalContent />
    </BoardConfigContextProvider>
  );
};

// TODO: Refactor this overly-bloated component
// Note: There is a forked component, CreateDocFromFileModal that is specialized for publishing a draft doc from the dropzone
export const CreateDocModal = () => {
  const { modalVisible } = useGetCreateDoc();
  if (!modalVisible) return null;
  return <ModalContent />;
};

const ModalContent = () => {
  const lastDoctypeIdUsed = useLastDoctypeIdUsed();
  const defaultDoctypeId = getCreateDoc().doctypeId || lastDoctypeIdUsed;
  const { product } = useProduct();
  const defaultDoctype = nodeToArray(product?.doctypes).find(d => d.id === defaultDoctypeId);
  const isFeedback = defaultDoctype?.type === DoctypeType.Feedback;
  const [isCustomerSelected, setCustomerSelected] = useState(!isFeedback || (!!defaultDoctypeId && !!getCreateDoc().drafts[defaultDoctypeId]));

  const [isModalReady, setModalReady] = useReducer(() => true, false);
  const [isEditorReady, setEditorReady] = useReducer(() => true, false);

  const {
    doc,
    onTitleUpdated,
    onOpenCoverInputFile,
    setDocViewCoverInput,
    updateDocCover,
    getDropzonePropsCover,
    isDraggingCover,
    isUploadingCover,
  } = useDocPanelProps(true);

  const [createDoc] = useSafeMutation(AddNewDocDocument);
  const containerRef = useRef<HTMLDivElement>(null);

  const defaultStatus = useDefaultDocTypeStatus(defaultDoctypeId);

  const {
    publishDoc, isPublishingDoc,
  } = usePublishDoc();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isModalReady) return;
    async function createDraft() {
      const {
        doctypeId, drafts,
      } = getCreateDoc();

      if (!product || !doctypeId) return;

      if (!drafts[doctypeId]) {
        const createdDoc = await createDoc({
          variables: {
            groupId: '',
            title: '',
            doctypeId,
            productId: product.id,
            isDraft: true,
            customer: getCreateDoc().customerId ? { id: getCreateDoc().customerId ?? '' } : undefined,
          },
        });
        const addNewDoc = createdDoc.data?.addNewDoc;
        if (addNewDoc && getCreateDoc().modalVisible) setDraftDocId(addNewDoc.doctype.id, addNewDoc.id);
      }

      // We do not ask the user to select a customer there is a draft
      if (drafts[doctypeId]) {
        setCustomerSelected(true);
      }
      setEditorReady();
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createDraft();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalReady]);

  const focusToContent = useCallback(() => {
    const editor = containerRef.current?.querySelector('.ProseMirror');
    if (!(editor instanceof HTMLElement)) return;
    editor?.focus();
  }, [containerRef]);

  const isPublishDisabled = !isCustomerSelected;

  const [editorText, setEditorText] = useState('');

  useHotkeyListener({
    callbacks: {
      [ShortcutCreateModal.Save]: () => publishDoc(doc, {
        withAutopilot: withAutopilot && editorText.length > 0,
      }),
      [ShortcutCreateModal.SaveAndOpen]: () => publishDoc(doc, {
        openDoc: true,
        withAutopilot: withAutopilot && editorText.length > 0,
      }),
    },
    shortcuts: Object.values(ShortcutCreateModal),
    enabled: () => !isPublishDisabled || !doc,
  });

  // We won't automatically change the title if the user has edited it
  const [isAutoTitle, setIsAutoTitle] = useState(true);

  const inputTitleRef = useRef<HTMLDivElement | null>(null);

  // We update the title with the name of the selected customer,
  // or the company name if it's the main contact
  useEffect(() => {
    if (!doc?.customer || !isAutoTitle) return;

    const draftTitle = getCreateDoc().draftTitles[doc.doctype.id];
    const title = draftTitle || getDefaultFeedbackTitle(doc.customer);
    if (!title) return;

    if (inputTitleRef.current) {
      inputTitleRef.current.textContent = title;
    }
    onTitleUpdated(title);
    setTimeout(focusToContent);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc?.customer, isAutoTitle, focusToContent, onTitleUpdated]);

  const onDiscard = () => {
    hideCreateDocModal();
    if (doc?.doctype.id) {
      removeDraft(doc.doctype.id);
    }
  };

  const onHide = () => {
    hideCreateDocModal();
    if (!isCustomerSelected && doc?.doctype.id) {
      removeDraft(doc.doctype.id);
    }
  };

  const {
    withAutopilot, setWithAutopilot, isAutopilotEnabled,
  } = useWithAutopilot();

  const buttons = (
    <Buttons $isDisabled={!isCustomerSelected}>
      {isFeedback && (
        <ToggleAutopilot
          checked={withAutopilot}
          onChange={e => setWithAutopilot(e.target.checked)}
          disabled={!isAutopilotEnabled}
          onTooltipClick={hideCreateDocModal}
        />
      )}
      <Button
        variant="secondary"
        size="M"
        onClick={onDiscard}
      >
        Discard
      </Button>
      <Button
        isLoading={isPublishingDoc}
        onClick={() => publishDoc(doc, {
          withAutopilot: withAutopilot && editorText.length > 0,
        })}
        size="M"
        tooltipPlacement="top"
        tooltip={(
          <Shortcuts
            shortcuts={[
              {
                label: 'Save',
                keys: shortcuts[ShortcutCreateModal.Save],
              },
              {
                label: 'Save & Open',
                keys: shortcuts[ShortcutCreateModal.SaveAndOpen],
              },
            ]}
          />
        )}
        disabled={isPublishDisabled}
      >
        Create
      </Button>
    </Buttons>
  );

  return (
    <PortalModalStyled
      hide={onHide}
      motionVariants={variantsContent}
      onAnimationComplete={setModalReady}
      layer={Layer.DocPanel}
    >
      <Container ref={containerRef}>
        <ErrorBoundary fallback={<StyledErrorPage />}>
          {isMobile ? buttons : (
            <Actions>
              <BigActionButton
                onClick={onHide}
                tooltipPlacement="top"
                tooltip={(
                  <Shortcuts
                    shortcuts={[{
                      label: 'Cancel',
                      keys: ['esc'],
                    }]}
                  />
                )}
              >
                <CloseIcon />
              </BigActionButton>
            </Actions>
          )}

          <ScrollableContent>
            {isCustomerSelected && (
              <CoverImageInputFile
                ref={r => r && setDocViewCoverInput(r)}
                onCoverChanged={updateDocCover}
              />
            )}
            {isCustomerSelected && doc?.cover?.url && (
              <DocPanelCoverStyled
                docId={doc.id}
                coverUrl={doc.cover.url}
                getDropzoneProps={getDropzonePropsCover}
                isDragActive={isDraggingCover}
                onUpdateCoverClicked={onOpenCoverInputFile}
                withNonConsistentDesign
              />
            )}
            <Content>
              <DocTop className="doc-top">
                <Toolbar>
                  {!isCustomerSelected && (
                    <CustomerSelect>
                      {doc ? (
                        <DocCustomer
                          doc={doc}
                          openDropdownTimeout={150}
                          isRemovable={false}
                          showWarnings={false}
                          onDocUpdated={() => {
                            setCustomerSelected(true);
                          }}
                        >
                          <Placeholder>
                            Select customer…
                            <DownIcon size={14} />
                          </Placeholder>
                        </DocCustomer>
                      ) : (
                        <CustomerButton disabled isLoading />
                      )}
                    </CustomerSelect>
                  )}

                  {isCustomerSelected && (
                    <>
                      {doc?.doctype.customer ? (
                        <CustomerContainer>
                          <DocCompanyCustomer
                            doc={doc}
                            canRemoveCustomer={false}
                            showArrow
                          />
                        </CustomerContainer>
                      ) : isFeedback && (
                        <SkeletonCustomer>
                          <SkeletonAvatar />
                          <SkeletonUsername />
                        </SkeletonCustomer>
                      )}

                      {!isFeedback && !isMobile && doc && !doc?.cover?.url && (
                        <AddCoverAction
                          onClick={onOpenCoverInputFile}
                          userColor={isUploadingCover}
                          disabled={isUploadingCover}
                          size="L"
                        >
                          <ImageIcon />
                          {isUploadingCover ? 'Uploading…' : 'Add cover'}
                        </AddCoverAction>
                      )}
                    </>
                  )}
                </Toolbar>

                <ContentEditableStyled
                  ref={inputTitleRef}
                  placeholder="Untitled"
                  onChange={title => {
                    setIsAutoTitle(false);
                    onTitleUpdated(title);
                    if (doc?.doctype.id) setDraftTitle(doc.doctype.id, title);
                  }}
                  onNext={() => containerRef.current?.click()}
                  initialValue={doc?.title}
                  onEnter={focusToContent}
                  isDisabled={!doc || !isCustomerSelected}
                />

                <Attributes $isDisabled={!isCustomerSelected}>
                  {!!doc && (
                    <DocPanelDocAttributes
                      doc={doc}
                      layer={Layer.DropdownModal}
                      showDoctype={!isFeedback}
                      showAssignee
                    />
                  )}
                  {!doc && defaultDoctype && (
                    <>
                      {!isFeedback && (
                        <Tag color="grey">
                          <DocTypeLabel>
                            <Emoji emoji={defaultDoctype?.emoji} size={12} />
                            {defaultDoctype.name}
                          </DocTypeLabel>
                        </Tag>
                      )}
                      {defaultStatus?.id && (
                        <DocStatus
                          statusId={defaultStatus.id}
                          docTypeId={defaultDoctype.id}
                        />
                      )}
                    </>
                  )}
                </Attributes>
              </DocTop>
            </Content>
            <DocContainer $isDisabled={!isCustomerSelected}>
              {isEditorReady && (
                <DocEditor
                  defaultDoctypeId={defaultDoctypeId}
                  docId={doc?.id}
                  parentRef={containerRef}
                  displayLoader={isCustomerSelected}
                  isChildrenCreationEnabled={false}
                  applyTemplateOnDoctypeUpdate
                  hideHierarchy
                  hideEmptyBlock
                  disabledShortcuts={['Mod-Enter', 'Shift-Enter']}
                  disabledActions={[ActionId.TurnTextIntoInsight]}
                  onUpdate={props => setEditorText(props.text.trim())}
                />
              )}
            </DocContainer>
          </ScrollableContent>
          {!isMobile && buttons}
        </ErrorBoundary>
      </Container>
    </PortalModalStyled>
  );
};
