import { SelectPanel, typo } from '@cycle-app/ui';
import { Label, LineShortcut } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine.styles';
import { Key } from '@cycle-app/ui/components/Shortcut/Shortcut.styles';
import styled, { css } from 'styled-components';

export const CurrentProperty = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CurrentPropertyName = styled.div`
  ${typo.body600}
  line-height: 1;

  display: flex;
  align-items: center;
  gap: 4px;
  height: 20px;
`;

type ContainerProps = {
  $hasPadding: boolean;
};

export const Container = styled.div<ContainerProps>`
  padding: ${p => (p.$hasPadding ? '8px' : '8px 0 0 0')};
  min-width: 260px;

  ${p => !p.$hasPadding && css`
    ${CurrentPropertyName} {
      padding-left: 8px;
    }
  `}
`;

export const StyledSelectPanel = styled(SelectPanel)`
  padding: 0;
  ${Label} {
    flex: 1;
  }
  ${LineShortcut} {
    opacity: 1;
    justify-content: flex-start;
    width: 65px;
  }
  ${Key} {
    font-weight: 500;
    padding: 0;
    min-width: 0;
    text-transform: uppercase;
    background: transparent;
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const InputContainer = styled.div`
  padding: 12px;
`;

export const InputTextContainer = styled.div`
  margin-top: 12px;
`;
