import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useOpenBoardConfig } from 'src/hooks/boards/useOpenBoardConfig';

import { StyledButton } from './ViewHeader.styles';

export const ViewSettings = () => {
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const openBoardConfig = useOpenBoardConfig();
  if (!boardId) return null;
  return (
    <StyledButton
      onClick={() => openBoardConfig(boardId)}
    >
      Settings
    </StyledButton>
  );
};
