import { Color } from '@cycle-app/graphql-codegen';
import { Controller } from 'react-hook-form';

import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { Dots, StyledRadioInput, DotContainer, Dot } from './ChangelogBuilderChipBadge.styles';
import { Section } from './ChangelogBuilderSidebar.styles';
import { LabelWithReset } from './LabelWithReset';

const values = [
  Color.A,
  Color.B,
  Color.C,
  Color.D,
  Color.E,
  Color.F,
  Color.G,
  Color.H,
  Color.I,
  Color.J,
  Color.K,
];

export const ChangelogBuilderChipBadge = () => {
  const {
    control, watch, formState, resetField,
  } = useChangelogBuilderFormContext();
  const shipBadgeColor = watch('shipBadgeColor');
  const isDirty = shipBadgeColor !== formState.defaultValues?.shipBadgeColor;
  return (
    <Section>
      <LabelWithReset label="Ship badge" showReset={isDirty} reset={() => resetField('shipBadgeColor')} />
      <Controller
        control={control}
        name="shipBadgeColor"
        render={({ field }) => (
          <Dots>
            {values.map(v => (
              <StyledRadioInput
                key={v}
                id={v}
                value={v}
                checked={v === field.value}
                label={<DotContainer><Dot $color={v} /></DotContainer>}
                onChange={field.onChange}
              />
            ))}
          </Dots>
        )}
      />
    </Section>
  );
};
