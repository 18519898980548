import { TextSecondarySpan, Button, Flex } from '@cycle-app/ui';
import { RightIcon } from '@cycle-app/ui/icons';

export const OnboardingFooter = () => {
  return (
    <Flex $gap={8}>
      <TextSecondarySpan>Not quite ready for an onboarding?</TextSecondarySpan>
      <Button size="M" variant="nospace-primary" onClick={() => window.open('https://www.cycle.app/explore', '_blank')}>
        See Cycle in action
        <RightIcon />
      </Button>
    </Flex>
  );
};
