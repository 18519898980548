import { Color, SectionType } from '@cycle-app/graphql-codegen';
import { EmojiInput, Emoji } from '@cycle-app/ui';
import { EmojiData } from 'emoji-mart';
import { FC } from 'react';

import { useFeatureFlag } from '../../hooks';
import { Layer } from '../../types/layers.types';
import { BoardDefaultIcon } from '../BoardDefaultIcon';
import { IconDropdown, IconInput } from '../IconDropdown';
import { Label, Container } from './BoardIconInput.styles';

type BoardIconInputProps = {
  color: Color | null | undefined;
  icon: string | null | undefined;
  onColorChange: (value: Color) => void;
  onIconChange: (value: string) => void;
  sectionType: SectionType | undefined;
};

export const BoardIconInput: FC<React.PropsWithChildren<BoardIconInputProps>> = ({
  color, onIconChange, icon, onColorChange, sectionType,
}: BoardIconInputProps) => {
  const { isEnabled: isFeatueIconEnabled } = useFeatureFlag('icon');
  return (
    <Container>
      <Label>Icon</Label>
      {(isFeatueIconEnabled ? (
        <IconDropdown
          layer={Layer.DropdownModalZ1}
          placement="bottom-start"
          color={color}
          onIconChange={onIconChange}
          onColorChange={onColorChange}
          button={buttonProps => (
            <IconInput
              ref={buttonProps.ref}
              onClick={buttonProps.onClick}
              forceFocus={buttonProps['data-active']}
            >
              {icon && <Emoji emoji={icon} size={16} color={color} />}
              {!icon && <BoardDefaultIcon sectionType={sectionType} />}
            </IconInput>
          )}
        />
      ) : (
        <EmojiInput
          emoji={icon || ''}
          onSelect={({ id }: EmojiData) => id && onIconChange(id)}
          color={color ?? undefined}
        />
      ))}
    </Container>
  );
};
