import { Tooltip } from '@cycle-app/ui';
import { VFC } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { Link } from 'react-router-dom';

import { SOURCE_INTEGRATIONS, PREINSTALLED_SOURCE_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { PageId } from 'src/constants/routing.constant';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useUrl } from 'src/hooks/useUrl';
import { setDoctypeGraph } from 'src/reactives/doctypeGraph.reactive';
import { FlowData } from 'src/utils/flow.util';

import {
  IntegrationsContainer,
  IntegrationsContent,
  IntegrationsList,
} from './FlowElement.styles';

const useInstalledSourceIntegrations = () => {
  const { list } = useProductIntegrations();
  const integrations = Object.fromEntries(list.map(i => [i.type, i]));
  return [
    ...PREINSTALLED_SOURCE_INTEGRATIONS,
    ...SOURCE_INTEGRATIONS.filter(type => !!integrations[type]?.provider),
  ].map(type => ({
    ...integrationsDataMap[type],
    type,
  }));
};

export const FlowElementIntegrations: VFC<NodeProps<FlowData>> = ({ id: elementId }) => {
  const getUrl = useUrl();
  const integrations = useInstalledSourceIntegrations();

  return (
    <Tooltip
      title="Open"
      content="Integrations"
      placement="top"
      withPortal
      displayFullTitle
    >
      <Link to={getUrl(PageId.SettingsIntegrations)}>
        <IntegrationsContainer
          view="integrations"
          onMouseEnter={() => setDoctypeGraph({ elementHover: elementId })}
          onMouseLeave={() => setDoctypeGraph({ elementHover: null })}
        >
          <Handle
            type="source"
            position={Position.Right}
            isConnectable={false}
            style={{ opacity: 0 }}
          />

          <IntegrationsContent>
            <div>Sources</div>

            <IntegrationsList>
              {integrations.map(i => <span key={i.type}>{i.icon}</span>)}
            </IntegrationsList>
          </IntegrationsContent>
        </IntegrationsContainer>
      </Link>
    </Tooltip>
  );
};
