/* eslint-disable jsx-a11y/no-static-element-interactions */
import { BillingPlan, DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Tooltip, SelectPanel, SelectOption } from '@cycle-app/ui';
import { AddIcon, CloseIcon, ReleaseIcon, BulbIcon, AiIcon } from '@cycle-app/ui/icons';
import { nodeToArray, ERROR_CODE } from '@cycle-app/utilities';
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';
import { useMemo, useCallback, ReactNode, useState } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { Link } from 'react-router-dom';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { BILLING_LIMIT_CUSTOM_DOC_TYPES } from 'src/constants/billing.constants';
import { ErrorMessage } from 'src/constants/errors.constants';
import { useCurrentBilling, useProductAddOn } from 'src/hooks';
import useDoctypeParentsMutations from 'src/hooks/api/mutations/useDoctypeParentsMutations';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { useMustAddRoadmapsTemplate } from 'src/hooks/useCanExploreRoadmapsTemplates';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { useDocTypeUrl } from 'src/hooks/useUrl';
import { setDoctypeGraph, useGetDoctypeGraph } from 'src/reactives/doctypeGraph.reactive';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import {
  getPotentialRelatives, isInsight, isFeedback, isBuiltIn, isCustom, showDocTypeEmoji, getDocTypeName, isParentOfInsight, getCustomDocTypesCount,
} from 'src/utils/docType.util';
import { getNativeEmoji } from 'src/utils/emoji.util';
import { addErrorToaster } from 'src/utils/errorToasters.utils';
import { FlowData } from 'src/utils/flow.util';
import { openIntercom } from 'src/utils/intercom.utils';

import { openRoadmapsTemplateModal } from '../../reactives/roadmaps.reactive';
import DialogModal from '../DialogModal/DialogModal';
import { DoctypesEditCommonModal } from '../DoctypesEditCommonModal/DoctypesEditCommonModal';
import {
  AddAction,
  Container,
  Content,
  Label,
  RemoveAction,
  Disc,
  ChildrenArea,
  FeedbackChildrenArea,
  ParentArea,
  TooltipContent,
  Text,
  DeleteConnexion,
  DeleteConnexionIconContainer,
  DeleteInfo,
  DeleteModalStyled,
  DeletePreviewBlock,
  DeletePreview,
  DeletePreviewContent,
  DeletePreviewContainer,
  TagStyled,
  Icons,
} from './FlowElement.styles';

const styles = {
  handle: (position: Position) => ({
    ...position === Position.Top && { top: -10 },
    ...position === Position.Right && { right: -10 },
    ...position === Position.Bottom && { bottom: -10 },
    ...position === Position.Left && { left: -10 },
  }),
};

const FlowElement = ({
  data: {
    doctypeId,
    root,
    asPlaceholder,
    phantom,
    iteration = 0,
    target,
    rootId,
    isUnlinkableFromInsight = true,
    insightParentId,
  },
  targetPosition = Position.Top,
  sourcePosition = Position.Bottom,
  id: elementId,
}: NodeProps<FlowData>): ReactNode => {
  const mustAddRoadmapsTemplate = useMustAddRoadmapsTemplate();
  const currentDoctype = useDoctype();
  const view = currentDoctype ? 'doctype' : 'global';
  const { doctypes } = useProductDoctypesFull();
  const doctype = doctypes.find(d => doctypeId?.startsWith(d.id));
  const children = nodeToArray(doctype?.children);
  const [newDocTypeName, setNewDocTypeName] = useState<string | null>(null);
  const {
    rootChildrenHover, rootParentIsHover,
  } = useGetDoctypeGraph();

  const {
    addDoctypeParent,
    removeDoctypeParent,
    loading,
  } = useDoctypeParentsMutations(currentDoctype);

  const getDocTypeUrl = useDocTypeUrl();

  const doctypesAddOn = useProductAddOn('UNLIMITED_DOCTYPES');
  const { docTypes } = useGetDocTypes();
  const currentBilling = useCurrentBilling();

  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdownVisibility,
  }] = useOptimizedBooleanState(false);
  const [isHierarchyErrorModalVisible, {
    setTrueCallback: showHierarchyErrorModal,
    setFalseCallback: hideHierarchyErrorModal,
  }] = useOptimizedBooleanState(false);

  const onRelativeOptionAdded = useCallback(async (option: Pick<SelectOption, 'value'>) => {
    hideDropdown();

    // Wait for the dropdown to close, avoid flickering
    await nextFrame();

    if (target === 'parent') {
      // add selected to current
      const result = await addDoctypeParent(option.value, undefined, { errorPolicy: 'all' });
      if (result?.errors) {
        if (result.errors.find(e => (
          e.message === ERROR_CODE.CANT_ADD_DOCTYPE_PARENT_WHEN_DOCTYPES_ALREADY_LINKED_TO_RELEASE))) {
          showHierarchyErrorModal();
        } else {
          addErrorToaster({ message: ErrorMessage._GENERIC });
        }
      }
    } else if (target === 'children' && currentDoctype) {
      // add current to selected
      const doctypeChild = doctypes.find(d => d.id === option.value);
      const result = await addDoctypeParent(currentDoctype.id, doctypeChild, { errorPolicy: 'all' });
      if (result?.errors) {
        if (result.errors.find(e => (
          e.message === ERROR_CODE.CANT_ADD_DOCTYPE_PARENT_WHEN_DOCTYPES_ALREADY_LINKED_TO_RELEASE))) {
          showHierarchyErrorModal();
        } else {
          addErrorToaster({ message: ErrorMessage._GENERIC });
        }
      }
    }
  }, [addDoctypeParent, target, hideDropdown, currentDoctype, doctypes, showHierarchyErrorModal]);

  const potentialRelatives = useMemo(() => getPotentialRelatives(doctypes, currentDoctype, target), [currentDoctype, doctypes, target]);

  const placeholderIsVisible = rootId === rootChildrenHover || !rootId;

  const parentPlaceholderIsVisible =
    isBuiltIn(doctype) && asPlaceholder
      ? placeholderIsVisible
      : (rootParentIsHover ||
        (!root && elementId !== 'current') ||
        !asPlaceholder);

  const [showWarningModal, {
    setTrueCallback: setShowWarningModal,
    setFalseCallback: setHideWarningModal,
  }] = useOptimizedBooleanState(false);
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation, setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);

  const handleConfirm = useCallback(async () => {
    if (target === 'parent' && doctype) {
      await removeDoctypeParent(doctype.id);
    } else if (target === 'children' && currentDoctype) {
      await removeDoctypeParent(currentDoctype.id, doctype);
    }
  }, [doctype, removeDoctypeParent, target, currentDoctype]);

  const currentName = showDocTypeEmoji(currentDoctype)
    ? `${getNativeEmoji(currentDoctype?.emoji)} ${currentDoctype?.name}`
    : getDocTypeName(currentDoctype);

  const targetName = showDocTypeEmoji(doctype)
    ? `${getNativeEmoji(doctype?.emoji)} ${doctype?.name}`
    : getDocTypeName(doctype);

  if (asPlaceholder && elementId !== 'current' && isCustom(doctype)) {
    return renderDropdown();
  }

  return render();

  function render() {
    return (
      <>
        <Container
          view={view}
          phantom={phantom || !parentPlaceholderIsVisible}
          asPlaceholder={asPlaceholder}
          isButton={asPlaceholder && isCustom(doctype)}
          forceFocus={isDropdownVisible}
          highlighted={!asPlaceholder && doctypeId === currentDoctype?.id}
          onMouseEnter={onMouseEnterContainer}
          onMouseLeave={onMouseLeaveContainer}
        >
          <Handle
            type="target"
            position={targetPosition}
            isConnectable={false}
            style={{
              opacity: 0,
              ...styles.handle(targetPosition),
            }}
          />
          <Handle
            type="source"
            position={sourcePosition}
            isConnectable={false}
            style={{
              opacity: 0,
              ...styles.handle(sourcePosition),
            }}
          />
          <Handle
            type="target"
            id="insight-target"
            position={sourcePosition}
            isConnectable={false}
            style={{
              opacity: 0,
              left: '30%',
              bottom: -15,
            }}
          />

          {isCustom(doctype) && view === 'doctype' && doctype && doctype.id !== currentDoctype?.id && (
            <RemoveAction>
              <Tooltip
                withPortal
                placement="top"
                content={
                  isUnlinkableFromInsight || !currentDoctype
                    ? 'Remove'
                    : (
                      <TooltipContent>
                        <DocTypeIcon doctype={currentDoctype} />
                        <span>
                          {getDocTypeName(currentDoctype)}
                          {' '}
                          must be linked to at least one doc type
                        </span>
                      </TooltipContent>
                    )
                }
              >
                <Disc
                  disabled={!isUnlinkableFromInsight}
                  {...isUnlinkableFromInsight && { onClick: setShowWarningModal }}
                >
                  <CloseIcon />
                </Disc>
              </Tooltip>
            </RemoveAction>
          )}

          {asPlaceholder && renderPlaceholder()}
          {!asPlaceholder && doctype && renderDoctypeContent(doctype)}
        </Container>

        {view === 'doctype' && root && target === 'parent' && (
          <ParentArea
            onClick={e => e.stopPropagation()}
            onMouseEnter={onMouseEnterParent}
            onMouseOutCapture={onMouseLeaveParent}
          />
        )}

        {view === 'doctype' && target === 'children' && (
          <>
            {isFeedback(doctype) ? (
              <FeedbackChildrenArea
                onClick={e => e.stopPropagation()}
                onMouseEnter={onMouseEnterChildren}
                onMouseOutCapture={onMouseLeaveChildren}
              />
            ) : (
              <ChildrenArea
                onClick={e => e.stopPropagation()}
                onMouseEnter={onMouseEnterChildren}
                onMouseOutCapture={onMouseLeaveChildren}
              />
            )}
          </>
        )}

        {isHierarchyErrorModalVisible && (
          <DialogModal
            type="default"
            hide={() => hideHierarchyErrorModal()}
            title="Link doc type"
            info={(
              <>
                <div>
                  {`Sorry, you can't add this doc type as ${target === 'parent' ? 'parent' : 'child'} of `}
                  <TagStyled>{currentName}</TagStyled>
                  .
                </div>
                <div>Contact us if you want some help.</div>
              </>
            )}
            useHighMaskLayer
            cancelLabel="Maybe later"
            confirmLabel="Contact support"
            confirmButtonId="contact-sales"
            variantCancelButton="secondary"
            variantSubmitButton="primary"
            onConfirm={() => openIntercom()}
          />
        )}
        {showWarningModal && (
          <DeleteModalStyled
            title="Remove Hierarchy rule"
            info={(
              <>
                <DeletePreviewBlock>
                  <DeletePreview $isReversed={target !== 'parent'}>
                    <DeletePreviewContainer view="doctype">
                      <DeletePreviewContent>{targetName}</DeletePreviewContent>
                    </DeletePreviewContainer>
                    <DeleteConnexion>
                      <DeleteConnexionIconContainer>
                        <CloseIcon />
                      </DeleteConnexionIconContainer>
                    </DeleteConnexion>
                    <DeletePreviewContainer view="doctype">
                      <DeletePreviewContent>{currentName}</DeletePreviewContent>
                    </DeletePreviewContainer>
                  </DeletePreview>
                </DeletePreviewBlock>
                <DeleteInfo>
                  Are you sure you want to remove this hierarchy rule?
                  <br />
                  All corresponding docs will be unlinked. This can&apos;t be undone.
                </DeleteInfo>
              </>
            )}
            confirmLabel="Remove"
            confirmMessage="Yes, permanently remove this hierarchy rule"
            useHighMaskLayer
            hide={() => {
              setDeleteConfirmationFalse();
              setHideWarningModal();
            }}
            onConfirm={handleConfirm}
            loading={loading}
            isConfirmDisabled={!isDeleteConfirmed}
            onToggleConfirmation={toggleDeleteConfirmation}
          />
        )}

        {newDocTypeName !== null && (
          <DoctypesEditCommonModal
            initialName={newDocTypeName}
            onHide={() => setNewDocTypeName(null)}
            onCreated={docType => {
              if (!currentDoctype) return null;
              return target === 'parent'
                ? addDoctypeParent(docType.id)
                : addDoctypeParent(currentDoctype.id, docType);
            }}
          />
        )}
      </>
    );
  }

  function renderPlaceholder() {
    return doctype
      ? (
        <Content>
          <DocTypeIcon doctype={doctype} />
          {isBuiltIn(doctype) && (
            <>
              <Text>{getDocTypeName(doctype)}</Text>
              {iteration > 0 ? ` ${iteration}` : ''}
            </>
          )}
        </Content>
      )
      : (
        <Content>
          <AddAction>
            <AddIcon />
            <span>Add</span>
          </AddAction>
        </Content>
      );
  }

  function renderDoctypeContent(someDoctype: DoctypeFragment) {
    const isSelectedDoctype = someDoctype?.id === currentDoctype?.id;

    const content = (
      <Content>
        <Tooltip
          content="Click to open details"
          placement="top"
          withPortal
          withWrapper={false}
          displayFullTitle
          disabled={isSelectedDoctype}
        >
          <Label>
            <DocTypeIcon doctype={doctype} />
            <Text>
              {getDocTypeName(someDoctype)}
              {iteration > 0 ? ` ${iteration}` : ''}
            </Text>
          </Label>
        </Tooltip>

        {isParentOfInsight(someDoctype) && (
          <Icons>
            <Tooltip
              content="Linked to Insights"
              placement="top"
              withPortal
              withWrapper={false}
            >
              <BulbIcon size={17} />
            </Tooltip>
            <Tooltip
              content="Linked to Releases"
              placement="top"
              withPortal
              withWrapper={false}
            >
              <ReleaseIcon size={16} />
            </Tooltip>
            <Tooltip
              content="This doc type's name and description impact Cycle AI requests"
              placement="top"
              withPortal
              withWrapper={false}
            >
              <AiIcon size={16} />
            </Tooltip>
          </Icons>
        )}
      </Content>
    );

    return isSelectedDoctype
      ? content
      : (
        <Link to={getDocTypeUrl(someDoctype)}>
          {content}
        </Link>
      );
  }

  function renderDropdown() {
    if (!currentDoctype) return null;
    return (
      <DropdownLayer
        visible={isDropdownVisible}
        hide={hideDropdown}
        placement="bottom-start"
        content={(
          <SelectPanel
            title="Select doc type"
            options={potentialRelatives.map(d => ({
              value: d.id,
              label: getDocTypeName(d),
              icon: <DocTypeIcon doctype={d} />,
            }))}
            onOptionChange={onRelativeOptionAdded}
            onCreateOption={async (text) => {
              hideDropdown();
              if (
                !doctypesAddOn?.isEnabled &&
                currentBilling?.plan !== BillingPlan.BusinessPlus &&
                getCustomDocTypesCount(docTypes) >= BILLING_LIMIT_CUSTOM_DOC_TYPES
              ) {
                setLimitationsModal({
                  action: 'USE_ADD_ON',
                  brand: 'UNLIMITED_DOCTYPES',
                });
                return;
              }
              setNewDocTypeName(text);
            }}
            showCreateOptionIfEmpty
            defaultCreateOptionLabel="Create new doc type"
          />
        )}
      >
        <div
          onClick={mustAddRoadmapsTemplate ? openRoadmapsTemplateModal : toggleDropdownVisibility}
          style={{ opacity: placeholderIsVisible ? 1 : 0 }}
        >
          {render()}
        </div>
      </DropdownLayer>
    );
  }

  function onMouseEnterContainer() {
    if (rootId) {
      onMouseEnterChildren();
    }
    if (root) {
      onMouseEnterParent();
    }
    if (asPlaceholder) {
      return;
    }

    let insightParentHover = null;
    if (isInsight(doctype)) insightParentHover = insightParentId ?? null;
    if (children.some(isInsight)) insightParentHover = doctype?.id ?? null;
    setDoctypeGraph({
      elementHover: rootId || elementId,
      insightParentHover,
    });
  }

  function onMouseLeaveContainer() {
    if (asPlaceholder) {
      return;
    }
    setDoctypeGraph({
      elementHover: null,
      insightParentHover: null,
    });
  }

  function onMouseEnterChildren() {
    setDoctypeGraph({ rootChildrenHover: rootId || doctypeId || elementId });
  }

  function onMouseLeaveChildren() {
    setDoctypeGraph({ rootChildrenHover: null });
  }

  function onMouseEnterParent() {
    setDoctypeGraph({ rootParentIsHover: true });
  }

  function onMouseLeaveParent() {
    setDoctypeGraph({ rootParentIsHover: false });
  }
};

export default FlowElement;
