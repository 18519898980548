import { Color as ColorApi, MoveAttributeInProductListDocument, ProductBySlugDocument } from '@cycle-app/graphql-codegen';
import { Button, Emoji, Badge } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import { toObject } from '@cycle-app/utilities';
import { DndContext } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useMemo, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { ATTRIBUTES_LIMIT } from 'src/constants/attributes.constants';
import { HELP_URL_PROPERTIES } from 'src/constants/help.constants';
import { useParams, useProductAddOn } from 'src/hooks';
import { useAttributes } from 'src/hooks/api/useAttributes';
import { useProductDoctypes } from 'src/hooks/api/useProductDoctypes';
import { useLoader } from 'src/hooks/useLoader';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useSortableDnd } from 'src/hooks/useSortableDnd';
import { setLimitationsModal } from 'src/reactives';
import { getOptionFromDoctype } from 'src/utils/selectOptions.util';
import { updateQuery } from 'src/utils/update-cache/update-query.util';

import {
  Content,
  Header,
  SavingLabelStyled,
  HeaderAction,
} from '../Settings.styles';
import { SettingsAttributeEditModal } from './SettingsAttributeEditModal';
import {
  GridContainer,
  NoContent,
  PropertyPreview,
  PreviewColor,
  PreviewLine,
  PreviewTitle,
  PreviewSubTitle,
  Table,
  StyledDragOverlay,
  Action,
} from './SettingsAttributes.styles';
import { SettingsAttributesLine } from './SettingsAttributesLine';
import SettingsAttributesLineDraft from './SettingsAttributesLineDraft';
import SettingsAttributesLineSortable from './SettingsAttributesLineSortable';

const previews = [
  {
    color: ColorApi.A,
    emoji: ':cherry_blossom:',
  },
  {
    color: ColorApi.E,
    emoji: ':fire:',
  },
];

export const SettingsAttributes = () => {
  const attributes = useAttributes();
  const doctypes = useProductDoctypes();
  const [isAddingAttribute, setIsAddingAttribute] = useState(false);
  const [isNewAttributeModalOpened, { toggleCallback: toggleNewAttributeModal }] = useOptimizedBooleanState(false);
  const [moveAttributeInProductList, { loading }] = useSafeMutation(MoveAttributeInProductListDocument);
  useLoader({ loading });
  const addOnUnlimitedProperties = useProductAddOn('UNLIMITED_PROPERTIES');
  const { productSlug } = useParams();

  const {
    activeId,
    dndContextProps,
    items,
  } = useSortableDnd({
    initialItems: attributes.map(attribute => attribute.id),
    onFinish: async ({
      position, items: newItems,
    }) => {
      if (!activeId) return;
      await moveAttributeInProductList({
        variables: {
          attributeId: activeId,
          position,
        },
      });

      if (!productSlug) return;
      updateQuery({
        query: ProductBySlugDocument,
        variables: {
          slug: productSlug,
        },
        update: draft => {
          if (!draft?.product) return;
          // eslint-disable-next-line no-param-reassign
          draft.product.attributeDefinitions.edges = newItems.map(
            attributeId => draft.product?.attributeDefinitions.edges.find(e => e.node.id === attributeId),
          ).filter(isPresent);
        },
      });
    },
  });

  const attributesMapping = toObject(attributes);
  const activeAttribute = activeId ? attributesMapping[activeId] : null;

  const doctypesOptions = useMemo(() => doctypes.map(d => getOptionFromDoctype(d)), [doctypes]);

  return (
    <>
      <Header>
        <h1>
          Properties
          {attributes.length > 0 && <Badge>{attributes.length}</Badge>}
          <Action
            tooltip="Learn more"
            tooltipPlacement="bottom"
            onClick={() => window.open(HELP_URL_PROPERTIES, '_blank')}
          >
            <InfoIconOutline size={14} />
          </Action>
        </h1>
        <SavingLabelStyled />
        <HeaderAction
          size="L"
          disabled={isAddingAttribute || !addOnUnlimitedProperties.isLoaded}
          onClick={() => {
            if (!addOnUnlimitedProperties.isEnabled && attributes.length >= ATTRIBUTES_LIMIT) {
              setLimitationsModal({
                action: 'USE_ADD_ON',
                brand: 'UNLIMITED_PROPERTIES',
              });
              return;
            }
            toggleNewAttributeModal();
          }}
        >
          Add property
        </HeaderAction>
      </Header>

      {attributes.length === 0
        ? renderNoContent()
        : renderContent()}

      {isNewAttributeModalOpened && <SettingsAttributeEditModal onHide={toggleNewAttributeModal} />}
    </>
  );

  function renderNoContent() {
    return (
      <GridContainer>
        <NoContent>
          {previews.map((preview) => (
            <PropertyPreview key={preview.color}>
              <PreviewColor color={preview.color} />
              <PreviewLine />
              <Emoji emoji={preview.emoji} />
            </PropertyPreview>
          ))}
          <PreviewTitle>You have no custom properties</PreviewTitle>
          <PreviewSubTitle>Start by adding a new</PreviewSubTitle>
          <Button onClick={toggleNewAttributeModal}>Add new</Button>
        </NoContent>
      </GridContainer>
    );
  }

  function renderContent() {
    return (
      <DndContext {...dndContextProps}>
        <Content>
          <Table cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th> </th>
                <th colSpan={2} align="left">Name</th>
                <th align="left">Property type</th>
                <th align="left">Options</th>
                <th align="center">Linked to</th>
                <th align="center"> </th>
              </tr>
            </thead>
            <tbody>
              <SortableContext items={items} strategy={verticalListSortingStrategy}>
                {items.map((attributeId) => {
                  const lineAttribute = attributesMapping[attributeId];
                  return lineAttribute ? (
                    <SettingsAttributesLineSortable
                      key={attributeId}
                      attribute={lineAttribute}
                      doctypesOptions={doctypesOptions}
                      asPlaceholder={activeId === attributeId}
                      isDeleteDisabled={items.length <= 1}
                    />
                  ) : null;
                })}
              </SortableContext>
              {isAddingAttribute && (
                <SettingsAttributesLineDraft
                  onAttributeAdded={() => setIsAddingAttribute(false)}
                />
              )}
            </tbody>
            <StyledDragOverlay wrapperElement="tbody">
              {activeAttribute && (
                <SettingsAttributesLine
                  key={activeAttribute.id}
                  attribute={activeAttribute}
                  doctypesOptions={doctypesOptions}
                  isDragOverlay
                />
              )}
            </StyledDragOverlay>
          </Table>
        </Content>
      </DndContext>
    );
  }
};
