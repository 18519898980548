import { CreateStatusDocument, CreateStatusMutationVariables, namedOperations } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import useSafeMutation from 'src/hooks/useSafeMutation';
import { lockWorkflows, unlockWorkflows } from 'src/reactives/settingsWorkflows.reactive';

type Props = {
  onDone?: VoidFunction;
};

export const useCreateStatus = ({ onDone }: Props) => {
  const [mutate, result] = useSafeMutation(CreateStatusDocument, {
    refetchQueries: [namedOperations.Query.productStatusBySlug],
    awaitRefetchQueries: true,
    onCompleted: () => {
      unlockWorkflows();
      onDone?.();
    },
  });

  const createStatus = useCallback((variables: CreateStatusMutationVariables) => {
    lockWorkflows();
    return mutate({
      variables,
    });
  }, [mutate]);

  return {
    ...result,
    createStatus,
  };
};
