import { ReactNode } from 'react';

import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { Root } from './ChangelogBuilderContent.styles';

export const ChangelogBuilderContentRoot = ({ children }: { children: ReactNode }) => {
  const { watch } = useChangelogBuilderFormContext();
  const backgroundColor = watch('backgroundColor');
  const bodyColor = watch('bodyColor');
  const headersColor = watch('headersColor');
  const releaseTagColor = watch('releaseTagColor');
  const dividerColor = watch('dividerColor');
  const linkColor = watch('linkColor');
  const heroSection = watch('heroSection');
  const contentSection = watch('contentSection');
  const typography = watch('typography');
  return (
    <Root
      $heroSection={heroSection}
      $contentSection={contentSection}
      $typography={typography}
      style={{
        // @ts-ignore -- ignore css vars
        '--headersColor': headersColor,
        '--backgroundColor': backgroundColor,
        '--bodyColor': bodyColor,
        '--dividerColor': dividerColor,
        '--linkColor': linkColor,
        '--tagColor': releaseTagColor,
      }}
    >
      {children}
    </Root>
  );
};
