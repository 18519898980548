import { CloseIcon } from '@cycle-app/ui/icons';

import { useAccessToken } from 'src/hooks/api/queries/api/useAccessTokens';
import { closeEditApiKeyModal, useGetSettingsApi } from 'src/reactives/settingsApi.reactive';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { StyledModal, ModalTitle, CloseButton, KeyInput, CopyButton } from '../SettingsAPI.styles';

export const ApiKeyModal = () => {
  const tokenId = useGetSettingsApi().openedEditApiKeyModal;
  if (tokenId === null) return null;
  return (
    <StyledModal hide={closeEditApiKeyModal}>
      <ModalTitle>
        API key
      </ModalTitle>

      <ApiKeyToken tokenId={tokenId} />

      <CloseButton onClick={closeEditApiKeyModal}>
        <CloseIcon size={14} />
      </CloseButton>
    </StyledModal>
  );
};

const ApiKeyToken = ({ tokenId }: { tokenId: string }) => {
  const { accessToken } = useAccessToken(tokenId);
  if (!accessToken?.token) return null;
  return (
    <KeyInput
      value={accessToken.token}
      onChange={() => {}}
      iconAfter={<CopyButton size={16} />}
      onClickIcon={() => copyToClipboard({
        text: accessToken?.token,
        notification: 'API key copied to clipboard',
      })}
    />
  );
};
