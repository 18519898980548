import { ViewType } from '@cycle-app/graphql-codegen';
import { DraggableCard, typo, Button } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import ContentEditable from 'src/components/ContentEditable/ContentEditable';

export const DraggableCardStyled = styled(DraggableCard).attrs({
  draft: true,
  isSelected: true,
})<{ viewType: ViewType }>`
  border-radius: 8px;
  ${p => p.viewType === ViewType.List && `
    height: 40px;
  `}
`;

export const Form = styled.form<{ $viewType: ViewType }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${p => p.$viewType === 'LIST' && css`
    flex-direction: row;
  `}
`;

export const DoctypeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DoctypeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 2px 4px;
  height: 24px;
  min-width: 24px;
  color: ${p => p.theme.colors.text.secondary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 4px;
  cursor: pointer;
  ${typo.caption400}
  ${p => p.disabled && css`
    pointer-events: none;
  `};
  :hover, :focus, &[data-active=true] {
    background-color: ${p => p.theme.colors.background.tertiary};
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
`;

export const DocTitleEditable = styled(ContentEditable)<{ $viewType: ViewType }>`
  flex: 1;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${p => p.theme.colors.text.primary};
  ${typo.body400}

  ${p => p.$viewType === 'LIST' && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const WarningContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  color: ${p => p.theme.colors.text.disabled};
  :hover {
    color: ${p => p.theme.colors.text.red};
    background: ${p => p.theme.colors.background.redlight};
  }
`;

export const StyledButton = styled(Button)`
  --radius: 4px;
  padding: 1px 8px;
  font-weight: 600;
`;

export const DocTitleContainer = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const Tag = styled.span`
  border: 1px solid ${p => p.theme.colors.border.primary};
  border-radius: 4px;
  padding: 0 4px;
  margin: 0 6px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  height: 24px;
`;
