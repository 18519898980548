import { StatusFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useUpdateStatus } from 'src/hooks/status';
import useAppHotkeys from 'src/hooks/useAppHotkeys';

import { StyledInput } from './EditStatusForm.styles';

export type EditStatusFormProps = {
  status: StatusFragment;
  usedNames: string[];
  onDone: VoidFunction;
};

export const EditStatusForm = ({
  status, usedNames, onDone,
}: EditStatusFormProps) => {
  const { updateStatus } = useUpdateStatus();

  const {
    handleSubmit, register, formState,
  } = useForm<StatusFragment>({
    defaultValues: status,
  });

  const submit = handleSubmit(updateStatus);
  const onChange = useDebouncedCallback(submit, INPUT_ONCHANGE_DEBOUNCE);

  const inputProps = register('value', {
    setValueAs: v => v.trim(),
    validate: {
      minLength: v => v.length > 2 || 'You must have at least 3 characters',
      alreadyUsed: v => !usedNames.includes(v) || 'This name is already used',
    },
    onChange,
  });

  const error = formState.errors.value?.message;
  const { isDirty } = formState;

  const onSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (isDirty) submit();
    onDone();
  };

  useAppHotkeys('escape', onSubmit);
  useAppHotkeys('enter', onSubmit);

  return (
    <Tooltip
      placement="top"
      content={error}
      disabled={!error}
      visible={!!error}
    >
      <StyledInput
        error={error}
        autoFocus
        {...inputProps}
        onBlur={onSubmit}
      />
    </Tooltip>
  );
};
