import { make } from 'src/utils/reactives.util';

interface DocState {
  /**
   * We store IDs of generate summary mutations to keep track of progress.
   * If an ID is in the list, it means that the API is generating a summary
   * e.g.:
   * ```
   * {
   *   [docId]: mutationId
   * }
   * ```
   */
  generateSummaries: Record<string, string>;
}

/**
 * This reactive will store the current open doc in the tab, so we can store
 * data such as the syncing for the editor in order to make other components
 * aware of that to show a loading element somewhere else on the app.
 */
export const {
  hookValue: useGetDocSummary,
  getValue: getDocSummary,
  setValue: setDocSummary,
} = make<DocState>({
  defaultState: {
    generateSummaries: {},
  },
});
