import { useCallback, useEffect, useRef, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { useIsMobile } from '../reactives';
import { useWindowSize } from './useWindowSize';

export const useCarousel = () => {
  const isMobile = useIsMobile();
  const container = useRef<HTMLDivElement | null>(null);
  const [isScrollableRight, setIsScrollableRight] = useState(false);
  const [isScrollableLeft, setIsScrollableLeft] = useState(false);
  const { width } = useWindowSize();

  const checkScroll = useCallback(() => {
    if (isMobile) return null;
    const el = container.current;
    if (!el) return false;
    const scrollableSpace = (el.scrollWidth ?? 0) - (el.offsetWidth ?? 0);
    setIsScrollableRight(el.scrollLeft < scrollableSpace);
    setIsScrollableLeft(el.scrollLeft > 0);
    return null;
  }, [isMobile]);

  const handleScroll = useThrottledCallback(checkScroll, 300);

  useEffect(() => {
    checkScroll();
  }, [checkScroll, width]);

  const handleScrollClick = (direction: 'left' | 'right') => {
    if (!container.current) return;
    const scrollableWidth = container.current.scrollWidth ?? 0;
    const containerWidth = container.current.offsetWidth - 16;
    const scrollableSpace = scrollableWidth - containerWidth;

    if (scrollableSpace <= 0) return;

    const scrollLeft = container.current.scrollLeft ?? 0;
    const distanceToScroll = (containerWidth * 0.8);

    container.current.scroll({
      behavior: 'smooth',
      left: direction === 'left'
        ? scrollLeft - distanceToScroll
        : scrollLeft + distanceToScroll,
    });
  };

  return {
    container,
    handleScroll,
    handleScrollClick,
    isMobile,
    isScrollableLeft,
    isScrollableRight,
  };
};
