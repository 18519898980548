import { IntegrationType } from '@cycle-app/graphql-codegen';
import { CompanyLogo } from '@cycle-app/ui';
import { NextArrowIcon, BackArrowIcon } from '@cycle-app/ui/icons';
import { useMeasure } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';

import { DocSource } from 'src/components/DocSource';
import { ReadOnlyEditor } from 'src/components/Editor/Editors/ReadOnlyEditor';
import { useDocV2 } from 'src/hooks';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
import { setIntegrationSyncModal } from 'src/reactives/integrationSyncModal.reactive';
import { setOnboardingSlackStep, getOnboardingSlackDocId, stopOnboardingSlack } from 'src/reactives/onboardingSlack.reactive';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';

import { AssigneeContent } from '../../DocAssignee/AssigneeContent';
import {
  Container,
  Title,
  Text,
  PrevButton,
  NextButton,
  ButtonsGroup,
  Footer,
} from '../OnboardingSlack.styles';
import { ArrowTooltip } from './ArrowTooltip';
import {
  CustomerContainer,
  CustomerName,
  DocCardPreview,
  DocCardPreviewSkeleton,
  DocTitle,
  Body,
  BodyContainer,
  Attributes,
} from './StepFeedbackCreated.styles';

export const StepFeedbackCreated = () => {
  const {
    doc, isLoading,
  } = useDocV2(getOnboardingSlackDocId() || null, {
    onError: () => setOnboardingSlackStep(OnboardingSlackStep.CreateFeedback),
    onCompleted: (data) => {
      if (data.node?.__typename !== 'Doc') {
        setOnboardingSlackStep(OnboardingSlackStep.CreateFeedback);
      }
    },
  });
  const { yDoc } = useRealtimeEditorConfig({ docId: doc?.id || '' });
  const containerMeasure = useMeasure<HTMLDivElement>([isLoading, doc?.id]);

  const customerMeasure = useMeasure<HTMLDivElement>([isLoading, doc?.customer?.id]);
  const sourceMeasure = useMeasure<HTMLDivElement>([isLoading, doc?.source]);
  const assigneeMeasure = useMeasure<HTMLDivElement>([isLoading, doc?.assignee]);

  const tooltipCustomerMeasure = useMeasure<HTMLDivElement>([
    containerMeasure.rect?.width,
    containerMeasure.rect?.top,
    sourceMeasure.rect?.width,
    sourceMeasure.rect?.top,
  ]);
  const tooltipSourceMeasure = useMeasure<HTMLDivElement>([
    containerMeasure.rect?.width,
    containerMeasure.rect?.top,
    sourceMeasure.rect?.width,
    sourceMeasure.rect?.top,
  ]);
  const tooltipAssigneeMeasure = useMeasure<HTMLDivElement>([
    containerMeasure.rect?.width,
    containerMeasure.rect?.top,
    assigneeMeasure.rect?.width,
    assigneeMeasure.rect?.top,
  ]);

  return (
    <Container>
      <Title>Here is your first feedback</Title>
      <Text>A feedback is any message you send to Cycle, no matter the source.</Text>

      {isLoading
        ? <DocCardPreviewSkeleton />
        : doc && (
          <BodyContainer ref={containerMeasure.ref}>
            <AnimatePresence>
              {containerMeasure.rect && customerMeasure.rect && (
                <ArrowTooltip
                  key="customer"
                  ref={tooltipCustomerMeasure.ref}
                  label="Customer"
                  tooltip="Customers are people who give product feedback – they can be team members, users or prospects"
                  style={{
                    left: '15px',
                    top: `${customerMeasure.rect.top - containerMeasure.rect.top + customerMeasure.rect.height / 2}px`,
                    '--arrow-width': tooltipCustomerMeasure.rect
                      ? `${customerMeasure.rect.left - tooltipCustomerMeasure.rect.right - 24 + 8}px`
                      : 0,
                  }}
                />
              )}
              {containerMeasure.rect && sourceMeasure.rect && (
                <ArrowTooltip
                  key="source"
                  ref={tooltipSourceMeasure.ref}
                  label="Source"
                  tooltip="Where the feedback was written first"
                  style={{
                    left: '15px',
                    top: `${sourceMeasure.rect.top - containerMeasure.rect.top + sourceMeasure.rect.height / 2}px`,
                    '--arrow-width': tooltipSourceMeasure.rect
                      ? `${sourceMeasure.rect.left - tooltipSourceMeasure.rect.right - 24 + 8}px`
                      : 0,
                  }}
                />
              )}
              {containerMeasure.rect && assigneeMeasure.rect && (
                <ArrowTooltip
                  key="assignee"
                  ref={tooltipAssigneeMeasure.ref}
                  label="Assignee"
                  isRTL
                  tooltip="Assignees are responsible for closing the feedback loop with customers"
                  style={{
                    right: '15px',
                    top: `${assigneeMeasure.rect.top - containerMeasure.rect.top + assigneeMeasure.rect.height / 2}px`,
                    '--arrow-width': tooltipAssigneeMeasure.rect
                      ? `${tooltipAssigneeMeasure.rect.left - assigneeMeasure.rect.right - 24 + 8}px`
                      : 0,
                  }}
                />
              )}
            </AnimatePresence>
            <Body>
              <DocCardPreview>
                {!!doc.customer && (
                  <CustomerContainer ref={customerMeasure.ref}>
                    <CompanyLogo
                      company={doc.customer.company}
                      size="S"
                    />
                    <CustomerName>{doc.customer.displayName || doc.customer.email}</CustomerName>
                  </CustomerContainer>
                )}
                <DocTitle>{doc.title}</DocTitle>
                <Attributes>
                  <DocSource ref={sourceMeasure.ref} docId={doc.id} source={doc?.source} showName={false} />
                  <AssigneeContent ref={assigneeMeasure.ref} assignee={doc.assignee} context="doc-panel" showLabel />
                </Attributes>
                <ReadOnlyEditor
                  interactive={false}
                  collaboration={yDoc ? { document: yDoc } : undefined}
                />
              </DocCardPreview>
            </Body>
          </BodyContainer>
        )}

      <Footer>
        <ButtonsGroup>
          <PrevButton onClick={() => setOnboardingSlackStep(OnboardingSlackStep.CreateFeedback)}>
            <BackArrowIcon />
            {' '}
            Back
          </PrevButton>
          <NextButton
            onClick={() => {
              stopOnboardingSlack();
              setIntegrationSyncModal({ integrationType: IntegrationType.Slack });
            }}
          >
            Done
            {' '}
            <NextArrowIcon />
          </NextButton>
        </ButtonsGroup>
      </Footer>
    </Container>
  );
};
