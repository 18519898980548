import { Flex, boxShadowZ3, RadioInput } from '@cycle-app/ui';
import { CycleStandardIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

import iconBg from './bg.svg';

export const Main = styled.form`
  margin-top: 32px;

  > :last-child {
    margin-top: 8px;
  }
`;

export const Aside = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const AsideContent = styled.div`
  display: flex;
`;

export const IconContainer = styled(Flex)`
  width: 112px;
  height: 112px;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    right: 0;
    width: 150%;
    height: 80px;
    background: url("${iconBg}");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    z-index: 1;

    ${p => p.theme.isDark && 'display: none;'}
  }
`;

export const Icon = styled(Flex)`
  border-radius: 24px;
  background: linear-gradient(269.66deg, rgba(255, 255, 255, 0) -14.58%, rgba(255, 255, 255, 0.82) 15.06%, rgba(255, 255, 255, 0.64726) 41.84%, rgba(255, 255, 255, 0) 99.71%);
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 46px 2px rgba(255, 255, 255, 0.9) inset;
  position: relative;
  z-index: 2;

  ${p => p.theme.isDark && css`
    ${boxShadowZ3}
    background: linear-gradient(180deg, #404040 0%, #303030 100%);
    border-top: 1px solid #4D4D4D;
  `}
`;

export const Cycle = styled(CycleStandardIcon)`
  width: 54px;
  height: 54px;
`;

export const ArrowsLeft = styled(Flex)`
  color: ${p => p.theme.colors.text.disabled};

  > :last-child {
    opacity: .25;
  }
  > :first-child + * {
    opacity: .5;
  }
`;

export const ArrowsRight = styled(Flex)`
  color: ${p => p.theme.colors.text.disabled};

  > :first-child {
    opacity: .25;
  }
  > :first-child + * {
    opacity: .5;
  }
`;

export const StyledRadioInput = styled(RadioInput)`
  &:first-child {
    margin-bottom: 8px;
  }
`;
