import { LINEAR_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { FC, useCallback } from 'react';

import { useEditorContext } from 'src/contexts/editorContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useLinkLinearIssue } from 'src/hooks/api/mutations/integrations/useLinkLinearIssue';
import { useCanAutomateLinear } from 'src/hooks/doc/useCanAutomateLinear';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { ShortcutEditor } from 'src/types/shortcuts.types';
import { insertIssues } from 'src/utils/editor/editor.utils';

import { useUpdateDocAutomation } from '../../hooks/doc/useUpdateDocAutomation';
import { AutomationType } from '../../types/automation.types';
import { LinearSearch } from '../DocLinear/LinearSearch';
import { LinearSkeletons } from '../DocLinear/LinearSkeletons';
import { LinearIssueCreationModal } from './LinearIssueCreationModal';
import { StyledDropdownLayer, Trigger } from './LinearIssueCreationModal.styles';

type Props = {
  children: JSX.Element;
  shortcut?: ShortcutEditor;
};

export const LinearIssueCreationModalTrigger: FC<React.PropsWithChildren<Props>> = ({
  children, shortcut,
}) => {
  const [isDropdownOpened, {
    toggleCallback: toggleDropdown, setTrueCallback: openDropdown, setFalseCallback: closeDropdown,
  }] = useOptimizedBooleanState(false);
  const [isModalOpened, {
    toggleCallback: toggleModal, setTrueCallback: openModal, setFalseCallback: closeModal,
  }] = useOptimizedBooleanState(false);
  const editor = useEditorContext(ctx => ctx.editor);
  const canAutomateLinear = useCanAutomateLinear();
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const { updateDocAutomation } = useUpdateDocAutomation();

  const { state } = editor;
  const {
    from, to,
  } = state.selection;
  const newTitles = getDocTitlesFromSelectedText(editor.state);
  const isMulti = newTitles.selectionValues.length > 1;
  const text = state.doc.textBetween(from, to, ' ');

  const handleToggle = useCallback((e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isMulti) {
      toggleModal();
    } else {
      toggleDropdown();
    }
  }, [toggleDropdown, toggleModal, isMulti]);

  const linearIntegrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  const docId = useEditorContext(ctx => ctx.doc?.id);
  const linkMutation = useLinkLinearIssue();

  useAppHotkeys('command+3', handleToggle, {
    enabled: () => !!shortcut && shortcut === ShortcutEditor.TurnLinearIssue,
  });
  useAppHotkeys('command+"', handleToggle, {
    enabled: () => !!shortcut && shortcut === ShortcutEditor.TurnLinearIssue,
  });

  const onInsert = async ({
    type, id, url,
  }: { type: AutomationType; id: string; url?: string | null }) => {
    if (!docId) return;
    insertIssues({
      editor,
      newIssues: [{
        id,
        type,
      }],
      extensionName: LINEAR_EXTENSION_NAME,
    });
    const promises = [
      ...(type === 'issue' ? [linkMutation.link({
        variables: {
          docId,
          issueId: id,
        },
      }),
      ] : []),
    ];
    if (canAutomateLinear && url) {
      promises.push(updateDocAutomation(docId, url, id));
    }
    await Promise.all(promises);
  };

  return isMulti ? (
    <>
      <Trigger onClick={openModal}>
        {children}
      </Trigger>
      {isModalOpened && <LinearIssueCreationModal onHide={closeModal} />}
    </>
  ) : (
    <StyledDropdownLayer
      hide={closeDropdown}
      placement="bottom"
      visible={isDropdownOpened}
      content={docId && linearIntegrationId && (
        <LinearSearch
          defaultSearchText={text}
          defaultSearchType="issue"
          docId={docId}
          hide={closeDropdown}
          integrationId={linearIntegrationId}
          onCreate={async (data) => {
            const isIssue = data.__typename === 'LinearIssue';
            await onInsert({
              id: data.id,
              url: data.url,
              type: isIssue ? 'issue' : 'project',
            });
          }}
          onSelect={onInsert}
          skeletons={<LinearSkeletons />}
        />
      )}
    >
      <Trigger onClick={openDropdown}>
        {children}
      </Trigger>
    </StyledDropdownLayer>
  );
};
