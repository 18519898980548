import { RessourceType } from '@cycle-app/graphql-codegen';
import { ActionButton, Button, Input, CheckboxInput, Tooltip } from '@cycle-app/ui';
import { Label } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { TrashIcon, AddIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { urlRegex } from '@cycle-app/utilities';
import mapValues from 'lodash/mapValues';
import { useFieldArray, useForm } from 'react-hook-form';

import { useOAuthClient } from 'src/hooks/api/queries/api/useOAuthClients';
import { closeEditAppModal } from 'src/reactives/settingsApi.reactive';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { CopyButton, KeyInput } from '../SettingsAPI.styles';
import {
  Form, FormFooter, StyledToggleInput, Actions, FieldSet, FieldArray, FieldItem, FieldTitle, PublicField,
} from './OAuthApp.styles';
import { useCreateOAuthClient } from './useCreateOAuthClient';
import { useUpdateOAuthClient, UpdateOAuthClientVariables } from './useUpdateOAuthClient';

type FormData = {
  name: string;
  description: string;
  developerName: string;
  developerUrl: string;
  webhookRessources: RessourceType[];
  webhookUrl: string;
  // flat field array is not supported by react-hook-form
  callbackUrls: { url: string }[];
  isPublic: boolean;
};

export const OAuthAppForm = ({ clientId }: { clientId: string }) => {
  const { client } = useOAuthClient(clientId);
  const createClient = useCreateOAuthClient();
  const updateClient = useUpdateOAuthClient();

  const {
    control, handleSubmit, register, formState,
  } = useForm<FormData>({
    defaultValues: {
      name: client?.name ?? '',
      description: client?.description ?? '',
      developerName: client?.developerName ?? '',
      developerUrl: client?.developerUrl ?? '',
      webhookRessources: client?.webhookRessources ?? [],
      webhookUrl: client?.webhookUrl ?? '',
      callbackUrls: client?.callbackUrls.map(url => ({ url })) ?? [{ url: '' }],
      isPublic: client?.isPublic ?? false,
    },
  });

  const {
    fields, append, remove,
  } = useFieldArray({
    control,
    name: 'callbackUrls',
  });

  return (
    <Form
      onSubmit={handleSubmit(values => {
        closeEditAppModal();
        const callbackUrls = values.callbackUrls.map(item => item.url);

        if (!clientId) {
          return createClient({
            ...values,
            callbackUrls,
          });
        }

        if (!client) return null;

        const dirtyValues = mapValues(
          formState.dirtyFields,
          (_, key) => values[key as keyof FormData],
        );

        const variables = {
          ...dirtyValues,
          callbackUrls,
        } as UpdateOAuthClientVariables;

        return updateClient(variables, client);
      })}
    >
      {clientId && (
        <KeyInput
          label="Client ID"
          value={clientId}
          onChange={() => {}}
          iconAfter={<CopyButton size={16} />}
          onClickIcon={() => {
            if (!clientId) return;
            copyToClipboard({
              text: clientId,
              notification: 'Client ID copied to clipboard',
            });
          }}
        />
      )}

      <Input
        label="Name"
        placeholder="Application name"
        autoFocus
        autoComplete="off"
        error={formState.errors.name?.message}
        {...register('name', { required: 'Name is required' })}
      />

      <Input
        label="Description"
        placeholder="Application description"
        error={formState.errors.description?.message}
        {...register('description')}
      />

      <Input
        label="Developer name"
        placeholder="Developer name"
        error={formState.errors.developerName?.message}
        {...register('developerName', { required: 'Developer name is required' })}
      />

      <Input
        label="Developer URL"
        placeholder="https://mydeveloperurl.com"
        error={formState.errors.developerUrl?.message}
        {...register('developerUrl', {
          required: 'Developer URL is required',
          pattern: {
            value: urlRegex,
            message: 'Developer URL must be a valid URL',
          },
        })}
      />

      <Input
        label="Webhook URL"
        placeholder="https://mywebhookurl.com"
        error={formState.errors.webhookUrl?.message}
        {...register('webhookUrl')}
      />

      <div>
        <Label>Webhook resources</Label>
        <FieldSet>
          <CheckboxInput
            id="webhookRessourcesComment"
            label="Comment created"
            value={RessourceType.CommentCreated}
            {...register('webhookRessources')}
          />
          <CheckboxInput
            id="webhookRessourcesDoc"
            label="Doc created"
            value={RessourceType.DocCreated}
            {...register('webhookRessources')}
          />
          <CheckboxInput
            id="webhookRessourcesStatus"
            label="Status changed"
            value={RessourceType.StatusChanged}
            {...register('webhookRessources')}
          />
        </FieldSet>
      </div>

      {client?.webhookSecret && (
        <KeyInput
          label="Webhook secret"
          value={client.webhookSecret}
          onChange={() => {}}
          iconAfter={<CopyButton size={16} />}
          onClickIcon={() => {
            if (!client.webhookSecret) return;
            copyToClipboard({
              text: client.webhookSecret,
              notification: 'Webhook secret copied to clipboard',
            });
          }}
        />
      )}

      <div>
        <FieldTitle>
          <Label>Callback URLs</Label>
          <ActionButton
            tooltip="Add callback URL"
            onClick={() => append({ url: '' })}
          >
            <AddIcon />
          </ActionButton>
        </FieldTitle>

        <FieldArray>
          {fields.map((item, index) => (
            <FieldItem key={item.id}>
              <Input
                placeholder="https://mycallbackurl.com"
                error={formState.errors.callbackUrls?.[index]?.url?.message}
                {...register(`callbackUrls.${index}.url`, {
                  required: 'Callback URL is required',
                  pattern: {
                    value: urlRegex,
                    message: 'Callback URL must be a valid URL',
                  },
                })}
              />

              {fields.length > 1 && (
                <ActionButton
                  tooltip={fields.length === 1 ? undefined : 'Delete callback URL'}
                  onClick={() => remove(index)}
                >
                  <TrashIcon size={14} />
                </ActionButton>
              )}
            </FieldItem>
          ))}
        </FieldArray>
      </div>

      <PublicField>
        <StyledToggleInput
          id="isPublic"
          label="Public app"
          togglePosition="left"
          {...register('isPublic')}
        />
        <Tooltip
          content="Allow this application to be installed by other workspaces."
          withPortal
        >
          <InfoIconOutline />
        </Tooltip>
      </PublicField>

      <FormFooter>
        <Actions>
          <Button size="M" variant="secondary" onClick={closeEditAppModal}>
            Cancel
          </Button>
          <Button size="M" type="submit" disabled={!formState.isDirty}>
            {clientId ? 'Update' : 'Create'}
          </Button>
        </Actions>
      </FormFooter>
    </Form>
  );
};
