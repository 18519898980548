import { Language, UpdateProductLanguageDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';

import useSafeMutation from '../useSafeMutation';

export const useProductUpdateLanguage = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [mutation, { loading: isLoading }] = useSafeMutation(UpdateProductLanguageDocument);

  const updateLanguage = (language: Language) => mutation({
    variables: {
      productId,
      language,
    },
    optimisticResponse: {
      updateProduct: {
        id: productId,
        language,
      },
    },
  });

  return {
    updateLanguage,
    isLoading,
  };
};
