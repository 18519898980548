import { SelectOption, Skeleton } from '@cycle-app/ui';
import { useMemo } from 'react';

import { useChangelog } from '../../hooks/releases/useChangelog';
import { Placeholder, StyledSelectPanel } from './ChangelogTagsPanel.styles';

export type ChangelogTagsPanelProps = {
  selected: string[];
  onSelectOption: (id: string) => VoidFunction | Promise<unknown>;
  onUnselectOption: (id: string) => VoidFunction | Promise<unknown>;
};

export const ChangelogTagsPanel = ({
  selected, onSelectOption, onUnselectOption,
}: ChangelogTagsPanelProps) => {
  const {
    changelog, isLoading,
  } = useChangelog();
  const options = useMemo<SelectOption[]>(() => changelog?.releaseNoteTagValues
    .map(tag => ({
      label: tag.value,
      value: tag.id,
      selected: selected.includes(tag.id),
    })) ?? [], [changelog?.releaseNoteTagValues, selected]);

  if (isLoading) {
    return (
      <Placeholder><Skeleton /></Placeholder>
    );
  }

  return options.length ? (
    <StyledSelectPanel
      options={options}
      hideSearch
      isMulti
      onSelectOption={option => onSelectOption(option.value)}
      onUnselectOption={option => onUnselectOption(option.value)}
    />
  ) : <Placeholder>No tags</Placeholder>;
};
