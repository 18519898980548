import { typo, ActionButton, boxShadowZ2, ShyScrollbarCss } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)<{ $noBorder:boolean }>`
  position: absolute;
  inset: 0;
  background-color: ${p => p.theme.colors.background.primary};
  z-index: 5;
  border-radius: 10px;
  ${boxShadowZ2}
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: ${p => (p.$noBorder ? 'none' : `1px solid ${p.theme.colors.border.primary}`)};
`;

export const CloseButton = styled(ActionButton)`
  width: 20px;
  height: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  justify-content: space-between;
  padding: 16px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Content = styled.div`
  padding: 0 16px;
  flex: 1;
  ${ShyScrollbarCss}
`;

export const Title = styled.div`
  ${typo.body500};
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  align-items: center;
  gap: 8px;
`;
