import { FC } from 'react';

import { DocCompanyCustomer } from '../DocCompanyCustomer';
import { CustomerContainer } from './InsightsList.styles';

import type { DocCompanyCustomerProps } from '../DocCompanyCustomer/DocCompanyCustomer.types';

interface Props {
  className?: string;
  doc: DocCompanyCustomerProps['doc'];
  isCompact?: boolean;
}

export const InsightCompanyCustomer: FC<React.PropsWithChildren<Props>> = ({
  className, doc, isCompact = false,
}) => {
  return (
    <CustomerContainer className={className}>
      <DocCompanyCustomer
        size="S"
        doc={doc}
        isDisabled
        showCompanyName
        isCompact={isCompact}
      />
    </CustomerContainer>
  );
};
