import {
  ReleaseIcon,
  BulbLight,
  RoadmapIcon,
  InboxIcon,
  CustomViewsIcon,
  InfoIconOutline,
  WheelIcon,
} from '@cycle-app/ui/icons';
import { ReactNode, useMemo } from 'react';

import { useIsRoadmapsEnabled } from 'src/hooks';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useFeedbackTour } from 'src/hooks/useFeedbackTour';
import { useInsightTour } from 'src/hooks/useInsightTour';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useRoadmapTour } from 'src/hooks/useRoadmapTour';
import { PageId, useUrl } from 'src/hooks/useUrl';
import { setMobileAsideMenu } from 'src/reactives/mobileAsideMenu.reactive';
import { openReleasesProductTour } from 'src/reactives/releases.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import {
  Card, FlatCard, Cards, IconContainer, CardActions, CardFooter, StyledLink, Action,
} from './HomeViewsSection.styles';

type CardsType = {
  pageId: PageId;
  title: string;
  icon: ReactNode;
  onClick?: VoidFunction;
  learnMore?: VoidFunction;
  goToSettings?: VoidFunction;
}[];

export const HomeViewsSection = () => {
  const getUrl = useUrl();
  const isMobile = useIsMobile();
  const { open: openFeedbackTour } = useFeedbackTour();
  const { open: openInsightTour } = useInsightTour();
  const { open: openRoadmapTour } = useRoadmapTour();
  const { navigate } = useNavigateToSettings({ useLimitationModal: true });
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const isReleasesEnabled = useIsReleasesEnabled();

  const cards: CardsType = useMemo(() => [
    {
      pageId: PageId.Inbox,
      title: 'Feedback',
      icon: <InboxIcon size={16} />,
      onClick: isMobile ? openFeedbackTour : undefined,
      learnMore: isMobile ? undefined : openFeedbackTour,
      goToSettings: () => navigate(PageId.SettingsFeedback),
    },
    {
      pageId: PageId.Insight,
      title: 'Insights',
      icon: <BulbLight size={17} />,
      onClick: isMobile ? openInsightTour : undefined,
      learnMore: isMobile ? undefined : openInsightTour,
      goToSettings: () => navigate(PageId.SettingsInsights),
    },
    ...(isRoadmapsEnabled ? [{
      pageId: PageId.Roadmap,
      title: 'Roadmaps',
      icon: <RoadmapIcon size={18} />,
      onClick: isMobile ? openRoadmapTour : undefined,
      learnMore: isMobile ? undefined : openRoadmapTour,
      goToSettings: () => navigate(PageId.SettingsRoadmaps),
    }] : []),
    ...(isReleasesEnabled ? [{
      pageId: PageId.Releases,
      title: 'Releases',
      icon: <ReleaseIcon size={17} />,
      onClick: isMobile ? openReleasesProductTour : undefined,
      learnMore: isMobile ? undefined : openReleasesProductTour,
    }] : []),
  ], [isMobile, isReleasesEnabled, isRoadmapsEnabled, navigate, openFeedbackTour, openInsightTour, openRoadmapTour]);

  return (
    <Cards>
      {cards.map(card => (
        <Card key={card.pageId}>
          <IconContainer>
            {card.icon}
          </IconContainer>

          <StyledLink
            to={getUrl(card.pageId)}
            onClick={e => {
              if (!card.onClick) return;
              e.preventDefault();
              card.onClick();
            }}
          >
            {card.title}
          </StyledLink>

          {!isMobile && (
            <CardActions>
              {card.learnMore && (
                <Action
                  size="S"
                  variant="nospace"
                  onClick={card.learnMore}
                  tooltip="Learn more"
                  tooltipPlacement="top"
                >
                  <InfoIconOutline />
                </Action>
              )}
              {card.goToSettings && (
                <Action
                  size="S"
                  variant="nospace"
                  onClick={card.goToSettings}
                  tooltip="Settings"
                  tooltipPlacement="top"
                >
                  <WheelIcon />
                </Action>
              )}
            </CardActions>
          )}
        </Card>
      ))}

      {isMobile && (
        <FlatCard>
          <IconContainer>
            <CustomViewsIcon />
          </IconContainer>
          <CardFooter>
            <StyledLink as="button" onClick={() => setMobileAsideMenu({ isVisible: true })}>
              Custom views
            </StyledLink>
          </CardFooter>
        </FlatCard>
      )}
    </Cards>
  );
};
