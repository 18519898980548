import { typo, ActionButton, card } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const STATUS_HEIGHT = 40;
export const STATUS_GAP = 8;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 32px;
`;

export const Sections = styled.div<{ $hideBorder: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 780px;
  ${p => !p.$hideBorder && card}
`;

export const CreateButton = styled(ActionButton)`
  --bg: ${p => p.theme.colors.button.light.bg};
  --bgHover: ${p => p.theme.colors.button.light.bgHover};
  --bgActive: ${p => p.theme.colors.button.light.bgHover};
  --colorHover: ${p => p.theme.colors.workflows.button.colorHover};
  width: 18px;
  height: 18px;
  opacity: 0;
  &[data-active=true] {
    opacity: 1;
    color: var(--colorHover);
    background: var(--bgHover);
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  :hover ${CreateButton} {
    opacity: 1;
  }
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.body500}
`;

export const Statuses = styled.div<{ $count: number }>`
  position: relative;
  height: ${p => `${p.$count * (STATUS_HEIGHT + STATUS_GAP)}px`};
`;

export const StatusItem = styled.div`
  position: absolute;
  width: 100%;
`;

export const StatusActions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  
  :disabled {
    cursor: not-allowed;
  }
`;

export const StatusName = styled.div`
  min-width: 150px;
  padding-left: 10px;
  display: flex;
  gap: 8px;
`;

export const ActionContainer = styled.div<{ $isDisabled: boolean }>`
  cursor: ${p => (p.$isDisabled ? 'not-allowed' : 'default')};
`;

export const Action = styled(ActionButton)`
  padding: 5px;
  ${typo.body400}
  line-height: 1;
  height: 24px;
  min-width: 24px;
  --color: ${p => p.theme.colors.workflows.button.color};
  --colorHover: ${p => p.theme.colors.workflows.button.colorHover};
  --bgHover: ${p => p.theme.colors.workflows.button.bgHover};
  --bgActive: ${p => p.theme.colors.workflows.button.bgActive};
  &[data-active=true] {
    opacity: 1;
    color: var(--colorHover);
    background: var(--bgHover);
  }
`;

export const StatusAction = styled(Action)`
  --color: ${p => p.theme.colors.workflows.buttonAlt.color};
  --colorHover: ${p => p.theme.colors.workflows.buttonAlt.colorHover};
  --bgHover: ${p => p.theme.colors.workflows.buttonAlt.bgHover};
  --bgActive: ${p => p.theme.colors.workflows.buttonAlt.bgActive};
  opacity: 0;
  ${p => p.isLoading && css`
    opacity: 1 !important;
  `}
`;

export const DocTypeAction = styled(StatusAction)`
  padding: 5px 8px;
  opacity: 1;
  --bg: ${p => p.theme.colors.workflows.buttonAlt.bg};
`;

export const UnlinkAction = styled(Action)`
  height: auto;
  min-width: auto;
`;

export const DocTypes = styled.div`
  display: flex;
  gap: 4px;
  line-height: 1;
`;

export const DocTypePanel = styled.div`
  min-width: 250px;
  padding: 8px 0;
`;

export const DocTypeLine = styled.div<{ $withAction?: boolean }>`
  display: grid;
  grid-template-columns: ${p => (p.$withAction ? '16px 1fr 20px' : '16px 1fr')};
  justify-items: center;
  align-items: center;
  gap: 16px;
  padding: 0 12px;
  height: 30px;
  color: ${p => p.theme.colors.text.secondary};
`;

export const DocTypeName = styled.div`
  justify-self: stretch;
  color: ${p => p.theme.colors.text.primary};
`;

export const LinkNew = styled(Action)`
  width: 100%;
  border-radius: 0;
  justify-items: flex-start;
  svg {
    justify-self: center;
  }
`;

export const DocTypeLabel = styled.span`
  font-weight: 500;
  white-space: nowrap;
`;

export const TextSecondary = styled.span`
  color: ${p => p.theme.colors.text.secondary};
`;

export const DocTypeTag = styled.div`
  --bg: ${p => p.theme.colors.workflows.buttonAlt.bg};
  --bgHover: ${p => p.theme.colors.workflows.buttonAlt.bgHover};
  --bgActive: ${p => p.theme.colors.workflows.buttonAlt.bgActive};
  --color: ${p => p.theme.colors.workflows.buttonAlt.color};
  --colorHover: ${p => p.theme.colors.workflows.buttonAlt.colorHover};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  height: 24px;
  border-radius: 5px;
  background: var(--bg);
  color: var(--color);
  :hover {
    background: var(--bgHover);
    color: var(--colorHover);
  }
  :active {
    background: var(--bgActive);
    color: var(--colorHover);
  }
`;

export const Status = styled.div<{ $hideActions: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  height: ${STATUS_HEIGHT}px;
  background: ${p => p.theme.colors.workflows.status.bg};
  border-radius: 6px;
  padding: 8px 16px 8px 6px;
  ${typo.body400};

  ${p => !p.$hideActions && css`
    :hover {
      cursor: pointer;
      background: ${p.theme.colors.workflows.status.bgHover};
      
      ${StatusAction} {
        opacity: 1;
      }

      ${DocTypeTag}, ${DocTypeAction} {
        --bg: ${p.theme.colors.workflows.buttonAlt.bgHover};
        --bgHover: ${p.theme.colors.workflows.buttonAlt.bgHover};
        --bgActive: ${p.theme.colors.workflows.buttonAlt.bgActive};
      }
    }
  `}
`;
