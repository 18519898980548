import { Spinner } from '@cycle-app/ui';
import { ReactNode, useCallback, useRef, VFC } from 'react';
import { DropzoneRootProps } from 'react-dropzone';

import { DocProcessButton } from 'src/components/DocProcessButton';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage } from 'src/components/ErrorPage';
import { Size } from 'src/components/ErrorPage/ErrorPage.types';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';
import { isFeedback } from 'src/utils/docType.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import { DocPanelContent } from '../DocPanelContent/DocPanelContent';
import DocPanelHeader from '../DocPanelHeader/DocPanelHeader';
import DocPanelRightPanel from '../DocPanelRightPanel/DocPanelRightPanel';
import {
  Container,
  MainSection,
  LoaderContainer,
} from './DocPanelFullPage.styles';

interface Props extends DocPanelBaseProps {
  widthSidebar: number;
  isDocLoading: boolean;
  getDropzonePropsTopBar: () => DropzoneRootProps;
  disabled?: boolean;
  children?: ReactNode;
}

const DocPanelFullPage: VFC<Props> = ({
  doc,
  getDropzonePropsCover,
  isDocLoading,
  isDraggingCover,
  isUploadingCover,
  onOpenCoverInputFile,
  onTitleUpdated,
  widthSidebar,
  disabled = false,
  children,
}) => {
  const { navigate } = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const onDeleteDoc = useCallback(() => {
    navigate(PageId.Main);
  }, [navigate]);

  if (isDocLoading) {
    return (
      <LoaderContainer $widthSidebar={widthSidebar}>
        <Spinner />
      </LoaderContainer>
    );
  }

  if (doc === null) {
    return (
      <Container
        ref={containerRef}
        $widthSidebar={widthSidebar}
      >
        <ErrorPage message="Oops, this doc no longer exists" size={Size.SMALL} />
      </Container>
    );
  }

  if (!doc && !isDocLoading) {
    return (
      <Container
        ref={containerRef}
        $widthSidebar={widthSidebar}
      >
        <ErrorPage size={Size.SMALL} />
      </Container>
    );
  }

  return (
    <Container
      ref={containerRef}
      $widthSidebar={widthSidebar}
    >
      <ErrorBoundary>
        <MainSection>
          <DocPanelHeader
            doc={doc}
            onDeleteDoc={onDeleteDoc}
            onOpenCoverInputFile={onOpenCoverInputFile}
            disabled={disabled}
            {...isFeedback(doc.doctype) && doc.status && {
              processButton: (
                <DocProcessButton
                  docId={doc.id}
                  docStatusCategory={doc.status.category}
                  parent="doc-panel"
                  quotesCount={doc.quotesCount ?? 0}
                />
              ),
            }}
          />
          <DocPanelContent
            doc={doc}
            containerRef={containerRef}
            getDropzonePropsCover={getDropzonePropsCover}
            isDraggingCover={isDraggingCover}
            isUploadingCover={isUploadingCover}
            onTitleUpdated={onTitleUpdated}
            onOpenCoverInputFile={onOpenCoverInputFile}
            disabled={disabled}
          />
        </MainSection>

        <DocPanelRightPanel
          doc={doc}
        />
      </ErrorBoundary>

      {children}
    </Container>
  );
};

export default DocPanelFullPage;
