import { typo, ActionButton, TextArea, ShyScrollbarCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  ${ShyScrollbarCss}
  scrollbar-gutter: stable;
`;

export const Item = styled.div<{
  $isActive?: boolean;
  $isClickable?: boolean;
}>`
  padding: 8px 16px;
  border-radius: 6px;
  background: ${p => p.theme.colors.background.secondaryElevated};
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 2px solid transparent;

  ${p => p.$isActive && `
    :not(:focus-within) {
      border-color: hsla(var(--cycle), 0.8);
    }
  `};

  ${p => p.$isClickable && `
    cursor: pointer;
  `};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;  
`;

export const Title = styled.p`
  ${typo.body500};
`;

export const DescriptionContainer = styled.p<{ $isEmpty: boolean }>`
  ${typo.body400};
  word-break: break-word;
  color: ${p => p.theme.colors.text.secondary};
  ${p => p.$isEmpty && `
    color: ${p.theme.colors.text.disabled};
    font-style: italic;
  `}
`;

export const EditButton = styled(ActionButton)`
  --color: ${p => p.theme.colors.text.disabled};
  position: relative;
  top: 2px;
  margin-left: 8px;
  display: inline;
  width: 22px;
  height: 22px;
`;

export const DescriptionTextArea = styled(TextArea)`
  resize: none;
  overflow: hidden;
  min-height: 50px;
`;
