import { ViewType } from '@cycle-app/graphql-codegen';
import { InsightContext } from '@cycle-app/ui';
import { htmlToText } from '@cycle-app/utilities';
import { FC } from 'react';

import { useDocContext } from 'src/contexts/docContext';
import { DOC_PREVIEW_DELAY, useDocPreview } from 'src/hooks/doc/useDocPreview';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { StyledInsightCompanyCustomer } from './DocItemContextTitle.styles';

interface Props {
  viewType: ViewType;
  showCustomer?: boolean;
  disablePreview?: boolean;
}

export const DocItemContextTitle: FC<React.PropsWithChildren<Props>> = ({
  viewType, showCustomer, disablePreview,
}) => {
  const doc = useDocContext();
  const context = doc.docSource?.content || doc.title;
  const {
    showDocPreview, hideDocPreview,
  } = useDocPreview();
  return (
    <InsightContext
      contextText={context}
      context={htmlToText(context)}
      onContextCopied={text => copyToClipboard({
        text,
        notification: 'Text copied to clipboard!',
      })}
      contextFooter={showCustomer && !!doc.customer?.id && (
        <StyledInsightCompanyCustomer
          doc={doc}
          $isInline={viewType === ViewType.List}
          isCompact={viewType === ViewType.List}
        />
      )}
      isInline={viewType === ViewType.List}
      {...!disablePreview && {
        onMouseEnter: () => showDocPreview(doc.id, DOC_PREVIEW_DELAY),
        onMouseLeave: () => hideDocPreview(),
      }}
    />
  );
};
