import dagre, { GraphLabel } from 'dagre';
import { Elements, isNode, FlowElement } from 'react-flow-renderer';

export const PADDING_CHART = 32;
export const GAP_ELEMENT = 32;
export const GAP_RANK = 92;

export const WIDTH_ELEMENT = 200;
export const HEIGHT_ELEMENT = 72;

export interface FlowData {
  doctypeId?: string;
  root?: boolean;
  hasChildren?: boolean;
  asPlaceholder?: boolean;
  phantom?: boolean;
  isLastRank?: boolean;
  asLink?: boolean;
  iteration?: number;
  target?: 'parent' | 'children';
  rootId?: string;
  insightParentId?: string;
  isUnlinkableFromInsight?: boolean;

  // Edge specific data
  edgeCenter?: number;
  edgeType?: 'integrations' | 'insight' | 'default';
  markerType?: 'arrow' | 'insight' | 'default';
}

const isGridPositionable = (el: FlowElement) => !el.data.phantom && el.data.edgeType !== 'insight';

export const getLayoutedElements = (elements: Elements, options: GraphLabel = {}): Elements => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: 'TB',
    nodesep: GAP_ELEMENT,
    marginx: PADDING_CHART,
    marginy: PADDING_CHART,
    ranksep: GAP_RANK,
    ...options,
  });

  elements.forEach((el) => {
    if (!isGridPositionable(el)) return;
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: el.__rf?.width ?? WIDTH_ELEMENT,
        height: el.__rf?.height ?? HEIGHT_ELEMENT,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  // First, compute visible elements positions on the grid
  const layouted = elements.map((el) => {
    if (!isGridPositionable(el)) return el;
    if (isNode(el)) {
      const node = dagreGraph.node(el.id);

      const x = node.x - node.width / 2;
      const y = node.y - node.height / 2;

      // eslint-disable-next-line
      el.position = {
        x,
        y,
      };
    }

    return el;
  });

  // Then, compute phantom elements positions based on their rootId element
  return layouted.map(el => {
    if (!el.data.phantom || !isNode(el)) return el;
    const root = layouted.find(({ id }) => el.data.rootId === id);
    if (!root || !isNode(root)) return el;
    // eslint-disable-next-line
    el.position = {
      x: root.position.x - 200,
      y: root.position.y + 70,
    };
    return el;
  });
};

export const getLayoutedElementsDoctype = (elements: Elements): Elements => elements.map((el) => {
  if (isNode(el)) {
    const indexColumn = el.data?.indexColumn ?? 0;
    const level = el.data?.level ?? 0;
    const indexChild = el.data?.indexChild ?? 0;

    const x =
      (indexColumn * 550) +
      (level * (WIDTH_ELEMENT / 2)) +
      (level * 20) +
      PADDING_CHART;

    const y =
      (level * (HEIGHT_ELEMENT + 20)) +
      (indexChild * (HEIGHT_ELEMENT + 20)) +
      PADDING_CHART +
      level * 20;

    // eslint-disable-next-line
    el.position = {
      x,
      y,
    };
  }

  return el;
});
