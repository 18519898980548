import { CloseIcon } from '@cycle-app/ui/icons';

import { closeEditWebhookModal, useGetSettingsApi } from 'src/reactives/settingsApi.reactive';

import { StyledModal, ModalTitle, CloseButton } from '../SettingsAPI.styles';
import { WebhookForm } from './WebhookForm';

export const WebhookModal = () => {
  const webhookId = useGetSettingsApi().openedEditWebhookpModal;
  if (webhookId === null) return null;
  return (
    <StyledModal hide={closeEditWebhookModal}>
      <ModalTitle>
        {!webhookId ? 'Create new webhook' : 'Edit webhook'}
      </ModalTitle>

      <WebhookForm webhookId={webhookId} />

      <CloseButton onClick={closeEditWebhookModal}>
        <CloseIcon size={14} />
      </CloseButton>
    </StyledModal>
  );
};
