import { Tag, Tooltip } from '@cycle-app/ui';
import {
  ZendeskIcon,
  HubSpotIcon,
  IntercomIcon,
  PipedriveIcon,
  SnowflakeIcon,
  UsersIcon,
} from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';

import { useProduct } from 'src/hooks';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { Container } from './CompanyAttributes.styles';

type Props = {
  companyId: string;
};

const builtInKeys = ['zendeskId', 'hubspotId', 'intercomId', 'pipedriveId', 'snowflakeId', 'customId'] as const;

type BuitInAttributeKeys = typeof builtInKeys[number];

const data: Record<BuitInAttributeKeys, { name?: string; icon?: JSX.Element }> = {
  zendeskId: {
    name: 'Zendesk ID',
    icon: <ZendeskIcon size={12} />,
  },
  hubspotId: {
    name: 'HubSpot ID',
    icon: <HubSpotIcon size={12} />,
  },
  intercomId: {
    name: 'Intercom ID',
    icon: <IntercomIcon size={12} />,
  },
  pipedriveId: {
    name: 'Pipedrive ID',
    icon: <PipedriveIcon size={12} />,
  },
  snowflakeId: {
    name: 'Snowflake ID',
    icon: <SnowflakeIcon size={12} />,
  },
  customId: {
    name: 'Custom ID',
  },
};

export const CompanyAttributes = ({ companyId }: Props) => {
  const { product } = useProduct();
  const { company } = useCompany(companyId);
  if (!company) return null;
  const keys = builtInKeys.filter((key) => company[key]);
  const attributes = nodeToArray(company.attributes);
  if (keys.length === 0 && attributes.length === 0) return null;
  const customAttributesDefinitions = keyBy(nodeToArray(product?.companyAttributeDefinitions), 'id');
  return (
    <Container>
      {company.arr && (
        <Tooltip
          content="ARR"
          placement="top"
          withPortal
          withWrapper={false}
        >
          <Tag color="grey">
            {`ARR ${Intl.NumberFormat('en-US').format(company.arr)}`}
          </Tag>
        </Tooltip>
      )}

      {company.numberOfEmployees && (
        <Tooltip
          content="Employees"
          placement="top"
          withPortal
          withWrapper={false}
        >
          <Tag color="grey" icon={<UsersIcon size={12} />}>
            {Intl.NumberFormat('en-US').format(company.numberOfEmployees)}
          </Tag>
        </Tooltip>
      )}

      {keys.map((key) => {
        // Built-in attributes
        const value = company[key];
        if (!value) return null;
        return (
          <Tooltip
            key={key}
            title={data[key].name}
            content="Click to copy"
            placement="top"
            withPortal
            withWrapper={false}
          >
            <Tag
              color="grey"
              icon={data[key].icon}
              onClick={() => {
                if (!value) return;
                copyToClipboard({
                  text: `${value}`,
                  notification: `${data[key].name} successfully copied to clipboard`,
                });
              }}
            >
              {value}
            </Tag>
          </Tooltip>
        );
      })}

      {attributes.map((attribute) => {
        const definition = customAttributesDefinitions[attribute.definition.id];
        if (!definition || !('id' in definition)) return null;
        const value = (() => {
          if (attribute.value?.__typename === 'AttributeTextValue') {
            return attribute.value.textValue;
          }
          if (attribute.value?.__typename === 'AttributeNumberValue') {
            return attribute.value.numberValue;
          }
          if (attribute.value?.__typename === 'AttributeCheckboxValue' && !!attribute.value.checkboxValue) {
            return definition.name;
          }
          return null;
        })();

        if (!value) return null;

        return (
          <Tooltip
            key={attribute.id}
            title={definition.name}
            content={definition.description}
            placement="top"
            withPortal
            withWrapper={false}
          >
            <Tag
              color={definition.color}
            >
              {value}
            </Tag>
          </Tooltip>
        );
      })}
    </Container>
  );
};
