import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '../SettingsAPI.styles';
import { Form, AppNameInput } from './ApiKeysSection.styles';
import { useCreateAccessToken } from './useCreateAccessToken';

export const ApiKeyForm = () => {
  const createAccessToken = useCreateAccessToken();
  const [isFocused, setIsFocused] = useState(false);

  const {
    handleSubmit, register, formState, reset,
  } = useForm({
    defaultValues: {
      label: '',
    },
  });

  return (
    <Form
      onSubmit={handleSubmit(values => {
        reset();
        return createAccessToken(values);
      })}
    >
      <AppNameInput
        placeholder="Name of your app"
        width={224}
        autoComplete="off"
        error={isFocused ? formState.errors.label?.message : undefined}
        onFocus={() => setIsFocused(true)}
        {...register('label', {
          required: 'Name is required',
          onBlur: () => setIsFocused(false),
        })}
      />

      <Button type="submit">
        Create new
      </Button>
    </Form>
  );
};
