import { UpdateProductAutopilotDocument, UpdateProductAutopilotMutationVariables } from '@cycle-app/graphql-codegen';
import pick from 'lodash/pick';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateProductAutopilot = () => {
  const { product } = useProduct();
  const [updateAutoLinkToRoadmap] = useSafeMutation(UpdateProductAutopilotDocument, {
    errorPolicy: 'all',
    displayErrorMessages: true,
  });

  return (variables: Omit<UpdateProductAutopilotMutationVariables, 'productId'>) => {
    if (!product) return;
    const optimisticData = pick(
      product,
      ['id', 'extractInsights', 'markFeedbackAsProcessed', 'linkToRoadmap', 'createParent'],
    );
    if (variables.extractInsights != null) optimisticData.extractInsights = variables.extractInsights;
    if (variables.markFeedbackProcessed != null) optimisticData.markFeedbackAsProcessed = variables.markFeedbackProcessed;
    if (variables.linkToRoadmap != null) optimisticData.linkToRoadmap = variables.linkToRoadmap;
    if (variables.createParent != null) optimisticData.createParent = variables.createParent;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateAutoLinkToRoadmap({
      variables: {
        productId: product.id,
        ...variables,
      },
      optimisticResponse: {
        updateAutoPilot: optimisticData,
      },
    });
  };
};
