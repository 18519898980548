import { FlashIcon, InfoIconOutline } from '@cycle-app/ui/icons';

import { Progress } from '../Progress/Progress';
import { Tooltip } from '../Tooltip';
import {
  Container, Title, UpgradeButton, ProgressTitle, TextSecondary, InfoIconContainer,
} from './FreePlan.styles';

export type FreePlanProps = {
  className?: string;
  maxActiveInsights: number;
  nbActiveInsights?: number;
  onClickUpgrade?: VoidFunction;
};

export const FreePlan = ({
  className = '',
  maxActiveInsights,
  nbActiveInsights = 0,
  onClickUpgrade,
}: FreePlanProps) => {
  const isDanger = nbActiveInsights >= maxActiveInsights;
  const isWarning = nbActiveInsights >= (maxActiveInsights - 5) && nbActiveInsights < maxActiveInsights;
  const message = isDanger
    ? `${nbActiveInsights}/${maxActiveInsights} Limit reached`
    : `${nbActiveInsights} of ${maxActiveInsights} created`;

  return (
    <Container className={className} danger={isDanger} warning={isWarning}>
      <header>
        <Title>
          Starter
        </Title>

        {onClickUpgrade && (
          <UpgradeButton
            danger={isDanger}
            warning={isWarning}
            onClick={onClickUpgrade}
            size="M"
          >
            <FlashIcon />
            Upgrade
          </UpgradeButton>
        )}
      </header>

      {nbActiveInsights != null && (
        <>
          <section>
            <ProgressTitle>
              Active insights
              <Tooltip
                content="Insights that are not canceled or loop closed"
                placement="top"
                withWrapper={false}
                width={160}
              >
                <InfoIconContainer>
                  <InfoIconOutline size={12} />
                </InfoIconContainer>
              </Tooltip>
            </ProgressTitle>
            <Progress
              type="bar"
              useCycleColor
              percentage={(nbActiveInsights * 100) / maxActiveInsights}
              // eslint-disable-next-line no-nested-ternary
              state={isDanger
                ? 'danger'
                : isWarning
                  ? 'warning'
                  : 'default'}
            />
          </section>

          <TextSecondary
            danger={isDanger}
            warning={isWarning}
          >
            {message}
          </TextSecondary>
        </>
      )}
    </Container>
  );
};
