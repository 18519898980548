import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { HEADER_SECONDARY_HEIGHT } from 'src/components/MobileHeader/MobileHeader.styles';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

const containerBaseCSS = css<{ $widthSidebar: number }>`
  background-color: ${p => p.theme.colors.docPanel.bg};
  position: absolute;
  z-index: ${mappingZindex[Layer.DocPanel]};
  top: 0;
  right: 0;
  width: ${p => `calc(100vw - ${p.$widthSidebar}px)`};
  height: 100vh;

  ${mobile} {
    width: 100%;
  }
`;
export const Container = styled.div<{ $widthSidebar: number }>`
  ${containerBaseCSS};
  display: flex;
  overflow: clip;

  ${mobile} {
    padding-top: ${HEADER_SECONDARY_HEIGHT}px;
    max-width: 100%;

    &:focus-within {
      max-height: calc(100% - var(--keyboard-height));
    }
  }
`;

export const MainSection = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: inherit;
  max-width: 100%;
`;

export const LoaderContainer = styled.div<{ $widthSidebar: number }>`
  ${containerBaseCSS};
  display: flex;
  align-items: center;
  justify-content: center;

  ${mobile} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
`;
