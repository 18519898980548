export enum GamificationStep {
  SetupAccount = 'SetupAccount',
  CreateFeedback = 'CreateFeedback',
  ExtractInsight = 'ExtractInsight',
  ProcessFeedback = 'ProcessFeedback',
  BrowseView = 'BrowseView',
  Search = 'Search',
  ChromeExtension = 'ChromeExtension',
  SyncCustomers = 'SyncCustomers',
  CreateView = 'CreateView',
  BecomeExpert = 'BecomeExpert',
}

export type GamificationStepDetail = {
  label: string;
  order: number;
  link?: string;
};
