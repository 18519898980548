import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { CloseIcon, BulbIcon, AiIcon, ReleaseIcon } from '@cycle-app/ui/icons';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import {
  DeleteConnexion,
  DeleteConnexionIconContainer,
  DeleteInfo,
  DeleteModalStyled,
  DeletePreviewBlock,
  DeletePreview,
  DeletePreviewContent,
  DeletePreviewContainer,
} from 'src/components/FlowElement/FlowElement.styles';
import { useOptimizedBooleanState } from 'src/hooks';
import useDoctypeParentsMutations from 'src/hooks/api/mutations/useDoctypeParentsMutations';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { findInsight } from 'src/utils/docType.util';

export const UnlinkInsightsModal = ({
  parent, hide,
}: {
  parent: DoctypeFragment;
  hide: VoidFunction;
}) => {
  const { docTypes } = useGetDocTypes();
  const docTypesList = Object.values(docTypes);
  const insight = findInsight(docTypesList);

  const {
    removeDoctypeParent,
    loading,
  } = useDoctypeParentsMutations(insight);

  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation, setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);

  return (
    <DeleteModalStyled
      title="Remove insight, release & AI links"
      info={(
        <>
          <DeletePreviewBlock>
            <DeletePreview>
              <DeletePreviewContainer view="doctype">
                <DeletePreviewContent>
                  <DocTypeIcon doctype={parent} />
                </DeletePreviewContent>
              </DeletePreviewContainer>
              <DeleteConnexion>
                <DeleteConnexionIconContainer>
                  <CloseIcon />
                </DeleteConnexionIconContainer>
              </DeleteConnexion>
              <DeletePreviewContainer view="doctype">
                <DeletePreviewContent>
                  <BulbIcon />
                  <ReleaseIcon />
                  <AiIcon />
                </DeletePreviewContent>
              </DeletePreviewContainer>
            </DeletePreview>
          </DeletePreviewBlock>
          <DeleteInfo>
            {`Are you sure you want to remove Insight, Release & AI links from ${parent.name} docs?`}
            <br />
            {`Corresponding insights will be unlinked from existing ${parent.name} docs.`}
            <br />
            This can’t be undone.
          </DeleteInfo>
        </>
            )}
      confirmLabel="Remove"
      confirmMessage="Yes, permanently remove links"
      useHighMaskLayer
      hide={() => {
        setDeleteConfirmationFalse();
        hide();
      }}
      onConfirm={() => removeDoctypeParent(parent.id)}
      loading={loading}
      isConfirmDisabled={!isDeleteConfirmed}
      onToggleConfirmation={toggleDeleteConfirmation}
    />
  );
};
