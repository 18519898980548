import { setSettingsApi } from 'src/reactives/settingsApi.reactive';

import { Section, SectionTitle, Info, Button } from '../SettingsAPI.styles';
import { OAuthAppList } from './OAuthAppList';
import { OAuthAppModal } from './OAuthAppModal';
import { OAuthRemoveAppModal } from './OAuthRemoveAppModal';

export const OAuthAppSection = () => (
  <Section>
    <header>
      <SectionTitle>
        OAuth applications
      </SectionTitle>
      <Info>
        You can manage your OAuth applications here.
      </Info>
    </header>

    <OAuthAppList />

    <Button
      onClick={() => setSettingsApi({ openedEditAppModal: '' })}
    >
      Create new
    </Button>

    <OAuthAppModal />
    <OAuthRemoveAppModal />
  </Section>
);
