import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const RefreshIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.634 1.67742C14.634 1.30329 14.3307 1 13.9566 1C13.5824 1 13.2792 1.30329 13.2792 1.67742V4.13849L12.4187 3.36409L12.4134 3.35935C11.295 2.3739 9.878 1.79272 8.38975 1.70907C6.9015 1.62543 5.42829 2.04416 4.20653 2.89809C2.98477 3.75202 2.08529 4.99162 1.65244 6.41799C1.21958 7.84436 1.27844 9.37479 1.81957 10.7637C2.36071 12.1526 3.35274 13.3195 4.63648 14.077C5.92021 14.8346 7.42123 15.1389 8.89866 14.9412C10.3761 14.7435 11.7443 14.0552 12.7837 12.9868C13.8231 11.9183 14.4734 10.5317 14.6303 9.04939C14.6697 8.67734 14.4 8.34381 14.0279 8.30442C13.6559 8.26504 13.3224 8.53472 13.283 8.90677C13.158 10.0871 12.6402 11.1913 11.8125 12.0421C10.9849 12.8928 9.89542 13.4409 8.71896 13.5984C7.54251 13.7558 6.34727 13.5134 5.32504 12.9102C4.30282 12.307 3.51288 11.3778 3.08198 10.2718C2.65109 9.16588 2.60422 7.94722 2.94889 6.81142C3.29357 5.67563 4.00981 4.68855 4.98268 4.00858C5.95555 3.32861 7.12865 2.99517 8.31372 3.06178C9.49766 3.12832 10.625 3.59028 11.5151 4.37361L12.1913 4.98219H9.97439C9.60026 4.98219 9.29697 5.28548 9.29697 5.65961C9.29697 6.03373 9.60026 6.33702 9.97439 6.33702H13.9566C14.0511 6.33702 14.1411 6.31766 14.2228 6.28269C14.3107 6.24521 14.392 6.18845 14.4602 6.11276C14.579 5.98076 14.6363 5.8149 14.634 5.6503V1.67742Z" />
  </Svg>
);
