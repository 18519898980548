import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { CheckCircleIcon, PenIcon } from '@cycle-app/ui/icons';

import { useReleasesContext } from 'src/contexts/releasesContext';
import { useChangelog } from 'src/hooks/releases/useChangelog';
import { useReleasePublicStatusUpdate } from 'src/hooks/releases/useReleasePublicStatusUpdate';
import { openPublishRelease } from 'src/reactives/releases.reactive';

import { StyledActionButton } from './ReleaseStatus.styles';

type Props = {
  publicStatus: ReleasePublicStatus;
  releaseId: string;
};

export const ReleaseStatus = ({
  publicStatus, releaseId,
}: Props) => {
  const { publish } = useReleasePublicStatusUpdate(releaseId);
  const { publishedCount } = useReleasesContext();
  const { changelog } = useChangelog();

  let tooltip;
  if (publicStatus === ReleasePublicStatus.Unpublished) {
    tooltip = 'Publish release';
  } else if (publicStatus === ReleasePublicStatus.Editing) {
    tooltip = 'Publish changes';
  }

  async function onClick() {
    if (publicStatus !== ReleasePublicStatus.Published) {
      if (publishedCount === 0 && !changelog?.isPublished) {
        openPublishRelease(releaseId)();
        return;
      }
      await publish();
    }
  }

  return (
    <StyledActionButton onClick={onClick} $publicStatus={publicStatus} size="L" tooltipPlacement="top" tooltip={tooltip}>
      {publicStatus === ReleasePublicStatus.Editing
        ? <PenIcon />
        : <CheckCircleIcon />}
    </StyledActionButton>
  );
};
