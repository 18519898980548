import { useQuery } from '@apollo/client';
import { AutopilotState, FindInsightState, DocStateDocument } from '@cycle-app/graphql-codegen';
import { useEffect, useRef, useState } from 'react';

import { useAutopilotStateSubscription } from 'src/hooks/api/useAutopilotStateSubscription';
import { useFindInsightStateSubscription } from 'src/hooks/api/useFindInsightStateSubscription';
import { getDocSummary } from 'src/reactives/docSummary.reactive';
import { extract } from 'src/types/graphql.types';

export const useDocState = (docId?: string) => {
  const isClosed = useRef(false);
  const [autopilotState, setAutopilotState] = useState<AutopilotState | null | undefined>(null);
  const [findInsightState, setFindInsightState] = useState<FindInsightState | null | undefined>(null);

  // Reset states when docId changes (e.g. when switching between docs with prev/next buttons)
  useEffect(() => {
    isClosed.current = false;
    setAutopilotState(null);
    setFindInsightState(null);
  }, [docId]);

  useAutopilotStateSubscription(docId, state => {
    if (isClosed.current) return;
    setAutopilotState(state);
  });

  useFindInsightStateSubscription(docId, state => {
    if (isClosed.current) return;
    setFindInsightState(state);
  });

  useQuery(DocStateDocument, {
    fetchPolicy: 'network-only',
    skip: !docId,
    variables: { id: docId as string },
    onCompleted: (data) => {
      if (isClosed.current) return;
      const doc = extract('Doc', data.node);

      if (doc?.findInsightState) {
        setFindInsightState(doc.findInsightState);
      }

      const isDocInManualGenerateSummary = !!docId && !!getDocSummary().generateSummaries[docId];
      if (doc?.autopilotState && !isDocInManualGenerateSummary) {
        setAutopilotState(doc?.autopilotState);
      }
    },
  });

  return {
    autopilotState,
    findInsightState,
    closeDocState: () => {
      isClosed.current = true;
      setAutopilotState(null);
      setFindInsightState(null);
    },
  };
};
