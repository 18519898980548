import { typo, Input } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const StyledPortalModal = styled(PortalModal)`
  width: 450px;
  padding: 16px;
`;

export const Title = styled.div`
  ${typo.headerSmall};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const Row = styled.div`
  display: flex;
  gap: 15px;
`;

export const InputStyled = styled(Input)`
  width: 100%;

  label {
    ${typo.body400}
  }
`;
