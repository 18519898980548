import { useMotionValue, animate, AnimationPlaybackControls, ValueAnimationTransition } from 'framer-motion';
import { useRef, useEffect } from 'react';
import { EdgeProps, getBezierPath, getSmoothStepPath } from 'react-flow-renderer';

type Props = EdgeProps & {
  initialDelay?: number;
  frontOptions: (delay?: number) => ValueAnimationTransition;
  backOptions: () => ValueAnimationTransition;
  isBezier?: boolean;
  circleR?: number;
};

export const AnimatedEdge = ({
  id, initialDelay, frontOptions, backOptions, isBezier = true, circleR = 5, ...props
}: Props) => {
  const edgePathRef = useRef<SVGPathElement>(null);
  const circleRef = useRef<SVGCircleElement>(null);
  const edgePath = isBezier ? getBezierPath(props) : getSmoothStepPath(props);
  const offset = useMotionValue(0);

  useEffect(() => {
    const hideCircle = () => {
      circleRef.current?.setAttribute('opacity', '0');
    };

    const showCircle = () => {
      circleRef.current?.setAttribute('opacity', '1');
    };

    const setCirclePosition = (latest: number) => {
      if (!circleRef.current || !edgePathRef.current) return;
      const point = edgePathRef.current.getPointAtLength(latest);
      circleRef.current.setAttribute('cx', `${point.x}`);
      circleRef.current.setAttribute('cy', `${point.y}`);
    };

    let controls: AnimationPlaybackControls;

    const animateFront = (delay?: number) => animate(offset, edgePathRef.current?.getTotalLength() ?? 0, {
      ...frontOptions(delay),
      onUpdate: setCirclePosition,
      onPlay: showCircle,
      onComplete: () => {
        hideCircle();
        controls = animateBack();
      },
    });

    const animateBack = () => animate(offset, 0, {
      ...backOptions(),
      onUpdate: setCirclePosition,
      onPlay: showCircle,
      onComplete: () => {
        hideCircle();
        controls = animateFront();
      },
    });

    controls = animateFront(initialDelay);

    return () => {
      controls?.stop();
    };
  }, []);

  return (
    <>
      <path
        ref={edgePathRef}
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
      />
      <circle
        ref={circleRef}
        r={circleR}
        opacity={0}
        className="react-flow__edge-circle"
      />
    </>
  );
};
