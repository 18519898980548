import { useQuery } from '@apollo/client';
import { FilterPropertyRuleCompanyWithSearchDocument } from '@cycle-app/graphql-codegen';
import { SelectPanelProps, SelectPanel } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useMemo, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { CompanyFromFilter } from 'src/utils/boardConfig/filtersData.util';
import { getCompanyOption } from 'src/utils/companies.util';

import { SelectedOptionCloseIcon, SelectedOptionTag, SelectedOptionTagContainer } from '../FilterElements.styles';

interface Props extends Pick<SelectPanelProps, 'onSelectOption' | 'onUnselectOption'>{
  selectedCount: number;
  defaultOptions: CompanyFromFilter[];
  selectedOptions: CompanyFromFilter[];
  filterPropertyRuleId: string | undefined;
}

const CompanyFilterElementsDropdownContent: FC<React.PropsWithChildren<Props>> = ({
  defaultOptions,
  filterPropertyRuleId,
  onSelectOption,
  onUnselectOption,
  selectedCount,
  selectedOptions,
}) => {
  const {
    data, refetch,
  } = useQuery(FilterPropertyRuleCompanyWithSearchDocument, {
    fetchPolicy: 'cache-first',
    skip: !filterPropertyRuleId,
    variables: {
      id: filterPropertyRuleId ?? '',
      searchText: '',
    },
  });
  const searchDebounced = useDebouncedCallback(refetch, INPUT_ONCHANGE_DEBOUNCE);

  const displayedOptionsFilters: CompanyFromFilter[] = useMemo(() => {
    const optionsFromSearch =
      data?.node?.__typename === 'FilterPropertyRuleCompany' &&
        data.node.rule.__typename === 'RuleCompanyMultipleValues'
        ? nodeToArray(data.node.rule.values)
        : undefined;

    return optionsFromSearch ?? defaultOptions;
  }, [data, defaultOptions]);

  const options = useMemo(() => displayedOptionsFilters.map(({ value: company }) => ({
    ...getCompanyOption(company),
    selected: !!selectedOptions.find(s => s.value.id === company.id),
  })), [displayedOptionsFilters, selectedOptions]);

  const onSearchChange = useCallback(async (search: string) => {
    if (!filterPropertyRuleId) return;
    await searchDebounced({
      searchText: search,
    });
  }, [filterPropertyRuleId, searchDebounced]);

  return (
    <SelectPanel
      filterOptionsOnInputChange={false}
      isMulti
      options={options}
      onSelectOption={onSelectOption}
      onUnselectOption={onUnselectOption}
      onSearchChange={onSearchChange}
      selectedCount={selectedCount}
      hideChecks
    >
      {!!selectedOptions.length && (
        <SelectedOptionTagContainer>
          {selectedOptions.map(({ value }) => (
            <SelectedOptionTag
              key={value.id}
              variant="ternary"
              size="S"
              onClick={() => onUnselectOption?.(getCompanyOption(value), options.findIndex(o => o.value === value.id))}
            >
              {value.name?.trim()}
              <SelectedOptionCloseIcon />
            </SelectedOptionTag>
          ))}
        </SelectedOptionTagContainer>
      )}
    </SelectPanel>
  );
};

export default CompanyFilterElementsDropdownContent;
