import { ToggleInput, typo } from '@cycle-app/ui';
import { LabelText } from '@cycle-app/ui/components/Inputs/ToggleInput/ToggleInput.styles';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledToggleInput = styled(ToggleInput)`
  --bg: ${p => (p.theme.isDark ? p.theme.colors.background.primary : p.theme.colors.background.disabled)};
  color: ${p => p.theme.colors.text.primary};
  ${LabelText} {
    color: ${p => p.theme.colors.text.primary};
    ${typo.body500}
  }
`;

export const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FieldArray = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FieldItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  > :nth-child(1) {
    flex: 1;
  }
  > :nth-child(2) {
    flex: none;
    margin-top: 8px;
  }
`;

export const FieldTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PublicField = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-items: center;
  
  > [data-popover] {
    line-height: 0;
    > svg {
      color: ${p => p.theme.colors.text.disabled};
    }
  }
`;
