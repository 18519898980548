import { ToasterAction } from '@cycle-app/ui';
import { useCallback, ReactChild } from 'react';

import { DocTagKey } from 'src/components/DocTagKey';
import { useNavigate } from 'src/hooks/useNavigate';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { getDocFromCache } from 'src/utils/cache.utils';
import { getDocKey } from 'src/utils/doc.util';
import { isCustom } from 'src/utils/docType.util';
import { addToaster, AddToasterParam } from 'src/utils/toasters.utils';

import { useProductBase } from '..';

type AddDocToasterParam = Omit<AddToasterParam, 'message'> & {
  docId: string;
  message: (tag: ReactChild | null) => ReactChild;
};

export const useAddDocToaster = () => {
  const product = useProductBase();
  const { navigateToDocPanelPage } = useNavigate();

  const addDocToaster = useCallback(({
    docId, message, actions, ...params
  }: AddDocToasterParam) => {
    const doc = getDocFromCache(docId);
    const docKey = getDocKey(product?.key, doc?.publicId);
    const tag = doc && docKey && isCustom(getDocType(doc.id))
      ? (
        <DocTagKey
          docKey={docKey}
          docTitle={doc.title}
          docId={doc.id}
          style={{
            display: 'inline-flex',
            margin: '8px 0 4px',
            color: 'inherit',
          }}
        />
      )
      : null;

    const isOnDocView = doc && window.location.pathname.includes(doc.id);

    return addToaster({
      actions: (
        <>
          {doc && !isOnDocView && (
            <ToasterAction onClick={() => navigateToDocPanelPage(doc)}>
              Open
            </ToasterAction>
          )}
          {actions}
        </>
      ),
      message: message(tag),
      ...params,
    });
  }, [navigateToDocPanelPage, product?.key]);

  return addDocToaster;
};
