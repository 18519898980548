import { Button } from '@cycle-app/ui';
import { plural } from '@cycle-app/utilities';
import { useState } from 'react';

import { AddOnItem } from 'src/components/AddOnItem';
import { useProductAddOns } from 'src/hooks';

import { Title, SubTitle, AddOnsContainer } from './AddOns.styles';
import { AddOnsModal } from './AddOnsModal';

interface Props {
  className?: string;
}

export const AddOns = ({ className }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const addOns = useProductAddOns();

  const addOnItems = addOns
    .filter(a => a.isEnabled)
    .map(a => <AddOnItem key={a.name} addOn={a} />);

  return (
    <div className={className}>
      <Title>
        Add-ons
        <Button size="M" variant="secondary" onClick={() => setIsModalVisible(true)}>
          Browse Add-ons
        </Button>
      </Title>
      <SubTitle>
        {addOnItems.length
          ? `You currently have ${addOnItems.length} ${plural('add-on', addOnItems.length)}`
          : 'You have no add-on yet'}
      </SubTitle>
      <AddOnsContainer>
        {addOnItems}
      </AddOnsContainer>

      {isModalVisible && <AddOnsModal hide={() => setIsModalVisible(false)} />}
    </div>
  );
};
