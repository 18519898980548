import BoardConfigFormFilters from 'src/components/BoardConfigForm//BoardConfigFormFilters';
import { BoardConfigFormViewType } from 'src/components/BoardConfigForm//BoardConfigFormViewType';
import { Label, LabelSoon } from 'src/components/BoardConfigForm/BoardConfigForm.styles';
import BoardConfigFormGroupby from 'src/components/BoardConfigForm/BoardConfigFormGroupby';
import BoardConfigFormProperties from 'src/components/BoardConfigForm/BoardConfigFormProperties';
import { NewBadge } from 'src/components/NewBadge';
import { SoonBadge } from 'src/components/SoonBadge/SoonBadge';
import useDraftBoardConfig from 'src/hooks/api/useDraftBoardConfig';
import { getBoardConfigGroupBy } from 'src/utils/boardConfig/boardConfig.util';

import { BoardConfigFormSortBy } from '../BoardConfigForm/BoardConfigFormSortBy';

export type InboxBoardConfigFieldsProps = {
  boardId: string;
  isDisabled?: boolean;
};

export const InboxBoardConfigFields = ({
  boardId, isDisabled = false,
}: InboxBoardConfigFieldsProps) => {
  const { data } = useDraftBoardConfig(boardId);
  const draftBoardConfig = data?.node?.draftBoardConfig;
  if (!draftBoardConfig) return null;
  return (
    <>
      <div>
        <Label>View type</Label>
        <BoardConfigFormViewType boardId={boardId} isDisabled={isDisabled} />
      </div>

      <div>
        <Label>Filters</Label>
        <BoardConfigFormFilters
          boardConfigId={draftBoardConfig.id}
          filterProperties={draftBoardConfig.filterProperties}
          filterableProperties={draftBoardConfig.filterableProperties}
          boardID={boardId}
          disabled={isDisabled}
        />
      </div>

      <div>
        <Label>
          Sort
          <NewBadge />
        </Label>
        <BoardConfigFormSortBy
          boardConfigId={draftBoardConfig.id}
          sortByProperty={draftBoardConfig.sortByProperty}
          sortableProperties={draftBoardConfig.sortableProperties}
          filterableProperties={draftBoardConfig.filterableProperties}
        />
      </div>

      <div>
        <Label>Displayed properties</Label>
        <BoardConfigFormProperties
          boardConfigId={draftBoardConfig.id}
          viewType={draftBoardConfig.viewType}
          properties={draftBoardConfig.properties}
          boardID={boardId}
          isDisabled={isDisabled}
        />
      </div>

      <div>
        <Label>Group</Label>
        <BoardConfigFormGroupby
          boardConfigId={draftBoardConfig.id}
          groupableProperties={draftBoardConfig?.groupableProperties}
          groupByConfig={getBoardConfigGroupBy(draftBoardConfig)}
          filterProperties={draftBoardConfig.filterProperties}
          boardID={boardId}
          isDisabled={isDisabled}
          filterableProperties={draftBoardConfig.filterableProperties}
          availableSwimlaneByDoctypes={draftBoardConfig.availableSwimlaneByDoctypes}
        />
      </div>

      <LabelSoon>
        Aggregate metrics
        <SoonBadge />
      </LabelSoon>
    </>
  );
};
