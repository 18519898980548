import { Reference } from '@apollo/client';
import { RevokeOAuthClientAccessDocument } from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { parseStoreFieldName } from 'src/utils/update-cache/cache.utils';

export const useRevokeOAuthClient = (clientId: string) => {
  const { product } = useProduct();
  const [deleteClient] = useSafeMutation(RevokeOAuthClientAccessDocument);
  return () => {
    return deleteClient({
      variables: {
        id: clientId,
        productId: product?.id as string,
      },
      optimisticResponse: { revokeOAuthClientAccess: { id: clientId } },
      update: (cache, { data }) => {
        if (!product || !data?.revokeOAuthClientAccess) return;
        cache.modify({
          id: cache.identify(product),
          fields: {
            oauthClients: (refs, {
              readField, storeFieldName,
            }) => {
              const storeVariables = parseStoreFieldName(storeFieldName);
              if (!storeVariables.installed) return refs;
              return {
                ...refs,
                edges: refs.edges.filter(
                  (edge: { node: Reference }) => readField('id', edge.node) !== data.revokeOAuthClientAccess?.id,
                ),
              };
            },
          },
        });
      },
    });
  };
};
