import { DocBaseFragment } from '@cycle-app/graphql-codegen';

import { ToggleButtonProps } from 'src/components/DropdownLayer';
import { ReleaseEditModal } from 'src/components/ReleaseModals';
import { ReleasesDropdown } from 'src/components/ReleasesDropdown';
import { PageId } from 'src/constants/routing.constant';
import { ReleasesProvider, useReleasesContext } from 'src/contexts/releasesContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCreateReleaseNote } from 'src/hooks/releases/useCreateReleaseNote';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openCreateRelease } from 'src/reactives/releases.reactive';

type DocReleaseNoteCreateButtonProps = {
  releaseNote: DocBaseFragment['releaseNote'];
  docId: string;
  children: (props: ToggleButtonProps, isLoading: boolean) => React.ReactNode;
};

export const DocReleaseNoteCreateButton = (props:DocReleaseNoteCreateButtonProps) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  return (
    <ReleasesProvider productId={productId}>
      <DocReleaseNoteCreateButtonContent {...props} />
    </ReleasesProvider>
  );
};

const DocReleaseNoteCreateButtonContent = ({
  docId, children, releaseNote,
}:DocReleaseNoteCreateButtonProps) => {
  const releasesCount = useReleasesContext(ctx => ctx.releasesList.length);
  const isReleasesEnabled = useIsReleasesEnabled();
  const { navigate } = useNavigateToSettings();
  const { canCreateReleaseNote } = useGetPermission();
  const {
    createReleaseNote, isCreatingReleaseNote,
  } = useCreateReleaseNote();
  const onChange = (releaseId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createReleaseNote({
      releaseId,
      docId,
      isOther: false,
    });
  };

  return (
    <>
      {!releaseNote && (
        <ReleasesDropdown
          title="First pick a release"
          placement="bottom-start"
          withWrapper={false}
          withPortal
          onChange={onChange}
          button={props => (
            <div>
              {children({
                ...props,
                // eslint-disable-next-line no-nested-ternary
                onClick: canCreateReleaseNote
                  // eslint-disable-next-line no-nested-ternary
                  ? isReleasesEnabled
                    ? releasesCount === 0
                      ? openCreateRelease
                      : props.onClick
                    : () => navigate(PageId.SettingsReleases)
                  : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
              }, isCreatingReleaseNote)}
            </div>
          )}
        />
      )}
      <ReleaseEditModal onReleaseCreated={onChange} />
    </>
  );
};
