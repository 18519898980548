import { DoctypeType, ViewType } from '@cycle-app/graphql-codegen';
import { Button, ViewCardSkeleton, InsightCardSkeleton } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useState, useMemo, FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useUpdateDocCustomer } from 'src/hooks/api/mutations/useUpdateDocCustomer';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useBuiltIntSpecificAttributes } from 'src/hooks/api/useAttributes';
import { usePageId } from 'src/hooks/usePageId';

import { AddButton, DoctypeInfo, DoctypeLabel } from '../DocHierarchy/DocHierarchyGroup/DocHierarchyGroup.styles';
import InputLinkDoc from '../InputLinkDoc/InputLinkDoc';
import { CustomerChidrenTabsCounter } from './CustomerChidrenTabsCounter';
import { CustomerChildrenInsightsAdd } from './CustomerChildrenInsightsAdd';
import { HierarchyContainer, StyledTab, TabList, Tabs } from './CustomerChildrenTabs.styles';
import { CustomerChildrenTabHierarchy } from './CustomerChildrenTabsHierarchy';

interface Props {
  customerId: string;
}

export const CustomerChildrenTabs: FC<React.PropsWithChildren<Props>> = ({ customerId }) => {
  const pageId = usePageId();

  const { customerAttribute } = useBuiltIntSpecificAttributes();
  const docTypes = useMemo(() => {
    const d = nodeToArray(customerAttribute?.doctypes);
    const ordered = getSortedDocTypes<typeof d>(d);
    return ordered;
  }, [customerAttribute]);
  const [activeDoctypeId, setActiveDoctypeId] = useState(docTypes[0]?.id);
  const [inputDoctypeId, setInputDoctypeId] = useState('');
  const { linkDocCustomer } = useUpdateDocCustomer();
  const { customer } = useCustomer(customerId);

  const isInDocView = [PageId.Doc, PageId.DocFullPage].includes(pageId);
  const activeDoctype = docTypes.find(({ id }) => activeDoctypeId === id);

  return (
    <Tabs>
      <TabList>
        {docTypes.map((doctype) => (
          <StyledTab
            key={doctype.id}
            $isActive={activeDoctype?.id === doctype.id}
            forwardedAs="div"
          >
            <Button
              variant="nospace"
              onClick={() => {
                setActiveDoctypeId(doctype.id);
                setInputDoctypeId('');
              }}
            >
              <DoctypeInfo>
                <DoctypeLabel>
                  {doctype.name}
                </DoctypeLabel>
                <CustomerChidrenTabsCounter customerId={customerId} doctypeType={doctype.type} />
              </DoctypeInfo>
            </Button>
            {doctype.type === DoctypeType.Insight
              ? (
                <CustomerChildrenInsightsAdd
                  customerId={customerId}
                  onAddClick={() => {
                    setActiveDoctypeId(doctype.id);
                  }}
                />
              )
              : (
                <AddButton
                  onClick={() => {
                    setActiveDoctypeId(doctype.id);
                    setInputDoctypeId(doctype.id);
                  }}
                  tooltipPlacement="top"
                  tooltip={`Add ${doctype.name}`}
                >
                  <AddIcon />
                </AddButton>
              )}
          </StyledTab>
        ))}
      </TabList>
      {activeDoctype && customer && (
        <HierarchyContainer key={activeDoctype.id}>
          {activeDoctype.id === inputDoctypeId && activeDoctype.type !== DoctypeType.Insight && (
            <InputLinkDoc
              autoFocus
              label={`Add a new ${activeDoctype.name}...`}
              placeholder={`Add a new ${activeDoctype.name}...`}
              hideInput={() => setInputDoctypeId('')}
              onDocLinked={(docId, doctypeId, created, doc) => linkDocCustomer({
                customer,
                created,
                docId,
                doctypeId,
                doc,
              })}
              doctype={activeDoctype}
              inheritedAttributes={[]}
              customerId={customerId}
              docFilter={(node) => !node.customer}
            />
          )}
          <CustomerChildrenTabHierarchy
            customerId={customerId}
            doctype={activeDoctype}
            isInDocView={isInDocView}
            onDocLinked={(docId, doctypeId, created, doc) => linkDocCustomer({
              customer,
              created,
              docId,
              doctypeId,
              doc,
            })}
            docsSkeleton={activeDoctype.type === DoctypeType.Insight ? (
              <>
                <InsightCardSkeleton />
                <InsightCardSkeleton />
                <InsightCardSkeleton />
              </>
            ) : (
              <>
                <ViewCardSkeleton viewType={ViewType.List} />
                <ViewCardSkeleton viewType={ViewType.List} />
                <ViewCardSkeleton viewType={ViewType.List} />
              </>
            )}
          />
        </HierarchyContainer>
      )}
    </Tabs>
  );
};

type GetSortedDocTypeParams = {
  docTypes: {
    type: DoctypeType;
  }[];
};
/**
 * We want to display Feedback first and insight in second
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const getSortedDocTypes = <T extends any>(
  docTypes: GetSortedDocTypeParams['docTypes'],
): T => docTypes.sort((a) => (a.type === DoctypeType.Feedback ? -1 : 0)) as T;
