import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CustomViewsIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="1 1 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M8.25 3.75H3.75C2.92157 3.75 2.25 4.42157 2.25 5.25V12.75C2.25 13.5784 2.92157 14.25 3.75 14.25H8.25M8.25 3.75H14.25C15.0784 3.75 15.75 4.42157 15.75 5.25V12.75C15.75 13.5784 15.0784 14.25 14.25 14.25H8.25M8.25 3.75V14.25" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
    <path d="M4.6875 6.75C4.6875 7.06066 4.93934 7.3125 5.25 7.3125C5.56066 7.3125 5.8125 7.06066 5.8125 6.75C5.8125 6.43934 5.56066 6.1875 5.25 6.1875C4.93934 6.1875 4.6875 6.43934 4.6875 6.75ZM4.6875 9C4.6875 9.31066 4.93934 9.5625 5.25 9.5625C5.56066 9.5625 5.8125 9.31066 5.8125 9C5.8125 8.68934 5.56066 8.4375 5.25 8.4375C4.93934 8.4375 4.6875 8.68934 4.6875 9ZM4.6875 11.25C4.6875 11.5607 4.93934 11.8125 5.25 11.8125C5.56066 11.8125 5.8125 11.5607 5.8125 11.25C5.8125 10.9393 5.56066 10.6875 5.25 10.6875C4.93934 10.6875 4.6875 10.9393 4.6875 11.25Z" fill="currentcolor" stroke="currentcolor" strokeWidth="0.375" />
  </Svg>
);
