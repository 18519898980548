import { make } from 'src/utils/reactives.util';

export const {
  hookValue: useGetSettingsApi,
  getValue: getSettingsApi,
  setValue: setSettingsApi,
} = make<{
  openedEditAppModal: string | null;
  openedRemoveAppModal: string | null;
  openedEditWebhookpModal: string | null;
  openedRemoveWebhookModal: string | null;
  openedEditApiKeyModal: string | null;
  openedRemoveApiKeyModal: string | null;
  openedRevokeAppModal: string | null;
}>({
  defaultState: {
    openedEditAppModal: null,
    openedRemoveAppModal: null,
    openedEditWebhookpModal: null,
    openedRemoveWebhookModal: null,
    openedEditApiKeyModal: null,
    openedRemoveApiKeyModal: null,
    openedRevokeAppModal: null,
  },
});

export const closeEditAppModal = () => setSettingsApi({ openedEditAppModal: null });
export const closeRemoveAppModal = () => setSettingsApi({ openedRemoveAppModal: null });
export const closeEditWebhookModal = () => setSettingsApi({ openedEditWebhookpModal: null });
export const closeRemoveWebhookModal = () => setSettingsApi({ openedRemoveWebhookModal: null });
export const closeEditApiKeyModal = () => setSettingsApi({ openedEditApiKeyModal: null });
export const closeRemoveApiKeyModal = () => setSettingsApi({ openedRemoveApiKeyModal: null });
export const closeRevokeAppModal = () => setSettingsApi({ openedRevokeAppModal: null });
