import { forwardRef } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const ClipboardIcon = forwardRef<SVGSVGElement, React.PropsWithChildren<SvgProps>>((props, ref) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    data-no-fill
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      // eslint-disable-next-line max-len
      d="M10.6667 3.33333H12.0001C12.7365 3.33333 13.3334 3.93029 13.3334 4.66667V12.6667C13.3334 13.403 12.7365 14 12.0001 14H4.00008C3.2637 14 2.66675 13.403 2.66675 12.6667V4.66667C2.66675 3.93029 3.2637 3.33333 4.00008 3.33333H5.33341M10.6667 3.33333V4.66667H5.33341V3.33333M10.6667 3.33333C10.6667 2.59695 10.0698 2 9.33341 2H6.66675C5.93037 2 5.33341 2.59695 5.33341 3.33333"
      stroke="currentcolor"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
));
