import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { ERROR_CODE } from '@cycle-app/utilities';
import { useRef, FC } from 'react';
import { Controller } from 'react-hook-form';

import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { addToaster } from 'src/utils/toasters.utils';

import { ErrorMap, useEnhancedForm } from '../../hooks/form/useEnhancedForm';
import { EditTextButton } from '../EditTextButton/EditTextButton';

interface Props {
  className?: string;
  customer: CustomerFragment;
  readOnly?: boolean;
  onClick?: VoidFunction;
}

type FormData = { email: string };

const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    code: ERROR_CODE.CUSTOMER_EMAIL_ALREADY_USED,
    fieldName: 'email',
    renderMessage: () => 'A customer with this email already exists',
  },
];

export const CustomerEmail: FC<React.PropsWithChildren<Props>> = ({
  className, customer, readOnly, onClick,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    update, isLoading,
  } = useCustomerUpdate(customer);
  const {
    control, handleSubmit, setValue, displayFieldsErrors,
  } = useEnhancedForm<FormData>({ defaultValues: { email: customer.email ?? '' } });
  const placeholder = readOnly
    ? 'No email'
    : (
      <>
        Add email
        <PenFilledIcon />
      </>
    );
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form onSubmit={handleSubmit(onSubmit)} onClick={onClick}>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <EditTextButton
            ref={inputRef}
            className={className}
            disabled={readOnly}
            isLoading={isLoading}
            onChange={field.onChange}
            onBlur={onBlur}
            onCancel={onBlur}
            required
            placeholder={placeholder}
            type="email"
            value={field.value}
            editTooltip="Edit customer email"
          />
        )}
      />
    </form>
  );

  function onBlur() {
    setValue('email', customer.email || '');
  }

  async function onSubmit({ email }: FormData) {
    if (!email && !customer.name?.trim()) {
      addToaster({
        title: 'An error occured',
        message: 'Customers must have at least a name or an email',
      });
      return;
    }
    const result = await update({
      customerId: customer.id,
      email,
    });
    if (result.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap, true);
      return;
    }
    if (result.data?.updateCustomer?.email) {
      inputRef.current?.blur();
      setValue('email', result.data.updateCustomer.email);
    }
  }
};
