import { IntegrationFullFragment } from '@cycle-app/graphql-codegen';
import { Flex } from '@cycle-app/ui';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { IntegrationLinearAutomation } from 'src/components/IntegrationLinearAutomation';
import { HELP_URL_LINEAR } from 'src/constants/help.constants';
import { Layer } from 'src/types/layers.types';

import {
  Description,
  LearnMoreButton,

} from './IntegrationLinearAutomationModal.styles';

type IntegrationLinearAutomationModalProps = {
  isVisible: boolean;
  hide: VoidFunction;
  integration: IntegrationFullFragment;
};

export const IntegrationLinearAutomationModal = ({
  isVisible, hide, integration,
}: IntegrationLinearAutomationModalProps) => {
  if (
    !isVisible ||
    integration.provider?.__typename !== 'Linear'
  ) return null;

  return (
    <DialogModal
      layer={Layer.Modal}
      type="default"
      title="Linear automation"
      info={(
        <>
          <Flex $column $align="flex-start" $gap={16}>
            <LinearAutomationsDescription />
            <LearnMoreButton as="a" href={HELP_URL_LINEAR} target="_blank">
              Learn more about Linear automations
            </LearnMoreButton>
            <IntegrationLinearAutomation />
          </Flex>
        </>
      )}
      hide={hide}
      autoHide={false}
      width="754px"
      hasFooter={false}
    />
  );
};

export const LinearAutomationsDescription = () => (
  <Description>
    {/* eslint-disable-next-line max-len */}
    Automate the creation of a Linear issue or project whenever a Cycle doc is created. You can create different automations for different doc types.
  </Description>
);
