import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MoreHorizCircleIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16px" height="16px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9.625C6.34518 9.625 6.625 9.34518 6.625 9C6.625 8.65482 6.34518 8.375 6 8.375C5.65482 8.375 5.375 8.65482 5.375 9C5.375 9.34518 5.65482 9.625 6 9.625Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
    <path d="M9 9.625C9.34518 9.625 9.625 9.34518 9.625 9C9.625 8.65482 9.34518 8.375 9 8.375C8.65482 8.375 8.375 8.65482 8.375 9C8.375 9.34518 8.65482 9.625 9 9.625Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
    <path d="M12 9.625C12.3452 9.625 12.625 9.34518 12.625 9C12.625 8.65482 12.3452 8.375 12 8.375C11.6548 8.375 11.375 8.65482 11.375 9C11.375 9.34518 11.6548 9.625 12 9.625Z" fill="currentColor" stroke="currentColor" strokeWidth="0.5" />
    <circle cx="9" cy="9" r="6.9375" stroke="currentColor" strokeWidth="1.5" />
  </Svg>
);
