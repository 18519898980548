import DialogModal, { DialogModalProps } from 'src/components/DialogModal/DialogModal';
import { useOptimizedBooleanState } from 'src/hooks';

type Props = Pick<DialogModalProps, 'hide' | 'onConfirm'> & {
  quotesCount: number;
};

export const DocProcessModal = ({
  quotesCount, hide, onConfirm,
}: Props) => {
  const [isChecked, {
    toggleCallback: toggleCheck,
    setFalseCallback: uncheck,
  }] = useOptimizedBooleanState(false);

  return (
    <DialogModal
      width="520px"
      type="default"
      variantCancelButton="secondary"
      variantSubmitButton="primary"
      title="Mark processed"
      cancelLabel="Cancel"
      confirmLabel="Mark processed"
      confirmMessage={getConfirmMessage(quotesCount)}
      info={(
        <>
          <p>Are you sure you want to mark this feedback as processed?</p>
          <p>{getInfoMessage(quotesCount)}</p>
        </>
      )}
      onToggleConfirmation={toggleCheck}
      onConfirm={() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        onConfirm?.();
      }}
      isConfirmDisabled={!isChecked}
      hide={() => {
        hide();
        uncheck();
      }}
    />
  );
};

const getConfirmMessage = (count: number) => (count === 1
  ? 'Discard 1 ai-generated quote'
  : `Discard ${count} ai-generated quotes`);

const getInfoMessage = (count: number) => (count === 1
  ? 'You still have 1 ai-generated quote to verify or discard.'
  : `You still have ${count} ai-generated quotes to verify or discard.`);
