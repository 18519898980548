import { typo, Skeleton, CircleSkeleton, ShyScrollbarCss, Button } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

const cardBased = css`
  width: 24px;
  height: 24px;
  border-radius: 8px;
  padding: 8px;
`;

export const Header = styled.header`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 0 32px 8px;

  ${mobile} {
    padding: 0 16px 8px;
  }
`;

export const Title = styled.h2`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
`;

export const MembersContainerWrapper = styled.div`
  display: flex;
`;

export const MembersContainer = styled.div`
  ${ShyScrollbarCss}
  display: flex;
  border-radius: 12px;
  overflow-x: scroll;
`;

export const MemberCardSkeleton = styled.div`
  ${cardBased}
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

export const MemberAvatarSkeleton = styled(CircleSkeleton)`
  width: 24px;
  height: 24px;

  &:not(:first-child) {
    margin-left: -9px;
  }
`;

export const MemberNameSkeleton = styled(Skeleton)`
  height: 12px;
  width: 40%;
`;

export const MemberStatusSkeleton = styled(Skeleton)`
  height: 10px;
  width: 60%;
  opacity: 0.7;
`;

export const Text = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};
  text-align: center;
`;

export const MemberEmptyState = styled.button`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 16px;
  margin: 8px 16px 16px 16px;

  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }
`;

export const SubTitle = styled.h4`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Image = styled.img`
  margin-bottom: 8px;
  object-fit: cover;
`;

const scrollButtonCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: ${p => p.theme.colors.background.primary};
  color: ${p => p.theme.colors.text.disabled};
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const ScrollLeftButton = styled.button`
  ${scrollButtonCSS}
  left: 40px;
  transform: translateY(-50%) rotate(180deg);
`;

export const ScrollRightButton = styled.button`
  ${scrollButtonCSS}
  right: 40px;
  transform: translateY(-50%);
`;

export const StyledButton = styled(Button)`
  padding: 0;
  &:hover, &:focus {
    --bg: transparent;
  }
`;
