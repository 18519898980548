import { useFeatureFlag, useIsOffline, useLocationSelector } from 'src/hooks';
import { PageId } from 'src/hooks/usePageId';
import { useIsMobile } from 'src/reactives';
import { useGetNewVersion } from 'src/reactives/newVersion.reactive';
import { getPageIdFromPathname, getParentFromPathname } from 'src/utils/routing.utils';

export const useIsDropIslandEnabled = () => {
  const isDropZoneMiniEnabled = useFeatureFlag('dropzone-mini').isEnabled;
  const isMobile = useIsMobile();
  const isOffline = useIsOffline();
  const { hasNewVersion } = useGetNewVersion();

  const isPageWithDropIsland = useLocationSelector(location => {
    const pageId = getPageIdFromPathname(location.pathname);
    const parentPage = getParentFromPathname(location.pathname);
    return (
      ['home', 'settings', 'billing-update'].includes(parentPage) ||
      [PageId.InboxDoc, PageId.InsightDoc, PageId.RoadmapDoc, PageId.DocFullPage, PageId.Changelog].includes(pageId)
    );
  });

  return !(isPageWithDropIsland || !isDropZoneMiniEnabled || isMobile || isOffline || hasNewVersion);
};
