import { UserJourney } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { getGamification, setGamification } from 'src/reactives';
import { GamificationStep } from 'src/types/gamification.types';

import { useMaybeMe } from '../api/useMe';
import { useProductRole } from '../useRoles';

export const useStartGamification = () => {
  const me = useMaybeMe();
  const { role } = useProductRole();

  useEffect(() => {
    if (
      !me ||
      !role ||
      getGamification().isActive ||
      me?.userJourney === UserJourney.Done ||
      me?.userJourney === UserJourney.Onboarding
    ) return;

    /**
     * If user pass the onboarding on mobile, he won't trigger the product tour
     * then we not complete onboarding step matching for him.
     */
    const currentStepDone = getGamification().stepDone;
    const stepDoneToAdd = [GamificationStep.SetupAccount].filter(s => !currentStepDone.includes(s));

    setGamification({
      isActive: true,
      stepDone: stepDoneToAdd,
    });
  }, [me, role]);
};
