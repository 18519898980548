import { CheckboxInput, CheckboxInputText, CheckboxSquare, typo, boxShadowZ3 } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import { tablet } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import backdrop from './backdrop.svg';
import bg from './bg.png';
import stroke from './stroke.png';

export const Aside = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const Desc = styled.div`
  white-space: pre;

  ${tablet} {
    white-space: normal;
  }
`;

export const StyledAiIcon = styled(AiIcon)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const DoctypesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 40px;
  margin-bottom: 8px;
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  border-radius: 50px;
  border: 2px solid ${p => p.theme.colors.border.primary};
  transition: background-color .1s;
  opacity: 1;

  label {
    align-items: center;
    display: flex;
    gap: 15px;
    height: 36px;
    padding: 12px;
  }

  ${CheckboxSquare} {
    display: none;
  }

  ${CheckboxInputText} {
    ${typo.body500}
    align-items: center;
    color: ${p => p.theme.colors.text.primary};
    display: flex;
    gap: 8px;
    overflow: hidden;
  }

  /* Focus by keyboard, hover */
  &:has(:focus-visible), &:hover {
    background-color: ${p => (p.theme.colors.input.hover)};
  }

  &:has(:checked) {
    border-color: hsl(var(--cycle));
  }
`;

export const Card = styled(motion.div)`
  --y: 0px;
  --x: 0px;
  min-height: 159px;
  position: relative;
  transition: transform .2s;
  width: 250px;

  &:nth-child(odd) {
    transform: translate(calc(-30% + var(--x)), var(--y));
    --y: calc(-69px * var(--i));
  }

  &:nth-child(even) {
    transform: translate(calc(30% + var(--x)), var(--y));
    --y: calc(-72px * var(--i));
  }

  /* use pseudo elements to make the backdrop behind */
  &::before {
    background: ${p => (p.theme.isDark ? 'linear-gradient(180deg, #404040 0%, #303030 100%)' : `url("${stroke}"), url("${bg}")`)};
    background-size: cover;
    border-radius: 16px;
    bottom: 0;
    ${p => (!p.theme.isDark && 'box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.06), 0px 0px 46px 2px rgba(255, 255, 255, 0.9) inset;')}
    ${p => (p.theme.isDark && boxShadowZ3)}
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  &::after {
    background: url("${backdrop}");
    background-repeat: repeat;
    ${p => !p.theme.isDark && 'content: "";'}
    height: 76px;
    left: -35px;
    position: absolute;
    top: 70%;
    width: 100%;
    z-index: 1;
  }
`;

export const CardTitle = styled.div`
  ${typo.body500}
  display: flex;
  gap: 8px;
  padding: 20px 10px 15px 20px;
  position: relative;
  z-index: 3;
`;

export const CardChart = styled.div`
  padding: 0 20px 20px;
  position: relative;
  z-index: 3;

  img {
    display: block;
  }
`;
