import { CompanyFragment } from '@cycle-app/graphql-codegen';
import { Button, CompanyLogo } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import ImageInput from 'src/components/ImageInput/ImageInput';
import { useCompanyUpdate } from 'src/hooks/api/mutations/customers/useCompanyUpdate';

import {
  Form,
  FormInput,
  InputContent,
  UploadNewBtn,
  RemoveBtn,
} from './Customers.styles';

type FormData = Pick<CompanyFragment, 'name' | 'logo'> & {
  logoFile?: File;
  logoPreviewUrl?: string;
};

interface Props {
  onClose: VoidFunction;
  defaultValues: FormData;
  company: CompanyFragment;
}

export const CompanyUpdateModal: FC<React.PropsWithChildren<Props>> = ({
  onClose, defaultValues, company,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });

  const {
    updateCompany, isLoading,
  } = useCompanyUpdate();

  const onSubmit = useCallback(async (data: FormData) => {
    if (isLoading || !company.id) return;

    await updateCompany({
      companyId: company.id,
      name: data.name ?? '',
      avatarInput: data.logoFile ? {
        avatar: data.logoFile,
      } : null,
    });
    onClose();
  }, [company.id, isLoading, onClose, updateCompany]);

  const logoUrl = watch('logoPreviewUrl');

  return (
    <PortalModalStyled hide={onClose}>
      <Header>
        <Title>
          Update company
        </Title>
        <CloseButtonStyled onClick={onClose}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          autoFocus
          type="text"
          label="Name"
          placeholder="Company name"
          error={errors.name?.message}
          {...register('name', {
            required: 'You must have at least 3 characters.',
            min: 3,
          })}
        />

        <ImageInput
          label="Logo"
          previewModalTitle="New company logo"
          previewModalSubmitLabel="Set new company logo"
          onChange={(file) => {
            setValue('logoFile', file);
            setValue('logoPreviewUrl', URL.createObjectURL(file));
          }}
        >
          {(inputRef) => (
            <InputContent>
              <CompanyLogo
                company={company}
                src={logoUrl}
                onClick={() => inputRef.current?.click()}
              />
              <UploadNewBtn
                onClick={() => inputRef.current?.click()}
              >
                Upload new
              </UploadNewBtn>

              <RemoveBtn
                onClick={() => {
                  setValue('logoFile', undefined);
                  setValue('logoPreviewUrl', '');
                }}
              >
                Remove
              </RemoveBtn>
            </InputContent>
          )}
        </ImageInput>

        <Actions>
          <Button
            size="M"
            type="button"
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="M"
            type="submit"
            isLoading={isLoading}
            disabled={!!Object.keys(errors).length}
          >
            Update
          </Button>
        </Actions>
      </Form>
    </PortalModalStyled>
  );
};
