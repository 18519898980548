import { CreateOAuthClientDocument, CreateOAuthClientMutationVariables } from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { KeyInput, CopyButton } from '../SettingsAPI.styles';

export const useCreateOAuthClient = () => {
  const { product } = useProduct();

  const [createClient] = useSafeMutation(CreateOAuthClientDocument, {
    onCompleted: (data) => {
      const clientSecret = data.createOAuthClient?.clientSecret;
      if (!clientSecret) return;
      addToaster({
        duration: 'infinite',
        title: 'Your new API key',
        message: (
          <div>
            <KeyInput
              value={clientSecret}
              onChange={() => {}}
              iconAfter={<CopyButton size={16} />}
              onClickIcon={() => copyToClipboard({
                text: clientSecret,
                notification: 'API key copied to clipboard',
              })}
            />
            <p style={{ marginTop: 8 }}>
              Please copy this secret and save it somewhere safe.
              For security reasons, we can’t show the secret to you again.
            </p>
          </div>
        ),
      });
    },
  });

  return (variables: Omit<CreateOAuthClientMutationVariables, 'productId'>) => {
    if (!product?.id) return null;
    return createClient({
      variables: {
        productId: product.id,
        ...variables,
      },
      optimisticResponse: {
        createOAuthClient: {
          __typename: 'OAuthClient',
          id: 'temp-id',
          name: variables.name,
          description: variables.description,
          developerName: variables.developerName,
          developerUrl: variables.developerUrl,
          webhookRessources: typeof variables.webhookRessources === 'string'
            ? [variables.webhookRessources]
            : variables.webhookRessources,
          webhookUrl: variables.webhookUrl,
          callbackUrls: typeof variables.callbackUrls === 'string'
            ? [variables.callbackUrls]
            : variables.callbackUrls,
          isPublic: variables.isPublic,
          webhookSecret: '',
          clientSecret: '',
        },
      },
      update: (cache, { data }) => {
        const newClientId = data?.createOAuthClient?.id;
        if (!product || !newClientId) return;
        cache.modify({
          id: cache.identify(product),
          fields: {
            oauthClients: (refs, { toReference }) => ({
              ...refs,
              edges: [
                { node: toReference(newClientId) },
                ...refs.edges,
              ],
            }),
          },
        });
      },
    });
  };
};
