import {
  AirtableIcon,
  AttioIcon,
  CannyIcon,
  ConfluenceIcon,
  DiscordIcon,
  DovetailIcon,
  EmailIcon,
  FreshDeskIcon,
  FrontIcon,
  FullStoryIcon,
  GainsightIcon,
  GongIcon,
  GoogleMeetIcon,
  GoogleSheetsIcon,
  GrainIcon,
  HubSpotIcon,
  IntercomIcon,
  MicrosoftTeamsIcon,
  ModjoIcon,
  NotionIcon,
  ProductBoardIcon,
  RedditIcon,
  SalesforceIcon,
  SlackIcon,
  SnowflakeIcon,
  ZendeskIcon,
  ZoomIcon,
} from '@cycle-app/ui/icons';
import planhatUrl from '@cycle-app/ui/icons/social/planhat.png';
import vitallyUrl from '@cycle-app/ui/icons/social/vitally.png';

import { StyledChartIcon } from './OnboardingSources.styles';

export const sources = [
  {
    id: 'slack',
    icon: <SlackIcon />,
    label: 'Slack',
  },
  {
    id: 'intercom',
    icon: <IntercomIcon />,
    label: 'Intercom',
  },
  {
    id: 'zendesk',
    icon: <ZendeskIcon />,
    label: 'Zendesk',
  },
  {
    id: 'hubspot',
    icon: <HubSpotIcon />,
    label: 'HubSpot',
  },
  {
    id: 'salesforce',
    icon: <SalesforceIcon />,
    label: 'Salesforce',
  },
  {
    id: 'email',
    icon: <EmailIcon />,
    label: 'Email',
  },
  {
    id: 'notion',
    icon: <NotionIcon />,
    label: 'Notion',
  },
  {
    id: 'google-sheets',
    icon: <GoogleSheetsIcon />,
    label: 'Google Sheets',
  },
  {
    id: 'surveys',
    icon: <StyledChartIcon />,
    label: 'Surveys',
  },
  {
    id: 'gong',
    icon: <GongIcon />,
    label: 'Gong',
  },
  {
    id: 'modjo',
    icon: <ModjoIcon />,
    label: 'Modjo',
  },
  {
    id: 'front',
    icon: <FrontIcon />,
    label: 'Front',
  },
  {
    id: 'teams',
    icon: <MicrosoftTeamsIcon />,
    label: 'Teams',
  },
  {
    id: 'discord',
    icon: <DiscordIcon />,
    label: 'Discord',
  },
  {
    id: 'freshdesk',
    icon: <FreshDeskIcon />,
    label: 'Freshdesk',
  },
  {
    id: 'attio',
    icon: <AttioIcon />,
    label: 'Attio',
  },
  {
    id: 'confluence',
    icon: <ConfluenceIcon />,
    label: 'Confluence',
  },
  {
    id: 'airtable',
    icon: <AirtableIcon />,
    label: 'Airtable',
  },
  {
    id: 'fullstory',
    icon: <FullStoryIcon />,
    label: 'FullStory',
  },
  {
    id: 'productboard',
    icon: <ProductBoardIcon />,
    label: 'ProductBoard',
  },
  {
    id: 'dovetail',
    icon: <DovetailIcon />,
    label: 'Dovetail',
  },
  {
    id: 'grain',
    icon: <GrainIcon />,
    label: 'Grain',
  },
  {
    id: 'canny',
    icon: <CannyIcon />,
    label: 'Canny',
  },
  {
    id: 'reddit',
    icon: <RedditIcon />,
    label: 'Reddit',
  },
  {
    id: 'snowflake',
    icon: <SnowflakeIcon />,
    label: 'Snowflake',
  },
  {
    id: 'gainsight',
    icon: <GainsightIcon />,
    label: 'Gainsight',
  },
  {
    id: 'zoom',
    icon: <ZoomIcon />,
    label: 'Zoom',
  },
  {
    id: 'google-meet',
    icon: <GoogleMeetIcon />,
    label: 'Google Meet',
  },
  {
    id: 'planhat',
    icon: <img src={planhatUrl} alt="planhat-logo" />,
    label: 'Planhat',
  },
  {
    id: 'vitally',
    icon: <img src={vitallyUrl} alt="vitally-logo" />,
    label: 'Vitally',
  },
];
