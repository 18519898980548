import { IntegrationType } from '@cycle-app/graphql-codegen';
import { ActionButton, Tooltip } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import { useRef } from 'react';

import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useFeatureFlag, useSlackTour } from 'src/hooks';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { setTours, useTours } from 'src/reactives/productTours.reactive';
import { useUserPreferencesValue, setUserPreferences } from 'src/reactives/userPreferences.reactive';
import { Integration } from 'src/types/integrations.types';
import { TourName } from 'src/types/productTour.types';
import { integrationNameTitles } from 'src/utils/integrations.utils';

export type LearnMoreButtonProps = {
  type?: Integration | null;
};

export const LearnMoreButton = ({ type }: LearnMoreButtonProps) => {
  const isSlack = type === IntegrationType.Slack;
  const { isEnabled: isOnboardingSlackEnabled } = useFeatureFlag('slack-onboarding-2-0');
  const { endedTour } = useTours();
  const { discoverSlack } = useUserPreferencesValue();
  const questionOutlineIconRef = useRef<HTMLDivElement>(null);
  const { open: openSlackLearnMoreTour } = useSlackTour();

  useClickOutside(questionOutlineIconRef, () => {
    if (endedTour === TourName.SLACK) {
      setTours({ endedTour: undefined });
      setUserPreferences({ discoverSlack: false });
    }
  });

  const discover = isSlack && discoverSlack && endedTour === TourName.SLACK;

  if (!type) return null;
  const { learnUrl } = integrationsDataMap[type];
  if (!learnUrl) return null;
  return (
    <ActionButton
      size="L"
      tooltip="Learn more"
      forceFocus={discover}
      tooltipPlacement="top"
      onClick={e => {
        e.stopPropagation();
        if (isSlack && isOnboardingSlackEnabled) {
          openSlackLearnMoreTour();
        } else {
          window.open(learnUrl, '_blank');
        }
      }}
      style={{
        padding: 4,
        position: 'relative',
      }}
    >
      {discover &&
        (
          <Tooltip
            visible
            variant="shiny"
            content={`You can learn more about ${integrationNameTitles[type]} anytime here`}
            withPortal
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              pointerEvents: 'none',
              position: 'absolute',
              width: '100%',
            }}
          >
            <span ref={questionOutlineIconRef} />
          </Tooltip>
        )}
      <InfoIconOutline />
    </ActionButton>
  );
};
