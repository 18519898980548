import { ThemeType, Color as ColorApi } from '@cycle-app/graphql-codegen';
import merge from 'lodash/merge';
import { DefaultTheme } from 'styled-components';

import baseTheme, { BaseTheme } from '../theme/baseTheme';
import {
  COLOR_NUANCES,
  COLOR_THEMES,
  SIDEBAR_COLOR_THEMES,
} from '../theme/colorThemes/index';
import { COLOR_THEMES_TYPES } from '../types/theme.types';
import { ucFirst } from './ucFirst.util';

export const getTheme = (
  themeType: ThemeType,
  userColor: ColorApi,
): BaseTheme => {
  const colors = COLOR_THEMES[themeType];
  // We just need to keep it around so we can use it later in sidebarTheme
  colors.sidebarOverride = SIDEBAR_COLOR_THEMES[themeType];

  const nuances = COLOR_NUANCES[themeType];
  const userColors = nuances[userColor];

  return merge({}, baseTheme, {
    colors,
    nuances,
    userColors,
    themeType,
    isDark: themeType === ThemeType.Nightfall,
  });
};

// Takes the current theme and applies the sidebarOverride object
export const sidebarTheme = (parentTheme: DefaultTheme) => {
  // There should always be a parent theme but here's a failsafe
  if (!parentTheme) return {} as DefaultTheme;

  return {
    ...parentTheme,
    colors: merge(
      {},
      parentTheme.colors,
      parentTheme.colors.sidebarOverride,
    ),
  };
};

export function getAllThemes(color?: ColorApi) {
  return COLOR_THEMES_TYPES
    .map(themeType => {
      let name = ucFirst(themeType);
      if (color) {
        name += ` (Color ${color.toUpperCase()})`;
      }

      return {
        theme: getTheme(themeType, color || ColorApi.A),
        name,
        id: themeType,
      };
    });
}
