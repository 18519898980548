import { AddProductWithOrganizationDocument } from '@cycle-app/graphql-codegen';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { useMe } from 'src/hooks/api/useMe';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useGetCommandbar } from 'src/reactives/commandbar.reactive';

import { closeCommandBar } from '../modals/useCommandBarModal';
import { useNavigate } from '../useNavigate';

type UseEnterCallback = (inputValue: string) => [VoidFunction | null, boolean];

export const useEnterCallback: UseEnterCallback = (inputValue: string) => {
  const { getUrl } = useNavigate();
  const history = useHistory();
  const { section } = useGetCommandbar();
  const [createWorkspace, { loading }] = useSafeMutation(AddProductWithOrganizationDocument);
  const { refetch: refetchMe } = useMe();

  return useMemo(() => {
    if (section === 'create-workspace') {
      return [
        async () => {
          const { data } = await createWorkspace({
            variables: {
              name: inputValue,
            },
          });
          if (data?.addProductWithOrganization?.slug) {
            closeCommandBar();

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            refetchMe();

            history.push(getUrl(PageId.Main, {
              productSlug: data.addProductWithOrganization.slug,
            }));
          }
        },
        loading,
      ];
    }

    return [null, false];
  }, [section, loading, createWorkspace, inputValue, refetchMe, history, getUrl]);
};
