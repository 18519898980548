import { ChangelogUpdateDomainDocument } from '@cycle-app/graphql-codegen';

import { useSafeMutation } from '../../hooks';
import DialogModal from '../DialogModal/DialogModal';
import { Bold } from './ChangelogModalDomainRemove.styles';

export type ChangelogModalDomainRemoveProps = {
  hide: VoidFunction;
  domain: string;
  changelogId: string;
};

export const ChangelogModalDomainRemove = ({
  domain, hide, changelogId,
}: ChangelogModalDomainRemoveProps) => {
  const [updateMutation, updateState] = useSafeMutation(ChangelogUpdateDomainDocument);
  return (
    <DialogModal
      type="warning"
      title="Remove custom domain"
      hide={hide}
      info={(
        <>
          Are you sure you want to remove
          {' '}
          <Bold>{domain}</Bold>
          {' '}
          ?
        </>
      )}
      onConfirm={async () => {
        const { data } = await updateMutation({
          variables: {
            id: changelogId,
            domain: null,
          },
          optimisticResponse: {
            updateChangelog: {
              id: changelogId,
              domain: null,
            },
          },
        });
        if (data?.updateChangelog?.id) {
          hide();
        }
      }}
      loading={updateState.loading}
      confirmLabel="Remove"
    />
  );
};
