import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const BoldIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.73438 2C3.32016 2 2.98438 2.33579 2.98438 2.75V8V13.25C2.98438 13.6642 3.32016 14 3.73438 14H9.64062C10.5357 14 11.3942 13.6444 12.0271 13.0115C12.66 12.3785 13.0156 11.5201 13.0156 10.625C13.0156 9.72989 12.66 8.87145 12.0271 8.23851C11.8345 8.04595 11.6211 7.87906 11.3921 7.74004C12.0117 7.10923 12.3594 6.26004 12.3594 5.375C12.3594 4.47989 12.0038 3.62145 11.3709 2.98851C10.7379 2.35558 9.87948 2 8.98438 2H3.73438ZM8.98438 7.25C9.48166 7.25 9.95857 7.05246 10.3102 6.70083C10.6618 6.34919 10.8594 5.87228 10.8594 5.375C10.8594 4.87772 10.6618 4.40081 10.3102 4.04917C9.95857 3.69754 9.48166 3.5 8.98438 3.5H4.48438V7.25H8.98438ZM4.48438 8.75V12.5H9.64062C10.1379 12.5 10.6148 12.3025 10.9664 11.9508C11.3181 11.5992 11.5156 11.1223 11.5156 10.625C11.5156 10.1277 11.3181 9.65081 10.9664 9.29917C10.6148 8.94754 10.1379 8.75 9.64062 8.75H8.98438H4.48438Z" fill="#171717" />
  </Svg>
);
