import DialogModal from 'src/components/DialogModal/DialogModal';
import { useOAuthClient } from 'src/hooks/api/queries/api/useOAuthClients';
import { closeRemoveAppModal, useGetSettingsApi } from 'src/reactives/settingsApi.reactive';

import { useRemoveOAuthClient } from './useRemoveOAuthClient';

export const OAuthRemoveAppModal = () => {
  const { openedRemoveAppModal } = useGetSettingsApi();
  if (!openedRemoveAppModal) return null;
  return <OAuthRemoveAppModalVisible clientId={openedRemoveAppModal} />;
};

const OAuthRemoveAppModalVisible = ({ clientId }: { clientId: string }) => {
  const { client } = useOAuthClient(clientId);
  const removeClient = useRemoveOAuthClient(clientId);
  return (
    <DialogModal
      title="Remove OAuth application"
      info={client ? `Are you sure you want to remove ${client.name}?` : undefined}
      hide={closeRemoveAppModal}
      confirmLabel="Remove"
      onConfirm={removeClient}
    />
  );
};
