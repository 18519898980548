import { gql, useApolloClient } from '@apollo/client';
import { AddNewDocDocument, CustomerFragment, DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Button, Emoji } from '@cycle-app/ui';
import { Helper } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { nodeToArray } from '@cycle-app/utilities';
import { format } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { CustomPropertiesFormFields } from 'src/components/CustomPropertiesFormFields';
import { LinearField } from 'src/components/CustomPropertyFormField/LinearField';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useDocInsightCreate, useDocTypeInsight, useFeedbackDocType, useProductBase } from 'src/hooks';
import { useCustomerDocFromCache } from 'src/hooks/api/cache/cacheCustomerDoc';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { useMe } from 'src/hooks/api/useMe';
import { useUpdateDocAutomation } from 'src/hooks/doc/useUpdateDocAutomation';
import { useEditorTextContent } from 'src/hooks/useEditorTextContent';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { useGetDocTypeEmoji } from 'src/reactives/docTypes.reactive';
import { useGetInsightForm } from 'src/reactives/insightForm.reactive';
import { useCompletedStatusId } from 'src/reactives/productStatus.reactive';
import { Layer } from 'src/types/layers.types';
import { CustomPropertyFormData, GetCustomPropertyValueReturn } from 'src/types/property.types';
import { FormGroup, FormGroup2Columns } from 'src/utils/form.util.styles';
import { getCustomPropertyValues, getSubmittableCustomPropertyValues } from 'src/utils/properties.util';

import { doctypeHasAutomation } from '../../utils/doctype.automation.util';
import { DocLinearAutoCreate } from '../DocLinearAutoCreate';
import { FieldAssignee } from '../Form';
import { FeedbackContentAsQuote } from './FeedbackContentAsQuote';
import {
  Footer,
  Form,
  InputBox,
  ParentDoc,
  ParentTitle,
  StyledFieldCustomer,
  Label,
  FormRow,
  ParentIcon,
} from './InsightCreateForm.styles';
import { InsightCreateFormData } from './InsightCreateForm.types';
import { QuoteField } from './QuoteField';

export const getCustomFieldFormValues = (
  data: CustomPropertyFormData[],
) => data.reduce<Record<string, GetCustomPropertyValueReturn>>(
  (previousValue, currentValue) => ({
    ...previousValue,
    [currentValue.id]: currentValue.inheritValue,
  }),
  {},
);

type InsightCreateFormProps = {
  hide: VoidFunction;
  onInsightCreated?: VoidFunction;
  onInsightCreating?: VoidFunction;
  parentDoc?: DocBaseFragment | null;
  feedbackDoc?: DocBaseFragment;
  customCommonPropertiesData: CustomPropertyFormData[];
  customSpecificPropertiesData: CustomPropertyFormData[];
  defaultContent?: string;
  defaultCover?: string;
  defaultCustomerId?: string;
  defaultAssignee?: string;
  blockId?: string | null;
  hideParentDoc?: boolean;
  isCustomerReadonly?: boolean;
  isAssigneeReadonly?: boolean;
  hideTitleField?: boolean;
  createFeedback?: boolean;
  layer?: Layer;
  withoutParent?: boolean;
  linearId?: string;
  linearUrl?: string;
  linearDisabled?: boolean;
};

export const InsightCreateForm: FC<React.PropsWithChildren<InsightCreateFormProps>> = ({
  hide,
  onInsightCreated,
  onInsightCreating,
  parentDoc,
  feedbackDoc,
  customCommonPropertiesData,
  customSpecificPropertiesData,
  defaultContent,
  defaultCover,
  defaultAssignee,
  defaultCustomerId,
  blockId,
  hideParentDoc,
  isCustomerReadonly,
  isAssigneeReadonly,
  hideTitleField = false,
  createFeedback,
  layer,
  withoutParent,
  linearId,
  linearUrl,
  linearDisabled,
}) => {
  const { cache } = useApolloClient();
  const [createFeedbackDoc, { loading: isCreateFeedbackLoading }] = useSafeMutation(AddNewDocDocument);
  const { updateDocAutomation } = useUpdateDocAutomation();
  const emoji = useGetDocTypeEmoji(parentDoc?.doctype?.id);
  const { me } = useMe();
  const { insight } = useDocTypeInsight();
  const {
    create, isLoading,
  } = useDocInsightCreate();
  const { addCustomerDoc } = useCustomerDocFromCache();
  const { useFeedbackContentAsQuote } = useGetInsightForm();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState,
    watch,
    getValues,
  } = useForm<InsightCreateFormData>({
    defaultValues: {
      parentId: (parentDoc?.title || '') + (parentDoc?.id || '') || '',
      ...getCustomFieldFormValues(customCommonPropertiesData),
      ...getCustomFieldFormValues(customSpecificPropertiesData),
      content: useFeedbackContentAsQuote && !!feedbackDoc ? '' : (defaultContent || ''),
      cover: defaultCover || '',
      assignee: defaultAssignee || '',
      customerId: defaultCustomerId || '',
      title: '',
      withLinear: doctypeHasAutomation(parentDoc?.doctype.id),
    },
  });

  const {
    loading: isCustomersLoading, customers,
  } = useCustomers({
    defaultSearch: me.email,
    onCompleted: async (data) => {
      const defaultCustomer = data.node.customers.edges.find(edge => edge.node.email === me.email)?.node;
      if (defaultCustomer && !defaultCustomerId) {
        setValue('customerId', defaultCustomer.id);
      }
      setValue('title', getDefaultTitleValue(feedbackDoc?.customer?.displayName || defaultCustomer?.displayName));
    },
  });
  const feedbackDoctype = useFeedbackDocType();
  const product = useProductBase();
  const completedStatusId = useCompletedStatusId();
  const customerId = watch('customerId');
  const parentId = watch('parentId');
  const quoteContent = watch('content');

  const [linearState, setLinearState] = useState<{
    id: string | null;
    url: string | null;
  }>({
    id: linearId ?? parentDoc?.automationId ?? null,
    url: linearUrl ?? parentDoc?.automationUrl ?? null,
  });

  /**
   * Make sure to always set a customer if none
   * This is also needed in the super admin (not in workspace) case
   */
  useEffect(() => {
    if (!customerId) {
      const firstCustomer = customers[0];
      if (!firstCustomer) return;
      setValue('customerId', firstCustomer.id);
    }
  }, [customerId, customers, setValue]);

  const doctypeParentHasAutomation = doctypeHasAutomation(parentDoc?.doctype.id);
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);

  const showLinearAutoCreate =
    isLinearInstalled &&
    !hideParentDoc &&
    !!parentDoc &&
    doctypeParentHasAutomation &&
    !linearState.id; // prevent linear creation if the parent already has an automation

  const onSubmit = async (data: InsightCreateFormData) => {
    const customPropertyValues = getCustomPropertyValues(data, {
      // Make sure we keep only custom field key/values
      ignoredKeys: ['title', 'parentId', 'content'],
    });
    onInsightCreating?.();

    // In instance, we do this from an initiave, as an insight should always have a feedback.
    let intermediateFeedback: Pick<DocBaseFragment, 'id' | 'source'> | null = null;
    if (createFeedback && feedbackDoctype && product) {
      const feedbackDoctypeAttributeDefinitions = nodeToArray(feedbackDoctype.attributeDefinitions);
      const customerCache = cache.readFragment<Pick<CustomerFragment, 'displayName'>>({
        id: data.customerId,
        fragment: gql`fragment Customer on Customer { displayName }`,
      });
      const result = await createFeedbackDoc({
        variables: {
          statusId: completedStatusId,
          doctypeId: feedbackDoctype.id,
          title: [
            'Feedback',
            customerCache ? `from ${customerCache.displayName}` : '',
            'in Cycle',
            `- ${format(new Date(), 'M/d/yyyy')}`,
          ].join(' '),
          // the feedback content, should be the insight quote
          contentJSON: JSON.stringify({
            type: 'doc',
            content: [{
              type: 'paragraph',
              content: [{
                type: 'text',
                text: data.content,
              }],
            }],
          }),
          productId: product.id,
          customer: { id: data.customerId },
          assignee: data.assignee,
          attributes: [
            ...getSubmittableCustomPropertyValues(customPropertyValues, customSpecificPropertiesData),
          ].filter(attribute => (
            feedbackDoctypeAttributeDefinitions.find(definition => definition.id === attribute.attributeDefinitionId))),
        },
      });
      if (result.data?.addNewDoc?.id) {
        addCustomerDoc({ doc: result.data.addNewDoc });
        intermediateFeedback = result.data.addNewDoc;
      }
    }

    await create(
      {
        assignee: data.assignee,
        contentJSON: '',
        customerId: data.customerId,
        docLinkContent: data.content,
        docLinkSourceId: intermediateFeedback ? intermediateFeedback.id : feedbackDoc?.id,
        doctypeId: insight?.id || '',
        parentId: parentDoc?.id || '',
        properties: [
          ...getSubmittableCustomPropertyValues(customPropertyValues, customSpecificPropertiesData),
        ],
        title: data.title,
        blockId: blockId || (intermediateFeedback ? crypto.randomUUID() : ''),
        sourceId: intermediateFeedback?.source ? intermediateFeedback.source.id : feedbackDoc?.source?.id,
        cover: data.cover,
        withLinear: showLinearAutoCreate && data.withLinear,
      },
      { parentDoc },
    );

    onInsightCreated?.();

    if (isLinearInstalled && !showLinearAutoCreate && !hideParentDoc && parentDoc) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateDocAutomation(parentDoc.id, linearState.url, linearState.id);
    }
  };

  function getDefaultTitleValue(name?: string) {
    const customerName = name
      ? ` from ${name}`
      : '';
    const parentTitle = parentDoc?.title
      ? ` on ${parentDoc.title}`
      : '';

    return `Insight${customerName}${parentTitle}`;
  }

  const isSubmitDisabled = (!parentId && !withoutParent) || !quoteContent;

  /* Update the quote field based on the useFeedbackContentAsQuote option */
  const editorContent = useEditorTextContent(feedbackDoc?.id);
  const prevContent = useRef(defaultContent || '');
  useEffect(() => {
    if (useFeedbackContentAsQuote) {
      prevContent.current = getValues('content');
      setValue('content', (defaultContent || editorContent || feedbackDoc?.title) ?? '');
    } else {
      setValue('content', prevContent.current);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useFeedbackContentAsQuote, editorContent]);

  return (
    <Form
      data-popper-overflow
      onSubmit={handleSubmit(onSubmit)}
    >
      {!hideParentDoc && (
        <FormGroup>
          <InputBox>
            {!withoutParent && !parentDoc
              ? 'Loading'
              : (
                <ParentDoc>
                  <ParentIcon size={12} />
                  {withoutParent && 'None'}
                  {parentDoc && (
                    <>
                      <Emoji emoji={emoji} size={14} />
                      <ParentTitle>{parentDoc.title}</ParentTitle>
                    </>
                  )}
                </ParentDoc>
              )}
          </InputBox>
        </FormGroup>
      )}
      {!feedbackDoc && (
        <>
          {!isCustomerReadonly && (
            <FormRow>
              <Label>Customer</Label>
              <Controller
                control={control}
                name="customerId"
                rules={{
                  required: 'You must select a customer',
                }}
                render={({
                  field, fieldState,
                }) => (
                  <>
                    <StyledFieldCustomer
                      value={field.value}
                      onChange={field.onChange}
                      hasError={!!fieldState.error}
                      autoOpen
                    />
                    {fieldState.error && (
                      <Helper $hasError>
                        {fieldState.error.message}
                      </Helper>
                    )}
                  </>
                )}
              />
            </FormRow>
          )}
          {!isAssigneeReadonly && (
            <FormRow>
              <Label>Assignee</Label>
              <Controller
                control={control}
                name="assignee"
                render={({ field }) => (
                  <FieldAssignee
                    value={field.value}
                    onChange={field.onChange}
                    disabled={!customerId}
                  />
                )}
              />
            </FormRow>
          )}
        </>
      )}
      {!hideTitleField && (
        <QuoteField
          isLoading={isCustomersLoading}
          customerId={customerId}
          register={register}
          error={formState.errors.content?.message || ''}
          autoFocus={!!feedbackDoc || !!isCustomerReadonly}
          disabled={!customerId}
        />
      )}

      {!hideTitleField && !!feedbackDoc && <FeedbackContentAsQuote />}

      {!!customSpecificPropertiesData.length && (
        <FormGroup2Columns>
          <CustomPropertiesFormFields<InsightCreateFormData>
            data={customSpecificPropertiesData}
            control={control}
            autoFocus={false}
            layer={layer}
            disabled={!customerId}
          />
          {!doctypeParentHasAutomation && !hideParentDoc && parentDoc && (
            <LinearField
              docId={parentDoc.id}
              isDisabled={linearDisabled}
              id={linearState.id}
              url={linearState.url}
              onChange={setLinearState}
            />
          )}
        </FormGroup2Columns>
      )}

      {showLinearAutoCreate && (
        <FormRow>
          <Label>Linear</Label>
          <Controller
            control={control}
            name="withLinear"
            render={({ field }) => (
              <DocLinearAutoCreate
                checked={field.value}
                doctypeId={parentDoc.doctype.id}
                onChange={checked => field.onChange(checked)}
                style={{ display: 'inline-block' }}
                size="M"
                withPortal={
                  // prevents closing the form when closing the discover tooltip.
                  false
                }
              />
            )}
          />
        </FormRow>
      )}

      <Footer>
        <Button
          size="M"
          variant="secondary"
          onClick={hide}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          size="M"
          isLoading={isLoading || isCreateFeedbackLoading}
          disabled={isSubmitDisabled}
          autoFocus
        >
          Confirm
        </Button>
      </Footer>
    </Form>
  );
};
