import { PlusBoxRoundedIcon } from '@cycle-app/ui/icons';
import { useCallback } from 'react';

import { shortcuts } from 'src/constants/shortcuts.constants';
import { useFeedbackDocType } from 'src/hooks';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { getBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { getBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { setCreateDoc, getCreateDoc } from 'src/reactives/createDoc.reactive';
import { useSidebarWidthSelector } from 'src/reactives/sidebar.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';

import { CreateNewButton, CreateNewShortcut } from './SidebarActions.styles';

export const NewFeedbackButton = () => {
  const showShortcut = useSidebarWidthSelector(state => state.width > 195);

  const feedback = useFeedbackDocType();

  const collapsed = useSidebarCollapsed();

  const createDoc = useCallback(() => {
    if (!feedback?.id) return;
    setCreateDoc({
      modalVisible: true,
      doctypeId: feedback?.id,
    });
  }, [feedback?.id]);

  useHotkeyListener({
    callbacks: {
      [ShortcutBoard.CreateDoc]: () => {
        if (getBoardGroupState().hoverGroupId) return;
        createDoc();
      },
    },
    shortcuts: Object.values(ShortcutBoard),
    disableOnLayers: [
      Layer.DocPanel,
      Layer.Dropdown,
      Layer.Modal,
      Layer.ModalZ2,
      Layer.ModalZ3,
      Layer.DialogModal,
      Layer.MaskCommandbar,
    ],
    preventCallback: () => (
      getBoardNewDocPositionState().draftPosition !== null ||
      getCreateDoc().modalVisible
    ),
  });

  return (
    <CreateNewButton
      $collapsed={collapsed}
      onClick={createDoc}
      iconStart={<PlusBoxRoundedIcon />}
    >
      New feedback
      {showShortcut && <CreateNewShortcut keys={shortcuts[ShortcutBoard.CreateDoc]} />}
    </CreateNewButton>
  );
};
