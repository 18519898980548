/* eslint-disable no-nested-ternary */
import { ColorTheme } from '@cycle-app/utilities';
import { getSmoothStepPath, getBezierPath, EdgeProps } from 'react-flow-renderer';

import { useGetDoctypeGraph } from 'src/reactives/doctypeGraph.reactive';
import { FlowData } from 'src/utils/flow.util';

import { Path } from './FlowPath.styles';

const FlowPath = ({
  id,
  style,
  ...props
}: EdgeProps<FlowData>) => {
  const {
    elementHover,
    rootChildrenHover,
    rootParentIsHover,
    insightParentHover,
  } = useGetDoctypeGraph();

  if (props.data?.phantom) return null;

  const edgeType = props.data?.edgeType ?? 'smooth';
  const edgePath = edgeType === 'integrations'
    ? [-15, 0, 15].map(dy => getBezierPath({
      ...props,
      sourceX: props.sourceX + 5,
      sourceY: props.sourceY + dy,
    })).join('')
    : getSmoothStepPath({
      ...props,
      centerY: props.data?.edgeCenter
        ? props.sourceY + (props.targetY - props.sourceY) * props.data?.edgeCenter
        : undefined,
      borderRadius: 16,
    });

  const isVisible =
    rootChildrenHover === props.data?.rootId ||
    (rootParentIsHover && props.data?.root) ||
    !props.data?.asPlaceholder ||
    !props.data?.rootId;

  const isActive =
    elementHover === (props.source || props.target) ||
    (elementHover && elementHover === props.data?.rootId);

  const isInsightEdge = props.data?.edgeType === 'insight';
  const isLinkedToInsight = insightParentHover === props.data?.insightParentId;

  const markerType = props.data?.markerType ?? 'arrow';

  const markerEnd = markerType === 'default' || props.data?.asPlaceholder
    ? undefined
    : isInsightEdge
      ? 'url(#react-flow__arrow-insight)'
      : isActive
        ? 'url(#react-flow__arrow-active)'
        : 'url(#react-flow__arrow-default)';

  const opacity = !isInsightEdge
    ? 1
    : !insightParentHover
      ? 0.5
      : isLinkedToInsight
        ? 1
        : 0;

  return (
    <Path
      id={id}
      opacity={isVisible ? opacity : 0}
      isActive={isActive || false}
      asPlaceholder={props.data?.asPlaceholder}
      style={style}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
      color={edgeType === 'insight' ? ColorTheme.Blue500 : undefined}
    />
  );
};

export default FlowPath;
