import styled from 'styled-components';

import PortalModal from '../PortalModal/PortalModal';
import { CustomerProfile } from './CustomerProfile';

export const StyledPortalModal = styled(PortalModal)`
  width: 748px;
  padding: 24px 0;
  background: ${p => p.theme.colors.docPanel.bg}
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-left: 24px;
  padding-right: 24px;
`;

export const StyledCustomerProfile = styled(CustomerProfile)`
  margin-top: 16px;
`;
