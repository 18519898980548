import { nodeToArray } from '@cycle-app/utilities';
import { Elements, FlowElement, isNode, isEdge, Position } from 'react-flow-renderer';

import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { findInsight, isBuiltIn } from 'src/utils/docType.util';

import { defaultOptions } from './defaultOptions';
import { getChildren } from './getChildren';
import { parseChildren } from './parseChildren';

export const useGlobalHierarchyElements = (): Elements => {
  const { doctypes } = useProductDoctypesFull();

  const insightDocType = findInsight(doctypes);
  const insightParentIds = nodeToArray(insightDocType?.parents).map(p => p.id);

  const parsedDoctypesId: Array<string> = [];

  const elements = doctypes
    .filter(doctype => {
      if (isBuiltIn(doctype)) return false;

      const hasParent = nodeToArray(doctype.parents).length > 0;
      const hasChildren = nodeToArray(doctype.children).length > 0;

      return (!hasParent && hasChildren) || (!hasParent && !hasChildren);
    }).map((doctype) => {
      const topParentId = `${doctype.id}-0-none`;

      // @todo rework recursive
      const n1 = getChildren({
        parentDoctype: doctype,
        level: 1,
        parentElementId: topParentId,
        asLink: true,
        data: {
          markerType: 'default',
        },
      }).flat();
      const n2 = n1.filter(isNode).map(parseChildren(doctypes, 2)).flat().flat();
      const n3 = n2.filter(isNode).map(parseChildren(doctypes, 3)).flat().flat();
      const n4 = n3.filter(isNode).map(parseChildren(doctypes, 4)).flat().flat();

      return [
        {
          ...defaultOptions,
          id: topParentId,
          type: 'custom',
          data: {
            root: true,
            doctypeId: doctype.id,
            asLink: true,
            name: doctype.name,
          },
        },
        ...n1,
        ...n2,
        ...n3,
        ...n4,
      ];
    }).flat();

  const allDoctypesId = elements.filter(isNode).map(element => ('data' in element && 'doctypeId' in element.data ? element.data.doctypeId : ''));

  return elements.map(element => {
    if (isNode(element) && element.data) {
      parsedDoctypesId.push(element.data.doctypeId);
      const totalIteration = allDoctypesId.filter(id => id === element.data.doctypeId).length;
      const currentIteration = parsedDoctypesId.filter(id => id === element.data.doctypeId).length;

      // eslint-disable-next-line
        element.data = {
        ...element.data,
        iteration: totalIteration > 1 ? currentIteration : 0,
      };
    }

    if (isEdge(element)) {
      // eslint-disable-next-line
      element.data = {
        ...element.data,
        edgeCenter: 0.8,
      };
    }

    return element;
  }).flatMap(addInsightElements(insightDocType?.id, insightParentIds));
};

type AddInsightElementsFn = (
  insightId: string | undefined,
  insightParentIds: string[],
) => (element: FlowElement, index: number) => Elements | FlowElement;

const addInsightElements: AddInsightElementsFn = (insightId, insightParentIds) => (element, index) => {
  if (!isNode(element) || !insightId) return element;
  if (!insightParentIds.includes(element.data.doctypeId)) return element;
  const insightNodeId = `${insightId}-phantom${index}`;
  return [
    element,
    {
      ...defaultOptions,
      id: insightNodeId,
      type: 'custom',
      sourcePosition: Position.Right,
      data: {
        doctypeId: insightId,
        phantom: true,
        rootId: element.id,
      },
    },
  ];
};
