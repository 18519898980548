import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const GroupNotStartedIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} data-icon-status width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_344_6467)">
      <path d="M1 7C1 5.4087 1.63214 3.88258 2.75736 2.75736C3.88258 1.63214 5.4087 1 7 1C8.5913 1 10.1174 1.63214 11.2426 2.75736C12.3679 3.88258 13 5.4087 13 7C13 8.5913 12.3679 10.1174 11.2426 11.2426C10.1174 12.3679 8.5913 13 7 13C5.4087 13 3.88258 12.3679 2.75736 11.2426C1.63214 10.1174 1 8.5913 1 7ZM7 2.125C5.70707 2.125 4.46709 2.63861 3.55285 3.55285C2.63861 4.46709 2.125 5.70707 2.125 7C2.125 8.29293 2.63861 9.53291 3.55285 10.4471C4.46709 11.3614 5.70707 11.875 7 11.875C8.29293 11.875 9.53291 11.3614 10.4471 10.4471C11.3614 9.53291 11.875 8.29293 11.875 7C11.875 5.70707 11.3614 4.46709 10.4471 3.55285C9.53291 2.63861 8.29293 2.125 7 2.125Z" fill="currentcolor" />
    </g>
    <defs>
      <clipPath id="clip0_344_6467">
        <rect width="12" height="12" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </Svg>
);
