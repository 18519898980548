import { ContentSection, HeroSection, Typography } from '@cycle-app/graphql-codegen';
import { CycleLogo, typo, ShyScrollbar } from '@cycle-app/ui';
import styled from 'styled-components';

interface RootProps {
  $heroSection: HeroSection;
  $contentSection: ContentSection;
  $typography: Typography;
}

export const Root = styled(ShyScrollbar)<RootProps>`
  margin: 24px 47px 77px;
  flex-grow: 1;
  background-color: var(--backgroundColor);
  color: var(--bodyColor);
  border-radius: 12px;
  padding-top: 50px;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0px 1px 5px 1px #0000000D;

  ${p => p.$typography === Typography.Montserrat && `
    font-family: "Montserrat", sans-serif;
  `}
  ${p => p.$typography === Typography.SourceSans_3 && `
    font-family: "Source Sans 3", sans-serif;
  `}
  ${p => p.$typography === Typography.Roboto && `
    font-family: "Roboto", sans-serif;
  `}
  ${p => p.$typography === Typography.WorkSans && `
    font-family: "Work Sans", sans-serif;
  `}
  ${p => p.$typography === Typography.PlayfairDisplay && `
    font-family: "Playfair Display", sans-serif;
  `}
  ${p => p.$typography === Typography.Mulish && `
    font-family: "Mulish", sans-serif;
  `}
  ${p => p.$typography === Typography.Montserrat && `
    font-family: "Montserrat", sans-serif;
  `}
  ${p => p.$typography === Typography.Merriweather && `
    font-family: "Merriweather", sans-serif;
  `}
  ${p => p.$typography === Typography.Manrope && `
    font-family: "Manrope", sans-serif;
  `}
  ${p => p.$typography === Typography.Lora && `
    font-family: "Lora", sans-serif;
  `}
  ${p => p.$typography === Typography.LibreBaskerville && `
    font-family: "Libre Baskerville", sans-serif;
  `}
  ${p => p.$typography === Typography.JetbrainsMono && `
    font-family: "JetBrains Mono", sans-serif;
  `}
  ${p => p.$typography === Typography.Hind && `
    font-family: "Hind", sans-serif;
  `}
  ${p => p.$typography === Typography.DmSans && `
    font-family: "DM Sans", sans-serif;
  `}
  ${p => p.$typography === Typography.Asap && `
    font-family: "Asap", sans-serif;
  `}
  ${p => p.$typography === Typography.Sora && `
    font-family: "Sora", sans-serif;
  `}

  h1,
  h2,
  h3,
  h4 {
    color: var(--headersColor);
  }

  ${p => p.$heroSection === HeroSection.S && `
    h1 {
      font-size: 48px;
      line-height: 57.6px;
    }
  `}
  ${p => p.$heroSection === HeroSection.M && `
    h1 { font-size: 54px; }
  `}
  ${p => p.$heroSection === HeroSection.L && `
    h1 { font-size: 62px; }
  `}
  ${p => p.$heroSection === HeroSection.Xl && `
    h1 { font-size: 82px; }
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledCycleLogo = styled(CycleLogo)`
  > svg:last-child {
    color: ${p => p.theme.colors.text.primary};
  }
`;

export const Title = styled.h1`
  ${typo.headerLarge}
  font-size: 62px;
  font-weight: 700;
  text-align: center;
  margin-top: 48px;
  line-height: 82px;
  word-break: break-word;
`;

interface SubtitleProps {
  $heroSection: HeroSection;
}

export const Subtitle = styled.div<SubtitleProps>`
  ${typo.headerLarge}
  font-weight: 500;
  text-align: center;
  margin-top: 8px;

  ${p => p.$heroSection === HeroSection.S && `
    font-size: 18px;
  `}
  ${p => p.$heroSection === HeroSection.M && `
    font-size: 18px;
  `}
  ${p => p.$heroSection === HeroSection.L && `
    font-size: 20px;
  `}
  ${p => p.$heroSection === HeroSection.Xl && `
    font-size: 20px;
  `}
`;

export const SocialContainer = styled.div`
  margin-top: 16px;
  text-align: center;
`;

interface SocialLinkProps {
  $contentSection: ContentSection;
}

export const SocialLink = styled.a<SocialLinkProps>`
  color: var(--linkColor);

  ${p => p.$contentSection === ContentSection.Discreet && `
    font-size: 14px;
  `}
  ${p => p.$contentSection === ContentSection.Uniform && `
    font-size: 16px;
  `}
  ${p => p.$contentSection === ContentSection.Classic && `
    font-size: 16px;
  `}
  ${p => p.$contentSection === ContentSection.Sober && `
    font-size: 16px;
  `}
  ${p => p.$contentSection === ContentSection.ShowOff && `
    font-size: 18px;
  `}
  ${p => p.$contentSection === ContentSection.Unique && `
    font-size: 14px;
  `}
`;

export const ShipPill = styled.img`
  height: 2.35em;
  width: auto;
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  margin-right: 4px;
  filter:
    drop-shadow(55.722px 64.587px 24.062px rgba(0, 0, 0, 0.00))
    drop-shadow(35.46px 41.792px 21.529px rgba(0, 0, 0, 0.01))
    drop-shadow(20.263px 22.796px 18.996px rgba(0, 0, 0, 0.03))
    drop-shadow(8.865px 10.131px 13.931px rgba(0, 0, 0, 0.05))
    drop-shadow(2.533px 2.533px 7.599px rgba(0, 0, 0, 0.06));
`;
