import { ViewType } from '@cycle-app/graphql-codegen';
import { NewDocPosition } from '@cycle-app/ui';
import { FC } from 'react';

import { NewDoc, NewDocProps, NewDocPlaceholder } from 'src/components/NewDoc';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useLastCustomDocTypeChild } from 'src/hooks/useCustomDocTypeChildren';
import { setBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { getPathValue, toggleDocChildren, useDocChildrenExpanded, useHasNextPage } from 'src/reactives/docChildren.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getDocFromCache } from 'src/utils/cache.utils';
import { canHaveChildren } from 'src/utils/doc.util';

interface Props extends Pick<NewDocProps, 'className' | 'possibleDoctypesId' | 'parentId'> {
  groupId: string;
  statusId?: string;
  viewType?: ViewType;
  targetPosition: NewDocPosition;
  lastChildId?: string;
}

const BoardGroupNewDoc: FC<React.PropsWithChildren<Props>> = ({
  groupId,
  statusId,
  viewType = ViewType.Kanban,
  targetPosition,
  lastChildId,
  ...newDocProps
}) => {
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const lastDocTypeChild = useLastCustomDocTypeChild(lastChildId);

  const nextPath = [boardId, lastChildId, lastDocTypeChild?.id];
  const lastChildPath = [boardId, lastChildId];

  const isLastChildExpanded = useDocChildrenExpanded(lastChildPath);
  const nextChildHasNextPage = useHasNextPage(nextPath);

  const lastChild = lastChildId ? getDocFromCache(lastChildId) : null;
  const lastChildHasChildren = !!lastChild?.childrenCount;
  const lastChildDocType = useGetDocType(lastDocTypeChild?.id);

  const canGoDown =
    viewType === ViewType.List &&
    targetPosition === 'bottom' &&
    !nextChildHasNextPage &&
    !!lastChild &&
    canHaveChildren(lastChildDocType) &&
    (!lastChildHasChildren || isLastChildExpanded);

  const goDown = () => {
    if (!canGoDown) return;
    if (!isLastChildExpanded) toggleDocChildren(lastChildPath);
    setBoardNewDocPositionState({
      groupId: getPathValue(nextPath),
      draftPosition: 'bottom',
    });
  };

  return (
    <NewDoc
      groupId={groupId}
      statusId={statusId}
      from={targetPosition}
      viewType={viewType}
      goDown={goDown}
      path={groupId}
      placeholder={<NewDocPlaceholder child={lastDocTypeChild} canGoDown={canGoDown} />}
      {...newDocProps}
    />
  );
};

export default BoardGroupNewDoc;
