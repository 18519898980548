import { ReactNode, useState, forwardRef } from 'react';
import { Placement } from 'tippy.js';

import { CloseIcon } from '../../icons';
import { Container, Layers, Content, CloseButton } from './ArrowContent.styles';

export type ArrowContentProps = {
  placement: Placement;
  hide?: VoidFunction;
  icon?: ReactNode;
  title?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
};

/**
 * Tooltip content with an arrow
 * Forked from ShinyContent
 * TODO: refactor to use the less complex styles
 */
export const ArrowContent = forwardRef<HTMLDivElement, ArrowContentProps>(({
  placement, hide, icon, title, content, footer,
}, contentRef) => {
  const [rect, setRect] = useState<DOMRect>();

  const ref = (node: HTMLDivElement) => {
    setTimeout(() => {
      const r = node?.getBoundingClientRect();
      if (!r || r?.width === rect?.width) return;
      setRect(r);
    });
  };

  return (
    <Container ref={ref} placement={placement}>
      <Layers rect={rect} placement={placement} />
      <Content ref={contentRef}>
        {(title || icon) && (
          <header>
            {icon}
            {title}
          </header>
        )}

        {content && <div>{content}</div>}

        {footer && <footer>{footer}</footer>}

        {hide && (
          <CloseButton onClick={hide}>
            <CloseIcon size={12} />
          </CloseButton>
        )}
      </Content>
    </Container>
  );
});
