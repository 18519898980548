import { AddNewDoctypeDocument } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { AnimatePresence } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { useTheme } from 'styled-components';
import { isPresent } from 'ts-is-present';

import { useSafeMutation } from 'src/hooks';
import { useDocTypes } from 'src/hooks/api/queries/docTypes/useDocTypes';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';
import { isInsight } from 'src/utils/docType.util';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Footer, NextButton } from '../OnboardingLayout/OnboardingLayout.styles';
import chartCircularDark from './chart-circular-dark.svg';
import chartCircular from './chart-circular.svg';
import chartHorizontal1 from './chart-horizontal-1.svg';
import chartHorizontal2 from './chart-horizontal-2.svg';
import chartHorizontalDark from './chart-horizontal-dark.svg';
import chartVertical1 from './chart-vertical-1.svg';
import chartVertical2 from './chart-vertical-2.svg';
import chartVerticalDark from './chart-vertical-dark.svg';
import {
  Aside, Card, CardChart, CardTitle, Desc, DoctypesContainer, StyledAiIcon, StyledCheckboxInput,
} from './OnboardingStepDoctypes.styles';

const chartVerticalDarkElement = (
  <img
    src={chartVerticalDark}
    alt=""
    width={168}
    style={{
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
  />
);

const chartHorizontalDarkElement = (
  <img
    src={chartHorizontalDark}
    alt=""
    width={182}
  />
);

const newDoctypes = [{
  id: 'problems',
  name: 'Problem',
  plural: 'Problems',
  emoji: 'rotating_light',
  chart: (isDark: boolean) => (isDark ? chartHorizontalDarkElement : <img src={chartHorizontal2} width={182} alt="" />),
  title: 'Main highlighted problems',
}, {
  id: 'bugs',
  name: 'Bug',
  plural: 'Bugs',
  emoji: 'beetle',
  chart: (isDark: boolean) => (isDark ? chartHorizontalDarkElement : <img src={chartHorizontal2} width={182} alt="" />),
  title: 'Top bug reports',
}, {
  id: 'improvements',
  name: 'Improvement',
  plural: 'Improvements',
  emoji: 'muscle',
  chart: (isDark: boolean) => (isDark ? chartHorizontalDarkElement : <img src={chartHorizontal2} width={182} alt="" />),
  title: 'Major improvements',
}, {
  id: 'kudos',
  name: 'Kudo',
  plural: 'Kudos',
  emoji: 'blue_heart',
  title: 'Kudos shared',
  chart: (isDark: boolean) => (isDark ? chartVerticalDarkElement : (
    <img
      src={chartVertical2}
      width={164}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      alt=""
    />
  )),
}];

const CardItem = ({
  i, x, y, title, emoji, chart,
}: { i: number; title: string; y?: string; x?: string; emoji?: string; chart: ReactNode }) => {
  return (
    <Card
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        // @ts-ignore ignore var
        '--i': i,
        zIndex: i,
        ...x && { '--x': x },
        ...y && { '--y': y },
      }}
    >
      <CardTitle>
        {emoji && <Emoji inline emoji={emoji} />}
        {title}
      </CardTitle>
      <CardChart>
        {chart}
      </CardChart>
    </Card>
  );
};

interface Props {
  productId: string;
}

export const OnboardingStepDoctypes = ({ productId }: Props) => {
  const {
    docTypes, refetch,
  } = useDocTypes(productId);
  const [addNewDoctype, {
    loading: loadingAddNewDoctype, called,
  }] = useSafeMutation(AddNewDoctypeDocument);
  const [selected, setSelected] = useState(newDoctypes.map(d => d.id));
  const { isDark } = useTheme();

  const toggleValue = (value: string) => () => {
    setSelected(values => (
      values.includes(value)
        ? values.filter(v => v !== value)
        : [...values, value]));
  };

  const isLoading = loadingAddNewDoctype;
  const insightDoctype = docTypes.find(isInsight);

  const onDoctypeCreate = async () => {
    if (isLoading || called) return;

    const doctypesToAdd = selected
      // filter out doctypes that are already present
      .filter(id => !docTypes.find(d => d.id === id))
      .map(id => newDoctypes.find(d => d.id === id))
      .filter(isPresent);

    if (doctypesToAdd.length && insightDoctype) {
      await Promise.all(doctypesToAdd.map(doctype => addNewDoctype({
        variables: {
          name: doctype.name,
          emoji: doctype.emoji,
          productId,
        },
      })));
      await refetch();
    }
    setOnboarding({ screen: LightOnboardingScreen.Linear });
  };

  return (
    <OnboardingLayout
      title="What kinds of insights do you want to look for in your feedback?"
      description={(
        <Desc>
          <StyledAiIcon />
          This will determine what Cycle AI will look for in your feedback
        </Desc>
      )}
      aside={(
        <Aside>
          <div style={{
            transition: 'transform .2s',
            transform: `translate(0, ${80 - selected.length * 20}px)`,
          }}
          >
            <CardItem
              i={0}
              title="What’s the feedback status?"
              chart={<img src={isDark ? chartCircularDark : chartCircular} width={163} alt="" />}
            />
            <CardItem
              i={1}
              title="Which customers give the most feedback?"
              chart={isDark ? chartVerticalDarkElement : (
                <img
                  src={chartVertical1}
                  width={164}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  alt=""
                />
              )}
              {...selected.length === 1 && { y: 'calc(-100px * var(--i))' }}
            />
            <CardItem
              i={2}
              title="Top requested features"
              chart={isDark ? chartHorizontalDarkElement : <img src={chartHorizontal1} width={182} alt="" />}
              emoji="four_leaf_clover"
            />
            <AnimatePresence>
              {newDoctypes.filter(doctype => selected.includes(doctype.id)).map((doctype, i) => (
                <CardItem
                  key={doctype.id}
                  i={i + 3}
                  title={doctype.title}
                  emoji={doctype.emoji}
                  chart={doctype.chart(isDark)}
                  {...{
                    ...i === 2 && { x: '30px' },
                    ...i === 3 && { x: '-10px' },
                  }}
                />
              ))}
            </AnimatePresence>
          </div>
        </Aside>
      )}
      main={(
        <>
          <DoctypesContainer>
            <StyledCheckboxInput
              checked
              disabled
              id="features"
              label={(
                <>
                  <Emoji emoji="four_leaf_clover" />
                  Features
                </>
              )}
            />
            {newDoctypes.map(doctype => (
              <StyledCheckboxInput
                key={doctype.id}
                checked={selected.includes(doctype.id)}
                id={doctype.id}
                label={(
                  <>
                    <Emoji emoji={doctype.emoji} />
                    {doctype.plural}
                  </>
                )}
                onChange={toggleValue(doctype.id)}
              />
            ))}
          </DoctypesContainer>
          <Footer>
            <NextButton isLoading={isLoading} size="M" onClick={onDoctypeCreate}>Next</NextButton>
          </Footer>
        </>
      )}
    />
  );
};
