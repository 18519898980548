import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const ListContainer = styled.div<{ $isNewInbox: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 0 8px;
  height: 28px;
  border-radius: 6px;
  ${typo.caption500}
  color: ${p => p.theme.colors.text.primary};
  background: ${p => p.theme.colors.background.tertiary};

  ${p => p.$isNewInbox && `
    background: ${p.theme.colors.background.secondary};
  `};
`;

export const DocType = styled.div`
  display: inline-flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`;
