import { Emoji, Tooltip } from '@cycle-app/ui';

import { useGetDocTypesWithReleaseProperty } from 'src/reactives/docTypes.reactive';

import { DocTypesContainer, DocType } from './ReleaseDocTypes.styles';

export const ReleaseDocTypes = () => {
  const docTypes = useGetDocTypesWithReleaseProperty();
  const docTypesList = Object.values(docTypes);

  const formatedList = docTypesList.map((docType, index) => (
    <DocType key={docType.id}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {index === 0 ? '' : index === docTypesList.length - 1 ? ' or ' : ', '}
      <Emoji emoji={docType.emoji} size={12} inline />
      {docType.name}
    </DocType>
  ));

  return (
    <Tooltip
      withWrapper
      content={(
        <>
          {'Any '}
          {formatedList}
          {' can be linked to releases'}
        </>
      )}
    >
      <DocTypesContainer>
        {docTypesList.map((docType) => (
          <DocType key={docType.id}>
            <Emoji emoji={docType.emoji} size={14} />
            {docTypesList.length === 1 && docType.name}
          </DocType>
        ))}
      </DocTypesContainer>
    </Tooltip>
  );
};
