import { TextSecondarySpan } from '@cycle-app/ui';
import { useState } from 'react';

import { STRIPE_PORTAL_EXPIRATION_DELAY } from '../../constants/billing.constants';
import { useCurrentBilling, useProductAddOns } from '../../hooks';
import { useStripePortal } from '../../hooks/api/queries/useStripePortal';
import { setUpgradeModal } from '../../reactives/upgradeModal.reactive';
import { getExpectedStandardFee } from '../../utils/billing.util';
import { currencyFormat } from '../../utils/currency.utils';
import { Badge, Price, StyledButtonSubmit, StyledRadioInput, Title } from './UpgradeBoxForm.styles';

export const UpgradeBoxForm = ({ isYC }: { isYC: boolean }) => {
  const [value, setValue] = useState<'monthly' | 'yearly'>('yearly');
  const isAnnual = value === 'yearly';
  const currentBilling = useCurrentBilling();
  const stripeQuery = useStripePortal({
    isAnnual,
    pollInterval: STRIPE_PORTAL_EXPIRATION_DELAY - 30_000,
  });
  const addOns = useProductAddOns();
  const addOnItems = addOns.filter(a => a.isEnabled);
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        const url = stripeQuery.stripePortal;
        if (url) {
          setUpgradeModal({ isOpened: false });
          window.open(url, '_blank');
        }
      }}
    >
      <StyledRadioInput
        id="UpgradeModalForm-monthly"
        value="monthly"
        name="frequency"
        onChange={() => setValue('monthly')}
        checked={value === 'monthly'}
        label={(
          <div>
            <Title>
              Monthly
            </Title>
            <div>
              <Price>
                {currentBilling && currencyFormat(getExpectedStandardFee({
                  addOns: addOnItems,
                  billing: currentBilling,
                  isAnnual: false,
                  isYC,
                }), { trimSymbol: true })}
                $
              </Price>
              <TextSecondarySpan>per month</TextSecondarySpan>
            </div>
          </div>
        )}
      />
      <StyledRadioInput
        id="UpgradeModalForm-yearly"
        name="frequency"
        value="yearly"
        checked={value === 'yearly'}
        onChange={() => setValue('yearly')}
        label={(
          <div>
            <Title>
              Yearly
              <Badge>Best value</Badge>
            </Title>
            <div>
              <Price>
                {currentBilling && currencyFormat(getExpectedStandardFee({
                  addOns: addOnItems,
                  billing: currentBilling,
                  isAnnual: true,
                  isYC,
                }), { trimSymbol: true })}
                $
              </Price>
              <TextSecondarySpan>per month · billed annually</TextSecondarySpan>
            </div>
          </div>
        )}
      />
      <StyledButtonSubmit full size="M" type="submit">Continue</StyledButtonSubmit>
    </form>
  );
};
