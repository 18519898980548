import { useQuery } from '@apollo/client';
import { OAuthClientByIdDocument, OAuthClientsDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useProductBase } from 'src/hooks/api/useProduct';
import { extract } from 'src/types/graphql.types';

type Options = {
  installed?: boolean;
};

const useOAuthClientsQuery = (options?: Options) => {
  const product = useProductBase();
  return useQuery(OAuthClientsDocument, {
    skip: !product?.id,
    variables: {
      productId: product?.id as string,
      installed: options?.installed,
    },
  });
};

export const useOAuthClients = (options?: Options) => {
  const query = useOAuthClientsQuery(options);
  return {
    ...query,
    clients: nodeToArray(extract('Product', query.data?.node)?.oauthClients),
  };
};

export const useOAuthClient = (clientId?: string | null) => {
  const query = useOAuthClientsQuery();
  return {
    ...query,
    client: extract('Product', query.data?.node)?.oauthClients.edges.find(edge => edge.node.id === clientId)?.node,
  };
};

export const useOAuthClientById = (clientId?: string | null) => {
  const query = useQuery(OAuthClientByIdDocument, {
    skip: !clientId,
    variables: {
      clientId: clientId as string,
    },
  });
  return {
    ...query,
    client: query.data?.node,
  };
};
