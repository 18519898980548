import { typo } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

import { AddOnIcon } from '../AddOnIcon';

export const Container = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border: 1px solid ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey200)};
  border-radius: 12px;
`;

export const Title = styled.h5`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  span {
    user-select: text;
  }
`;

export const Description = styled.p`
   ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const StyledAddOnIcon = styled(AddOnIcon)`
  --radius: 8px;
  width: 40px;
  height: 40px;

  > svg, > img {
    width: 16px;
  }
`;

export const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;
