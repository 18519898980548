import { FC } from 'react';

import { Tooltip } from '../Tooltip/Tooltip';
import { Container, Bullet } from './StatusBullet.styles';

export type StatusBulletProps = {
  className?: string;
  tooltipLabel?: string;
  color: string;
};

export const StatusBullet: FC<React.PropsWithChildren<StatusBulletProps>> = ({
  className,
  tooltipLabel,
  color,
}) => {
  return (
    <Container
      className={className}
    >
      {tooltipLabel ? (
        <Tooltip
          content={tooltipLabel}
          placement="top"
          withPortal
        >
          <Bullet $color={color} />
        </Tooltip>
      ) : <Bullet $color={color} />}
    </Container>
  );
};
