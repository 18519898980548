import { IntegrationFullFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { CycleLogo, IntegrationCardV2, Skeleton, Tooltip, Input } from '@cycle-app/ui';
import { Label } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import {
  FigmaIcon, MiroIcon, PitchIcon, LoomIcon,
  GongIcon, ModjoIcon, GrainIcon,
  CheckIcon, TrashIcon, DuplicateIcon, WheelIcon,
  DescriptIcon, DovetailIcon, SlidersIcon, LogoutIcon,
} from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { useMeasure } from '@cycle-app/utilities/src/hooks/useMeasure';
import { useMemo, useState, MouseEvent } from 'react';
import ReactFlow, { ReactFlowProps, Position } from 'react-flow-renderer';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { AvatarList } from 'src/components/AvatarList';
import { CallRecordingIcons } from 'src/components/CallRecordingIcons';
import DialogModal from 'src/components/DialogModal/DialogModal';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { GithubProjectDropdown } from 'src/components/GithubProjectDropdown/GithubProjectDropdown';
import { IntegrationLinearAutomationModal } from 'src/components/IntegrationLinearAutomationModal';
import { LearnMoreButton } from 'src/components/Integrations/LearnMoreButton';
import { IntegrationSyncAction } from 'src/components/IntegrationSyncAction';
import { LinearImportModal } from 'src/components/LinearImportModal';
import { MeetingButtonInstall } from 'src/components/MeetingButtonInstall';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { ErrorMessage } from 'src/constants/errors.constants';
import { PREINSTALLED_SOURCE_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { useFeatureFlag, useInstallIntegration } from 'src/hooks';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { useSlackIntegration } from 'src/hooks/slack/useSlackIntegration';
import { useCallSettingsUpdate } from 'src/hooks/user/useCallSettingsUpdate';
import { PageId, useUrl } from 'src/hooks/useUrl';
import { openLinearImport } from 'src/reactives/linearImport.reactive';
import { openSlackSettingsWorkspace } from 'src/reactives/slackSettingsModal.reactive';
import { FrontEndIntegration, Integration } from 'src/types/integrations.types';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { addErrorToaster } from 'src/utils/errorToasters.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { CollabEdge } from './Edges/CollabEdge';
import { SourceEdge } from './Edges/SourceEdge';
import { IntegrationItem, IntegrationItemProps } from './IntegrationItem/IntegrationItem';
import { IntegrationsDropdown } from './IntegrationsDropdown';
import { IntegrationsSection, Offsets } from './IntegrationsSection';
import {
  Page,
  FlowContainer,
  Handle,
  IntegrationSection,
  SectionHeader,
  Subtitle,
  AddIntegrationButton,
  SectionBody,
  IntegrationButton,
  StyledActionButton,
  IconsContainer,
  IconSentence,
  MakersText,
} from './SettingsIntegrations.styles';
import { UninstallIntegrationModal } from './UninstallIntegrationModal';

const LOGO_SIZE = 90;
const DEFAULT_FLOW_HEIGHT = 500;

const isCycleApiOrWebhook = (integrationType: Integration) => [
  FrontEndIntegration.CYCLE_API,
  FrontEndIntegration.WEBHOOKS,
].includes(integrationType as FrontEndIntegration);

export const SettingsIntegrations = () => {
  const {
    isEnabled, isLoaded,
  } = useFeatureFlag('integrations-settings-page-2-0');
  if (!isLoaded) return null;
  return isEnabled ? <SettingsIntegrationsContent2 /> : <SettingsIntegrationsContent />;
};

const SettingsIntegrationsContent = () => {
  const {
    isLoading, isCalled, sourcesByStatus, collabByStatus,
  } = useProductIntegrations();
  const slackIntegration = useSlackIntegration();

  const loading = !isCalled || isLoading;

  const {
    ref: flowRef,
    rect: flowRect,
  } = useMeasure<HTMLDivElement>();
  const flowWidth = flowRect?.width ?? 0;

  const [sourceOffsets, setSourceOffsets] = useState<Offsets>();
  const [collabOffsets, setCollabOffsets] = useState<Offsets>();

  const flowHeight = Math.max(
    sourceOffsets?.height ?? 0,
    collabOffsets?.height ?? 0,
    DEFAULT_FLOW_HEIGHT,
  );

  const elements = useMemo<ReactFlowProps['elements']>(() => {
    if (!flowWidth) return [];

    const isEmpty = sourcesByStatus.installed.length === 0;

    const nodes = [
      {
        id: 'sources',
        data: {
          label: (
            <IntegrationsSection
              title="Sources"
              installed={sourcesByStatus.installed.filter(source => source.integrationType !== IntegrationType.Slack)}
              uninstalled={sourcesByStatus.uninstalled.filter(source => source.integrationType !== IntegrationType.Slack)}
              loading={loading}
              onRender={setSourceOffsets}
              isEmpty={isEmpty}
              defaultIntegration={(
                <IntegrationItem
                  integration={slackIntegration.integration}
                  integrationType={IntegrationType.Slack}
                />
              )}
            />),
        },
        position: {
          x: 5,
          y: 0,
        },
      },
      {
        id: 'logo',
        data: { label: <CycleLogo animation="loop" size={LOGO_SIZE} color="blue" /> },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        position: {
          x: flowWidth / 2 - LOGO_SIZE / 2,
          y: Math.max(sourceOffsets?.center ?? 0, collabOffsets?.center ?? 0) - LOGO_SIZE / 2,
        },
      },
      {
        id: 'collab',
        data: {
          label: (
            <IntegrationsSection
              title="Collaboration"
              preInstalled={{
                Figma: <FigmaIcon />,
                Miro: <MiroIcon />,
                Pitch: <PitchIcon />,
                Loom: <LoomIcon />,
              }}
              installed={collabByStatus.installed}
              uninstalled={collabByStatus.uninstalled}
              loading={loading}
              onRender={setCollabOffsets}
            />
          ),
        },
        targetPosition: Position.Left,
        position: {
          x: flowWidth - (collabOffsets?.width ?? 0) - 5,
          y: 0,
        },
      },
    ];

    const sourceNodes = sourceOffsets?.handles.map((y, i) => ({
      id: `source${i}`,
      data: {
        label: <Handle />,
      },
      sourcePosition: Position.Right,
      position: {
        x: sourceOffsets.width,
        y,
      },
    })) ?? [];

    const collabNodes = collabOffsets?.handles.map((y, i) => ({
      id: `collab${i}`,
      data: {
        label: <Handle />,
      },
      targetPosition: Position.Left,
      position: {
        x: flowWidth - collabOffsets.width,
        y,
      },
    })) ?? [];

    const sourceEdges = isEmpty ? [] : sourceOffsets?.handles.map((_, i) => ({
      id: `source${i}-logo`,
      source: `source${i}`,
      target: 'logo',
      type: 'source-edge',
    })) ?? [];

    const collabEdges = isEmpty ? [] : collabOffsets?.handles.map((_, i) => ({
      id: `collab${i}-logo`,
      source: 'logo',
      target: `collab${i}`,
      type: 'collab-edge',
    })) ?? [];

    return [...nodes, ...sourceNodes, ...collabNodes, ...sourceEdges, ...collabEdges].map(element => ({
      ...element,
      className: 'nodrag',
    }));
  }, [flowWidth, sourcesByStatus, loading, sourceOffsets, collabOffsets, collabByStatus]);

  return (
    <>
      <SettingsViewHeader title="Integrations" description="Collect, unify, and connect all product information across apps" />
      <FlowContainer
        ref={flowRef}
        style={{ height: flowHeight }}
      >
        <ReactFlow
          elements={elements}
          zoomOnScroll={false}
          zoomOnPinch={false}
          zoomOnDoubleClick={false}
          paneMoveable={false}
          edgeTypes={{
            // @ts-ignore temp fix @types/react 18 element vs node
            'source-edge': SourceEdge,
            // @ts-ignore fix @types/react 18 element vs node
            'collab-edge': CollabEdge,
          }}
        />
      </FlowContainer>
    </>
  );
};

const SettingsIntegrationsContent2 = () => {
  const { isLoading } = useProductIntegrations();

  return (
    <Page>
      <SettingsViewHeader title="Integrations" />
      {isLoading
        ? (
          <>
            <Skeleton height={24} />
            <div style={{
              display: 'flex',
              gap: '32px',
            }}
            >
              <Skeleton height={180} width={360} />
              <Skeleton height={180} width={360} />
              <Skeleton height={180} width={360} />
            </div>
            <Skeleton height={24} />
            <div style={{
              display: 'flex',
              gap: '32px',
            }}
            >
              <Skeleton height={180} width={360} />
              <Skeleton height={180} width={360} />
              <Skeleton height={180} width={360} />
            </div>
          </>
        )
        : (
          <>
            <IntegrationsGroup kind="source" />
            <IntegrationsGroup kind="collaboration" />
            <IconsContainer>
              <Tooltip content="Figma" placement="top">
                <FigmaIcon />
              </Tooltip>
              <Tooltip content="Miro" placement="top">
                <MiroIcon />
              </Tooltip>
              <Tooltip content="Loom" placement="top">
                <LoomIcon />
              </Tooltip>
              <Tooltip content="Grain" placement="top">
                <GrainIcon />
              </Tooltip>
              <Tooltip content="Modjo" placement="top">
                <ModjoIcon />
              </Tooltip>
              <Tooltip content="Gong" placement="top">
                <GongIcon />
              </Tooltip>
              <Tooltip content="Descript" placement="top">
                <DescriptIcon />
              </Tooltip>
              <Tooltip content="Dovetail" placement="top">
                <DovetailIcon />
              </Tooltip>
              <IconSentence>Embed links from your favorite tools</IconSentence>
            </IconsContainer>
          </>
        )}
    </Page>
  );
};

type IntegrationsGroupProps = {
  kind: 'source' | 'collaboration';
};

const IntegrationsGroup = ({ kind }: IntegrationsGroupProps) => {
  const {
    sourcesByStatus, collabByStatus, getIntegration,
  } = useProductIntegrations();
  const linearIntegration = getIntegration(IntegrationType.Linear);
  const integrations = kind === 'source' ? sourcesByStatus : collabByStatus;
  const subtitle = kind === 'source' ? 'Sources' : 'Collaborations';
  return (
    <IntegrationSection>
      <SectionHeader>
        <Subtitle>{subtitle}</Subtitle>
        <IntegrationsDropdown integrations={integrations.uninstalled} skipLinear>
          <AddIntegrationButton>
            Add
            {' '}
            {kind}
          </AddIntegrationButton>
        </IntegrationsDropdown>
      </SectionHeader>
      <SectionBody>
        {kind === 'collaboration' && linearIntegration && !linearIntegration.provider?.id && (
          <IntegrationCardV2
            logo={integrationsDataMap[IntegrationType.Linear].icon}
            title={integrationsDataMap[IntegrationType.Linear].label}
            description={integrationsDataMap[IntegrationType.Linear].description || ''}
            cta={<IntegrationCta linearUrl={linearIntegration.url} />}
          />
        )}
        {[...integrations.installed]
          // Make sure Linear will always be at the top of the list
          .sort(i => (i.integrationType === IntegrationType.Linear ? -1 : 1))
          .map(integration => (
            <IntegrationCardV2
              key={integration.integrationType}
              logo={integration.integrationType === IntegrationType.Meeting
                ? <CallRecordingIcons />
                : integrationsDataMap[integration.integrationType].icon}
              title={integrationsDataMap[integration.integrationType].label}
              description={integrationsDataMap[integration.integrationType].description || ''}
              cta={<IntegrationCta integration={integration} />}
              actions={<IntegrationActions integration={integration} />}
            />
          ))}
      </SectionBody>
    </IntegrationSection>
  );
};

type IntegrationCtaProps = {
  integration?: IntegrationItemProps;
  linearUrl?: string;
};

const IntegrationCta = ({
  integration, linearUrl,
}: IntegrationCtaProps) => {
  const getUrl = useUrl();

  if (linearUrl && !integration) {
    return <IntegrationInstallLinearCta url={linearUrl} />;
  }

  if (!integration) return null;
  if (integration.integrationType === IntegrationType.Meeting) return <IntegrationCallSettingButton />;

  if (PREINSTALLED_SOURCE_INTEGRATIONS.includes(integration.integrationType)) {
    return (
      <IntegrationButton
        as={isCycleApiOrWebhook(integration.integrationType) ? Link : 'a'}
        target={!isCycleApiOrWebhook(integration.integrationType) ? '_blank' : undefined}
        {...isCycleApiOrWebhook(integration.integrationType)
          ? { to: getUrl(integrationsDataMap[integration.integrationType].learnUrl as PageId) }
          : { href: integrationsDataMap[integration.integrationType].learnUrl }}
        onClick={!isCycleApiOrWebhook(integration.integrationType)
          ? (e: MouseEvent<HTMLAnchorElement>) => e.currentTarget?.blur?.()
          : undefined}
      >
        Learn more
      </IntegrationButton>
    );
  }

  return (
    <IntegrationInstallCta
      id={integration.integration?.id || ''}
      type={integration.integrationType}
    />
  );
};

type IntegrationInstallCtaProps = {
  id: string;
  type: Integration;
};

const IntegrationInstallCta = ({
  id, type,
}: IntegrationInstallCtaProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const icon = isHovering ? <TrashIcon /> : <CheckIcon />;
  const label = isHovering ? 'Uninstall' : 'Installed';

  return (
    <>
      <IntegrationButton
        $isHoverDanger={isHovering}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onFocus={() => setIsHovering(true)}
        onBlur={() => setIsHovering(false)}
        onClick={(e) => {
          // This helps to not keep the active state after click
          e.currentTarget.blur();
          setIsModalVisible(true);
        }}
      >
        {icon}
        {label}
      </IntegrationButton>
      <UninstallIntegrationModal type={type} id={id} isVisible={isModalVisible} hide={() => setIsModalVisible(false)} />
    </>
  );
};

const IntegrationInstallLinearCta = ({ url }: { url: string }) => {
  const install = useInstallIntegration();
  return (
    <IntegrationButton
      onClick={async () => {
        await install(IntegrationType.Linear, {
          url,
          type: IntegrationType.Linear,
        });
      }}
    >
      Install
    </IntegrationButton>
  );
};

const SEE_PROJECT_VALUE = 'see-projects';

const IntegrationActions = ({ integration }: { integration: IntegrationItemProps }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  switch (integration.integrationType) {
    case IntegrationType.Slack:
      return (
        <>
          {!!integration.integration?.provider && <IntegrationSyncAction integrationType={integration.integrationType} />}
          <LearnMoreButton type={integration.integrationType} />
          {!!integration.integration?.provider && (
            <StyledActionButton
              onClick={openSlackSettingsWorkspace}
              size="L"
              tooltip="Configure"
              tooltipPlacement="top"
            >
              <WheelIcon size={16} />
            </StyledActionButton>
          )}
        </>
      );
    case IntegrationType.Intercom:
    case IntegrationType.Hubspot:
      return (
        <>
          {!!integration.integration?.provider && <IntegrationSyncAction integrationType={integration.integrationType} />}
        </>
      );
    case IntegrationType.Mail:
      return (
        <StyledActionButton
          onClick={() => {
            const provider = integration.integration?.provider;
            if (provider?.__typename !== 'Mail') return;
            copyToClipboard({
              text: provider.mail,
              notification: 'Email copied to clipboard',
            });
          }}
          size="L"
          tooltip="Copy email"
          tooltipPlacement="top"
        >
          <DuplicateIcon />
        </StyledActionButton>
      );
    case IntegrationType.Github:
      return (
        <GithubProjectDropdown>
          {(toggle) => (
            <DotsMenuLayer
              options={[{
                label: 'See projects',
                value: SEE_PROJECT_VALUE,
              }]}
              onChange={option => option.value === SEE_PROJECT_VALUE && toggle()}
            />
          )}
        </GithubProjectDropdown>
      );
    case IntegrationType.Meeting:
      if (!integration.integration?.provider) return null;
      return (
        <>
          <StyledActionButton
            onClick={() => setIsModalVisible(true)}
            size="L"
          >
            <WheelIcon />
          </StyledActionButton>
          <IntegrationMeetingSettingsModal
            integration={integration.integration}
            isVisible={isModalVisible}
            hide={() => setIsModalVisible(false)}
          />
        </>
      );
    case IntegrationType.Linear:
      if (!integration.integration?.provider) return null;
      return (
        <>
          <StyledActionButton
            onClick={() => setIsModalVisible(true)}
            size="L"
            tooltip="Automations"
            tooltipPlacement="top"
          >
            <SlidersIcon style={{ transform: 'rotate(90deg) scaleX(-1)' }} />
          </StyledActionButton>

          <StyledActionButton
            onClick={openLinearImport}
            tooltip="Import"
            tooltipPlacement="top"
          >
            <LogoutIcon size={14} direction="right" />
          </StyledActionButton>

          <LinearImportModal />

          <IntegrationLinearAutomationModal
            integration={integration.integration}
            isVisible={isModalVisible}
            hide={() => setIsModalVisible(false)}
          />
        </>
      );
    case IntegrationType.Gong:
      return (
        <StyledActionButton
          onClick={() => {
            const provider = integration.integration?.provider;
            if (provider?.__typename !== 'Gong') return;
            copyToClipboard({
              text: provider.webhookUrl,
              notification: 'Webhook url copied to clipboard',
            });
          }}
          size="L"
          tooltip="Copy webhook url"
          tooltipPlacement="top"
        >
          <DuplicateIcon />
        </StyledActionButton>
      );
    default:
      return null;
  }
};

export const IntegrationCallSettingButton = () => {
  const {
    isInstalled, integration,
  } = useMeetingsIntegration();
  const [isUninstallModalVisible, setIsUninstallModalVisible] = useState(false);
  return (
    <>
      <MeetingButtonInstall
        button={buttonProps => (
          <IntegrationButton
            onClick={(e) => {
              if (isInstalled) {
                setIsUninstallModalVisible(true);
              } else {
                buttonProps?.onClick(e);
              }
            }}
          >
            {isInstalled ? 'Disable' : 'Enable'}
          </IntegrationButton>
        )}
      />
      {integration && isInstalled && (
        <UninstallIntegrationModal
          id={integration.id}
          type={IntegrationType.Meeting}
          isVisible={isUninstallModalVisible}
          hide={() => setIsUninstallModalVisible(false)}
        />
      )}
    </>
  );
};

type IntegrationMeetingSettingsModalProps = {
  isVisible: boolean;
  hide: VoidFunction;
  integration: IntegrationFullFragment;
};

type FormData = {
  botName: string;
};

export const IntegrationMeetingSettingsModal = ({
  isVisible, hide, integration,
}: IntegrationMeetingSettingsModalProps) => {
  const { updateRecordingBotName } = useCallSettingsUpdate();
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      botName: integration.provider?.__typename === 'Meeting' ? integration.provider.botName : '',
    },
  });

  if (!isVisible) return null;

  const onSubmit = handleSubmit(async (data: FormData) => {
    if (
      data.botName &&
      integration.provider?.__typename === 'Meeting' &&
      data.botName !== integration.provider?.botName
    ) {
      try {
        await updateRecordingBotName(data.botName);
        addToaster({
          icon: <CheckIcon />,
          message: 'Bot name successfully updated',
        });
      } catch {
        addErrorToaster({ message: ErrorMessage.CALL_RECORDING_UPDATE_FAIL });
      } finally {
        hide();
      }
    }
  });
  const makersWhoHasInstalledMeetingIntegration = nodeToArray(integration.installations);

  return (
    <DialogModal
      type="default"
      variantSubmitButton="primary"
      variantCancelButton="secondary"
      title="Call recording settings"
      info={(
        <>
          <form>
            <Input
              autoFocus
              type="text"
              label="Recorder name"
              placeholder="Bot's name"
              helper="Choose a custom name for your recorder in Zoom, Meet & Teams"
              {...register('botName', { required: true })}
            />
          </form>
          <Label>All makers can record</Label>
          <AvatarList
            avatars={makersWhoHasInstalledMeetingIntegration}
            isLoading={false}
            skeletonCount={2}
            after={(
              <MakersText>
                {makersWhoHasInstalledMeetingIntegration.length}
                {' '}
                {makersWhoHasInstalledMeetingIntegration.length > 1 ? 'makers' : 'maker'}
              </MakersText>
            )}
          />
        </>
      )}
      hide={hide}
      onConfirm={onSubmit}
      confirmLabel="Save"
      isConfirmDisabled={!isDirty}
      autoHide={false}
    />
  );
};
