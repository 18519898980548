import { InfoIconOutline } from '@cycle-app/ui/icons';
import { useEffect } from 'react';

import { CustomersList } from 'src/components/CustomersList/CustomersList';
import { InboxSources } from 'src/components/InboxSources';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { HELP_URL_CUSTOMERS } from 'src/constants/help.constants';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useDocsPreview } from 'src/hooks/doc/useDocsPreview';
import { useGetDocIdPreview, setDocIdPreview } from 'src/reactives/docPreview.reactive';
import { Layer } from 'src/types/layers.types';

import { TitleContainer, Action, Title } from './Customers.styles';

export const CustomersView = () => {
  const { enabled: isDocPreviewEnabled } = useGetDocIdPreview();
  useDocsPreview({
    enabled: isDocPreviewEnabled,
    disableOnLayers: [Layer.Toaster],
  });
  useEffect(() => {
    setDocIdPreview({ enabled: true });
  }, []);

  return (
    <>
      <SettingsViewHeader
        title={(
          <TitleContainer>
            <Title>Customers</Title>
            <Action
              tooltip="Learn more"
              tooltipPlacement="bottom"
              onClick={() => window.open(HELP_URL_CUSTOMERS, '_blank')}
            >
              <InfoIconOutline size={14} />
            </Action>
          </TitleContainer>
        )}
        actionsSlot={<InboxSources onlyCustomers />}
      />
      <BoardConfigContextProvider>
        <CustomersList />
      </BoardConfigContextProvider>
    </>
  );
};
