import { Role } from '@cycle-app/graphql-codegen/generated';

import { GAMIFICATION_STEPS } from 'src/constants/gamification.constant';
import { useRole } from 'src/hooks/useRoles';
import { GamificationStep, GamificationStepDetail } from 'src/types/gamification.types';
import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

const onlyCollaboratorSteps = ([step]: [string, GamificationStepDetail]) => ![
  GamificationStep.CreateView,
  GamificationStep.SyncCustomers,
  GamificationStep.BecomeExpert,
].includes(step as GamificationStep);

type GamificationState = {
  isActive: boolean;
  stepDone: GamificationStep[];
};

export const {
  getValue: getGamification,
  setValue: setGamification,
  hookValue: useGamification,
} = make<GamificationState>({
  defaultState: {
    isActive: false,
    stepDone: [],
  },
  localKey: LocalKey.Gamification,
});

export type useGamificationStepsReturn = ({
  step: GamificationStep;
  isDone: boolean;
} & GamificationStepDetail)[];

export const useGamificationSteps = (): useGamificationStepsReturn => {
  const { stepDone } = useGamification();
  const { role } = useRole();

  const stepsByRole = role === Role.Collaborator
    ? Object.entries(GAMIFICATION_STEPS).filter(onlyCollaboratorSteps)
    : Object.entries(GAMIFICATION_STEPS);

  return stepsByRole
    .sort((a, b) => a[1].order - b[1].order)
    .map(([step, details]) => ({
      ...details,
      step: step as GamificationStep,
      isDone: stepDone.includes(step as GamificationStep),
    }));
};

export const setGamificationStepDone = (step: GamificationStep) => {
  const {
    isActive, stepDone,
  } = getGamification();

  if (!isActive || stepDone.includes(step)) return;

  setGamification({
    stepDone: [
      ...stepDone,
      step,
    ],
  });
};
