import { Tooltip, Emoji } from '@cycle-app/ui';

import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import { useOptimizedBooleanState } from 'src/hooks';
import { setLimitationsModal } from 'src/reactives';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getPermission } from 'src/reactives/permission.reactive';

import { TooltipTitle, TooltipDescription, TooltipButton, InfoIcon } from './DoctypeInfoIcon.styles';

export const DoctypeInfoIcon = ({ doctypeId }: { doctypeId?: string }) => {
  const doctype = useGetDocType(doctypeId);

  const [isModalVisible, {
    setFalseCallback: closeModal,
    setTrueCallback: openModal,
  }] = useOptimizedBooleanState(false);

  if (!doctype) return null;

  const onClick = () => {
    if (getPermission().isPermissionInit && !getPermission().canReadSettings) {
      setLimitationsModal({ action: 'SETTINGS_READ' });
      return;
    }
    openModal();
  };

  return (
    <>
      <Tooltip
        placement="bottom"
        withWrapper={false}
        interactive
        title={(
          <TooltipTitle>
            <Emoji emoji={doctype.emoji} />
            {doctype.name}
          </TooltipTitle>
      )}
        content={(
          <div>
            <TooltipDescription>
              {doctype.description}
            </TooltipDescription>

            <TooltipButton
              onClick={onClick}
            >
              Click to edit
            </TooltipButton>
          </div>
      )}
      >
        <InfoIcon onClick={onClick} />
      </Tooltip>

      {isModalVisible && (
        <DoctypesEditCommonModal
          doctype={doctype}
          onHide={closeModal}
        />
      )}
    </>
  );
};
