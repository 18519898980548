import { useApolloClient, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { FetchLinearIssueByIdDocument, FetchLinearIssueByIdQuery } from '@cycle-app/graphql-codegen';

export const useLinearIssue = ({
  issueId,
  integrationId,
  skip = false,
  onError,
}: {
  issueId: string | undefined | null;
  integrationId: string | undefined | null;
  skip?: boolean;
  onError?: VoidFunction;
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const {
    data,
    loading: isLoading,
    refetch,
    error,
  } = useQuery(FetchLinearIssueByIdDocument, {
    fetchPolicy: 'cache-and-network',
    skip: !issueId || !integrationId || skip,
    variables: {
      integrationId: integrationId as string,
      issueId: issueId as string,
    },
    onError,
  });

  return {
    isLoading,
    issue: getIssue(data),
    refetch,
    error,
  };
};

const getIssue = (data?: FetchLinearIssueByIdQuery) => {
  if (data?.node?.__typename !== 'Integration') return null;
  if (data.node.provider?.__typename !== 'Linear') return null;
  return data.node.provider.issues[0] ?? null;
};

export const usePrefetchLinearIssue = () => {
  const client = useApolloClient();
  return (issueId: string, integrationId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.query({
      query: FetchLinearIssueByIdDocument,
      variables: {
        integrationId,
        issueId,
      },
    });
  };
};
