import { CreateAccessTokenDocument, CreateAccessTokenMutationVariables } from '@cycle-app/graphql-codegen';

import { useMaybeMeV2 } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useCreateAccessToken = () => {
  const { me } = useMaybeMeV2();
  const [createClient] = useSafeMutation(CreateAccessTokenDocument);
  return (variables: Omit<CreateAccessTokenMutationVariables, 'productId'>) => {
    return createClient({
      variables,
      optimisticResponse: {
        createAccessToken: {
          __typename: 'AccessToken',
          id: 'temp-id',
          label: variables.label,
          token: '',
        },
      },
      update: (cache, { data }) => {
        const newAccessTokenId = data?.createAccessToken?.id;
        if (!me || !newAccessTokenId) return;
        cache.modify({
          id: cache.identify(me),
          fields: {
            accessTokens: (refs, { toReference }) => ({
              ...refs,
              edges: [
                { node: toReference(newAccessTokenId) },
                ...refs.edges,
              ],
            }),
          },
        });
      },
    });
  };
};
