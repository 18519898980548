import { useEffect } from 'react';

import { useIsMobile } from '../reactives/responsive.reactive';

export const useDetectKeyboardHeight = () => {
  const isMobile = useIsMobile();
  useEffect(() => {
    const listener = () => {
      document.body.style.setProperty('--keyboard-height', `${window.innerHeight - window.visualViewport.height}px`);
    };
    if (isMobile && typeof visualViewport !== 'undefined') {
      document.body.style.setProperty('--keyboard-height', '0px');
      window.visualViewport.addEventListener('resize', listener);
    }
    return () => {
      if (typeof visualViewport !== 'undefined') {
        window.visualViewport.removeEventListener('resize', listener);
        document.body.style.removeProperty('--keyboard-height');
      }
    };
  }, [isMobile]);
};
