import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const InfoIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM5.14282 3.42855C5.14282 2.95517 5.52658 2.57141 5.99997 2.57141C6.47335 2.57141 6.85711 2.95517 6.85711 3.42855C6.85711 3.90194 6.47335 4.2857 5.99997 4.2857C5.52658 4.2857 5.14282 3.90194 5.14282 3.42855ZM5.14282 5.99996C5.14282 5.52658 5.52658 5.14282 5.99997 5.14282C6.47335 5.14282 6.85711 5.52658 6.85711 5.99997V8.57139C6.85711 9.04478 6.47335 9.42854 5.99997 9.42854C5.52658 9.42854 5.14282 9.04478 5.14282 8.57139V5.99996Z" />
  </Svg>
);
