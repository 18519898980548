import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Spinner, Shortcut, Toaster } from '@cycle-app/ui';
import { DownIcon, UpIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { getDocSlug } from '@cycle-app/utilities';
import {
  FC, useCallback, useRef, useState, useReducer, useEffect, ReactNode,
} from 'react';

import { DocProcessButton } from 'src/components/DocProcessButton';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage } from 'src/components/ErrorPage';
import { Size } from 'src/components/ErrorPage/ErrorPage.types';
import { PageId, routing } from 'src/constants/routing.constant';
import { shortcuts, ShortcutDocPanel } from 'src/constants/shortcuts.constants';
import { useLocation, useRouteMatch } from 'src/hooks';
import { useBoard } from 'src/hooks/api/useBoard';
import { useDocShortcutListener } from 'src/hooks/shortcuts/useDocPanelShortcutListener';
import { useInitLayer } from 'src/hooks/state/useInitLayer';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useIsDocInBoard } from 'src/hooks/useIsDocInBoard';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePrevNextDoc } from 'src/hooks/usePrevNextDoc';
import { getEditorAi } from 'src/reactives';
import { getZoom } from 'src/reactives/zoom.reactve';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { Layer } from 'src/types/layers.types';
import { isFeedback } from 'src/utils/docType.util';
import { getOpenedLayersAbove } from 'src/utils/layers.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import { DocPanelContent } from '../DocPanelContent/DocPanelContent';
import DocPanelHeader from '../DocPanelHeader/DocPanelHeader';
import DocPanelRightPanel from '../DocPanelRightPanel/DocPanelRightPanel';
import {
  Container,
  MainSection,
  Overlay,
  ToasterContainer,
  StyledEmoji,
  PrevNextActions,
  StyledActionButton,
} from './DocPanelBoardPage.styles';

interface Props extends DocPanelBaseProps {
  widthSidebar: number;
  isDocLoading: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

const DocPanelBoardPage: FC<React.PropsWithChildren<Props>> = ({
  doc,
  widthSidebar,
  getDropzonePropsCover,
  isDocLoading,
  isDraggingCover,
  isUploadingCover,
  onOpenCoverInputFile,
  onTitleUpdated,
  disabled = false,
  children,
}) => {
  const location = useLocation();
  useInitLayer(Layer.DocPanel);
  useDocShortcutListener(doc);
  const [expanded, expand] = useReducer(() => true, false);
  const {
    navigate, navigateToDocPanelParent,
  } = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);

  const [visible, hide] = useReducer(() => false, true);

  const goBack = useCallback(() => {
    hide();
    setTimeout(navigateToDocPanelParent);
  }, [navigateToDocPanelParent]);

  const onExpand = useCallback(() => {
    expand();
    setTimeout(() => {
      navigate(PageId.DocFullPage, { docSlug: doc ? getDocSlug(doc) : undefined });
    }, 100);
  }, [navigate, doc]);

  const {
    navigateToPrevDoc, navigateToNextDoc, isFirstDoc, isLastDoc, loadingNextDoc, shouldLoadMore, loadMore,
  } = usePrevNextDoc(doc);

  useEffect(() => {
    if (shouldLoadMore) loadMore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  const getIsDocInBoard = useIsDocInBoard();
  const isDocInBoard = location.state?.isDocRelative || getIsDocInBoard(doc) !== false;

  const [autofocus, setAutofocus] = useState<'content' | 'prev' | 'next'>('content');
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const prevButtonRef = useRef<HTMLButtonElement>(null);

  const focusOnPrevButton = () => {
    setAutofocus('prev');
    prevButtonRef.current?.focus();
  };

  const focusOnNextButton = () => {
    setAutofocus('next');
    nextButtonRef.current?.focus();
  };

  const onEscapeEdition = () => {
    if (getEditorAi().visible || getZoom().src || getOpenedLayersAbove(Layer.DocPanel)) return;
    if (isLastDoc && isFirstDoc) goBack();
    if (isLastDoc) {
      focusOnPrevButton();
    } else {
      focusOnNextButton();
    }
  };

  const isNavigationDisabled = (
    location.state?.isDocRelative &&
    // Add the insight id in `docRelativeTo` on open. This is be convenient in the prev/next navigation.
    !location.state.docRelativeTo
  ) || !isDocInBoard;
  const isPrevDocDisabled = isNavigationDisabled || isFirstDoc;
  const isNextDocDisabled = isNavigationDisabled || isLastDoc;

  useAppHotkeys('control+shift+k', () => {
    focusOnPrevButton();
    navigateToPrevDoc();
  }, { enabled: () => !isPrevDocDisabled });

  useAppHotkeys('control+shift+j', () => {
    focusOnNextButton();
    navigateToNextDoc();
  }, { enabled: () => !isNextDocDisabled });

  if (!visible) return null;

  return (
    <>
      <Overlay onClick={goBack} />

      <Container
        ref={containerRef}
        $widthSidebar={widthSidebar}
        $expanded={expanded}
      >
        <ErrorBoundary>
          {!isDocLoading && (doc ? (
            <>
              <MainSection>
                <DocPanelHeader
                  doc={doc}
                  onClose={goBack}
                  onExpand={onExpand}
                  onOpenCoverInputFile={onOpenCoverInputFile}
                  onDeleteDoc={goBack}
                  disabled={disabled}
                  prevNextButtons={(
                    <PrevNextActions isNotInBoard={!isDocInBoard}>
                      <StyledActionButton
                        size="L"
                        onClick={() => {
                          setAutofocus('content');
                          navigateToPrevDoc();
                        }}
                      // eslint-disable-next-line no-nested-ternary
                        tooltip={!isDocInBoard
                          ? <NotInBoardMessage doc={doc} />
                          : isPrevDocDisabled ? null : (
                            <Shortcut
                              keys={shortcuts[ShortcutDocPanel.PrevDoc]}
                              label="Previous"
                            />
                          )}
                        tooltipPlacement="bottom"
                        disabled={isPrevDocDisabled}
                        ref={prevButtonRef || !isDocInBoard}
                      >
                        <UpIcon />
                      </StyledActionButton>
                      <StyledActionButton
                        size="L"
                        onClick={() => {
                          setAutofocus('content');
                          navigateToNextDoc();
                        }}
                      // eslint-disable-next-line no-nested-ternary
                        tooltip={!isDocInBoard
                          ? <NotInBoardMessage doc={doc} />
                          : isNextDocDisabled ? null : (
                            <Shortcut
                              keys={shortcuts[ShortcutDocPanel.NextDoc]}
                              label="Next"
                            />
                          )}
                        tooltipPlacement="bottom"
                        disabled={isNextDocDisabled}
                        ref={nextButtonRef}
                      >
                        {!isLastDoc && loadingNextDoc ? <Spinner /> : <DownIcon />}
                      </StyledActionButton>
                    </PrevNextActions>
                  )}
                  {...isFeedback(doc.doctype) && doc.status && {
                    processButton: (
                      <DocProcessButton
                        docId={doc.id}
                        docStatusCategory={doc.status.category}
                        onCompleted={isLastDoc ? goBack : navigateToNextDoc}
                        parent="doc-panel"
                        quotesCount={doc.quotesCount ?? 0}
                      />
                    ),
                  }}
                />

                <DocPanelContent
                  doc={doc}
                  containerRef={containerRef}
                  getDropzonePropsCover={getDropzonePropsCover}
                  isDraggingCover={isDraggingCover}
                  isUploadingCover={isUploadingCover}
                  onTitleUpdated={onTitleUpdated}
                  onOpenCoverInputFile={onOpenCoverInputFile}
                  onEscapeEdition={onEscapeEdition}
                  focusOnMount={autofocus === 'content'}
                  disabled={disabled}
                />

                {!isDocInBoard && (
                  <ToasterContainer>
                    <Toaster
                      icon={<InfoIconOutline />}
                      title={<NotInBoardMessage doc={doc} />}
                    />
                  </ToasterContainer>
                )}
              </MainSection>

              <DocPanelRightPanel
                doc={doc}
              />
            </>
          ) : <ErrorPage size={Size.SMALL} message="Oops, this doc no longer exists" />)}
        </ErrorBoundary>

        {children}
      </Container>
    </>
  );
};

export default DocPanelBoardPage;

const NotInBoardMessage = ({ doc }: { doc: FullDocWithPublicId }) => {
  const board = useBoard();
  const matchCustomView = useRouteMatch(routing[PageId.Board]);
  const emoji = !!matchCustomView && <StyledEmoji inline emoji={board?.emoji} size={12} />;

  return (
    // Non-breaking space and pre-line guarantees that the emoji will be on the same line as the board name
    <span style={{ whiteSpace: 'pre-line' }}>
      {`${getDocName(doc)} is no longer in `}
      {emoji}
      {!!emoji && '\u00A0'}
      {board?.name}
    </span>
  );
};

const getDocName = (doc: FullDocWithPublicId) => {
  switch (doc.doctype.type) {
    case DoctypeType.Feedback:
      return 'This feedback';
    case DoctypeType.Insight:
      return 'This insight';
    default:
      return doc._docKey;
  }
};
