import {
  GroupByConfigBoardQueryWithGroupByFragment,
  PropertiesFragment,
  SwimlanebyConfigFragment,
  SwimlaneByDoctypeFragment,
} from '@cycle-app/graphql-codegen';
import { Emoji, SelectPanel, SelectOption } from '@cycle-app/ui';
import { EyeClosedIcon, EyeIcon, SumIcon, AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import memoize from 'fast-memoize';
import { FC, useMemo, useCallback, useState } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import FilterRules from 'src/components/FilterRules/FilterRules';
import { SoonBadge } from 'src/components/SoonBadge/SoonBadge';
import useManageHiddenSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageHiddenSwimlanes';
import useManageSwimlanes from 'src/hooks/api/mutations/boardConfig/useManageSwimlanes';
import useSwimlaneFilterMutations from 'src/hooks/api/mutations/boardConfig/useSwimlaneFilterMutations';
import { Layer } from 'src/types/layers.types';
import { getFilterAttributeOption } from 'src/utils/boardConfig/boardConfig.util';
import { Truncate } from 'src/utils/css.styles';
import { getOptionFromDoctype } from 'src/utils/selectOptions.util';

import {
  LightButton,
  GroupByButtons,
  GroupByButton,
  StyledToggleInput,
  Caret,
} from '../BoardConfigForm.styles';
import HiddenSwimlanes from './HiddenSwimlanes';

type DropdownVisible = 'groupby' | 'hiddenGroups' | 'filters' | null;

interface Props {
  availableSwimlaneByDoctypes: SwimlaneByDoctypeFragment['availableSwimlaneByDoctypes'] | null;
  boardConfigId: string;
  boardID?: string;
  filterableProperties: PropertiesFragment;
  full: boolean;
  groupByConfig: GroupByConfigBoardQueryWithGroupByFragment['groupbyConfig'] | null;
  swimlaneByConfig: SwimlanebyConfigFragment | null;
}
const BoardConfigFormSwimlanes: FC<React.PropsWithChildren<Props>> = ({
  availableSwimlaneByDoctypes,
  boardConfigId,
  boardID,
  filterableProperties: filterablePropertiesProp,
  full,
  groupByConfig,
  swimlaneByConfig,
}) => {
  const {
    addSwimlaneDoctype,
    removeSwimlane,
    loadingAddSwimlane,
    loadingRemoveSwimlane,
  } = useManageSwimlanes(boardConfigId);
  const {
    showSwimlane,
    hideSwimlane,
    showAllSwimlanes,
    hideAllSwimlanes,
  } = useManageHiddenSwimlanes({
    boardConfigId,
    swimlaneConfigId: swimlaneByConfig?.id,
    boardID,
  });
  const {
    loadingChangeOperator,
    changeFilterRuleOperator,
    removeFilterRule,
    addFilterRule,
    addFilterValue,
    changeFilterRuleAttribute,
    removeFilterValue,
  } = useSwimlaneFilterMutations({
    boardConfigId,
    swimlaneConfigId: swimlaneByConfig?.id ?? '',
    boardID,
  });

  const [dropdownVisible, setDropdownVisible] = useState<DropdownVisible>(null);
  const toggleDrodown = useMemo(
    () => memoize((dropdown: DropdownVisible) => () => {
      const newState = dropdownVisible ? null : dropdown;
      setDropdownVisible(newState);
    }),
    [dropdownVisible],
  );
  const hideDropdown = useCallback(() => setDropdownVisible(null), []);

  const selectedGroupbyDoctype = swimlaneByConfig?.doctype;
  const groupByOptions = useMemo(
    () => {
      const currentGroupByOptions = nodeToArray(availableSwimlaneByDoctypes).map(d => getOptionFromDoctype(d));
      const comingSoonOptions = nodeToArray(filterablePropertiesProp)
        .filter(property => {
          // Filter out parent property already in currentGroupByOptions
          if (property.__typename === 'ParentDefinition') return false;
          // Filter out the groupBy property
          if (groupByConfig?.property.id === property.id) return false;
          // Filter out non-groupable properties
          if (['AttributeUrlDefinition', 'AttributeTextDefinition', 'AttributeNumberDefinition'].includes(property.__typename)) return false;
          return true;
        })
        .map(property => ({
          ...getFilterAttributeOption(property),
          disabled: true,
          end: <SoonBadge />,
        }));

      return [
        ...currentGroupByOptions,
        ...comingSoonOptions,
      ];
    },
    [availableSwimlaneByDoctypes, filterablePropertiesProp, groupByConfig?.property.id],
  );

  const nbShownSwimlanes = useMemo(
    () => swimlaneByConfig?.hiddenSwimlanes.edges.filter(({ node }) => !node.hidden)?.length ?? 0,
    [swimlaneByConfig?.hiddenSwimlanes],
  );
  const nbHiddenSwimlanes = useMemo(
    () => swimlaneByConfig?.hiddenSwimlanes.edges.filter(({ node }) => node.hidden)?.length ?? 0,
    [swimlaneByConfig?.hiddenSwimlanes],
  );
  const buttonConfig = useMemo(() => (nbShownSwimlanes > nbHiddenSwimlanes
    ? {
      label: `${nbHiddenSwimlanes} hidden sub group${nbHiddenSwimlanes > 1 ? 's' : ''}`,
      icon: <EyeClosedIcon />,
    }
    : {
      label: `${nbShownSwimlanes} shown sub group${nbShownSwimlanes > 1 ? 's' : ''}`,
      icon: <EyeIcon />,
    }),
  [nbHiddenSwimlanes, nbShownSwimlanes]);

  const {
    filters,
    filterableProperties,
  } = useMemo(
    () => ({
      filters: nodeToArray(swimlaneByConfig?.filterProperties).filter(f => f.__typename !== 'SwimlaneByFilterPropertyRuleDocChildren'),
      filterableProperties: nodeToArray(swimlaneByConfig?.filterableProperties).filter(f => f.__typename !== 'ChildrenDefinition'),
    }),
    [swimlaneByConfig?.filterProperties, swimlaneByConfig?.filterableProperties],
  );

  const swimlaneChildrenAvailableFilterRule = useMemo(
    () => swimlaneByConfig
      ?.filterableProperties
      .edges
      .find(({ node }) => node.__typename === 'ChildrenDefinition')?.node,
    [swimlaneByConfig?.filterableProperties],
  );
  const swimlaneChildrenActiveFilter = useMemo(
    () => swimlaneByConfig
      ?.filterProperties
      .edges
      .find(({ node }) => node.__typename === 'SwimlaneByFilterPropertyRuleDocChildren')?.node,
    [swimlaneByConfig?.filterProperties],
  );

  const onRemoveSwimlane = useCallback(() => {
    setDropdownVisible(null);
    return removeSwimlane();
  }, [removeSwimlane]);

  const onDoctypeOptionChange = useCallback((option: SelectOption) => {
    setDropdownVisible(null);
    return addSwimlaneDoctype(option.value);
  },
  [addSwimlaneDoctype]);

  const changeFilter = useCallback(async (filterId: string, valueId: string) => {
    const propertyAdded = filterableProperties.find(p => p.id === valueId);
    if (propertyAdded) {
      await changeFilterRuleAttribute(filterId, propertyAdded);
    }
  }, [changeFilterRuleAttribute, filterableProperties]);

  const addFirstFilterAvailable = useCallback(async () => {
    const firstFilterableProperty = filterableProperties[0];
    if (!firstFilterableProperty) return;
    await addFilterRule(firstFilterableProperty);
  }, [filterableProperties, addFilterRule]);

  const onToggleEmptySwimlanesFilter = useCallback(async () => {
    if (swimlaneChildrenActiveFilter) {
      await removeFilterRule(swimlaneChildrenActiveFilter.id);
    } else if (swimlaneChildrenAvailableFilterRule) {
      await addFilterRule(swimlaneChildrenAvailableFilterRule);
    }
  }, [swimlaneChildrenAvailableFilterRule, swimlaneChildrenActiveFilter, addFilterRule, removeFilterRule]);

  return (
    <>
      <GroupByButtons>
        <DropdownLayer
          layer={Layer.DropdownModalZ1}
          visible={dropdownVisible === 'groupby'}
          hide={hideDropdown}
          placement="bottom-start"
          content={(
            <SelectPanel
              options={groupByOptions}
              isMulti={false}
              {...!!swimlaneByConfig && { onClearValue: onRemoveSwimlane }}
              onOptionChange={onDoctypeOptionChange}
            />
          )}
        >
          {selectedGroupbyDoctype
            ? (
              <GroupByButton
                type="button"
                onClick={toggleDrodown('groupby')}
                forceFocus={dropdownVisible === 'groupby'}
                position="left"
                style={{ maxWidth: 170 }}
              >
                <Emoji size={12} emoji={selectedGroupbyDoctype.emoji} />
                <Truncate>{selectedGroupbyDoctype.name}</Truncate>
                <Caret />
              </GroupByButton>
            )
            : (
              <LightButton
                onClick={toggleDrodown('groupby')}
                forceFocus={dropdownVisible === 'groupby'}
                disabled={!groupByConfig}
                isLoading={loadingAddSwimlane || loadingRemoveSwimlane}
                iconStart={<AddIcon size={11} />}
              >
                Add sub group
              </LightButton>
            )}
        </DropdownLayer>

        {full && selectedGroupbyDoctype && (
          <>
            <HiddenSwimlanes
              swimlanebyConfig={swimlaneByConfig}
              isVisible={dropdownVisible === 'hiddenGroups'}
              placement="top"
              hideDropdown={hideDropdown}
              onHideSwimlane={hideSwimlane}
              onShowSwimlane={showSwimlane}
              onHideSwimlanes={hideAllSwimlanes}
              onShowSwimlanes={showAllSwimlanes}
              lowerdropdownLayer={Layer.DropdownModalZ1}
              upperDropdownLayer={Layer.DropdownModalZ2}
            >
              <GroupByButton
                type="button"
                onClick={toggleDrodown('hiddenGroups')}
                forceFocus={dropdownVisible === 'hiddenGroups'}
                position="middle"
                // To prevent collision with doc preview using space
                onKeyUp={e => e.preventDefault()}
              >
                {buttonConfig.icon}
                <Truncate>{buttonConfig.label}</Truncate>
              </GroupByButton>
            </HiddenSwimlanes>

            <FilterRules
              dropdownLayer={Layer.DropdownModalZ1}
              dropdownPlacement="top"
              filtersDropdownLayer={Layer.DropdownModalZ2}
              visible={dropdownVisible === 'filters'}
              hide={hideDropdown}
              filters={filters}
              filterableProperties={filterableProperties}
              loadingChangeOperator={loadingChangeOperator}
              onAddFirstFilterAvailable={addFirstFilterAvailable}
              onAddValue={addFilterValue}
              onRemoveValue={removeFilterValue}
              onChangeFilter={changeFilter}
              onDeleteFilter={removeFilterRule}
              onOperatorUpdated={changeFilterRuleOperator}
            >
              <GroupByButton
                type="button"
                onClick={toggleDrodown('filters')}
                forceFocus={dropdownVisible === 'filters'}
                position="right"
              >
                <SumIcon />
                <span>{filters.length}</span>
              </GroupByButton>
            </FilterRules>
          </>
        )}
      </GroupByButtons>

      {full && !!selectedGroupbyDoctype && (
        <StyledToggleInput
          id="hide-empty-swimlanes-toggle"
          label="Hide empty sub groups"
          checked={!!swimlaneChildrenActiveFilter}
          onChange={onToggleEmptySwimlanesFilter}
          togglePosition="left"
        />
      )}
    </>
  );
};
export default BoardConfigFormSwimlanes;
