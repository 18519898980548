import { ThemeType } from '@cycle-app/graphql-codegen';
import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Card = styled.div`
  border-radius: 12px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
`;

export const SlackCard = styled(Card)`
  background: hsl(var(--cycle), ${p => (p.theme.themeType === ThemeType.Nightfall ? 0.15 : 0.1)});
`;

export const SourcesCard = styled(Card)`
  background: ${p => p.theme.colors.inbox.collectMore.bg};
`;

export const AvailableSources = styled.div`
  height: 28px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  
  > * {
    height: 16px;
  }
`;

export const Title = styled.p`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Slack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: ${p => p.theme.colors.home.integrations.logo.bg};
  border-radius: 8px;
`;
