export const getAttributeParser = (field: string) => ({
  parseHTML: (e: HTMLElement) => {
    const attr = e.getAttribute(field);
    if (attr) {
      try {
        return JSON.parse(attr);
      } catch {
        return '';
      }
    }
    return '';
  },
  renderHTML: (e: Record<string, any>) => ({
    [field]: e[field] ? JSON.stringify(e[field]) : '',
  }),
});
