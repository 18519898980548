import {
  card, typo, Button, ActionButton, ellipsis, Input,
} from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Header = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ChangeLogContainer = styled.div`
  ${card}
  width: 780px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const ChangeLogRecordContainer = styled.div`
  border-radius: 8px;
  padding: 16px;
  border: 1px solid ${p => (p.theme.isDark ? p.theme.colors.border.primary : p.theme.colors.border.hover)};
  width: 100%;
`;

export const ChangelogRecordLabel = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  text-align: left;
  white-space: nowrap;
  padding-right: 8px;
`;

interface ChangelogRecordValueProps {
  $isFirst?: boolean;
  $isLast?: boolean;
}

export const ChangelogRecordValue = styled.div<ChangelogRecordValueProps>`
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey800 : '#F7F7F7')};
  width: 100%;
  padding-right: 8px;
  user-select: text;

  ${p => p.$isFirst && `
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 12px;
  `}

  ${p => p.$isLast && `
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 12px;
  `}

  svg {
    color: ${p => p.theme.colors.text.disabled};
  }

  span {
    ${ellipsis}
    user-select: text;
  }
`;

export const Bold = styled.span`
  ${typo.body500}
  word-break: break-word;
`;

export const PublicChangeLogContainer = styled.div`
  ${card}
  width: 780px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
`;

export const PublicChangelogImage = styled.img`
  width: 212px;
  margin: 0 auto;
`;

export const Title = styled.h5`
  ${typo.headerSmall}
  color: ${p => p.theme.colors.text.primary};
`;

export const PublicChangelogTitle = styled.div`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
  display: flex;
  gap: 8px;
  margin-top: 32px;
  align-items: center;
`;

export const PublicChangelogDescription = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 16px;
  text-align: center;
`;

export const Date = styled.span`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  flex: none;
`;

export const Description = styled.span`
  ${typo.body400}
  display: block;
  color: ${p => p.theme.colors.text.secondary};
`;

export const ReleasesListContainer = styled.div`
  ${card}
  width: 780px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type ReleaseItemsProps = {
  $hasMinDimensions: boolean;
};

export const ReleaseItems = styled.ul<ReleaseItemsProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;

  ${p => p.$hasMinDimensions && css`
    width: 100%;
    height: 345px;
  `}
`;

export const ReleaseItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  gap: 16px;
  align-items: flex-start;
`;

export const Badge = styled.span`
  ${typo.caption400}
  display: inline-flex;
  padding: 0px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background-color: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.Grey100)};
  color: ${p => p.theme.colors.text.disabled};
`;

export const RequestButton = styled(Button).attrs({
  size: 'M',
  variant: 'secondary',
})`
  margin-top: 32px;
`;

export const Modal = styled(PortalModal)`
  width: 690px;
  padding: 64px 86px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ModalTitle = styled.h4`
  ${typo.headerLarge600}
  color: ${p => p.theme.colors.text.primary};
  margin-bottom: 40px;
  text-align: center;
`;

export const ModalItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  background-color: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey100)};

  > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    background-color: ${p => (p.theme.isDark ? ColorTheme.Grey850 : ColorTheme.White)};
    border-radius: 8px;
    color: ${p => p.theme.colors.text.primary};
  }

  > div:last-child {

    h5 {
      ${typo.body500}
      color: ${p => p.theme.colors.text.primary};
    }

    p {
      ${typo.body400}
      color: ${p => p.theme.colors.text.secondary};
    }
  }
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const StyledInput = styled(Input)`
  width: 350px;
  input {
    cursor: text !important;
  }
  svg {
    color: ${p => p.theme.colors.text.disabled};
    outline: none;
    :hover {
      color: ${p => p.theme.colors.text.primary};
    }
  }
`;

interface ChangelogStatusRootProps {
  $isValid: boolean;
}

export const ChangelogStatusRoot = styled.button<ChangelogStatusRootProps>`
  ${typo.body500}
  cursor: pointer;
  --bg: ${p => (p.$isValid
    ? ((p.theme.isDark && p.theme.baseColors.Green900) || '#EEFAF5')
    : ((p.theme.isDark && p.theme.baseColors.Red800) || p.theme.baseColors.Red200))};
  --dot: ${p => (p.$isValid ? '#56BD87' : p.theme.baseColors.Red700)};
  background: var(--bg);
  padding: 0px 10px;
  border-radius: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
  height:  32px;
  margin-bottom: 4px;

  &::before {
    background-color: var(--dot);
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }
`;
