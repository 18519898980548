import { MeFragment } from '@cycle-app/graphql-codegen';
import { LogoutIcon, NextArrowIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';

import ProductItem from 'src/components/ProductsMenu/ProductItem/ProductItem';
import { DEFAULT_AVATARS } from 'src/constants/avatars.constants';
import { HELP_URL } from 'src/constants/help.constants';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate, useParams } from 'src/hooks';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useIntercom } from 'src/hooks/useIntercom';
import { useLogout } from 'src/hooks/useLogout';
import { getLastView } from 'src/reactives/lastView.reactive';
import { openIntercom } from 'src/utils/intercom.utils';

import {
  AvatarImg,
  Body,
  Container,
  Content,
  Contacts,
  Header,
  Logo,
  LogoutButton,
  Title,
  ContactTitle,
  ContactButton,
  LogoutButtonText,
  WorkspaceContainer,
} from './EmptyState.styles';

type EmptyStateProps = {
  /**
   * un-auth mode means that user try to access views that are reserved for
   * unAuthenticated users like SignUp
   *
   * workspace-access means that user try to access a workspace he doesn't have
   * access either cause is not part of it, either cause it archived
   */
  mode?: 'un-auth' | 'workspace-access';
};

export const EmptyState = ({ mode }: EmptyStateProps) => {
  const me = useMaybeMe();
  const logout = useLogout();
  useIntercom();

  if (!me) return null;

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.3 },
      }}
    >
      <Header>
        <Logo animation="hover" full size={32} />
        <LogoutButton size={14} onClick={logout}>
          <LogoutButtonText>
            {`Connected as ${me?.firstName} (${me?.email})`}
          </LogoutButtonText>
          <LogoutIcon />
        </LogoutButton>
      </Header>
      <Body>
        <Content>
          <AvatarImg src={DEFAULT_AVATARS[0]} alt="avatar" />
          {mode === 'un-auth' ? <UnAuth user={me} /> : <WorkspaceAccess user={me} />}
        </Content>
      </Body>
    </Container>
  );
};

type UnAuthProps = {
  user: MeFragment;
};

const UnAuth = ({ user }: UnAuthProps) => {
  const logout = useLogout();
  const { navigate } = useNavigate();
  const workspaces = user.products.edges.length ? nodeToArray(user.products) : [];
  const { isEnabled: isCreateWSEnabled } = useFeatureFlag('empty-state-create-ws');
  const { productSlug: lastWorkspaceSlug } = getLastView();
  const productSlug = workspaces.some(w => w.slug === lastWorkspaceSlug)
    ? lastWorkspaceSlug
    : workspaces[0]?.slug;

  return (
    <div>
      <Title>
        {`Hey ${user.firstName} 👋`}
        <br />
        Looks like you’re already logged in
      </Title>
      <Contacts>
        <ContactTitle>Want to go to your current workspace?</ContactTitle>
        <ContactButton
          size="M"
          variant="nospace"
          onClick={() => {
            if (productSlug) {
              navigate(PageId.Inbox, { productSlug });
            } else {
              navigate(PageId.Main);
            }
          }}
        >
          Go to Cycle
          <NextArrowIcon />
        </ContactButton>
        <ContactTitle>
          {isCreateWSEnabled
            ? 'Want to create a workspace with another account?'
            : 'Need help ?'}
        </ContactTitle>
        <ContactButton
          size="M"
          variant="nospace"
          onClick={() => {
            if (isCreateWSEnabled) {
              logout();
              navigate(PageId.GetStarted);
            } else {
              openIntercom();
            }
          }}
        >
          {isCreateWSEnabled
            ? 'Get started'
            : 'Talk to our team'}
          <NextArrowIcon />
        </ContactButton>
        {isCreateWSEnabled && (
          <>
            <ContactTitle>Learn more about Cycle</ContactTitle>
            <ContactButton
              size="M"
              variant="nospace"
              onClick={() => window.open(HELP_URL, '_black')}
            >
              Documentation
              <NextArrowIcon />
            </ContactButton>
          </>
        )}
      </Contacts>
    </div>
  );
};

type WorkspaceAccessProps = {
  user: MeFragment;
};

const WorkspaceAccess = ({ user }: WorkspaceAccessProps) => {
  const { navigate } = useNavigate();
  const { productSlug } = useParams();
  const { isEnabled: isCreateWSEnabled } = useFeatureFlag('empty-state-create-ws');

  const workspaces = user.products.edges.length ? nodeToArray(user.products) : [];
  const hasSlug = !!productSlug;

  return (
    <WorkspaceContainer>
      <Title>
        {`Hey ${user.firstName} 👋`}
        <br />
        {!hasSlug && !workspaces.length
          ? 'Look like you do not have any workspace'
          : 'Looks like you no longer have access to this workspace'}
      </Title>
      <Contacts>
        {!!workspaces.length && (
          <div>
            {workspaces.map(w => (
              <ProductItem
                key={w.id}
                isActive={false}
                product={w}
                selected={false}
                onClick={() => navigate(PageId.Main, { productSlug: w.slug })}
              />
            ))}
          </div>
        )}
        {!workspaces.length && (
          <>
            <ContactTitle>
              {isCreateWSEnabled ? 'Want to create a workspace?' : 'Need help ?'}
            </ContactTitle>
            <ContactButton
              size="M"
              variant="nospace"
              onClick={() => {
                if (isCreateWSEnabled) {
                  navigate(PageId.GetStarted);
                } else {
                  openIntercom();
                }
              }}
            >
              {isCreateWSEnabled ? 'Get started' : 'Talk to our team'}
              <NextArrowIcon />
            </ContactButton>
            <ContactTitle>Learn more about Cycle</ContactTitle>
            <ContactButton
              size="M"
              variant="nospace"
              onClick={() => window.open(HELP_URL, '_black')}
            >
              Documentation
              <NextArrowIcon />
            </ContactButton>
          </>
        )}
      </Contacts>
    </WorkspaceContainer>
  );
};
