import { Reference } from '@apollo/client';
import { AiState, VerifyQuoteDocument } from '@cycle-app/graphql-codegen';
import isEqual from 'lodash/isEqual';

import { useSafeMutation } from 'src/hooks';
import { getDocFullFromCache } from 'src/utils/cache.utils';
import { getVerifiedQuoteTempId } from 'src/utils/quotes.utils';
import { parseStoreFieldName } from 'src/utils/update-cache/cache.utils';

export const useVerifyQuote = () => {
  const [mutate, { loading }] = useSafeMutation(VerifyQuoteDocument);

  const verify = (docId: string, quoteId: string) => {
    const doc = getDocFullFromCache(docId);
    if (!doc) return;
    const quote = doc.quotes.edges.find(edge => edge.node?.id === quoteId)?.node;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    mutate({
      variables: {
        id: quoteId,
      },
      optimisticResponse: {
        verifyQuote: null,
      },
      update: (cache, { data }) => {
        // Remove the quote from the doc and add it to the verified quotes
        cache.modify({
          id: cache.identify({ id: docId }),
          fields: {
            quotesCount: (value) => (value > 0 ? value - 1 : 0),
            quotes: (refs, { readField }) => ({
              ...refs,
              edges: refs.edges.filter(
                (edge: { node: Reference }) => readField('id', edge.node) !== quoteId,
              ),
            }),
            docTargetsCount: (count, { storeFieldName }) => {
              const storeVariables = parseStoreFieldName(storeFieldName);
              if (storeVariables.aiState === AiState.AiCreated) return count;
              return count + 1;
            },
            docTargets: (refs, {
              toReference, storeFieldName,
            }) => {
              if (!data?.verifyQuote) {
                return refs;
              }
              const storeVariables = parseStoreFieldName(storeFieldName);
              if (isEqual(storeVariables.aiStates, [AiState.AiCreated])) return refs;
              return ({
                ...refs,
                edges: [
                  ...refs.edges,
                  {
                    __typename: 'DocTargetEdge',
                    node: {
                      __typename: 'DocTarget',
                      id: getVerifiedQuoteTempId(),
                      content: quote?.content,
                      blockId: null,
                      doc: toReference(data.verifyQuote.id),
                    },
                  },
                ],
              });
            },
          },
        });
      },
    });
  };

  return {
    verify,
    loading,
  };
};
