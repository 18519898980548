import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Flex } from '@cycle-app/ui';
import { SelectLineProps } from '@cycle-app/ui/components/Selects/SelectLine/SelectLine';
import { useListNav, isChromeExtension } from '@cycle-app/utilities';
import { NodeViewRendererProps, Range } from '@tiptap/react';
import { FC, useState } from 'react';

import { InstallIntegrationButton } from 'src/components/Integrations/InstallIntegrationButton/InstallIntegrationButton';
import { LearnMoreButton } from 'src/components/Integrations/LearnMoreButton';
import { NewBadge } from 'src/components/NewBadge';
import { useEditorContext } from 'src/contexts/editorContext';
import { useProductAddOn } from 'src/hooks';
import { useProductIntegrations } from 'src/hooks/api/useProductIntegrations';
import { useCheckMonthlyAiQueries } from 'src/hooks/billing/useCheckMonthlyAiQueries';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { slashActions, actions as allActions, Action, ActionId } from 'src/services/editor/editorActions';

import { LanguageSelect } from '../../LanguageSelect';
import EmbedForm from './EmbedForm/EmbedForm';
import {
  Container,
  Category,
  CategoryTitle,
  Actions,
  StyledSelectLine,
  TranscriptLabel,
} from './SlashDropdown.styles';

interface Props extends NodeViewRendererProps {
  range: Range;
  command: () => void;
  items: Array<string>;
  hide: () => void;
}

type Step = 'default' | 'embed' | 'image';

const SlashDropdown: FC<React.PropsWithChildren<Props>> = ({
  range, command, items, hide,
}) => {
  const [step, setStep] = useState<Step>('default');
  const editor = useEditorContext(ctx => ctx.editor);
  const editorId = useEditorContext(ctx => ctx.id);
  const disabledActions = useEditorContext(ctx => ctx.disabledActions);

  const actionsToShow = slashActions
    .map(section => ({
      ...section,
      actions: filterActions(section.actions),
    }))
    .filter(section => section.actions.length);

  const optionsValues = actionsToShow
    .map(({ actions }) => filterActions(actions).map(action => action.id)).flat();

  const {
    selected,
    listProps,
    itemProps,
    hoverDisabled,
  } = useListNav({
    optionsValues,
    onSelect: onActionSelected,
    value: null,
    autoFocus: true,
  });

  const { canUseAi } = useGetPermission();

  const nbAiQueries = useEditorContext(ctx => ctx.nbAiQueries);
  const {
    maxAiQueriesReached,
    openUnlimitedAiPrompt,
  } = useCheckMonthlyAiQueries(nbAiQueries);

  const jiraAddOn = useProductAddOn('jira', { enableForSuperAdmin: false });
  const confluenceAddOn = useProductAddOn('confluence', { enableForSuperAdmin: false });

  if (!optionsValues.length) return null;

  return (
    <Container {...listProps} large={step !== 'default' && !isChromeExtension}>
      {renderContent()}
    </Container>
  );

  function renderContent() {
    if (step === 'embed') {
      return (
        <EmbedForm
          range={range}
          onCancel={backToList}
        />
      );
    }

    return renderActions();
  }

  function renderActions() {
    return (
      <>
        {actionsToShow
          .map(({
            category, actions,
          }) => (
            <Category key={category}>
              <CategoryTitle>{category}</CategoryTitle>
              <Actions>
                {actions
                  .filter(action => (items.includes(action.id)))
                  .map(action => {
                    const lineProps = {
                      label: action.label,
                      startSlot: action.icon,
                      endSlot: action.isNew ? <NewBadge /> : undefined,
                      isSelected: selected === action.id,
                      hoverDisabled,
                      ...itemProps(action.id),
                    };

                    if (action.id === ActionId.GithubIssue) {
                      return (
                        <GitHubSelectLine
                          key={action.id}
                          hide={hide}
                          {...lineProps}
                        />
                      );
                    }

                    if (action.id === ActionId.Linear || action.id === ActionId.LinearProject) {
                      return (
                        <LinearSelectLine
                          key={action.id}
                          hide={hide}
                          {...lineProps}
                        />
                      );
                    }

                    if (action.id === ActionId.Notion) {
                      return (
                        <NotionSelectLine
                          key={action.id}
                          {...lineProps}
                        />
                      );
                    }

                    if (action.id === ActionId.Transcript) {
                      return (
                        <StyledSelectLine
                          key={action.id}
                          {...lineProps}
                          endSlot={null}
                          label={(
                            <TranscriptLabel>
                              Transcript
                              <LanguageSelect />
                            </TranscriptLabel>
                          )}
                        />
                      );
                    }

                    return (
                      <StyledSelectLine
                        key={action.id}
                        {...lineProps}
                      />
                    );
                  })}
              </Actions>
            </Category>
          ))}
      </>
    );
  }

  function filterActions(actions: Array<Action>) {
    return actions.filter(action => {
      const shouldShowIncludeAction =
        !disabledActions?.includes(action.id) &&
        items.includes(action.id);

      return shouldShowIncludeAction;
    });
  }

  function onActionSelected(actionId: string | null) {
    if (!actionId || !allActions[actionId as ActionId]) return;

    if (actionId === ActionId.Jira && !jiraAddOn.isEnabled) {
      setLimitationsModal({
        action: 'USE_ADD_ON',
        brand: 'jira',
      });
      return;
    }

    if (actionId === ActionId.Confluence && !confluenceAddOn.isEnabled) {
      setLimitationsModal({
        action: 'USE_ADD_ON',
        brand: 'confluence',
      });
      return;
    }

    if (actionId === ActionId.Embed) {
      setStep('embed');
      return;
    }

    if (actionId === ActionId.Ai && !canUseAi) {
      setLimitationsModal({ action: 'USE_AI' });
      closeDropdown();
      return;
    }
    if (actionId === ActionId.Ai && maxAiQueriesReached) {
      openUnlimitedAiPrompt();
      closeDropdown();
      return;
    }

    closeDropdown();
    allActions[actionId as ActionId].toggle?.(editor, 'slash-menu', editorId);
  }

  function backToList() {
    setStep('default');
  }

  function closeDropdown() {
    command();
  }
};

export default SlashDropdown;

const GitHubSelectLine = ({
  hide, ...props
}: SelectLineProps & { hide: VoidFunction }) => {
  const { getIntegration } = useProductIntegrations();
  const gitHubIntegration = getIntegration(IntegrationType.Github);
  const isDisabled = !gitHubIntegration?.provider;
  return (
    <StyledSelectLine
      {...props}
      endSlot={(
        <Flex style={{ gap: 4 }}>
          <LearnMoreButton type={IntegrationType.Github} />
          {isDisabled && gitHubIntegration && (
            <InstallIntegrationButton
              integration={gitHubIntegration}
              onClick={hide}
            />
          )}
        </Flex>
      )}
      {...isDisabled && { onClick: undefined }}
    />
  );
};

const LinearSelectLine = ({
  hide, ...props
}: SelectLineProps & { hide: VoidFunction }) => {
  const { getIntegration } = useProductIntegrations();
  const linearIntegration = getIntegration(IntegrationType.Linear);
  const isDisabled = !linearIntegration?.provider;
  return (
    <StyledSelectLine
      {...props}
      endSlot={(
        <Flex style={{ gap: 4 }}>
          <LearnMoreButton type={IntegrationType.Linear} />
          {isDisabled && linearIntegration && (
            <InstallIntegrationButton
              integration={linearIntegration}
              onClick={hide}
            />
          )}
        </Flex>
      )}
      {...isDisabled && { onClick: undefined }}
    />
  );
};

const NotionSelectLine = (props: SelectLineProps) => {
  return (
    <StyledSelectLine
      {...props}
      endSlot={<LearnMoreButton type={IntegrationType.Notion} />}
    />
  );
};
