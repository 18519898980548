import { Skeleton, CircleSkeleton, Flex } from '@cycle-app/ui';

import { Container } from './ThreadItem.styles';

export const ThreadsSkeleton = () => {
  return (
    <>
      {[...Array(2).keys()].map(i => (
        <Container key={i}>
          <Flex $column $gap={16} $align="flex-start">
            <Flex $gap={16}>
              <CircleSkeleton height={20} width={20} />
              <Skeleton height={16} width={150} />
            </Flex>
            <Skeleton height={16} />
            <Skeleton height={16} />
          </Flex>
        </Container>
      ))}
    </>
  );
};
