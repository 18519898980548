import { SearchIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useCustomers } from 'src/hooks/api/queries/customers/useCustomers';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { openCustomerModal } from 'src/hooks/modals/useCustomerModal';

import { CustomersCommonTabList } from '../CustomersCommonTabList/CustomersCommonTabList';
import {
  Tabs,
  Nav,
  Actions,
  SearchInput,
} from './Customers.styles';
import { CustomersAddCustomerButton } from './CustomersAddCustomerButton';
import { CustomersListPeople } from './CustomersListPeople';

export const CustomersList = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchText] = useDebounce(inputValue, INPUT_ONCHANGE_DEBOUNCE);
  const {
    customers, fetchNextPage, loading, hasNextPage,
  } = useCustomers({ defaultSearch: searchText });

  return (
    <Tabs>
      <Nav>
        <CustomersCommonTabList />
        <Actions>
          <SearchInput
            iconBefore={<SearchIcon />}
            placeholder="Search…"
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value);
            }}
          />
          <CustomersAddCustomerButton />
        </Actions>
      </Nav>
      <CustomersListPeople
        customers={customers ?? []}
        searchText={searchText}
        onCustomerClick={openCustomerModal}
        onCompanyClick={openCompanyModal}
        isLoading={!searchText && loading && !customers.length}
        hasNextPage={hasNextPage}
        loadMore={fetchNextPage}
        actionsCellSize={50}
      />
    </Tabs>
  );
};
