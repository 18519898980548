import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const EmailIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 21 21" color="white" xmlns="http://www.w3.org/2000/svg">
    <rect x="7.62939e-06" width="21" height="21" rx="4.28571" fill="url(#paint0_linear_4179_119919)" />
    <g filter="url(#filter0_d_4179_119919)">
      <path d="M10.5135 11.2333C10.7745 11.2333 11.0355 11.1078 11.2965 10.8569L16.8452 5.59394C16.6112 5.36465 16.1792 5.25 15.5492 5.25H5.28882C4.7443 5.25 4.36854 5.35383 4.16153 5.5615L9.73723 10.8569C9.99824 11.1078 10.257 11.2333 10.5135 11.2333ZM3.66876 14.796L8.19143 10.5195L3.64851 6.22342C3.54951 6.39648 3.50001 6.69283 3.50001 7.11248L3.50001 13.881C3.50001 14.318 3.55626 14.623 3.66876 14.796ZM5.45758 15.75H15.7112C16.2512 15.75 16.627 15.6483 16.8385 15.445L12.2416 11.0905L11.8028 11.5059C11.3843 11.8909 10.9545 12.0834 10.5135 12.0834C10.0725 12.0834 9.64498 11.8909 9.23096 11.5059L8.7922 11.0905L4.20203 15.445C4.43604 15.6483 4.85456 15.75 5.45758 15.75ZM17.3515 14.7831C17.4505 14.61 17.5 14.3093 17.5 13.881L17.5 7.11248C17.5 6.70581 17.4528 6.41811 17.3583 6.24938L12.8491 10.5195L17.3515 14.7831Z" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_d_4179_119919"
        x="2.07144"
        y="5.25"
        width="16.8571"
        height="13.3571"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1.42857" />
        <feGaussianBlur stdDeviation="0.714286" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4179_119919" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4179_119919" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_4179_119919" x1="10.5" y1="0" x2="10.5" y2="21" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4058F2" />
        <stop offset="1" stopColor="#1F8DED" />
      </linearGradient>
    </defs>
  </Svg>
);
