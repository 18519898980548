import { Section, SectionTitle, Info } from '../SettingsAPI.styles';
import { ApiKeyForm } from './ApiKeyForm';
import { ApiKeyModal } from './ApiKeyModal';
import { ApiKeyRemoveModal } from './ApiKeyRemoveModal';
import { ApiKeysList } from './ApiKeysList';

export const ApiKeysSection = () => (
  <Section>
    <header>
      <SectionTitle>
        Personal API keys
      </SectionTitle>

      <Info>
        You can create personal API keys for accessing Cycle’s GraphQL API.
      </Info>
    </header>

    <ApiKeysList />
    <ApiKeyForm />
    <ApiKeyModal />
    <ApiKeyRemoveModal />
  </Section>
);
