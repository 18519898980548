import { AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';

import { useVerifyQuotes } from 'src/reactives/docRightPanel.reactive';

import { Wrapper, Container, Content } from './VerifyQuotes.styles';

export const VerifyQuotesContainer = ({
  fullWidth, children,
}: { fullWidth: boolean; children: ReactNode }) => {
  const isVerifyQuotesOpen = useVerifyQuotes().isOpen;
  return (
    <AnimatePresence>
      {isVerifyQuotesOpen && (
        <Wrapper
          {...(fullWidth ? {
            style: {
              position: 'absolute',
              inset: 0,
            },
            variants: {
              visible: {
                opacity: 1,
              },
              hidden: {
                opacity: 0,
              },
            },
          } : {
            variants: {
              visible: {
                opacity: 1,
                width: 'auto',
              },
              hidden: {
                opacity: 0,
                width: 0,
              },
            },
          })}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Container
            style={{
              width: fullWidth ? '100%' : '480px',
            }}
          >
            <Content>
              {children}
            </Content>
          </Container>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};
