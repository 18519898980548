import { ForgotPasswordDocument } from '@cycle-app/graphql-codegen';
import { Button, Input } from '@cycle-app/ui';
import { GoogleIcon } from '@cycle-app/ui/icons';
import { emailRegex, ERROR_CODE } from '@cycle-app/utilities';
import { useState, useEffect } from 'react';
import { UnpackNestedValue, useForm, UseFormRegisterReturn } from 'react-hook-form';

import { PageId, routing } from 'src/constants/routing.constant';
import { GOOGLE_SSO_URL } from 'src/constants/sso.constants';
import { useLogin } from 'src/hooks/user/useLogin';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setAuth } from 'src/reactives/auth.reactive';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { getThemeConfig } from 'src/reactives/theme.reactive';
import { isInIframe } from 'src/utils/iframe';
import { defaultPagination } from 'src/utils/pagination.util';

import { AuthLayout } from '../AuthLayout';
import {
  Form,
  Actions,
  ResetPwdHeading,
  ResetPwdHint,
  ResetPwdActions,
  TextButtonStyled,
  Footer,
  Anchor,
  Heading,
  SigInChoice,
  ButtonOutline,
} from './Login.styles';

interface LoginFormData {
  email: string;
  password: string;
}

interface ResetPasswordFormData {
  email: string;
}

type LoginContent = 'signIn' | 'resetPwd' | 'resetPwdSuccessfull' | 'emailSignIn';

const emailValidationOptions = {
  required: 'Email is required',
  pattern: {
    value: emailRegex,
    message: 'Email format is incorrect',
  },
};

const Login = () => {
  const {
    login,
    isLoading: loginLoading,
  } = useLogin();
  const [forgotPassword, { loading: forgotPwdLoading }] = useSafeMutation(ForgotPasswordDocument);
  const {
    handleSubmit: handleLoginFormSubmit,
    register: loginRegister,
    formState: loginFormState,
    setError: setLoginError,
  } = useForm<LoginFormData>();
  const {
    handleSubmit: handleResetPwdSubmit,
    register: resetPwdRegister,
    formState: resetPwdFormState,
    reset: resetForgotPwdForm,
  } = useForm<ResetPasswordFormData>();

  const [content, setContent] = useState<LoginContent>('signIn');

  useEffect(() => {
    if (content !== 'resetPwd') {
      resetForgotPwdForm();
    }
  }, [content]);

  return (
    <AuthLayout>
      <div>
        {content === 'signIn' && renderSignIn()}
        {content === 'emailSignIn' && renderEmailSignIn()}
        {content === 'resetPwd' && renderResetPwdForm()}
        {content === 'resetPwdSuccessfull' && renderResetPwdSent()}
      </div>
    </AuthLayout>
  );

  function renderEmailSignIn() {
    return (
      <Form onSubmit={handleLoginFormSubmit(onSubmitLogin)}>
        {renderEmailInput({
          error: loginFormState.errors.email?.message,
          registerProps: loginRegister('email', emailValidationOptions),
        })}

        <Input
          id="password"
          label="Password"
          type="password"
          placeholder="Type your password"
          error={loginFormState.errors.password?.message}
          autoComplete="current-password"
          helperSize="S"
          {...loginRegister('password', { required: 'Password is required' })}
        />

        <Actions>
          <TextButtonStyled
            type="button"
            size="M"
            onClick={() => setContent('resetPwd')}
            variant="nospace"
          >
            Forgot password ?
          </TextButtonStyled>

          <Button
            type="submit"
            size="M"
            isLoading={loginLoading}
          >
            Sign in
          </Button>
        </Actions>
        <Button
          onClick={() => setContent('signIn')}
          variant="nospace"
          style={{ margin: '24px auto 0' }}
        >
          Back to login
        </Button>
      </Form>
    );
  }

  function renderSignIn() {
    return (
      <>
        <Heading>Log in</Heading>
        <SigInChoice>
          {!isInIframe() && (
            <ButtonOutline
              size="L"
              variant="outlined-alt"
              onClick={() => {
                window.location.href = GOOGLE_SSO_URL;
              }}
            >
              <GoogleIcon />
              Continue with Google
            </ButtonOutline>
          )}
          <Button
            type="submit"
            size="L"
            onClick={() => setContent('emailSignIn')}
          >
            Continue with work email
          </Button>
          <Footer>
            Don’t have an account ?
            {' '}
            <Anchor
              onClick={() => setOnboarding({ theme: getThemeConfig().colorTheme })}
              to={routing[PageId.GetStarted]}
            >
              Join Cycle now
            </Anchor>
          </Footer>
        </SigInChoice>
      </>
    );
  }

  function renderResetPwdForm() {
    return (
      <>
        <ResetPwdHeading>Reset password</ResetPwdHeading>
        <ResetPwdHint>Please enter your email address and we will send you a password reset link.</ResetPwdHint>
        <Form onSubmit={handleResetPwdSubmit(onSubmitResetPwd)}>
          {renderEmailInput({
            error: resetPwdFormState.errors.email?.message,
            registerProps: resetPwdRegister('email', emailValidationOptions),
          })}

          <ResetPwdActions>
            <Button
              type="button"
              size="M"
              variant="secondary"
              onClick={() => setContent('signIn')}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              size="M"
              isLoading={forgotPwdLoading}
            >
              Reset password
            </Button>
          </ResetPwdActions>
        </Form>
      </>
    );
  }

  function renderResetPwdSent() {
    return (
      <>
        <ResetPwdHeading>Reset password</ResetPwdHeading>
        <ResetPwdHint>
          Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.
        </ResetPwdHint>
        <ResetPwdActions>
          <Button
            type="submit"
            size="M"
            onClick={() => setContent('signIn')}
          >
            Sign in
          </Button>
        </ResetPwdActions>
      </>
    );
  }

  function renderEmailInput({
    error, registerProps,
  }: { error: string | undefined; registerProps: UseFormRegisterReturn }) {
    return (
      <Input
        autoFocus
        id="email"
        label="Email"
        type="text"
        placeholder="Type your email"
        error={error}
        autoComplete="username"
        helperSize="S"
        {...registerProps}
      />
    );
  }

  async function onSubmitLogin(formData: UnpackNestedValue<LoginFormData>) {
    const {
      data, errors,
    } = await login({
      email: formData.email,
      password: formData.password,
      ...defaultPagination,
    });

    if (!data?.login) {
      if (errors?.find(error => error.message === ERROR_CODE.WRONG_CREDENTIALS)) {
        setLoginError('password', {
          message: 'The email or password you entered is incorrect. Please try again.',
        }, {
          shouldFocus: true,
        });
      }
      return;
    }

    setAuth({
      token: data.login.token,
      userId: data.login.me.id,
    });
  }

  async function onSubmitResetPwd(formData: UnpackNestedValue<ResetPasswordFormData>) {
    const { data } = await forgotPassword({
      variables: {
        email: formData.email,
      },
    });

    if (data?.forgotPassword) {
      setContent('resetPwdSuccessfull');
    }
  }
};

export default Login;
