import {
  getDropCursorExtension,
  getHighlightMarkExtension,
  getDetailsExtension,
  getTableExtension,
  getFormattingExtensions,
} from '@cycle-app/editor-extensions';
import { Editor } from '@tiptap/core';
import Collaboration, { CollaborationOptions } from '@tiptap/extension-collaboration';
import CollaborationCursor, { CollaborationCursorOptions } from '@tiptap/extension-collaboration-cursor';
import { ListKeymap } from '@tiptap/extension-list-keymap';

import { getDetailsListExtension } from 'src/editorExtensions/extension-details-list';
import { getTableOfContentExtension } from 'src/editorExtensions/TableOfContent';
import { setEditorIsDragging } from 'src/reactives/editor.reactive';
import { ActionId } from 'src/services/editor/editorActions';

import { audioExtension } from './Audio';
import { getCodeBlockExtension } from './CodeBlock/CodeBlockExtension';
import { disableShortcutsExtension } from './disableShortcutsExtension';
import { getEmojisExtension } from './Emojis/EmojisExtension';
import { getPasteEmojisExtension } from './Emojis/PasteEmojisExtension';
import { getEventHandlerExtension, EventHandlerParams } from './EventHandler/EventHandler';
import { fileExtension } from './File/FileExtension';
import { getGithubIssuesExtension } from './Github/GithubIssuesExtension';
import { getIframelyExtension } from './Iframely/IframelyExtension';
import { getImageExtension } from './Image/ImageExtension';
import { getLinearMentionExtension, linearProjectMentionExtension } from './Linear/LinearMentionExtension';
import { getMentionExtension } from './Mention/MentionExtension';
import { getMentionDocExtension } from './MentionDoc/MentionDocExtension';
import { getNotionMentionExtension } from './Notion/NotionMentionExtension';
import { getPlaceholderExtension } from './Placeholder/PlaceholderExtension';
import { getSlashExtension } from './Slash/SlashExtension';
import { videoExtension } from './Video';

interface EditorExtensionsBaseParams {
  disabledActions: ActionId[];
  isMobile?: boolean;
}

interface FullEditorExtensionsParams extends EditorExtensionsBaseParams {
  collaboration?: Partial<CollaborationOptions>;
  cursors?: Partial<CollaborationCursorOptions>;
  userColor: string;
  onUserMentioned?: (userId: string) => void;
  onPastedFile?: (editor: Editor, file: File) => Promise<void>;
  emptyPlaceholder?: string;
  handlePaste?: EventHandlerParams['handlePaste'];
  disabledShortcuts?: string[];
  isDnDEnabled: boolean;
}

export const fullEditorExtensions = ({
  userColor,
  collaboration,
  cursors,
  onUserMentioned,
  disabledActions,
  onPastedFile,
  emptyPlaceholder,
  isMobile,
  handlePaste,
  disabledShortcuts = [],
  isDnDEnabled,
}: FullEditorExtensionsParams) => {
  return [
    ...getFormattingExtensions({
      starterKit: {
        codeBlock: false,
        dropcursor: false,
        // "@tiptap/extension-collaboration" comes with its own history
        ...collaboration && { history: false },
      },
      onDragStart: () => setEditorIsDragging(true),
      onDragEnd: () => setEditorIsDragging(false),
      isDnDEnabled,
    }),
    getDropCursorExtension({
      color: userColor,
    }),
    ...!disabledActions.includes(ActionId.Image)
      ? [getImageExtension({ onPastedFile })]
      : [],
    // With custom node view
    ...!disabledActions.includes(ActionId.Code) ? [getCodeBlockExtension()] : [],
    getEmojisExtension(),
    getPasteEmojisExtension(),
    getGithubIssuesExtension(),
    getLinearMentionExtension(),
    linearProjectMentionExtension,
    getNotionMentionExtension(),
    // Custom extensions
    fileExtension,
    videoExtension,
    audioExtension,
    getPlaceholderExtension({
      defaultPlaceholder: 'Type \'/\' for commands',
      emptyPlaceholder: emptyPlaceholder ?? 'Type \'/\' for commands, start typing, or pick an item below',
    }),
    getSlashExtension({ isMobile }),
    ...!disabledActions.includes(ActionId.MentionDoc) ? [getMentionDocExtension({
      onDocMentioned: undefined, // TODO
      isMobile,
    })] : [],
    ...!disabledActions.includes(ActionId.MentionUser) ? [getMentionExtension({
      onUserMentioned,
      isMobile,
    })] : [],
    getIframelyExtension(),
    ...collaboration ? [Collaboration.configure({
      ...collaboration,
      field: 'sharedDoc',
    })] : [],
    ...cursors ? [CollaborationCursor.configure(cursors)] : [],
    getHighlightMarkExtension(),
    getEventHandlerExtension({ handlePaste }),
    ...!disabledActions.includes(ActionId.Details) ? getDetailsExtension({ extensions: [getDetailsListExtension()] }) : [],
    ...!disabledActions.includes(ActionId.Table) ? getTableExtension() : [],
    ...!disabledActions.includes(ActionId.TableOfContent) ? getTableOfContentExtension() : [],
    disableShortcutsExtension(disabledShortcuts),
    ListKeymap,
  ];
};

type BasicEditorExtensionsParams = {
  disabledActions: ActionId[];
  userColor: string;
  onUserMentioned?: (userId: string) => void;
  onPastedFile?: (editor: Editor, file: File) => Promise<void>;
  emptyPlaceholder?: string;
  isMobile?: boolean;
};

export const basicEditorExtensions = ({
  userColor,
  disabledActions,
  onPastedFile,
  emptyPlaceholder,
  isMobile,
}: BasicEditorExtensionsParams) => [
  ...getFormattingExtensions({
    starterKit: {
      // putting history: false would remove the tipap warning but would also remove the do / undo shortcuts
      codeBlock: false,
      dropcursor: false,
    },
    isDnDEnabled: false,
  }),
  getDropCursorExtension({
    color: userColor,
  }),

  ...!disabledActions.includes(ActionId.Image)
    ? [getImageExtension({ onPastedFile })]
    : [],

  // With custom node view
  ...!disabledActions.includes(ActionId.Code) ? [getCodeBlockExtension()] : [],
  getEmojisExtension(),
  getPasteEmojisExtension(),
  getGithubIssuesExtension(),
  getLinearMentionExtension(),
  linearProjectMentionExtension,
  getNotionMentionExtension(),
  // Custom extensions
  fileExtension,
  videoExtension,
  audioExtension,
  getPlaceholderExtension({
    defaultPlaceholder: 'Type \'/\' for commands',
    emptyPlaceholder: emptyPlaceholder ?? 'Type \'/\' for commands, start typing, or pick an item below',
  }),
  getSlashExtension({ isMobile }),
  getIframelyExtension(),
];

interface CommentEditorExtensionsParams extends EditorExtensionsBaseParams {
  emptyContentPlaceholder: string;
}

export const commentEditorExtensions = ({
  emptyContentPlaceholder,
  isMobile,
}: CommentEditorExtensionsParams) => {
  return [
    ...getFormattingExtensions({
      starterKit: {
        heading: false,
        codeBlock: false,
        blockquote: false,
      },
      isDnDEnabled: false,
    }),
    getEmojisExtension(),
    // Custom extensions
    getPlaceholderExtension({
      defaultPlaceholder: '',
      emptyPlaceholder: emptyContentPlaceholder,
    }),
    getMentionExtension({
      isMobile,
    }),
    getMentionDocExtension({
      onDocMentioned: undefined,
      isMobile,
    }),
  ];
};

interface ReadonlyEditorExtensionsParams {
  collaboration?: Partial<CollaborationOptions>;
}

export const displayExtensions = ({ collaboration }: ReadonlyEditorExtensionsParams) => [
  ...getFormattingExtensions({
    starterKit: {
      codeBlock: false,
      dropcursor: false,
      gapcursor: false,
      history: false,
    },
    isDnDEnabled: false,
  }),
  getIframelyExtension(),
  getCodeBlockExtension(),
  getEmojisExtension(),
  ...collaboration ? [Collaboration.configure({
    ...collaboration,
    field: 'sharedDoc',
  })] : [],

  getImageExtension(),
  fileExtension,
  videoExtension,
  audioExtension,
  getGithubIssuesExtension(),
  getLinearMentionExtension(),
  linearProjectMentionExtension,
  getNotionMentionExtension(),
  getMentionExtension({
    readOnly: true,
  }),
  getMentionDocExtension({ readOnly: true }),
  getHighlightMarkExtension(),
  ...getDetailsExtension({ extensions: [getDetailsListExtension()] }),
  ...getTableExtension(),
  ...getTableOfContentExtension(),
];
