import aurelien from './avatar-aurelien.png';
import olivier from './avatar-olivier.png';
import shreyas from './avatar-shreyas.png';
import { Container, Author, Name, Content } from './OnboardingQuote.styles';

interface Props {
  user: keyof typeof quotes;
}

const quotes = {
  shreyas: {
    quote: (
      <>
        “Cycle&apos; s vision to build for product management, not product managers, is very appealing.Product management is a team sport.
        The product is not just the Product Manager&apos;s concern.”
      </>
    ),
    avatar: shreyas,
    name: 'Shreyas Doshi',
    job: 'Led & grew products at Stripe, Twitter, Google & Yahoo',
  },
  aurelien: {
    quote: (
      <>
        “We tried them all, no other software beats Cycle. We redesigned our feedback loop to extract concrete
        customer insights in no time with the autopilot feature.
        It&apos;s a no-brainer for any product team.”
      </>
    ),
    avatar: aurelien,
    name: 'Aurélien Georget',
    job: 'CPO @ Strapi',
  },
  olivier: {
    quote: (
      <>
        I love how Cycle connects customer quotes to product requirements – without the busywork.
        Feedback autopilot is one of the smartest applications of AI I&apos;ve seen for product folks.
      </>
    ),
    avatar: olivier,
    name: 'Olivier Godement',
    job: 'Product @ OpenAI',
  },
};

export const OnboardingQuote = ({ user } : Props) => {
  const {
    quote, avatar, name, job,
  } = quotes[user];
  return (
    <Container>
      <Content>
        <div>{quote}</div>
        <Author>
          <img src={avatar} alt="" width={32} height={32} />
          <div>
            <Name>{name}</Name>
            <p>{job}</p>
          </div>
        </Author>
      </Content>
    </Container>
  );
};
