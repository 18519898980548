import {
  Input, Button, Skeleton, typo, CheckboxInput, CustomerAvatar,
} from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { BasicEditor } from 'src/components/Editor/Editors/BasicEditor';

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  input {
    border-radius: 6px;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 12px;
`;

export const FieldLabel = styled.div`
  ${typo.body500}
  padding-bottom: 8px;
`;

export const FormButton = styled(Button).attrs({
  size: 'L',
})`
  padding: 4px 16px;
`;

const fieldCss = css`
  padding: 8px 12px;
  border-radius: 6px;
  border-width: 2px;
  border-color: transparent;
`;

export const FieldInput = styled(Input)`
  input {
    ${fieldCss}
    height: 40px;
  }
`;

export const FieldButton = styled(Button).attrs({
  variant: 'outlined',
  size: 'L',
})`
  ${fieldCss}
  width: 100%;
  height: 40px;
  justify-content: flex-start;
  color: ${p => p.theme.colors.text.primary};
  background-color: ${p => p.theme.colors.input.bgAlt};
  border: 1px solid ${p => p.theme.colors.border.primary};
  &:active {
    outline: none;
  }
  &:disabled {
    cursor: wait;
  }
`;

export const CustomFieldsContainer = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${p => (p.count > 1 ? 2 : 1)}, minmax(0, 1fr));
  gap: 16px;
`;

export const Checkbox = styled(CheckboxInput)`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const SkeletonInput = styled(Skeleton)`
  height: 40px;
`;

export const SkeletonLabel = styled(Skeleton)`
  height: 24px;
  width: 100px;
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  ${typo.headerLight}
  > p {
    max-width: 275px;
  }
`;

export const StyledCustomerAvatar = styled(CustomerAvatar)`
  margin-right: 8px;
`;

export { StyledCustomerAvatar as CustomerAvatar };

export const StyledEditor = styled(BasicEditor)`
  ${fieldCss}
  padding: 0;
  border: 2px solid transparent;
  background: ${p => p.theme.colors.input.bg};

  &:hover {
    background: ${p => p.theme.colors.input.hover};
  }

  :focus-within {
    border: 2px solid hsla(var(--cycle), 0.8);
    background: ${p => p.theme.colors.popup.bg};
  }
`;

export const CreateSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 0 4px;
`;

type CreateButtonProps = {
  hoverDisabled: boolean;
  isSelected: boolean;
};
export const CreateButton = styled(Button) <CreateButtonProps>`
  background-color: ${p => (p.isSelected
    ? p.theme.colors.background.hover
    : p.theme.colors.background.tertiary)};
  color: ${p => p.theme.colors.text.primary};
  padding: 2px 8px;

  &:hover, &:active, &:focus {
    background-color: ${p => p.theme.colors.background.hover};
  }
`;
