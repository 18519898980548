import { ProductUpdateIntegrationsDocument } from '@cycle-app/graphql-codegen';
import { RadioInput, Flex } from '@cycle-app/ui';
import { LinearIcon, CaretIcon } from '@cycle-app/ui/icons';
import { ColorTheme } from '@cycle-app/utilities';
import { useState } from 'react';

import { useSafeMutation } from 'src/hooks';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Footer, NextButton } from '../OnboardingLayout/OnboardingLayout.styles';
import {
  ArrowsLeft, ArrowsRight, Cycle, Icon, IconContainer, Main, StyledRadioInput,
} from './OnboardingStepLinear.styles';

interface Props {
  productId: string;
  shouldBookCall: boolean;
}

export const OnboardingStepLinear = ({
  productId, shouldBookCall,
} : Props) => {
  const [value, setValue] = useState<'y' | 'n' | undefined>(undefined);
  const [updateIntegrations, { loading: isLoading }] = useSafeMutation(ProductUpdateIntegrationsDocument);
  return (
    <OnboardingLayout
      title="Do you track issues on Linear?"
      description="Cycle works with any issue tracker but we have built a unique experience for Linear users."
      aside={(
        <Flex $align="center" $justify="center" $heightFull>
          <Flex $gap={24}>
            <IconContainer $align="center" $justify="center">
              <Icon $justify="center">
                <Cycle fill={ColorTheme.Cycle} />
              </Icon>
            </IconContainer>
            <Flex $column $gap={8}>
              <ArrowsLeft>
                <CaretIcon direction="left" />
                <CaretIcon direction="left" />
                <CaretIcon direction="left" />
              </ArrowsLeft>
              <ArrowsRight>
                <CaretIcon direction="right" />
                <CaretIcon direction="right" />
                <CaretIcon direction="right" />
              </ArrowsRight>
            </Flex>
            <IconContainer $align="center" $justify="center">
              <Icon $justify="center">
                <LinearIcon height="54px" width="54px" />
              </Icon>
            </IconContainer>
          </Flex>
        </Flex>
      )}
      main={(
        <>
          <Main
            onSubmit={async e => {
              e.preventDefault();
              if (isLoading) return;
              if (value === 'y') {
                await updateIntegrations({
                  variables: {
                    productId,
                    integrations: ['linear'],
                  },
                });
              }
              setOnboarding({ screen: shouldBookCall ? LightOnboardingScreen.BookCall : LightOnboardingScreen.Done });
            }}
          >
            <StyledRadioInput
              checked={value === 'y'}
              id="y"
              label={<>Yes, we&apos;re on Linear 😍</>}
              name="radio"
              onChange={() => setValue('y')}
              value="y"
            />
            <RadioInput
              checked={value === 'n'}
              id="n"
              label={<>No, we use another issue tracker... 🥲</>}
              name="radio"
              onChange={() => setValue('n')}
              value="n"
            />
            <Footer style={{ marginTop: '32px' }}>
              <NextButton
                type="submit"
                size="M"
                isLoading={isLoading}
                disabled={value === undefined}
              >
                Next
              </NextButton>
            </Footer>
          </Main>
        </>
      )}
    />
  );
};
