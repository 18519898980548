import { Output } from '@cycle-app/front/src/types/editor.types';
import { extractMentionedUsers } from '@cycle-app/front/src/utils/editor/editor.utils';
import { getOS } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';
import { useEditor, EditorContent } from '@tiptap/react';
import React, { FC, useEffect, useCallback, useRef } from 'react';

import { EditorContextProvider } from 'src/contexts/editorContext';
import { commentEditorExtensions } from 'src/editorExtensions/editorExtensions';
import { useProduct } from 'src/hooks';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import { useForcedFocus } from 'src/hooks/editor/useForcedFocus';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { ActionId } from 'src/services/editor/editorActions';
import { clearColors } from 'src/utils/html.utils';

import { CommentEditorContainer, CommentEditorContent } from './Editor.styles';

const os = getOS();

export interface CommentEditorOutput extends Output {
  editor: Editor;
  mentionedUserIds: string[];
}

interface Props {
  className?: string;
  content?: string;
  placeholder?: string;
  autofocus?: boolean;
  onUpdate?: (output: CommentEditorOutput) => void;
  disabledActions?: ActionId[];
  maxHeight?: number;
  isReadOnly?: boolean;
  onSubmit?: VoidFunction;
}

export const CommentEditor: FC<React.PropsWithChildren<Props>> = ({
  className,
  autofocus = false,
  maxHeight,
  onUpdate,
  content = '',
  placeholder = '',
  disabledActions = [],
  isReadOnly = false,
  onSubmit,
}) => {
  const { onError } = useEditorLogic();
  const isMobile = useIsMobile();

  const handleUpdate = useCallback((e: Editor) => {
    if (!onUpdate) return;
    const html = e.getHTML();
    onUpdate({
      html,
      json: e.getJSON(),
      editor: e,
      mentionedUserIds: extractMentionedUsers(html),
    });
  }, [onUpdate]);

  const editorRef = useRef<Editor | null>(null);

  const editor = useEditor({
    immediatelyRender: true,
    extensions: commentEditorExtensions({
      emptyContentPlaceholder: placeholder,
      disabledActions,
      isMobile,
    }),
    autofocus,
    content,
    onCreate({ editor: e }) {
      handleUpdate(e);
    },
    onUpdate({ editor: e }) {
      handleUpdate(e);
      if (e.isFocused) {
        e.commands.scrollIntoView();
      }
    },
    editorProps: {
      /* Override the default behavior for Enter.
      It stops ProseMirror from calling any other handlers for the given input.
      See https://prosemirror.net/docs/ref/#view.EditorProps */
      handleKeyDown: (_view, e) => {
        if (e.key !== 'Enter') return false;
        if (e.shiftKey || e.altKey) return false;
        if (e.ctrlKey && os === 'macOS') return false;
        if (e.metaKey && os === 'Windows') return false;
        if (editorRef.current?.isActive('bulletList')) return false;
        if (editorRef.current?.isActive('orderedList')) return false;
        if (editorRef.current?.isActive('taskList')) return false;

        // Check if there is an active suggestion
        const editorState = editorRef.current?.state;
        if (editorState) {
          const stateKeys = Object.keys(editorState) as Array<keyof EditorState>;
          for (const key of stateKeys) {
            if (key.startsWith('suggestion')) {
              // TODO: Type editor state
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const pluginState = editorState[key] as any;
              if (pluginState?.active) return false;
            }
          }
        }

        onSubmit?.();
        return true;
      },
      transformPastedHTML: clearColors,
    },
    editable: !isReadOnly,
  });

  useEffect(() => {
    if (editor) editorRef.current = editor;
  }, [editor]);

  useForcedFocus({
    editor,
    isEnabled: autofocus,
    timeout: 0,
  });

  // This effect is used to synchronize the content between DocComments and DocPanelRightPanel
  useEffect(() => {
    if (!editor || editor.isFocused || editor.getHTML() === content || editor?.isEditable) return;
    queueMicrotask(() => {
      editor.commands.setContent(content);
    });
    handleUpdate(editor);
  }, [content, editor, handleUpdate]);

  const { product } = useProduct();

  if (!editor) {
    return null;
  }

  return (
    <EditorContextProvider
      editor={editor}
      onError={onError}
      nbAiQueries={product?.nbAiQueries}
    >
      <CommentEditorContainer className={className}>
        <CommentEditorContent
          className="content-editor"
          maxHeight={maxHeight}
        >
          <EditorContent editor={editor} />
        </CommentEditorContent>
      </CommentEditorContainer>
    </EditorContextProvider>
  );
};
