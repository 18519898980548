import { PageId } from 'src/constants/routing.constant';
import { useNavigate } from 'src/hooks/useNavigate';
import { getLastInsightBoard } from 'src/reactives/lastView.reactive';

import { useInsightSection } from './useInsightSection';

export const useGetInsightDefaultUrl = () => {
  const { getUrl } = useNavigate();
  const {
    boardSlug, boardId,
  } = getLastInsightBoard();
  const { boards } = useInsightSection();
  return () => (boards.find(board => board.id === boardId)
    ? getUrl(PageId.InsightView, { boardSlug })
    : getUrl(PageId.Insight));
};
