import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { EmojiData, EmojiInput, Button, CheckboxInput } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { ERROR_CODE } from '@cycle-app/utilities';
import { FC, useState } from 'react';
import { Controller } from 'react-hook-form';

import { PortalModalStyled, Header, Title, CloseButtonStyled, Actions } from 'src/components/DialogModal/DialogModal.styles';
import useDoctypeParentsMutations from 'src/hooks/api/mutations/useDoctypeParentsMutations';
import useDoctypesMutations, { Options } from 'src/hooks/api/mutations/useDoctypesMutations';
import { useEnhancedForm, ErrorMap } from 'src/hooks/form/useEnhancedForm';
import { useInsightDocType } from 'src/reactives/docTypes.reactive';
import { isParentOfInsight } from 'src/utils/docType.util';
import { DEFAULT_EMOJI } from 'src/utils/emoji.util';

import { Form, Row, InputStyled } from './DoctypesEditCommonModal.styles';

export type DoctypeEditCommonFormData = Pick<DoctypeFragment, 'name' | 'emoji' | 'description'>;

interface Props extends Options {
  onHide: VoidFunction;
  doctype?: DoctypeFragment;
  initialName?: string;
  onCreated?: (docType: DoctypeFragment) => void;
  linkToInsights?: boolean;
}

// We do not have an unified format for business errors from the server (yet !)
// so we need to filter only expected errors.
const mutationErrorsMap: ErrorMap<DoctypeEditCommonFormData>[] = [
  {
    code: ERROR_CODE.DOCTYPE_ALREADY_EXISTS,
    fieldName: 'name',
    renderMessage: (formValues) => `A doc type with name "${formValues.name}" already exists`,
  },
];

export const DoctypesEditCommonModal: FC<React.PropsWithChildren<Props>> = ({
  onHide,
  doctype,
  redirectOnCreate,
  initialName = '',
  onCreated,
  linkToInsights: defaultLinkToInsights = false,
}) => {
  const [linkToInsights, setLinkToInsights] = useState(defaultLinkToInsights || isParentOfInsight(doctype));
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors: formErrors },
    displayFieldsErrors,
  } = useEnhancedForm<DoctypeEditCommonFormData>({
    defaultValues: {
      emoji: doctype?.emoji || DEFAULT_EMOJI,
      description: doctype?.description || '',
      name: doctype?.name || initialName,
    },
    reValidateMode: 'onChange',
  });
  const {
    loading, addDoctype, updateDoctype,
  } = useDoctypesMutations(doctype?.id, { redirectOnCreate });

  const insightDocType = useInsightDocType();
  const {
    addDoctypeParent, removeDoctypeParent, loading: loadingParent,
  } = useDoctypeParentsMutations(insightDocType);

  async function onSubmit(data: DoctypeEditCommonFormData) {
    const updateResult = doctype ? await updateDoctype(data) : null;
    const addResult = doctype ? null : await addDoctype(data);
    const result = updateResult ?? addResult;

    if (result?.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap);
      return;
    }

    const createdDocType = addResult?.data?.addNewDoctype;
    if (createdDocType) {
      onCreated?.(createdDocType);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (linkToInsights) addDoctypeParent(createdDocType.id);
    }

    if (doctype) {
      if (!isParentOfInsight(doctype) && linkToInsights) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        await addDoctypeParent(doctype.id);
      } else if (isParentOfInsight(doctype) && !linkToInsights) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        await removeDoctypeParent(doctype.id);
      }
    }

    onHide();
  }

  const title = doctype ? 'Edit doc type' : 'Create doc type';

  return (
    <PortalModalStyled hide={onModalHide}>
      <Header>
        <Title>{title}</Title>
        <CloseButtonStyled size="L" onClick={onModalHide}>
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Controller
            name="emoji"
            control={control}
            render={({
              field: {
                value,
                onChange,
              },
            }) => {
              return (
                <EmojiInput
                  emoji={value}
                  label="Icon"
                  onSelect={(data: EmojiData) => {
                    if ('id' in data) {
                      onChange(data.id);
                    }
                  }}
                />
              );
            }}
          />
          <InputStyled
            id="doctTypedEdit-name"
            label="Doc type name"
            placeholder="Your doc type name"
            autoFocus
            {...register('name', {
              maxLength: {
                value: 25,
                message: 'You can\'t add more than 25 characters.',
              },
              required: 'You must have at least one character.',
            })}
            error={formErrors.name?.message}
          />
        </Row>
        <InputStyled
          id="doctTypedEdit-description"
          label="Description"
          placeholder="Your doc type description"
          {...register('description')}
        />

        <CheckboxInput
          id="doctTypedEdit-linkToInsights"
          label="Link to insights & releases"
          disabled={defaultLinkToInsights}
          checked={linkToInsights}
          onChange={e => setLinkToInsights(e.target.checked)}
        />

        <Actions>
          <Button
            type="button"
            size="M"
            variant="secondary"
            disabled={loading || loadingParent}
            onClick={onModalHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="M"
            isLoading={loading || loadingParent}
            disabled={!!Object.keys(formErrors).length}
          >
            Save
          </Button>
        </Actions>
      </Form>
    </PortalModalStyled>
  );

  function onModalHide() {
    reset();
    onHide();
  }
};
