import { TextSecondary } from '@cycle-app/ui';
import { motion } from 'framer-motion';

import { Chat } from '../OnboardingLayout/Chat';
import { SubTitle, Title } from '../OnboardingLayout/OnboardingLayout.styles';
import {
  Content, StyledButton, StyledLogo, Body, LogoRotate, Sentence,
} from './Welcome.styles';

const LIFT_DURATION = 1.7;
const LIFT_DELAY = 1;

type OnboardingStepWelcomeProps = {
  showOnboardingSentence: boolean;
  products: { slug: string }[];
  onContinue: VoidFunction;
};

export const Welcome = ({
  showOnboardingSentence, products, onContinue,
}: OnboardingStepWelcomeProps) => {
  const hasProduct = products.length > 0;
  return (
    <Content
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div
        initial={{
          translateY: 70,
        }}
        animate={{
          translateY: 0,
          transition: {
            delay: LIFT_DELAY,
            duration: LIFT_DURATION,
          },
        }}
      >
        <LogoRotate
          initial={{
            rotate: '-190deg',
          }}
          animate={{
            rotate: '-1deg',
          }}
          transition={{
            duration: LIFT_DELAY,
            ease: [0.075, 0.82, 0.165, 1],
          }}
        >
          <StyledLogo size={100} />
        </LogoRotate>
      </motion.div>
      <Body
        initial={{
          translateY: 40,
          opacity: 0,
          marginBottom: 40,
        }}
        animate={{
          translateY: 0,
          opacity: 1,
          transition: {
            delay: LIFT_DELAY,
            duration: LIFT_DURATION,
          },
          marginBottom: 0,
        }}
      >
        <Title>{hasProduct ? 'Welcome to Cycle' : 'Start your 3-day free trial'}</Title>
        <SubTitle>{hasProduct ? 'Your product feedback, on autopilot' : 'Set up your workspace and book your personalized onboarding in < 2 minutes'}</SubTitle>
        <StyledButton
          onClick={onContinue}
          size="M"
        >
          {hasProduct ? <>Let&apos;s start</> : 'Continue'}
          {showOnboardingSentence && (
            <Sentence>
              <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M23.3874 26.5282C21.1638 17.7026 19.0168 12.2284 10.9842 7.70582C9.03468 6.60818 6.0141 4.58733 3.65332 4.65467C2.31237 4.69292 1.06862 3.75782 2.07667 5.59533C2.90684 7.10857 4.02967 9.39933 5.37099 10.5248C5.85541 10.9313 2.17309 5.68748 0.985984 4.13882C-0.531713 2.1589 10.6486 1.63449 12.5258 1.47029" strokeWidth="1.2" strokeLinecap="round" />
              </svg>
              Exceptionally, you&apos;ll get access to a white glove onboarding as we&apos;re iterating on a whole new self-serve experience.
            </Sentence>
          )}
        </StyledButton>
        {!hasProduct && <TextSecondary $mt="24px" $typo="caption">No credit card required, nothing to install.</TextSecondary>}
      </Body>
      <Chat />
    </Content>
  );
};
