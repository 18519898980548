import { ComponentProps } from 'react';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { useSortedDocTypesFromCache } from 'src/hooks/useSortedDocTypes';
import { isCustom } from 'src/utils/docType.util';

import { DocTypes } from './SettingsWorkflows.styles';

type Props = ComponentProps<typeof DocTypes> & {
  ids?: string[];
  placeholder: string;
  currentDocTypeId?: string;
};

export const LinkedDocTypesLabel = ({
  ids, placeholder, currentDocTypeId, ...props
}: Props) => {
  const docTypes = useSortedDocTypesFromCache(ids, currentDocTypeId).filter(isCustom);
  if (!docTypes.length) return <>{placeholder}</>;

  return (
    <DocTypes {...props}>
      {docTypes
        .slice(0, 4)
        .map(d => <DocTypeIcon key={d.id} doctype={d} size={14} />)}
      {docTypes.length > 4 && `+${docTypes.length - 4}`}
    </DocTypes>
  );
};
