import { FilterGroup } from '@luzmo/react-embed';

import { LocalKey } from 'src/types/localStorage.types';
import { make } from 'src/utils/reactives.util';

type State = {
  lastRefreshRequested: number | null;
  requestId: string;
  progress: number;
  aiSuggestion: string;
  isAiGenerating: boolean;
  dashboardManualFilters: FilterGroup[];
  mutationId: string | null;
  dashboardId: string | null;
  token: string | null;
  key: string | null;
};

const {
  hookState,
  setValue,
  getValue,
  hookValue,
} = make<State>({
  defaultState: {
    lastRefreshRequested: null,
    requestId: '',
    progress: 0,
    aiSuggestion: '',
    isAiGenerating: false,
    dashboardManualFilters: [],
    dashboardId: null,
    mutationId: null,
    token: null,
    key: null,
  },
  localKey: LocalKey.CustomerDashboardRefresh,
});

export const useCustomerDashboardState = hookState;
export const useCustomerDashboardStateValue = hookValue;
export const setCustomerDashboard = setValue;
export const getCustomerDashboard = getValue;

export const resetCustomerDashboardRefresh = () => {
  setCustomerDashboard({
    requestId: '',
    progress: 0,
    aiSuggestion: getValue().aiSuggestion,
  });
};
