import { ChangeAttributeColorDocument } from '@cycle-app/graphql-codegen';
import { NuancePicker } from '@cycle-app/ui';
import { UnlinkIcon, TrashIcon } from '@cycle-app/ui/icons';

import { AttributeOptionsDropdown } from 'src/components/AttributeOptionsDropdown';
import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useOptimizedBooleanState } from 'src/hooks';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { Layer } from 'src/types/layers.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import { SettingsAttributeEditModal } from '../../SettingsAttributes/SettingsAttributeEditModal';
import { useDeleteAttributeModal } from '../../SettingsAttributes/useDeleteAttributeModal';
import {
  PropertyLine,
  PropertyName,
  StyledColorPicker,
  PropertyIcon,
  NameButton,
} from './SettingsDocTypeProperties.styles';

export const SettingsDocTypePropertyLine = ({
  property,
  unlink,
}: {
  property: AttributeDefinitionsNode;
  unlink: VoidFunction;
}) => {
  const [updateAttributeColor] = useSafeMutation(ChangeAttributeColorDocument);
  const {
    openDeleteAttributeModal, deleteAttributeModal,
  } = useDeleteAttributeModal(property);

  const [isEditionModalOpened, { toggleCallback: toggleEditionModal }] = useOptimizedBooleanState(false);

  const type = getCustomAttributeTypeData(property.__typename);

  return (
    <>
      <PropertyLine key={property.id}>
        <DotsMenuLayer
          layer={Layer.DropdownModal}
          vertical
          placement="bottom-start"
          options={[
            {
              label: 'Unlink',
              value: 'Unlink',
              icon: <UnlinkIcon />,
              onSelect: unlink,
            },
            {
              label: 'Delete',
              value: 'Delete',
              variant: 'danger' as const,
              icon: <TrashIcon />,
              onSelect: openDeleteAttributeModal,
            },
          ]}
        />

        <NuancePicker
          color={property.color}
          // TODO: Mutation not yet implemented on the backend, check that it works well once done
          onClick={(newColor) => updateAttributeColor({
            variables: {
              attributeId: property.id,
              color: newColor,
            },
          })}
          placement="bottom-start"
        >
          <StyledColorPicker color={property.color} />
        </NuancePicker>

        <PropertyIcon>{type.icon}</PropertyIcon>
        <PropertyName>
          <NameButton type="button" size={14} onClick={toggleEditionModal}>
            {property.name}
          </NameButton>
        </PropertyName>

        {'values' in property && (
          <AttributeOptionsDropdown attribute={property} layer={Layer.DropdownModal} />
        )}
      </PropertyLine>

      {deleteAttributeModal}

      {isEditionModalOpened && (
        <SettingsAttributeEditModal attribute={property} onHide={toggleEditionModal} />
      )}
    </>
  );
};
