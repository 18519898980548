import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const Text = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 4px;
`;

export const Fields = styled.div`
  padding: 16px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: ${p => p.theme.colors.background.secondaryElevated};
`;

export const Field = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export const StyledEditContent = styled(Button)`
  margin-top: 26px;
  gap: 6px;

  svg {
    width: 14px;
    height: 14px;
  }
`;
