import { BulkCreateDocDocument, MutationAddNewDocArgs, namedOperations } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useProductBase } from 'src/hooks/api/useProduct';
import { usePageId } from 'src/hooks/usePageId';
import useSafeMutation from 'src/hooks/useSafeMutation';

import { useUpdateChildCache } from '../api/cache/cacheHierarchy';

type CreateDocsParams = {
  assigneeId?: string;
  attributes?: MutationAddNewDocArgs['attributes'];
  customerId?: string;
  docTypeId: string;
  parentDocId?: string;
  parentId?: string;
  sourceId?: string;
  titles: string[];
};

export const useDocsCreate = () => {
  const page = usePageId();
  const productId = useProductBase()?.id ?? '';
  const updateChild = useUpdateChildCache();

  const [bulkCreateDocs, { loading }] = useSafeMutation(BulkCreateDocDocument, {
    refetchQueries: () => (page !== PageId.DocFullPage ? [namedOperations.Query.boardWithConfig] : []),
  });

  const createDocs = useCallback(async ({
    assigneeId,
    customerId,
    docTypeId,
    attributes,
    titles,
    sourceId,
    parentId,
  }: CreateDocsParams) => {
    const newDocs = titles.map(title => ({
      id: btoa(`Doc_${crypto.randomUUID()}`),
      doctypeId: docTypeId,
      isDraft: false,
      productId,
      assignee: assigneeId || undefined,
      customer: customerId ? { id: customerId } : undefined,
      parentId,
      source: sourceId ? { sourceId } : undefined,
      attributes: attributes || undefined,
      title,
    }));

    await bulkCreateDocs({
      variables: {
        newDocs,
        productId,
      },
    });

    return newDocs.map(d => d.id);
  }, [bulkCreateDocs, productId, updateChild]);

  return {
    createDocs,
    isLoading: loading,
  };
};
