import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const SumIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8333 2.16666H2.16667C1.85725 2.16666 1.5605 2.28957 1.34171 2.50837C1.12292 2.72716 1 3.0239 1 3.33332C1 3.64274 1.12292 3.93949 1.34171 4.15828C1.5605 4.37707 1.85725 4.49999 2.16667 4.49999H13.8333C14.1428 4.49999 14.4395 4.37707 14.6583 4.15828C14.8771 3.93949 15 3.64274 15 3.33332C15 3.0239 14.8771 2.72716 14.6583 2.50837C14.4395 2.28957 14.1428 2.16666 13.8333 2.16666Z" />
    <path d="M11.5 6.83332H4.5C4.19058 6.83332 3.89383 6.95624 3.67504 7.17503C3.45625 7.39383 3.33333 7.69057 3.33333 7.99999C3.33333 8.30941 3.45625 8.60616 3.67504 8.82495C3.89383 9.04374 4.19058 9.16666 4.5 9.16666H11.5C11.8094 9.16666 12.1062 9.04374 12.325 8.82495C12.5437 8.60616 12.6667 8.30941 12.6667 7.99999C12.6667 7.69057 12.5437 7.39383 12.325 7.17503C12.1062 6.95624 11.8094 6.83332 11.5 6.83332Z" />
    <path d="M6.83333 11.5H9.16667C9.47609 11.5 9.77283 11.6229 9.99162 11.8417C10.2104 12.0605 10.3333 12.3572 10.3333 12.6667C10.3333 12.9761 10.2104 13.2728 9.99162 13.4916C9.77283 13.7104 9.47609 13.8333 9.16667 13.8333H6.83333C6.52391 13.8333 6.22717 13.7104 6.00838 13.4916C5.78958 13.2728 5.66667 12.9761 5.66667 12.6667C5.66667 12.3572 5.78958 12.0605 6.00838 11.8417C6.22717 11.6229 6.52391 11.5 6.83333 11.5Z" />
  </Svg>
);
