import { ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

import { DocAttributes } from 'src/components/DocAttributes';

export const AddPropertyButton = styled(ActionButton)`
  opacity: ${p => (p.forceFocus ? 1 : 0)};
  transition: opacity .2s ease-in-out;
  height: 20px;
  width: 20px;

  svg {
    height: 12px;
    width: 12px;
  }
`;

export const StyledDocAttributes = styled(DocAttributes)`
  overflow: hidden;

  &:hover {
    ${AddPropertyButton} {
      opacity: 1;
    }
  }
`;
