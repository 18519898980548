import { CreateDocPosition, DocBaseFragment, DoctypeRelativeFragment, PublishDocDocument, ViewType } from '@cycle-app/graphql-codegen';
import { Shortcut, Shortcuts, Tooltip, Emoji } from '@cycle-app/ui';
import { CaretIcon, WarningIcon } from '@cycle-app/ui/icons';
import { getDocSlug, nodeToArray, useResizeObserver } from '@cycle-app/utilities';
import {
  useMemo,
  FC,
  FormEvent,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import DoctypesOptions from 'src/components/DoctypesOptions/DoctypesOptions';
import { PageId, routing } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useOptimizedBooleanState, useProductDoctypesFull, useRouteMatch } from 'src/hooks';
import { useUpdateDocTitle } from 'src/hooks/api/mutations/updateDocHooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useCreateDoc } from 'src/hooks/api/mutations/useCreateDoc';
import { usePossibleDoctypes } from 'src/hooks/boards/usePossibleDoctypes';
import { useFirstStatusinView } from 'src/hooks/useFirstStatusinView';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useNavigate } from 'src/hooks/useNavigate';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setBoardGroupState } from 'src/reactives/boardGroup.reactive';
import { setBoardNewDocPositionState, getBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { getNewDocTitle, setNewDocTitle, resetNewDocTitle } from 'src/reactives/boardNewDoc/newDocTitle.reactive';
import { useLastDoctypeIdUsed } from 'src/reactives/lastView.reactive';
import { getActiveProductTour } from 'src/reactives/productTours.reactive';
import { ShortcutDocDraft } from 'src/types/shortcuts.types';
import { getDocFromCache } from 'src/utils/cache.utils';
import { getDocTypeName, showDocTypeEmoji } from 'src/utils/docType.util';

import { useBoardConfig } from '../../contexts/boardConfigContext';
import { addDocAutomationWatch } from '../../reactives/docAutomationWatch.reactive';
import { doctypeHasAutomation } from '../../utils/doctype.automation.util';
import { DocItemDraftAttributes } from '../DocItem/DocItemDraftAttributes';
import { DocItemDraftParent } from '../DocItem/DocItemDraftParent';
import {
  DraggableCardStyled,
  Form,
  Actions,
  Buttons,
  DoctypeContainer,
  DocTitleEditable,
  WarningContainer,
  StyledButton,
  DoctypeButton,
  DocTitleContainer,
} from './NewDoc.styles';

export interface NewDocProps {
  className?: string;
  key?: string;
  groupId?: string; // To use when board grouped by is available
  statusId?: string;
  from?: 'top' | 'bottom';
  viewType: ViewType;
  possibleDoctypesId?: Array<string>;
  parentId?: string;
  placeholder?: ReactNode;
  goDown?: VoidFunction;
  goUp?: VoidFunction;
  path?: string;
  onClose?: VoidFunction;
}

export const NewDoc: FC<React.PropsWithChildren<NewDocProps>> = ({
  className = '',
  from,
  groupId,
  statusId,
  viewType,
  possibleDoctypesId,
  parentId,
  placeholder = 'Untitled',
  goDown,
  goUp,
  path,
  onClose,
}) => {
  const [withLinearChecked, { setValueCallback: setWithLinear }] = useOptimizedBooleanState(true);
  const matchFeedback = !!useRouteMatch(routing[PageId.InboxView]);
  const containerMeasure = useResizeObserver<HTMLDivElement>();
  const { navigate } = useNavigate();
  const lastDoctypeIdUsed = useLastDoctypeIdUsed();
  const firstStatusinView = useFirstStatusinView();
  const {
    addNewdocCache,
    createDoc,
    hasDefaultAttributes,
    loading,
  } = useCreateDoc({
    from,
    groupId,
    statusId: statusId ?? firstStatusinView?.id,
  });
  const isDraftEditable = useBoardConfig(ctx => ctx.isDraftEditable);

  const [draftDocId, setDraftDocId] = useState('');
  const {
    updateDocTitle, loading: isTitleLoading,
  } = useUpdateDocTitle();
  const changeDocParent = useChangeDocParent();
  const [publishDoc, { loading: isPublishLoading }] = useSafeMutation(PublishDocDocument, {
    optimisticResponse: {
      publishDoc: {
        isDraft: false,
        id: 'temp-id',
        title: getNewDocTitle().title,
        publicId: 0,
      },
    },
  });

  const autoScrollToView = useCallback(() => {
    if (!containerMeasure.ref.current) return;
    scrollIntoView(containerMeasure.ref.current, {
      scrollMode: 'if-needed',
      block: viewType === 'KANBAN' && from === 'bottom' ? 'start' : 'end',
    });
  }, [viewType, containerMeasure.ref, from]);

  useEffect(() => {
    autoScrollToView();
  }, [autoScrollToView]);

  const { getPossibleDoctypes } = usePossibleDoctypes();
  const possibleDoctypes = useMemo(() => getPossibleDoctypes({
    groupId,
    possibleDoctypesId,
  }), [getPossibleDoctypes, groupId, possibleDoctypesId]);
  const [doctype, setDoctype] = useState(possibleDoctypes.find(d => d.id === lastDoctypeIdUsed) || possibleDoctypes[0]);
  const { doctypes } = useProductDoctypesFull();
  const canHaveParent = useMemo(() => (doctypes.find(d => d.id === doctype?.id)?.parents?.edges || []).length > 0, [doctypes, doctype]);
  const doctypeSelectDisabled = possibleDoctypes.length <= 1;

  const docParentsFromBoardConfig = useBoardConfig(ctx => ctx.docParents);

  const getDocParentsByDoctype = useCallback((doctypeId: string) => (
    docParentsFromBoardConfig
      .filter(docParent => (
        nodeToArray(docParent.doctype.children).find(doctypeChild => doctypeChild.id === doctypeId)
      ))
  ), [docParentsFromBoardConfig]);

  const docParents = useMemo(() => {
    if (!doctype?.id) return [];
    return getDocParentsByDoctype(doctype.id);
  }, [doctype?.id, getDocParentsByDoctype]);

  const [draftDocParentId, setDraftDocParentId] = useState(parentId || docParents[0]?.id || '');

  const onParentSelected = async (selectedParentId: string) => {
    setDraftDocParentId(selectedParentId);
    await changeDocParent({
      docId: draftDocId,
      parentId: selectedParentId,
    });
  };

  const onDoctypeSelected = useCallback((selectedDoctype: DoctypeRelativeFragment) => {
    const docParentsByDoctype = getDocParentsByDoctype(selectedDoctype.id);
    const docParentId = docParentsByDoctype[0]?.id;
    if (docParentId) setDraftDocParentId(docParentId);
    setDoctype(selectedDoctype);
  }, [getDocParentsByDoctype]);

  const showParent =
    isDraftEditable &&
    !parentId &&
    !!docParents.length &&
    canHaveParent;

  const onCloseNewDoc = useCallback(() => {
    if (loading || getActiveProductTour()) return;

    setBoardNewDocPositionState({
      draftPosition: null,
      initialGroupId: null,
    });

    setBoardGroupState({ hoverGroupId: null });

    onClose?.();
  }, [loading, onClose]);

  const onCreateNewDoc = useCallback(async () => {
    if ((isDraftEditable && !draftDocId) || loading || getActiveProductTour()) return null;
    const { title } = getNewDocTitle();

    let result: Pick<DocBaseFragment, 'id' | 'title' | 'publicId'> | undefined | null = null;
    if (draftDocId) {
      if (title) {
        // User can start editing the title before the draft is created.
        await updateDocTitle({
          docId: draftDocId,
          title,
        });
      }
      // We need to double check as withLinearChecked does not change on doctype update.
      const withLinear = withLinearChecked && doctypeHasAutomation(doctype?.id);
      if (withLinear) {
        addDocAutomationWatch(draftDocId);
      }
      const publisDocResult = await publishDoc({
        variables: {
          docId: draftDocId,
          position: from === 'top' ? CreateDocPosition.Start : CreateDocPosition.End,
          withLinear,
        },
      });
      result = publisDocResult.data?.publishDoc;
      if (result) {
        const doc = getDocFromCache(result.id);
        if (doc) {
          await addNewdocCache({
            ...doc,
            isDraft: false,
            publicId: result?.publicId,
          });
        }
      }
    } else if (doctype) {
      // Deprecated
      result = (await createDoc({
        title,
        doctype,
        parentId,
      }))?.data?.addNewDoc;
    }
    setDraftDocId('');
    resetNewDocTitle();
    setBoardNewDocPositionState({
      draftPosition: null,
      initialGroupId: null,
    });
    autoScrollToView();
    return result;
  }, [
    loading,
    autoScrollToView,
    publishDoc,
    from,
    updateDocTitle,
    addNewdocCache,
    createDoc,
    doctype,
    parentId,
    isDraftEditable,
    draftDocId,
    withLinearChecked,
  ]);

  const onCreateNewDocAndOpen = useCallback(async () => {
    if (getActiveProductTour()) return;

    const createdDoc = await onCreateNewDoc();
    if (createdDoc) {
      navigate(PageId.Doc, { docSlug: getDocSlug(createdDoc) });
    }
  }, [onCreateNewDoc, navigate]);

  const onCreateDocAndOpenNewDraft = useCallback(async () => {
    if (getActiveProductTour()) return;

    await onCreateNewDoc();
    /**
     * Without this timeout, setBoardNewDocPositionState({ draftPosition: null })
     * would be overwritten by "draftPosition: from" due to React 18 automatic
     * batching. Here we explicitly want to close the new doc first, and then
     * open a new one
     */
    setTimeout(() => {
      setBoardNewDocPositionState({ draftPosition: from ?? null });
    }, 0);
  }, [onCreateNewDoc, from]);

  const onGoDown = useCallback(() => {
    if (!getBoardNewDocPositionState().initialGroupId) {
      setBoardNewDocPositionState({ initialGroupId: path ?? null });
    }
    goDown?.();
  }, [goDown, path]);

  useHotkeyListener({
    callbacks: {
      [ShortcutDocDraft.Abort]: onCloseNewDoc,
      [ShortcutDocDraft.Create]: onCreateNewDoc,
      [ShortcutDocDraft.CreateAndOpen]: onCreateNewDocAndOpen,
      [ShortcutDocDraft.CreateAndNewDraft]: onCreateDocAndOpenNewDraft,
      [ShortcutDocDraft.GoDown]: onGoDown,
      [ShortcutDocDraft.GoUp]: goUp,
    },
    shortcuts: Object.values(ShortcutDocDraft),
  });

  const titleElement = (
    <DocTitleContainer>
      <DocTitleEditable
        tag="span"
        initialValue={getNewDocTitle().title}
        focusEndOnMount
        onChange={(title) => setNewDocTitle({ title })}
        $viewType={viewType}
        placeholderContent={placeholder}
      />
    </DocTitleContainer>
  );

  return (
    <DraggableCardStyled
      draft
      isSelected
      ref={containerMeasure.setRef}
      className={className}
      viewType={viewType}
    >
      <Form onSubmit={submit} $viewType={viewType}>
        {!matchFeedback && (
          <DoctypeContainer>
            <DoctypesOptions
              doctype={doctype}
              possibleDoctypes={possibleDoctypes}
              onSelect={onDoctypeSelected}
              showTriangle={!doctypeSelectDisabled}
              disabled={doctypeSelectDisabled}
            // filtering already done in getPossibleDoctypes
              excludeBuiltIn={false}
            >
              {buttonProps => (
                <DoctypeButton {...buttonProps}>
                  {showDocTypeEmoji(doctype) && <Emoji emoji={doctype?.emoji} size={12} />}
                  {getDocTypeName(doctype)}
                  {!doctypeSelectDisabled && (
                    <CaretIcon direction="bottom" size={10} />
                  )}
                </DoctypeButton>
              )}
            </DoctypesOptions>
          </DoctypeContainer>
        )}

        {isDraftEditable && doctype?.id ? (
          <DocItemDraftAttributes
            doctypeId={doctype.id}
            groupId={groupId}
            onDocCreated={(data) => {
              setDraftDocId(data.id);
              if (data.parent?.id) setDraftDocParentId(data.parent.id);
            }}
            statusId={statusId}
            viewType={viewType}
            parentId={draftDocParentId}
            parent={showParent && (
              <DocItemDraftParent
                parentId={draftDocParentId}
                docParents={docParents}
                onSelect={onParentSelected}
                viewType={viewType}
              />
            )}
            title={titleElement}
            containerWidth={containerMeasure.rect?.width}
            withLinearChecked={withLinearChecked}
            onWithLinearChange={setWithLinear}
          />
        ) : titleElement}

        <Actions>
          <div>
            {hasDefaultAttributes && !isDraftEditable && (
              <Tooltip
                placement="top"
                title="Default values"
                content="Some values will be set by default due to view filters"
                withPortal
              >
                <WarningContainer>
                  <WarningIcon />
                </WarningContainer>
              </Tooltip>
            )}
          </div>

          <Buttons>
            <StyledButton
              type="button"
              variant="secondary"
              tooltipPlacement="top"
              tooltip={(
                <Shortcut
                  keys={shortcuts[ShortcutDocDraft.Abort]}
                  label="Cancel"
                />
              )}
              onClick={onCloseNewDoc}
              disabled={loading || isPublishLoading || isTitleLoading}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="submit"
              isLoading={loading || isPublishLoading || isTitleLoading}
              tooltipPlacement="top"
              tooltip={(
                <Shortcuts
                  shortcuts={[
                    {
                      label: 'Save',
                      keys: shortcuts[ShortcutDocDraft.Create],
                    },
                    {
                      label: 'Save & Open',
                      keys: shortcuts[ShortcutDocDraft.CreateAndOpen],
                    },
                    {
                      label: 'Save & Create new',
                      keys: shortcuts[ShortcutDocDraft.CreateAndNewDraft],
                    },
                  ]}
                />
            )}
            >
              Create
            </StyledButton>
          </Buttons>
        </Actions>
      </Form>
    </DraggableCardStyled>
  );

  async function submit(e: FormEvent) {
    e.preventDefault();
    await onCreateNewDoc();
  }
};

export default NewDoc;
