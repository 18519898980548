import { TippyProps } from '@tippyjs/react';
import { FC, useMemo } from 'react';

import { StyledButton } from 'src/app/Main/Settings/SettingsAttributes/SettingsAttributes.styles';
import { AttributeOptionsManager } from 'src/components/AttributeOptionsManager';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { Layer } from 'src/types/layers.types';
import { getAttributeOptions } from 'src/utils/attributes.util';

interface AttributeOptionsDropdownProps {
  attribute: AttributeDefinitionsNode;
  placement?: TippyProps['placement'];
  layer?: Layer;
}

export const AttributeOptionsDropdown: FC<React.PropsWithChildren<AttributeOptionsDropdownProps>> = ({
  attribute, placement = 'bottom-end', layer,
}) => {
  const [isDropdownVisible, {
    setTrueCallback: showDropdown,
    setFalseCallback: hideDropdown,
  }] = useOptimizedBooleanState(false);

  const options = useMemo(() => getAttributeOptions(attribute), [attribute]);

  return (
    <DropdownLayer
      layer={layer}
      visible={isDropdownVisible}
      hide={hideDropdown}
      placement={placement}
      content={(
        <AttributeOptionsManager
          attributeDefinition={attribute}
          options={options}
          onCreate={() => { }} // needed to display create option
        />
      )}
    >
      <StyledButton onClick={showDropdown}>
        {getLabel()}
      </StyledButton>
    </DropdownLayer>
  );

  function getLabel() {
    if (options.length === 0) { return 'Add option'; }
    if (options.length === 1) { return '1 option'; }
    return `${options.length} options`;
  }
};
