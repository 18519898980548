import { ComponentProps } from 'react';
import ReactFlow, { Elements } from 'react-flow-renderer';

import FlowElement from 'src/components/FlowElement/FlowElement';
import { FlowElementIntegrations } from 'src/components/FlowElement/FlowElementIntegrations';
import FlowPath from 'src/components/FlowPath/FlowPath';

import { Container } from './FlowDoctypes.styles';
import { FlowMarkerDefs } from './FlowMarkerDefs';

type Props = ComponentProps<typeof Container> & {
  elements: Elements;
};

const FlowDoctypes = ({
  elements, ...props
}: Props) => {
  return (
    <>
      <FlowMarkerDefs />
      <Container {...props}>
        <ReactFlow
          zoomOnPinch
          zoomOnScroll={false}
          elements={elements}
          defaultZoom={0.8}
          panOnScroll
          nodeTypes={{
            // @ts-ignore temp fix @types/react 18 element vs node
            custom: FlowElement,
            // @ts-ignore temp fix @types/react 18 element vs node
            integrations: FlowElementIntegrations,
          }}
          edgeTypes={{
            // @ts-ignore temp fix @types/react 18 element vs node
            customEdge: FlowPath,
          }}
        />
      </Container>
    </>
  );
};

export default FlowDoctypes;
