import { useState, useCallback, useMemo, forwardRef } from 'react';
import { useDebounce } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';

import { DocSearchInsightResult, DocSearchInsightResultProps } from '../DocSearchDropdown/DocSearchInsightResult';
import { SearchContainer, Input, SearchIcon } from './DocSearch.styles';

type TargetContent = 'link' | 'docId';

export type DocSearchProps = DocSearchInsightResultProps & {
  className?: string;
  onSubmit?: (p: SubmitParams) => void;
  targetContent?: TargetContent;
};

export type SubmitParams = {
  searchText: string;
  docId?: string;
};

export const DocSearchInsights = forwardRef<HTMLDivElement, DocSearchProps>(({
  className = '',
  inheritedAttributes,
  onAdd: onAddProps,
  onSubmit,
  targetContent = 'docId',
  ...docSearchProps
}, ref) => {
  const [search, setSearch] = useState('');
  const searchEnabled = useMemo(() => search.substr(0, 4) !== 'http', [search]);

  const [searchDebounced] = useDebounce(search, INPUT_ONCHANGE_DEBOUNCE);

  const onAdd = useCallback<NonNullable<DocSearchInsightResultProps['onAdd']>>((docId, options) => {
    onAddProps?.(docId, options);
    onSubmit?.({
      docId,
      searchText: search,
    });
  }, [onAddProps, onSubmit, search]);

  return (
    <SearchContainer
      ref={ref}
      className={className}
      onClick={e => e.stopPropagation()}
      $isSearching={!!search}
    >
      <Input
        type="text"
        autoFocus
        iconBefore={<SearchIcon />}
        onChange={(e) => setSearch(e.target.value)}
        onKeyUp={(e) => {
          if (!searchEnabled && e.code === 'Enter') {
            onSubmit?.({
              searchText: search,
            });
          }
        }}
        placeholder="Add insight…"
        value={search}
      />
      {(targetContent === 'docId' || searchEnabled) && (
        <DocSearchInsightResult
          {...docSearchProps}
          inheritedAttributes={inheritedAttributes}
          onAdd={onAdd}
          search={searchDebounced}
        />
      )}
    </SearchContainer>
  );
});
