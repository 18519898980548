import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CannyIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g>
      <path d="M11.8154 9.30909C11.2938 9.26144 10.8373 9.64263 10.7897 10.1618C10.5866 12.3737 8.68061 14.1041 6.45114 14.1041C4.04111 14.1041 2.08249 12.1555 2.08249 9.75799V6.23198C2.08249 3.83699 4.04362 1.88589 6.45114 1.88589C8.68563 1.88589 10.5966 3.61881 10.8022 5.82821C10.8499 6.34734 11.3088 6.72602 11.8279 6.68088C12.3471 6.63323 12.7283 6.17429 12.6806 5.65517C12.3847 2.48276 9.64864 0 6.45114 0C3.00036 0 0.194092 2.79624 0.194092 6.23198V9.75799C0.194092 13.1937 3.00036 15.99 6.45114 15.99C9.69628 15.99 12.3696 13.5599 12.6681 10.3348C12.7157 9.81567 12.332 9.35674 11.8154 9.30909Z" fill="#525DF9" />
      <path d="M6.45862 3.37305C5.16708 3.37305 4.03103 4.24828 3.69498 5.49718C3.55956 6.00126 3.85799 6.51787 4.36207 6.6533C4.44483 6.67587 4.52508 6.6859 4.60533 6.6859C5.02163 6.6859 5.40282 6.40753 5.51567 5.98621C5.63103 5.55737 6.01724 5.25894 6.45611 5.25894C6.9 5.25894 7.28871 5.55487 7.39906 5.9812C7.53197 6.48527 8.04608 6.78621 8.55015 6.65581C9.05423 6.52289 9.35517 6.00878 9.22476 5.50471C8.89874 4.24828 7.76019 3.37305 6.45862 3.37305Z" fill="#525DF9" />
    </g>
  </Svg>
);
