import { FC } from 'react';

import { SvgProps } from '../../components/Svg/Svg.styles';
import gainsight from './gainsight.png';

export const GainsightIcon: FC<React.PropsWithChildren<SvgProps>> = ({
  className, size,
}) => (
  <img className={className} width={size || 16} src={gainsight} alt="Gainsight" />
);
