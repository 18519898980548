import { useMemo } from 'react';

import productTourRoadmapImageStep3 from 'src/assets/product-tour-feedback-step-4.svg';
import productTourRoadmapImageStep2 from 'src/assets/product-tour-insight-step-5.svg';
import productTourRoadmapImageStep1 from 'src/assets/product-tour-roadmap-step-1.svg';
import productTourRoadmapImageStep4 from 'src/assets/product-tour-roadmap-step-4.svg';
import productTourRoadmapImageStep5 from 'src/assets/product-tour-roadmap-step-5.svg';
import { ProductTourModalContent } from 'src/components/ProductTourModalContent';
import { useIsRoadmapTourActive, useRoadmapTour } from 'src/hooks/useRoadmapTour';

export const RoadmapProductTour = () => {
  const { isActive } = useIsRoadmapTourActive();
  return isActive ? <OnboardingStarted /> : null;
};

const OnboardingStarted = () => {
  const { close } = useRoadmapTour();

  /* eslint-disable max-len */
  const slides = useMemo(() => [
    {
      id: 0,
      title: 'Create insightful roadmaps',
      description: 'Infuse your roadmap with customer insights. Organize features the way you like without losing sight of customer context. Create transparency with no-bullshit roadmap views.',
      image: <img src={productTourRoadmapImageStep1} alt="" height={145} draggable="false" />,
    },
    {
      id: 1,
      title: 'Add features',
      description: 'Create features from any feedback doc or roadmap view. Add a title, a release note & some rich content. You can also update its status, assignee & properties.',
      image: <img src={productTourRoadmapImageStep2} alt="" height={145} draggable="false" />,
    },
    {
      id: 2,
      title: 'Create roadmap views',
      description: 'Add as many roadmap views as you want so you can explore features in your own way. Add filters, groups & displayed properties. Choose between list and kanban views.',
      image: <img src={productTourRoadmapImageStep3} alt="" height={155} draggable="false" />,
    },
    {
      id: 3,
      title: 'Customize properties & workflows',
      description: 'Configure the properties & workflows you want for your features. They will be available whenever you create new features or roadmap views.',
      image: <img src={productTourRoadmapImageStep4} alt="" height={145} draggable="false" />,
    },
    {
      id: 4,
      title: 'Create advanced roadmaps',
      description: 'Pick your favorite hierarchy & create multi-level roadmaps. You can create multiple doc types that can be nested within each other and visualized in roadmap views.',
      image: <img src={productTourRoadmapImageStep5} alt="" height={145} draggable="false" />,
    },
  ], []);
  /* eslint-enable max-len */

  return (
    <ProductTourModalContent
      slides={slides}
      stop={close}
    />
  );
};
