import {
  Table, Button, ShyScrollbar, Td, Input, Tr, typo, Th, CellContent,
} from '@cycle-app/ui';
import { mobile, tablet } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { PortalModal } from 'src/components/PortalModal';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';

export const Header = styled.header`
  flex: none;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 28px;

  h1 {
    font-size: 26px;
    font-weight: 600;
    outline: none;
  }

  ${mobile} {
    margin-top: 32px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const Nav = styled(ShyScrollbar)`
  flex: none;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
  margin-bottom: 32px;
  overflow-y: hidden;
  gap: 20px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  ${mobile} {
    margin-bottom: 7px;
  }
`;

export const ActionButton = styled(Button).attrs({
  size: 'M',
})`
  padding: 3px 12px;
  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const StyledShyScrollbar = styled(ShyScrollbar)`
  width: 100%;
  max-height: 100%;
  margin: 0 -8px;
  padding: 0 8px;
  padding-bottom: 8px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 16px;
  label {
    margin-bottom: 8px;
  }
`;

export const FormInput = styled(Input)`
  width: 100%;
`;

export const SearchInput = styled(Input)`
  input {
    height: 32px;
    width: 180px
  }
  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const StyledTable = styled(Table)<{ $isPrimary?: boolean }>`
  ${typo.body400}
  background-color: ${p => (p.$isPrimary ? p.theme.colors.background.primary : p.theme.colors.modal.content.bg)};
  table-layout: auto;
`;

export const StyledTr = styled(Tr)``;

export const StyledDotsMenuLayer = styled(DotsMenuLayer)`
  &.force-focus, &:hover {
    color: ${p => p.theme.colors.text.primary};
    background-color: ${p => p.theme.colors.settings.navigationItem.bgHover};
  }
`;

interface CellSize {
  $size?: number;
  $sizeTablet?: number;
  $sizeMobile?: number;
}

const CellCommonCss = css<CellSize>`
  --size: ${p => (p.$size ? `${p.$size}px` : 'auto')};
  --size-tablet: ${p => ((p.$sizeTablet ?? p.$size) ? `${(p.$sizeTablet ?? p.$size)}px` : 'auto')};
  --size-mobile: ${p => ((p.$sizeMobile ?? p.$size) ? `${(p.$sizeMobile ?? p.$size)}px` : 'auto')};
  min-width: var(--size);
  max-width: var(--size);
  width: var(--size);
  position: relative;

  ${mobile} {
    --size: var(--size-mobile);
  }

  ${tablet} {
    --size: var(--size-tablet);
  }
`;

export const StyledTh = styled(Th)<CellSize>`
  ${CellCommonCss}
  > svg {
    margin-right: 4px;
    vertical-align: text-bottom;
  }
`;

export const StyledThActions = styled(Th)<CellSize>`
  background-color: inherit;
  position: sticky;
  ${CellCommonCss}
`;

export const StyledTd = styled(Td)<CellSize>`
  ${CellCommonCss}
`;

export const TdActions = styled(StyledTd)`
  text-align: right;
  background-color: inherit;
  position: sticky;
  right: -8px;

  > * {
    display: inline-block;
  }
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const CompanyActionSelect = styled(CustomerCompanyAction)`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: transparent;
  gap: 4px;
  justify-content: space-between;
  padding: 7px 8px;
  width: 100%;
  ${typo.body400};

  &:hover, &:active, &:focus {
    background: transparent;
  }
`;

export const CompanyActionSelectLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.primary};
`;

export const DocCountCell = styled(CellContent)`
  justify-content: flex-start;
  color: ${p => p.theme.colors.text.secondary};
`;

export const InputContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const UploadNewBtn = styled(Button).attrs({ size: 'M' })`
  white-space: nowrap;
`;

export const RemoveBtn = styled(Button).attrs({
  size: 'M',
  variant: 'nospace',
})`
  white-space: nowrap;
  color: ${p => p.theme.colors.text.secondary}
`;

export const CustomerModal = styled(PortalModal)`
  min-width: 450px;
  width: 450px;
  padding: 16px;

  @media (max-width: 500px) {
    min-width: unset;
    min-height: unset;
    max-width: unset;
    max-height: unset;
    width: 100vw;
    height: 100vh;
    border: none;
    border-radius: 0; 
  }
`;
