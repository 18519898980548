import { BillingFrequency } from '@cycle-app/graphql-codegen';
import { CurrentPlanSection, CurrentPlanSectionProps } from '@cycle-app/ui';
import { plural } from '@cycle-app/utilities';
import { add, parseISO, format } from 'date-fns';
import { useState } from 'react';

import { CancelSubscription } from 'src/components/CancelSubscription';
import { STRIPE_PORTAL_EXPIRATION_DELAY } from 'src/constants/billing.constants';
import { useProductAddOns } from 'src/hooks';
import { useFetchExpectedAmountToPay } from 'src/hooks/api/queries/useFetchExpectedAmountToPay';
import { useStripePortal } from 'src/hooks/api/queries/useStripePortal';
import { useCurrentBilling, useProduct } from 'src/hooks/api/useProduct';
import { useProductStatus } from 'src/reactives/product.reactive';
import { currencyFormat } from 'src/utils/currency.utils';
import { addToaster } from 'src/utils/toasters.utils';

import {
  Header, Container, Bold, PriceToPaySkeleton, StyledMakers, StyledAddons, Section, SectionTitle, StyledFeaturesList,
} from './SettingsBilling.styles';

export const SettingsBilling = () => {
  const { product } = useProduct();
  const currentBilling = useCurrentBilling();
  const [action, setAction] = useState<'manage' | 'info' | 'history' | null>(null);
  const stripeQuery = useStripePortal({
    pollInterval: STRIPE_PORTAL_EXPIRATION_DELAY - 30_000,
  });
  const {
    expectedAmountToPay, loading: isExpectedAmountToPayLoading,
  } = useFetchExpectedAmountToPay();
  const addOns = useProductAddOns();
  const { productStatus } = useProductStatus();
  const addOnsEnabled = addOns.filter(a => a.isEnabled);
  const isAnnual = currentBilling?.frequency === BillingFrequency.Yearly;

  if (!product) return null;

  // we should have Int (will be fixed later in the schema)
  const formattedExpectedAmountToPay = (typeof expectedAmountToPay === 'string'
    ? parseFloat(expectedAmountToPay)
    : expectedAmountToPay) ?? 0;

  const monthlyAmountToPay = isAnnual ? formattedExpectedAmountToPay / 12 : formattedExpectedAmountToPay;
  let status: CurrentPlanSectionProps['status'] = 'active';
  if (productStatus === 'pro-cancelled') {
    status = 'canceled';
  } else if (currentBilling?.hasPaymentFailed) {
    status = 'failed';
  }

  return (
    <div>
      <Header>
        <h1>Billing</h1>
      </Header>

      <Container>
        <CurrentPlanSection
          status={status}
          body={(
            <>
              This workspace is set for
              {' '}
              <Bold>
                {(isExpectedAmountToPayLoading || !currentBilling)
                  ? <PriceToPaySkeleton />
                  : currencyFormat(monthlyAmountToPay)}
                {' '}
                per month
                for
                {' '}
                {`${currentBilling?.nbMakers} ${plural('maker', currentBilling?.nbMakers || 0)}`}
              </Bold>
              {' '}
              and
              {' '}
              <Bold>
                {addOnsEnabled.length}
                {' '}
                {plural('add-on', addOnsEnabled.length)}
              </Bold>
              {' '}
              and  will renew on
              {' '}
              <Bold>
                {getNextBillingDate()}
                .
              </Bold>
              <br />
              Adjusted for your account balance, you will be charged
              <Bold>
                {isExpectedAmountToPayLoading
                  ? <PriceToPaySkeleton /> : ` ${currencyFormat(formattedExpectedAmountToPay)}`}
                .
              </Bold>
            </>
          )}
          action={action}
          isLoadingStripePortal={stripeQuery.loading}
          onViewBillingInformation={async (selectedAction) => {
            setAction(selectedAction);
            const url = stripeQuery.stripePortal;
            setAction(null);
            if (url) {
              window.open(url, '_blank');
            } else {
              addToaster({
                title: 'We could not redirect you',
                message: 'Please try again later',
              });
            }
          }}
          makers={<StyledMakers limit={5} />}
        />

        <Section>
          <SectionTitle>What&apos;s included</SectionTitle>
          <StyledFeaturesList split />
        </Section>

        <StyledAddons />

        {productStatus !== 'pro-cancelled' && <CancelSubscription />}
      </Container>
    </div>
  );

  function getNextBillingDate() {
    if (currentBilling?.invoicedAt) {
      const createdAt = parseISO(currentBilling.invoicedAt);
      return format(add(createdAt, isAnnual ? { years: 1 } : { months: 1 }), 'PPP');
    }
    return '';
  }
};
