import { LightOnboardingScreen, OnboardingScreen } from 'src/types/onboarding.types';

export const OnboardingStepsMaker = [
  OnboardingScreen.ConfigureAccount,
  OnboardingScreen.CreatePassword,
  OnboardingScreen.Colors,
  OnboardingScreen.Integrations,
  OnboardingScreen.CreateDocs,
  OnboardingScreen.EditDocs,
  OnboardingScreen.Welcome,
];

export const OnboardingStepsCollaborator = [
  OnboardingScreen.ConfigureAccount,
  OnboardingScreen.CreatePassword,
  OnboardingScreen.Colors,
  OnboardingScreen.Integrations,
  OnboardingScreen.Boards,
  OnboardingScreen.Collaboration,
  OnboardingScreen.Chrome,
  OnboardingScreen.Welcome,
];

export const OnboardingStepsMobile = [
  OnboardingScreen.ConfigureAccount,
  OnboardingScreen.CreatePassword,
  OnboardingScreen.Colors,
  OnboardingScreen.Welcome,
];

export const LightOnboardingSteps = [
  LightOnboardingScreen.Welcome,
  LightOnboardingScreen.AccountVerify,
  LightOnboardingScreen.AccountInfos,
  LightOnboardingScreen.AccountPreferences,
  LightOnboardingScreen.Workspace,
  LightOnboardingScreen.Sources,
  LightOnboardingScreen.Doctypes,
  LightOnboardingScreen.Linear,
];
