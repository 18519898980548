import { MoreHorizIcon, LoadingIcon } from '@cycle-app/ui/icons';
import { Setter } from '@cycle-app/utilities';
import {
  FC,
  useCallback,
  useEffect,
  MouseEvent,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { Placement } from 'tippy.js';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { Layer } from 'src/types/layers.types';

import { StyledActionButton } from './DocOptions.styles';
import { DocOptionsMenu, Props as DocOptionsMenuProps } from './DocOptionsMenu';

export type DocOptionsProps = Omit<DocOptionsMenuProps, 'setVisible' | 'onDelete'> & {
  buttonSize?: 'S' | 'M' | 'L';
  disabled?: boolean;
  dropdownPlacement?: Placement;
  isLoading?: boolean;
  layer?: Layer;
  onDeleteDoc?: VoidFunction;
  onVisibilityChange?: Setter<boolean>;
  secondaryBg?: boolean;
  isNewInbox?: boolean;
};

const DocOptions: FC<React.PropsWithChildren<DocOptionsProps>> = (props) => {
  const [isVisible, setVisible] = useState(false);
  return (
    <DocOptionsStateless
      {...props}
      isVisible={isVisible}
      setVisible={setVisible}
    />
  );
};

export const DocOptionsStateless: FC<React.PropsWithChildren<DocOptionsProps & {
  isVisible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}>> = ({
  buttonSize,
  disabled = false,
  doc,
  dropdownPlacement = 'bottom-start',
  isLoading = false,
  layer,
  onDeleteDoc,
  onVisibilityChange,
  viewType,
  isNewInbox,
  isVisible,
  setVisible,
  ...docOptionsMenuProps
}) => {
  const onButtonClicked = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setVisible(b => !b);
  }, [setVisible]);

  useEffect(() => {
    if (isVisible) {
      setDocItem({ hoverDocId: null });
    }
  }, [isVisible]);
  useEffect(() => {
    onVisibilityChange?.(isVisible);
  }, [isVisible, onVisibilityChange]);

  return (
    <>
      <DropdownLayer
        visible={isVisible}
        hide={() => setVisible(false)}
        placement={dropdownPlacement}
        layer={layer}
        content={(
          <DocOptionsMenu
            doc={doc}
            setVisible={setVisible}
            viewType={viewType}
            {...docOptionsMenuProps}
            onConfirmDelete={() => {
              setVisible(false);
              onDeleteDoc?.();
            }}
          />
        )}
      >
        <StyledActionButton
          forceFocus={isVisible}
          onClick={onButtonClicked}
          hide={disabled}
          size={buttonSize}
          $context={docOptionsMenuProps.context}
          $isNewInbox={isNewInbox}
        >
          {isLoading ? <LoadingIcon /> : <MoreHorizIcon />}
        </StyledActionButton>
      </DropdownLayer>
    </>
  );
};

export default DocOptions;
