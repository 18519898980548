import { memo, MouseEvent } from 'react';

import { CustomViewIconEditable } from 'src/app/Main/Sidebar/SidebarBoard/SidebarBoardSections/SidebarBoardSection/SidebarItemBoard/CustomViewIcon';
import { useIsBoardStarred } from 'src/hooks';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { SectionParent } from 'src/utils/section.utils';

import { ItemViewType } from './ItemViewType';
import { ViewDescription } from './ViewDescription';
import { ViewName } from './ViewName';
import {
  Item, Title, View, Actions, StyledBoardStarButton, StyledBoardOptions,
} from './ViewsList.styles';

type Props = {
  sectionParent: SectionParent;
  boardId: string;
  onClick?: (event: MouseEvent) => void;
  isOverlay?: boolean;
  isDragging?: boolean;
  isSmall: boolean;
};

export const ViewItem = memo(({
  sectionParent, boardId, onClick, isOverlay = false, isSmall, isDragging,
}: Props) => {
  const isBoardStarred = useIsBoardStarred();
  const { boards } = useSectionBoards(sectionParent);
  const board = boards.find(b => b.id === boardId);
  const viewType = board?.publishedBoardConfig?.viewType;
  if (!board || !viewType) return null;
  return (
    <Item
      onClick={onClick}
      $isDragging={isDragging}
      $isOverlay={isOverlay}
      $isSmall={isSmall}
    >
      <Title>
        <CustomViewIconEditable
          viewId={board.id}
          color={board.color}
          emoji={board.emoji}
          sectionType={board.section?.type}
        />
        <ViewName
          boardId={board.id}
          name={board.name}
          isSmall={isSmall}
        />
      </Title>

      <View>
        <ItemViewType
          viewType={viewType}
          isSmall={isSmall}
        />
      </View>

      <ViewDescription
        boardId={board.id}
        description={board.description?.trim()}
      />

      <Actions>
        {!isSmall && (
          <StyledBoardStarButton
            boardId={board.id}
            tooltipPlacement="top"
            $visible={isBoardStarred(board.id)}
          />
        )}

        <StyledBoardOptions
          boardId={board.id}
          placement="bottom-end"
          tooltipPlacement="top"
          boardConfigId={board.publishedBoardConfig?.id}
          hideSettings
        />
      </Actions>
    </Item>
  );
});
