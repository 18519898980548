import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { Container, Placeholder, Logo } from './ChangelogBuilderContentLogo.styles';

export const ChangelogBuilderContentLogo = () => {
  const { watch } = useChangelogBuilderFormContext();
  const logoUrl = watch('logo_url');
  return (
    <Container>
      {logoUrl ? <Logo src={logoUrl} /> : <Placeholder>LOGO</Placeholder>}
    </Container>
  );
};
