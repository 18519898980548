import {
  GITHUB_EXTENSION_NAME,
  LINEAR_EXTENSION_NAME,
  MENTION_DOC_EXTENSION_NAME,
  MENTION_EXTENSION_TAGNAME,
} from '@cycle-app/editor-extensions';
import { last, FileUploadedData } from '@cycle-app/utilities';
import type { FileType } from '@cycle-app/utilities';
import { Content, Editor, NodeWithPos, Range } from '@tiptap/core';
import { EditorState, Plugin } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { Node, NodeViewRendererProps, posToDOMRect } from '@tiptap/react';
import { SuggestionProps } from '@tiptap/suggestion';

import { BULLET_LIST_TYPE, CHECK_LIST_TYPE, ORDERED_LIST_TYPE, PARAGRAPH_TYPE } from 'src/constants/editor.constants';
import { setEditorNodeOpen } from 'src/reactives';
import { GithubIssuesCommandAttributes, LinearIssuesCommandAttributes } from 'src/types/editor.types';
import { logError } from 'src/utils/errors.utils';

import type { KeyEditorNodeOpenState } from 'src/reactives';

export const lastNodeIsParagraph = (editor: Editor) => {
  const json = editor.getJSON();
  const lastNode = last(json.content) as Node;
  return lastNode.type === 'paragraph';
};

export const fixCommentContent = (editor: Editor) => {
  const jsonContent = editor.getJSON().content;
  if (!jsonContent) return null;

  const lastNode = last(jsonContent);
  const lastNodeIndex = jsonContent.length - 1;
  const lastContent = lastNode?.content
    ? last(lastNode.content)
    : undefined;

  if (lastContent?.type === 'hardBreak') {
    jsonContent[lastNodeIndex] = {
      ...lastNode,
      content: lastNode?.content?.slice(0, -1),
    };

    editor.commands.setContent({
      type: 'doc',
      content: jsonContent,
    });

    return {
      html: editor.getHTML(),
      json: editor.getJSON(),
    };
  }
  return null;
};

export const extractMentionedUsers = (html: string): string[] => {
  const htmlElement = document.createElement('html');
  htmlElement.innerHTML = html;

  const mentionExtensionTags = htmlElement.getElementsByTagName(MENTION_EXTENSION_TAGNAME);
  return Array.prototype.slice.call(mentionExtensionTags).map(element => element.id);
};

/**
 * This is a workaround in order to re-order prosemirror plugins so that the
 * event from suggestions will have a higher priority then the ones from the native
 * extension like `heading`.
 *
 * Note that the priority of tiptap extension doesn't work since it only reorder extensions and not
 * plugins.
 *
 * - https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/Extension.ts#L32
 * - https://github.com/ueberdosis/tiptap/issues/2570
 */
export const reorderProsemirrorPlugins = (editor: Editor): void => {
  const {
    state, view,
  } = editor;

  if (state.plugins.length > 0) {
    const restOfPlugins: Plugin[] = [];
    const suggestionPlugins: Plugin[] = [];

    state.plugins.forEach((plugin) => {
      // @ts-ignore: The `Plugin` type does not include `key`
      if ((plugin.key as string).includes('suggestion')) {
        suggestionPlugins.push(plugin);
      } else {
        restOfPlugins.push(plugin);
      }
    });

    view.updateState(
      state.reconfigure({
        plugins: [...suggestionPlugins, ...restOfPlugins],
      }),
    );
  }
};

export const deleteNodeRange = ({
  editor, node, getPos,
}: Pick<NodeViewRendererProps, 'editor' | 'node' | 'getPos'>) => {
  if (!node || typeof getPos !== 'function') return undefined;

  const from = getPos();
  if (Number.isNaN(from)) return undefined;
  const to = from + node.nodeSize;

  return editor.commands.deleteRange({
    from,
    to,
  });
};

export const getRootNodeListFromSelection = (state: EditorState) => {
  const {
    $head, $anchor,
  } = state.selection;
  const headPaths = ($head as { path?: NodeWithPos['node'][] }).path?.filter(p => !!p.type) ?? [];
  const anchorPaths = ($anchor as { path?: NodeWithPos['node'][] }).path?.filter(p => !!p.type) ?? [];
  return [...anchorPaths, ...headPaths].find(p => [ORDERED_LIST_TYPE, BULLET_LIST_TYPE, CHECK_LIST_TYPE].includes(p.type.name));
};

type GithubIssue = Pick<GithubIssuesCommandAttributes, 'id' | 'projectId'>;
type LinearIssueOrProject = Pick<LinearIssuesCommandAttributes, 'id' | 'type'>;

type InsertIssuesParams = {
  editor: Editor;
} & (
  {
    extensionName: typeof GITHUB_EXTENSION_NAME;
    newIssues: GithubIssue[];
  } |
  {
    extensionName: typeof LINEAR_EXTENSION_NAME;
    newIssues: LinearIssueOrProject[];
  }
);

type Issue = ({
  type: typeof GITHUB_EXTENSION_NAME;
  attrs: GithubIssue;
} | {
  type: typeof LINEAR_EXTENSION_NAME;
  attrs: LinearIssueOrProject;
});

type ExtensionNames = typeof GITHUB_EXTENSION_NAME | typeof LINEAR_EXTENSION_NAME;

function isGithubIssue(extensionName: ExtensionNames, issue: { id: string }): issue is GithubIssue {
  return !!issue.id && extensionName === GITHUB_EXTENSION_NAME;
}

export const insertIssues = ({
  editor, newIssues, extensionName,
}: InsertIssuesParams) => {
  const selectionNodeName = editor.state.selection.content().content.firstChild?.type.name;
  const issuesNodesContent = newIssues.map<Issue>((issue) => (
    isGithubIssue(extensionName, issue)
      ? {
        type: GITHUB_EXTENSION_NAME,
        attrs: {
          id: issue.id,
          projectId: issue.projectId,
        },
      } : {
        type: LINEAR_EXTENSION_NAME,
        attrs: {
          id: issue.id,
          type: issue.type,
        },
      }
  ));

  switch (selectionNodeName) {
    case BULLET_LIST_TYPE:
    case CHECK_LIST_TYPE:
    case ORDERED_LIST_TYPE: {
      editor
        .chain()
        .clearNodes()
        .insertContent([...issuesNodesContent])
        .run();
      return;
    }
    case PARAGRAPH_TYPE:
    default: {
      editor
        .chain()
        .deleteSelection()
        .insertContent([...issuesNodesContent])
        .createParagraphNear()
        .run();
    }
  }
};

type InsertDocMentionsParams = {
  editor: Editor;
  docIds: string[];
  selectionType: string;
};
export const insertDocMentions = ({
  editor, docIds, selectionType,
}: InsertDocMentionsParams) => {
  if (!docIds.length) return true;

  if (docIds.length === 1) {
    return editor
      .chain()
      .insertContent([
        {
          type: MENTION_DOC_EXTENSION_NAME,
          attrs: { id: docIds[0] },
        },
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run();
  }

  const [firstCreatedDoc, ...otherCreatedDocs] = docIds;

  return editor
    .chain()
    .insertContent([
      {
        type: MENTION_DOC_EXTENSION_NAME,
        attrs: { id: firstCreatedDoc },
      },
      {
        type: 'text',
        text: ' ',
      },
      ...otherCreatedDocs
        .map((id) => (selectionType === PARAGRAPH_TYPE ? {
          type: 'paragraph',
          content: [
            {
              type: MENTION_DOC_EXTENSION_NAME,
              attrs: { id },
            },
          ],
        } : {
          type: selectionType === CHECK_LIST_TYPE ? 'taskItem' : 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: MENTION_DOC_EXTENSION_NAME,
                  attrs: { id },
                },
                {
                  type: 'text',
                  text: ' ',
                },
              ],
            },
          ],
        })),
    ])
    .run();
};

const getEditorNodeKey = (fileType: FileType): KeyEditorNodeOpenState => {
  if (fileType === 'audio') return 'audioNodeOpen';
  if (fileType === 'video') return 'videoNodeOpen';
  if (fileType === 'image') return 'imageNodeOpen';
  return 'fileNodeOpen';
};

type InsertFileParams = {
  editor: Editor;
  file: FileUploadedData;
  rangeToDelete?: Range;
  dataId?: string;
  content?: Content | null;
};

export const insertFile = ({
  editor, file, rangeToDelete, dataId: dataIdParam, content,
}: InsertFileParams) => {
  const dataId = dataIdParam || crypto.randomUUID();
  setEditorNodeOpen(getEditorNodeKey(file.type), dataId);
  editor
    .chain()
    .focus()
    .command(({ commands }) => {
      if (rangeToDelete) {
        return commands.deleteRange({
          from: rangeToDelete.from ?? 0,
          to: rangeToDelete.to ?? 0,
        });
      }
      return true;
    })
    .command(({ commands }) => {
      if (file.type.includes('video')) {
        return commands.setVideo({
          src: file.src,
          title: file.title,
          dataId,
        });
      }
      if (file.type.includes('audio')) {
        return commands.setAudio({
          src: file.src,
          dataId,
        });
      }
      return commands.setFile({
        file,
        dataId,
      });
    })
    .createParagraphNear()
    .command(({ commands }) => !content || commands.insertContent(content))
    .focus()
    .run();

  return dataId;
};

/**
 * Safe version of posToDOMRect which returns an off-screen DOMRect
 * in the case of an error which may be due to Prosemirror DOM manipulation
 */
const safePosToDOMRect = (view: EditorView, from: number, to: number): DOMRect => {
  // docView is an non-typed internal property that must be non-null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!(view as any).docView) return offscreenRect;

  try {
    return posToDOMRect(view, from, to);
  } catch (error) {
    logError(error);
    return offscreenRect;
  }
};

export { safePosToDOMRect as posToDOMRect };

/**
 * Tiptap Suggestion clientRect can return null, but Tippy getReferenceClientRect must always return a DOMRect
 */
export const clientRectWithFallback = (clientRect: SuggestionProps['clientRect']) => {
  if (!clientRect) return undefined;
  return () => clientRect() ?? offscreenRect;
};

export const offscreenRect = new DOMRect(-10000, -10000, 0, 0);
