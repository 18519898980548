import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const CheckCircleAltIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-no-fill
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M6.6978 1.92746C7.38587 1.13551 8.61554 1.13551 9.3036 1.92746L9.8396 2.54435C9.92847 2.64661 10.0638 2.69586 10.1976 2.67463L11.0047 2.5466C12.0409 2.38222 12.9828 3.17262 13.0009 4.22159L13.0149 5.03868C13.0173 5.17414 13.0893 5.29882 13.2054 5.36857L13.906 5.7893C14.8054 6.32942 15.0189 7.54036 14.3585 8.35556L13.8441 8.9905C13.7587 9.09576 13.7337 9.23756 13.7779 9.36563L14.0441 10.1383C14.3859 11.1302 13.7711 12.195 12.7412 12.395L11.939 12.5507C11.806 12.5766 11.6957 12.6691 11.6472 12.7956L11.3545 13.5586C10.9788 14.5381 9.82327 14.9587 8.9058 14.4498L8.1912 14.0535C8.07267 13.9878 7.92873 13.9878 7.81027 14.0535L7.0956 14.4498C6.17813 14.9587 5.02265 14.5381 4.64692 13.5586L4.35424 12.7956C4.30572 12.6691 4.19543 12.5766 4.06243 12.5507L3.2602 12.395C2.2303 12.195 1.61548 11.1302 1.95728 10.1383L2.22353 9.36563C2.26767 9.23756 2.24266 9.09576 2.15738 8.9905L1.64294 8.35556C0.982508 7.54036 1.19603 6.32942 2.09543 5.7893L2.79603 5.36857C2.91217 5.29882 2.98415 5.17414 2.98649 5.03868L3.00055 4.22159C3.01861 3.17262 3.96057 2.38222 4.99673 2.5466L5.80386 2.67463C5.93766 2.69586 6.07295 2.64661 6.16181 2.54435L6.6978 1.92746ZM10.1388 7.47156C10.3991 7.21123 10.3991 6.7891 10.1388 6.52876C9.87847 6.26841 9.45634 6.26841 9.196 6.52876L7.33407 8.3907L6.80547 7.8621C6.5451 7.60176 6.12299 7.60176 5.86264 7.8621C5.60229 8.12243 5.60229 8.54456 5.86264 8.8049L6.62694 9.56923C7.01747 9.9597 7.6506 9.9597 8.04113 9.56923L10.1388 7.47156Z"
      fill="currentcolor"
    />
  </Svg>
);
