import { CompanyFragment } from '@cycle-app/graphql-codegen/generated';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { ERROR_CODE } from '@cycle-app/utilities';
import { useRef, FC } from 'react';
import { Controller } from 'react-hook-form';

import { useCompanyUpdate } from '../../hooks/api/mutations/customers/useCompanyUpdate';
import { useEnhancedForm, ErrorMap } from '../../hooks/form/useEnhancedForm';
import { NameEditTextButton } from './CompanyProfile.styles';

interface Props {
  className?: string;
  company: CompanyFragment;
}

const mutationErrorsMap: ErrorMap<FormData>[] = [
  {
    code: ERROR_CODE.COMPANY_WITH_NAME_ALREADY_EXISTS,
    fieldName: 'name',
    renderMessage: () => 'A company with this name already exists',
  },
];

type FormData = { name: string };

export const CompanyName: FC<React.PropsWithChildren<Props>> = ({ company }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    updateCompany, isLoading,
  } = useCompanyUpdate();
  const {
    control, handleSubmit, displayFieldsErrors, setValue,
  } = useEnhancedForm<FormData>({ defaultValues: { name: company.name || '' } });
  const readOnly = company.isDefault;
  const placeholder = readOnly
    ? 'No name'
    : (
      <>
        Add name
        <PenFilledIcon />
      </>
    );
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <NameEditTextButton
            ref={inputRef}
            disabled={company.isDefault}
            isLoading={isLoading}
            onChange={field.onChange}
            onBlur={onBlur}
            onCancel={onBlur}
            placeholder={placeholder}
            value={field.value}
            required
            editTooltip="Edit company name"
          />
        )}
      />
    </form>
  );

  function onBlur() {
    setValue('name', company.name || '');
  }

  async function onSubmit({ name }: FormData) {
    const result = await updateCompany({
      companyId: company.id,
      name,
    });
    if (result.errors) {
      displayFieldsErrors(result.errors, mutationErrorsMap, true);
      return;
    }
    if (result.data?.updateCompany?.name) {
      inputRef.current?.blur();
      setValue('name', result.data.updateCompany.name);
    }
  }
};
