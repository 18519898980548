import { ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Info = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  margin-left: 40px;
`;

export const SourceButton = styled(ActionButton)`
  ${p => !p.onClick && `
    pointer-events: none;
  `}

  > svg {
    height: 16px;
    width: 16px;
  }
`;
