import { DocFullFragment, DocLinkDataFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { ImageIcon, CommentAltIcon, AiIcon, ReleaseNoteIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import DocAction from 'src/components/DocAction/DocAction';
import { DocPanelComments } from 'src/components/DocComments/DocComments';
import { DocCompanyCustomer } from 'src/components/DocCompanyCustomer';
import { DocReleaseNoteCreateButton } from 'src/components/DocReleaseNoteCreateButton';
import { ExtractInsights } from 'src/components/ExtractInsights';
import { useGetDoc } from 'src/reactives/doc.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { getCommentsCount } from 'src/utils/comments.utils';
import { isFeedback, isCustom } from 'src/utils/docType.util';

import {
  AddCoverAction,
  AddReleaseAction,
  Container,
  CustomerContainer,
  StyledDocPanelBreadcrumb,
  SyncingContainer,
} from './DocPanelActions.styles';

interface Props {
  className?: string;
  doc: DocFullFragment;
  onAddCoverClicked: VoidFunction;
  isUploadingCover: boolean;
  isDraggingCover: boolean;
  minimalBreadcrumb?: boolean;
  docSource?: DocLinkDataFragment | null;
}

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

const DocPanelActions: FC<React.PropsWithChildren<Props>> = ({
  className,
  doc,
  onAddCoverClicked,
  isUploadingCover,
  isDraggingCover,
  minimalBreadcrumb,
  docSource,
}) => {
  const isMobile = useIsMobile();
  const coverActionLabel = getCoverActionButtonLabel();
  const isCoverActionEmphasized = isUploadingCover || isDraggingCover;

  return (
    <Container className={className}>
      {doc?.doctype.customer && isFeedback(doc?.doctype) && (
        <CustomerContainer>
          <DocCompanyCustomer
            doc={doc}
            isDisabled={!!docSource}
            canRemoveCustomer={doc.doctype.type === DoctypeType.Custom}
          />
        </CustomerContainer>
      )}

      {!isMobile && (
        <>
          <DocPanelComments
            docId={doc.id}
            nbComments={getCommentsCount(doc)}
            tooltipPlacement="bottom"
            size="L"
            showLabel={!isMobile}
            buttonIcon={<CommentAltIcon />}
            maxHeight="70vh"
            className="doc-panel-comments"
          />
          {!isFeedback(doc?.doctype) && (
            <AddCoverAction
              onClick={onAddCoverClicked}
              userColor={isCoverActionEmphasized}
              disabled={isUploadingCover}
              size="L"
            >
              <ImageIcon />
              <span>{coverActionLabel}</span>
            </AddCoverAction>
          )}

          {isCustom(doc?.doctype) &&
          !!doc.doctype.release &&
          !doc.releaseNote && (
            <DocReleaseNoteCreateButton
              releaseNote={doc.releaseNote}
              docId={doc.id}
            >
              {(toggleProps, isLoading) => (
                <AddReleaseAction
                  onClick={toggleProps.onClick}
                  userColor={false}
                  disabled={isLoading}
                  size="L"
                >
                  {isLoading ? <Spinner /> : <ReleaseNoteIcon />}
                  <span>Add release</span>
                </AddReleaseAction>
              )}
            </DocReleaseNoteCreateButton>
          )}

          {!quotesEnabled && isFeedback(doc?.doctype) && (
            <>
              <ExtractInsights
                button={({
                  isLoading: isButtonLoading, ...props
                }) => ((
                  <DocAction
                    size="L"
                    {...props}
                  >
                    <AiIcon isAnimated={isButtonLoading} hasGradient />
                    Find insights
                  </DocAction>
                ))}
              />
            </>
          )}
        </>
      )}
      {minimalBreadcrumb && (
        <StyledDocPanelBreadcrumb
          docId={doc.id}
          docTypeId={doc.doctype.id}
          showParentTitle={false}
        />
      )}
      {!isMobile && <SyncingLabel />}
    </Container>
  );

  function getCoverActionButtonLabel(): string {
    if (isUploadingCover) return 'Uploading…';
    return doc?.cover?.url
      ? 'Update cover'
      : 'Add cover';
  }
};

export default DocPanelActions;

export const SyncingLabel = () => {
  const { isEditorSyncing } = useGetDoc();

  if (!isEditorSyncing) return null;

  return (
    <SyncingContainer>
      <Spinner />
      Saving...
    </SyncingContainer>
  );
};
