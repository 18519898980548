import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const VolumeLowIcon = ({ className = '' }: Props) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="volume full, speaker, loud, sound on, music">
      <g id="Icon">
        <path d="M8.63637 3.63139C8.63637 2.55233 7.37783 1.96285 6.54886 2.65366L4.12842 4.67069C4.01406 4.76599 3.8699 4.81819 3.72103 4.81819H2.90909C1.85473 4.81819 1 5.67292 1 6.72728V9.27274C1 10.3271 1.85473 11.1818 2.90909 11.1818H3.72103C3.8699 11.1818 4.01406 11.234 4.12842 11.3293L6.54886 13.3464C7.37783 14.0372 8.63637 13.4477 8.63637 12.3686V3.63139Z" />
        <path d="M10.9249 5.07549C10.6764 4.82698 10.2735 4.82698 10.025 5.07549C9.77644 5.32401 9.77644 5.72693 10.025 5.97545C10.5439 6.49436 10.8637 7.2094 10.8637 8.00035C10.8637 8.79129 10.5439 9.50633 10.025 10.0252C9.77644 10.2738 9.77644 10.6767 10.025 10.9252C10.2735 11.1737 10.6764 11.1737 10.9249 10.9252C11.6727 10.1774 12.1364 9.1424 12.1364 8.00035C12.1364 6.85829 11.6727 5.82333 10.9249 5.07549Z" />
      </g>
    </g>
  </Svg>
);
