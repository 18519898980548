import {
  Color,
  CustomerFragment,
  DocBaseFragment,
  DocChildFragment,
  DocSourceFragment,
  DoctypeFragment,
  DoctypeType,
  Role,
} from '@cycle-app/graphql-codegen';
import { toShortLocaleDateString } from '@cycle-app/utilities';

import { getFeedbackCustomerName } from './customers.util';

export const getEditCoverAction = (coverUrl: string | undefined): string => (coverUrl ? 'Update cover' : 'Add cover');

export const canHaveParent = (docType?: DoctypeFragment) => (docType?.parents?.edges ?? []).length > 0;

export const getDocKey = (productKey?: string | null, docPublicId?: number | null) => (
  productKey && docPublicId
    ? `${productKey}-${docPublicId}`
    : undefined
);

export const createDummyDoc = (doc: Partial<DocBaseFragment>): Partial<DocBaseFragment> => ({
  id: '',
  isDraft: true,
  title: '',
  threads: {
    edges: [{
      node: {
        commentsCount: 0,
      },
    }],
  },
  createdAt: '',
  doctype: {
    __typename: 'Doctype',
    id: '',
  },
  creator: {
    id: '',
    color: Color.A,
    email: '',
    role: Role.Admin,
  },
  childrenCount: 0,
  insightsCount: 0,
  docTargetsCount: 0,
  docTargetsAiCount: 0,
  attributes: {
    edges: [{
      node: {
        __typename: 'DocAttributeText',
        id: '',
        definition: {
          id: '',
          name: '',
          color: Color.A,
        },
      },
    }],
  },
  ...doc,
});

export const isCycleFileSource = (source?: Partial<DocSourceFragment> | null) => source?.__typename === 'SourceCycle' && source.fileSize != null;

export const isCycleWithoutFileNorUrlSource = (source?: Partial<DocSourceFragment> | null) => {
  if (source?.__typename !== 'SourceCycle') return false;

  /**
   * The Api is putting the origin url as source by default when we leave the
   * source as `undefined`
   */
  return source.url === window.location.origin && !source.fileSize;
};

export const shouldShowSource = (
  doc?: Partial<DocChildFragment>,
  docType?: DoctypeFragment,
) => {
  if (isCycleFileSource(doc?.source)) return true;
  return !doc?.isDraft &&
    !!doc?.source &&
    !!docType?.customer &&
    docType?.type === DoctypeType.Feedback;
};

export type MinimalDocDocType = { doctype: { id: string } };

export const getIsAtLeastOneInsightFromDocIds = (
  docIds: string[],
  getDoc: (docId: string) => MinimalDocDocType | undefined,
  docTypes: Record<string, DoctypeFragment>,
): boolean => (docIds.length ? docIds.some(id => docTypes[getDoc(id)?.doctype.id ?? '']?.type === DoctypeType.Insight) : false);

export const getIsAtLeastOneFeedbackFromDocIds = (
  docIds: string[],
  getDoc: (docId: string) => MinimalDocDocType | undefined,
  docTypes: Record<string, DoctypeFragment>,
): boolean => (docIds.length ? docIds.some(id => docTypes[getDoc(id)?.doctype.id ?? '']?.type === DoctypeType.Feedback) : false);

export const getIsAtLeastOneInsightFromDocs = (
  docs: MinimalDocDocType[],
  docTypes: Record<string, DoctypeFragment>,
): boolean => (docs.length ? docs.some(doc => docTypes[doc.doctype.id]?.type === DoctypeType.Insight) : false);

export const canHaveInsightChildren = (
  docType?: DoctypeFragment,
) => docType?.type === DoctypeType.Feedback || docType?.children?.edges.some(edge => edge.node.type === DoctypeType.Insight);

export const canHaveChildren = (
  docType?: DoctypeFragment,
) => !!docType?.children?.edges.filter(e => e.node.type !== DoctypeType.Insight).length;

export const getDefaultFeedbackTitle = (customer?: CustomerFragment | null) => {
  const name = getFeedbackCustomerName(customer);
  if (!name) return null;
  return `Feedback from ${name} (${toShortLocaleDateString()})`;
};

export const getDefaultChromeFeedbackTitle = (customer?: Partial<CustomerFragment> | null) => {
  const name = getFeedbackCustomerName(customer);
  if (!name) return null;
  return `Feedback from ${name} in Chrome (${toShortLocaleDateString()})`;
};
