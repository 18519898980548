import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { Cover, Root } from './ChangelogBuilderContentNoteBody.styles';

export const ChangelogBuilderContentNoteBody = () => {
  const { watch } = useChangelogBuilderFormContext();
  const contentSection = watch('contentSection');
  return (
    <Root $contentSection={contentSection}>
      <Cover />
      <h4>The new revolution</h4>
      <p>
        Our new sick feature is a game changer. In the world of efficiency, we bring something new,
        the “we never know efficiency”. Something unpredictable.
        This feature is quite extraordinary in a way that we actually never know if it&apos;s gonna work or not.
        Because like us, this feature is allowed to have up and downs.
      </p>
      <h4>Why now?</h4>
      <p>
        Because why not? Features have been working days and nights for us without ever complaining or having any time off.
        We decided to act upon it. And we hope we will lead the way into a world of random efficiency.
      </p>
    </Root>
  );
};
