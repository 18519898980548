import { Events } from 'src/constants/analytics.constants';
import { integrationsDataMap } from 'src/constants/integrations.constants';
import { useRemoveIntegration } from 'src/hooks/api/mutations/integrations/useRemoveIntegration';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { logError } from 'src/utils/errors.utils';
import { addToaster } from 'src/utils/toasters.utils';

import { Modal } from './UninstallIntegrationModal.styles';

import type { Integration } from 'src/types/integrations.types';

type UninstallIntegrationModalProps = {
  id: string;
  type: Integration;
  hide: VoidFunction;
  isVisible: boolean;
};

export const UninstallIntegrationModal = ({
  hide, isVisible, type, id,
}: UninstallIntegrationModalProps) => {
  const {
    remove, isLoading,
  } = useRemoveIntegration();

  const onRemoveIntegration = () => {
    remove(id)
      .then(() => {
        trackAnalytics(Events.IntegrationRemoved, { type });
        addToaster({
          title: 'Successfully uninstalled',
          message: `${integrationsDataMap[type].label} has been successfully uninstalled`,
        });
      })
      .catch(() => logError(Error(`Error on removing integration, integration id: ${id}`)));
  };

  return isVisible ? (
    <Modal
      title={integrationsDataMap[type].remove?.title ??
        `Uninstall ${integrationsDataMap[type].label}`}
      info={(
        <p>
          {integrationsDataMap[type].remove?.text ??
            `Are you sure you want to uninstall ${integrationsDataMap[type].label} integration?`}
        </p>
      )}
      hide={hide}
      onConfirm={onRemoveIntegration}
      loading={isLoading}
      confirmLabel="Uninstall"
    />
  ) : null;
};
