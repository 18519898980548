import { Badge } from '@cycle-app/ui';
import { Link } from 'react-router-dom';

import { PageId } from 'src/constants/routing.constant';
import { useCompaniesCount } from 'src/hooks/api/queries/customers/useCompaniesCount';
import { useCustomersCount } from 'src/hooks/api/queries/customers/useCustomersCount';
import { usePageId } from 'src/hooks/usePageId';
import { useUrl } from 'src/hooks/useUrl';

import { TabList, Tab } from './CustomersCommonTabList.styles';

export const CustomersCommonTabList = () => {
  const pageId = usePageId();
  const getUrl = useUrl();
  const { count: customerCount } = useCustomersCount();
  const { count: companyCount } = useCompaniesCount();

  return (
    <TabList>
      <Tab
        as={Link}
        to={getUrl(PageId.SettingsCustomers)}
        $active={PageId.SettingsCustomers === pageId}
      >
        People
        {customerCount ? <Badge>{customerCount}</Badge> : null}
      </Tab>
      <Tab
        as={Link}
        to={getUrl(PageId.SettingsCompanies)}
        $active={PageId.SettingsCompanies === pageId}
      >
        Companies
        {companyCount ? <Badge>{companyCount}</Badge> : null}
      </Tab>
    </TabList>
  );
};
