import { DocTargetFragment, UpdateInsightQuoteDocument } from '@cycle-app/graphql-codegen';
import { ButtonV2 as Button } from '@cycle-app/ui';
import { useEffect, useState } from 'react';

import { DropdownLayerProps } from 'src/components/DropdownLayer';
import { useSafeMutation } from 'src/hooks';
import useAppHotkeys from 'src/hooks/useAppHotkeys';

import { StyledDropdown, StyledCommentEditor, Footer } from './EditQuoteDropdown.styles';

type Props = Omit<DropdownLayerProps, 'content'> & {
  insight: DocTargetFragment;
};

export const EditQuoteDropdown = ({
  insight, hide, ...props
}: Props) => {
  const [updateQuote] = useSafeMutation(UpdateInsightQuoteDocument);
  const [value, setValue] = useState(insight.content ?? '');

  useEffect(() => {
    setValue(insight.content ?? '');
  }, [insight.content]);

  const submit = () => {
    if (!insight?.doc?.id || !insight.doc?.docSource?.doc?.id) return;
    hide?.();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateQuote({
      variables: {
        targetId: insight.doc.id,
        content: value,
        sourceId: insight.doc.docSource.doc.id,
      },
      optimisticResponse: {
        changeDocLink: {
          ...insight.doc,
          docSource: {
            ...insight.doc.docSource,
            content: value,
          },
        },
      },
      update: (cache, { data }) => {
        if (!data?.changeDocLink?.docSource || !insight) return;
        cache.modify({
          id: cache.identify(insight),
          fields: {
            content: () => data.changeDocLink?.docSource?.content ?? '',
          },
        });
      },
    });
  };

  useAppHotkeys('enter', submit);

  return (
    <StyledDropdown
      {...props}
      withPortal
      withWrapper={false}
      placement="bottom-start"
      hide={hide}
      onHide={() => setValue(insight.content ?? '')}
      content={(
        <>
          <StyledCommentEditor
            autofocus
            content={value}
            onUpdate={(data) => setValue(data.html)}
          />

          <Footer>
            <Button
              variant="naked"
              onClick={hide}
            >
              Cancel
            </Button>

            <Button
              onClick={submit}
            >
              Save
            </Button>
          </Footer>
        </>
      )}
    />
  );
};
