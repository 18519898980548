import { SearchIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCompanies } from 'src/hooks/api/queries/customers/useCompanies';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';

import { CustomersCommonTabList } from '../CustomersCommonTabList/CustomersCommonTabList';
import { CompanyAddButton } from './CompanyAddButton';
import {
  Tabs,
  Nav,
  Actions,
  SearchInput,
} from './Customers.styles';
import { CustomerListCompanies } from './CustomersListCompanies';

export const CustomerCompanies = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchText] = useDebounce(inputValue, INPUT_ONCHANGE_DEBOUNCE);
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    companies, pageInfo, isLoading, fetchNextPage,
  } = useCompanies({
    defaultSearch: searchText,
    productId,
  });

  return (
    <Tabs>
      <Nav>
        <CustomersCommonTabList />
        <Actions>
          <SearchInput
            iconBefore={<SearchIcon />}
            placeholder="Search…"
            value={inputValue}
            onChange={async (e) => setInputValue(e.target.value)}
          />
          <CompanyAddButton />
        </Actions>
      </Nav>
      <CustomerListCompanies
        companies={companies}
        onCompanyClick={openCompanyModal}
        isLoading={!searchText && isLoading && !companies.length}
        hasNextPage={pageInfo?.hasNextPage}
        loadMore={() => fetchNextPage()}
        searchText={searchText}
      />
    </Tabs>
  );
};
