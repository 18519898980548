import { DoctypeRelativeFragment } from '@cycle-app/graphql-codegen/generated';
import { Emoji } from '@cycle-app/ui';
import { FC } from 'react';

import { DialogModalProps } from 'src/components/DialogModal/DialogModal';
import { useOptimizedBooleanState } from 'src/hooks';
import useDoctypesMutations from 'src/hooks/api/mutations/useDoctypesMutations';

import { DialogModalStyled, TagStyled } from './DoctypeDeleteModal.styles';

interface Props extends Omit<DialogModalProps, 'title'> {
  doctype: DoctypeRelativeFragment;
}

export const InsightTypeDeleteModal: FC<React.PropsWithChildren<Props>> = ({
  doctype, hide, ...props
}) => {
  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirmation,
    setFalseCallback: setDeleteConfirmationFalse,
  }] = useOptimizedBooleanState(false);

  const {
    deleteDoctype, loading,
  } = useDoctypesMutations(doctype.id);

  return (
    <DialogModalStyled
      title="Delete insight type"
      info={(
        <>
          {'Are you sure you want to delete '}
          <TagStyled
            inline
            limitSize={false}
            icon={<Emoji emoji={doctype.emoji} />}
          >
            {doctype.name}
          </TagStyled>
          {' ?'}
          <br />
          All corresponding docs will be deleted. This can&apos;t be undone.
        </>
    )}
      confirmLabel="Delete"
      confirmMessage="Yes, permanently delete this insight type"
      isConfirmDisabled={!isDeleteConfirmed}
      onToggleConfirmation={toggleDeleteConfirmation}
      onConfirm={async () => {
        await deleteDoctype();
      }}
      loading={loading}
      autoHide={false}
      hide={() => {
        setDeleteConfirmationFalse();
        hide();
      }}
      {...props}
    />
  );
};
