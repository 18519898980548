import { CompanyFullFragment } from '@cycle-app/graphql-codegen';
import {
  THead,
  TBody,
  Tr,
  RowsSkeleton,
  TextHighlighter,
  InfiniteScroll,
  Tooltip,
} from '@cycle-app/ui';
import {
  ZendeskIcon,
  HubSpotIcon,
  IntercomIcon,
  PipedriveIcon,
  SnowflakeIcon,
} from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';

import { useProduct } from 'src/hooks';

import { CompanyActionsMenu } from './CompanyActionsMenu';
import {
  StyledTable,
  TdActions,
  StyledTr,
  StyledShyScrollbar,
  StyledTd,
  StyledTh,
  StyledThActions,
} from './CustomersListCompanies.styles';
import { CustomerTableCompanyCell } from './CustomerTableCompanyCell';

interface Props {
  companies: CompanyFullFragment[];
  className?: string;
  onCustomerClick?: (id: string) => void;
  onCompanyClick?: (id: string) => void;
  isLoading?: boolean;
  hasNextPage?: boolean;
  loadMore: VoidFunction;
  searchText: string;
  isFullPage?: boolean;
}

const buitInAttributeKeys = ['arr', 'numberOfEmployees', 'zendeskId', 'hubspotId', 'intercomId', 'pipedriveId', 'snowflakeId', 'customId'] as const;

type BuitInAttributeKeys = typeof buitInAttributeKeys[number];

const buitInAttributeData: Record<BuitInAttributeKeys, { name: string; icon?: JSX.Element }> = {
  arr: { name: 'ARR' },
  numberOfEmployees: { name: 'Employees' },
  zendeskId: {
    name: 'Zendesk ID',
    icon: <ZendeskIcon />,
  },
  hubspotId: {
    name: 'HubSpot ID',
    icon: <HubSpotIcon />,
  },
  intercomId: {
    name: 'Intercom ID',
    icon: <IntercomIcon />,
  },
  pipedriveId: {
    name: 'Pipedrive ID',
    icon: <PipedriveIcon />,
  },
  snowflakeId: {
    name: 'Snowflake ID',
    icon: <SnowflakeIcon />,
  },
  customId: { name: 'Custom ID' },
};

type Row = Record<string, string | number | boolean>;

export const CustomerListCompanies: FC<React.PropsWithChildren<Props>> = ({
  className, companies, onCompanyClick, isLoading, hasNextPage, loadMore, searchText, isFullPage = true,
}) => {
  const { product } = useProduct();
  const customAttributesColumns = nodeToArray(product?.companyAttributeDefinitions);

  const customAttributesRows = useMemo(() => {
    const rows: Record<string, Row> = {};

    for (const company of companies) {
      const row: Row = {};

      for (const edge of company.attributes.edges) {
        const {
          definition, value,
        } = edge.node;

        if (value?.__typename === 'AttributeTextValue') {
          row[definition.id] = value.textValue;
        }
        if (value?.__typename === 'AttributeNumberValue') {
          row[definition.id] = value.numberValue;
        }
        if (value?.__typename === 'AttributeCheckboxValue') {
          row[definition.id] = value.checkboxValue;
        }
      }
      rows[company.id] = row;
    }

    return rows;
  }, [companies]);

  return (
    <StyledShyScrollbar>
      <InfiniteScroll
        id="companies"
        isLoading={!!isLoading}
        hasMoreData={!!hasNextPage}
        loadMore={loadMore}
      >
        <StyledTable className={className} $isPrimary={isFullPage}>
          <THead>
            <Tr>
              <StyledTh>Name</StyledTh>
              <StyledTh>People</StyledTh>

              {buitInAttributeKeys.map(key => (
                // Built-in attributes
                <StyledTh key={key}>
                  {buitInAttributeData[key].icon}
                  {buitInAttributeData[key].name}
                </StyledTh>
              ))}

              {customAttributesColumns.map(column => {
                if (!('id' in column)) return null;
                return ((
                // Custom attributes
                  <StyledTh key={column.id}>
                    <Tooltip
                      content={column.description}
                      placement="top"
                      withPortal
                      withWrapper={false}
                      disabled={!column.description}
                    >
                      {column.name}
                    </Tooltip>
                  </StyledTh>
                ));
              })}

              <StyledThActions $size={50} />
            </Tr>
          </THead>

          <TBody>
            {companies.map(company => (
              <StyledTr key={company.id}>
                <StyledTd>
                  <CustomerTableCompanyCell
                    name={<TextHighlighter className="highlight" searchWords={[searchText]} textToHighlight={company.name ?? ''} />}
                    company={company}
                    onClick={() => onCompanyClick?.(company?.id ?? '')}
                  />
                </StyledTd>
                <StyledTd>{company.countCustomers ?? 0}</StyledTd>

                {buitInAttributeKeys.map(key => {
                  // Built-in attributes
                  const value = company[key];
                  return (
                    <StyledTd
                      key={key}
                      $type={typeof value === 'number' ? 'number' : 'text'}
                    >
                      {['arr', 'numberOfEmployees'].includes(key) && Number.isFinite(value)
                        ? Intl.NumberFormat('en-US').format(value as number)
                        : value}
                    </StyledTd>
                  );
                })}

                {customAttributesColumns.map(column => {
                  // Custom attributes
                  if (!('id' in column)) return null;
                  const value = (() => {
                    const v = customAttributesRows[company.id]?.[column.id];
                    if (v === true) return column.name;
                    if (v === false) return null;
                    return v;
                  })();
                  return (
                    <StyledTd
                      key={column.id}
                      $type={typeof value === 'number' ? 'number' : 'text'}
                    >
                      {value}
                    </StyledTd>
                  );
                })}

                <TdActions>
                  {!company.isDefault && <CompanyActionsMenu company={company} />}
                </TdActions>
              </StyledTr>
            ))}
            {isLoading && <RowsSkeleton rows={5} cols={3 + buitInAttributeKeys.length + customAttributesColumns.length} />}
          </TBody>
        </StyledTable>
      </InfiniteScroll>
    </StyledShyScrollbar>
  );
};
