import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

export const Form = styled.form`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  div {
    ${typo.body400}
  }
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const CancelButton = styled(Button).attrs({
  size: 'M',
  variant: 'nospace',
})`
  ${typo.body400}
`;
