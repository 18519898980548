import { Avatar, AvatarProps, CircleSkeleton, ShyScrollbarCss } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

interface StyledAvatarProps {
  $isMasked?: boolean;
  $size: NonNullable<AvatarProps['size']>;
}

const mask = css<StyledAvatarProps>`
  --mask: radial-gradient(circle at -${p => (p.$size < 32 ? '1' : '5')}px calc(var(--size) / 2), transparent calc(var(--size) / 2), white calc(var(--size) / 2));
  mask-image: var(--mask);
`;

export const StyledSkeleton = styled(CircleSkeleton) <StyledAvatarProps>`
  width: ${p => p.$size}px;
  height: ${p => p.$size}px;

  ${p => p.$isMasked && mask}
`;

export const StyledAvatar = styled(Avatar) <StyledAvatarProps>`
  --size: ${p => p.$size}px;
  --border-size: 0px;
  --padding: 0px;
  --shadow-size: 0px;

  ${p => p.$isMasked && mask}
`;

export const List = styled.ul<StyledAvatarProps>`
  ${ShyScrollbarCss}
  display: flex;
  white-space: nowrap;
  height: ${p => p.$size}px;

  li:not(:first-child) {
    margin-left: -9px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  flex-wrap: wrap;
`;

export const Info = styled.div`
  white-space: nowrap;
`;
