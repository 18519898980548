import { DoctypeType } from '@cycle-app/graphql-codegen';
import { FC, useCallback, KeyboardEvent, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';

import { EditorProps, Editor } from 'src/components/Editor';
import { useFullDoc } from 'src/hooks/api/useDoc';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useMe } from 'src/hooks/api/useMe';
import useRealtimeEditorConfig from 'src/hooks/doc/useRealtimeEditorConfig';
import { getEditorAi } from 'src/reactives';
import { setDocPanel, useGetDocPanel } from 'src/reactives/docPanel.reactive';
import { getLayer } from 'src/reactives/layer.reactive';
import { Layer } from 'src/types/layers.types';

import { DocHierarchy, StyledEditorSkeleton } from './DocEditor.styles';

import type { Editor as TipTapEditor } from '@tiptap/core';

if (!import.meta.env.VITE_WS_API_SYNC_SERVER) {
  throw new Error('The variable WS_API_SYNC_SERVER is not defined');
}

/* eslint-disable @typescript-eslint/indent */
type DocEditorProps = Pick<
  EditorProps,
  | 'parentRef'
  | 'autoFocus'
  | 'content'
  | 'applyTemplateOnDoctypeUpdate'
  | 'defaultDoctypeId'
  | 'onUpdate'
  | 'hideQuickActions'
  | 'disabledActions'
  | 'disabledShortcuts'
>;
/* eslint-enable @typescript-eslint/indent */

type Props = DocEditorProps & {
  docId?: string;
  displayLoader?: boolean;
  prefillTemplate?: boolean;
  isChildrenCreationEnabled?: boolean;
  applyTemplateOnMount?: boolean;
  draft?: boolean;
  onEditorReady?: (editor: TipTapEditor) => void;
  onEscape?: VoidFunction;
  hideHierarchy?: boolean;
  showAddTemplate?: boolean;
  showIntegrations?: boolean;
  hideEmptyBlock?: boolean;
};

const DocEditor: FC<React.PropsWithChildren<Props>> = ({
  docId,
  displayLoader = true,
  isChildrenCreationEnabled = true,
  defaultDoctypeId,
  draft,
  onEditorReady,
  onEscape,
  hideHierarchy,
  showAddTemplate,
  showIntegrations,
  hideEmptyBlock,
  ...editorProps
}) => {
  const previousDocId = useRef<string | null>();
  const { me } = useMe();
  const theme = useTheme();
  const { doc } = useFullDoc({
    docId: docId ?? null,
    draft,
  });
  const doctypeId = doc?.doctype.id ?? defaultDoctypeId;
  const doctype = useDoctype(doctypeId);
  const { isEditorInit } = useGetDocPanel();

  const {
    provider,
    yDoc,
    isSync,
  } = useRealtimeEditorConfig({
    docId,
    isEnabled: !draft,
    isEditorInit,
    disableEditorOnDisconnect: true,
  });

  useEffect(() => {
    if (isSync && !isEditorInit) {
      previousDocId.current = docId;
      setDocPanel({ isEditorInit: true });
    } else if (isEditorInit && docId !== previousDocId.current) {
      previousDocId.current = docId;
      setDocPanel({ isEditorInit: false });
    }
  }, [isEditorInit, isSync, docId]);

  const cursorName = `${me.firstName} ${me.lastName}`;

  const showHierarchy = !hideHierarchy && (doctype?.children?.edges ?? []).length > 0;

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (
      e.key !== 'Escape' ||
      getLayer()[Layer.DropdownZ1] ||
      getLayer()[Layer.Dropdown] ||
      !!getEditorAi().visible
    ) return;
    e.stopPropagation();
    onEscape?.();
  }, [onEscape]);

  const isLoading = displayLoader && (!isEditorInit || !isSync);

  const insightChildrenDocType = doctype?.children?.edges.find(edge => edge.node.type === DoctypeType.Insight)?.node;

  return (
    <>
      {isLoading && <StyledEditorSkeleton />}

      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <div {...onEscape && { onKeyDown }}>
          <Editor
            key={doc?.id}
            showAddTemplate={showAddTemplate}
            showIntegrations={showIntegrations}
            doc={doc}
            collaboration={yDoc ? { document: yDoc } : undefined}
            cursors={provider ? {
              provider,
              user: {
                color: theme.userColors.main,
                name: cursorName,
              },
            } : undefined}
            defaultDoctypeId={defaultDoctypeId}
            onEditorReady={onEditorReady}
            isDraft={draft}
            smallEmptyBlock={showHierarchy}
            hideEmptyBlock={hideEmptyBlock}
            {...editorProps}
          />
        </div>

        {showHierarchy && isEditorInit && (
          <DocHierarchy
            docId={docId}
            doctypeId={doctypeId}
            isChildrenCreationEnabled={isChildrenCreationEnabled}
            excludeDoctypeId={insightChildrenDocType?.id}
          />
        )}
      </div>
    </>
  );
};

export default DocEditor;
