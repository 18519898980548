import { HIGHLIGHT_DATA_ID, HIGHLIGHT_EXTENSION_NAME, HIGHLIGHT_DATA_TYPE, MarkType } from '@cycle-app/editor-extensions';
import { Editor } from '@tiptap/core';
import { Plugin, PluginKey, Transaction } from '@tiptap/pm/state';
import { AddMarkStep, RemoveMarkStep } from '@tiptap/pm/transform';
import { EditorView } from '@tiptap/pm/view';
import tippy, { Instance, Props } from 'tippy.js';

import { posToDOMRect } from 'src/utils/editor/editor.utils';
import { isElementOutside } from 'src/utils/elements.util';
import { logError } from 'src/utils/errors.utils';

export const MARK_TYPE: MarkType = 'insight';

const matchMarkType = (markType: string | null) => !markType || markType === MARK_TYPE;

export interface HighlightViewPluginProps {
  pluginKey: PluginKey | string;
  editor: Editor;
  element: HTMLElement;
  tippyOptions?: Partial<Props>;
  updateDelay?: number;
  onOpen?: (instance: Instance, props: {
    id: string;
    create?: boolean;
    text?: string;
  }) => void;
  onClose?: () => void;
}

type HighlightViewProps = HighlightViewPluginProps & {
  view: EditorView;
};

class HighlightView {
  public editor: Editor;

  public element: HTMLElement;

  public view: EditorView;

  public preventHide = false;

  public tippy: Instance | undefined;

  public tippyOptions?: Partial<Props>;

  public updateDelay: number;

  public mouseOutSetTimeout = 0;

  public onOpen: HighlightViewPluginProps['onOpen'];

  public onClose: HighlightViewPluginProps['onClose'];

  public isDestroyed: boolean;

  constructor({
    editor,
    element,
    onOpen,
    onClose,
    tippyOptions = {},
    updateDelay = 250,
    view,
  }: HighlightViewProps) {
    this.isDestroyed = false;
    this.editor = editor;
    this.element = element;
    this.view = view;
    this.updateDelay = updateDelay;
    this.view.dom.addEventListener('mouseenter', this.handleMouseover, true);
    this.view.dom.addEventListener('mouseleave', this.handleMouseout, true);
    this.element.addEventListener('mouseenter', this.handlePopperMouseover);
    this.element.addEventListener('mouseleave', this.handlePopperMouseout);
    this.editor.on('transaction', this.handleTransaction);
    this.tippyOptions = tippyOptions;
    this.element.style.visibility = 'visible';
    this.onOpen = onOpen;
    this.onClose = onClose;
  }

  handleTransaction = (attrs: { editor: Editor; transaction: Transaction }) => {
    try {
      const { transaction } = attrs;

      const docMarkSteps = transaction.steps.filter(isHighlightStep);
      if (!docMarkSteps.length) return;

      const addMarkStep = docMarkSteps.find(step => (
      // https://prosemirror.net/docs/ref/#transform.Step.toJSON
        (step.toJSON() as { stepType?: string })?.stepType === 'addMark' &&
      step.mark.attrs.id
      ));
      if (!addMarkStep) return;

      const {
        id, type,
      } = addMarkStep.mark.attrs;
      if (!id || !matchMarkType(type)) return;

      const markStepsWithId = docMarkSteps.filter(step => step.mark.attrs.id);
      const from = markStepsWithId[0]?.from;
      const to = markStepsWithId[markStepsWithId.length - 1]?.to;
      const text = transaction.doc.textBetween(from, to, ' ', ' ');

      const target = this.view.dom.querySelector(`[${HIGHLIGHT_DATA_ID}="${id}"]`);
      if (!target) return;

      this.createTooltip();
      if (!this.tippy) return;

      const pos = this.view.posAtDOM(target, -1);
      this.tippy.setProps({
        getReferenceClientRect: () => posToDOMRect(this.view, pos, pos),
      });
      this.tippy.show();
      this.onOpen?.(this.tippy, {
        id,
        create: true,
        text,
      });
    } catch (error) {
      if (error instanceof Error && error.toString().startsWith('NotFoundError')) {
        logError(error, {
          context: 'HighlightViewPlugin',
          tippySTate: this.tippy?.state,
        });
      } else {
        throw error;
      }
    }
  };

  handlePopperMouseover = () => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    window.clearTimeout(this.mouseOutSetTimeout);
  };

  handlePopperMouseout = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    if (
      event instanceof MouseEvent &&
      event.relatedTarget instanceof HTMLElement &&
      isElementOutside(this.element, event.relatedTarget) &&
      matchMarkType(event.relatedTarget.getAttribute(HIGHLIGHT_DATA_TYPE))
    ) {
      this.onClose?.();
      this.tippy?.hide();
    }
  };

  handleMouseout = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }
    if (
      event.target instanceof HTMLElement &&
      event.target.getAttribute(HIGHLIGHT_DATA_ID) &&
      matchMarkType(event.target.getAttribute(HIGHLIGHT_DATA_TYPE))
    ) {
      this.mouseOutSetTimeout = window.setTimeout(() => {
        this.onClose?.();
        this.tippy?.hide();
      }, 50);
    }
  };

  createTooltip() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;

    if (this.tippy || !editorIsAttached) return;

    this.tippy = tippy(editorElement, {
      duration: 0,
      getReferenceClientRect: null,
      content: this.element,
      interactive: true,
      trigger: 'manual',
      placement: 'top-start',
      hideOnClick: 'toggle',
      offset: [0, 0],
      ...this.tippyOptions,
    });
  }

  handleMouseover = (event: Event) => {
    if (this.isDestroyed) {
      this.cleanup();
      return;
    }

    const { target } = event;
    if (!(target instanceof HTMLElement)) return;

    const markType = target.getAttribute(HIGHLIGHT_DATA_TYPE);
    if (!matchMarkType(markType)) return;

    const id = target.getAttribute(HIGHLIGHT_DATA_ID);
    if (!id) return;

    window.clearTimeout(this.mouseOutSetTimeout);

    const node = this.view.dom.querySelector(`[${HIGHLIGHT_DATA_ID}="${id}"]`);
    if (!node) return;

    this.createTooltip();
    if (!this.tippy) return;

    const pos = this.view.posAtDOM(node, -1);
    this.tippy.setProps({
      getReferenceClientRect: () => posToDOMRect(this.view, pos, pos),
    });
    this.tippy.show();
    this.onOpen?.(this.tippy, {
      id,
    });
  };

  cleanup() {
    this.tippy?.destroy();
    this.view.dom.removeEventListener('mouseenter', this.handleMouseover, true);
    this.view.dom.removeEventListener('mouseleave', this.handleMouseover, true);
    this.element.removeEventListener('mouseenter', this.handlePopperMouseover);
    this.element.removeEventListener('mouseleave', this.handlePopperMouseout);
    this.editor.off('transaction', this.handleTransaction);
  }

  destroy() {
    this.isDestroyed = true;
    this.cleanup();
  }
}

export const HighlightViewPlugin = (options: HighlightViewPluginProps) => {
  return new Plugin({
    props: {
      transformPasted(slice) {
        slice.content.nodesBetween(0, slice.content.size, node => {
          if (node.marks.find(mark => mark.type.name === HIGHLIGHT_EXTENSION_NAME)) {
            Object.assign(node, { marks: node.marks.filter(mark => mark.type.name !== HIGHLIGHT_EXTENSION_NAME) });
          }
        });
        return slice;
      },
    },
    key: typeof options.pluginKey === 'string'
      ? new PluginKey(options.pluginKey)
      : options.pluginKey,
    view: view => new HighlightView({
      view,
      ...options,
    }),
  });
};

function isHighlightStep(step: unknown): step is (AddMarkStep | RemoveMarkStep) {
  return (
    (step as (Partial<AddMarkStep | RemoveMarkStep>)).mark?.type.name === HIGHLIGHT_EXTENSION_NAME
  );
}
