import { Tooltip, ActionButton, Spinner } from '@cycle-app/ui';
import { MinusIcon, TrashIcon, PenIcon, LinkIcon } from '@cycle-app/ui/icons';
import { nodeToArray, useToggle, getDocFullUrl, getDocSlug } from '@cycle-app/utilities';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useState, useMemo } from 'react';
import { isPresent } from 'ts-is-present';
import { useDebouncedCallback } from 'use-debounce';

import DialogModal from 'src/components/DialogModal/DialogModal';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { EditProperty, OnAddNewAttributeToDocArgs, OnValueSelectedParams } from 'src/components/EditProperty';
import { Events } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { useUpdateDocAssignee, useBulkChangeParent, useRemoveDocAssignee, useRemoveDocs } from 'src/hooks/api/mutations/updateDocHooks';
import { useAddNewAttributeToDoc, useChangeDocAttributeValue } from 'src/hooks/api/mutations/useChangeDocAttributeValue';
import { useRemoveDocAttributeValue } from 'src/hooks/api/mutations/useRemoveDocAttributeValue';
import { useAttributesFromSelection } from 'src/hooks/api/useAttributesFromSelection';
import { useUpdateReleaseNotes } from 'src/hooks/releases/useUpdateReleaseNotes';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { useUrl } from 'src/hooks/useUrl';
import { getDocType, useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { getSelection, setSelection, useGetBulkLoading, useSelection } from 'src/reactives/selection.reactive';
import { Layer } from 'src/types/layers.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { isAttributeScalar } from 'src/utils/attributes.util';
import { getDocFromCache } from 'src/utils/cache.utils';
import { copyToClipboard } from 'src/utils/clipboard.utils';

import { useIsReleasesEnabled } from '../../hooks/releases/useIsReleasesEnabled';
import { isInsight } from '../../utils/docType.util';
import { isReleasePublished } from '../../utils/release.utils';
import { addToaster } from '../../utils/toasters.utils';
import { ReleaseCreateModalBulk } from '../ReleaseModals';
import { motionProps, variantsContent } from './BulkActions.motion';
import { ActionButtons, Container, DeselectButton, Label } from './BulkActions.styles';

const BulkActions = () => {
  const { docTypes } = useGetDocTypes();
  const { isLoading } = useGetBulkLoading();
  const getUrl = useUrl();
  const [{ selected }, setSelectedState] = useSelection();
  const attributesFromSelection = useAttributesFromSelection();
  const { changeDocAttributeValue } = useChangeDocAttributeValue();
  const { removeDocAttributeValue } = useRemoveDocAttributeValue();
  const { updateDocAssignee } = useUpdateDocAssignee();
  const { removeDocAssignee } = useRemoveDocAssignee();
  const { removeDocs } = useRemoveDocs();
  const { addNewAttributeToDoc } = useAddNewAttributeToDoc();

  const [propertiesDropdown, setPropertiesDropdown] = useState(false);
  const [deleteModal, toggleDeleteModal] = useToggle(false);

  const bulkUpdateDebounce = useDebouncedCallback(bulkUpdate, INPUT_ONCHANGE_DEBOUNCE);
  const bulkChangeParent = useBulkChangeParent();

  const removeSelection = useCallback(() => setSelection({
    selected: [],
    lock: false,
  }), []);
  useAppHotkeys('escape', removeSelection);

  const hide = useCallback(() => {
    setPropertiesDropdown(false);
  }, [setPropertiesDropdown]);

  const onClickCopyURLs = useCallback(() => {
    const urls = selected.map(docId => {
      const doc = getDocFromCache(docId);
      if (!doc) return '';

      const docUrl = getDocFullUrl(
        getUrl(PageId.DocFullPage, { docSlug: getDocSlug(doc) }),
      );
      return docUrl;
    });

    copyToClipboard({
      text: urls.join('\n'),
      notification: selected.length === 1
        ? '1 doc URL copied to clipboard'
        : `${selected.length} doc URLs copied to clipboard`,
    });
    trackAnalytics(Events.DocBulkUrlCopied);
  }, [getUrl, selected]);

  // TODO: do that action in one mutation as soon as the api has implemented it
  const onAddNewAttributeToDoc = useCallback(async (args: OnAddNewAttributeToDocArgs) => {
    const [firstDocId, ...otherDocsIds] = getSelection().selected;
    if (!firstDocId) return;
    const firstDoc = getDocFromCache(firstDocId);
    if (!firstDoc) return;
    const newValueId = await addNewAttributeToDoc({
      doc: firstDoc,
      ...args,
    });

    if (!newValueId) return;

    await Promise.all(otherDocsIds.map((docId) => {
      const doc = getDocFromCache(docId);
      if (!doc) return null;
      return changeDocAttributeValue({
        doc,
        attributeDefinition: args.attributeDefinition,
        value: newValueId,
        // Providing textValue because other docs cache has not been updated with the new attribute value
        textValue: args.textValue,
      });
    }));
    trackAnalytics(Events.DocBulkUpdated);
  }, [addNewAttributeToDoc, changeDocAttributeValue]);

  const canHaveParent = useMemo(() => selected.every(docId => {
    const doc = getDocFromCache(docId);
    if (!doc) return false;
    const docType = docTypes[doc.doctype.id];
    return (docType?.parents?.edges ?? []).length > 0;
  }), [docTypes, selected]);

  const commonDoctypeParents = useMemo(() => {
    if (!canHaveParent) return [];

    return selected.reduce<Array<string>>((common, docId) => {
      const doc = getDocFromCache(docId);
      if (!doc) return common;
      const docType = docTypes[doc.doctype.id];
      const doctypeParents = nodeToArray(docType?.parents).map(d => d.id);

      return common.length ? doctypeParents.filter(doctypeId => common.includes(doctypeId)) : doctypeParents;
    }, []);
  }, [canHaveParent, selected, docTypes]);

  const allInsights = selected.every(id => {
    const doc = getDocFromCache(id);
    return isInsight(getDocType(doc?.doctype.id));
  });
  const selectionKind = allInsights ? 'insight' : 'doc';

  const selectedReleaseDocs = selected.map(docId => {
    const doc = getDocFromCache(docId);
    const isDoctypeRelease = !!getDocType(doc?.doctype.id)?.release;
    return {
      docId,
      isDoctypeRelease,
      releaseNote: doc?.releaseNote,
    };
  });

  const releaseIds = [...new Set(selectedReleaseDocs.map(doc => doc.releaseNote?.release.id))];

  const { updateReleaseNotes } = useUpdateReleaseNotes();
  const isReleasesEnabled = useIsReleasesEnabled();
  const hasPublishedRelease = selectedReleaseDocs.some(doc => isReleasePublished(doc.releaseNote?.release));

  const onReleaseUpdate = (releaseId: string) => {
    if (hasPublishedRelease) {
      addToaster({
        title: 'Operation not allowed on published release',
        message: 'Unpublish the release to make changes',
      });
      return null;
    }
    return updateReleaseNotes({
      releaseId,
      docIds: selectedReleaseDocs
        // Omit docs that have the same release to update.
        .filter(selectedDoc => selectedDoc.releaseNote?.release.id !== releaseId)
        .map(selectedDoc => selectedDoc.docId),
    });
  };

  return (
    <AnimatePresence>
      {!!selected.length && (
        <Container
          key="bulkActions"
          variants={variantsContent}
          {...motionProps}
        >
          <Tooltip
            placement="top"
            content="Unselect all"
            withPortal
          >
            <DeselectButton onClick={() => setSelectedState({
              selected: [],
              lock: false,
            })}
            >
              <MinusIcon />
            </DeselectButton>
          </Tooltip>
          <Label>
            {selected.length === 1 ? `1 ${selectionKind} selected` : `${selected.length} ${selectionKind}s selected`}
          </Label>
          <ActionButtons>
            <DropdownLayer
              visible={propertiesDropdown}
              hide={hide}
              placement="bottom"
              content={(
                <EditProperty
                  possibleAttributes={attributesFromSelection}
                  onValueUpdated={onValueUpdated}
                  onAssigneeUpdated={onAssigneeUpdated}
                  onParentUpdated={canHaveParent && commonDoctypeParents.length ? onParentUpdated : undefined}
                  onAddNewAttributeToDoc={onAddNewAttributeToDoc}
                  onReleaseUpdate={
                    // Enabled and all selected doctypes can have release
                    isReleasesEnabled && selectedReleaseDocs.every(selectedDoc => selectedDoc.isDoctypeRelease)
                      ? onReleaseUpdate
                      : undefined
                  }
                  hideReleaseId={
                    // Hide release in the options if all selected docs have the same release.
                    releaseIds.length === 1 ? releaseIds[0] : undefined
                  }
                  commonDoctypeParents={commonDoctypeParents}
                  hide={hide}
                  bulk
                />
              )}
              offsetY={20}
            >
              <ActionButton
                aria-label="bulk-actions-edit"
                size="L"
                tooltipPlacement="top"
                tooltip="Edit"
                onClick={() => setPropertiesDropdown(true)}
              >
                {isLoading ? <Spinner /> : <PenIcon />}
              </ActionButton>
            </DropdownLayer>
            <ActionButton
              size="L"
              tooltipPlacement="top"
              tooltip="Copy URLs"
              onClick={onClickCopyURLs}
            >
              <LinkIcon />
            </ActionButton>
            <ActionButton
              size="L"
              tooltipPlacement="top"
              tooltip="Delete"
              variant="warning"
              onClick={() => {
                if (hasPublishedRelease) {
                  addToaster({
                    title: 'Operation not allowed on published release',
                    message: 'Unpublish the release to make changes',
                  });
                  return;
                }
                toggleDeleteModal();
              }}
            >
              <TrashIcon />
            </ActionButton>
          </ActionButtons>
        </Container>
      )}

      {deleteModal && (
        <DialogModal
          key="deleteDialogModal"
          title={`Delete ${selected.length} docs`}
          confirmLabel="Delete"
          info={(
            <>
              {'Are you sure you want to delete '}
              {selected.length}
              {' selected '}
              docs?
              <br />
              They can&apos;t be restored.
            </>
          )}
          hide={toggleDeleteModal}
          onConfirm={bulkDelete}
          layer={Layer.Modal}
        />
      )}

      <ReleaseCreateModalBulk onReleaseCreated={onReleaseUpdate} />
    </AnimatePresence>
  );

  function onValueUpdated(selectedParams: OnValueSelectedParams) {
    if (isAttributeScalar(selectedParams.attributeDefinition)) {
      bulkUpdateDebounce(selectedParams);
    } else {
      bulkUpdate(selectedParams);
    }
  }

  function onAssigneeUpdated(userId: string | null, notCompatible: boolean) {
    getSelection().selected.forEach(async (docId) => {
      if (userId) {
        await updateDocAssignee(
          {
            docId,
            userId,
          },
          notCompatible,
        );
      } else {
        await removeDocAssignee({ docId }, notCompatible);
      }
    });

    if (notCompatible) {
      removeSelection();
    }
  }

  async function onParentUpdated(parentId: string | undefined) {
    hide();

    const doctypesChildId = [...new Set(
      getSelection().selected.map(docId => {
        const doc = getDocFromCache(docId);
        return doc?.doctype.id;
      }),
    )].filter(isPresent);

    await bulkChangeParent({
      parentId,
      doctypesChildId,
      docIds: getSelection().selected,
    });
  }

  function bulkUpdate({
    attributeDefinition,
    propertyValue,
    isValueRemoved,
    notCompatible,
  }: OnValueSelectedParams) {
    getSelection().selected.forEach(async (docId) => {
      const doc = getDocFromCache(docId);
      if (!doc) return;

      if (isValueRemoved) {
        const docAttribute = nodeToArray(doc.attributes).find(a => a.definition.id === attributeDefinition.id);

        if (docAttribute?.__typename === 'DocAttributeSingleSelect' && docAttribute.selectValue?.id) {
          await removeDocAttributeValue({
            doc,
            attributeDefinition,
            valueId: docAttribute.selectValue?.id,
            notCompatible,
          });
        }

        if (docAttribute?.__typename === 'DocAttributeCheckbox') {
          await removeDocAttributeValue({
            doc,
            attributeDefinition,
            valueId: docAttribute.definition.id,
            notCompatible,
          });
        }
      } else if (propertyValue) {
        await changeDocAttributeValue({
          doc,
          attributeDefinition,
          value: propertyValue,
          notCompatible,
        });
      }
    });

    if (notCompatible) {
      removeSelection();
    }
  }

  async function bulkDelete() {
    const toDelete = getSelection().selected;
    removeSelection();
    await removeDocs(toDelete);
    trackAnalytics(Events.DocBulkDeleted);
  }
};

export default BulkActions;
