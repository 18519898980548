import { AnalysesIcon } from '@cycle-app/ui';
import { useMeasure } from '@cycle-app/utilities';
import { UniqueIdentifier } from '@dnd-kit/core';
import { useState } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useNavigate, useProductAddOn } from 'src/hooks';
import { useMoveBoardInSectionsList } from 'src/hooks/api/mutations/useBoardSectionsMutations';
import { useSectionBoards } from 'src/hooks/boards/useSectionBoards';
import { getViewPageId, SectionParent } from 'src/utils/section.utils';
import { getBoardSlug } from 'src/utils/slug.util';

import { ItemViewType } from './ItemViewType';
import { ViewItem } from './ViewItem';
import {
  Container, Item, Title, Name, View, Total,
} from './ViewsList.styles';
import { ViewSortableItem } from './ViewSortableItem';
import { ViewsSortableContext } from './ViewsSortableContext';

type Props = {
  sectionParent: SectionParent;
};

export const ViewsList = ({ sectionParent }: Props) => {
  const {
    boards, sectionId,
  } = useSectionBoards(sectionParent);
  const { navigate } = useNavigate();
  const { isEnabled: isAnalysesEnabled } = useProductAddOn('ANALYSES');
  const showAnalyses = isAnalysesEnabled && sectionParent === 'insight';
  const viewsCount = boards.length + (showAnalyses ? 1 : 0);
  const [items, setItems] = useState<UniqueIdentifier[]>(boards.map(board => board.id));
  const { moveBoardInSectionList } = useMoveBoardInSectionsList();
  const measure = useMeasure<HTMLDivElement>();
  const isSmall = measure.rect ? measure.rect.width < 850 : null;
  return (
    <Container ref={measure.ref}>
      {isSmall !== null && (
        <>
          {showAnalyses && <AnalysesItem isSmall={isSmall} />}

          <ViewsSortableContext
            items={items}
            setItems={setItems}
            overlay={id => (
              <ViewItem
                sectionParent={sectionParent}
                boardId={id.toString()}
                isSmall={isSmall}
                isOverlay
              />
            )}
            onDragEnd={event => {
              if (!sectionId || !event.over) return;
              const activeId = event.active.id.toString();
              const overId = event.over.id.toString();
              if (activeId === overId) return;
              const boardIds = boards.map(board => board.id);
              const isAfter = boardIds.indexOf(overId) > boardIds.indexOf(activeId);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              moveBoardInSectionList({
                boardId: activeId,
                fromSectionId: sectionId,
                toSectionId: sectionId,
                position: isAfter ? {
                  after: overId,
                } : {
                  before: overId,
                },
              });
            }}
          >
            {props => items.map(id => (
              <ViewSortableItem
                id={id}
                key={id}
              >
                <ViewItem
                  sectionParent={sectionParent}
                  boardId={id.toString()}
                  isSmall={isSmall}
                  isDragging={props.isDragging}
                  onClick={event => {
                    const board = boards.find(b => b.id === id);
                    navigate(getViewPageId(sectionParent), { boardSlug: getBoardSlug(board) }, undefined, event?.metaKey);
                  }}
                />
              </ViewSortableItem>
            ))}
          </ViewsSortableContext>

          <Total>{`${viewsCount} views`}</Total>
        </>
      )}
    </Container>
  );
};

const AnalysesItem = ({ isSmall }: { isSmall: boolean }) => {
  const { navigate } = useNavigate();
  return (
    <Item
      $isSmall={isSmall}
      onClick={(event) => {
        navigate(PageId.Analyses, undefined, undefined, event?.metaKey);
      }}
    >
      <Title>
        <AnalysesIcon />
        <Name>Analyses</Name>
      </Title>

      <View>
        <ItemViewType
          viewType="dashboard"
          isSmall={isSmall}
        />
      </View>
    </Item>
  );
};
