import {
  UpdateMeDocument,
  ThemeType,
} from '@cycle-app/graphql-codegen';
import { ColorPicker, Button } from '@cycle-app/ui';
import { getTheme } from '@cycle-app/ui/utils/theme.util';
import { useState, useCallback } from 'react';
import { useTheme, ThemeProvider } from 'styled-components';

import { MentionNodeView } from 'src/components/Editor/NodeViews/Mention/MentionView.styles';
import { ThemePicker } from 'src/components/ThemePicker';
import { SystemPreferenceCheckbox } from 'src/components/ThemePicker/SystemPreferenceCheckbox';
import { useMe } from 'src/hooks/api/useMe';
import { useSyncThemeWithSystem } from 'src/hooks/useHandleSystemTheme';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getThemeConfig, setThemeConfig } from 'src/reactives/theme.reactive';
import { COLLABORATION_CURSOR_CARET_CLASS, COLLABORATION_CURSOR_LABEL_CLASS } from 'src/styles';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

import {
  Container,
  StyledAvatar,
  ColorSection,
  Preview,
  PreviewItem,
  ThemeSection,
  SectionTitle,
  PreviewLabel,
  PreviewHighlight,
  CursorsPreview,
  CollaborationCursorCaret,
  CollaborationCursorCaretWithName,
  Buttons,
} from './ApprearanceTab.styles';

const AppearanceTab = () => {
  const { me } = useMe();
  const theme = useTheme();
  const [updateMe, { loading }] = useSafeMutation(UpdateMeDocument);

  const [selectedUserColor, setSelectedUserColor] = useState(me.color);
  const [selectedThemeColor, setSelectedThemeColor] = useState<ThemeType>(convertLegacyLocalStorageValue(getThemeConfig().colorTheme));
  const [selectedSyncWithSystem, setSelectedSyncWithSystem] = useState(getThemeConfig().syncWithSystem);

  useSyncThemeWithSystem({
    setTheme: setSelectedThemeColor,
    isEnabled: selectedSyncWithSystem,
  });

  const currentTheme = getTheme(selectedThemeColor, selectedUserColor);
  const selectedMainColor = currentTheme.nuances[selectedUserColor].main;

  const onSave = useCallback(async () => {
    await updateMe({
      variables: {
        color: selectedUserColor,
      },
      optimisticResponse: {
        updateMe: {
          ...me,
          color: selectedUserColor,
        },
      },
    });

    setThemeConfig({
      syncWithSystem: selectedSyncWithSystem,
      colorTheme: selectedThemeColor,
    });
  }, [me, selectedThemeColor, selectedSyncWithSystem, selectedUserColor, updateMe]);

  return (
    <Container>
      <ThemeProvider theme={currentTheme}>

        <ColorSection>
          <SectionTitle>Choose your color</SectionTitle>
          <ColorPicker
            color={selectedUserColor}
            onClick={setSelectedUserColor}
          />

          <Preview>
            <PreviewItem>
              <PreviewLabel>Avatar</PreviewLabel>
              <StyledAvatar
                user={me}
                src={me.avatar?.url}
                size={40}
                userColor={selectedUserColor}
              />
            </PreviewItem>

            <PreviewItem>
              <PreviewLabel>Text highlight</PreviewLabel>
              <PreviewHighlight $highlightColor={theme.nuances[selectedUserColor].textHighlight}>
                <mark>Ship stuff </mark>
                people need.
              </PreviewHighlight>
            </PreviewItem>

            <PreviewItem>
              <PreviewLabel>Cursor</PreviewLabel>
              <CursorsPreview $color={selectedMainColor}>
                <CollaborationCursorCaretWithName>
                  <span className={COLLABORATION_CURSOR_CARET_CLASS}>
                    <span className={COLLABORATION_CURSOR_LABEL_CLASS}>John Doe</span>
                  </span>
                </CollaborationCursorCaretWithName>

                <p>
                  <CollaborationCursorCaret className={COLLABORATION_CURSOR_CARET_CLASS} />
                </p>
              </CursorsPreview>
            </PreviewItem>

            <PreviewItem>
              <PreviewLabel>Mention</PreviewLabel>
              <MentionNodeView style={{ marginTop: 18 }}>
                @John Doe
              </MentionNodeView>
            </PreviewItem>
          </Preview>
        </ColorSection>

        <ThemeSection>
          <SectionTitle>Choose your theme</SectionTitle>
          <ThemePicker
            theme={selectedThemeColor}
            onChange={(value) => {
              setSelectedSyncWithSystem(false);
              setSelectedThemeColor(value);
            }}
            syncWithSystem={selectedSyncWithSystem}
          />
        </ThemeSection>
      </ThemeProvider>

      <div style={{ marginTop: 16 }}>
        <SystemPreferenceCheckbox
          theme={selectedThemeColor}
          onChange={(value) => {
            setSelectedSyncWithSystem(false);
            setSelectedThemeColor(value);
          }}
          syncWithSystem={selectedSyncWithSystem}
          setSyncWithSystem={setSelectedSyncWithSystem}
        />
      </div>

      <Buttons>
        <Button
          size="M"
          onClick={onSave}
          isLoading={loading}
        >
          Save
        </Button>
      </Buttons>
    </Container>
  );
};

export default AppearanceTab;
