import DialogModal from 'src/components/DialogModal/DialogModal';
import { useAccessToken } from 'src/hooks/api/queries/api/useAccessTokens';
import { closeRemoveApiKeyModal, useGetSettingsApi } from 'src/reactives/settingsApi.reactive';

import { useRevokeAccessToken } from './useRevokeAccessToken';

export const ApiKeyRemoveModal = () => {
  const { openedRemoveApiKeyModal } = useGetSettingsApi();
  if (!openedRemoveApiKeyModal) return null;
  return <ApiKeyRemoveModalVisible tokenId={openedRemoveApiKeyModal} />;
};

const ApiKeyRemoveModalVisible = ({ tokenId }: { tokenId: string }) => {
  const { accessToken } = useAccessToken(tokenId);
  const revokeToken = useRevokeAccessToken(tokenId);
  return (
    <DialogModal
      title="Revoke API key"
      info={accessToken?.label ? `Are you sure you want to revoke ${accessToken.label}?` : undefined}
      hide={closeRemoveApiKeyModal}
      confirmLabel="Revoke"
      onConfirm={revokeToken}
    />
  );
};
