import { typo, Button, DropdownSelect, boxShadowZ1, ActionButton } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 964px;
  margin: 0 auto;
  padding-bottom: 32px;
`;

const reactFlowOverwrites = css`
  .react-flow__node {
    cursor: default;
    background: transparent;
    border: none;
    padding: 0;
    text-align: left;
    box-shadow: none !important;
    width: auto !important;
    height: auto !important;
    color: inherit;
  }

  .react-flow__handle {
    visibility: hidden;
  }

  .react-flow__edge {
    color: ${p => p.theme.colors.border.primary};
  }

  .react-flow__edge-path {
    stroke: currentColor;
    fill: none;
  }

  .react-flow__edge-circle {
    stroke: ${p => p.theme.colors.text.disabled};
    fill: ${p => p.theme.colors.background.primary};
  }
`;

export const IntegrationsContainer = styled.div<{ $showFooter: boolean }>`
  width: 303px;
  footer {
    margin-top: 16px;
    opacity: ${p => (p.$showFooter ? 1 : 0)};
    transition: opacity .2s ease-in-out;
  }
  &:hover {
    footer {
      opacity: 1;
    }
  }
`;

export const IntegrationsTitle = styled.h3`
  ${typo.headerLight}
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IntegrationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddButton = styled(Button).attrs({
  variant: 'light',
  size: 'L',
})`
  width: 100%;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  color: ${p => p.theme.colors.text.secondary};
  &:hover {
    color: ${p => p.theme.colors.text.secondary};
  }
  &:active {
    outline: none;
    color: ${p => p.theme.colors.text.primary};
    background-color: ${p => p.theme.colors.background.hover};
  }
  > svg {
    width: 10px;
    height: 10px;
  }
`;

export const Dropdown = styled(DropdownSelect)`
  [data-disabled=true] {
    filter: grayscale(1);
    opacity: 0.6;
  }
`;

export const Info = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  margin-left: 40px;
`;

export const FlowContainer = styled.div`
  ${reactFlowOverwrites}
  min-width: 800px;
`;

export const Handle = styled.div`
  width: 1px;
  height: 1px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 16px;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;

// New page classes

export const IntegrationSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Subtitle = styled.h2`
  ${typo.headerLight}
`;

export const AddIntegrationButton = styled(Button).attrs({ size: 'M' })`
  --bg: transparent;
  --color: ${p => (p.theme.isDark ? ColorTheme.White : ColorTheme.Cycle)};
  --border: transparent;
  box-shadow: none;

  &:hover {
    --bg: ${p => (p.theme.isDark ? ColorTheme.Grey800 : ColorTheme.Grey200)};
    --color: ${p => (p.theme.isDark ? ColorTheme.White : ColorTheme.Cycle)};
    --border: transparent;
    box-shadow: none;
  }
  &:active, &:focus {
    --bg: ${p => (p.theme.isDark ? ColorTheme.Grey700 : ColorTheme.Grey300)};
    --color: ${p => (p.theme.isDark ? ColorTheme.White : ColorTheme.Cycle)};
    --border: transparent;
    box-shadow: none;
  }
`;

export const SectionBody = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 780px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

type IntegrationButtonProps = {
  $isHoverDanger?: boolean;
};

export const IntegrationButton = styled(Button).attrs({
  variant: 'primary',
  size: 'S',
}) <IntegrationButtonProps>`
  display: flex;
  --bg: ${p => (p.theme.isDark ? ColorTheme.Grey800 : p.theme.colors.button.noColor.bg)};
  --color: ${p => (p.theme.isDark ? ColorTheme.White : p.theme.colors.button.noColor.color)};
  --border: ${p => (p.theme.isDark ? p.theme.colors.border.surprisingGreyDark : 'rgba(0,0,0,0.06)')};
  ${boxShadowZ1}
  padding: 4px 12px;
  border-radius: 6px;
  outline: 1px solid var(--border);
  background: var(--bg);
  ${typo.body500};
  border: none;

  &:hover {
    --bg: ${p => (p.$isHoverDanger ? p.theme.colors.ui.info.danger.bg : p.theme.colors.button.outlined.bgHover)};
    --color: ${p => (p.$isHoverDanger ? p.theme.colors.text.red : p.theme.colors.button.noColor.color)};
    --border: ${p => (p.theme.isDark ? p.theme.colors.border.surprisingGreyDark : 'rgba(0,0,0,0.06)')};
    outline: 1px solid var(--border);
  }
  &:focus {
    --bg: ${p => (p.$isHoverDanger
    ? darken(0.08, p.theme.colors.ui.info.danger.bg)
    : darken(0.08, p.theme.colors.button.outlined.bgHover))};
    outline: 1px solid var(--border);
  }
  &:active {
    --bg: ${p => (p.$isHoverDanger
    ? darken(0.12, p.theme.colors.ui.info.danger.bg)
    : darken(0.12, p.theme.colors.button.outlined.bgHover))};
    outline: 1px solid var(--border);
  }
`;

export const StyledActionButton = styled(ActionButton)`
  height: 24px;
  width: 24px;
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  min-height: 30vh;
`;

export const IconSentence = styled.p`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.disabled};
`;

export const IconsGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const LoadingButton = styled.div`
  padding: 12px 6px;
`;

export const MakersText = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

export const List = styled.ul`
  display: flex;
  white-space: nowrap;

  li:not(:first-child) {
    margin-left: -9px;
  }
`;
