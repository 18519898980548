import { SelectOption } from '@cycle-app/ui';
import { FC, useMemo, useCallback } from 'react';

import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { Truncate } from 'src/utils/css.styles';

import { Placeholder } from '../Filter.styles';
import { Container, StyledSelectPanel, MoreValues } from './FilterElements.styles';

interface Props {
  className?: string;
  dropdownLayer?: Layer;
  panelTitle: string;
  placeholder: string;
  options: SelectOption[];
  onOptionsAdded: (newOptions: SelectOption[]) => void;
  onOptionRemoved: (optionValue: string) => void;
  disabled?: boolean;
}
const MultiSelectFilterElement: FC<React.PropsWithChildren<Props>> = ({
  className = '',
  dropdownLayer = Layer.DropdownBoardConfig,
  children,
  panelTitle,
  placeholder,
  options,
  onOptionsAdded,
  onOptionRemoved,
  disabled = false,
}) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  const selectedOptions = useMemo(() => options.filter(o => o.selected), [options]);

  const onUnselectOption = useCallback((o: SelectOption) => onOptionRemoved(o.value), [onOptionRemoved]);
  const onSelectOption = useCallback((o: SelectOption) => onOptionsAdded([
    ...selectedOptions,
    {
      ...o,
      selected: true,
    },
  ]),
  [selectedOptions, onOptionsAdded]);

  const onSelectAll = useCallback(() => onOptionsAdded(options), [options, onOptionsAdded]);
  const onUnselectAll = useCallback(() => onOptionsAdded([]), [onOptionsAdded]);

  return (
    <DropdownLayer
      layer={dropdownLayer}
      placement="bottom-start"
      visible={isDropdownVisible}
      hide={hideDropdown}
      disabled={disabled}
      withWrapper={false}
      content={(
        <StyledSelectPanel
          isMulti
          title={panelTitle}
          options={options}
          onSelectOption={onSelectOption}
          onUnselectOption={onUnselectOption}
          onSelectAll={onSelectAll}
          onUnselectAll={onUnselectAll}
          toggleAllValuesVariant="toggle"
        />
      )}
    >
      <Container
        className={className}
        forceFocus={isDropdownVisible}
        onClick={toggleDropdown}
        disabled={disabled || options.length === 0}
      >
        {selectedOptions?.[0]
          ? (
            <>
              <Truncate>{children ?? selectedOptions[0].label}</Truncate>
              {selectedOptions.length > 1 && (
                <MoreValues>{`+${selectedOptions.length - 1}`}</MoreValues>
              )}
            </>
          )
          : (<Placeholder>{placeholder}</Placeholder>)}
      </Container>
    </DropdownLayer>
  );
};

export default MultiSelectFilterElement;
