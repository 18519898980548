import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiOutlineIcon = ({ ...props }: SvgProps) => {
  return (
    <Svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" data-no-fill xmlns="http://www.w3.org/2000/svg">
      <g stroke="currentcolor" strokeLinejoin="round" strokeWidth="1.5" clipPath="url(#a)" clipRule="evenodd">
        <path d="M16.5 11.25c-3.646 0-5.25 1.604-5.25 5.25 0-3.646-1.604-5.25-5.25-5.25 3.646 0 5.25-1.604 5.25-5.25 0 3.646 1.604 5.25 5.25 5.25ZM8.25 4.875c-2.344 0-3.375 1.031-3.375 3.375 0-2.344-1.031-3.375-3.375-3.375 2.344 0 3.375-1.031 3.375-3.375 0 2.344 1.031 3.375 3.375 3.375Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </Svg>
  );
};
