import { mobile } from '@cycle-app/utilities';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import { Color } from '../../theme/baseColors';
import { body400, body500, headerLarge, headerSmall } from '../../theme/typo';
import { Button } from '../Buttons/Button/Button';
import { FreePlan } from '../FreePlan';

export const ButtonPrimary = styled(Button)`
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: ${Color.Cycle};
  ${body500}

  &:hover, &:focus {
    background-color: ${lighten(0.05, Color.Cycle)};
  }
  &:active {
    background-color: ${lighten(0.1, Color.Cycle)};
  }
`;

export const ButtonSecondary = styled(ButtonPrimary).attrs({ variant: 'ternary' })`
  background-color: ${Color.Transparent};
  border: 1px solid ${p => p.theme.colors.border.primary};

  &:hover, &:focus {
    background-color: ${p => p.theme.colors.button.light.bgHover};
  }
`;

const sectionCSS = css`
  display: flex;

  ${ButtonPrimary} {
    flex: 1;
  }
`;

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 64px;
  border-radius: 8px;

  ${mobile} {
    padding: 32px;
  }

  > header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    > h2 {
      ${headerLarge}
      color: ${p => p.theme.colors.components.FreePlan.default.text.primary};
      text-align: center;
    }
    
    > p {
      ${body400}
      max-width: 100%;
      flex: none;
      color: ${p => p.theme.colors.components.FreePlan.default.text.secondary};
      text-align: center;
    }
  }

  > footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: ${p => p.theme.colors.text.disabled};

    > p {
      display: inline-flex;
      align-items: center;
      text-align: center;
      gap: 3px;
      font-weight: 500;

      svg {
        flex-shrink: 0;
      }
    }
  }
`;

export const FreePlanSection = styled(FreePlan)`
  padding: 16px;
`;

export const FeaturesSection = styled.section`
  ${sectionCSS}
  flex-direction: column;
  background-color: ${p => p.theme.colors.components.FreePlan.default.bg};
  padding: 12px 12px 27px 12px;
  border-radius: 8px;
  gap: 13px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;

    > h3 {
      ${headerSmall}
      color: ${p => p.theme.colors.text.primary};
      display: inline-flex;
      align-items: center;
      gap: 6px;
    }

    > p {
      ${body400}
      color: ${p => p.theme.colors.text.secondary}
    }
  }

  > ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 8px;
    gap: 8px;

    ${mobile} {
      grid-template-columns: 1fr;
    }

    li {
      display: flex;
      align-items: center;
      gap: 8px;
      ${body400}
      color: ${p => p.theme.colors.text.primary};

      svg {
        color: ${p => p.theme.colors.text.disabled};
      }
    }
  }
`;

export const ButtonsSection = styled.section`
  ${sectionCSS}
  gap: 16px;

  ${mobile} {
    flex-direction: column-reverse;
  }
`;

export const ChatCta = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
