import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

import { Label } from '../CheckboxInput/CheckboxInput.styles';
import {
  Container, Toggle, ToggleDot, StyledInput, LabelText, ToggleVariant,
} from './ToggleInput.styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: ReactNode;
  checked?: boolean;
  togglePosition?: 'left' | 'right';
  variant?: ToggleVariant;
}
export const ToggleInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    className,
    style,
    label,
    togglePosition = 'right',
    variant = 'primary',
    ...inputProps
  } = props;

  const toggle = (
    <Toggle>
      <ToggleDot />
    </Toggle>
  );

  return (
    <Container
      className={className}
      style={style}
      $isDisabled={!!inputProps.disabled}
      $variant={variant}
    >
      <StyledInput
        ref={ref}
        type="checkbox"
        $variant={variant}
        {...inputProps}
      />
      <Label htmlFor={inputProps.id}>
        {togglePosition === 'left' && toggle}
        {label && (
          <LabelText>{label}</LabelText>
        )}
        {togglePosition === 'right' && toggle}
      </Label>
    </Container>
  );
});
