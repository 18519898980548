import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const GeneralOutlineIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.46779 1C1.65719 1 1.00006 1.65713 1.00006 2.46773V5.629C1.00006 6.43961 1.65719 7.09673 2.46779 7.09673H5.62906C6.43967 7.09673 7.09679 6.43961 7.09679 5.629V2.46773C7.09679 1.65713 6.43967 1 5.62906 1H2.46779ZM2.35489 2.46773C2.35489 2.40538 2.40544 2.35483 2.46779 2.35483H5.62906C5.69142 2.35483 5.74196 2.40538 5.74196 2.46773V5.629C5.74196 5.69135 5.69142 5.7419 5.62906 5.7419H2.46779C2.40544 5.7419 2.35489 5.69135 2.35489 5.629V2.46773Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.371 1C9.56038 1 8.90326 1.65713 8.90326 2.46773V5.629C8.90326 6.43961 9.56038 7.09673 10.371 7.09673H13.5323C14.3429 7.09673 15 6.43961 15 5.629V2.46773C15 1.65713 14.3429 1 13.5323 1H10.371ZM10.2581 2.46773C10.2581 2.40538 10.3086 2.35483 10.371 2.35483H13.5323C13.5946 2.35483 13.6452 2.40538 13.6452 2.46773V5.629C13.6452 5.69135 13.5946 5.7419 13.5323 5.7419H10.371C10.3086 5.7419 10.2581 5.69135 10.2581 5.629V2.46773Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.00006 10.3711C1.00006 9.56045 1.65719 8.90332 2.46779 8.90332H5.62906C6.43967 8.90332 7.09679 9.56045 7.09679 10.3711V13.5323C7.09679 14.3429 6.43967 15.0001 5.62906 15.0001H2.46779C1.65719 15.0001 1.00006 14.3429 1.00006 13.5323V10.3711ZM2.46779 10.2582C2.40544 10.2582 2.35489 10.3087 2.35489 10.3711V13.5323C2.35489 13.5947 2.40544 13.6452 2.46779 13.6452H5.62906C5.69142 13.6452 5.74196 13.5947 5.74196 13.5323V10.3711C5.74196 10.3087 5.69142 10.2582 5.62906 10.2582H2.46779Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.371 8.90332C9.56038 8.90332 8.90326 9.56045 8.90326 10.3711V13.5323C8.90326 14.3429 9.56038 15.0001 10.371 15.0001H13.5323C14.3429 15.0001 15 14.3429 15 13.5323V10.3711C15 9.56045 14.3429 8.90332 13.5323 8.90332H10.371ZM10.2581 10.3711C10.2581 10.3087 10.3086 10.2582 10.371 10.2582H13.5323C13.5946 10.2582 13.6452 10.3087 13.6452 10.3711V13.5323C13.6452 13.5947 13.5946 13.6452 13.5323 13.6452H10.371C10.3086 13.6452 10.2581 13.5947 10.2581 13.5323V10.3711Z" />
  </Svg>
);
