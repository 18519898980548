import { IntegrationType, LinearProjectFragment, CreateLinearProjectDocument } from '@cycle-app/graphql-codegen';
import { SelectLine, Spinner, LinearProjectIcon, SelectOption, TextHighlighter } from '@cycle-app/ui';
import { useListNav } from '@cycle-app/utilities';
import { FC, useState } from 'react';

import { IntegrationEmptyStateLine } from 'src/components/Integrations/EmptyStateLine/IntegrationEmptyStateLine';
import { LineSpinnerContainer, SelectLinesWrapper, SelectWrapper } from 'src/components/Integrations/IntegrationsCommon.styles';
import { IntegrationNotInstalledLine } from 'src/components/Integrations/NotInstalledLine/IntegrationNotInstalledLine';
import { useSafeMutation } from 'src/hooks';
import useAppHotkeys from 'src/hooks/useAppHotkeys';
import { getLinearModal, setLinearModal } from 'src/reactives/linear.reactive';
import { projectInfos } from 'src/utils/linear.utils';

import { Infos } from '../DocLinear/DocLinear.styles';
import { LinearTeamsSearchDropdownMulti } from '../LinearTeamsSearchDropdown/LinearTeamsSearchDropdownMulti';
import { ShortcutsContainer, ShortcutStyled } from './LinearProjectsSearchList.styles';

const CREATE_VALUE = 'create-linear-project-value';

type Props = {
  isIntegrationActive: boolean;
  isLoading: boolean;
  onInsert: (project: LinearProjectFragment) => void;
  results: LinearProjectFragment[];
  search: string;
  docId?: string;
};

export const LinearProjectsSearchList: FC<React.PropsWithChildren<Props>> = ({
  isIntegrationActive,
  isLoading,
  results,
  search,
  docId,
  onInsert,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<{ value: string; selected?: boolean }[] | null>(null);
  const options: SelectOption[] = results.map(project => ({
    value: project.id,
    label: project.name || '',
    icon: (
      <LinearProjectIcon
        icon={project.icon}
        color={project.color}
        size={14}
      />
    ),
    end: (
      <Infos>
        {projectInfos(project.progress, project.issues.length)}
      </Infos>
    ),
    onSelect: () => onInsert(project),
  }));

  useAppHotkeys('command+enter', (e) => {
    e.stopPropagation();
    if (!docId) return;

    setLinearModal({
      docId,
      onCreate: (data) => {
        if (data.__typename === 'LinearProject') {
          onInsert(data);
        }
      },
      searchText: search,
      teamIds: selectedOptions?.map(option => option.value) || [],
      type: 'project',
    });
  }, {
    enabled: () => !!search.length && !getLinearModal().type,
    capture: true,
  });

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues: [...options.map((r) => r.value), CREATE_VALUE],
    onSelect: onResultSelected,
  });
  const [createLinearProject, { loading: isCreateLoading }] = useSafeMutation(CreateLinearProjectDocument);

  return (
    <div {...listProps}>
      {renderContent()}
      {renderCreate()}
    </div>
  );

  function renderContent() {
    if (!isIntegrationActive) return <IntegrationNotInstalledLine integrationType={IntegrationType.Linear} />;

    if (isLoading) return <LineSpinnerContainer><Spinner /></LineSpinnerContainer>;

    if (!results.length && search.length < 3) return <IntegrationEmptyStateLine />;

    return (
      <>
        <SelectLinesWrapper>
          {options.map(option => (
            <SelectLine
              key={option.value}
              label={(
                <TextHighlighter
                  searchWords={[search]}
                  textToHighlight={option.label || ''}
                  className="highlight"
                />
              )}
              isSelected={option.value === selected}
              endSlot={option.end}
              startSlot={option.icon}
              onSelect={() => option.onSelect?.()}
            />
          ))}
        </SelectLinesWrapper>
      </>
    );
  }

  function renderCreate() {
    return isIntegrationActive && !!search ? (
      <>
        <SelectLine
          startSlot={(
            <SelectWrapper>
              {isCreateLoading ? <Spinner /> : '+ New project'}
              <LinearTeamsSearchDropdownMulti
                isDisabled={isCreateLoading}
                selectedTeamIds={selectedOptions?.map(option => option.value) || []}
                onOptionsChange={setSelectedOptions}
              />
            </SelectWrapper>
          )}
          endSlot={(
            <ShortcutsContainer>
              <ShortcutStyled keys={['mod']} />
              +
              <ShortcutStyled keys={['enter']} />
              to add details
            </ShortcutsContainer>
          )}
          label={search}
          isSelected={CREATE_VALUE === selected}
          {...itemProps(CREATE_VALUE)}
        />
      </>
    ) : null;
  }

  async function onResultSelected(issueId: string | null) {
    if (issueId === CREATE_VALUE) {
      await onCreateInline();
    }
  }

  async function onCreateInline() {
    if (!search.trim() || !docId || !selectedOptions?.length) return;
    const { data } = await createLinearProject({
      variables: {
        docId,
        name: search,
        teamIds: selectedOptions.map(option => option.value),
      },
    });
    if (data?.createLinearProject) {
      onInsert(data.createLinearProject);
    }
  }
};
