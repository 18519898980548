import { mapAddOnsData } from 'src/constants/addOn.constants';

import { AddOnIcon } from '../AddOnIcon';
import { Container, Name } from './AddOnItem.styles';

import type { AddOn } from 'src/types/addOn.types';

type AddOnItemProps = {
  addOn: AddOn;
};

export const AddOnItem = ({ addOn }: AddOnItemProps) => (
  <Container>
    <AddOnIcon name={addOn.name} />
    <Name>
      {mapAddOnsData[addOn.name].name}
    </Name>
  </Container>
);
