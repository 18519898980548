import { focusEndContenteditable } from '@cycle-app/utilities';
import {
  useImperativeHandle,
  forwardRef, KeyboardEvent, useEffect, useRef, ClipboardEvent, ReactNode,
} from 'react';

import { setToasters } from 'src/reactives/toasters.reactive';

import { Content, Placeholder } from './ContentEditable.styles';

export interface Props {
  className?: string;
  focusEndOnMount?: boolean;
  initialValue?: string;
  value?: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  onEnter?: VoidFunction;
  onNext?: VoidFunction;
  placeholder?: string;
  placeholderContent?: ReactNode;
  tag?: 'h1' | 'h2' | 'h3' | 'span';
}

const ContentEditable = forwardRef<HTMLHeadingElement, Props>(({
  className,
  focusEndOnMount = true,
  isDisabled = false,
  onChange,
  onEnter,
  onNext,
  placeholder,
  placeholderContent,
  tag,
  ...props
}, ref) => {
  const inputRef = useRef<HTMLHeadingElement>(null);
  const initialValue = useRef(props.initialValue ?? '');

  useImperativeHandle(ref, () => inputRef.current!, [inputRef]);

  useEffect(() => {
    if (focusEndOnMount && inputRef.current && !isDisabled) {
      focusEndContenteditable(inputRef.current);
    }
  }, [focusEndOnMount, isDisabled]);

  return (
    <>
      <Content
        $isDisabled={isDisabled}
        ref={inputRef}
        as={tag}
        className={className}
        placeholder={placeholder}
        onInput={onInput}
        contentEditable={!isDisabled}
        suppressContentEditableWarning
        onPaste={(e: ClipboardEvent<HTMLHeadingElement>) => {
          e.preventDefault();

          if (isDisabled) return;

          // We explicitely want to paste the text of the clipboard, in case it would contains html
          const pastedText = e.clipboardData.getData('text');
          const selection = window.getSelection();

          if (!selection?.rangeCount) return;

          selection.deleteFromDocument();
          selection.getRangeAt(0).insertNode(document.createTextNode(pastedText));
          const newValue = selection.focusNode?.textContent;
          if (newValue) {
            onChange(newValue);
          }

          // Move the cursor after the pasted text
          if (!selection.focusNode) return;
          const range = document.createRange();
          range.setStart(selection.focusNode, selection.focusOffset);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.metaKey || e.ctrlKey || isDisabled) return;
          if (e.code === 'Tab') {
            e.preventDefault();
            onNext?.();
          } else if (e.code === 'Enter') {
            e.preventDefault();
            onEnter?.();
          }
        }}
        onBlur={() => setToasters({ areShortcutsEnabled: true })}
        onFocus={() => setToasters({ areShortcutsEnabled: false })}
      >
        {props.value ?? initialValue.current}
      </Content>
      {placeholderContent && <Placeholder>{placeholderContent}</Placeholder>}
    </>
  );

  function onInput(e: React.FormEvent<HTMLElement>) {
    if (isDisabled) return;

    onChange(e.currentTarget.innerText);
  }
});

export default ContentEditable;
