import { ReactNode, FC } from 'react';

import { Button } from '../Buttons/Button/Button';
import {
  Container, ButtonsGroup, Body, Header, Makers, PlanStatus,
} from './CurrentPlanSection.styles';

type Actions = 'manage' | 'info' | 'history';

export type CurrentPlanSectionProps = {
  action: Actions | null;
  status: 'active' | 'failed' | 'canceled';
  isLoadingStripePortal?: boolean;
  onViewBillingInformation?: (action: 'manage' | 'info' | 'history') => void;
  body: ReactNode;
  makers?: ReactNode;
};

export const CurrentPlanSection: FC<React.PropsWithChildren<CurrentPlanSectionProps>> = ({
  status,
  isLoadingStripePortal = false,
  onViewBillingInformation,
  body,
  action,
  makers,
}) => (
  <Container>
    <Header>
      {status === 'active' && <PlanStatus $status={status}>Your subscription is active</PlanStatus>}
      {status === 'failed' && <PlanStatus $status={status}>Payment failed - Please try again to keep access</PlanStatus>}
      {status === 'canceled' && <PlanStatus $status={status}>Subscription canceled</PlanStatus>}
      <Makers>
        {makers}
      </Makers>
    </Header>

    <Body>
      {body}
    </Body>

    {onViewBillingInformation && (
      <ButtonsGroup>
        <Button
          isLoading={action === 'manage' && isLoadingStripePortal}
          onClick={() => onViewBillingInformation('manage')}
          size="M"
        >
          Manage subscription
        </Button>
        <Button
          isLoading={action === 'info' && isLoadingStripePortal}
          onClick={() => onViewBillingInformation('info')}
          size="M"
          variant="secondary"
        >
          Billing information
        </Button>
        <Button
          isLoading={action === 'history' && isLoadingStripePortal}
          onClick={() => onViewBillingInformation('history')}
          size="M"
          variant="secondary"
        >
          Invoice history
        </Button>
      </ButtonsGroup>
    )}
  </Container>
);
