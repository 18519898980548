import { mobile, hover } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { ViewType } from '../../../../../graphql-codegen/generated';
import { DraggableCard } from '../DraggableCard/DraggableCard';

export const Cover = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 135px;
  object-fit: cover;
`;

export const Title = styled.span<{
  isEmpty?: boolean;
}>`
  font-size: 14px;
  word-break: break-word;
  ${p => p.isEmpty && css`color: ${p.theme.colors.text.disabled};`};
`;

export const Description = styled.span`
  font-size: 14px;
  word-break: break-word;
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  gap: 4px;
`;

export const ToolbarSection = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const StyledDraggableCard = styled(DraggableCard)<{ viewType: ViewType }>`
  position: relative;
  padding: ${p => (p.viewType === ViewType.Kanban ? '8px' : '4px 6px')};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: inherit;

  > div {
    display: flex;
  }
`;

export const InfoContent = styled.div<{ viewType?: ViewType }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${p => p.viewType === ViewType.List && css`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 24px;

    ${Title} {
      margin-right: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 50px;
      flex: 1;
    }

    ${Toolbar} {
      margin-top: 0;
    }
  `};
`;

export const Actions = styled.div<{ forceVisible?: boolean }>`
  button {
    opacity: ${p => (p.forceVisible ? 1 : 0)};
  }

  ${mobile} {
    button {
      opacity: 1;
      background-color: transparent;
    }
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  ${InfoContent} & {
    top: 0;
    right: 0;
  }

  ${hover} {
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${StyledDraggableCard}:not(:hover) & button {
      opacity: 0;
    }

    /* stylelint-disable-next-line selector-type-no-unknown */
    ${Info}:hover & {
      opacity: 1;
    }
  }

`;
