import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const HubSpotIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M18.022 8.1241V5.30558C18.3945 5.1315 18.7099 4.85521 18.9314 4.50887C19.153 4.16253 19.2716 3.76039 19.2735 3.34926V3.28459C19.2735 2.08586 18.3017 1.1141 17.1031 1.1141H17.0383C15.8396 1.1141 14.8678 2.08586 14.8678 3.28459V3.34926C14.8697 3.76039 14.9884 4.16253 15.2099 4.50887C15.4315 4.85521 15.7468 5.1315 16.1193 5.30558V8.1241C15.0488 8.28803 14.0406 8.73192 13.1968 9.4108L5.46689 3.38995C5.52201 3.19098 5.55076 2.98636 5.55401 2.78099C5.55495 2.29727 5.41242 1.82413 5.14443 1.42143C4.87644 1.01872 4.49503 0.704544 4.04846 0.518633C3.60189 0.332721 3.11021 0.283429 2.63563 0.37699C2.16104 0.470551 1.72486 0.702762 1.38226 1.04425C1.03966 1.38574 0.806034 1.82117 0.710934 2.29545C0.615834 2.76973 0.663532 3.26157 0.847994 3.70874C1.03246 4.15591 1.34539 4.53833 1.74723 4.80762C2.14906 5.07692 2.62173 5.22099 3.10545 5.22161C3.52863 5.21962 3.94387 5.10664 4.30972 4.89397L11.9215 10.8171C10.5221 12.9311 10.5595 15.6863 12.0158 17.7617L9.70069 20.0774C9.51346 20.0176 9.31859 19.9858 9.1221 19.9832C8.01336 19.9841 7.11515 20.8833 7.11544 21.992C7.11572 23.1007 8.0145 23.9993 9.12315 23.9996C10.2319 23.9999 11.1311 23.1017 11.132 21.993C11.1294 21.7965 11.0976 21.6016 11.0376 21.4145L13.3279 19.1233C15.3743 20.6987 18.1849 20.8345 20.3736 19.4636C22.5623 18.0926 23.6668 15.5045 23.1424 12.9758C22.618 10.447 20.5754 8.51164 18.022 8.124V8.1241ZM17.0727 17.394C16.6496 17.4054 16.2285 17.3319 15.8343 17.1778C15.44 17.0238 15.0806 16.7923 14.7774 16.497C14.4741 16.2018 14.233 15.8488 14.0684 15.4588C13.9038 15.0689 13.819 14.6499 13.819 14.2266C13.819 13.8033 13.9038 13.3844 14.0684 12.9944C14.233 12.6045 14.4741 12.2515 14.7774 11.9562C15.0806 11.661 15.44 11.4295 15.8343 11.2754C16.2285 11.1214 16.6496 11.0479 17.0727 11.0592C18.7778 11.1189 20.1295 12.5181 20.1304 14.2243C20.1312 15.9304 18.781 17.3309 17.0759 17.3924" fill="#FF7A59" />
  </Svg>

);
