import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FolderIcon = (props:SvgProps) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_d_3420_17346)">
      <path d="M3.33334 2C2.22878 2 1.33334 2.89543 1.33334 4V11.3333C1.33334 12.4379 2.22878 13.3333 3.33334 13.3333H12.6667C13.7713 13.3333 14.6667 12.4379 14.6667 11.3333V6C14.6667 4.89543 13.7713 4 12.6667 4H8.35681L7.61721 2.8906C7.24628 2.3342 6.6218 2 5.9531 2H3.33334Z" fill="url(#paint0_linear_3420_17346)" />
    </g>
    <defs>
      <filter id="filter0_d_3420_17346" x="-0.666667" y="0" width="17.3333" height="17.3333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.666667" />
        <feGaussianBlur stdDeviation="0.333333" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3420_17346" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3420_17346" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_3420_17346" x1="8.00001" y1="2" x2="8.00001" y2="13.3333" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0.5" />
      </linearGradient>
    </defs>
  </Svg>
);
