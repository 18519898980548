import { useApolloClient } from '@apollo/client';
import { FetchBoardsStarredDocument, BoardFragment } from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';

import { useMe } from 'src/hooks/api/useMe';
import { useProductBase } from 'src/hooks/api/useProduct';

const useGetStarredBoardFromCache = () => {
  const { cache } = useApolloClient();
  const product = useProductBase();
  const { me } = useMe();

  const getStarredBoardFromCache = useCallback(() => {
    if (!product) return null;

    const response = cache.readQuery({
      query: FetchBoardsStarredDocument,
      variables: {
        userId: me.id,
        pId: product.id,
      },
    });

    if (response?.node?.__typename !== 'Me') return null;

    return response.node.boards.edges;
  }, [cache, me.id, product]);

  return getStarredBoardFromCache;
};

type BoardEdge = {
  __typename?: 'BoardEdge' | undefined;
  node: {
    __typename: 'Board';
    id: string;
    name: string;
    emoji?: string | null | undefined;
    description?: string | null;
  };
};

const useAddStarredBoardToCache = () => {
  const { cache } = useApolloClient();
  const product = useProductBase();
  const { me } = useMe();

  const addStarredBoardToCache = useCallback((boardEdges: BoardEdge[]) => {
    if (!product) return;

    cache.writeQuery({
      query: FetchBoardsStarredDocument,
      variables: {
        userId: me.id,
        pId: product.id,
      },
      data: {
        node: {
          __typename: 'Me',
          id: me.id,
          boards: {
            __typename: 'BoardsConnection',
            edges: boardEdges,
          },
        },
      },
    });
  }, [cache, me.id, product]);

  return addStarredBoardToCache;
};

export const useCacheBoardStarred = () => {
  const product = useProductBase();
  const getStarredBoardFromCache = useGetStarredBoardFromCache();
  const addStarredBoardToCache = useAddStarredBoardToCache();

  const remove = useCallback((boardId: string) => {
    if (!product) return;

    const boardEdges = getStarredBoardFromCache();

    if (!boardEdges) {
      console.warn('Error during reading query');
      return;
    }

    const oldBoardIds = boardEdges.map(e => e.node.id);

    if (!oldBoardIds.includes(boardId)) return;

    const newBoardEdges = boardEdges.filter(e => e.node.id !== boardId);

    addStarredBoardToCache(newBoardEdges);
  }, [addStarredBoardToCache, getStarredBoardFromCache, product]);

  const add = useCallback((newBoard: BoardFragment) => {
    if (!product) return;

    const boardEdges = getStarredBoardFromCache();

    if (!boardEdges) {
      console.warn('Error during reading query');
      return;
    }

    const newBoardEdges = [...boardEdges];
    const index = newBoardEdges.findIndex(edge => edge.node.name.localeCompare(newBoard.name) >= 0);
    newBoardEdges.splice(Math.max(0, index), 0, {
      __typename: 'BoardEdge',
      node: {
        ...newBoard,
        __typename: 'Board',
      },
    });
    addStarredBoardToCache(newBoardEdges);
  }, [addStarredBoardToCache, getStarredBoardFromCache, product]);

  return {
    add,
    remove,
  };
};
