import { TextButtonCss, TextButton, typo } from '@cycle-app/ui';
import { InfoIconOutline, CaretIcon } from '@cycle-app/ui/icons';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: 1px dashed ${p => p.theme.colors.border.greyLight};
  z-index: -1;
  background-image:
    linear-gradient(0deg, rgba(255, 255, 255, 1) 15%, rgba(255, 255, 255, 0.20) 90%),
    linear-gradient(91deg, #A990FF 0%, #57CFEF 52.39%, #7FEA96 100%);


  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 55vh;
    background-image: linear-gradient(0deg, rgba(255,0,0,0) 10%, rgba(255,255,255,1) 70%);
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;

    &.cycle-circle {
      transform: translate(calc(-50% + 1px), calc(-50% + 45px));
    }
    &.three {
      transform: translate(calc(-50% + 0px), calc(-50% + 40px));
    }
    &.two {
      transform: translate(calc(-50% + 0px), calc(-50% + 35px));
    }
    &.one {
      transform: translate(calc(-50% + 0px), calc(-50% + 0px));
    }
  }
`;

export const StyledScrollableContent = styled.div`
  overflow-x: hidden;
  padding: 0;
  position: relative;
  background-color: ${p => p.theme.colors.ugradeLayout.bg};
`;

export const StyledInfoIconOutline = styled(InfoIconOutline)`
  height: 12px;
  width: 12px;
`;

export const StyledCaretIcon = styled(CaretIcon) <{ $isUp?: boolean }>`
  ${p => p.$isUp && css`transform: rotate(180deg);`}
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  position: relative;
`;

const StyledTextButtonCss = css`
  ${TextButtonCss}
  ${typo.body400}
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
  width: auto;

  svg {
    width: 10px;
    height: 10px;
  }
`;

export const StyledTextButton = styled(TextButton)`
  ${StyledTextButtonCss}
`;

export const BackButton = styled(Link)`
  ${StyledTextButtonCss}
  position: relative;
  z-index: 2;
`;

export const Centered = styled.div`
  flex: 1;
  max-width: 1180px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  z-index: 10;
`;

export const Main = styled.div`
  max-width: 400px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 83px;
`;

export const LogoContainer = styled.div`
  display: inline-flex;
  position: relative;
`;

export const Title = styled.h1`
  ${typo.headerLarge}
  margin-top: 24px;
`;

export const Desc = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.ugradeLayout.desc};
  margin: 12px auto 0;
  max-width: 400px;
`;
