import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${typo.body400};
  color: ${p => p.theme.colors.text.disabled};

  strong {
    font-weight: inherit;
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;

export const LabelIcon = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;
