import { typo, Input, Button } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const DivLabel = styled.div`
  ${typo.body400};
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
`;

export const Caret = styled(CaretIcon)`
  margin-left: auto;
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const Row = styled.div`
  display: flex;
  gap: 15px;
`;

export const InputStyled = styled(Input)`
  width: 100%;

  label {
    ${typo.body400}
  }
`;

export const SelectedTypeButton = styled(Button)`
  border: 1px solid ${p => p.theme.colors.border.primary};
  background-color: transparent;
  color: inherit;
  padding: 7px 8px;
  width: 100%;
  ${typo.body400};
`;
