import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { QuoteIcon } from '@cycle-app/ui/icons';
import { useEffect } from 'react';

import DocAction from 'src/components/DocAction/DocAction';
import { OpenInsightCreateModal } from 'src/components/DocHierarchy/DocHierarchyGroup/OpenInsightCreateModal';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { verifyQuotesLabel } from 'src/components/QuotesSummary';
import { navigateToDocPanelPage } from 'src/hooks';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { isFeedback } from 'src/utils/docType.util';

import { StyledDocHierarchyInsight, QuoteAction } from './DocInsights.styles';

type Props = {
  doc: DocBaseFragment;
  context?: 'doc-item';
  // eslint-disable-next-line react/no-unused-prop-types
  isReadOnly?: boolean;
};

export const DocQuotes = (props: Props) => {
  const {
    doc, context, isReadOnly = false,
  } = props;
  const docType = useGetDocType(doc.doctype.id);
  const count = isFeedback(docType) ? doc.docTargetsCount : doc.insightsCount;
  if (count === 0 && isReadOnly) return null;
  return (
    <>
      {count > 0 ? (
        <DocInsightsList
          {...props}
          context={context}
          count={count}
        />
      ) : (
        <DocInsightsEmpty
          {...props}
          context={context}
        />
      )}
      {isFeedback(docType) && !!doc.quotesCount && (
        <QuoteAction
          tooltipPlacement="top"
          label={verifyQuotesLabel(doc.quotesCount)}
          buttonIcon={<QuoteIcon size={14} hasGradient />}
          counter={doc.quotesCount}
          context={context}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            navigateToDocPanelPage(doc, { verifyQuotes: true }, e.metaKey);
          }}
        />
      )}
    </>
  );
};

const DocInsightsEmpty = ({
  doc, context,
}: Props) => {
  return (
    <OpenInsightCreateModal
      layer={Layer.Dropdown}
      parentDoc={doc}
      button={buttonProps => (
        <DocAction
          forceFocus={buttonProps.isOpen}
          tooltipPlacement="top"
          label="Add insight"
          buttonIcon={<QuoteIcon size={14} />}
          counter={0}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            buttonProps.onClick();
          }}
          context={context}
        />
      )}
    />
  );
};

type DocInsightsListProps = Props & {
  count: number;
};

const DocInsightsList = ({
  doc,
  context,
  count,
  isReadOnly = false,
}: DocInsightsListProps) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItem({ hoverDocId: null });
    }
  }, [isDropdownVisible]);

  return (
    <DropdownLayer
      visible={isDropdownVisible}
      hide={hideDropdown}
      layer={Layer.DocItemDropdown}
      disableDocPreview={false}
      placement="bottom-start"
      exitAnimation={false}
      content={(
        <StyledDocHierarchyInsight
          docId={doc.id}
          count={count}
          onClickInsight={hideDropdown}
          isReadOnly={isReadOnly}
        />
      )}
    >
      <DocAction
        forceFocus={isDropdownVisible}
        tooltipPlacement="top"
        label="Insights"
        buttonIcon={<QuoteIcon size={14} />}
        counter={count}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          toggleDropdown();
        }}
        context={context}
      />
    </DropdownLayer>
  );
};
