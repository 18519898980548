import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const VerifyQuotesIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M1.3335 5.33317C1.3335 3.86041 2.5274 2.6665 4.00016 2.6665H12.0002C13.4729 2.6665 14.6668 3.86041 14.6668 5.33317C14.6668 5.70136 14.3684 5.99984 14.0002 5.99984C13.632 5.99984 13.3335 5.70136 13.3335 5.33317C13.3335 4.59679 12.7366 3.99984 12.0002 3.99984H4.00016C3.26378 3.99984 2.66683 4.59679 2.66683 5.33317V10.6665C2.66683 11.4029 3.26378 11.9998 4.00016 11.9998H6.66683C7.03503 11.9998 7.3335 12.2983 7.3335 12.6665C7.3335 13.0347 7.03503 13.3332 6.66683 13.3332H4.00016C2.5274 13.3332 1.3335 12.1392 1.3335 10.6665V5.33317ZM12.0002 7.33317C12.3684 7.33317 12.6668 7.63164 12.6668 7.99984C12.6668 8.84437 12.8546 9.30037 13.1104 9.55624C13.3663 9.8121 13.8223 9.99984 14.6668 9.99984C15.035 9.99984 15.3335 10.2983 15.3335 10.6665C15.3335 11.0347 15.035 11.3332 14.6668 11.3332C13.8223 11.3332 13.3663 11.5209 13.1104 11.7768C12.8546 12.0326 12.6668 12.4886 12.6668 13.3332C12.6668 13.7014 12.3684 13.9998 12.0002 13.9998C11.632 13.9998 11.3335 13.7014 11.3335 13.3332C11.3335 12.4886 11.1458 12.0326 10.8899 11.7768C10.634 11.5209 10.178 11.3332 9.3335 11.3332C8.9653 11.3332 8.66683 11.0347 8.66683 10.6665C8.66683 10.2983 8.9653 9.99984 9.3335 9.99984C10.178 9.99984 10.634 9.8121 10.8899 9.55624C11.1458 9.30037 11.3335 8.84437 11.3335 7.99984C11.3335 7.63164 11.632 7.33317 12.0002 7.33317ZM12.0002 10.3133C11.9479 10.3776 11.8922 10.4396 11.8327 10.499C11.7732 10.5585 11.7113 10.6142 11.647 10.6665C11.7113 10.7188 11.7732 10.7745 11.8327 10.834C11.8922 10.8934 11.9479 10.9554 12.0002 11.0197C12.0524 10.9554 12.1082 10.8934 12.1676 10.834C12.2271 10.7745 12.289 10.7188 12.3534 10.6665C12.289 10.6142 12.2271 10.5585 12.1676 10.499C12.1082 10.4396 12.0524 10.3776 12.0002 10.3133Z"
      fill="currentcolor"
    />
    <rect
      x="4"
      y="5"
      width="6"
      height="1.25"
      rx="0.625"
      fill="currentcolor"
    />
    <rect
      x="4"
      y="7"
      width="4"
      height="1.25"
      rx="0.625"
      fill="currentcolor"
    />
    <rect
      x="11"
      y="10"
      width="2"
      height="1"
      fill="currentcolor"
    />
  </Svg>
);
