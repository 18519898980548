import styled, { css } from 'styled-components';

import { Color as ColorApi } from '../../../../../graphql-codegen/generated';
import { body400, caption500, tiny600 } from '../../../theme/typo';

export const Counter = styled.span`
  ${caption500};
  line-height: 1;
`;

export type ActionVariant = 'warning' | 'special' | 'secondary' | 'tertiary';

interface ContainerProps {
  $hasCounter: boolean;
  $size: 'S' | 'M' | 'L' | 'XL';
  $variant?: ActionVariant;
  $color?: ColorApi;
  $forceFocus?: boolean;
  $onColoredBg?: boolean;
}

export const Container = styled.button<ContainerProps>`
  --bg: ${p => p.theme.colors.actionButton.bg};
  --bgHover: ${p => p.theme.colors.actionButton.bgHover};
  --bgActive: ${p => p.theme.colors.actionButton.bgActive};
  --color: ${p => p.$color ?? p.theme.colors.actionButton.color};
  --colorHover: ${p => p.theme.colors.actionButton.colorHover};
  --size: 12px;
  background: var(--bg);
  color: var(--color);

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  padding: 4px;

  &:hover, &:focus-visible, &.force-focus {
    background-color: var(--bgHover);
    color: var(--colorHover);
    opacity: 1;
  }

  &:active {
    background-color: var(--bgActive);
    color: var(--colorHover);
  }

  svg {
    width: var(--size);
    height: var(--size);
  }

  ${p => p.$size === 'S' && css`
    --size: 8px;
    ${tiny600};
  `};

  ${p => p.$size === 'M' && css`
    ${tiny600};
  `};

  ${p => (p.$size === 'L' || p.$size === 'XL') && css`
    padding: 2px 4px;
    gap: 4px;
    ${body400};

    svg {
      width: 16px;
      height: 16px;
    }
  `};

  ${p => p.$size === 'XL' && css`
    height: 28px;
    width: 28px;
  `};

  ${p => p.disabled && css`
    pointer-events: none;
  `};

  ${p => p.$hasCounter && css`
    --bg: ${p.theme.colors.background.transparent};
    --bgHover: ${p.theme.colors.background.hover};
    --color: ${p.theme.colors.text.secondary};
    --colorHover: ${p.theme.colors.text.primary};
  `};

  ${p => p.$variant && css`
    --color: ${p.theme.colors.actionButton[p.$variant].color ?? p.theme.colors.text.disabled};
    --colorHover: ${p.theme.colors.actionButton[p.$variant].colorHover ?? p.theme.colors.text.primary};
    --bg: ${p.theme.colors.actionButton[p.$variant].bg ?? p.theme.colors.background.transparent};
    --bgHover: ${p.theme.colors.actionButton[p.$variant].bgHover ?? p.theme.colors.background.transparent};
  `};

  ${p => p.$variant === 'secondary' && `
    --color: ${p.theme.colors.actionButton.secondary.color};
    --colorHover: ${p.theme.colors.actionButton.secondary.colorHover};
    --bg: ${p.theme.colors.actionButton.secondary.bg};
    --bgHover: ${p.theme.colors.actionButton.secondary.bgHover};
    --bgActive: ${p.theme.colors.actionButton.secondary.bgActive};
  `};

  ${p => p.$variant === 'tertiary' && css`
    --color: ${p.theme.colors.actionButton.tertiary.color};
    --colorHover: ${p.theme.colors.actionButton.tertiary.colorHover};
    --bg: ${p.theme.colors.background.transparent};
    --bgHover: ${p.theme.colors.actionButton.tertiary.bgHover};
    --bgActive: ${p.theme.colors.actionButton.tertiary.bgActive};
  `};

  ${p => p.$onColoredBg && css`
    --color: ${p.theme.colors.actionButton.onColoredBg.color};
    --colorHover: ${p.theme.colors.actionButton.onColoredBg.colorHover};
    --bgHover: ${p.theme.colors.actionButton.onColoredBg.bgHover};
  `}

  &[disabled] {
    --bgActive: var(--bg);
    --bgHover: var(--bg);
    --colorHover: var(--color);
    cursor: default;
  }
`;
