import { WebhookIcon } from '@cycle-app/ui/icons';

import { DOCS_WEBHOOKS_URL } from 'src/constants/docs.constants';
import { setSettingsApi } from 'src/reactives/settingsApi.reactive';

import { Section, SectionTitle, Info, Button, DocLink } from '../SettingsAPI.styles';
import { WebhookModal } from './WebhookModal';
import { WebhookRemoveModal } from './WebhookRemoveModal';
import { WebhooksList } from './WebhooksList';

export const WebhooksSection = () => (
  <Section>
    <header>
      <SectionTitle>
        <WebhookIcon />
        Webhooks
      </SectionTitle>

      <Info>
        Send workspace update to different third-party apps that don’t have a dedicated integration
      </Info>
    </header>

    <WebhooksList />

    <footer>
      <Button onClick={() => setSettingsApi({ openedEditWebhookpModal: '' })}>
        Create new
      </Button>

      <DocLink href={DOCS_WEBHOOKS_URL} target="_blanck">
        Documentation
      </DocLink>
    </footer>

    <WebhookModal />
    <WebhookRemoveModal />
  </Section>
);
