import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const ExpandIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.8427 2.18314C13.8907 2.23422 13.9277 2.29187 13.9538 2.35308C13.9836 2.42296 14.0001 2.49988 14.0001 2.58064V6.19355C14.0001 6.51423 13.7401 6.77419 13.4194 6.77419C13.0987 6.77419 12.8388 6.51423 12.8388 6.19355V3.98244L9.61491 7.20627C9.38815 7.43303 9.02051 7.43303 8.79375 7.20627C8.56699 6.97952 8.56699 6.61188 8.79375 6.38512L12.0176 3.16129H9.80649C9.48581 3.16129 9.22584 2.90133 9.22584 2.58064C9.22584 2.25996 9.48581 2 9.80649 2H13.4194C13.5732 2 13.7131 2.05983 13.817 2.15749C13.8258 2.16577 13.8344 2.17433 13.8427 2.18314ZM3.16129 9.80645C3.16129 9.48577 2.90133 9.22581 2.58065 9.22581C2.25996 9.22581 2 9.48577 2 9.80645V13.4193C2 13.5731 2.05983 13.7131 2.15749 13.817C2.16577 13.8258 2.17433 13.8343 2.18314 13.8426C2.23422 13.8906 2.29187 13.9277 2.35308 13.9537C2.42296 13.9835 2.49988 14 2.58065 14H6.19356C6.51425 14 6.77421 13.74 6.77421 13.4194C6.77421 13.0987 6.51425 12.8387 6.19356 12.8387H3.98245L7.2063 9.61488C7.43305 9.38812 7.43305 9.02048 7.2063 8.79372C6.97954 8.56696 6.6119 8.56696 6.38514 8.79372L3.16129 12.0175V9.80645Z" fill="#242424" />
  </Svg>
);
