import { ActionButton } from '@cycle-app/ui';
import { TrashIcon } from '@cycle-app/ui/icons';

import { useAccessTokens } from 'src/hooks/api/queries/api/useAccessTokens';
import { setSettingsApi } from 'src/reactives/settingsApi.reactive';

import { ItemList, ItemCard, ItemCardSkeleton, ItemTitle } from '../SettingsAPI.styles';

export const ApiKeysList = () => {
  const query = useAccessTokens();
  if (!query.loading && query.accessTokens.length === 0) return null;
  return (
    <ItemList>
      {query.loading && (
        <>
          <ItemCardSkeleton />
          <ItemCardSkeleton />
        </>
      )}

      {query.accessTokens.map(accessToken => (
        <ItemCard
          key={accessToken.id}
          onClick={() => setSettingsApi({ openedEditApiKeyModal: accessToken.id })}
        >
          <ItemTitle>{accessToken.label}</ItemTitle>

          <ActionButton
            tooltip={`Revoke ${accessToken.label || 'this API key'}`}
            onClick={e => {
              e.stopPropagation();
              setSettingsApi({ openedRemoveApiKeyModal: accessToken.id });
            }}
          >
            <TrashIcon size={16} />
          </ActionButton>
        </ItemCard>
      ))}
    </ItemList>
  );
};
