import { useApolloClient } from '@apollo/client';
import {
  BoardWithDraftConfigDocument,
  CreateBoardConfigDraftDocument,
  PublishBoardConfigDraftDocument,
  RevertBoardConfigDraftDocument,
  DocQueryFragmentDoc,
  DocQueryFragment,
  RevertUnsavedBoardConfigDraftDocument,
  BoardWithConfigDocument,
  namedOperations,
  BoardWithFullConfigFragment,
  BoardConfigQueryVariables,
} from '@cycle-app/graphql-codegen';
import { ERROR_CODE } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useBoard } from 'src/hooks/api/useBoard';
import { useSafeAsyncLazyQuery } from 'src/hooks/useSafeAsyncLazyQuery';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { setBoardConfigModal } from 'src/reactives/boardConfig.reactive';
import { getOptimisticDraftBoardConfig } from 'src/utils/boardConfig/boardConfig.util';
import { defaultPagination } from 'src/utils/pagination.util';

export const useCreateDraftBoardConfig = () => {
  const [fetchBoardConfig] = useSafeAsyncLazyQuery<{ node: BoardWithFullConfigFragment }, BoardConfigQueryVariables>(BoardWithConfigDocument, {
    fetchPolicy: 'cache-first',
  });

  const [createDraftBoardConfigMutation, { loading }] = useSafeMutation(CreateBoardConfigDraftDocument);

  const createDraftBoardConfig = useCallback(async (boardId: string) => {
    const boardConfigData = await fetchBoardConfig({
      variables: {
        id: boardId,
        ...defaultPagination,
      },
    });

    const boardConfig = boardConfigData?.node.publishedBoardConfig;
    if (!boardConfig) return null;

    const result = await createDraftBoardConfigMutation({
      variables: {
        boardConfigId: boardConfig.id,
      },
      optimisticResponse: {
        createDraftBoardConfig: getOptimisticDraftBoardConfig(boardConfig),
      },
      update: (cache, { data }) => {
        if (!data?.createDraftBoardConfig) return;

        cache.writeQuery({
          query: BoardWithDraftConfigDocument,
          data: {
            node: {
              id: boardId,
              draftBoardConfig: data.createDraftBoardConfig,
            },
          },
        });

        // Merging docs from published config to avoid loosing the data with auto cache merging
        if (boardConfig?.docQuery) {
          cache.writeFragment({
            fragment: DocQueryFragmentDoc,
            fragmentName: 'DocQuery',
            id: data.createDraftBoardConfig.id,
            data: {
              __typename: 'BoardConfig',
              id: data.createDraftBoardConfig.id,
              docQuery: {
                ...boardConfig.docQuery,
                ...data.createDraftBoardConfig.docQuery,
              } as DocQueryFragment['docQuery'],
            },
          });
        }
      },
    });
    setBoardConfigModal({ disabled: false });
    return result;
  }, [createDraftBoardConfigMutation, fetchBoardConfig]);

  return {
    createDraftBoardConfig,
    isLoading: loading,
  };
};

// Make it stand-alone as useBoardConfig() needs to be inside a board context,
// and it only needs draftBoardConfigId.
export const usePublishBoardConfigMutation = (draftBoardConfigId: string) => useSafeMutation(PublishBoardConfigDraftDocument, {
  variables: {
    boardConfigId: draftBoardConfigId,
    ...defaultPagination,
  },
});

export default function useDraftBoardConfigMutations(draftBoardConfigId: string, boardID?: string) {
  const client = useApolloClient();
  const board = useBoard(boardID);
  const maybeBoardId = useBoardConfig(ctx => ctx.boardId);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const boardId = maybeBoardId ?? '';
  const [publishBoardConfig, { loading: loadingPublish }] = usePublishBoardConfigMutation(draftBoardConfigId);

  const [revertDraftConfig, { loading: loadingRevert }] = useSafeMutation(RevertBoardConfigDraftDocument, {
    variables: {
      boardConfigId: draftBoardConfigId,
    },
    ...boardConfig && board && {
      optimisticResponse: {
        revertBoardConfigDraft: {
          __typename: 'BoardConfig',
          id: draftBoardConfigId,
        },
      },
    },
    refetchQueries: [namedOperations.Query.boardWithConfig],
    update: (cache) => {
      cache.writeQuery({
        query: BoardWithDraftConfigDocument,
        data: {
          node: {
            id: boardId,
            draftBoardConfig: null,
          },
        },
      });
    },
  });

  const removeDraftConfigFromCache = () => {
    client.cache.writeQuery({
      query: BoardWithDraftConfigDocument,
      data: {
        node: {
          id: boardId,
          draftBoardConfig: null,
        },
      },
    });
  };

  // In this mutation, the PRODUCT_NOT_FOUND error occurs when, for some reason, a draft config is already reverted in the backend.
  // In this case, the error can be ignored and the draft config removed from the cache.
  const [revertUnsavedDraftConfig, { loading: loadingRevertUnsaved }] = useSafeMutation(RevertUnsavedBoardConfigDraftDocument, {
    ignoreErrors: [ERROR_CODE.PRODUCT_NOT_FOUND],
    variables: {
      boardConfigId: draftBoardConfigId,
    },
    ...boardConfig && board && {
      optimisticResponse: {
        revertUnsavedBoardConfigDraft: {
          __typename: 'BoardConfig',
          id: draftBoardConfigId,
        },
      },
    },
    update: () => {
      removeDraftConfigFromCache();
    },
    onError: (error) => {
      if (error.message === ERROR_CODE.PRODUCT_NOT_FOUND) {
        removeDraftConfigFromCache();
      }
    },
  });

  return {
    publishBoardConfig,
    revertUnsavedDraftConfig,
    revertDraftConfig,
    loading: {
      publish: loadingPublish,
      revertUnsaved: loadingRevertUnsaved,
      revert: loadingRevert,
    },
  };
}
