import { typo, Button, DropdownCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${DropdownCss};
  cursor: default;
  min-width: 280px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: 1px solid ${p => p.theme.colors.border.surprisingGreyDark};
  gap: 8px;
`;

export const TopContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  
  div:has(> svg) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Bottom = styled.div`
  display: flex;
  padding: 12px;
  gap: 8px;
`;

export const UserInfo = styled.div`
  border: none;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  span {
    user-select: all;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    line-height: 20px;
  }
`;

export const Role = styled.button`
  color: ${p => p.theme.colors.text.secondary};
  background: ${p => p.theme.colors.background.tertiary};
  margin-right: 4px;
  height: 20px;
  padding: 0 6px;
  border-radius: 4px;
  ${typo.caption500}
  text-transform: lowercase;
  cursor: pointer;
  :first-letter {
    text-transform: uppercase;
  }
`;

export const Name = styled.span`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Email = styled.span`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const CTA = styled(Button).attrs({
  variant: 'secondary',
  useUserColor: false,
})`
  border: 1px solid ${p => p.theme.colors.border.surprisingGreyDark};
  color: ${p => p.theme.colors.text.primary};

  &:focus {
    background: ${p => p.theme.colors.button.secondary.bg};
  }
`;
