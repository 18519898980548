import { Flex } from '@cycle-app/ui';
import { useState } from 'react';

import { useOptimizedBooleanState } from 'src/hooks';

import { Chat } from '../OnboardingLayout/Chat';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Title } from '../OnboardingLayout/OnboardingLayout.styles';
import { Policy } from '../OnboardingLayout/Policy';
import { ConfirmEmailForm } from './ConfirmEmailForm';
import { Body, Content } from './OnboardingStepAccount.styles';
import { VerifyEmailForm } from './VerifyEmailForm';

export const OnboardingStepAccount = () => {
  const [isConfirmForm, {
    setTrueCallback: showConfirmForm, setFalseCallback: showVerifyForm,
  }] = useOptimizedBooleanState(false);
  const [email, setEmail] = useState('');
  return (
    <OnboardingLayout
      isContentGrow
      main={(
        <Flex $justify="center">
          <Content>
            <Body>
              <Title>{isConfirmForm ? 'Confirm your email' : 'Set up your account'}</Title>
              <div style={{ marginTop: '64px' }}>
                {isConfirmForm
                  ? <ConfirmEmailForm email={email} onBack={showVerifyForm} />
                  : (
                    <VerifyEmailForm
                      defaultValue={email}
                      onSuccess={(emailValue) => {
                        setEmail(emailValue);
                        showConfirmForm();
                      }}
                    />
                  )}
              </div>
              {!isConfirmForm && <Policy />}
            </Body>
          </Content>
        </Flex>
      )}
      root={!isConfirmForm && <Chat />}
    />
  );
};
