import { FC } from 'react';

import { DocMentionCreationModal } from 'src/components/DocMentionCreationModal/DocMentionCreationModal';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { ShortcutEditor } from 'src/types/shortcuts.types';

import { Trigger } from './DocMentionLastUsed.styles';

type Props = {
  children: JSX.Element;
  shortcut?: ShortcutEditor;
  isDisabled?: boolean;
};

export const DocMentionLastUsed: FC<React.PropsWithChildren<Props>> = ({
  children, isDisabled,
}) => {
  const [isModalOpen, {
    toggleCallback,
    setFalseCallback,
  }] = useOptimizedBooleanState(false);

  return (
    <>
      <Trigger {...!isDisabled && { onClick: toggleCallback }}>
        {children}
      </Trigger>
      <DocMentionCreationModal
        isOpen={isModalOpen}
        onClose={setFalseCallback}
      />
    </>
  );
};
