import { IntegrationFullFragment, IntegrationType } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';

import { useProductIntegrations } from 'src/hooks';
import { useGetCustomDocTypes } from 'src/reactives/docTypes.reactive';

import { CycleSourceItem } from './CycleSourceItem';
import { Container, List, Row, ColumnHeader, InfoIcon } from './FeedbackAutopilot.styles';
import { SourceItem } from './SourceItem';

export const FeedbackAutopilot = () => {
  const sources = useProductIntegrations().sourcesByStatus.installed
    // Put Slack first
    .sort(a => (a.integrationType === IntegrationType.Slack ? -1 : 1));
  const doctypes = useGetCustomDocTypes();
  const doctypesList = Object.values(doctypes).map(d => d.name).join(', ');

  return (
    <Container>
      <Row>
        <ColumnHeader>
          Extract quotes
          <Tooltip
            content="This will automatically extract quotes for every new feedback captured from the selected source"
            placement="top"
            withWrapper={false}
          >
            <InfoIcon />
          </Tooltip>
        </ColumnHeader>

        <ColumnHeader>
          Categorize
          <Tooltip
            content={`This will automatically link quotes to relevant insights (${doctypesList})`}
            placement="top"
            withWrapper={false}
          >
            <InfoIcon />
          </Tooltip>
        </ColumnHeader>

        <ColumnHeader>
          Create features
          <Tooltip
            content="This will automatically create new features when no existing feature was found for a certain quote"
            placement="top"
            withWrapper={false}
          >
            <InfoIcon />
          </Tooltip>
        </ColumnHeader>

        <ColumnHeader>
          Mark processed
          <Tooltip
            content="This will automatically mark every new feedback as processed for the selected source"
            placement="top"
            withWrapper={false}
          >
            <InfoIcon />
          </Tooltip>
        </ColumnHeader>
      </Row>

      <List>
        <CycleSourceItem />
        {sources.map(source => {
          const type = source.integrationType;
          const provider = getProviderWithAutopilot(source.integration);
          return (
            <SourceItem
              key={type}
              type={type}
              integrationId={source.integration?.id}
              providerId={provider?.id}
              extractInsights={provider?.extractInsights}
              linkToRoadmap={provider?.linkToRoadmap}
              markFeedbackAsProcessed={provider?.markFeedbackAsProcessed}
              createParent={provider?.createParent}
            />
          );
        })}
      </List>
    </Container>
  );
};

const getProviderWithAutopilot = (integration: IntegrationFullFragment | undefined) => {
  if (!integration?.provider) return null;
  if (!('extractInsights' in integration.provider)) return null;
  return integration.provider;
};
