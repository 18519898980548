import { TrialPlan, Progress } from '@cycle-app/ui';
import {
  Container as FreePlanContainer,
  TextSecondary as FreePlanTextSecondary,
} from '@cycle-app/ui/components/FreePlan/FreePlan.styles';
import { FlashIcon, AiIcon, CloseIcon } from '@cycle-app/ui/icons';
import { memo } from 'react';

import { MAX_MONTHLY_AI_QUERIES } from 'src/constants/ai.constants';
import { useProductAddOn } from 'src/hooks';
import { useAiQueriesSubscription } from 'src/hooks/api/useAiQueriesSubscription';
import { useCurrentBilling, useProduct } from 'src/hooks/api/useProduct';
import { setLimitationsModal, useAiCounter } from 'src/reactives';
import { useProductStatus } from 'src/reactives/product.reactive';
import { setUpgradeModal } from 'src/reactives/upgradeModal.reactive';

import { UpgradeButton, FooterSection, AiQueriesTitle, CloseButton } from './Sidebar.styles';

export const SidebarBilling = memo(() => {
  const billing = useCurrentBilling();
  const unlimitedAiQueriesAddOn = useProductAddOn('UNLIMITED_AI_QUERIES');
  const { productStatus } = useProductStatus();
  const { product } = useProduct();

  if (productStatus === 'trial') {
    return (
      <TrialPlan
        upgradeLink={(
          <UpgradeButton onClick={() => setUpgradeModal({ isOpened: true })}>
            <FlashIcon />
            Upgrade
          </UpgradeButton>
        )}
        endOfTrial={billing?.endOfFreeTrial}
      />
    );
  }

  if (productStatus === 'pro-cancelled') {
    return (
      <TrialPlan
        endOfTrial={product?.subscriptionPeriodEnd}
        title="Subscription canceled"
      />
    );
  }

  return (unlimitedAiQueriesAddOn.isEnabled || !unlimitedAiQueriesAddOn.isLoaded) ? null : <AiCounter />;
});

const AiCounter = () => {
  useAiQueriesSubscription();
  const { product } = useProduct();
  const billing = useCurrentBilling();
  const numberQueries = product?.nbAiQueries || 0;
  const addOnUnlimitedAiQueries = useProductAddOn('UNLIMITED_AI_QUERIES');
  const [{ isHidden }, setAiCounter] = useAiCounter();

  if (isHidden && numberQueries < MAX_MONTHLY_AI_QUERIES) return null;

  return (
    <FreePlanContainer
      danger={false}
      warning={false}
      onClick={() => {
        if (!addOnUnlimitedAiQueries.isEnabled) {
          setLimitationsModal({
            action: 'USE_ADD_ON',
            brand: 'UNLIMITED_AI_QUERIES',
          });
        }
      }}
    >
      <header>
        <AiQueriesTitle>
          <AiIcon size={12} />
          AI queries
        </AiQueriesTitle>
        {numberQueries < MAX_MONTHLY_AI_QUERIES && (
          <CloseButton
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setAiCounter({ isHidden: true });
            }}
          >
            <CloseIcon />
          </CloseButton>
        )}
      </header>
      <section>
        <Progress
          type="bar"
          useCycleColor
          percentage={(numberQueries * 100) / MAX_MONTHLY_AI_QUERIES}
          // eslint-disable-next-line no-nested-ternary
          state={numberQueries >= 100
            ? 'danger'
            : numberQueries === MAX_MONTHLY_AI_QUERIES - 1
              ? 'warning'
              : 'default'}
        />
      </section>
      {billing && (
        <FooterSection>
          <FreePlanTextSecondary danger={false} warning={false}>
            {`${numberQueries}/${MAX_MONTHLY_AI_QUERIES} · ${billing.remainingDays} day${billing.remainingDays > 1 ? 's' : ''} left`}
          </FreePlanTextSecondary>
        </FooterSection>
      )}
    </FreePlanContainer>
  );
};
