import { isDevEnv } from './env.util';

export const changelogUrl = (productSlug: string, customDomain: string | null | undefined) => {
  if (customDomain) {
    return `https://${customDomain}`;
  }
  return (isDevEnv()
    ? `http://localhost:3000/${productSlug}`
    : `https://changelog.cycle.app/${productSlug}`);
};
