import { typo } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import styled from 'styled-components';

const CELL_WIDTH = 160;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const List = styled.div`
  > * + * {
    position: relative;
    margin-top: 25px;
    :before {
      content: '';
      position: absolute;
      top: -13px;
      height: 1px;
      width: 100%;
      background: ${p => p.theme.colors.border.primary};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ColumnHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  user-select: text;
  width: ${CELL_WIDTH}px;
`;

export const RowHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  user-select: text;
  ${typo.body500}
  >[data-popover] {
    display: flex;
    align-items: center;
  }
`;

export const Cell = styled.div`
  width: ${CELL_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoIcon = styled(InfoIconOutline).attrs({ size: 16 })`
  color: ${p => p.theme.colors.text.disabled};
`;
