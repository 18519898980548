import { FC, MouseEvent } from 'react';

import { Color as ColorApi } from '../../../../graphql-codegen/generated';
import { CheckedDot } from '../CheckedDot/CheckedDot';
import { Container } from './ColorPicker.styles';

export interface ColorPickerProps {
  className?: string;
  color?: ColorApi | null;
  onClick?: (color: ColorApi) => void;
  small?: boolean;
  variant?: 'user' | 'property';
}
export const ColorPicker: FC<React.PropsWithChildren<ColorPickerProps>> = ({
  className,
  color,
  small,
  onClick,
  variant = 'user',
}) => {
  return (
    <Container className={className}>
      {Object
        .values(ColorApi)
        .map((c) => (
          <CheckedDot
            key={c}
            color={c}
            hasIcon={c === color}
            small={small}
            onClick={onColorClicked(c)}
            variant={variant}
          />
        ))}
    </Container>
  );

  function onColorClicked(selectedColor: ColorApi) {
    return (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick?.(selectedColor);
    };
  }
};
