import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ReleaseNoteCard } from './ReleaseNoteCard';

export const ReleaseNoteCardSortable = ({
  noteId, isDisabled, isChangelogEnabled,
}: {
  noteId: string;
  isDisabled?: boolean;
  isChangelogEnabled: boolean;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    active,
  } = useSortable({
    id: noteId,
    disabled: isDisabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ReleaseNoteCard
      noteId={noteId}
      ref={setNodeRef}
      style={style}
      isPlaceholder={active?.id === noteId}
      isChangelogEnabled={isChangelogEnabled}
      handleDragProps={{
        ...attributes,
        ...listeners,
      }}
    />
  );
};
