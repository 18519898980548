import { TrashIcon } from '@cycle-app/ui/icons';
import { TableOfContentData } from '@tiptap-pro/extension-table-of-content';
import { useState, useEffect, useRef } from 'react';

import { EditorDragMenu } from 'src/components/EditorDragMenu';
import { deleteNodeRange } from 'src/utils/editor/editor.utils';

import {
  Container,
  List,
  ListItem,
  ItemLink,
  EmptyState,
} from './TableOfContentNodeView.styles';

import type { NodeViewProps } from '@tiptap/react';

export const TableOfContentNodeView = ({
  editor,
  selected,
  node,
  getPos,
}: NodeViewProps) => {
  const [data, setData] = useState<TableOfContentData>([]);
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHandler = () => {
      const content = editor.storage.tableOfContent?.content;
      if (content) {
        setData(content);
      }
    };

    updateHandler();

    editor.on('update', updateHandler);
    editor.on('selectionUpdate', updateHandler);

    return () => {
      editor.off('update', updateHandler);
      editor.off('selectionUpdate', updateHandler);
    };
  }, [editor]);

  const onDelete = () => deleteNodeRange({
    editor,
    node,
    getPos,
  });

  return (
    <Container draggable="true" ref={nodeRef}>
      <List
        contentEditable={false}
        $isActive={editor.isEditable && selected}
      >

        {editor.isEditable && (
          <EditorDragMenu
            tooltip={{
              content: 'Click to open menu',
              title: 'Move to reorder',
            }}
            options={[{
              label: 'Delete',
              onSelect: onDelete,
              value: 'delete',
              icon: <TrashIcon />,
              variant: 'danger',
            }]}
          />
        )}

        {data.length ? data.map((item) => (
          <ListItem key={item.id} $level={item.level}>
            <ItemLink
              isActive={item.isActive}
              onClickCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const target = document.querySelector(`[data-toc-id="${item.id}"]`);
                if (!target) return;
                target.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
            >
              {item.textContent || 'Untitled'}
            </ItemLink>
          </ListItem>
        )) : (
          <EmptyState>
            Add headings to create a table of content
          </EmptyState>
        )}

      </List>
    </Container>
  );
};
