import { Tooltip, TooltipProps } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';
import { Fragment, ReactNode } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { isParentOfInsight } from 'src/utils/docType.util';
import { getNativeEmoji } from 'src/utils/emoji.util';

import { Container, Row } from './SettingsRoadmapsLinkedTo.styles';

type ItemProps = {
  label: ReactNode;
  tooltip: TooltipProps['content'];
};

const Item = ({
  label, tooltip,
}: ItemProps) => (
  <Tooltip
    withWrapper={false}
    withPortal
    placement="bottom"
    content={tooltip}
  >
    <strong>{label}</strong>
  </Tooltip>
);

export const SettingsRoadmapsLinkedTo = ({ doctypeId }: {
  doctypeId: string;
}) => {
  const docType = useGetDocType(doctypeId);
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const isReleasesEnabled = useIsReleasesEnabled();
  if (!docType) return null;
  const items: (ItemProps & { key: string })[] = [{
    key: 'assignee',
    label: 'Assignee',
    tooltip: 'The assignee of a roadmap doc is the product person in charge of getting it done',
  }];

  if (isParentOfInsight(docType)) {
    items.push({
      key: 'insight',
      label: 'Insight',
      tooltip: `${getNativeEmoji(docType.emoji)} ${docType.name} can be linked to insights`,
    });
  }

  if (docType.release && isReleasesEnabled) {
    items.push({
      key: 'release',
      label: 'Release',
      tooltip: `${getNativeEmoji(docType.emoji)} ${docType.name} has the release property and can be added to the changelog`,
    });
  }

  if (isLinearInstalled && isRoadmapsEnabled) {
    items.push({
      key: 'linear',
      label: 'Linear',
      tooltip: 'Link Linear projects and issues to your docs to sync their statuses',
    });
  }

  const lastItem = items.pop();
  if (!lastItem) return null;

  return (
    <Container>
      <Row>
        <LinkIcon size={14} />
        <span>
          {'Linked to '}
          {items.map((item, index) => (
            <Fragment key={item.key}>
              <Item
                label={item.label}
                tooltip={item.tooltip}
              />
              {index === items.length - 1 ? ' & ' : ', '}
            </Fragment>
          ))}

          <Item
            label={lastItem.label}
            tooltip={lastItem.tooltip}
          />
        </span>
      </Row>
    </Container>
  );
};
