import { useQuery } from '@apollo/client';
import { DocsByCustomerDocument, DocsByCustomerQueryVariables } from '@cycle-app/graphql-codegen/generated';
import { nodeToArray } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { getDocKey } from 'src/utils/doc.util';
import { defaultCustomerDocsPagination } from 'src/utils/pagination.util';

import { useProductBase } from '../../useProduct';

export const useCustomerDocs = (variables: Pick<DocsByCustomerQueryVariables, 'id' | 'doctypeType'>, skip?: boolean) => {
  const product = useProductBase();
  const {
    data, loading: isLoading, fetchMore,
  } = useQuery(DocsByCustomerDocument, {
    variables: {
      ...variables,
      ...defaultCustomerDocsPagination,
    },
    skip,
  });
  const [isPaginationLoading, {
    setTrueCallback: setPaginationLoading, setFalseCallback: unsetPaginationLoading,
  }] = useOptimizedBooleanState(false);
  const customer = useMemo(() => (data?.node?.__typename === 'Customer' ? data.node : null), [data]);
  const docs = useMemo(() => (data?.node?.__typename === 'Customer' ? nodeToArray(data.node.docs).map(doc => ({
    ...doc,
    _docKey: getDocKey(product?.key, doc.publicId) || '',
  })) : []), [data, product?.key]);

  const loadMore = useCallback(async (cursor: string) => {
    setPaginationLoading();
    await fetchMore({
      variables: {
        ...variables,
        ...defaultCustomerDocsPagination,
        cursor,
      },
    });
    unsetPaginationLoading();
  }, [fetchMore, variables, setPaginationLoading, unsetPaginationLoading]);

  return {
    count: (variables.doctypeType === 'FEEDBACK' ? customer?.nbFeedbacks : customer?.nbInsights) ?? 0,
    docs,
    loadMore,
    isLoading,
    isPaginationLoading,
    pageInfo: customer?.docs?.pageInfo,
  };
};
