import { StatusFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';

import { ToggleDialog } from 'src/components/DialogModal/ToggleDialog';
import { DocTypeIconInline } from 'src/components/DocTypeIcon';
import { useProduct } from 'src/hooks';
import { useRemoveStatusDocType } from 'src/hooks/status';
import { getDocTypeName } from 'src/utils/docType.util';
import { getIsUnlinkable } from 'src/utils/status';

import { UnlinkAction, DocTypeLabel } from './SettingsWorkflows.styles';

type Props = {
  docTypeId: string;
  status: StatusFragment;
};

export const UnlinkStatusDocType = ({
  docTypeId, status,
}: Props) => {
  const { removeStatusDocType } = useRemoveStatusDocType(status, docTypeId);
  const { product } = useProduct();
  const docType = product?.doctypes.edges.find(e => e.node.id === docTypeId)?.node;
  if (!docType) return null;
  const isUnlinkable = getIsUnlinkable(status, docType);
  if (!isUnlinkable) return null;
  return (
    <Tooltip
      content="Unlink"
      placement="top"
    >
      <ToggleDialog
        title="Unlink"
        variantCancelButton="warning-secondary"
        variantSubmitButton="warning"
        info={(
          <div>
            {'Are you sure you want to unlink '}
            <DocTypeLabel>
              <DocTypeIconInline doctype={docType} size={14} />
              {` ${getDocTypeName(docType)} ?`}
            </DocTypeLabel>
            <br />
            Docs that had this status will change to the default status.
          </div>
        )}
        confirmLabel="Unlink"
        onConfirm={removeStatusDocType}
        button={buttonProps => (
          <UnlinkAction
            {...buttonProps}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              buttonProps.onClick();
            }}
          >
            <CloseIcon size={10} />
          </UnlinkAction>
        )}
      />
    </Tooltip>
  );
};
