import { MutationProgressSubscription, ProductFragment } from '@cycle-app/graphql-codegen';
import { isAfter, parseISO } from 'date-fns';

import { getCustomerDashboard, setCustomerDashboard, resetCustomerDashboardRefresh } from 'src/reactives';

export const onAnalyzeDashboardRefreshProgress = (
  result: NonNullable<MutationProgressSubscription['mutationProgress']>,
  lastRefresh: ProductFragment['lastCustomerVoiceRefresh'],
) => {
  const {
    requestId, lastRefreshRequested,
  } = getCustomerDashboard();
  if (!requestId || requestId !== result.mutationId || !lastRefreshRequested || !lastRefresh) return;
  const lastRefreshDate = parseISO(lastRefresh);
  if (isAfter(lastRefreshRequested, lastRefreshDate)) {
    setCustomerDashboard({ progress: result.progress });
  } else {
    resetCustomerDashboardRefresh();
  }
};
