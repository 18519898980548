import {
  Table, ShyScrollbar, Td, Tr, typo, Th,
} from '@cycle-app/ui';
import styled, { css } from 'styled-components';

export const StyledShyScrollbar = styled(ShyScrollbar)`
  width: 100%;
  max-height: 100%;
  margin: 0 -8px;
  padding: 0 8px;
  padding-bottom: 8px;
`;

export const StyledTable = styled(Table)<{ $isPrimary?: boolean }>`
  ${typo.body400}
  --bg: ${p => (p.$isPrimary ? p.theme.colors.background.primary : p.theme.colors.modal.content.bg)};
  background-color: var(--bg);
  table-layout: auto;
  width: 100%;
`;

export const StyledTr = styled(Tr)``;

interface CellSize {
  $size?: number;
  $sizeTablet?: number;
  $sizeMobile?: number;
}

const CellCommonCss = css<CellSize>`
  white-space: nowrap;
  padding: 8px 12px;
  ${p => p.$size && `
    --size: ${p.$size}px;
    min-width: var(--size);
    max-width: var(--size);
    width: var(--size);
  `}
`;

export const StyledTh = styled(Th)<CellSize>`
  ${CellCommonCss}
  > svg {
    margin-right: 8px;
    vertical-align: text-bottom;
  }
  :first-child {
    background-color: var(--bg);
    padding-right: 32px;
    position: sticky;
    left: 0;
  }
`;

export const StyledThActions = styled(Th)<CellSize>`
  background-color: inherit;
  position: sticky;
  right: -8px;
  ${CellCommonCss}
`;

export const StyledTd = styled(Td)<CellSize & {
  $type?: 'number' | 'text';
}>`
  ${CellCommonCss}
  :first-child {
    background-color: var(--bg);
    padding-right: 32px;
    position: sticky;
    left: 0;
  }
  ${p => p.$type === 'number' && `
    text-align: right;
    font-variant-numeric: tabular-nums;
  `}
`;

export const TdActions = styled(StyledTd)`
  text-align: right;
  background-color: inherit;
  position: sticky;
  right: -8px;

  > * {
    display: inline-block;
  }
`;
