import { typo, Button, card } from '@cycle-app/ui';
import styled from 'styled-components';

import PortalModal from 'src/components/PortalModal/PortalModal';

export const Container = styled.div`
  ${card}
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
`;

export const H2 = styled.h2`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  div[data-popover="true"] {
    display: flex;
    align-items: center;
    color: ${p => p.theme.colors.text.secondary};
  }
`;

export const Parent = styled.div`
  width: 100%;
  background: ${p => p.theme.colors.background.hoverSoft}; 
  height: 40px;
  border-radius: 6px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const DocTypeName = styled.div`
  flex: 1;
  color: ${p => p.theme.colors.text.primary};
  ${typo.body400};
`;

export const AddButton = styled(Button).attrs({
  size: 'S',
})`
  ${typo.caption400}
  --bg: transparent;
  gap: 4px;
  padding: 2px 6px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${p => p.theme.colors.text.disabled};
  svg {
    outline: none;
  }
`;

export const PortalModalStyled = styled(PortalModal)`
  padding: 0;
  border-radius: 10px;
  width: 650px;
  height: 100vh;
  max-height: calc(100vh - 30px);
`;

export const DocTypeSettingsContainer = styled.div`
  position: relative;
  width: 650px;
  height: 100%;
`;
