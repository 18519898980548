import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const ResizeImageIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.77358 1.13208C1.41929 1.13208 1.13208 1.41929 1.13208 1.77358C1.13208 2.12788 1.41929 2.41509 1.77358 2.41509C2.12788 2.41509 2.41509 2.12788 2.41509 1.77358C2.41509 1.41929 2.12788 1.13208 1.77358 1.13208ZM0 1.77358C0 0.794061 0.794061 0 1.77358 0C2.75311 0 3.54717 0.794061 3.54717 1.77358C3.54717 2.75311 2.75311 3.54717 1.77358 3.54717C0.794061 3.54717 0 2.75311 0 1.77358Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2265 1.13208C9.87217 1.13208 9.58496 1.41929 9.58496 1.77358C9.58496 2.12788 9.87217 2.41509 10.2265 2.41509C10.5808 2.41509 10.868 2.12788 10.868 1.77358C10.868 1.41929 10.5808 1.13208 10.2265 1.13208ZM8.45288 1.77358C8.45288 0.794061 9.24694 0 10.2265 0C11.206 0 12.0001 0.794061 12.0001 1.77358C12.0001 2.75311 11.206 3.54717 10.2265 3.54717C9.24694 3.54717 8.45288 2.75311 8.45288 1.77358Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.77358 9.58471C1.41929 9.58471 1.13208 9.87193 1.13208 10.2262C1.13208 10.5805 1.41929 10.8677 1.77358 10.8677C2.12788 10.8677 2.41509 10.5805 2.41509 10.2262C2.41509 9.87193 2.12788 9.58471 1.77358 9.58471ZM0 10.2262C0 9.2467 0.794061 8.45264 1.77358 8.45264C2.75311 8.45264 3.54717 9.2467 3.54717 10.2262C3.54717 11.2057 2.75311 11.9998 1.77358 11.9998C0.794061 11.9998 0 11.2057 0 10.2262Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2265 9.58471C9.87217 9.58471 9.58496 9.87193 9.58496 10.2262C9.58496 10.5805 9.87217 10.8677 10.2265 10.8677C10.5808 10.8677 10.868 10.5805 10.868 10.2262C10.868 9.87193 10.5808 9.58471 10.2265 9.58471ZM8.45288 10.2262C8.45288 9.2467 9.24694 8.45264 10.2265 8.45264C11.206 8.45264 12.0001 9.2467 12.0001 10.2262C12.0001 11.2057 11.206 11.9998 10.2265 11.9998C9.24694 11.9998 8.45288 11.2057 8.45288 10.2262Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.77356 2.41504C2.08617 2.41504 2.33959 2.66846 2.33959 2.98108V9.01881C2.33959 9.33143 2.08617 9.58485 1.77356 9.58485C1.46094 9.58485 1.20752 9.33143 1.20752 9.01881V2.98108C1.20752 2.66846 1.46094 2.41504 1.77356 2.41504Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.41504 1.77356C2.41504 1.46094 2.66846 1.20752 2.98108 1.20752H9.01881C9.33143 1.20752 9.58485 1.46094 9.58485 1.77356C9.58485 2.08617 9.33143 2.33959 9.01881 2.33959H2.98108C2.66846 2.33959 2.41504 2.08617 2.41504 1.77356Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.41504 10.2262C2.41504 9.91358 2.66846 9.66016 2.98108 9.66016H9.01881C9.33143 9.66016 9.58485 9.91358 9.58485 10.2262C9.58485 10.5388 9.33143 10.7922 9.01881 10.7922H2.98108C2.66846 10.7922 2.41504 10.5388 2.41504 10.2262Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2264 2.41504C10.5391 2.41504 10.7925 2.66846 10.7925 2.98108V9.01881C10.7925 9.33143 10.5391 9.58485 10.2264 9.58485C9.91382 9.58485 9.6604 9.33143 9.6604 9.01881V2.98108C9.6604 2.66846 9.91382 2.41504 10.2264 2.41504Z" fill="white" />
  </Svg>
);
