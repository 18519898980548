import { useEffect, useRef } from 'react';

import { setDocIdPreview } from 'src/reactives/docPreview.reactive';

export const DOC_PREVIEW_DELAY = 600;

export const useDocPreview = () => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearTimeout = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
  };

  const showDocPreview = (docId: string, delay = 0) => {
    if (delay) {
      clearTimeout();
      timeoutRef.current = setTimeout(() => setDocIdPreview({ docIdPreview: docId }), delay);
    } else {
      setDocIdPreview({ docIdPreview: docId });
    }
  };

  const hideDocPreview = () => {
    clearTimeout();
    setDocIdPreview({ docIdPreview: null });
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
  }, []);

  return {
    showDocPreview,
    hideDocPreview,
  };
};
