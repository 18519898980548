import { useLazyQuery } from '@apollo/client';
import { FilterPropertyRuleDocParentWithSearchDocument } from '@cycle-app/graphql-codegen';
import { Emoji, SelectPanelProps } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useMemo, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { DocFromFilter } from 'src/utils/boardConfig/filtersData.util';

import { StyledSelectPanel } from '../FilterElements.styles';

interface Props extends Pick<SelectPanelProps, 'onSelectOption' | 'onUnselectOption'>{
  selectedCount: number;
  defaultDocParentOptions: DocFromFilter[];
  filterPropertyRuleId: string | undefined;
}
const DropdownContent: FC<React.PropsWithChildren<Props>> = ({
  selectedCount,
  defaultDocParentOptions,
  filterPropertyRuleId,
  onSelectOption,
  onUnselectOption,
}) => {
  const [searchParents, { data }] = useLazyQuery(FilterPropertyRuleDocParentWithSearchDocument);
  const searchParentsDebounced = useDebouncedCallback(searchParents, INPUT_ONCHANGE_DEBOUNCE);

  const displayedDocFilters: DocFromFilter[] = useMemo(() => {
    const optionsFromSearch =
      data?.node?.__typename === 'FilterPropertyRuleDocParent' &&
      data.node.rule.__typename === 'RuleDocParentMultipleValues'
        ? nodeToArray(data.node.rule.values)
        : undefined;
    return optionsFromSearch ?? defaultDocParentOptions;
  }, [data?.node, defaultDocParentOptions]);

  const options = useMemo(() => displayedDocFilters.map(({
    selected,
    value: doc,
  }) => ({
    value: doc.id,
    label: doc.title,
    selected,
    icon: <Emoji emoji={getDocType(doc.doctype.id)?.emoji} />,
  })), [displayedDocFilters]);

  const onSearchChange = useCallback((search: string) => {
    if (!filterPropertyRuleId) return;
    searchParentsDebounced({
      variables: {
        id: filterPropertyRuleId,
        searchText: search,
      },
    });
  }, [searchParentsDebounced, filterPropertyRuleId]);

  return (
    <StyledSelectPanel
      isMulti
      title="Selected docs"
      selectedCount={selectedCount}
      options={options}
      onSelectOption={onSelectOption}
      onUnselectOption={onUnselectOption}
      onSearchChange={onSearchChange}
    />
  );
};
export default DropdownContent;
