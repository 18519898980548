import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SortIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.625 4.96825H1L4.5 1L8 4.96825H5.375V15H3.625V4.96825Z" />
    <path d="M12.375 11.0317H15L11.5 15L8 11.0317H10.625V1H12.375V11.0317Z" />
  </Svg>
);
