import {
  Button, typo, ActionButton, ShyScrollbarCss, truncate, boxShadowZ3,
} from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { motion } from 'framer-motion';
import { darken } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  background: ${p => p.theme.colors.docPanel.bg};
  overflow: hidden;
`;

export const Container = styled.div`
  position: sticky;
  top: 0;
  padding: 8px 16px 16px 16px;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  ${boxShadowZ3}
  position: relative;
`;

export const Header = styled.div<{ $withBorder: boolean }>`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 24px 24px 20px;
  ${p => p.$withBorder && `
    border-bottom: 1px solid ${p.theme.colors.border.primary};
  `}
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const ListContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const List = styled.div`
  height: 100%;
  ${ShyScrollbarCss}
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
`;

export const Footer = styled.div<{ $withBorder: boolean }>`
  padding: 20px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${p => p.$withBorder && `
    border-top: 1px solid ${p.theme.colors.border.primary};
  `}

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  }
`;

export const ListItem = styled.div<{ $selected: boolean }>`
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 8px;
  background: ${p => (p.$selected ? 'hsla(var(--cycle), 0.08)' : p.theme.colors.background.secondary)};
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  ${p => p.theme.isDark && `
    background: ${p.$selected ? 'hsla(var(--cycle500), 0.2)' : darken(0.025, Color.Grey850)};
  `};
`;

export const SubmitButton = styled(Button)<{ $disabled: boolean }>`
  ${p => p.$disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const SelectButton = styled(Button).attrs({
  size: 'M',
  variant: 'light',
})`
  --bg: transparent;
  font-weight: 500;
  height: 32px;
  padding: 0 12px;
  color: ${p => (p.theme.isDark ? p.theme.colors.text.primary : p.theme.colors.text.blue)};
`;

export const GroupHeader = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const GroupTitle = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const GroupInfo = styled.div`
  ${truncate}
  color: ${p => p.theme.colors.text.secondary};
  margin-bottom: 16px;
`;
