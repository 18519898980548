import { FC } from 'react';

import { OfflineIcon } from '../../icons';
import { Container } from './OfflineBadge.styles';

export const OfflineBadge: FC<React.PropsWithChildren<unknown>> = () => (
  <Container>
    <OfflineIcon />
    Offline
  </Container>
);
