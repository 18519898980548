import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { useState, useEffect, useRef } from 'react';

import { closeCompanyModal, useCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { Layer } from 'src/types/layers.types';

import { CompanyProfile } from './CompanyProfile';
import { Header, StyledPortalModal } from './CustomerProfileModal.styles';

export const CompanyProfileModal = () => {
  const {
    companyId, isOpen,
  } = useCompanyModal();

  const modalContentRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // When we want to use some refs inside a portal, we need to add a mounted flag, unless the refs will be null.
    setIsMounted(true);
  }, []);

  if (!isOpen) return null;

  return (
    <StyledPortalModal layer={Layer.ModalZ2} contentRef={modalContentRef} hide={closeCompanyModal}>
      <Header>
        <ActionButton tooltipPlacement="top" onClick={closeCompanyModal}><CloseIcon /></ActionButton>
      </Header>
      {isMounted && companyId && (
        <CompanyProfile
          companyId={companyId}
          isHeaderCentered
          hideShareLink
          scrollableElement={modalContentRef.current}
        />
      )}
    </StyledPortalModal>
  );
};
