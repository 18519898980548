import { typo, ShyScrollbarCss, ShyScrollbarProps, truncate } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { MainButton } from 'src/components/DocParent/DocParent.styles';
import { DocParentDropdown } from 'src/components/DocParentDropdown';
import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP } from 'src/constants/editor.constants';

import DocPanelActionsRaw from '../DocPanelActions/DocPanelActions';
import DocPanelDocAttributesRaw from '../DocPanelDocAttributes/DocPanelDocAttributes';
import DocPanelEditableTitleRaw from '../DocPanelEditableTitle/DocPanelEditableTitle';

export const CoverContainer = styled.div`
  padding-top: 2px;
  padding-left: 24px;
  padding-right: 24px;

  > * {
    max-width: ${EDITOR_WIDTH_DESKTOP}px;
    margin: 0 auto;
  }

  ${mobile} {
    padding-left: 0;
    padding-right: 0;

    > * {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
    }
  }
`;

export const HeaderContainer = styled.div<{ coloredTopBorder: boolean }>`
  border-top: 2px solid ${p => (p.coloredTopBorder ? 'hsl(var(--cycle))' : 'transparent')};
  width: 100%;
  max-width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  margin: 0 auto;
  padding-left: ${EDITOR_MARGIN}px;
  padding-right: ${EDITOR_MARGIN}px;
`;

export const PreTitle = styled.div`
  ${mobile} {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;

    /* Needed to truncate the parent title */
    > [data-popover] {
      overflow: hidden;
    }
  }
`;

export const DocPanelActions = styled(DocPanelActionsRaw)`
  padding-top: 12px;

  ${mobile} {
    padding-top: 0;
  }
`;

export const DocPanelDocAttributes = styled(DocPanelDocAttributesRaw)``;

export const DocPanelEditableTitle = styled(DocPanelEditableTitleRaw)`
  margin: 10px 0;

  ${mobile} {
    margin-top: 20px;
  }
`;

type EditorWrapperProps = {
  $isDisabled: boolean;
};

export const EditorWrapper = styled.div<EditorWrapperProps>`
  flex: 1;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  ${p => p.$isDisabled && css`
    cursor: not-allowed;
  `}
`;

type ScrollableContentProps = {
  $disabled: boolean;
} & ShyScrollbarProps;

export const ScrollableContent = styled.section <ScrollableContentProps>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  background-color: inherit;
  overflow: hidden;

  /* Hide DocPanelHeader box shadow when content is at scroll top 0 position */
  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0px;
    height: 2px;
    width: 100%;
    background-color: inherit;
  }

  ${CoverContainer} + ${HeaderContainer} {
    padding-top: 24px;
  }

  ${p => p.$disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const MainContent = styled.div`
  ${ShyScrollbarCss} 
  flex: 1;
`;

export const DocParentTitle = styled.span`
  ${truncate}
  ${typo.body400}
`;

export const StyledDocParentDropdown = styled(DocParentDropdown)`
  &, ${MainButton} {
    overflow: hidden;
  }
`;

export const InsightAiLoadingState = styled.div`
  display: flex;
  justify-content: center;
`;

export const InsightAiLoadingStateContainer = styled.div`
  width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 23px ${EDITOR_MARGIN}px 0;
`;

const DocPanelSection = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  padding: 0 ${EDITOR_MARGIN}px;
`;

export const QuotesSummarySection = styled(DocPanelSection)`
  margin-top: 32px;
  margin-bottom: 8px;
`;
