/* eslint-disable no-nested-ternary */
import { Color as ColorAPI } from '@cycle-app/graphql-codegen';
import styled, { css } from 'styled-components';

import { Color } from '../../theme/baseColors';
import * as typo from '../../theme/typo';
import { Container as EmojiContainer } from '../Emoji/Emoji.styles';

export const Container = styled.div<{
  $color: ColorAPI;
  $pending: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${p => p.theme.colors.avatar.bg};
  border-radius: 50%;
  width: 24px;
  height: 24px;

  &:not(:first-child) {
    margin-left: -9px;
  }
`;

export const UserName = styled.div`
  color: ${p => p.theme.colors.text.white};
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const viewingFormat = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Viewing = styled.div`
  ${typo.caption400}
  ${viewingFormat}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
  width: 100%;
  /** If emoji in children, height will be increased to 22px */
  min-height: 22px;

  > * {
    ${viewingFormat}
  }

  ${EmojiContainer} {
    display: inline-block;

    span {
      display: inline-block;
    }
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
`;

export const BulletContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  pointer-events: all;
`;

const Bullet = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid ${p => p.theme.colors.avatar.bg};
`;

export const BulletOnline = styled(Bullet)`
  background: ${Color.Green700};
`;

export const BulletOffline = styled(Bullet)`
  background: ${Color.Yellow600};
`;

export const BulletPending = styled(Bullet)`
  background: ${p => p.theme.colors.avatar.pending};
`;
