import { typo, Button } from '@cycle-app/ui';
import { mobile, small } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  ${typo.body500}
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: transparent;
  border-radius: 6px;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;
`;

export const Cards = styled.div`
  display: flex;
  gap: 16px;
  justify-content: start;
  margin: 0 32px;

  ${small} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: unset;
  }

  ${mobile} {
    margin: 0 16px;
  }
`;

export const Card = styled.div`
  position: relative;
  background: ${p => p.theme.colors.home.views.card.bg};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border: transparent;
  width: 180px;

  ${small} {
    padding: 12px;
    width: unset;
  }
`;

export const CardActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  ${small} {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
`;

export const FlatCard = styled(Card)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  grid-column-start: span 2;
  height: auto;

  ${CardFooter} {
    margin: 0;
  }
`;

export const Action = styled(Button).attrs({
  variant: 'nospace',
  size: 'S',
})`
  color: ${p => p.theme.colors.text.disabled};
  
  :hover, :focus {
    color: ${p => p.theme.colors.text.secondary};
  }

  opacity: 0;
  ${Card}:hover & {
    opacity: 1;
  }
`;
