import { useEffect, useState } from 'react';

import BoardContent from 'src/app/Main/Board/BoardContent/BoardContent';
import { BoardContentSkeleton } from 'src/app/Main/Board/BoardContent/BoardContentSkeleton';
import BoardContentWithSwimlaneLoader from 'src/app/Main/Board/BoardContentWithSwimlane/BoardContentWithSwimlaneLoader';
import { ErrorPage, Size } from 'src/components/ErrorPage';
import { InsightsEmpty } from 'src/components/ReleasesEmpty/InsightsEmpty';
import { RoadmapsEmpty } from 'src/components/RoadmapsEmpty';
import { BULK_SELECTION_AREA_ID } from 'src/constants/bulkSelection.constants';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useInitBoard } from 'src/hooks/boards/useInitBoard';
import { useInsightViewCurrent } from 'src/hooks/insight/useInsightViewCurrent';
import { useBoardSlug } from 'src/hooks/usePathParams';
import { setLastInsightBoard, setLastView } from 'src/reactives/lastView.reactive';
import { isViewEmpty } from 'src/utils/boardConfig/isViewEmpty.util';

export const InsightViewContent = () => {
  const boardSlug = useBoardSlug();
  const board = useInsightViewCurrent();
  const loading = useBoardConfig(ctx => ctx.loading);
  const notFound = useBoardConfig(ctx => ctx.notFound);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const isFilteredByQuote = useBoardConfig(ctx => ctx.insightDocType.isInBoard);
  const [isEmptyStateVisible, setIsEmptyStateVisible] = useState(isViewEmpty(boardConfig));

  // Show the empty state if the board is empty
  useEffect(() => {
    if (!isEmptyStateVisible && isViewEmpty(boardConfig)) {
      setIsEmptyStateVisible(true);
    } else if (isEmptyStateVisible && !isViewEmpty(boardConfig)) {
      setIsEmptyStateVisible(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardConfig]);

  // If the user clicks on "New doc" in the empty state,
  // we open the new doc form in the first group
  useEffect(() => {
    if (!isEmptyStateVisible && isViewEmpty(boardConfig)) {
      const button = document.querySelector(`#${BULK_SELECTION_AREA_ID} button[data-action="new-doc"]`);
      if (button instanceof HTMLButtonElement) button.click();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptyStateVisible]);

  useInitBoard({
    board,
    boardConfigId: boardConfig?.id,
  });

  useEffect(() => {
    if (board?.id) {
      setLastView({
        section: 'insights',
      });
      setLastInsightBoard({
        boardSlug,
        boardId: board.id,
      });
    }
  }, [boardSlug, board?.id]);

  if (notFound) return <ErrorPage message="Oops, this view no longer exists" size={Size.SMALL} />;

  const viewType = board?.publishedBoardConfig?.viewType;
  if (!viewType) return null;

  if (!boardConfig?.id || loading) return <BoardContentSkeleton viewType={viewType} />;

  if (isEmptyStateVisible) {
    return isFilteredByQuote ? (
      <InsightsEmpty />
    ) : (
      <RoadmapsEmpty
        onClick={() => setIsEmptyStateVisible(false)}
      />
    );
  }

  const isSwimlaneBoard = board?.publishedBoardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy';

  if (isSwimlaneBoard) {
    return <BoardContentWithSwimlaneLoader />;
  }

  return viewType ? (
    <BoardContent
      viewType={viewType}
      boardConfigId={boardConfig?.id}
    />
  ) : null;
};
