import { typo, Button, ShyScrollbarCss } from '@cycle-app/ui';
import { AiIcon } from '@cycle-app/ui/icons';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  overflow: hidden;

  h3 {
    ${typo.headerLight}
  }
`;

export const DocTypeButton = styled(Button).attrs({
  size: 'M',
  variant: 'nospace',
})`
  height: 28px;
  padding: 0 10px;
  > span { gap: 4px }
  color: ${p => (p.theme.isDark ? p.theme.colors.text.white : p.theme.colors.text.blue)};
  :hover {
    --bg: ${p => p.theme.colors.button.secondary.bgHover};
  }
  :active {
    --bg: ${p => p.theme.colors.button.secondary.bgActive};
  }
`;

export const Hierarchy = styled.div`
  flex: 1;
  position: relative;
  background: ${p => p.theme.colors.flow.bg};
  overflow: hidden;
  border-radius: 8px;
`;

export const Info = styled.p`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
`;

type ContentProps = {
  $center: boolean;
};

export const Content = styled.div<ContentProps>`
  flex: 1;
  display: flex;
  gap: 24px;
  overflow: hidden;
  ${p => p.$center && css`
    justify-content: center;
    align-items: flex-start;
  `}
`;

export const RoadmapsContainer = styled.div`
  min-width: 200px;
  max-width: 440px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const StyledAiIcon = styled(AiIcon)`
  color: ${p => p.theme.colors.text.disabled};
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${p => p.theme.colors.text.disabled};
  margin-left: 4px;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  ${ShyScrollbarCss}
  scrollbar-gutter: stable;
  flex: none;
`;
