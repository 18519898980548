import { CreateStatusMutationVariables, StatusCategory } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { useForm } from 'react-hook-form';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { useProduct } from 'src/hooks';
import { useGetDoctypeFromCache } from 'src/hooks/api/cache/cacheDoctype';
import { useCreateStatus } from 'src/hooks/status';
import { getDocTypeName } from 'src/utils/docType.util';

import { StyledForm, Actions, StyledInput, DocTypeButton } from './CreateStatusForm.styles';

export type CreateStatusProps = {
  category: StatusCategory;
  usedNames: string[];
  onDone: VoidFunction;
  currentDocTypeId: string;
};

export const CreateDocTypeStatusForm = ({
  category, usedNames, onDone, currentDocTypeId,
}: CreateStatusProps) => {
  const { product } = useProduct();
  const getDocType = useGetDoctypeFromCache();
  const docType = getDocType(currentDocTypeId);

  const {
    createStatus, loading: isCreating,
  } = useCreateStatus({ onDone });

  const {
    handleSubmit, register, formState,
  } = useForm<CreateStatusMutationVariables>({
    defaultValues: {
      productId: product?.id,
      category,
      value: '',
      docTypeId: currentDocTypeId,
    },
  });

  if (!docType) return null;

  return (
    <StyledForm onSubmit={handleSubmit(createStatus)}>
      <StyledInput
        autoFocus
        placeholder="Status name"
        error={formState.errors.value?.message}
        {...register('value', {
          setValueAs: v => v.trim(),
          validate: {
            minLength: v => v.length > 2 || 'You must have at least 3 characters',
            alreadyUsed: v => !usedNames.includes(v) || 'This name is already used',
          },
        })}
      />

      <DocTypeButton
        iconStart={<DocTypeIcon doctype={docType} size={14} />}
        disabled
      >
        {getDocTypeName(docType)}
      </DocTypeButton>

      <Actions>
        <Button onClick={onDone} variant="secondary" size="M">
          Cancel
        </Button>
        <Button type="submit" variant="primary" size="M" isLoading={isCreating}>
          Create
        </Button>
      </Actions>
    </StyledForm>
  );
};
