import { typo, Button, boxShadowZ1 } from '@cycle-app/ui';
import { tablet } from '@cycle-app/utilities';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Footer = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
  margin-top: 60px;

  ${tablet} {
    margin-bottom: 24px;
  }
`;

export const SigInChoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 64px;
`;

export const Form = styled.form`
  display: grid;
  gap: 24px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
`;

export const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: 32px;
  ${typo.headerLarge600};
  text-align: center;
`;

export const ResetPwdHeading = styled(Heading)`
  margin-bottom: 8px;
`;

export const ResetPwdHint = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  color: ${p => p.theme.colors.text.secondary};
  ${typo.body400};
`;

export const ResetPwdActions = styled(Actions)`
  justify-content: flex-end;
`;

export const TextButtonStyled = styled(Button)`
  ${typo.body400}
  width: auto;
`;

export const Anchor = styled(Link)`
  ${typo.body500}
  color: ${p => (p.theme.colors.login.link.userColor ? 'hsl(var(--cycle))' : p.theme.colors.text.disabled)};
`;

export const ButtonOutline = styled(Button)`
  ${boxShadowZ1}
`;
