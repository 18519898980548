import { AddIcon } from '@cycle-app/ui/icons';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';

import { ActionButton } from './Customers.styles';
import { CustomersAddCustomerModal } from './CustomersAddCustomerModal';

export const CustomersAddCustomerButton = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [isAddCustomerModalVisible, {
    setTrueCallback: showAddCustomerModal,
    setFalseCallback: hideAddCustomerModal,
  }] = useOptimizedBooleanState(false);

  return (
    <>
      <ActionButton
        iconStart={<AddIcon />}
        onClick={showAddCustomerModal}
      >
        Add
      </ActionButton>

      {isAddCustomerModalVisible && (
        <CustomersAddCustomerModal
          productId={productId}
          onClose={hideAddCustomerModal}
        />
      )}
    </>
  );
};
