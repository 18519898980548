import { ShyScrollbarCss, ActionButton, truncate, typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import EditorSkeleton from 'src/components/EditorSkeleton/EditorSkeleton';
import { EDITOR_MARGIN } from 'src/constants/editor.constants';

import DocPanelRealtime from '../../app/Main/Board/DocPanel/DocPanelRealtime/DocPanelRealtime';
import { StyledAvatar } from '../RealtimeUsers/RealtimeUsers.styles';

export const Container = styled(motion.div)`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
`;

export const ReleaseNoteHeader = styled.div`
  padding: 18px 20px;
  border-bottom: 1px solid ${p => p.theme.colors.releases.border};

  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  flex: 1;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PrevNextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Action = styled(ActionButton)`
  width: 24px;
  height: 24px;
  :disabled {
    opacity: 0.5;
  }
`;

export const Date = styled.p`
  color: ${p => p.theme.colors.releases.note.date};
  ${truncate}
`;

export const EditorContainer = styled.div`
  ${ShyScrollbarCss}
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TagContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const EditorHeader = styled.div`
  flex: none;
  padding: 22px ${EDITOR_MARGIN}px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Alert = styled.div`
  display: flex;
  gap: 8px;
  ${typo.caption400};
  color: ${p => p.theme.colors.text.secondary};
  background: ${p => p.theme.colors.releases.bgActive};
  padding: 8px;
  border-radius: 6px;
  svg {
    flex: none;
    vertical-align: middle;
  }
  strong {
    font-weight: 500;
  }
`;

export const ReleaseNoteSkeleton = styled(EditorSkeleton)`
  padding: 26px 18px 26px 26px;
`;

export const StyledDocPanelRealtime = styled(DocPanelRealtime)`
  ${StyledAvatar} {
    --size: 14px;
    --font-size: 8px;
  }
`;

export const HiddenEditorContainer = styled.div`
  position: fixed;
  top: 100vh;
  left: 100vw;
  opacity: 0;
`;
