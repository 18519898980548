import { typo, truncate } from '@cycle-app/ui';
import styled from 'styled-components';

type FontWeight = '400' | '500' | '600';

export const Text = styled.div<{
  $font?: FontWeight;
}>`
  ${p => getTypo(p.$font)}
  user-select: text;
`;

export const TextSecondary = styled(Text)<{
  $font?: FontWeight;
}>`
  color: ${p => p.theme.colors.text.secondary};
`;

const getTypo = (font?: FontWeight) => {
  if (font === '400') return typo.body400;
  if (font === '500') return typo.body500;
  if (font === '600') return typo.body600;
  return typo.body400;
};

export const Truncate = styled.span`
  ${truncate}
`;
