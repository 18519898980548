import {
  CheckboxInput, CheckboxInputText, typo, ShyScrollbar, Tooltip, SelectPanel, Button,
} from '@cycle-app/ui';
import { ChartIcon } from '@cycle-app/ui/icons';
import { tablet } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { AsideApp } from '../OnboardingLayout/AsideApp';

export const StyledChartIcon = styled(ChartIcon)`
  width: 10px;
  height: 10px;
`;

export const Main = styled.form`
  margin-top: 32px;
`;

export const StyledTooltip = styled(Tooltip)`
  text-align: center;
`;

export const StyledAsideApp = styled(AsideApp)`
  &:after {
    display: none;
  }
`;

export const SourceListContainer = styled(ShyScrollbar)`
  max-height: 415px;
`;

export const SourceList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 2px;

  > li {
    min-width: 0;
  }

  > :last-child {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  ${tablet} {
    grid-template-columns: 1fr;

    > :last-child {
      grid-column-end: 1;
    }
  }
`;

export const AppMain = styled.div`
  padding-left: 48px;
  padding-top: 48px;
`;

export const AppTitle = styled.div`
  ${typo.headerMedium}
`;

export const AppSourceList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 32px;
`;

const imageLogoCss = css`
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`;

export const AppSourceListItem = styled(motion.li)`
  height: 44px;
  border-radius: 12px;
  padding: 14px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.05);
  background: ${p => (p.theme.isDark ? p.theme.baseColors.Grey900 : '#FFF')};
  ${imageLogoCss}

  [data-popover] {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const SourceLabel = styled.div`
  ${typo.body500}
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  ${imageLogoCss}
`;

export const SourceName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface StyledCheckboxInputProps {
  $isSelected: boolean;
}

export const StyledCheckboxInput = styled(CheckboxInput)<StyledCheckboxInputProps>`
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  transition: background-color .1s;
  height: 48px;

  label {
    padding: 12px;
    gap: 15px;
  }

  ${CheckboxInputText} {
    overflow: hidden;
  }

  ${p => p.$isSelected && css`
    background-color: ${p.theme.colors.lightOnboarding.sources.selected};
    border-color: ${p.theme.colors.lightOnboarding.sources.selectedBorder};
  `}
`;

export const ButtonOther = styled(Button)`
  ${typo.body500}
  --radius: 8px;
  --color: ${p => p.theme.colors.text.primary};
  height: 48px;
  padding: 12px;
  border: 1px solid ${p => p.theme.colors.border.primary};

  &.force-focus {
    background-color: ${p => p.theme.colors.button.ternary.bg};
  }

  > span {
    justify-content: flex-start;
  }
`;

export const StyledSelectPanel = styled(SelectPanel)`
  svg, img {
    width: 16px;
    flex: none;
  }
`;
