import { BoardConfigSortByFragment, Order, PropertiesFragment } from '@cycle-app/graphql-codegen';
import { ActionButton, SelectOption } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useBoardConfigSortBy } from 'src/hooks/boards/useBoardConfigSortBy';
import { Layer } from 'src/types/layers.types';
import { Truncate } from 'src/utils/css.styles';

import {
  Attribute,
  Container,
  FakeInput,
  DeleteBtnContainer,
} from './Filter.styles';
import SingleSelectFilterElement from './FilterElement/SingleSelectFilterElement';

interface Props {
  boardConfigId: string;
  sortByProperty: BoardConfigSortByFragment['sortByProperty'];
  sortableProperties: BoardConfigSortByFragment['sortableProperties'];
  filterableProperties: PropertiesFragment;
  layer?: Layer;
}

const orderOptions: SelectOption[] = [
  {
    label: 'Oldest',
    value: Order.Asc,
  }, {
    label: 'Most recent',
    value: Order.Desc,
  },
];

export const SortBy: FC<React.PropsWithChildren<Props>> = ({
  boardConfigId,
  sortableProperties,
  sortByProperty,
  filterableProperties,
  layer = Layer.DropdownModalZ1,
}) => {
  const {
    sortBy, sortableOptions, sortByAdd, sortByRemove, sortByOrderChange, isRemoveLoading,
  } = useBoardConfigSortBy({
    boardConfigId,
    sortableProperties,
    sortByProperty,
    filterableProperties,
  });

  if (!sortBy) return null;

  return (
    <Container>
      <FakeInput>
        <SingleSelectFilterElement
          dropdownLayer={layer}
          value={sortBy?.value}
          options={sortableOptions}
          onChange={o => sortByAdd(o.value)}
        >
          <Attribute>
            {sortBy.icon}
            <Truncate>
              {sortBy.label}
            </Truncate>
          </Attribute>
        </SingleSelectFilterElement>
        <SingleSelectFilterElement
          dropdownLayer={layer}
          value={sortBy.order}
          options={orderOptions}
          onChange={(o) => sortByOrderChange(o.value as Order)}
        >
          {orderOptions.find(o => o.value === sortBy.order)?.label}
        </SingleSelectFilterElement>
        <DeleteBtnContainer>
          <ActionButton
            tooltip="Remove sort"
            tooltipPlacement="top"
            onClick={sortByRemove}
            isLoading={isRemoveLoading}
          >
            <CloseIcon />
          </ActionButton>
        </DeleteBtnContainer>
      </FakeInput>
    </Container>
  );
};
