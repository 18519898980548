import { SelectPanel, type SelectOption } from '@cycle-app/ui';
import { isEmail } from '@cycle-app/utilities';
import { useEffect, useState, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { CompanyAddModal } from 'src/components/CustomersList/CompanyAddModal';
import { CustomersAddCustomerModal } from 'src/components/CustomersList/CustomersAddCustomerModal';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { useCustomersByProductId } from 'src/hooks/api/queries/customers/useCustomers';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { getFeedbackCustomerName, getCustomerOption, renderCustomerOptionLabel } from 'src/utils/customers.util';
import { getDefaultFeedbackTitle } from 'src/utils/doc.util';

import { useProductBase } from '../Product';
import { FieldButton, SkeletonInput, CustomerAvatar, CreateSection, CreateButton } from './Form.styles';
import { FormData } from './Form.types';

export const FieldCustomer = () => {
  const {
    setValue, getValues, formState: { dirtyFields },
  } = useFormContext<FormData>();
  const value = useWatch<FormData>({ name: 'customer.id' });

  const {
    customer: selectedCustomer, loading,
  } = useCustomer(value, {
    onCompleted: (data) => {
      if (!data) setValue('customer', undefined);
    },
  });

  const { product } = useProductBase();
  const customerName = getFeedbackCustomerName(selectedCustomer);

  // Auto fill title with a default title that includes the customer name, if the title is modified and not empty
  useEffect(() => {
    if (getValues().title && dirtyFields.title) return;
    const title = getDefaultFeedbackTitle(selectedCustomer);
    if (!title) return;
    // Set title field as non-dirty to allow auto-fill if the user select another customer
    setValue('title', title, { shouldDirty: false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerName]);

  return (
    <ToggleDropdown
      placement="bottom-start"
      button={props => (loading ? <SkeletonInput /> : (
        <FieldButton
          iconStart={selectedCustomer && <CustomerAvatar customer={selectedCustomer} size="S" />}
          {...props}
        >
          {value ? customerName : 'Choose from list'}
        </FieldButton>
      ))}
      content={props => {
        if (!product?.id) return null;
        return (
          <SelectCompanyCustomerPanel
            onChange={customerId => {
              setValue('customer.id', customerId);
              props.hide();
            }}
            productId={product.id}
          />
        );
      }}
    />
  );
};

type SelectCompanyCustomerPanelProps = {
  productId: string;
  onChange: (customerId: string) => void;
};

const SelectCompanyCustomerPanel = ({
  productId, onChange,
}:SelectCompanyCustomerPanelProps) => {
  const [searchText, setSearchText] = useState('');
  const { customers } = useCustomersByProductId(productId, {
    defaultSearch: searchText,
    fetchPolicy: 'network-only',
  });
  const [isCreateCustomerVisible, { toggleCallback: toggleCreateCustomer }] = useOptimizedBooleanState(false);
  const [isCreateCompanyVisible, { toggleCallback: toggleCreateCompany }] = useOptimizedBooleanState(false);

  const customerOptions = useMemo<SelectOption[]>(() => {
    const sortedCustomers = customers;
    return sortedCustomers
      .map(customer => ({
        ...getCustomerOption(customer, false),
        renderLabel: (filterText) => (
          renderCustomerOptionLabel({
            customer,
            filterText,
          })
        ),
      }));
  }, [customers]);

  return (
    <SelectPanel
      filterOptionsOnInputChange={false}
      options={customerOptions}
      onSearchChange={setSearchText}
      onOptionChange={(option) => onChange(option.value)}
      debounceSearch
    >
      <CreateSection>
        <CreateButton
          variant="secondary"
          isSelected={false}
          useCycleColor={false}
          hoverDisabled={false}
          onClick={toggleCreateCompany}
        >
          Create company
        </CreateButton>
        <CreateButton
          variant="secondary"
          isSelected={false}
          useCycleColor={false}
          hoverDisabled={false}
          onClick={toggleCreateCustomer}
        >
          Create person
        </CreateButton>
      </CreateSection>
      {isCreateCustomerVisible && (
        <CustomersAddCustomerModal
          productId={productId}
          defaultValues={{
            name: isEmail(searchText, false) ? '' : searchText,
            email: isEmail(searchText, false) ? searchText : '',
          }}
          layer={Layer.ModalZ2}
          onClose={toggleCreateCustomer}
          onCreated={data => {
            onChange(data.id);
            toggleCreateCustomer();
          }}
        />
      )}
      {isCreateCompanyVisible && (
        <CompanyAddModal
          productId={productId}
          defaultValues={{ name: searchText }}
          layer={Layer.ModalZ2}
          onClose={toggleCreateCompany}
          onCreated={data => {
            const newCustomerId = data.createCompany?.customers.edges?.[0]?.node.id;
            if (!newCustomerId) return;
            onChange(newCustomerId);
            toggleCreateCompany();
          }}
        />
      )}
    </SelectPanel>
  );
};
