import { CompanyFullFragment } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { UserIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useUrl } from 'src/hooks/useUrl';
import { useGetPermission } from 'src/reactives';

import { Container } from './CustomerDetails.styles';
import { CustomerShareButton } from './CustomerShareButton';

interface Props {
  className?: string;
  company: CompanyFullFragment;
  hideShareLink?: boolean;
}

export const CompanyDetails: FC<React.PropsWithChildren<Props>> = ({
  className, company, hideShareLink,
}) => {
  const getUrl = useUrl();
  const { canReadSettings } = useGetPermission();

  return (
    <Container className={className}>
      <ActionButton size="L" disabled>
        <UserIcon />
        {company?.countCustomers ?? 0}
      </ActionButton>
      {!hideShareLink && canReadSettings && (
        <CustomerShareButton url={getUrl(PageId.SettingsCompany, { companyId: company?.id })}>
          Copy URL
        </CustomerShareButton>
      )}
    </Container>
  );
};
