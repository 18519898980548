import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 345px;
  max-width: 100%;
`;

export const FormFooter = styled.div`
  margin-top: auto;
  margin-left: auto;

  display: flex;
  gap: 8px;
`;
