import { Reference, useApolloClient } from '@apollo/client';
import {
  AiState,
  BoardConfigDocument,
  BoardWithConfigDocument,
  DocInitialBaseFragment,
  FetchDocLinkedDocsDocument,
} from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { produce } from 'immer';
import { useCallback } from 'react';
import { isPresent } from 'ts-is-present';

import { getDocType } from 'src/reactives/docTypes.reactive';
import { extract } from 'src/types/graphql.types';
import { getDocFromCache } from 'src/utils/cache.utils';
import { isFeedback } from 'src/utils/docType.util';
import { defaultPagination } from 'src/utils/pagination.util';
import { getParams } from 'src/utils/routing.utils';
import { parseStoreFieldName } from 'src/utils/update-cache/cache.utils';
import { updateQuery } from 'src/utils/update-cache/update-query.util';

import { useDocDocSourceLazy } from '../../doc/useDocDocSource';
import { useGetBoardConfigFromCache } from './cacheBoardConfig';
import { useCustomerDocFromCache } from './cacheCustomerDoc';
import { useUpdateChildCache } from './cacheHierarchy';

export const useRemoveDocFromBoardDocsList = () => {
  const { cache } = useApolloClient();
  const getBoardConfig = useGetBoardConfigFromCache();

  return useCallback((boardConfigId: string, docId: string) => {
    const boardConfig = getBoardConfig(boardConfigId);
    if (boardConfig?.docQuery?.__typename !== 'BoardQuery') return;

    cache.writeQuery({
      query: BoardConfigDocument,
      variables: {
        id: boardConfigId,
        ...defaultPagination,
      },
      data: {
        node: {
          ...boardConfig,
          docQuery: {
            ...boardConfig.docQuery,
            docGroup: {
              ...boardConfig.docQuery.docGroup,
              docs: {
                ...boardConfig.docQuery.docGroup.docs,
                edges: boardConfig.docQuery.docGroup.docs.edges.filter(
                  edge => edge.node.id !== docId,
                ),
              },
            },
          },
        },
      },
    });
  }, [cache, getBoardConfig]);
};

export const useRemoveDocsFromCache = () => {
  const { cache } = useApolloClient();
  const updateChild = useUpdateChildCache();
  const { getDocDocSourceFromCache } = useDocDocSourceLazy();
  const { removeCustomerDoc } = useCustomerDocFromCache();

  const removeDocsFromBoard = (docIds: string[]) => {
    const { boardId } = getParams();
    if (!boardId) return;
    updateQuery({
      query: BoardWithConfigDocument,
      variables: {
        id: boardId,
        ...defaultPagination,
      },
      update: draft => {
        if (draft?.node?.__typename !== 'Board') return;

        const draftConfig = draft?.node.publishedBoardConfig;

        if (draftConfig?.docQuery.__typename === 'BoardQueryWithGroupBy') {
          const groups = draftConfig.docQuery.docGroups.edges;
          for (const group of groups) {
            const newDocs = group.node.docs.edges.filter(doc => !docIds.includes(doc.node.id));
            group.node.docs.edges = newDocs;
          }
          return;
        }

        if (draftConfig?.docQuery.__typename === 'BoardQuery') {
          const group = draftConfig.docQuery.docGroup;
          const newDocs = group.docs.edges.filter(doc => !docIds.includes(doc.node.id));
          group.docs.edges = newDocs;
          return;
        }

        if (draftConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy') {
          const { swimlanes } = draftConfig.docQuery;
          for (const swimlane of swimlanes.edges) {
            for (const group of swimlane.node.docGroups.edges) {
              const newDocs = group.node.docs.edges.filter(doc => !docIds.includes(doc.node.id));
              group.node.docs.edges = newDocs;
            }
          }
        }
      },
    });
  };

  return (docIds: string[]) => {
    const boardIdParams = getParams().boardId;
    /* eslint-disable no-param-reassign */
    if (boardIdParams) {
      removeDocsFromBoard(docIds);
    }
    /* eslint-disable no-param-reassign */
    // Group feedback updates
    const feedbacksToDowngrade: Record<string, { insightsIds: string[]; count: number; doc: Pick<DocInitialBaseFragment, '__typename' | 'id'> }> = {};

    const docs = docIds.map(id => {
      const doc = getDocFromCache(id);
      if (doc?.customer?.id) {
        removeCustomerDoc({ doc });
      }
      // Remove all the insights from the board.
      if (boardIdParams && isFeedback(getDocType(doc?.doctype.id))) {
        const docTargets = cache.readQuery({
          query: FetchDocLinkedDocsDocument,
          variables: { id },
        });
        const docTargetsAi = cache.readQuery({
          query: FetchDocLinkedDocsDocument,
          variables: {
            id,
            aiStates: [AiState.UserValidated, null],
          },
        });
        const data = extract('Doc', docTargets?.node);
        const dataAi = extract('Doc', docTargetsAi?.node);
        const targets = [...nodeToArray(data?.docTargets), ...nodeToArray(dataAi?.docTargets)];
        if (targets.length) {
          const insightIds = Array.from(
            new Set(targets.map(target => target.doc?.id).filter(isPresent)),
          );
          removeDocsFromBoard(insightIds);
          insightIds.forEach(insightId => cache.evict({ id: insightId }));
        }
      }
      return {
        docId: id,
        parentId: doc?.parent?.id,
      };
    });

    docIds.forEach(docId => {
      const docSource = getDocDocSourceFromCache(docId);
      if (docSource?.doc) {
        const feedbackToDowngrade = feedbacksToDowngrade[docSource.doc.id];
        if (feedbackToDowngrade) {
          feedbackToDowngrade.count += 1;
          feedbackToDowngrade.insightsIds.push(docId);
        } else {
          feedbacksToDowngrade[docSource.doc.id] = {
            count: 1,
            doc: docSource.doc,
            insightsIds: [docId],
          };
        }
      }
    });
    const feedbacks = Object.values(feedbacksToDowngrade);
    if (feedbacks.length) {
      feedbacks.forEach(feedback => {
        cache.modify({
          id: cache.identify(feedback.doc),
          fields: {
            docTargetsCount: (currentDocTargetsCount, { storeFieldName }) => {
              const storeVariables = parseStoreFieldName(storeFieldName);
              if (storeVariables?.aiState === AiState.AiCreated) return currentDocTargetsCount;
              return currentDocTargetsCount - feedback.count;
            },
            docTargets: (currentDocTarget: { edges: { node: Reference }[] }, { readField }) => {
              return produce(currentDocTarget, draft => {
                draft.edges = draft.edges.filter(edge => {
                  const docTargetRef = readField<Reference>('doc', edge.node);
                  if (docTargetRef) {
                    const docId = readField<string>('id', docTargetRef);
                    return !docId || !feedback.insightsIds.includes(docId);
                  }
                  return true;
                });
              });
            },
          },
        });
      });
    }

    updateChild({
      docs,
      newParentId: undefined,
    });
    docIds.forEach(id => cache.evict({ id }));
    cache.gc();
  };
};
