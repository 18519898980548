import { useMemo } from 'react';

import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import {
  TagStyled,
  AttributeTagIconContainer,
  DeleteDialogModalStyled,
} from './SettingsAttributes.styles';

export const useDeleteAttributeModal = (attribute?: AttributeDefinitionsNode) => {
  const [showWarningModal, {
    setTrueCallback: setShowWarningModal,
    setFalseCallback: setHideWarningModal,
  }] = useOptimizedBooleanState(false);

  const [isDeleteConfirmed, {
    toggleCallback: toggleDeleteConfirm, setFalseCallback: setDeleteConformFalse,
  }] = useOptimizedBooleanState(false);

  const { removeAttribute } = useAttributesMutations();

  return useMemo(() => ({
    openDeleteAttributeModal: setShowWarningModal,
    deleteAttributeModal: showWarningModal && attribute?.id ? (
      <DeleteDialogModalStyled
        title="Delete property"
        info={showWarningModal && (
          <>
            Are you sure you want to delete
            {' '}
            <TagStyled
              inline
              limitSize={false}
              icon={<AttributeTagIconContainer>{getCustomAttributeTypeData(attribute.__typename).icon}</AttributeTagIconContainer>}
            >
              {attribute.name}
            </TagStyled>
            {' '}
            ? All docs with a value for this property will lose it. This can&apos;t be undone
          </>
        )}
        confirmLabel="Delete"
        confirmMessage="Yes, permanently delete this property"
        hide={() => {
          setDeleteConformFalse();
          setHideWarningModal();
        }}
        onConfirm={() => removeAttribute(attribute.id)}
        onToggleConfirmation={toggleDeleteConfirm}
        isConfirmDisabled={!isDeleteConfirmed}
      />
    ) : null,
  }), [
    attribute?.__typename, attribute?.id, attribute?.name,
    isDeleteConfirmed, removeAttribute,
    setDeleteConformFalse, toggleDeleteConfirm,
    setHideWarningModal, setShowWarningModal, showWarningModal]);
};
