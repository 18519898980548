/* eslint-disable no-nested-ternary */
import { darken, rgba } from 'polished';
import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { Color as ColorApi } from '../../../../graphql-codegen/generated';
import { CloseIcon, ArrowRightUpIcon } from '../../icons';
import { caption400 } from '../../theme/typo';

interface TagProps {
  $color?: ColorApi | 'grey' | 'greyAlt';
  $onDarkBg?: boolean;
  $hasSideButton?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  href?: string;
  $inline?: boolean;
}

export const Container = styled.div<TagProps>`
  --bg: ${p => (!p.$color || p.$color === 'grey'
    ? p.theme.colors.tag.bg
    : p.$color === 'greyAlt'
      ? p.theme.colors.tag.greyAlt.bg
      : p.theme.nuances[p.$color].tag
  )};
  --bgHover: ${p => (!p.$color || p.$color === 'grey'
    ? p.theme.colors.tag.bgHover
    : p.$color === 'greyAlt'
      ? p.theme.colors.tag.greyAlt.bgHover
      : darken(0.05, p.theme.nuances[p.$color].tag)
  )};
  --bgActive: ${p => (!p.$color || p.$color === 'grey'
    ? p.theme.colors.tag.bgActive
    : p.$color === 'greyAlt'
      ? p.theme.colors.tag.greyAlt.bgActive
      : darken(0.1, p.theme.nuances[p.$color].tag)
  )};
  border-radius: 4px;
  border: ${p => (p.$color ? 'none' : `1px solid ${p.theme.colors.tag.border}`)};
  position: relative;
  display: flex;
  height: 20px;
  overflow: hidden;
  ${caption400};
  max-width: 140px;
  flex: none;



  color: ${p => p.theme.colors.tag.color};
  ${p => p.$onDarkBg && css`
    color: ${p.theme.colors.text.opposite};
  `};

  > *:first-child {
    flex-shrink: 1;
    overflow: hidden;
  }

  ${p => p.$inline && css`
    display: inline;
    border: none;
    padding-top: 2px;
    padding-bottom: 2px;
  `}
`;

const TagCss = css<TagProps>`
  padding: ${p => (p.$hasSideButton ? '0 2px 0 4px' : '0 4px')};

  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;

  height: 100%;

  ${p => p.$color && css`
    background-color: var(--bg);
    &:hover {
      background-color: var(--bgHover);
    }
    &:active {
      background-color: var(--bgActive);
    }
  `}

  ${p => (p.onClick || p.href) && p.$color && css`
    &:hover {
      background-color: var(--bgHover);
    }
    &:active {
      background-color: var(--bgActive);
    }
  `};

  ${p => (p.onClick || p.href) && p.$onDarkBg && css`
    &:hover {
      background-color: ${rgba(255, 255, 255, 0.05)};
    }
    &:active {
      background-color: ${rgba(255, 255, 255, 0.15)};
    }
  `};
`;

export const Content = styled.div<Omit<TagProps, 'href'>>`
  ${TagCss}

  ${p => (p.onClick || p.onClickCapture) && css`
    cursor: pointer;
  `};

  ${p => !(p.onClick || p.onClickCapture) && css`
    &:hover {
      background-color: var(--bg);
    }
    &:active {
      background-color: var(--bg);
    }
  `};

  &[data-active=true] {
    background: var(--bgActive);
  }

  button {
    background-color: ${p => p.theme.colors.background.primary};
  }

  ${p => p.$inline && css`
    display: inline-flex;
  `}
`;

export const Link = styled.a<TagProps>`
  ${TagCss}
  color: ${p => p.theme.colors.text.secondary};
  padding: 0 4px 0 2px;
  > svg {
    width: 10px;
    height: 10px;
  }
`;

interface NameProps {
  $limitSize?: boolean;
}

export const Name = styled.div<NameProps>`
  ${p => p.$limitSize && css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

export const TagButton = styled.button<TagProps>`
  flex: none;
  ${TagCss}
  color: ${p => p.theme.colors.text.secondary};
  padding: 0 4px 0 2px;
  cursor: pointer;
`;

export const TagButtonClose = styled(CloseIcon)`
  width: 9px;
  height: 9px;
`;

export const TagButtonLink = styled(ArrowRightUpIcon)`
  width: 9px;
  height: 9px;
`;

export const Start = styled.div<TagProps>`
  flex: none;
  padding: 0 2px 0 4px;

  position: relative;
  display: flex;
  align-items: center;

  height: 100%;

  ${p => p.$color && css`
    background-color: var(--bg);
  `}
`;
