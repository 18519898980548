import { BillingPlan, Feature } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { InfoIconOutline, AddIcon, BulbIcon, ReleaseIcon } from '@cycle-app/ui/icons';
import { ReactNode, useMemo, useState } from 'react';
import { isNode } from 'react-flow-renderer';

import featureStatusesPreview from 'src/assets/feature-preview-statuses.png';
import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import { DocTypesList } from 'src/components/DocTypesList';
import { FeatureEmptyState } from 'src/components/FeatureEmptyState';
import { FeedbackLifecycle } from 'src/components/FeedbackLifecycle';
import FlowDoctypes from 'src/components/FlowDoctypes/FlowDoctypes';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { BILLING_LIMIT_CUSTOM_DOC_TYPES } from 'src/constants/billing.constants';
import { PageId, routing } from 'src/constants/routing.constant';
import { useCurrentBilling, useIsRoadmapsEnabled, useProductAddOn, useRouteMatch } from 'src/hooks';
import { useGlobalHierarchyElements } from 'src/hooks/hierarchy/useGlobalHierarchy';
import { useMustAddRoadmapsTemplate } from 'src/hooks/useCanExploreRoadmapsTemplates';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useRoadmapTour } from 'src/hooks/useRoadmapTour';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { setFeatureToEnable } from 'src/reactives/features.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openRoadmapsTemplateModal } from 'src/reactives/roadmaps.reactive';
import { getCustomDocTypesCount, isParentOfInsight } from 'src/utils/docType.util';
import { getLayoutedElements } from 'src/utils/flow.util';

import {
  Container, Content, DocTypeButton, Hierarchy, Info, RoadmapsContainer, StyledAiIcon, Icons, ListHeader,
} from './SettingsRoadmaps.styles';

export const SettingsRoadmaps = ({ children }: { children: ReactNode }) => {
  const { open: openRoadmapTour } = useRoadmapTour();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const matchRoadmapsDocType = !!useRouteMatch(routing[PageId.SettingsRoadmapsDocType]);
  return (
    <Container>
      <SettingsViewHeader
        title="Roadmaps"
        description="Plan your product, collaboratively"
        helpAction={openRoadmapTour}
        actionsSlot={(
          <>
            {isRoadmapsEnabled && <FeedbackLifecycle step="roadmap" />}
          </>
        )}
      />

      <Content $center={!isRoadmapsEnabled}>
        {isRoadmapsEnabled ? (
          <>
            <RoadmapsDocTypes />
            <Hierarchy>
              {!matchRoadmapsDocType && <RoadmapsHierarchy />}
              <Info>
                <InfoIconOutline />
                Click on a doc type to see its details
              </Info>
              {matchRoadmapsDocType && children}
            </Hierarchy>
          </>
        ) : (
          <FeatureEmptyState
            onClick={() => setFeatureToEnable(Feature.Roadmap)}
            src={featureStatusesPreview}
            title="Roadmaps"
            // eslint-disable-next-line max-len
            description="Enable Roadmaps to unlock planning capability as well as a seamless project management experience. With Roadmaps, you will create multi-level planning views, customize your statuses, and integrate with Linear to keep feedback and issue tracking in sync"
          />
        )}
      </Content>
    </Container>
  );
};

const RoadmapsDocTypes = () => {
  const { docTypes } = useGetDocTypes();
  const { navigate } = useNavigateToSettings();
  const currentBilling = useCurrentBilling();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const match = useRouteMatch(routing[PageId.SettingsRoadmapsDocType]);
  const mustAddRoadmapsTemplate = useMustAddRoadmapsTemplate();
  const doctypesAddOn = useProductAddOn('UNLIMITED_DOCTYPES');

  return (
    <RoadmapsContainer>
      <ListHeader>
        <h3>Doc types</h3>
        <DocTypeButton
          onClick={e => {
            e.stopPropagation();

            if (mustAddRoadmapsTemplate) {
              openRoadmapsTemplateModal();
              return;
            }

            if (
              !doctypesAddOn?.isEnabled &&
              currentBilling?.plan !== BillingPlan.BusinessPlus &&
              getCustomDocTypesCount(docTypes) >= BILLING_LIMIT_CUSTOM_DOC_TYPES
            ) {
              setLimitationsModal({
                action: 'USE_ADD_ON',
                brand: 'UNLIMITED_DOCTYPES',
              });
              return;
            }

            setIsModalOpen(true);
          }}
        >
          <AddIcon size={12} />
          Add
        </DocTypeButton>
      </ListHeader>

      <DocTypesList
        isItemActive={doctypeId => match?.params.doctypeId === doctypeId}
        onItemClick={doctypeId => navigate(PageId.SettingsRoadmapsDocType, { doctypeId })}
        labelSuffix={docType => isParentOfInsight(docType) && (
          <Icons>
            <Tooltip
              content="This doc type's name and description impact Cycle AI requests"
              placement="top"
              withWrapper={false}
            >
              <StyledAiIcon size={12} />
            </Tooltip>

            <Tooltip
              content="Linked to Insights"
              placement="top"
              withWrapper={false}
            >
              <BulbIcon size={14} />
            </Tooltip>

            <Tooltip
              content="Linked to Releases"
              placement="top"
              withWrapper={false}
            >
              <ReleaseIcon size={14} />
            </Tooltip>
          </Icons>
        )}
      />

      {isModalOpen && <DoctypesEditCommonModal onHide={() => setIsModalOpen(false)} />}
    </RoadmapsContainer>
  );
};

const RoadmapsHierarchy = () => {
  const globalElements = useGlobalHierarchyElements();

  const layoutedElements = useMemo(() => {
    const globalCount = globalElements.filter(e => isNode(e) && !e.data.phantom).length;
    return globalCount >= 1 ? getLayoutedElements(globalElements, { marginx: 36 }) : [];
  }, [globalElements]);

  return <FlowDoctypes elements={layoutedElements} />;
};
