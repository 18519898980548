import { DocAttribute, DoctypeType, ThemeType } from '@cycle-app/graphql-codegen';
import { InsightCardSkeleton, CompanyLogo } from '@cycle-app/ui';
import { nodeToArray, getHighlightHash, getHighlightHashData, HIGHLIGHT_HASH_PREFIX } from '@cycle-app/utilities';
import { FC, ReactNode, useEffect, useRef } from 'react';

import { AiStateTag } from 'src/components/AiStateTag';
import DocAssignee from 'src/components/DocAssignee/DocAssignee';
import { DocParentDropdown } from 'src/components/DocParentDropdown';
import { DocSource } from 'src/components/DocSource';
import { DocTagProperties } from 'src/components/DocTagProperties';
import { useProductBase, useOptimizedBooleanState, useLocation, useIsRoadmapsEnabled } from 'src/hooks';
import useDocCoverMutations from 'src/hooks/api/mutations/useDocCoverMutations';
import { DOC_PREVIEW_DELAY, useDocPreview } from 'src/hooks/doc/useDocPreview';
import { Insight as InsightType, InsightList } from 'src/hooks/insight/useDocInsights.types';
import { useInsightCardEditQuoteProps } from 'src/hooks/insight/useInsightCardEditQuoteProps';
import { useDocRightPanelShortcutListener } from 'src/hooks/shortcuts/useDocRightPanelShortcutListener';
import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { useNavigate } from 'src/hooks/useNavigate';
import { useZoomableProps } from 'src/hooks/useZoomableProps';
import { useGetPermission } from 'src/reactives';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { getDocType, useGetDocTypeName } from 'src/reactives/docTypes.reactive';
import { useGetHighlight, setHighlight, resetHighlight, HighlightState } from 'src/reactives/highlight.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetSelection } from 'src/reactives/selection.reactive';
import { getThemeConfig } from 'src/reactives/theme.reactive';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { getDocKey, isCycleWithoutFileNorUrlSource } from 'src/utils/doc.util';
import { isCustom, isInsight } from 'src/utils/docType.util';
import { convertLegacyLocalStorageValue } from 'src/utils/theme.utils';

import CoverImageInputFile from '../CoverImageInputFile/CoverImageInputFile';
import { DocCompanyCustomer } from '../DocCompanyCustomer';
import { DocLinear } from '../DocLinear';
import { DocStatus } from '../DocStatus';
import { InsightCardOptions } from '../InsightCardOptions';
import { IllustrationDark } from './IllustrationDark';
import { IllustrationLight } from './IllustrationLight';
import { InsightSkeletons } from './InsightSkeletons';
import {
  ButtonEmptyWrapper,
  ButtonWrapper,
  Container,
  EmptyStateContainer,
  Paragraph,
  ParagraphSkeleton,
  PropertiesContainer,
  ScrollableContainer,
  Title,
  TitleSkeleton,
  ParentContainer,
  OptionContainer,
  CustomerContainer,
  StyledInsightCard,
  DocParentLine,
  CustomerLabel,
  CustomerName,
} from './InsightsList.styles';

type InsightListProps = {
  children?: ReactNode;
  cursor?: string | null;
  hasNextPage?: boolean;
  insights: InsightList;
  isLoading?: boolean;
  isPaginationLoading?: boolean;
  isParent?: boolean;
  loadMore?: (cursor: string) => void;
  modal?: ReactNode;
  hideSource?: boolean;
  hideCustomer?: boolean;
  hideAssignee?: boolean;
  hideParent?: boolean;
  hideStatus?: boolean;
  hideCopy?: boolean;
  skeletonsCount?: number;
  docTargetsAiCount?: number;
};

export const InsightsList: FC<React.PropsWithChildren<InsightListProps>> = ({
  children,
  cursor,
  hasNextPage,
  insights,
  isLoading,
  isPaginationLoading,
  isParent,
  loadMore,
  modal,
  hideSource,
  hideCustomer,
  hideAssignee,
  hideParent,
  hideStatus,
  hideCopy,
  skeletonsCount = 0,
  docTargetsAiCount,
}) => {
  useDocRightPanelShortcutListener({ docIds: insights.map(insight => insight?.doc?.id) });
  const highlight = useGetHighlight();
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    hash, pathname,
  } = useLocation();
  const prevPathname = useRef(pathname);

  // Avoids conflicts with the mouseenter on each card when we want to autofocus an insight.
  // We enable mouseenter only when the user starts to move or scroll.
  const [
    isManualHighlightDisabled,
    { setFalseCallback: setAllowManualHighlight },
  ] = useOptimizedBooleanState(hash?.startsWith(`#${HIGHLIGHT_HASH_PREFIX}`));

  useEffect(() => {
    const clean = () => {
      if (
        prevPathname.current === pathname &&
        hash?.startsWith(`#${HIGHLIGHT_HASH_PREFIX}`)
      ) {
        // This is a pattern saw on github issues: when a user pastes a comment link,
        // the hash is removed when he interacts with the page.
        window.history.replaceState('', document.title, window.location.pathname + window.location.search);
        resetHighlight();
      }
    };
    prevPathname.current = pathname;
    document.addEventListener('click', clean, {
      once: true,
      capture: true,
    });
    document.addEventListener('keydown', clean, {
      once: true,
      capture: true,
    });
  }, [pathname, hash]);

  const scrollInBlock = (blockId: string, behavior: ScrollIntoViewOptions['behavior'] = 'smooth') => {
    const block = ref.current?.querySelector(`[data-block-id="${blockId}"]`);
    if (block) {
      block.scrollIntoView({
        behavior,
        block: 'center',
        inline: 'center',
      });
    }
  };

  useEffect(() => {
    if (hash) {
      const {
        docId, blockId,
      } = getHighlightHashData(hash.replace('#', ''));
      if (docId || blockId) {
        setHighlight({
          docId: docId ?? null,
          blockId: blockId ?? null,
          context: 'auto-focus',
        });
      }
    }
  }, [hash]);

  useEffect(() => {
    if (highlight.blockId && highlight.context === 'doc-content') {
      scrollInBlock(highlight.blockId);
    }
  }, [highlight.blockId, highlight.context]);

  const { colorTheme } = getThemeConfig();
  const { selected } = useGetSelection();

  const Illustration = convertLegacyLocalStorageValue(colorTheme) === ThemeType.Nightfall
    ? <IllustrationDark />
    : <IllustrationLight />;

  if (isLoading) {
    return (
      <Container>
        <EmptyStateContainer>
          {Illustration}
          <TitleSkeleton />
          <ParagraphSkeleton />
        </EmptyStateContainer>
      </Container>
    );
  }

  if (!insights.length && skeletonsCount === 0) {
    return (
      <Container>
        <EmptyStateContainer>
          {Illustration}
          <Title>{(isParent || !docTargetsAiCount) ? 'No insight yet' : 'No user-verified insight yet'}</Title>
          <Paragraph>
            Insights let you connect feedback to
            <br />
            product delivery workflows
          </Paragraph>
          <ButtonEmptyWrapper>
            {modal}
          </ButtonEmptyWrapper>
        </EmptyStateContainer>
      </Container>
    );
  }

  return (
    <Container ref={ref}>
      <ButtonWrapper>
        {modal}
      </ButtonWrapper>
      <ScrollableContainer
        isLoading={!!isLoading}
        hasMoreData={!!hasNextPage}
        loadMore={() => loadMore?.(cursor ?? '')}
        id="insights-list"
        onWheel={() => {
          if (isManualHighlightDisabled) {
            setAllowManualHighlight();
          }
        }}
      >
        <InsightSkeletons count={skeletonsCount} />
        {insights.map((insightLink, index) => (
          <Insight
            key={insightLink?.id || insightLink?.doc?.id || index}
            insightLink={insightLink}
            hideSource={hideSource}
            hideCustomer={hideCustomer}
            hideAssignee={hideAssignee}
            hideParent={hideParent}
            hideStatus={hideStatus}
            hideCopy={hideCopy}
            isParent={isParent || false}
            highlightBlockId={highlight.blockId}
            highlightDocId={highlight.docId}
            highlightContext={highlight.context}
            isManualHighlightDisabled={isManualHighlightDisabled}
            isSelected={selected?.includes(insightLink?.doc?.id || '')}
            setAllowManualHighlight={setAllowManualHighlight}
          />
        ))}
        {isPaginationLoading && <InsightCardSkeleton />}
      </ScrollableContainer>
      {children}
    </Container>
  );
};

type InsightProps = {
  hideAssignee?: boolean;
  hideCustomer?: boolean;
  hideSource?: boolean;
  hideParent?: boolean;
  hideStatus?: boolean;
  hideCopy?: boolean;
  isParent: boolean;
  insightLink: InsightType;
  highlightBlockId: HighlightState['blockId'];
  highlightDocId: HighlightState['blockId'];
  highlightContext: HighlightState['context'];
  isManualHighlightDisabled: boolean;
  isSelected: boolean;
  setAllowManualHighlight: VoidFunction;
};

const Insight: FC<React.PropsWithChildren<InsightProps>> = ({
  insightLink,
  hideSource,
  hideCustomer,
  hideAssignee,
  hideParent,
  hideStatus,
  hideCopy,
  isParent,
  highlightBlockId,
  highlightDocId,
  highlightContext,
  isManualHighlightDisabled,
  isSelected,
  setAllowManualHighlight,
}) => {
  const { navigateToDocFullPage } = useNavigate();
  const ref = useRef<HTMLDivElement | null>(null);
  const product = useProductBase();
  const [isOptionFocus, {
    setTrueCallback, setFalseCallback,
  }] = useOptimizedBooleanState(false);
  const [isCoverUpdateLoading, {
    setTrueCallback: startCoverUpdate, setFalseCallback: stopCoverUpdate,
  }] = useOptimizedBooleanState(false);
  const {
    isClickOnEditForm,
    contextEl,
    contextUpdateEl,
    startEdit,
  } = useInsightCardEditQuoteProps({
    docTarget: insightLink,
  });
  const { canUpdateInsight } = useGetPermission();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const insightLinkDocTypeName = useGetDocTypeName(insightLink?.doc?.doctype.id);

  useEffect(() => {
    if (
      ref.current && (
        (highlightBlockId && highlightBlockId === insightLink?.blockId) ||
        (highlightDocId && highlightDocId === insightLink?.doc?.id)
      ) && highlightContext === 'auto-focus') {
      ref.current.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, [insightLink?.blockId, insightLink?.doc?.id, highlightBlockId, highlightContext, highlightDocId]);

  const coverInputRef = useRef<HTMLInputElement | null>(null);

  const { updateDocCover } = useDocCoverMutations(insightLink?.doc?.id || '', {
    onUpdateStart: startCoverUpdate,
    onUpdateSuccess: stopCoverUpdate,
  });

  const { removeDocCover } = useDocCoverMutations(insightLink?.doc?.id || '');
  const {
    showDocPreview, hideDocPreview,
  } = useDocPreview();
  const { toggleSelectDoc } = useDocsSelection();
  const { selected } = useGetSelection();

  const setCoverInputClick = () => coverInputRef.current?.click();

  const getZoomableProps = useZoomableProps();

  if (!insightLink?.doc) return null;

  const {
    blockId, doc, content,
  } = insightLink;

  const isBlockHighlighted =
    !!highlightBlockId &&
    blockId === highlightBlockId &&
    (highlightContext === 'auto-focus' || highlightContext === 'doc-content');

  // a doc can be highlighted only from auto-focus.
  const isDocHighlighted =
    !!highlightDocId &&
    doc.id === highlightDocId &&
    highlightContext === 'auto-focus';
  const isHighlighted = isBlockHighlighted || isDocHighlighted;
  const quoteContent = doc.docSource?.content || content || doc.title;

  const hasProperties = !!nodeToArray(doc.attributes).length;
  const statusInProperties = hideParent && !hideStatus;

  const isParentWithSource = isParent && !!doc.docSource?.doc;
  const hasParent = !isParent && !!doc.parent;
  const docType = getDocType(doc.doctype.id);
  const isDisabled = !doc || !(isParentWithSource || hasParent || !isInsight(docType));
  const docTypeParent = getDocType(doc.parent?.doctype.id);

  return (
    <StyledInsightCard
      key={doc.id}
      coverUrl={doc.cover?.url}
      coverProps={getZoomableProps({
        src: insightLink?.doc?.cover?.url,
      })}
      title={doc.title}
      context={contextEl}
      contextText={quoteContent}
      isHighlighted={isHighlighted}
      isSelected={isSelected}
      hasSelection={!!selected.length}
      hasSmallMaxHeight
      blockId={blockId}
      hideCopy={hideCopy}
      isCoverLoading={isCoverUpdateLoading}
      onCoverRemove={removeDocCover}
      onCoverUpdate={setCoverInputClick}
      {...isParent && {
        onContextMouseEnter: () => showDocPreview(doc.id, DOC_PREVIEW_DELAY),
        onContextMouseLeave: () => hideDocPreview(),
      }}
      $isDisabled={isDisabled}
      onClick={(e) => {
        if (!doc) return;
        if (selected.length) {
          toggleSelectDoc(doc.id);
          return;
        }

        /**
         * We do not want to redirect if user is clicking inside the editing
         * quote area
         */
        if (isClickOnEditForm(e)) return;

        /**
         * If the link have a source -> Go to the source
         * Most of the time coming from insight parent (initiative for instance)
         * and going to a feedback
         */
        if (isParent && !!doc.docSource?.doc) {
          navigateToDocFullPage({
            title: doc.docSource.doc.title,
            id: doc.docSource.doc.id,
            // use hash as the user can open url in new tab with metaKey.
            hash: getHighlightHash({
              docId: doc.id,
              blockId,
            }),
          }, {}, e.metaKey);
          return;
        }
        /**
         * If the link have a parent -> Go the to parent
         * Always coming from the feedback and going to the insight parent
         * (initiative for instance)
         */
        if (!isParent && !!doc.parent) {
          navigateToDocFullPage({
            title: doc.parent.title,
            id: doc.parent.id,
            hash: getHighlightHash({
              docId: doc.id,
              blockId,
            }),
          }, {}, e.metaKey);
          return;
        }

        /**
         * We don't want to open an insight from the insight list
         */
        if (isInsight(docType)) return;

        navigateToDocFullPage({
          title: doc.title,
          id: doc.id,
        }, {}, e.metaKey);
      }}
      onMouseMove={() => {
        setAllowManualHighlight();
      }}
      onMouseEnter={() => {
        setDocItem({ hoverDocId: doc.id });
        if (!isManualHighlightDisabled && blockId) {
          setHighlight({
            docId: null,
            blockId,
            context: 'doc-link-list',
          });
        }
      }}
      onMouseLeave={() => {
        setDocItem({ hoverDocId: null });
        if (!isManualHighlightDisabled) {
          resetHighlight();
        }
      }}
      onContextCopied={context => copyToClipboard({
        text: context,
        notification: 'Text copied to clipboard!',
      })}
      onSelect={() => toggleSelectDoc(doc.id)}
      properties={(hasProperties || !hideSource || statusInProperties) && (
        <PropertiesContainer>
          {!hideSource && !!doc.docSource?.doc?.id && !isCycleWithoutFileNorUrlSource(doc.docSource.doc.source) && (
            <DocSource
              docId={doc.docSource?.doc?.id}
              source={doc.docSource.doc.source}
              color="greyAlt"
            />
          )}
          {statusInProperties && !!doc.status?.id && (
            <DocStatus
              docTypeId={doc.doctype.id}
              statusId={doc.status.id}
              isDisabled
              hideLabel
            />
          )}
          {hasProperties && <DocTagProperties properties={nodeToArray(doc.attributes) as DocAttribute[]} />}
        </PropertiesContainer>
      )}
      options={(
        <OptionContainer>
          <AiStateTag
            docId={doc.id}
            docTypeId={doc.doctype.id}
            aiState={doc.aiState}
          />
          {!hideAssignee && !!doc.assignee?.id && insightLinkDocTypeName && (
            <DocAssignee
              assignee={doc.assignee}
              showLabel={false}
              tooltipPlacement="top"
              isDisabled={!!insightLink.id}
              isRemovable={false}
              docId={doc.id}
              context="doc-item"
              docTypeName={insightLinkDocTypeName}
              docTypeType={DoctypeType.Insight}
            />
          )}
          <InsightCardOptions
            blockId={insightLink.blockId}
            doc={{
              ...doc,
              _docKey: getDocKey(product?.key, doc.publicId) || '',
            }}
            editParent={isParent}
            isOpen={isOptionFocus}
            onOpen={setTrueCallback}
            onClose={setFalseCallback}
            onUpdateQuoteSelect={() => {
              if (canUpdateInsight) {
                startEdit();
              } else {
                setLimitationsModal({ action: 'INSIGHT_UPDATE' });
              }
            }}
            onAddCoverClicked={setCoverInputClick}
          />
          <CoverImageInputFile
            ref={coverInputRef}
            onCoverChanged={updateDocCover}
          />
        </OptionContainer>
      )}
      contextFooter={!hideCustomer && !!doc.customer && (
        <CustomerContainer>
          <DocCompanyCustomer
            size="S"
            doc={doc}
            isDisabled
            showCompanyName
          >
            <CustomerLabel>
              <CompanyLogo company={doc.customer.company} size="XS" />
              <CustomerName>
                {doc.customer.displayName}
              </CustomerName>
              {doc.customer.company?.name && (
                <>
                  <span>·</span>
                  <span>{doc.customer.company.name}</span>
                </>
              )}
            </CustomerLabel>
          </DocCompanyCustomer>
        </CustomerContainer>
      )}
      parentSlot={!hideParent && !!doc && (
        <ParentContainer>
          {doc.status && isRoadmapsEnabled && (
            <DocStatus
              docTypeId={doc.doctype.id}
              statusId={doc.status.id}
              isDisabled
              hideLabel
            />
          )}
          <DocParentLine>
            <DocParentDropdown
              showParentTitle
              docId={doc.id}
              docTypeId={doc.doctype.id}
              minimal
              placeholder="Add"
              context="doc-item"
              style={{ overflow: 'hidden' }}
            />
            {doc.parent?.id && isCustom(docTypeParent) && (
              <DocLinear
                color="greyAlt"
                docId={doc.parent.id}
                automationUrl={doc.parent.automationUrl}
                automationId={doc.parent.automationId}
              />
            )}
          </DocParentLine>
        </ParentContainer>
      )}
      contextUpdate={contextUpdateEl}
    />
  );
};
