import { Input } from '@cycle-app/ui';
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const AppNameInput = styled(Input)`
  input {
    width: 224px;
    border-radius: 6px;
    height: 32px;
  }
`;
