import { CustomerFragment } from '@cycle-app/graphql-codegen/generated';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { useRef, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useCustomerUpdate } from 'src/hooks/api/mutations/customers/useCustomerUpdate';
import { addToaster } from 'src/utils/toasters.utils';

import { StyledEditTextButton } from './CustomerName.styles';

interface Props {
  className?: string;
  customer: CustomerFragment;
  readOnly?: boolean;
}

type FormData = { name: string };

export const CustomerName: FC<React.PropsWithChildren<Props>> = ({
  className, customer, readOnly,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    update, isLoading,
  } = useCustomerUpdate(customer);
  const {
    control, handleSubmit, setValue,
  } = useForm<FormData>({ defaultValues: { name: customer.name ?? '' } });
  const placeholder = readOnly
    ? 'No name'
    : (
      <>
        Add name
        <PenFilledIcon />
      </>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <StyledEditTextButton
            ref={inputRef}
            className={className}
            disabled={readOnly}
            isLoading={isLoading}
            onChange={field.onChange}
            onBlur={onBlur}
            onCancel={onBlur}
            placeholder={placeholder}
            value={field.value}
            editTooltip="Edit customer name"
          />
        )}
      />
    </form>
  );

  function onBlur() {
    setValue('name', customer.name || '');
  }

  async function onSubmit({ name }: FormData) {
    if (!name.trim() && !customer.email) {
      addToaster({
        title: 'An error occured',
        message: 'Customers must have at least a name or an email',
      });
      return;
    }
    const result = await update({
      customerId: customer.id,
      name,
    });
    if (result.data?.updateCustomer?.name) {
      inputRef.current?.blur();
      setValue('name', result.data.updateCustomer.name);
    }
  }
};
