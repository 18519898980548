import { Button, ButtonVariant, CheckboxInput } from '@cycle-app/ui';
import { WarningIcon, CloseIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode, ChangeEventHandler, useRef, useLayoutEffect } from 'react';

import { Layer } from '../../types/layers.types';
import {
  Title, Actions, Body, PortalModalStyled, IconContainer, Header, CloseButtonStyled, Footer, ConfirmMessageContainer,
} from './DialogModal.styles';

export interface DialogModalProps {
  className?: string;
  title: string;
  info?: string | ReactNode;
  type?: 'warning' | 'default';
  confirmMessage?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  useHighMaskLayer?: boolean;
  hide: () => void;
  /* If onCancel is async, the modal will be hidden once the operation is complete */
  onCancel?: (() => void) | (() => Promise<void>);
  onConfirm?: (() => void) | (() => Promise<void>);
  variantCancelButton?: ButtonVariant;
  variantSubmitButton?: ButtonVariant;
  loading?: boolean;
  autoHide?: boolean;
  isCancelDisabled?: boolean;
  isCancelLoading?: boolean;
  isConfirmDisabled?: boolean;
  closeOnEscape?: boolean;
  onToggleConfirmation?: ChangeEventHandler<HTMLInputElement>;
  layer?: Layer;
  width?: string;
  confirmButtonId?: string;
  hasFooter?: boolean;
}

const DialogModal: FC<React.PropsWithChildren<DialogModalProps>> = ({
  className,
  title,
  info,
  type = 'warning',
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  useHighMaskLayer,
  onCancel,
  onConfirm,
  hide,
  variantCancelButton = 'warning-secondary',
  variantSubmitButton = 'warning',
  loading,
  autoHide = true,
  confirmMessage,
  isCancelDisabled,
  isCancelLoading,
  isConfirmDisabled,
  onToggleConfirmation,
  layer = Layer.DialogModal,
  width,
  confirmButtonId,
  closeOnEscape = true,
  hasFooter = true,
}) => {
  const showConfirmMessage = !!confirmMessage && !!onToggleConfirmation;
  const confirmButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (!isConfirmDisabled) {
      confirmButtonRef.current?.focus();
      return;
    }
    if (showConfirmMessage) {
      checkboxRef.current?.focus();
      return;
    }
    closeButtonRef.current?.focus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PortalModalStyled
      animate
      hide={hide}
      useHighMaskLayer={useHighMaskLayer}
      className={className}
      layer={layer}
      width={width}
      closeOnEscape={closeOnEscape}
    >
      <Header>
        <Title>
          {type === 'warning' && (
            <IconContainer>
              <WarningIcon />
            </IconContainer>
          )}
          {title}
        </Title>
        <CloseButtonStyled
          size="L"
          onClick={hide}
          ref={closeButtonRef}
        >
          <CloseIcon />
        </CloseButtonStyled>
      </Header>
      {info && (
        <Body>
          {info}
        </Body>
      )}
      {hasFooter && (
        <Footer>
          {showConfirmMessage && (
            <ConfirmMessageContainer>
              <CheckboxInput
                id="dialog-confirm-checkbox"
                defaultChecked={false}
                label={confirmMessage}
                onChange={onToggleConfirmation}
                ref={checkboxRef}
              />
            </ConfirmMessageContainer>
          )}
          <Actions>
            <Button
              onClick={async () => {
                await onCancel?.();
                hide();
              }}
              variant={variantCancelButton}
              size="M"
              disabled={isCancelDisabled}
              isLoading={isCancelLoading}
            >
              {cancelLabel}
            </Button>
            <Button
              onClick={() => {
                if (autoHide) {
                  hide();
                }
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                onConfirm?.();
              }}
              variant={variantSubmitButton}
              size="M"
              isLoading={loading}
              disabled={isConfirmDisabled}
              id={confirmButtonId}
            >
              {confirmLabel}
            </Button>
          </Actions>
        </Footer>
      )}
    </PortalModalStyled>
  );
};

export default DialogModal;
