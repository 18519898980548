import { ThemeType } from '@cycle-app/graphql-codegen';
import { typo, Tooltip } from '@cycle-app/ui';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)`
  padding: 0;
  ${p => p.theme.themeType === ThemeType.Nightfall && 'background: #000;'}
`;

export const Content = styled.div`
  padding: 24px 24px;
  display: flex;
  gap: 8px;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LeftLabels = styled(Labels)`
  align-items: flex-end;
`;

export const RightLabels = styled(Labels)`
  align-items: flex-start;
`;

export const Label = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${typo.body500}
  color: ${p => p.theme.colors.text[p.$active ? 'white' : 'disabled']};
`;

export const SvgContainer = styled.div`
  padding: 11px 0 9px;
  display: flex;
  align-items: center;
`;
