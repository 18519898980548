import { ErrorPolicy } from '@apollo/client';
import {
  AddDoctypeParentDocument,
  DoctypeFragment,
  RemoveDoctypeParentDocument,
  DoctypeChildrenIdsFragmentDoc,
  DoctypeChildrenIdsFragment,
} from '@cycle-app/graphql-codegen';
import { useCallback, useMemo } from 'react';

import { useGetDoctypeFromCache } from 'src/hooks/api/cache/cacheDoctype';
import useSafeMutation from 'src/hooks/useSafeMutation';

export default function useDoctypeParentsMutations(doctypeArg: DoctypeFragment | undefined) {
  const [addDoctypeParentMutation, { loading: loadingAddDoctype }] = useSafeMutation(AddDoctypeParentDocument);
  const [removeDoctypeParentMutation, { loading: loadingRemoveDoctype }] = useSafeMutation(RemoveDoctypeParentDocument);
  const getDoctypeFromCache = useGetDoctypeFromCache();

  const addDoctypeParent = useCallback((parentId: string, doctypeChild?: DoctypeFragment, options?: { errorPolicy?: ErrorPolicy }) => {
    const doctype = doctypeChild || doctypeArg;

    if (!doctype) return null;

    return addDoctypeParentMutation({
      ...options,
      variables: {
        doctypeId: doctype.id,
        parentId,
      },
      optimisticResponse: {
        addDoctypeParent: {
          ...doctype,
          parents: {
            __typename: 'DoctypesConnection',
            edges: [
              ...(doctype.parents?.edges ?? []),
              {
                __typename: 'DoctypeEdge',
                node: {
                  __typename: 'Doctype',
                  id: parentId,
                },
              },
            ],
          },
          children: {
            __typename: 'DoctypesConnection',
            edges: (doctype.children?.edges ?? []).map(edge => ({
              __typename: 'DoctypeEdge',
              node: {
                __typename: 'Doctype',
                id: edge.node.id,
              },
            })),
          },
        },
      },
      update: (cache, { data }) => {
        if (!doctypeChild || !parentId || !data?.addDoctypeParent?.id) return;

        const parentDoctypeFromCache = getDoctypeFromCache(parentId);
        if (!parentDoctypeFromCache) return;

        cache.writeFragment<DoctypeChildrenIdsFragment>({
          fragment: DoctypeChildrenIdsFragmentDoc,
          fragmentName: 'DoctypeChildrenIds',
          data: {
            ...parentDoctypeFromCache,
            children: {
              __typename: 'DoctypesConnection',
              edges: [
                ...(parentDoctypeFromCache.children?.edges ?? []),
                {
                  __typename: 'DoctypeEdge',
                  node: {
                    __typename: 'Doctype',
                    id: doctypeChild.id,
                  },
                },
              ],
            },
          },
        });
      },
    });
  }, [addDoctypeParentMutation, doctypeArg, getDoctypeFromCache]);

  const removeDoctypeParent = useCallback((parentId: string, doctypeChild?: DoctypeFragment) => {
    const doctype = doctypeChild || doctypeArg;

    if (!doctype) return null;

    return removeDoctypeParentMutation({
      variables: {
        doctypeId: doctype.id,
        parentId,
      },
      optimisticResponse: {
        removeDoctypeParent: {
          __typename: 'Doctype',
          id: doctype.id,
          parents: {
            __typename: 'DoctypesConnection',
            edges: (doctype.parents?.edges ?? []).filter(({ node }) => node.id !== parentId),
          },
        },
      },
      update: (cache) => {
        if (!doctypeChild || !parentId) return;

        const parentDoctypeFromCache = getDoctypeFromCache(parentId);
        if (!parentDoctypeFromCache) return;

        cache.writeFragment<DoctypeChildrenIdsFragment>({
          fragment: DoctypeChildrenIdsFragmentDoc,
          fragmentName: 'DoctypeChildrenIds',
          data: {
            id: parentDoctypeFromCache.id,
            children: {
              __typename: 'DoctypesConnection',
              edges: (parentDoctypeFromCache.children?.edges ?? []).filter(({ node }) => node.id !== doctypeChild.id),
            },
          },
        });
      },
    });
  }, [removeDoctypeParentMutation, doctypeArg, getDoctypeFromCache]);

  return useMemo(() => ({
    loading: loadingAddDoctype || loadingRemoveDoctype,
    addDoctypeParent,
    removeDoctypeParent,
  }), [loadingAddDoctype, loadingRemoveDoctype, addDoctypeParent, removeDoctypeParent]);
}
