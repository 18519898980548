import { AiState, DocBaseFragment } from '@cycle-app/graphql-codegen';
import { ActionButton, Button, Tooltip } from '@cycle-app/ui';
import { CaretIcon, InfoIconOutline } from '@cycle-app/ui/icons';
import { motion } from 'framer-motion';
import { ComponentPropsWithRef } from 'react';

import { InsightCardReadOnly } from 'src/components/InsightCardReadOnly';
import { InsightSkeletons } from 'src/components/InsightsList/InsightSkeletons';
import { PageId } from 'src/constants/routing.constant';
import { useDocInsights, useOptimizedBooleanState, useProduct } from 'src/hooks';
import { refetchAiGenetatedInsights } from 'src/hooks/insight/useDocInsights.cache';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useGetDocPanel, Content, useGetAiGeneratedInsights, toggleAiGeneratedInsights } from 'src/reactives/docRightPanel.reactive';

import {
  Container, Header, Title, Count, Cards, Card, EmptyState, EmptyStateText,
} from './AiGeneratedInsights.styles';
import { AiGeneratedInsightsModal } from './AiGeneratedInsightsModal';

type Props = Omit<LayoutProps, 'count'> & {
  doc: DocBaseFragment;
};

export const AiGenetaredInsights = ({
  isCollapsable, doc, ...props
}: Props) => {
  const { product } = useProduct();
  if (!doc.docTargetsAiCount && !product?.autoLinkToRoadmap) return null;
  return (
    <Layout
      isCollapsable={isCollapsable}
      count={doc.docTargetsAiCount}
      {...props}
    >
      {doc.docTargetsAiCount
        ? <AiGenetaredInsightsFull doc={doc} />
        : <AiGenetaredInsightsEmpty />}
    </Layout>
  );
};

const AiGenetaredInsightsEmpty = () => {
  const { navigate } = useNavigateToSettings({ useLimitationModal: true });
  return (
    <EmptyState>
      <EmptyStateText>
        <p>There’s no AI-generated insight</p>
        <p>Go to feedback autopilot settings to configure how AI-generated insights get created.</p>
      </EmptyStateText>
      <Button
        variant="outlined-alt"
        onClick={() => navigate(PageId.SettingsAi)}
      >
        Go to AI settings
      </Button>
    </EmptyState>
  );
};

const AiGenetaredInsightsFull = ({ doc }: { doc: DocBaseFragment }) => {
  const { content } = useGetDocPanel();

  const query = useDocInsights(doc.id, {
    aiStates: [AiState.AiCreated],
    skip: content !== Content.Insights,
  });

  const [
    isModalOpen, {
      setTrueCallback: openModal,
      setFalseCallback: closeModal,
    },
  ] = useOptimizedBooleanState(false);

  if (query.isLoading && !query.data) {
    return <InsightSkeletons />;
  }

  const firstInsight = query.insights[0];
  if (!firstInsight?.doc) return null;

  return (
    <>
      <Cards
        onClick={() => {
          refetchAiGenetatedInsights(doc.id);
          openModal();
        }}
      >
        <InsightCardReadOnly doc={firstInsight.doc} showAttributes />

        {doc.docTargetsAiCount > 1 && <Card />}
        {doc.docTargetsAiCount > 2 && <Card />}
      </Cards>

      {isModalOpen && (
        <AiGeneratedInsightsModal
          hide={closeModal}
          doc={doc}
        />
      )}
    </>
  );
};

type LayoutProps = ComponentPropsWithRef<'div'> & {
  isCollapsable?: boolean;
  count?: number;
};

const Layout = ({
  isCollapsable = false, count, children, ...props
}: LayoutProps) => {
  const isCollapsed = useGetAiGeneratedInsights().isCollapsed && isCollapsable;
  return (
    <Container $isCollapsed={isCollapsed} {...props}>
      <Header
        $isCollapsed={isCollapsed}
        onClick={isCollapsed ? toggleAiGeneratedInsights : undefined}
      >
        <Title>
          AI-generated insights
          {count != null && <Count>{count}</Count>}
          <Tooltip
            content="AI-generated, not verified insights are automatically created with feedback autopilot"
            placement="top"
            withWrapper={false}
          >
            <InfoIconOutline size={16} />
          </Tooltip>
        </Title>

        {isCollapsable && (
          <ActionButton
            onClick={e => {
              e.stopPropagation();
              toggleAiGeneratedInsights();
            }}
          >
            <CaretIcon size={12} direction={isCollapsed ? 'top' : 'bottom'} />
          </ActionButton>
        )}
      </Header>

      <motion.div
        style={{ zIndex: 0 }}
        animate={isCollapsed ? 'collapsed' : 'expanded'}
        variants={{
          collapsed: {
            height: 0,
            opacity: 0,
          },
          expanded: {
            height: 'auto',
            opacity: 1,
          },
        }}
      >
        {children}
      </motion.div>
    </Container>
  );
};
