import { Button, typo } from '@cycle-app/ui';
import { InfoIconOutline } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const TooltipTitle = styled.div`
  ${typo.caption600}
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TooltipDescription = styled.div`
  color: ${p => p.theme.colors.tooltip.color};
  ${typo.caption400}
`;

export const TooltipButton = styled(Button).attrs({
  variant: 'nospace',
})`
  border: none;
  ${typo.caption400}
  color: ${p => p.theme.colors.tooltip.textAlt};
  :hover {
    color: ${p => p.theme.colors.tooltip.color};
  }
`;

export const InfoIcon = styled(InfoIconOutline)`
  color: ${p => p.theme.colors.text.disabled};
  cursor: pointer;
`;
