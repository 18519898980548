import BoardOptions from 'src/components/BoardOptions/BoardOptions';
import { useBoardConfig } from 'src/contexts/boardConfigContext';

export const ViewActions = () => {
  const boardId = useBoardConfig(ctx => ctx.boardId);
  const boardConfigId = useBoardConfig(ctx => ctx.boardConfig?.id);
  if (!boardId) return null;
  return (
    <BoardOptions
      boardId={boardId}
      placement="bottom-start"
      tooltipPlacement="bottom"
      boardConfigId={boardConfigId}
      hideSettings
    />
  );
};
