import { UpdateProductDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { Events, Objects } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getInitial, setInitial } from 'src/reactives/initial.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';

export const useProductUpdate = () => {
  const [updateProduct, { loading: isUpdatingProduct }] = useSafeMutation(UpdateProductDocument, {
    onCompleted: (data) => {
      const initialProducts = getInitial().products;

      const product = initialProducts.find(p => p.id === data.updateProduct?.id);
      if (product?.name !== data.updateProduct?.name) {
        trackAnalytics(Events.ProductUpdated, { object: Objects.ProductName });
      }

      setInitial({
        products: produce(initialProducts, draft => {
          const productDraft = draft.find(p => p.id === data.updateProduct?.id);
          if (!productDraft || !data.updateProduct) return;
          productDraft.name = data.updateProduct.name;
          productDraft.description = data.updateProduct.description;
        }),
      });
    },
  });

  return {
    updateProduct,
    isUpdatingProduct,
  };
};
