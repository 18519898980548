import { Gradient } from '@cycle-app/ui/icons/Gradient';
import { sidebarTheme } from '@cycle-app/ui/utils/theme.util';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import { ThemeProvider } from 'styled-components';

import { CreateDocFromFileModal } from 'src/app/Main/Board/CreateDocModal/CreateDocFromFileModal';
import NotificationCenter from 'src/app/Main/Board/NotificationCenter/NotificationCenter';
import { SettingsUserAddUserModal } from 'src/app/Main/Settings/SettingsUsers/SettingsUserAddUserModal';
import { AppGlobalDialogs } from 'src/components/AppGlobalDialogs';
import { Ask } from 'src/components/Ask';
import { CommandBarModal } from 'src/components/CommandBar';
import { DropzoneMini } from 'src/components/DropzoneMini';
import LayoutFooter from 'src/components/LayoutFooter/LayoutFooter';
import TemplateModal from 'src/components/TemplateModal/TemplateModal';
import { ViewModals } from 'src/components/ViewModals';
import { useIsMobile } from 'src/reactives/responsive.reactive';

import { SubdomainModal } from '../Settings/SettingsIntegrations/SubdomainModal';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Page } from './MainLayout.styles';
import { MainLayoutListeners } from './MainLayoutListener';

const MainLayout: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const isMobile = useIsMobile();
  return (
    <>
      <MainLayoutListeners />
      <Container role="main" aria-busy="false">
        {isMobile && (
          <NotificationCenter offset={[5, 5]} />
        )}

        {!isMobile && (
          <ThemeProvider theme={sidebarTheme}>
            <AnimatePresence>
              <Sidebar />
            </AnimatePresence>
          </ThemeProvider>
        )}

        <Page>
          {children}
        </Page>
        <CommandBarModal />
        <TemplateModal />
        <SettingsUserAddUserModal />
        <AppGlobalDialogs />
        <LayoutFooter />
        {/** Gradient is used for gradient icons everywhere in the app */}
        <Gradient />
        <DropzoneMini />
        <CreateDocFromFileModal />
        <ViewModals />
        <Ask />
        <SubdomainModal />
      </Container>
    </>
  );
};

export default MainLayout;
