/**
 * Tab UI component
 * @see: For A11Y - http://web-accessibility.carnegiemuseums.org/code/tabs/
 */
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ComponentPropsWithRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
  VFC,
} from 'react';

import { Badge } from '../../Badge';
import { Tooltip } from '../../Tooltip/Tooltip';
import { Container, Content, Wrapper, AddButton, AddIcon } from './Tab.styles';

import type { TabItem } from '../Tabs.types';
import type { Transform } from '@dnd-kit/utilities';

export type TabProps = ComponentPropsWithRef<typeof Container> & {
  count?: TabItem['count'];
  emoji?: TabItem['emoji'];
  end?: TabItem['end'];
  id: TabItem['id'];
  isActive?: boolean;
  isSortable?: boolean;
  label?: TabItem['label'];
  onAdd?: TabItem['onAdd'];
  onClick: TabItem['onClick'];
  tooltip?: TabItem['tooltip'];
};

export const Tab: VFC<TabProps> = ({
  count,
  emoji,
  end,
  id,
  isActive = false,
  isSortable = false,
  label,
  onAdd,
  onClick,
  tooltip,
  ...props
}) => {
  const [showAdd, setShowAdd] = useState(false);

  const content = (isDragging = false) => (
    <Container
      tabIndex={isActive ? -1 : 0}
      $isActive={isActive}
      $isDragging={isDragging}
      aria-selected={isActive}
      id={`tab-${id}`}
      data-id={`tab-${id}`}
      onClick={onClick}
      onMouseEnter={onAdd ? () => setShowAdd(true) : undefined}
      onMouseLeave={onAdd ? () => setShowAdd(false) : undefined}
      role="tab"
      {...props}
    >
      <Content $hasText={!!label}>
        {emoji}
        <TextWrapper label={label} tooltip={tooltip} />
        {typeof count === 'number' && <Badge>{count}</Badge>}
        {end}
        {!!onAdd && (
          <AddButton
            $isAnimated={showAdd}
            onClick={e => {
              e.stopPropagation();
              onAdd();
            }}
          >
            <AddIcon />
          </AddButton>
        )}
      </Content>
    </Container>
  );

  if (isSortable) {
    return (
      <SortableContent id={id}>
        {({ isDragging }) => content(isDragging)}
      </SortableContent>
    );
  }

  return content();
};

interface SortableContentProps {
  id: TabProps['id'];
  children: (data: { isDragging: boolean }) => JSX.Element;
}

export const TAB_ZINDEX = 10;

const SortableContent = ({
  children, id,
}: SortableContentProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    active,
  } = useSortable({ id });

  const controlledTransform: Transform | null = transform ? {
    x: transform.x,
    y: 0, // Force to not move vertically
    scaleX: 1, // Force to not change dimensions
    scaleY: 1, // Force to not change dimensions
  } : null;

  const style = {
    transform: CSS.Transform.toString(controlledTransform),
    transition,
    // Make sure the active tab is above the others
    zIndex: active?.id === id ? TAB_ZINDEX + 1 : TAB_ZINDEX,
  };

  return (
    <div
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      tabIndex={-1}
    >
      {children({ isDragging: !!transform })}
    </div>
  );
};

interface TextWrapperProps {
  label: TabProps['label'];
  tooltip: TabProps['tooltip'];
}

const TextWrapper: VFC<TextWrapperProps> = ({
  label, tooltip,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  useEffect(() => {
    if (
      !!label &&
      (ref.current?.offsetWidth || 0) < (ref.current?.scrollWidth || 0)
    ) {
      setHasOverflow(true);
    }
  }, [label]);

  if (!label) return null;

  const innerContent = <Wrapper ref={ref}>{label}</Wrapper>;
  const hasTooltip = !!tooltip?.content;

  if (hasTooltip || hasOverflow) {
    /**
     * The tooltip logic it the following
     * 1. Display the full label if cropped (hasOverflow)
     * 2. Display a tooltip content if present (then full label as title)
     * 3. Display a tooltip content and title is present (then don't show the full label)
     */
    const {
      content, title, ...tooltipProps
    } = tooltip || {};
    const texts: ReactNode[] = [
      ...(content ? [content] : []),
      ...(title ? [title] : []),
      ...(hasOverflow ? [label] : []),
    ];

    return (
      <Tooltip
        content={texts[0]}
        title={texts[1]}
        placement={tooltip?.placement || 'top'}
        {...tooltipProps}
      >
        {innerContent}
      </Tooltip>
    );
  }

  return innerContent;
};
