import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const FlashIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1649_53294)">
      <path d="M10.1156 5.54062C10.1017 5.48018 10.073 5.42413 10.0321 5.3775C9.99115 5.33087 9.93932 5.29511 9.8812 5.27344L7.1812 4.26094L7.86557 0.825C7.88131 0.74541 7.87114 0.662871 7.83656 0.589482C7.80197 0.516092 7.74479 0.45571 7.67339 0.417187C7.60128 0.378831 7.51851 0.365389 7.43798 0.378953C7.35744 0.392518 7.28364 0.432328 7.22807 0.492187L1.97807 6.11719C1.93506 6.16182 1.90393 6.21654 1.88755 6.27632C1.87117 6.33611 1.87006 6.39905 1.88432 6.45937C1.899 6.51949 1.92797 6.57517 1.96878 6.62168C2.00958 6.6682 2.06101 6.70418 2.1187 6.72656L4.8187 7.73906L4.13432 11.175C4.11859 11.2546 4.12875 11.3371 4.16334 11.4105C4.19793 11.4839 4.25511 11.5443 4.32651 11.5828C4.38033 11.61 4.43967 11.6244 4.49995 11.625C4.55089 11.6252 4.60133 11.6149 4.64811 11.5948C4.69489 11.5746 4.737 11.545 4.77182 11.5078L10.0218 5.88281C10.0648 5.83818 10.096 5.78346 10.1123 5.72368C10.1287 5.66389 10.1298 5.60095 10.1156 5.54062Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_1649_53294">
        <rect width="12" height="12" fill="currentColor" />
      </clipPath>
    </defs>
  </Svg>
);
