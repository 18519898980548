import { CustomerFragment } from '@cycle-app/graphql-codegen';
import { Tooltip } from '@cycle-app/ui';
import { FC } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { copyToClipboard } from 'src/utils/clipboard.utils';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { CustomerCompanyAction } from '../CustomerCompanyAction/CustomerCompanyAction';
import { Logo } from '../CustomerCompanyAction/CustomerCompanyAction.styles';
import { Container, Details, StyledCustomerEmail, CustomerEmailButton } from './CustomerDetails.styles';
import { CustomerLinkedProfiles } from './CustomerLinkedProfiles';

interface Props {
  className?: string;
  customer: CustomerFragment;
  hideShareLink?: boolean;
  isReadOnly?: boolean;
  isFullPage?: boolean;
  onCompanyClick: (companyId: string) => void;
}

export const CustomerDetails: FC<React.PropsWithChildren<Props>> = ({
  onCompanyClick,
  isReadOnly = false,
  customer,
  className,
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  return (
    <Container className={className}>
      <Details>
        {customer.email
          ? (
            <Tooltip content="Copy email" title={customer.email} placement="top" withPortal>
              <CustomerEmailButton
                onClick={() => customer.email && copyToClipboard({
                  text: customer.email,
                  notification: 'Email copied to clipboard',
                })}
              >
                {customer.email}
              </CustomerEmailButton>
            </Tooltip>
          )
          : (
            <StyledCustomerEmail
              customer={customer}
              readOnly={isReadOnly || !!customer.email}
            />
          )}
        <CustomerCompanyAction
          productId={productId}
          customer={customer}
          isReadOnly={isDefaultCustomer(customer)}
          isCompact={false}
          {...customer.company && {
            tooltip: 'Open company',
            onCompanyClick,
          }}
        >
          {customer.company
            ? (
              <>
                <Logo company={customer.company} />
                {customer.company.name}
              </>
            )
            : 'No company'}
        </CustomerCompanyAction>
        {!!customer.profiles?.length && <CustomerLinkedProfiles customer={customer} />}
      </Details>
    </Container>
  );
};
