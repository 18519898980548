import {
  ActionButton,
  Button,
  ButtonCss,
  ButtonContainerProps,
  SelectLine,
  ShyScrollbar,
  Spinner,
  typo,
  truncate,
} from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { DocLinearAutoCreate } from '../DocLinearAutoCreate';

const GAP_SIZE = 8;

export const SearchResults = styled(ShyScrollbar) <{ $hasMarginBottom: boolean }>`
  margin-bottom: ${p => (p.$hasMarginBottom ? `${GAP_SIZE}px` : 0)};
  max-height: 250px;
`;

export const SearchResultsInsights = styled(SearchResults)`
  margin-right: -4px;
  padding-right: 4px;
  max-height: 450px;
`;

export const StyledSelectLine = styled(SelectLine)`
  border-radius: 4px;
  padding: ${GAP_SIZE / 2}px;
  height: auto;
`;

export const ResultLabel = styled.div`
  display: flex;
  align-items: center;
  gap: ${GAP_SIZE}px;
`;

export const ResultTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditBtn = styled(ActionButton)`
  background-color: ${p => p.theme.colors.actionButton.bgHover};
`;

export const NewItemContainer = styled.div<{ $hasFlexNone?: boolean }>`
  display: flex;
  margin-bottom: ${GAP_SIZE}px;
  gap: ${GAP_SIZE}px;
  flex-wrap: wrap;

  > div[data-popover="true"] {
    overflow: hidden;
    min-width: 25%;

    ${p => p.$hasFlexNone && css`
      &:nth-child(4) {
        flex: none;
        flex-grow: 0;
        min-width: initial;
      }
    `}
  }
`;

interface NewItemButtonProps extends ButtonContainerProps {
  $isGray?: boolean;
  $hoverDisabled: boolean;
  $isSelected: boolean;
}

export const NewItemButton = styled.div<NewItemButtonProps>`
  ${ButtonCss}
  height: 24px;
  background-color: ${p => (p.$isSelected
    ? p.theme.colors.background.hover
    : p.theme.colors.background.tertiary)};
  /* TODO: reactivate when we iterate on a11n outline focus */
  /* outline: ${p => (p.$isSelected ? '2px solid hsl(var(--cycle))' : 'none')}; */
  color: ${p => (p.$isGray ? p.theme.colors.text.secondary : p.theme.colors.text.primary)};
  padding: 3px 8px;
  width: 100%;

  &:hover, &:active, &:focus {
    background-color: ${p => p.theme.colors.background.hover};
  }
`;

export const NewItemButtonLabel = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
`;

export const StyledDocLinearAutoCreate = styled(DocLinearAutoCreate)`
  margin-right: -7px;
`;

export const ButtonContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${GAP_SIZE}px;
`;

export const ResultSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
`;

export const OtherDocTypesSpinner = styled(Spinner)`
  height: 20px;
`;

export const DocTypesContent = styled.div`
  min-width: 160px;
  padding: 8px 0;
`;

export const SelectLinePreSlotWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${GAP_SIZE}px;
`;

export const SectionTitle = styled.div`
  ${typo.caption500}
  color: ${p => p.theme.colors.text.disabled};
  display: flex;
  align-items: center;
  gap: 10px;
  height: 26px;
  user-select: text;
`;

export const TitleSpinner = styled(Spinner)`
  width: 12px;
  height: 12px;
`;

export const NotFound = styled.div`
  color: ${p => p.theme.colors.text.disabled};
  ${typo.caption400}
  height: 32px;
  display: flex;
  align-items: center;
  user-select: text;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Content = styled.div`
  color: ${p => p.theme.colors.text.secondary};
`;

export const InsightButton = styled(Button).attrs({
  variant: 'nospace',
  size: 'M',
})`
  margin-bottom: 8px;
  ${typo.body400}
  > span { overflow: hidden }
`;

export const InsightQuote = styled.span`
  background: hsla(var(--cycle400), 0.5);
  border-radius: 4px;
  padding: 0 3px;
  color: ${p => p.theme.colors.text.primary};
  ${truncate}
`;

export const CreateSelectLine = styled(SelectLine)`
  margin-bottom: ${GAP_SIZE}px;
`;

export const CreateContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${p => p.theme.colors.text.secondary};
`;

export const DocTitle = styled.div`
  color: ${p => p.theme.colors.text.primary};
  background: ${p => p.theme.colors.background.tertiary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 6px;
  height: 24px;
  overflow: hidden;
  svg {
    flex: none;
  }
  span {
    ${truncate}
  }
`;

export const SuggestDocTitle = styled(DocTitle)`
  --bg-base: ${p => (p.theme.isDark ? 'rgba(49, 49, 49, 0.8)' : 'rgba(255, 255, 255, 0.8)')};
  background:
    linear-gradient(0deg, var(--bg-base), var(--bg-base)),
    linear-gradient(90.4deg, #AE8FFF 0.38%, #00D2F3 63.15%, #4DED8D 120.18%);
`;
