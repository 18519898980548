import { typo, Button, Avatar } from '@cycle-app/ui';
import styled from 'styled-components';

import { LinearSkeletons } from '../DocLinear/LinearSkeletons';

export const InfosList = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const InfosTitle = styled.div`
  ${typo.body500}
  display: flex;
  margin-bottom: 4px;
`;

export const InfosDescription = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  margin: 0;
  padding: 0;
`;

export const StyledSelectButton = styled(Button).attrs({
  variant: 'light',
  size: 'S',
})`
  ${typo.body400};
  --bg: transparent;
  --color: ${p => p.theme.colors.text.primary};
  height: 24px;
  gap: 4px;
  justify-content: space-between;
  padding: 0px 4px;
  width: 100%;
  white-space: nowrap;
`;

export const StyledAssigneeSelectButton = styled(Button)`
  ${typo.body400};
  height: 24px;
`;

export const StyledAvatar = styled(Avatar)`
  --border-size: 0px;
  --shadow-size: 0px;
  --padding: 0px;
  --shadow-size-hover: 0px;
`;

export const ProjectHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

export const ProjectSkeleton = styled(LinearSkeletons)`
  width: 100%;
`;
