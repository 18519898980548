import { CompanyLogo } from '@cycle-app/ui';
import { toShortLocaleDateString } from '@cycle-app/utilities';

import { navigateToDocFullPage } from 'src/hooks';
import { DOC_PREVIEW_DELAY, useDocPreview } from 'src/hooks/doc/useDocPreview';
import { useEditorTextContent } from 'src/hooks/useEditorTextContent';
import { closeAskPanel } from 'src/reactives/ask.reactive';

import {
  DocCard, DocFooter, DocCustomer, DocCompany, DocDate, LineClamp,
} from './Ask.styles';
import { Doc } from './Ask.types';

export const AnswerDoc = ({ doc }: { doc: Doc }) => {
  const content = useEditorTextContent(doc.id);
  const {
    showDocPreview, hideDocPreview,
  } = useDocPreview();
  return (
    <DocCard
      key={doc.id}
      onMouseEnter={() => {
        showDocPreview(doc.id, DOC_PREVIEW_DELAY);
      }}
      onMouseLeave={() => {
        hideDocPreview();
      }}
      onClick={event => {
        if (!event.metaKey) closeAskPanel();
        navigateToDocFullPage(doc, undefined, event.metaKey);
      }}
    >
      <LineClamp>
        {content}
      </LineClamp>

      <DocFooter>
        <DocCustomer>
          {doc.customer?.company && (
            <CompanyLogo
              size="XS"
              company={doc.customer?.company}
            />
          )}
          <span>{doc.customer?.displayName?.trim() || doc.customer?.name?.trim() || doc.customer?.email?.trim()}</span>
          {doc.customer?.company?.name && (
            <DocCompany>
              <span>·</span>
              {doc.customer?.company?.name}
            </DocCompany>
          )}
        </DocCustomer>

        <DocDate>
          {toShortLocaleDateString(doc.createdAt)}
        </DocDate>
      </DocFooter>
    </DocCard>
  );
};
