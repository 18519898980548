import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const AiTextIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="ai text, text generation">
      <g id="Icon">
        <path d="M2.66667 3.33334C2.29848 3.33334 2 3.63182 2 4.00001C2 4.3682 2.29848 4.66668 2.66667 4.66668H13.3333C13.7015 4.66668 14 4.3682 14 4.00001C14 3.63182 13.7015 3.33334 13.3333 3.33334H2.66667Z" />
        <path d="M11.9461 6.40406C11.841 6.15894 11.6 6.00001 11.3333 6.00001C11.0666 6.00001 10.8256 6.15894 10.7206 6.40406L9.82562 8.49229L7.73739 9.38725C7.49226 9.4923 7.33333 9.73333 7.33333 10C7.33333 10.2667 7.49226 10.5077 7.73739 10.6128L9.82562 11.5077L10.7206 13.596C10.8256 13.8411 11.0666 14 11.3333 14C11.6 14 11.841 13.8411 11.9461 13.596L12.8411 11.5077L14.9293 10.6128C15.1744 10.5077 15.3333 10.2667 15.3333 10C15.3333 9.73333 15.1744 9.4923 14.9293 9.38725L12.8411 8.49229L11.9461 6.40406Z" />
        <path d="M2.66667 7.33334C2.29848 7.33334 2 7.63182 2 8.00001C2 8.3682 2.29848 8.66668 2.66667 8.66668H6C6.36819 8.66668 6.66667 8.3682 6.66667 8.00001C6.66667 7.63182 6.36819 7.33334 6 7.33334H2.66667Z" />
        <path d="M2.66667 11.3333C2.29848 11.3333 2 11.6318 2 12C2 12.3682 2.29848 12.6667 2.66667 12.6667H4.66667C5.03486 12.6667 5.33333 12.3682 5.33333 12C5.33333 11.6318 5.03486 11.3333 4.66667 11.3333H2.66667Z" />
      </g>
    </g>
  </Svg>
);
