import { ReactNode } from 'react';

export enum TourStatus {
  OFF = 'off',
  STEP = 'step',
  TRANSITION = 'transition',
}

export enum TourName {
  SLACK = 'slack-tour',
}

export enum TourStep {
  // Slack
  SlackUserSync,
  SlackInvite,
  CreateFeedbackFromSlack,
  CreateFeedbackFromSlackCommand,
  SlackNotifications,
  CloseLoopInSlack,
}

export type Tour = {
  name: TourName;
  step: TourStep;
  steps: TourStep[];
};

export type ProductToursState = {
  active?: {
    /**
     * If a product tour is enable, the index correspond to the active one
     * else is undefined
     */
    name: TourName;
    /**
     * Will be the element to use for dimension to highlight
     */
    el?: HTMLElement;
  };
  /**
   * Will contain all product tours then keep track of steps for each of them
   */
  tours: Tour[];
  status: TourStatus;
  /**
   * Usefull to know which tour we just ended.
   * Ex. of usage: highlight a "know more" button of that feature.
   */
  endedTour?: TourName;
};

export type TourStepAction = 'back' | 'next' | 'skip';

type Top = number;
type Right = number;
type Bottom = number;
type Left = number;

export type StepDetails = {
  actions?: TourStepAction[];
  chapter?: `${number}/${number}`; // current chapter / total chapter
  /**
   * The gap will be the pixels space around the highlighted area in the app
   * Gap is optional since is use for now only for the "First Tour" tour.
   */
  frameGap?: number | [Top, Right, Bottom, Left];
  /**
   * Optional since it's use only with frame option
   */
  tooltipPosition?: 'top' | 'bottom-start' | 'top-right-corner';
  isComingSoon?: string;
} & ({
  text?: string | ((name?: string) => string);
  title?: string;
  images?: string[];
  component?: never;
} | {
  text?: never;
  title?: never;
  images?: never;
  component: ReactNode;
});

export enum ProductTourLock {
  REDIRECT_INBOX = 'redirect-inbox',
  SHOW_EMPTY_INBOX = 'show-empty-inbox',
}
