import { SelectOption, ActionButton } from '@cycle-app/ui';
import { AddIcon, UnlinkIcon } from '@cycle-app/ui/icons';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import DoctypesOptionsPreview from 'src/components/DoctypesOptionsPreview/DoctypesOptionsPreview';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { PageId } from 'src/constants/routing.constant';
import useAttributesMutations from 'src/hooks/api/mutations/useAttributesMutations';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { Layer } from 'src/types/layers.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';
import { isFeedback, isInsight } from 'src/utils/docType.util';
import { getOptionFromDoctype } from 'src/utils/selectOptions.util';

import {
  OptionsCard, TagDoctypeStyled, AttributeTagIconContainer, TagStyled,
  DocTypes, UnlinkAction, SelectLine, DocTypeTag,
} from './SettingsAttributes.styles';

interface Props {
  attribute: AttributeDefinitionsNode;
  doctypesOptions: SelectOption[];
}

const DoctypesRelationsManager: FC<React.PropsWithChildren<Props>> = ({
  attribute,
  doctypesOptions,
}) => {
  const { navigate } = useNavigateToSettings();

  const {
    addAttributeDoctype, removeAttributeDoctype,
  } = useAttributesMutations();

  const options = useMemo(() => attribute.doctypes.edges.map(({ node }) => getOptionFromDoctype(node, 14)), [attribute.doctypes.edges]);

  const [isMainDropdownVisible, {
    setFalseCallback: hideMainDropdown,
    toggleCallback: toggleMainDropdown,
  }] = useOptimizedBooleanState(false);
  const [isAddDoctypeDropdownVisible, {
    setTrueCallback: showAddDoctypeDropdown,
    setFalseCallback: hideAddDoctypeDropdown,
  }] = useOptimizedBooleanState(false);
  const [doctypeIdToUnlink, setDoctypeIdToUnlink] = useState<string | null>(null);

  useEffect(() => {
    if (isMainDropdownVisible && options.length === 0) {
      showAddDoctypeDropdown();
    }
  // We don't want to show the dropdown if options.length goes from 1 to 0, only on opening the main dropdown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMainDropdownVisible, showAddDoctypeDropdown]);

  const unrelatedDocTypes = useMemo(
    () => doctypesOptions.filter(doctypeOption => !options.find(o => o.value === doctypeOption.value)),
    [doctypesOptions, options],
  );

  const resetDoctypeIdToUnlink = useCallback(() => setDoctypeIdToUnlink(null), []);
  const handleRemoveAttributeDoctype = useCallback(async () => {
    if (!doctypeIdToUnlink) return;
    await removeAttributeDoctype(attribute, doctypeIdToUnlink);
  }, [attribute, doctypeIdToUnlink, removeAttributeDoctype]);

  const doctype = useMemo(() => options.find(option => option.value === doctypeIdToUnlink), [options, doctypeIdToUnlink]);

  const feedback = attribute.doctypes.edges.find((e) => isFeedback(e.node));
  const insight = attribute.doctypes.edges.find((e) => isInsight(e.node));
  const filteredOptions = options
    .filter(option => option.value !== feedback?.node.id && option.value !== insight?.node.id);

  return (
    <DocTypes style={{ gap: 8 }}>
      {feedback && (
        <DocTypeTag
          role="button"
          tabIndex={0}
          onClick={() => navigate(PageId.SettingsFeedback)}
        >
          <DocTypeIcon
            doctype={{
              emoji: feedback.node.emoji,
              type: feedback.node.type,
            }}
            size={14}
          />
          Feedback
          <UnlinkAction
            tooltip="Unlink"
            tooltipPlacement="top"
            onClick={e => {
              e.stopPropagation();
              setDoctypeIdToUnlink(feedback.node.id);
            }}
            variant="warning"
          >
            <UnlinkIcon size={12} />
          </UnlinkAction>
        </DocTypeTag>
      )}

      {insight && (
        <DocTypeTag
          role="button"
          tabIndex={0}
          onClick={() => navigate(PageId.SettingsInsights)}
        >
          <DocTypeIcon
            doctype={{
              emoji: insight.node.emoji,
              type: insight.node.type,
            }}
            size={14}
          />
          Insight
          <UnlinkAction
            tooltip="Unlink"
            tooltipPlacement="top"
            onClick={e => {
              e.stopPropagation();
              setDoctypeIdToUnlink(insight.node.id);
            }}
            variant="warning"
          >
            <UnlinkIcon size={12} />
          </UnlinkAction>
        </DocTypeTag>
      )}

      <DropdownLayer
        visible={isMainDropdownVisible}
        hide={hideMainDropdown}
        placement="left"
        content={(
          <OptionsCard disableHover>
            {filteredOptions
              .map(option => (
                <SelectLine
                  key={option.value}
                  label={option.label}
                  startSlot={option.icon}
                  endSlot={(
                    <ActionButton
                      tooltip="Unlink doc type"
                      tooltipPlacement="top"
                      onClick={() => setDoctypeIdToUnlink(option.value)}
                      variant="warning"
                    >
                      <UnlinkIcon />
                    </ActionButton>
                )}
                />
              ))}
            {renderAddDoctypeInternalDropdown()}
          </OptionsCard>
        )}
      >
        <DocTypeTag
          role="button"
          tabIndex={0}
          onClick={toggleMainDropdown}
        >
          {filteredOptions.length > 0
            ? <DoctypesOptionsPreview attribute={attribute} />
            : 'Link doc types'}
        </DocTypeTag>
      </DropdownLayer>

      {doctypeIdToUnlink && doctype && (
        <DialogModal
          title="Unlink doc type"
          info={(
            <>
              {'Are you sure you want to unlink '}
              <TagDoctypeStyled
                limitSize={false}
                icon={doctype.icon}
              >
                {doctype.label}
              </TagDoctypeStyled>
              {' from '}
              <TagStyled
                inline
                limitSize={false}
                icon={<AttributeTagIconContainer>{getCustomAttributeTypeData(attribute.__typename).icon}</AttributeTagIconContainer>}
              >
                {attribute.name}
              </TagStyled>
              ? Some docs might lose their value for this property.
            </>
          )}
          confirmLabel="Unlink"
          useHighMaskLayer
          hide={resetDoctypeIdToUnlink}
          onConfirm={handleRemoveAttributeDoctype}
        />
      )}
    </DocTypes>
  );

  function renderAddDoctypeInternalDropdown() {
    return (
      <DropdownLayer
        visible={isAddDoctypeDropdownVisible}
        hide={hideAddDoctypeDropdown}
        placement="right"
        layer={Layer.DropdownZ2}
        content={(
          <OptionsCard disableHover>
            {unrelatedDocTypes.map(option => (
              <SelectLine
                key={option.value}
                label={option.label}
                onClick={onLinkDoctype(option.value)}
                startSlot={option.icon}
              />
            ))}
          </OptionsCard>
        )}
      >
        {unrelatedDocTypes.length > 0 && (
          <SelectLine
            startSlot={(
              <AddIcon
                style={{
                  width: 22,
                  height: 12,
                }}
              />
)}
            label="Link new"
            onClick={showAddDoctypeDropdown}
          />
        )}
      </DropdownLayer>
    );
  }

  function onLinkDoctype(doctypeId: string) {
    return async () => {
      if (unrelatedDocTypes.length <= 1) {
        hideAddDoctypeDropdown();
      }
      await addAttributeDoctype(attribute, doctypeId);
    };
  }
};

export default DoctypesRelationsManager;
