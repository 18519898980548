import { typo } from '@cycle-app/ui';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  position: absolute;
  bottom: 24px;
  left: 50%;
  z-index: inherit;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 56px;
  border-radius: 29px;
  background: ${p => p.theme.colors.docPanel.bg};
  border: 1px solid ${p => p.theme.colors.border.primary};
  padding: 0 12px 0 10px;
  box-shadow: 0px 6px 22px 0px #0000001A;
`;

export const Title = styled.span`
  ${typo.body500}
`;

export const Details = styled.span`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: -3px;
`;

export const ProgressContainer = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
