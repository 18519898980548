import { typo, transitionCss, ActionButton, card } from '@cycle-app/ui';
import { tablet } from '@cycle-app/utilities';
import { Tabs, TabList, Tab, TabPanels } from '@reach/tabs';
import styled from 'styled-components';

import { Header } from '../Settings.styles';

const PAGE_PADDING = 32;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  padding-bottom: 30vh;
`;

export const SectionTitle = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${p => p.theme.colors.text.primary};
  ${typo.headerLight}
  > div {
    display: flex;
    align-items: center;
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const HeaderStyled = styled(Header)`
  padding-right: 0;
  margin-bottom: 0;
  width: 100%;
`;

export const Description = styled.div`
  margin-top: 8px;
  ${typo.body400}
`;

export const Title = styled.h2`
  ${typo.headerLarge600}
`;

export const DoctypeTemplates = styled.div`
  ${card}
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

export const ContentTemplateEmptyWithFrame = styled.div`
  ${card}
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  text-align: center;
  padding-bottom: 48px;
`;

export const ContentTemplateEmpty = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  padding: 65px 16px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContentTemplateActions = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: center;
  gap: 8px;
`;

export const ContentTemplateIllustration = styled.img`
  height: 135px;
  margin-bottom: 8px;
`;

export const ContentTemplate = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;

  border-radius: 6px;
  background-color: ${p => p.theme.colors.background.tertiary};
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const HierarchyContainer = styled.div`
  grid-area: hierarchy;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 780px;

  ${tablet} {
    display: flex;
    flex-direction: column;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  ${typo.body400}
  color: ${p => p.theme.colors.text.disabled};

  display: inline-flex;
  align-items: center;
  gap: 8px;

  strong {
    color: ${p => p.theme.colors.text.secondary};
    font-weight: 400;
  }
`;

const StyledTabs = styled(Tabs)`
  grid-area: tabs;
  height: 100%;
  display: flex;
  flex-direction: column;

  [data-reach-tab-panel] {
    height: 100%;
  }
`;

const StyledTabList = styled(TabList)<{ $padding?: number }>`
  padding: 8px ${p => p.$padding ?? PAGE_PADDING}px;
  border-bottom: 1px solid ${p => p.theme.colors.home.separator};
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 10px;
  margin: 0 -${p => p.$padding ?? PAGE_PADDING}px;
  width: calc(100% + ${p => (p.$padding ?? PAGE_PADDING) * 2}px);
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${p => p.theme.colors.background.primary};
`;

const StyledTab = styled(Tab)`
  position: relative;
  min-width: 82px;
  cursor: pointer;
  ${typo.body500}
  color: ${p => p.theme.colors.text.secondary};

  :after {
    content: "";
    height: 2px;
    width: 100%;
    background: hsl(var(--cycle));
    position: absolute;
    left: 0;
    bottom: -11px;
    opacity: 0;
    transform: scale(0.75);
    ${transitionCss(['transform', 'opacity'])}
  }

  &[data-selected] {
    color: ${p => p.theme.colors.text.primary};
    :after {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const StyledTabPanels = styled(TabPanels)`
  padding: 24px 0;
  flex: 1;
`;

export {
  StyledTabs as Tabs,
  StyledTabList as TabList,
  StyledTab as Tab,
  StyledTabPanels as TabPanels,
};
export const Action = styled(ActionButton)`
  --colorHover: ${p => p.theme.colors.text.secondary};
`;

export const Separator = styled.div`
  height: 28px;
  width: 1px;
  background-color: ${p => p.theme.colors.background.tertiary};
  margin: 0 8px;
`;

export const DoctypePropertiesContainer = styled.div`
  ${card}
`;
