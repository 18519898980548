import { CopyIcon } from '@cycle-app/ui/icons';

import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { Title, Copy } from './ChangelogBuilderContentNoteTitle.styles';

export const ChangelogBuilderContentNoteTitle = () => {
  const { watch } = useChangelogBuilderFormContext();
  const contentSection = watch('contentSection');
  return (
    <Title>
      <h3>New sick feature</h3>
      <Copy $contentSection={contentSection}>
        <CopyIcon />
      </Copy>
    </Title>
  );
};
