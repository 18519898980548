import styled from 'styled-components';

import DocHierarchyEl from 'src/components/DocHierarchy/DocHierarchy';
import EditorSkeleton from 'src/components/EditorSkeleton/EditorSkeleton';
import { EDITOR_MARGIN, EDITOR_WIDTH_DESKTOP } from 'src/constants/editor.constants';

export const DocHierarchy = styled(DocHierarchyEl)`
  overflow: visible;
  margin: 0 auto;
  width: 100%;
  max-width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  margin-bottom: 200px;

  padding-left: ${EDITOR_MARGIN}px;
  padding-right: ${EDITOR_MARGIN}px;
`;

export const StyledEditorSkeleton = styled(EditorSkeleton)`
  margin: 0 auto;
  width: 100%;
  max-width: ${EDITOR_WIDTH_DESKTOP + EDITOR_MARGIN * 2}px;
  padding: 16px ${EDITOR_MARGIN}px;
`;
