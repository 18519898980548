import { DocThreadsDocument, ResolveThreadDocument, ResolveThreadMutationVariables } from '@cycle-app/graphql-codegen';
import { useCallback, useRef } from 'react';

import { Events, Sources } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import client from 'src/services/apollo/client';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getPageIdFromPathname, isDocPage } from 'src/utils/routing.utils';
import { parseStoreFieldName } from 'src/utils/update-cache/cache.utils';

/**
 * This hook is used to resolve a thread of comments from a doc.
 * Side effects:
 * - Update comments count and comments list in cache
 * - Send ResolveThread event to analytics
 */
export const useResolveThread = () => {
  const pathname = useRef(window.location.pathname);

  const [mutate, { loading }] = useSafeMutation(ResolveThreadDocument, {
    onCompleted: () => trackResolveThread(pathname.current),
  });

  const resolveThread = useCallback((
    docId: string, blockId: string | null,
    variables: ResolveThreadMutationVariables,
  ) => mutate({
    variables,
    refetchQueries: [{
      query: DocThreadsDocument,
      variables: {
        id: docId,
        resolved: false,
      },
    }, {
      query: DocThreadsDocument,
      variables: {
        id: docId,
        resolved: true,
      },
    }],
    update: (_, { data }) => {
      const comment = data?.resolveThread;
      if (!comment) return;
      client.cache.modify({
        id: client.cache.identify(comment.doc),
        fields: {
          threads: (threads, { storeFieldName }) => {
            // We only want to update the cache for the thread with the given blockId
            const storeVariables = parseStoreFieldName(storeFieldName);
            // eslint-disable-next-line eqeqeq -- for the main thread, undefined != null must be false
            if (storeVariables.blockId != blockId) return threads;

            return ({
              edges: [{
                node: {
                  commentsCount: 0,
                  comments: {
                    __typename: 'CommentsConnection',
                    edges: [],
                  },
                },
              }],
            });
          },
        },
      });
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return {
    resolveThread,
    isResolvingThread: loading,
  };
};

const trackResolveThread = (pathname: string) => {
  const pageId = getPageIdFromPathname(pathname);
  const source = isDocPage(pageId) ? Sources.DocPanel : Sources.Board;
  trackAnalytics(Events.ThreadResolved, { source });
};
