import { InfoIconOutline } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import {
  Container, Top, Bottom, TitleContainer, Title, SubTitle, Action,
} from './SettingsViewHeader.styles';

type SettingsViewHeaderProps = {
  actionsSlot?: ReactNode;
  description?: string;
  title: string | ReactNode;
  helpUrl?: string;
  helpAction?: VoidFunction;
  fillDescriptionSpace?: boolean;
};

export const SettingsViewHeader: FC<React.PropsWithChildren<SettingsViewHeaderProps>> = ({
  actionsSlot,
  description,
  title,
  helpUrl,
  helpAction,
  fillDescriptionSpace = false,
}) => {
  return (
    <Container>
      <Top>
        <TitleContainer>
          {typeof title === 'string' ? <Title>{title}</Title> : title}

          {(helpUrl || helpAction) && (
            <Action
              tooltip="Learn more"
              tooltipPlacement="bottom"
              onClick={() => {
                if (helpAction) {
                  helpAction();
                } else {
                  window.open(helpUrl, '_blank');
                }
              }}
            >
              <InfoIconOutline size={14} />
            </Action>
          )}
        </TitleContainer>
        {actionsSlot}
      </Top>

      {(!!description || fillDescriptionSpace) && (
        <Bottom $hasMinHeight={fillDescriptionSpace}>
          <SubTitle>{description}</SubTitle>
        </Bottom>
      )}
    </Container>
  );
};
