import { useQuery } from '@apollo/client';
import { WebhooksDocument } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';

import { useProduct } from 'src/hooks/api/useProduct';
import { extract } from 'src/types/graphql.types';

const useWebhooksQuery = () => {
  const { product } = useProduct();
  return useQuery(WebhooksDocument, {
    skip: !product?.id,
    variables: {
      productId: product?.id as string,
    },
  });
};

export const useWebhooks = () => {
  const query = useWebhooksQuery();
  return {
    ...query,
    webhooks: nodeToArray(extract('Product', query.data?.node)?.webhooks),
  };
};

export const useWebhook = (webhookId: string) => {
  const query = useWebhooksQuery();
  return {
    ...query,
    webhook: extract('Product', query.data?.node)?.webhooks.edges.find(edge => edge.node.id === webhookId)?.node,
  };
};
