import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background: ${p => p.theme.colors.flow.bg};
  border-radius: 8px;
  width: 100%;
  height: 100%;

  .react-flow__node {
    cursor: default;
  }

  .react-flow__handle {
    width: 10px;
    height: 10px;
    background-color: white !important;
    border-color: hsl(var(--cycle)) !important;
    border-width: 2px;
  }
`;
