import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const BellIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M10.6666 11.3333C10.6666 12.8061 9.47272 14 7.99996 14C6.5272 14 5.33329 12.8061 5.33329 11.3333M12.1997 5.99501L12.3193 8.38722C12.3285 8.57151 12.3759 8.75188 12.4584 8.91692L13.2764 10.5528C13.3138 10.6277 13.3333 10.7103 13.3333 10.794C13.3333 11.0919 13.0918 11.3333 12.7939 11.3333H3.20597C2.9081 11.3333 2.66663 11.0919 2.66663 10.794C2.66663 10.7103 2.68612 10.6277 2.72357 10.5528L3.5415 8.91692C3.62402 8.75188 3.67138 8.57151 3.6806 8.38722L3.80021 5.99501C3.91211 3.75707 5.75923 2 7.99996 2C10.2407 2 12.0878 3.75707 12.1997 5.99501Z" stroke="currentColor" strokeWidth="1.33333" strokeLinejoin="round" />
  </Svg>
);
