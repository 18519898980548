import { Reference } from '@apollo/client';
import { RemoveOAuthClientDocument } from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useRemoveOAuthClient = (clientId: string) => {
  const { product } = useProduct();
  const [deleteClient] = useSafeMutation(RemoveOAuthClientDocument);
  return () => deleteClient({
    variables: { id: clientId },
    optimisticResponse: { removeOAuthClient: { id: clientId } },
    update: (cache, { data }) => {
      if (!product || !data?.removeOAuthClient) return;
      cache.modify({
        id: cache.identify(product),
        fields: {
          oauthClients: (refs, { readField }) => ({
            ...refs,
            edges: refs.edges.filter(
              (edge: { node: Reference }) => readField('id', edge.node) !== data.removeOAuthClient?.id,
            ),
          }),
        },
      });
    },
  });
};
