import { getImageExtension as getExtension, ImageSize } from '@cycle-app/editor-extensions';
import { Editor } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { EditorImageView } from 'src/components/EditorImageView';

type GetImageExtensionParams = {
  onPastedFile?: (editor: Editor, file: File) => Promise<void>;
};

export const getImageExtension = (params?: GetImageExtensionParams) => getExtension().extend({
  addOptions: () => ({
    sizes: Object.values(ImageSize),
  }),

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('handlePastedImage'),
        props: {
          handlePaste: (_, event) => {
            const clipboardFile: File | undefined = Array.from(event.clipboardData?.files ?? [])[0];
            if (!clipboardFile) {
              return false;
            }
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            params?.onPastedFile?.(this.editor, clipboardFile);
            return true;
          },
        },
      }),
    ];
  },

  addNodeView: () => ReactNodeViewRenderer(EditorImageView),
});
