import { LinearProjectIcon, TagProps, Spinner } from '@cycle-app/ui';
import { ChevronDoubleRight } from '@cycle-app/ui/icons';

import { useDocLinearContext } from 'src/contexts/docLinearContext';
import { useDocSyncAutomationContent } from 'src/hooks/doc/useDocSyncAutomationContent';
import { useUpdateDocAutomation } from 'src/hooks/doc/useUpdateDocAutomation';
import { linearLabel } from 'src/utils/linear.utils';

import { useLinearProject } from '../../hooks/api/queries/integrations/useLinearProject';
import { StyledTag, IssueIcon } from './DocLinear.styles';
import { LinearSelect } from './LinearSelect';

export const LinearTag = ({
  color = 'grey', ...props
}: TagProps) => {
  const docId = useDocLinearContext(ctx => ctx.docId);
  const id = useDocLinearContext(ctx => ctx.automationId);
  const url = useDocLinearContext(ctx => ctx.automationUrl);
  const type = useDocLinearContext(ctx => ctx.automationType);
  const integrationId = useDocLinearContext(ctx => ctx.integrationId);
  const { project } = useLinearProject(type === 'project' ? id : null, integrationId);

  const showSyncAutomationContent = useDocLinearContext(ctx => ctx.showSyncAutomationContent);
  const {
    syncAutomationContent, isLoading: isSyncAutomationContentLoading,
  } = useDocSyncAutomationContent();
  const { updateDocAutomation } = useUpdateDocAutomation();
  return (
    <LinearSelect
      docId={docId}
      type={type}
      onSelect={data => {
        if (!docId) return null;
        return updateDocAutomation(docId, data.url, data.id);
      }}
      onCreate={data => {
        if (!docId || !data.url) return null;
        return updateDocAutomation(docId, data.url, data.id);
      }}
      onUnlink={() => {
        if (!docId) return null;
        return updateDocAutomation(docId, null, null);
      }}
      button={buttonProps => (
        <StyledTag
          color={color}
          icon={id && type === 'project'
            ? (
              <LinearProjectIcon
                icon={project?.icon}
                color={project?.color}
                size={12}
              />
            )
            : (
              <IssueIcon
                $isInactive={type === null}
                size={12}
              />
            )}
          onClickLink={url ? async () => {
            if (showSyncAutomationContent) {
              await syncAutomationContent();
            } else {
              window.open(url, '_blank');
            }
          } : undefined}
          linkTooltip={showSyncAutomationContent ? 'Send content to Linear' : 'Open in Linear'}
          onClick={buttonProps.onClick}
          data-active={buttonProps['data-active']}
          {...showSyncAutomationContent && {
            linkIcon: isSyncAutomationContentLoading ? <Spinner height={12} width={12} /> : <ChevronDoubleRight />,
          }}
          {...props}
        >
          {linearLabel(type, url)}
        </StyledTag>
      )}
    />
  );
};
