import { MateFragment } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { useMe } from 'src/hooks/api/useMe';

import RealtimeMore from './RealtimeMore';
import RealtimeUser from './RealtimeUser';
import { Container, User, StyledAvatar, UserTooltip } from './RealtimeUsers.styles';

interface Props {
  className?: string;
  users?: MateFragment[];
  showLocation?: boolean;
}

const PREVIEW_USERS_LENGTH = 5;

const RealtimeUsers: FC<React.PropsWithChildren<Props>> = ({
  className,
  users = [],
  showLocation = false,
}) => {
  const { me } = useMe();
  const more = users.length - PREVIEW_USERS_LENGTH;

  return (
    <Container className={className}>
      <User>
        <UserTooltip
          content={`${me.firstName} (me)`}
          placement="bottom"
          withPortal
        >
          <StyledAvatar user={me} />
        </UserTooltip>
      </User>

      {users
        .slice(0, PREVIEW_USERS_LENGTH)
        .filter(user => user.id !== me.id)
        .map(user => (
          <RealtimeUser
            key={user.id}
            user={user}
            showLocation={showLocation}
          />
        ))}
      {more > 0 && (
        <RealtimeMore
          users={users.slice(PREVIEW_USERS_LENGTH)}
          more={more}
        />
      )}
    </Container>
  );
};

export default RealtimeUsers;
