import { typo } from '@cycle-app/ui';
import { YCIcon } from '@cycle-app/ui/icons';
import { small } from '@cycle-app/utilities';
import styled from 'styled-components';

import { FeaturesList } from '../FeaturesList';

export const Container = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 34px;
  justify-content: space-between;
`;

export const Title = styled.h2`
  ${typo.headerLarge600}
`;

export const Description = styled.p`
  color: ${p => p.theme.colors.text.secondary};
  max-width: 447px;
`;

export const SubTitle = styled.h2`
  ${typo.headerLarge600}
  margin-top: 8px;
`;

export const StyledFeatures = styled(FeaturesList)`
  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const ContentBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${small} {
    display: none;
  }
`;

export const RightBox = styled.div`
  border-radius: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
  border: 1px solid ${p => (p.theme.isDark ? p.theme.baseColors.Grey850 : p.theme.baseColors.Grey200)};
  flex-grow: 1;
  max-width: 408px;

  ${small} {
    max-width: 100%;
  }
`;

export const RightBoxHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  & > :first-child {
    border-right: 1px solid ${p => (p.theme.isDark ? p.theme.baseColors.Grey850 : p.theme.baseColors.Grey200)};
  }
`;

export const RightBoxBody = styled.div`
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Logo = styled(YCIcon)`
  height: 40px;
  width: 40px;
  display: block;
  margin: 0 auto 8px;
`;
