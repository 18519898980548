import { ApolloQueryResult } from '@apollo/client';
import { ChangelogCheckDomainQuery } from '@cycle-app/graphql-codegen';
import { Spinner, Tooltip } from '@cycle-app/ui';
import { useState } from 'react';

import { ChangelogStatusRoot } from './SettingsReleases.styles';

type Props = {
  isValid: boolean;
  refetch: () => Promise<ApolloQueryResult<ChangelogCheckDomainQuery>>;
};

export const ChangelogStatus = ({
  isValid, refetch,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Tooltip
      content="Verify the configuration"
      placement="top"
    >
      <ChangelogStatusRoot
        type="button"
        onClick={async () => {
          try {
            setIsLoading(true);
            await refetch();
          } finally {
            setIsLoading(false);
          }
        }}
        $isValid={isValid}
      >
        {isValid ? 'Valid configuration' : 'Invalid configuration'}
        {isLoading && <Spinner />}
      </ChangelogStatusRoot>
    </Tooltip>
  );
};
