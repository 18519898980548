import { Input } from '@cycle-app/ui';
import { emailRegex, ERROR_CODE } from '@cycle-app/utilities';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useVerifyEmail } from 'src/hooks';
import { setOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

import { BackButton, Footer } from '../OnboardingLayout/OnboardingLayout.styles';
import { Form, StyledButton } from './OnboardingStepAccount.styles';

interface FormData {
  email: string;
  terms: boolean;
}

interface VerifyEmailFormProps {
  onSuccess: (email: string) => void;
  defaultValue: string;
}

export const VerifyEmailForm = ({
  defaultValue, onSuccess,
}: VerifyEmailFormProps) => {
  const {
    handleSubmit, register, formState, setError,
  } = useForm<FormData>({
    defaultValues: {
      email: defaultValue,
      terms: false,
    },
  });
  const {
    verifyEmail, isLoading, error,
  } = useVerifyEmail();

  useEffect(() => {
    if (error?.message === ERROR_CODE.NOT_A_BUSINESS_EMAIL) {
      setError('email', { message: 'Please enter your business email address.' });
    }
  }, [error, setError]);

  const onSubmit = async (data: FormData) => {
    const result = await verifyEmail(data.email);
    if (result.data?.verifyEmail) {
      onSuccess(data.email);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        autoFocus
        label="Work email"
        type="email"
        placeholder="example@mail.com"
        error={formState.errors.email?.message}
        {...register('email', {
          required: true,
          pattern: {
            value: emailRegex,
            message: 'Email format is incorrect',
          },
        })}
      />
      <Footer>
        <BackButton
          onClick={() => setOnboarding({ screen: LightOnboardingScreen.Welcome })}
          size="M"
        >
          Back
        </BackButton>
        <StyledButton
          disabled={!!formState.errors.email || !!formState.errors.terms}
          type="submit"
          size="M"
          isLoading={isLoading}
        >
          Next
        </StyledButton>
      </Footer>
    </Form>
  );
};
