import { ActionButton } from '@cycle-app/ui';
import { FlashIcon, TrashIcon } from '@cycle-app/ui/icons';

import { useOAuthClients } from 'src/hooks/api/queries/api/useOAuthClients';
import { setSettingsApi } from 'src/reactives/settingsApi.reactive';

import { ItemList, ItemCard, ItemCardSkeleton, ItemTitle, AppIconContainer } from '../SettingsAPI.styles';

export const OAuthAppList = () => {
  const query = useOAuthClients();
  if (!query.loading && query.clients.length === 0) return null;
  return (
    <ItemList>
      {query.loading && (
        <>
          <ItemCardSkeleton />
          <ItemCardSkeleton />
        </>
      )}

      {query.clients.map(client => (
        <ItemCard
          key={client.id}
          onClick={() => setSettingsApi({ openedEditAppModal: client.id })}
        >
          <AppIconContainer>
            <FlashIcon size={11} />
          </AppIconContainer>

          <ItemTitle>{client.name}</ItemTitle>

          <ActionButton
            tooltip={`Remove ${client.name}`}
            onClick={e => {
              e.stopPropagation();
              setSettingsApi({ openedRemoveAppModal: client.id });
            }}
          >
            <TrashIcon size={16} />
          </ActionButton>
        </ItemCard>
      ))}
    </ItemList>
  );
};
