import { Emoji } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, Fragment } from 'react';

import { AttributeDefinitionsNode } from 'src/types/attribute.types';
import { isCustom } from 'src/utils/docType.util';

import { More } from './DoctypesOptionsPreview.styles';

const DEFAULT_PREVIEW_LENGTH = 4;

interface Props {
  attribute: AttributeDefinitionsNode;
  previewLength?: number;
}
const DoctypesOptionsPreview: FC<React.PropsWithChildren<Props>> = ({
  attribute,
  previewLength = DEFAULT_PREVIEW_LENGTH,
}) => {
  const docTypes = nodeToArray(attribute.doctypes).filter(isCustom);
  return (
    <>
      {docTypes.slice(0, previewLength).map(d => (
        <Fragment key={d.id}>
          <Emoji emoji={d.emoji} size={14} />
        </Fragment>
      ))}
      {docTypes.length > previewLength && (
        <More>
          {`+${docTypes.length - previewLength}`}
        </More>
      )}
    </>
  );
};

export default DoctypesOptionsPreview;
