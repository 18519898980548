import { Language } from '@cycle-app/graphql-codegen';
import { Input, Tooltip, TextArea, Flex } from '@cycle-app/ui';
import { DownIcon } from '@cycle-app/ui/icons';
import { useDebouncedCallback } from 'use-debounce';

import { insightTypeSettingTitle } from 'src/app/Main/Settings/SettingsDocType/LinkedToRoadmaps';
import { DocTypesList } from 'src/components/DocTypesList';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { FeedbackAutopilot } from 'src/components/FeedbackAutopilot/FeedbackAutopilot';
import * as LanguageSelectStyles from 'src/components/LanguageSelect/LanguageSelect.styles';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { getLanguageName, LANGUAGES_OPTIONS } from 'src/constants/languages.constants';
import { useProduct } from 'src/hooks/api/useProduct';
import { useProductUpdateLanguage } from 'src/hooks/product/useProductUpdateLanguage';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useLoader } from 'src/hooks/useLoader';
import { useProductUpdate } from 'src/hooks/useProductUpdate';
import { isParentOfInsight } from 'src/utils/docType.util';

import { AddDocTypeLinkedToRoadmaps } from '../SettingsDocType/AddDocTypeLinkedToRoadmaps';
import {
  Container, Header, HeaderSide, FakeSectionContent, SavingLabelStyled, Section, InfoIcon, Spacer, Bold,
} from './SettingsAI.styles';
import { ToggleAutoLinkToRoadmap } from './ToggleAutoLinkToRoadmap';
import { UnlimitedAiQueries } from './UnlimitedAiQueries';

export const SettingsAi = () => {
  const { isEnabled: isAutopilotEnabled } = useFeatureFlag('feedback-autopilot-settings');
  return (
    <div>
      <Header>
        <h1>Cycle AI</h1>
        <HeaderSide>
          <SavingLabelStyled />
          <UnlimitedAiQueries />
        </HeaderSide>
      </Header>

      <Container>
        <ProductDescriptionSection />
        <LanguageSection />
        <Section>
          <Header style={{ alignSelf: 'stretch' }}>
            {insightTypeSettingTitle}

            <Spacer />

            <ToggleAutoLinkToRoadmap />
            <Tooltip
              content="This will automatically link AI-generated insights to the right categories (existing or new)"
              placement="top"
              withWrapper={false}
            >
              <InfoIcon />
            </Tooltip>
          </Header>

          <DocTypesList filter={isParentOfInsight} />

          <AddDocTypeLinkedToRoadmaps />
        </Section>

        <Section>
          <Header>
            <h2>Feedback autopilot</h2>
            <Tooltip
              content="You can set up Feedback autopilot for every new feedback coming from your different sources"
              placement="bottom"
              withWrapper={false}
            >
              <InfoIcon />
            </Tooltip>
          </Header>
          {isAutopilotEnabled ? <FeedbackAutopilot /> : <FakeSection />}
        </Section>

        <Section>
          <Header>
            <h2>Fill properties</h2>
          </Header>
          <FakeSection />
        </Section>
      </Container>
    </div>
  );
};

const LanguageSection = () => {
  const { updateLanguage } = useProductUpdateLanguage();
  const { product } = useProduct();
  const language = product?.language;
  return (
    <Flex $justify="space-between">
      <Bold>
        Preferred language for AI-generated titles
      </Bold>
      <ToggleDropdown
        placement="bottom-end"
        button={props => (
          <LanguageSelectStyles.StyledButton
            onClick={props?.onClick}
            {...props['data-active'] && {
              className: 'force-focus',
            }}
            iconEnd={<DownIcon />}
            variant="secondary"
            useCycleColor={false}
            size="S"
          >
            {language ? getLanguageName(language) : 'Select a language'}
          </LanguageSelectStyles.StyledButton>
        )}
        content={({ hide }) => (
          <LanguageSelectStyles.StyledSelectPanel
            options={LANGUAGES_OPTIONS}
            selectedValue={language || undefined}
            onOptionChange={(option) => {
              hide();
              return updateLanguage(option.value as Language);
            }}
          />
        )}
      />
    </Flex>
  );
};

const FakeSection = () => (
  <FakeSectionContent>
    <ul>
      <li>
        <span>ll  some text</span>
        <span>ll  some text</span>
      </li>
      <li>
        <span>some text</span>
        <span>some text</span>
      </li>
    </ul>
    <p>Coming soon as part of Unlimited AI add-on  👀</p>
  </FakeSectionContent>
);

const ProductDescriptionSection = () => {
  const { product } = useProduct();
  const mutation = useProductUpdate();
  useLoader({ loading: mutation.isUpdatingProduct });
  const updateProduct = useDebouncedCallback(mutation.updateProduct, INPUT_ONCHANGE_DEBOUNCE);

  if (!product) return null;

  return (
    <>
      <Input
        id="name"
        label="Product name"
        type="text"
        tooltipLabel="The name of your product will be taken into account in AI queries"
        tooltipProps={{ placement: 'bottom' }}
        defaultValue={product?.name}
        onChange={e => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          updateProduct({
            variables: {
              productId: product.id,
              name: e.target.value.trim(),
            },
          });
        }}
      />
      <TextArea
        autoResize
        id="description"
        label="Product description"
        // eslint-disable-next-line max-len
        tooltipLabel="The description of your product will be taken into account in AI queries. Make it as complete as possible using the terminology you want the AI to be aware of."
        tooltipProps={{ placement: 'bottom' }}
        defaultValue={product?.description ?? ''}
        onChange={e => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          updateProduct({
            variables: {
              productId: product.id,
              description: e.target.value.trim(),
            },
          });
        }}
      />
    </>
  );
};
