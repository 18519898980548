import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';
import { VIDEO_ICON_CLASS_NAME } from '../../constants/icons.constants';

export const VideoIcon: FC<React.PropsWithChildren<SvgProps>> = ({
  className, ...props
}) => (
  <Svg
    className={`${className} ${VIDEO_ICON_CLASS_NAME}`}
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 4.66665C2 3.93027 2.59695 3.33331 3.33333 3.33331H8.66667C9.40305 3.33331 10 3.93027 10 4.66665V11.3333C10 12.0697 9.40305 12.6666 8.66667 12.6666H3.33333C2.59695 12.6666 2 12.0697 2 11.3333V4.66665Z" strokeWidth="1.33333" strokeLinejoin="round" />
    <path d="M10.3685 6.48238L13.0352 5.14905C13.4785 4.92742 14 5.24975 14 5.74534V10.2546C14 10.7502 13.4785 11.0725 13.0352 10.8509L10.3685 9.51758C10.1427 9.40465 10 9.1738 10 8.92129V7.07867C10 6.82615 10.1427 6.59531 10.3685 6.48238Z" strokeWidth="1.33333" strokeLinejoin="round" />
  </Svg>
);
