import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const PlayIcon = ({ className = '' }: Props) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="play, go">
      <path id="Icon" d="M5.36041 1.54836C4.4719 1.00693 3.33325 1.64647 3.33325 2.68695V13.3131C3.33325 14.3536 4.4719 14.9931 5.36041 14.4516L14.0793 9.1386C14.9319 8.619 14.9319 7.38102 14.0793 6.86142L5.36041 1.54836Z" />
    </g>
  </Svg>
);
