import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Badge } from '@cycle-app/ui';
import { FC } from 'react';

import { useCustomerDocs } from 'src/hooks/api/queries/customers/useCustomerDocs';

interface Props {
  customerId: string;
  doctypeType: DoctypeType;
}

export const CustomerChidrenTabsCounter: FC<React.PropsWithChildren<Props>> = ({
  customerId, doctypeType,
}) => {
  const { count } = useCustomerDocs({
    doctypeType,
    id: customerId,
  });
  return (
    <Badge>
      {count}
    </Badge>
  );
};
