import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';

import { SocialContainer, SocialLink } from './ChangelogBuilderContent.styles';

export const ChangelogBuilderContentSocial = () => {
  const { watch } = useChangelogBuilderFormContext();
  const text = watch('socialLinkText');
  const url = watch('socialLinkURL');
  const contentSection = watch('contentSection');
  return text && url ? (
    <SocialContainer>
      <SocialLink
        target="_blank"
        rel="noreferrer"
        href={url}
        $contentSection={contentSection}
      >
        {text}
      </SocialLink>
    </SocialContainer>
  ) : null;
};
