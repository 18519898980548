import { Tooltip, ToggleInput } from '@cycle-app/ui';
import { ComponentProps } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';

import { useProduct } from '../../../../hooks';
import { TooltipButton } from './CreateDocModal.styles';

type Props = Pick<ComponentProps<typeof ToggleInput>, 'checked' | 'onChange' | 'disabled' | 'style'> & {
  onTooltipClick: VoidFunction;
};

const generateTooltipContent = (findInsights?: boolean, categorize?: boolean, markProcessed?: boolean): string => {
  const options = [
    {
      key: findInsights,
      value: 'find insights',
    },
    {
      key: categorize,
      value: 'categorize',
    },
    {
      key: markProcessed,
      value: 'mark processed',
    },
  ];

  const activeOptions = options.filter(option => option.key).map(option => option.value);

  const actionText = activeOptions.length > 1
    ? `${activeOptions.slice(0, -1).join(', ')} and ${activeOptions.slice(-1)}`
    : activeOptions.join('');

  return activeOptions.length === 0
    ? 'Feedback autopilot will write a summary, find insights, categorize, and mark processed – automatically'
    : `Feedback autopilot will ${actionText} – automatically`;
};

export const ToggleAutopilot = ({
  disabled, onTooltipClick, ...props
}: Props) => {
  const { product } = useProduct();
  const { navigate } = useNavigateToSettings({ useLimitationModal: true });
  return (
    <Tooltip
      withPortal
      title={generateTooltipContent(product?.extractInsights, product?.linkToRoadmap, product?.markFeedbackAsProcessed)}
      content={!disabled ? undefined : (
        <TooltipButton
          onClick={e => {
            navigate(PageId.SettingsAi, undefined, undefined, e.metaKey);
            onTooltipClick();
          }}
        >
          Click here to configure
        </TooltipButton>
      )}
      placement="top"
      interactive
    >
      <ToggleInput
        id="toggle-autopilot"
        label="Feedback autopilot"
        togglePosition="left"
        variant="ai"
        disabled={disabled}
        {...props}
      />
    </Tooltip>
  );
};
