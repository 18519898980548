import { FC, useRef } from 'react';
import { Instance as TippyInstance } from 'tippy.js';

import DropdownLayer, { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { Layer } from 'src/types/layers.types';
import { adjustPopperPosition } from 'src/utils/popper.utils';

import { DocSearch, DocSearchProps, SubmitParams } from '../DocSearch/DocSearch';

import type { Placement } from 'tippy.js';

type TargetContent = 'link' | 'docId';

export interface DocSearchDropdownProps extends DocSearchProps {
  className?: string;
  dropdownProps?: Partial<DropdownLayerProps>;
  hideSearchDropdown: VoidFunction;
  isSearchDropdownVisible?: boolean;
  onSubmit?: (p: SubmitParams) => void;
  placeholder?: string;
  placement?: Placement;
  targetContent?: TargetContent;
  layer?: Layer;
}

const DocSearchDropdown: FC<React.PropsWithChildren<DocSearchDropdownProps>> = ({
  children,
  className = '',
  dropdownProps,
  hideSearchDropdown,
  inheritedAttributes,
  isSearchDropdownVisible = false,
  onAdd: onAddProps,
  onSubmit,
  placeholder = 'Search...',
  placement = 'bottom-start',
  targetContent = 'docId',
  layer,
  ...docSearchProps
}) => {
  const tippyInstanceRef = useRef<TippyInstance | null>(null);
  return (
    <DropdownLayer
      visible={isSearchDropdownVisible}
      hide={hideSearchDropdown}
      placement={placement}
      layer={layer}
      content={(
        <DocSearch
          className={className}
          inheritedAttributes={inheritedAttributes}
          onAdd={onAddProps}
          onSubmit={onSubmit}
          placeholder={placeholder}
          targetContent={targetContent}
          onOptionsChange={() => tippyInstanceRef.current?.popperInstance?.forceUpdate()}
          {...docSearchProps}
        />
      )}
      popperOptions={{
        modifiers: [{
          name: 'flip',
          enabled: false,
        }, {
          name: 'offsetAdapter',
          enabled: true,
          phase: 'read',
          requires: ['popperOffsets'],
          options: {
            offset: 24,
          },
          fn: adjustPopperPosition,
        }],
      }}
      onMount={(tippyInstance) => {
        tippyInstanceRef.current = tippyInstance;
      }}
      {...dropdownProps}
    >
      {children}
    </DropdownLayer>
  );
};

export default DocSearchDropdown;
