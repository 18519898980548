/**
 * Subscribe to changes in localStorage
 * @example const unsubscribe = subscribeLocalStorage(LocalKey.Count, console.log);
 */
export const subscribeToLocalStorage = <T>(
  key: string,
  callback: (value: T) => void,
) => {
  const onStorage = (e: StorageEvent) => {
    if (e.storageArea !== localStorage) return;
    if (e.key !== key) return;
    if (!e.newValue) return;
    try {
      callback(JSON.parse(e.newValue));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Failed to parse item from localStorage with key: ${key}`, error);
    }
  };
  window.addEventListener('storage', onStorage);
  return () => {
    window.removeEventListener('storage', onStorage);
  };
};
