import { useQuery } from '@apollo/client';
import { ChangelogCheckDomainDocument } from '@cycle-app/graphql-codegen';

export const useChangelogDomainCheck = (changelogId: string | null | undefined) => {
  const {
    data, loading: isLoading, refetch,
  } = useQuery(ChangelogCheckDomainDocument, {
    skip: !changelogId,
    variables: {
      changelogId,
    },
    fetchPolicy: 'network-only',
  });
  return {
    refetch,
    isValid: !!data?.checkChangelogDomain,
    isLoading,
  };
};
