import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const LinkIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.89464 6.10021L6.09554 8.90105C6.02995 8.96614 5.97789 9.04359 5.94237 9.12891C5.90684 9.21424 5.88855 9.30576 5.88855 9.3982C5.88855 9.49063 5.90684 9.58216 5.94237 9.66748C5.97789 9.75281 6.02995 9.83025 6.09554 9.89535C6.16059 9.96098 6.23799 10.0131 6.32326 10.0486C6.40854 10.0842 6.5 10.1025 6.59238 10.1025C6.68476 10.1025 6.77622 10.0842 6.8615 10.0486C6.94677 10.0131 7.02417 9.96098 7.08922 9.89535L9.88832 7.09451C10.0201 6.96266 10.0941 6.78383 10.0941 6.59736C10.0941 6.41089 10.0201 6.23206 9.88832 6.10021C9.75655 5.96836 9.57783 5.89429 9.39148 5.89429C9.20513 5.89429 9.02641 5.96836 8.89464 6.10021Z" fill="currentcolor" />
    <path d="M8.18789 11.7789L7.29218 12.6681C6.78934 13.1866 6.11337 13.5017 5.3932 13.5534C4.67302 13.605 3.95904 13.3896 3.38744 12.9482C3.08533 12.6991 2.83879 12.3894 2.66376 12.039C2.48873 11.6886 2.38909 11.3054 2.37129 10.9141C2.35348 10.5228 2.4179 10.1321 2.56039 9.76725C2.70288 9.4024 2.92026 9.07152 3.1985 8.79598L4.19218 7.79468C4.25777 7.72959 4.30983 7.65214 4.34536 7.56682C4.38088 7.48149 4.39917 7.38997 4.39917 7.29753C4.39917 7.2051 4.38088 7.11357 4.34536 7.02825C4.30983 6.94292 4.25777 6.86548 4.19218 6.80038C4.12713 6.73475 4.04973 6.68266 3.96446 6.64711C3.87918 6.61156 3.78772 6.59326 3.69534 6.59326C3.60296 6.59326 3.5115 6.61156 3.42622 6.64711C3.34095 6.68266 3.26355 6.73475 3.1985 6.80038L2.30979 7.69665C1.56114 8.42207 1.10099 9.39467 1.01476 10.4339C0.928538 11.4731 1.2221 12.5083 1.84094 13.3473C2.20824 13.8241 2.67272 14.2173 3.20353 14.5006C3.73434 14.784 4.31934 14.9511 4.91965 14.9908C5.51997 15.0305 6.12186 14.942 6.68535 14.7311C7.24884 14.5202 7.76103 14.1917 8.18789 13.7675L9.18157 12.7732C9.31334 12.6413 9.38737 12.4625 9.38737 12.276C9.38737 12.0896 9.31334 11.9107 9.18157 11.7789C9.0498 11.647 8.87108 11.5729 8.68473 11.5729C8.49838 11.5729 8.31966 11.647 8.18789 11.7789Z" fill="currentcolor" />
    <path d="M13.3522 1.84984C12.508 1.22417 11.4649 0.927442 10.418 1.01506C9.37103 1.10269 8.39176 1.56867 7.66308 2.32598L6.90733 3.09621C6.82095 3.15904 6.74871 3.23933 6.69531 3.33187C6.6419 3.42441 6.60851 3.52714 6.5973 3.63341C6.58608 3.73968 6.59729 3.84712 6.63021 3.94878C6.66312 4.05044 6.717 4.14405 6.78836 4.22355C6.85342 4.28918 6.93081 4.34127 7.01609 4.37682C7.10136 4.41237 7.19283 4.43067 7.2852 4.43067C7.37758 4.43067 7.46905 4.41237 7.55432 4.37682C7.63959 4.34127 7.71699 4.28918 7.78204 4.22355L8.69175 3.30627C9.19181 2.78554 9.86698 2.46871 10.5868 2.41698C11.3067 2.36525 12.0202 2.5823 12.5895 3.02619C12.8939 3.27532 13.1423 3.58583 13.3188 3.93749C13.4952 4.28914 13.5956 4.67404 13.6134 5.06711C13.6312 5.46018 13.5661 5.8526 13.4222 6.21879C13.2784 6.58499 13.059 6.91674 12.7784 7.19244L11.7848 8.19373C11.7192 8.25883 11.6671 8.33627 11.6316 8.4216C11.5961 8.50693 11.5778 8.59845 11.5778 8.69088C11.5778 8.78332 11.5961 8.87484 11.6316 8.96017C11.6671 9.04549 11.7192 9.12294 11.7848 9.18803C11.8498 9.25366 11.9272 9.30575 12.0125 9.3413C12.0977 9.37685 12.1892 9.39515 12.2816 9.39515C12.374 9.39515 12.4654 9.37685 12.5507 9.3413C12.636 9.30575 12.7134 9.25366 12.7784 9.18803L13.7721 8.19373C14.1949 7.76668 14.5221 7.25462 14.7322 6.69146C14.9423 6.12831 15.0304 5.52692 14.9907 4.92712C14.951 4.32732 14.7844 3.74281 14.502 3.2123C14.2195 2.68178 13.8276 2.21738 13.3522 1.84984Z" fill="currentcolor" />
  </Svg>
);
