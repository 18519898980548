import { findParentNode } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';

export const isInsideSummary = (typeOrName: string, state: EditorState) => {
  const summaryData = findParentNode(currentNode => currentNode.type.name === typeOrName)(state.selection);

  if (!summaryData) {
    return false;
  }

  return summaryData.node.type.name === typeOrName;
};

export const isInsideContent = (typeOrName: string, state: EditorState) => {
  const { $anchor } = state.selection;
  const parentNode = $anchor.node($anchor.depth - 1);
  const isContentNode = parentNode.type.name === typeOrName;

  if (!parentNode || !isContentNode) {
    return false;
  }

  return true;
};
