import { AddCommentToDocDocument, AddCommentToDocMutation, AddCommentToDocMutationVariables, DocThreadsDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';
import { useCallback, useRef } from 'react';

import { Events, Sources } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { deleteDraftComment } from 'src/reactives/draftComments.reactive';
import client from 'src/services/apollo/client';
import { RefConnection } from 'src/types/apollo.types';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getMeFromCache, getDocBaseFromCache } from 'src/utils/cache.utils';
import { getCommentsCount } from 'src/utils/comments.utils';
import { getPageIdFromPathname, isDocPage } from 'src/utils/routing.utils';
import { parseStoreFieldName } from 'src/utils/update-cache/cache.utils';

/**
 * This hook is used to add a comment to a doc.
 * Side effects:
 * - Optimistically update comments count and comments list in cache
 * - Reset AddComment form
 * - Send CommentSent or ThreadStarted event to analytics
 */
export const useAddComment = (draftId: string) => {
  const pathname = useRef(window.location.pathname);

  const [mutate, { loading }] = useSafeMutation(AddCommentToDocDocument, {
    onCompleted: (data) => {
      deleteDraftComment(draftId);
      const docId = data.addComment?.doc.id;
      if (!docId) return;
      trackAddComment(docId, pathname.current);
    },
  });

  const addComment = useCallback(async (variables: AddCommentToDocMutationVariables) => mutate({
    variables,
    refetchQueries: [{
      query: DocThreadsDocument,
      variables: {
        id: variables.docId,
        resolved: false,
      },
    }, {
      query: DocThreadsDocument,
      variables: {
        id: variables.docId,
        resolved: true,
      },
    }],
    optimisticResponse: getOptimisticResponse(variables),
    update: (_, { data }) => {
      const comment = data?.addComment;
      if (!comment) return;
      client.cache.modify({
        id: client.cache.identify(comment.doc),
        fields: {
          threads: (threads, {
            toReference, storeFieldName,
          }) => {
            // We only want to update the cache for the thread with the given blockId
            const storeVariables = parseStoreFieldName(storeFieldName);
            // eslint-disable-next-line eqeqeq -- for the main thread, undefined != null must be false
            if (storeVariables.blockId != variables.blockId) return threads;

            const commentsCount = threads.edges[0]?.node.commentsCount ?? 0;
            const comments = threads.edges[0]?.node.comments ?? { edges: [] };

            return ({
              edges: [{
                node: {
                  commentsCount: commentsCount + 1,
                  comments: produce(comments, (draft: RefConnection) => {
                    draft.edges.unshift({
                      __typename: 'CommentEdge',
                      node: toReference(comment.id),
                    });
                  }),
                },
              }],
            });
          },
        },
      });
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return {
    addComment,
    isAddingComment: loading,
  };
};

const getOptimisticResponse = (variables: AddCommentToDocMutationVariables): AddCommentToDocMutation | undefined => {
  const creator = getMeFromCache();
  if (!creator) return undefined;
  return {
    addComment: {
      __typename: 'Comment',
      id: 'temp-id',
      content: variables.content,
      creator,
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      doc: {
        __typename: 'Doc',
        id: variables.docId,
      },
    },
  };
};

const trackAddComment = (docId: string, pathname: string) => {
  const doc = getDocBaseFromCache(docId);
  if (!doc) return;
  const event = getCommentsCount(doc) > 1 ? Events.CommentSent : Events.ThreadStarted;
  const pageId = getPageIdFromPathname(pathname);
  const source = isDocPage(pageId) ? Sources.DocPanel : Sources.Board;
  trackAnalytics(event, { source });
};
