import { Input } from '@cycle-app/ui';
import { forwardRef, ChangeEvent } from 'react';

import { ACCEPTED_COVER_IMAGE_FORMAT } from 'src/constants/upload.constant';

interface Props {
  disabled?: boolean;
  onCoverChanged: (file: File) => void;
}
const CoverImageInputFile = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    disabled,
    onCoverChanged,
  } = props;
  return (
    <Input
      ref={ref}
      id="cover"
      disabled={disabled}
      type="file"
      maxLength={1}
      onClick={e => e.stopPropagation()}
      accept={Object.keys(ACCEPTED_COVER_IMAGE_FORMAT).join(',')}
      onChange={onInputChange}
      inputHidden
    />
  );

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return null;
    return onCoverChanged(selectedFile);
  }
});

export default CoverImageInputFile;
