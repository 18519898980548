import { FC } from 'react';

import { mapAddOnsData } from 'src/constants/addOn.constants';

import { Container } from './AddOnIcon.styles';

import type { AddOn } from 'src/types/addOn.types';

export type AddOnIconProps = {
  name: AddOn['name'];
  className?: string;
};

export const AddOnIcon: FC<React.PropsWithChildren<AddOnIconProps>> = ({
  className, name,
}) => {
  return (
    <Container $name={name} className={className}>
      {mapAddOnsData[name].logoV2 || mapAddOnsData[name].logo}
    </Container>
  );
};
