import styled, { css } from 'styled-components';

export const truncate = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Right now in the design system all components use those box-shadows values.
 * If another one is used it's a design smell (or the design system should be updated)
 */
export const BOX_SHADOW_COLOR_Z1 = 'rgba(0, 0, 0, 0.05)';
export const BOX_SHADOW_COLOR_Z2 = 'rgba(0, 0, 0, 0.06)';
export const BOX_SHADOW_COLOR_Z3 = 'rgba(0, 0, 0, 0.06)';
export const BOX_SHADOW_COLOR_Z4 = 'rgba(0, 0, 0, 0.2)';
export const BOX_SHADOW_COLOR_Z5 = 'rgba(0, 0, 0, 0.15)';

export const boxShadowZ1 = css`
  box-shadow: 0px 1px 2px ${BOX_SHADOW_COLOR_Z2};
`;
export const boxShadowZ2 = css`
  box-shadow: 0px 2px 5px ${BOX_SHADOW_COLOR_Z2};
`;
export const boxShadowZ3 = css`
  box-shadow: 0px 3px 11px ${BOX_SHADOW_COLOR_Z3};
`;
export const boxShadowZ2Z3 = css`
  box-shadow: 0px 2px 5px ${BOX_SHADOW_COLOR_Z2}, 0px 3px 11px ${BOX_SHADOW_COLOR_Z3};
`;
export const boxShadowZ4 = css`
  box-shadow: 0px 6px 15px ${BOX_SHADOW_COLOR_Z4};
`;
export const boxShadowZ5 = css`
  box-shadow: 0px 0px 20px ${BOX_SHADOW_COLOR_Z5}, 0px 25px 30px ${BOX_SHADOW_COLOR_Z5};
`;

export const card = css`
  padding: 20px;
  border-radius: 12px;
  background: ${p => p.theme.colors.settings.section.bg};
  border: 1px solid ${p => p.theme.colors.settings.section.border};
  ${boxShadowZ2}
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type FlexProps = {
  $align?: 'center' | 'flex-start' | 'flex-end' | 'stretch';
  $justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch';
  $column?: boolean;
  $gap?: number;
  $grow?: number;
  $widthFull?: boolean;
  $heightFull?: boolean;
};

export const Flex = styled.div<FlexProps>`
  display: flex;
  width: ${p => (p.$widthFull ? '100%' : 'auto')};
  gap: ${p => p.$gap || 0}px;
  flex-direction: ${p => (p.$column ? 'column' : 'row')};
  justify-content: ${p => p.$justify || 'initial'};
  align-items: ${p => p.$align || 'center'};
  flex-grow: ${p => p.$grow || 0};
  ${p => p.$heightFull && 'height: 100%;'}
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

interface StoryPlaygroundContainerProps {
  justifyContent?: 'flex-start' | 'center';
}

export const StoryPlaygroundContainer = styled.div<StoryPlaygroundContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${p => p.justifyContent || 'center'};
  align-items: center;
`;

/**
 * Custom outline compatible with border-radius on Safari
 */

export const ring = css`
  --ring-color: hsl(var(--cycle));
  --ring-size: 1px;
  box-shadow: 0 0 0 var(--ring-size) var(--ring-color);
  outline: none;
`;

export const ring2 = css`
  ${ring}
  --ring-size: 2px;
`;

export const TEXT_GRADIENT_COLORS = ['#967ee4', '#31add7', '#2bc69e'];

export const textGradient = css`
  background-image: linear-gradient(90.4deg, ${TEXT_GRADIENT_COLORS[0]} 0.38%, ${TEXT_GRADIENT_COLORS[1]} 63.15%, ${TEXT_GRADIENT_COLORS[2]} 120.18%);
  background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
`;
