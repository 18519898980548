import { useFormContext } from 'react-hook-form';

import { FieldInput } from './Form.styles';

export const FieldTitle = () => {
  const form = useFormContext();
  const error = typeof form.formState.errors.title?.message === 'string'
    ? form.formState.errors.title.message
    : undefined;
  return (
    <FieldInput
      id="title"
      type="title"
      autoComplete="off"
      autoFocus
      error={error}
      placeholder="Your feedback title"
      {...form.register('title')}
    />
  );
};
