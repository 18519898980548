import { useQuery } from '@apollo/client';
import { FetchProductIntegrationsDocument, IntegrationType, LinearProjectFragment, Feature } from '@cycle-app/graphql-codegen';
import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import keyBy from 'lodash/keyBy';
import { FC, useMemo } from 'react';

import { useLinearProjects } from 'src/hooks/api/queries/integrations/useLinearProjects';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { extract } from 'src/types/graphql.types';

import { useIsRoadmapsEnabled } from '../hooks';

type WorkspaceProviderProps = {
  productId: string;
};

type WorkspaceContextValue = {
  productId: string;
  isLinearInstalled: boolean;
  isIntegrationLoading: boolean;
  linearIntegrationId: string | null;
  linearProviderId: string | null;
  linearProjects: Record<string, LinearProjectFragment>;
  features: Record<Feature, boolean>;
};

const WorkspaceContext = createContext<WorkspaceContextValue>({} as WorkspaceContextValue);

export const WorkspaceProvider: FC<React.PropsWithChildren<WorkspaceProviderProps>> = ({
  productId, children,
}) => {
  const integrationsQuery = useQuery(FetchProductIntegrationsDocument, { variables: { productId } });
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const isReleasesEnabled = useIsReleasesEnabled();

  const linearIntegration = extract('Product', integrationsQuery.data?.node)?.integrations.edges
    .find(e => e.node.type === IntegrationType.Linear)?.node;
  const isLinearInstalled = !!linearIntegration?.provider;
  const linearProjectsQuery = useLinearProjects(linearIntegration?.id, '', { skip: !isLinearInstalled });

  const value = useMemo<WorkspaceContextValue>(() => {
    return {
      productId,
      isLinearInstalled,
      isIntegrationLoading: integrationsQuery.loading,
      linearIntegrationId: linearIntegration?.id ?? null,
      linearProviderId: linearIntegration?.provider?.id ?? null,
      linearProjects: keyBy(linearProjectsQuery.projects, 'id'),
      features: {
        [Feature.Release]: isReleasesEnabled,
        [Feature.Roadmap]: isRoadmapsEnabled,
        [Feature.Analyses]: false,
        [Feature.CustomSections]: false,
        [Feature.CustomerAttributes]: false,
        [Feature.Hubspot]: false,
        [Feature.Meetings]: false,
        [Feature.Salesforce]: false,
        [Feature.UnlimitedAiQueries]: false,
        [Feature.UnlimitedDoctypes]: false,
        [Feature.UnlimitedProperties]: false,
      },
    };
  }, [
    productId, integrationsQuery.loading,
    isLinearInstalled, linearProjectsQuery.projects,
    linearIntegration?.id, linearIntegration?.provider?.id,
    isReleasesEnabled, isRoadmapsEnabled,
  ]);

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useWorkspaceContext = <T extends unknown = WorkspaceContextValue>(selector?: ContextSelector<WorkspaceContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(WorkspaceContext);
  if (!isWrappedWithContext) throw new Error('useWorkspaceContext must be used within a WorkspaceProvider');
  return useContextSelector(WorkspaceContext, selector ?? (ctx => ctx as T));
};
