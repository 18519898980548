import DialogModal from 'src/components/DialogModal/DialogModal';
import { useOAuthClient } from 'src/hooks/api/queries/api/useOAuthClients';
import { closeRevokeAppModal, useGetSettingsApi } from 'src/reactives/settingsApi.reactive';

import { useRevokeOAuthClient } from './useRevokeOAuthClient';

export const OAuthRevokeAppModal = () => {
  const { openedRevokeAppModal } = useGetSettingsApi();
  if (!openedRevokeAppModal) return null;
  return <OAuthRevokeAppModalVisible clientId={openedRevokeAppModal} />;
};

const OAuthRevokeAppModalVisible = ({ clientId }: { clientId: string }) => {
  const { client } = useOAuthClient(clientId);
  const revokeClient = useRevokeOAuthClient(clientId);
  return (
    <DialogModal
      title="Revoke access to OAuth application"
      info={client ? `Are you sure you want to revoke access to ${client.name}?` : undefined}
      hide={closeRevokeAppModal}
      confirmLabel="Revoke"
      onConfirm={revokeClient}
    />
  );
};
