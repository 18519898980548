import { hover, tablet } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

import { body400 } from '../../theme/typo';
import { ring, boxShadowZ1 } from '../../utils/styles.util';
import { ActionButton } from '../Buttons';
import { Skeleton } from '../Skeleton/Skeleton';

type ContainerProps = {
  $withBorder?: boolean;
  $isHighlighted?: boolean;
  $isSelected?: boolean;
  $isReadOnly?: boolean;
  $hasSelection?: boolean;
};

export const OptionContainer = styled.div<{ $isFocus: boolean }>`
  border-radius: 4px;
`;

export const ShowMore = styled.span`
  position: absolute;
  display: inline;
  bottom: -6px;
  right: 0px;
  z-index: 1;
  background-color: var(--context-bg);
  font-weight: bold;
  transition: background-color .2s;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: -20px 0px 15px 0px var(--context-bg);
    transition: box-shadow .2s;
    display: block;
    top: 0;
    bottom: 0;
  }
`;

export const ShowLess = styled.div`
  font-weight: bold;
  cursor: pointer;
`;

export const CopyContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: 70px;
  opacity: 0;
`;

// we need this extra container so the tooltip of CopyButton is well positioned.
export const CopyButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const CopyButton = styled(ActionButton)`
  ${ring}
  --ring-color: ${p => p.theme.colors.actionButton.outline};
  --bg: ${p => p.theme.colors.background.primary};
  --bgHover: ${p => p.theme.colors.background.hover};
`;

interface BulkCheckboxProps {
  $isSelected?: boolean;
}

export const BulkCheckbox = styled.button<BulkCheckboxProps>`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 16px;
  justify-content: center;
  opacity: 0;
  padding: 3px;
  width: 16px;
  pointer-events: all;

  svg {
    width: 8px;
    height: 8px;
  }

  &:hover {
    opacity: 1;
  }

  ${p => p.$isSelected && css`
    opacity: 1;
    background-color: hsl(var(--cycle));
    color: ${p.theme.colors.text.white};
  `};

  ${p => !p.$isSelected && css`
    background-color: ${p.theme.colors.card.checkbox.bg};
    color: ${p.theme.colors.card.checkbox.color};
    border: 1px solid ${p.theme.colors.card.checkbox.border};
    &:hover {
      color: ${p.theme.colors.text.secondary};
    }
  `};

  ${boxShadowZ1};
`;

const HoverStyles = css<ContainerProps>`
  ${hover} {
    &:hover {
      cursor: ${p => (p.$hasSelection ? 'default' : 'pointer')};

      ${OptionContainer} {
        display: block;
      }

      ${CopyContainer} {
        opacity: 1;
      }

      ${BulkCheckbox} {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
`;

export const BulkContainer = styled.div`
  bottom: 0;
  left: 0;
  max-height: 70px;
  position: absolute;
  top: 0;
  z-index: 1;
`;

export const BuklButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 12px;
  border-radius: ${p => (p.$withBorder ? '8px' : '0')};
  border: ${p => (p.$withBorder ? `1px solid ${p.theme.colors.components.EditorIntegrationCard.border}` : 'none')};
  box-shadow: ${p => (p.$withBorder ? '0px 2px 5px rgba(0, 0, 0, 0.05)' : 'none')};
  transition: background .2s, filter .2s;
  box-sizing: border-box;
  background: ${p => p.theme.colors.components.InsightCard.card.bg};

  ${p => (p.$isHighlighted || p.$isSelected) && css`
    outline: 2px solid ${p.theme.colors.components.InsightCard.card.outline};
    outline-offset: -2px;
    border-radius: 8px;
  `}

  ${p => !p.$isReadOnly && HoverStyles}

  ${p => p.$hasSelection && css`
    & > * {
      pointer-events: none;
    }
  `}

  ${p => p.theme.isDark && `
    box-shadow: none;
  `};
`;

interface ContextProps {
  $isInline?: boolean;
}

export const Context = styled.div<ContextProps>`
  position: relative;
  display: flex;

  ${p => !p.$isInline && `
    width: 100%;
    flex-direction: column;
    gap: 8px;
  `};

  ${p => p.$isInline && `
    max-width: 100%;
    margin-top: 0px;
    padding-right: 50px;
    align-items: center;
    gap: 4px;

    ${tablet} {
      padding-right: 20px;
    }
  `}
`;

interface ContextBlockProps {
  $isInline?: boolean;
  $hasHover?: boolean;
  $isReadOnly?: boolean;
}

export const ContextBlock = styled.div<ContextBlockProps>`
  --context-bg: ${p => p.theme.colors.components.InsightCard.quote.bg};
  --context-bg-hover: ${p => p.theme.colors.components.InsightCard.quote.bgHover};
  background-color: var(--context-bg);
  padding: 8px 16px;
  transition: background-color .2s;
  position: relative;
  
  ${p => p.$isInline && css`
    position: relative;
    gap: 8px;
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
  `}

  ${p => !p.$isReadOnly && HoverStyles}

  ${p => p.$hasHover && css`
    &:hover {
      --context-bg: var(--context-bg-hover);
    }
  `}

  :before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    border-radius: 10px;
    height: 100%;
    background: ${p => p.theme.colors.border.primary};
  }
`;

interface ContextContentProps {
  $isInline?: boolean;
  $isExpand?: boolean;
  $shouldHaveMaxHeight?: boolean;
  $isReadOnly?: boolean;
  $hasSmallHeight?: boolean;
}

export const ContextContent = styled.blockquote<ContextContentProps>`
  ${body400}
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  color: ${({ theme }) => theme.colors.text.primary};
  overflow: hidden;
  position: relative;
  max-height: ${(p) => {
    const size = p.$hasSmallHeight ? '87px' : '91px';
    return (p.$isExpand || !p.$shouldHaveMaxHeight ? 'auto' : size);
  }};

  &:not(:first-child) {
    margin-top: 4px;
  }

  ${p => p.$isInline && css`
    white-space: nowrap;
    text-overflow: ellipsis;

    &:not(:empty) {
      min-width: 20px;
    }
  `}

  .ProseMirror, .ProseMirror * {
    cursor: ${p => (p.$isReadOnly ? 'inherit' : 'pointer')};
  }
`;

export const Footer = styled.footer`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  width: 100%;
  min-height: 20px;
  align-items: center;
`;

export const TitleSkeleton = styled(Skeleton)`
  height: 12px;
  width: 100%;
`;

export const SlotOption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
`;
