import { ToggleInput } from '@cycle-app/ui';

import { useProduct } from 'src/hooks';
import { useUpdateAutoLinkToRoadmap } from 'src/hooks/useUpdateAutoLinkToRoadmap';

export const ToggleAutoLinkToRoadmap = () => {
  const { product } = useProduct();
  const updateAutoLinkToRoadmap = useUpdateAutoLinkToRoadmap();
  return (
    <ToggleInput
      id="auto-categorize"
      label="Auto categorize"
      togglePosition="left"
      checked={product?.autoLinkToRoadmap ?? false}
      onChange={e => updateAutoLinkToRoadmap(e.target.checked)}
      variant="secondary"
    />
  );
};
