import { getDocSummary, setDocSummary } from 'src/reactives/docSummary.reactive';

export const onGenerateDocSummaryProgress = (
  mutationId: string,
  progress: number,
) => {
  if (progress !== 100) return;
  const { generateSummaries } = getDocSummary();
  const docIdToRemove = Object.keys(generateSummaries).find(key => generateSummaries[key] === mutationId);
  if (!docIdToRemove) return;
  const newData = generateSummaries;
  delete newData[docIdToRemove];
  setDocSummary({ generateSummaries: newData });
};
