import styled, { css, keyframes } from 'styled-components';

interface ButtonActionProps {
  active?: boolean;
  $hasSmallIcon?: boolean;
  hasGradient?: boolean;
}

const gradient = keyframes`
  0% {
   background-position: 0% 50%;
  }
  50% {
   background-position: 100% 50%;
  }
  100% {
   background-position: 0% 50%;
  }
`;

export const ButtonAction = styled.button<ButtonActionProps>`
  cursor: pointer;
  
  width: 28px;
  height: 32px;
  border-radius: 0;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${p => (p.active ? p.theme.colors.text.opposite : p.theme.colors.text.secondary)};
  background-color: ${p => (p.active ? 'hsl(var(--cycle))' : 'transparent')};

  :first-of-type {
    width: 30px;
    padding-left: 2px;
  }

  :last-of-type {
    width: 30px;
    padding-right: 2px;
  }

  ${p => !p.active && css`
    &:hover {
      color: ${p.theme.colors.text.primary};
      background-color: ${p.theme.colors.background.secondary};
    }
  `};

  ${p => p.$hasSmallIcon && css`
    svg {
      width: 12px;
      height: 12px;
    }
  `};

  ${p => p.hasGradient && css`
    :hover {
      background: linear-gradient(60deg, #FF387030, #A990FF30, #57CFEF30, #7FEA9630, #FFE97430);
      background-size: 300% 300%;
      background-opacity: 0.3;
      animation: ${gradient} 4s ease infinite;
    }
  `};
`;

export const ButtonText = styled(ButtonAction)`
  width: auto;
  gap: 4px;
  padding-left: 4px;
  padding-right: 4px;
  margin: 0;

  :first-of-type {
    width: auto;
    padding-left: 6px;
  }

  :last-of-type {
    width: auto;
    padding-right: 6px;
  }
`;

export const TooltipLabelContainer = styled.div`
  display: inline-flex;
  gap: 2px;
`;
