import { DocFullFragment } from '@cycle-app/graphql-codegen';
import { FileUploadSource } from '@cycle-app/utilities';
import { createContext, Context, ContextSelector, useContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import { Editor } from '@tiptap/react';
import { FC, useMemo } from 'react';

import { UploadReturn } from 'src/hooks/useUploadFile';
import { ActionId } from 'src/services/editor/editorActions';

type EditorFeature =
 | 'image-resizable'
 | 'cover';

export interface EditorContextValue {
  disabledActions?: ActionId[];
  doc?: DocFullFragment | null;
  editor: Editor;
  isUploading?: boolean;
  onError?: (message: string) => void;
  onUpload?: (file: File, from: FileUploadSource) => UploadReturn;
  setDragFileIsDisabled?: (value: boolean) => void;
  isReadOnly?: boolean;
  uploadingSource?: FileUploadSource;
  isInert?: boolean;
  id?: string;
  nbAiQueries?: number;
  disabledFeatures?: EditorFeature[];
}

export const EditorContext = createContext<EditorContextValue | undefined>(undefined) as Context<EditorContextValue>;

export const EditorContextProvider: FC<React.PropsWithChildren<EditorContextValue>> = ({
  disabledActions = [],
  doc,
  editor,
  isUploading,
  onError,
  onUpload,
  setDragFileIsDisabled,
  isReadOnly,
  children,
  uploadingSource,
  isInert,
  id,
  nbAiQueries,
  disabledFeatures = [],
}) => (
  <EditorContext.Provider
    value={useMemo(() => ({
      disabledActions,
      doc,
      editor,
      isReadOnly,
      isUploading,
      onError,
      onUpload,
      setDragFileIsDisabled,
      uploadingSource,
      isInert,
      id,
      nbAiQueries,
      disabledFeatures,
    }), [
      disabledActions,
      doc,
      editor,
      isReadOnly,
      isUploading,
      onError,
      onUpload,
      setDragFileIsDisabled,
      uploadingSource,
      isInert,
      id,
      nbAiQueries,
      disabledFeatures,
    ])}
  >
    {children}
  </EditorContext.Provider>
);

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const useEditorContext = <T,>(selector: ContextSelector<EditorContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(EditorContext);
  if (!isWrappedWithContext) {
    throw new Error('useEditorContext must be used within an EditorProvider');
  }
  return useContextSelector(EditorContext, selector);
};
