import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const DocumentIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.41954 1C9.75763 1 10.0317 1.27408 10.0317 1.61217V4.4512C10.0317 4.47709 10.042 4.50191 10.0603 4.52021C10.0786 4.53851 10.1034 4.5488 10.1293 4.5488H12.9683C13.3064 4.5488 13.5805 4.82287 13.5805 5.16096C13.5805 5.49905 13.3064 5.77313 12.9683 5.77313H10.1293C9.7787 5.77313 9.44247 5.63386 9.19456 5.38595C8.94665 5.13804 8.80737 4.8018 8.80737 4.4512V1.61217C8.80737 1.27408 9.08145 1 9.41954 1Z" fill="#171717" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.45124 2.22433C4.23712 2.22433 4.03177 2.30939 3.88036 2.4608C3.72895 2.61221 3.64389 2.81756 3.64389 3.03169V12.9683C3.64389 13.1824 3.72895 13.3878 3.88036 13.5392C4.03177 13.6906 4.23712 13.7757 4.45124 13.7757H11.5488C11.763 13.7757 11.9683 13.6906 12.1197 13.5392C12.2711 13.3878 12.3562 13.1824 12.3562 12.9683V5.41453L9.16599 2.22433H4.45124ZM3.01462 1.59507C3.39564 1.21405 3.9124 1 4.45124 1H9.41956C9.58191 1 9.73762 1.0645 9.85242 1.1793L13.4012 4.7281C13.516 4.8429 13.5805 4.99861 13.5805 5.16096V12.9683C13.5805 13.5072 13.3665 14.0239 12.9855 14.4049C12.6044 14.7859 12.0877 15 11.5488 15H4.45124C3.9124 15 3.39564 14.7859 3.01462 14.4049C2.63361 14.0239 2.41956 13.5072 2.41956 12.9683V3.03169C2.41956 2.49285 2.63361 1.97608 3.01462 1.59507Z" fill="#171717" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.20544 7.64501C5.20544 7.27752 5.50335 6.97961 5.87084 6.97961H10.1294C10.4969 6.97961 10.7948 7.27752 10.7948 7.64501C10.7948 8.0125 10.4969 8.31041 10.1294 8.31041H5.87084C5.50335 8.31041 5.20544 8.0125 5.20544 7.64501Z" fill="#171717" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.20544 10.4841C5.20544 10.1166 5.50335 9.81873 5.87084 9.81873H9.06476C9.43225 9.81873 9.73016 10.1166 9.73016 10.4841C9.73016 10.8516 9.43225 11.1495 9.06476 11.1495H5.87084C5.50335 11.1495 5.20544 10.8516 5.20544 10.4841Z" fill="#171717" />
  </Svg>
);
