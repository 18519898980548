import { DoctypeFragment, DoctypeType } from '@cycle-app/graphql-codegen';
import { MouseEvent, useCallback, useState, VFC } from 'react';

import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useAttributesFromDoc } from 'src/hooks/api/useAttributesFromBoardConfig/useAttributesFromDoc';
import { useDoc } from 'src/hooks/api/useDoc';
import { useDocChildrenSubscription } from 'src/hooks/api/useDocChildrenSubscription';
import { useDoctype } from 'src/hooks/api/useDocType';
import { useUnlinkedDocChildSubscription } from 'src/hooks/api/useUnlinkedDocChildSubscription';
import { useBoardSlug } from 'src/hooks/usePathParams';
import { isBuiltIn } from 'src/utils/docType.util';

import { Container } from './DocHierarchy.styles';
import DocHierarchyGroup from './DocHierarchyGroup/DocHierarchyGroup';

interface Props {
  className?: string;
  docId?: string;
  isChildrenCreationEnabled?: boolean;
  doctypeId?: string;
  excludeDoctypeId?: string | null;
  onClickChildren?: (e?: MouseEvent) => void;
}

const DocHierarchy: VFC<Props> = ({
  className,
  docId: docIdProps = null,
  isChildrenCreationEnabled = true,
  doctypeId,
  excludeDoctypeId,
  onClickChildren,
}) => {
  const { doc } = useDoc(docIdProps);
  const docAttributes = useAttributesFromDoc(doc);
  const boardSlug = useBoardSlug();
  useDocChildrenSubscription(docIdProps);
  useUnlinkedDocChildSubscription(docIdProps);

  const doctype = useDoctype(doctypeId);
  const [inputDoctypeId, setInputDoctypeId] = useState<string | null>(null);

  const changeDocParent = useChangeDocParent();
  const onDocLinked = useCallback(async (childrenId: string, _: string, created: boolean) => {
    if (!doctype || !doc || created) return;

    await changeDocParent({
      docId: childrenId,
      parentId: doc.id,
    });
  }, [doc, changeDocParent, doctype]);

  if (!doctype?.children?.edges.length) {
    return null;
  }
  return (
    <Container className={className}>
      {doctype?.children?.edges
        .filter(({ node: doctypeChildren }) => doctypeChildren.type !== DoctypeType.Insight &&
          (!excludeDoctypeId || doctypeChildren.id !== excludeDoctypeId))
        .map(({ node: doctypeChildren }) => (
          <DocHierarchyGroup
            key={doctypeChildren.id}
            parentId={doc?.id}
            doctypeChildren={doctypeChildren as DoctypeFragment}
            showInput={inputDoctypeId === doctypeChildren.id}
            onDocLinked={onDocLinked}
            setInputDoctypeId={setInputDoctypeId}
            createChildrenEnabled={isChildrenCreationEnabled}
            docAttributes={docAttributes}
            sourceId={doc?.source?.id}
            customerId={doc?.customer ? doc.customer.id : undefined}
            refefresDeleteQueries={!!boardSlug}
            filterByCustomer={isBuiltIn(doctype)}
            onClickItem={onClickChildren}
          />
        ))}
    </Container>
  );
};

export default DocHierarchy;
