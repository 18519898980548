import { SelectPanel } from '@cycle-app/ui';

import { useBoardMenuOptions } from 'src/hooks/boards/useBoardMenuOptions';

interface Props {
  hide: VoidFunction;
  boardConfigId?: string;
  isDeleteDisabled?: boolean;
  hideSettings?: boolean;
  onDeleteView: (id: string) => void;
  onDuplicateView: (id: string) => void;
  onExportView: (id: string) => void;
  onOpenBoardConfig?: VoidFunction;
  viewId: string;
}

export const ViewMenu = ({
  boardConfigId,
  hide,
  hideSettings,
  isDeleteDisabled,
  onDeleteView,
  onDuplicateView,
  onExportView,
  onOpenBoardConfig,
  viewId,
}: Props) => {
  const {
    options, onMouseEnterItem, onMouseLeaveItem,
  } = useBoardMenuOptions({
    boardConfigId,
    hide,
    hideSettings,
    isDeleteDisabled,
    onDeleteView,
    onDuplicateView,
    onExportView,
    onOpenBoardConfig,
    viewId,
  });
  return (
    <SelectPanel
      hideSearch
      onOptionChange={option => option.onSelect?.()}
      options={options}
      onMouseEnterItem={onMouseEnterItem}
      onMouseLeaveItem={onMouseLeaveItem}
    />
  );
};
