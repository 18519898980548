import { CycleLogo, TextButton, typo, Button, ShyScrollbarCss } from '@cycle-app/ui';
import { tablet, mobile } from '@cycle-app/utilities';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px 48px;

  ${mobile} {
    padding: 24px 24px;
  }
`;
export const Container = styled(motion.div)`
  ${ShyScrollbarCss}
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  :after {
    content: " ";
    height: 100px;
  }

  ${Header} {
    height: 100px;
  }
`;

export const Logo = styled(CycleLogo)`
  --color1: ${p => p.theme.colors.background.active};
  --color2: ${p => p.theme.colors.background.active};
`;

export const Content = styled.div`
  align-items: center;
  gap: 70px;
  max-width: 916px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 480px;
  padding-left: 48px;
  padding-right: 48px;
  height: 100%;

  ${tablet} {
    display: block;
  }
`;

export const Title = styled.h1`
  ${typo.headerMedium}
  line-height: 28px;
`;

export const Text = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 8px;
`;

export const Body = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

export const AvatarImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin: auto;

  ${tablet} {
    display: none;
  }
`;

export const LogoutButton = styled(TextButton)`
  width: auto;
  gap: 20px;

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const LogoutButtonText = styled.span`
  ${mobile} {
    display: none;
  }
`;

export const RequestButton = styled(Button)`
  margin-top: 20px;
`;

export const Contacts = styled.div`
  ${ShyScrollbarCss}
`;

export const ContactTitle = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 16px;
`;

export const ContactButton = styled(Button)`
  color: hsl(var(--cycle));
  width: auto;
  margin-top: 8px;
`;

export const WorkspaceContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
`;
