import {
  DocumentNode,
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client';
import { GraphQLError } from 'graphql';
import set from 'lodash/fp/set';
import { useCallback, useRef } from 'react';

import { ErrorMessage } from 'src/constants/errors.constants';
import { ErrorType } from 'src/types/errors.types';
import { logError, getErrorInfo } from 'src/utils/errors.utils';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

export function isErrorMessage(error: GraphQLError): error is GraphQLError & { message: keyof typeof ErrorMessage } {
  return error.message in ErrorMessage;
}

/**
 * A wrapper around useMutation that adds error handling and aborting
 *
 * @example - Mutation with aborting
 * const [createBoard, { abort: abortCreateBoard }] = useSafeMutation(CREATE_BOARD);
 * createBoard();
 * abortCreateBoard();
 */
export default function useSafeMutation<TData, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & {
    displayErrorMessages?: boolean;
    preventToaster?: boolean;
    /**
     * If true, the previous mutation will be aborted before sending a new one
     * This is useful for cases where the user can trigger the same mutation multiple times in a row
     */
    autoAbort?: boolean;
    /**
     * List of error messages that should not be displayed to the user
     */
    ignoreErrors?: string[];
  },
): [
    (options?: MutationFunctionOptions<TData, TVariables>) => Promise<FetchResult<TData>>,
    MutationResult<TData> & { abort: VoidFunction },
  ] {
  const [mutationFn, mutationResult] = useMutation(mutation, options);

  const abortController = useRef<AbortController>();
  const abort = () => abortController.current?.abort();

  const safeMutation = useCallback(async (args?: MutationFunctionOptions<TData, TVariables> | undefined) => {
    if (options?.autoAbort) abort?.();
    abortController.current = new AbortController();
    const argsWithAbort = set('context.fetchOptions.signal', abortController.current.signal, args ?? {});
    try {
      const result = await mutationFn(argsWithAbort);
      if (options?.displayErrorMessages && result.errors) {
        const errorMessages = result.errors.filter(isErrorMessage);
        errorMessages.forEach(err => {
          addErrorToaster({ message: ErrorMessage[err.message] });
        });
        if (result.errors.length !== errorMessages.length) {
          addErrorToaster({ message: ErrorMessage._GENERIC });
        }
      }
      return result;
    } catch (error) {
      const {
        message,
        isError,
        type,
      } = getErrorInfo(error);

      if (!isError) return { data: null };

      if (error instanceof Error && options?.ignoreErrors?.includes(error.message)) return { data: null };

      const definition = mutation.definitions.find(d => d.kind === 'OperationDefinition');
      logError(error, {
        mutationName: definition && 'name' in definition ? definition.name?.value : undefined,
        mutationsVariable: args?.variables,
      });

      if (options?.preventToaster) return { data: null };

      if (type === ErrorType.AUTHORIZATION) {
        addErrorToaster({ message });
      } else {
        addErrorToaster({
          message: ErrorMessage.SERVER_UNAVAILABLE,
        });
      }

      return { data: null };
    }
  }, [mutation.definitions, mutationFn, options?.autoAbort, options?.displayErrorMessages, options?.ignoreErrors, options?.preventToaster]);

  return [safeMutation, {
    ...mutationResult,
    abort,
  }];
}
