import { DropdownCss, SelectLine, Spinner } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  ${DropdownCss};

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 8px 0;
  width: 458px;
  max-width: 40vw;

  ${mobile} {
    min-width: 330px;
    max-height: 136px;
  }
`;

export const Suggestion = styled(SelectLine)`
  > :last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Loader = styled(Spinner)`
  margin: auto;
`;
