import { CaretIcon } from '@cycle-app/ui/icons';
import { useEffect, FC, useMemo } from 'react';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { useLinearTeams } from 'src/hooks/api/queries/integrations/useLinearTeams';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { isLinearTeam } from 'src/utils/integrations.utils';

import { useGetLinearModal } from '../../reactives/linear.reactive';
import { TeamSelect, LoadingState } from './LinearTeamsSearchDropdown.styles';

type Props = {
  selectedTeamIds: string[];
  isDisabled: boolean;
  onOptionsChange: (options: { value: string; selected?: boolean }[]) => void;
};

export const LinearTeamsSearchDropdownMulti: FC<React.PropsWithChildren<Props>> = ({
  onOptionsChange, selectedTeamIds, isDisabled,
}) => {
  const {
    teams, isLoading,
  } = useLinearTeams();
  const [isDropdownVisible, {
    setTrueCallback: openDropdown,
    setFalseCallback: onHideDropdown,
  }] = useOptimizedBooleanState(false);

  const options = useMemo(() => {
    if (!teams?.length) return [];

    return teams
      .filter(isLinearTeam)
      .map(team => ({
        value: team.id,
        label: team.name,
        selected: selectedTeamIds.some(selectedId => selectedId === team.id),
      }));
  }, [teams, selectedTeamIds]);

  const selectedTeam = teams && selectedTeamIds[0]
    ? teams.find(r => r?.id === selectedTeamIds[0])
    : undefined;

  const linearModal = useGetLinearModal();

  // If we press cmd+enter to open the create project modal, close the dropdown.
  useEffect(() => {
    if (linearModal.type) {
      onHideDropdown();
    }
  }, [linearModal.type, onHideDropdown]);

  useEffect(() => {
    const firstTeamId = teams?.[0]?.id;
    if (selectedTeamIds.length || !firstTeamId) return;

    onOptionsChange([{
      value: firstTeamId,
      selected: true,
    }]);
  }, [onOptionsChange, teams, selectedTeamIds]);

  if (isLoading) return renderSelectButton();

  return (
    <DropdownSelectLayer
      layer={Layer.DropdownBoardConfig}
      placement="bottom-start"
      options={options}
      visible={isDropdownVisible}
      hide={onHideDropdown}
      onOptionsChange={onOptionsChange}
      onSelectAll={onOptionsChange}
      closingArea={false}
      isMulti
      isRequired
      toggleAllValuesVariant="toggle"
    >
      {renderSelectButton()}
    </DropdownSelectLayer>
  );

  function renderSelectButton() {
    return (
      <TeamSelect
        onClick={() => {
          if (isDisabled) return;
          openDropdown();
        }}
      >
        {(isLoading || !selectedTeam?.name)
          ? <LoadingState />
          : (
            <>
              {selectedTeam?.name}
              {selectedTeamIds.length > 1 && ` +${selectedTeamIds.length - 1}`}
              <CaretIcon />
            </>
          )}
      </TeamSelect>
    );
  }
};
