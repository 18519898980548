import {
  CompassIcon,
  AddMemberAltIcon,
} from '@cycle-app/ui/icons';
import { memo } from 'react';

import { Item } from 'src/app/Main/Sidebar/SidebarFooterItem';
import { AskIcon } from 'src/components/Ask';
import { useFeatureFlag, useOfflineGuard, useSidebarCollapsed } from 'src/hooks';
import { setLearningCenter } from 'src/reactives';
import { toggleAddMember } from 'src/reactives/addMember.reactive';
import { openAsk, useGetAskPanel } from 'src/reactives/ask.reactive';

import { Container, LearningCenterContainer } from './SidebarFooterActions.styles';

export const SidebarFooterActions = memo(() => {
  const collapsed = useSidebarCollapsed();
  const offlineGuard = useOfflineGuard({ message: '🔌 You need access to network to invite a member' });
  const isAskOpen = useGetAskPanel().isOpen;
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  return (
    <Container $collapsed={collapsed}>
      {isAskEnabled && (
        <Item
          icon={<AskIcon size={18} />}
          label="Ask Cycle"
          onClick={() => openAsk()}
          isActive={isAskOpen}
        />
      )}

      <LearningCenterContainer>
        <Item
          icon={<CompassIcon />}
          label="Learning center"
          onClick={() => {
            setLearningCenter({ isVisible: true });
          }}
        />
      </LearningCenterContainer>
      <Item
        icon={<AddMemberAltIcon />}
        label="Invite new member"
        onClick={() => offlineGuard(toggleAddMember)}
      />
    </Container>
  );
});
