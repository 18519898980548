import { createContext, useContextSelector, ContextSelector, useHasParentContext } from '@fluentui/react-context-selector';
import { Editor } from '@tiptap/core';
import { FC, useMemo, useState } from 'react';

import { useOptimizedBooleanState } from 'src/hooks';
import { useChangeDocParent } from 'src/hooks/api/mutations/useChangeDocParent';
import { useDocTargetsSubscription } from 'src/hooks/api/useDocTargetsSubscription';
import { useDocInsightsCache } from 'src/hooks/insight/useDocInsights.cache';
import { FullDocWithPublicId } from 'src/types/doc.types';

export type GeneratedInsightData = {
  blockId: string;
  id: string;
  isReadyToBeInserted: boolean;
  parentDocTypeId?: string | null;
  quote: string;
};

type DocPanelProviderProps = {
  docId: string;
  doc: FullDocWithPublicId | null;
};

type BulkDocTarget = {
  docId: string;
  newParentId: string | null;
};

type DocPanelContextValue = {
  docId: string;
  doc: FullDocWithPublicId | null;
  threadsCount?: number;
  setThreadsCount: (count: number) => void;
  editor?: Editor;
  setEditor: (editor: Editor) => void;
  // Used to display skeletons of insights extracted from feedback
  loadingBulkDocTargets: BulkDocTarget[];
  setLoadingBulkDocTargets: (contents: BulkDocTarget[]) => void;
  insightsAiData: GeneratedInsightData[];
  isInsightsReadyToBeInsertedInDoc: boolean;
  isInsightsAiGenerating: boolean;
  addGeneratedInsightAi: (data: GeneratedInsightData[]) => void;
  onInsightsAiInserted: VoidFunction;
};

const DocPanelContext = createContext<DocPanelContextValue>({} as DocPanelContextValue);

export const DocPanelProvider: FC<React.PropsWithChildren<DocPanelProviderProps>> = ({
  docId, doc, children,
}) => {
  const [isInsightsAiGenerating, {
    setTrueCallback: setInsightsAiGenerating,
    setFalseCallback: setInsightsAiStopGenerating,
  }] = useOptimizedBooleanState(false);

  const [isInsightsReadyToBeInsertedInDoc, {
    setTrueCallback: setInsightAiReady,
    setFalseCallback: setInsightAiNotReady,
  }] = useOptimizedBooleanState(false);

  const [insightsAiData, setInsightsAiData] = useState<GeneratedInsightData[]>([]);
  const [threadsCount, setThreadsCount] = useState<number>();
  const [editor, setEditor] = useState<Editor>();
  const [loadingBulkDocTargets, setLoadingBulkDocTargets] = useState<BulkDocTarget[]>([]);
  const { add: addInsightsInCache } = useDocInsightsCache();
  const changeDocParent = useChangeDocParent();

  useDocTargetsSubscription(docId, {
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) return;

      // Remove skeletons of insights
      if (loadingBulkDocTargets.length > 0) {
        const lastIds = data.docTargets.map(insight => insight?.doc?.id);
        const newState = loadingBulkDocTargets.filter(target => !lastIds.includes(target.docId));
        if (newState.length === loadingBulkDocTargets.length) return;
        setLoadingBulkDocTargets(newState);

        setTimeout(() => {
          const newInsightAiData = insightsAiData;
          for (const docTarget of data.docTargets) {
            const docSource = docTarget?.doc?.docSource;
            const item = loadingBulkDocTargets.find(target => target.docId === docTarget?.doc?.id);

            if (!docTarget?.doc?.id || !docSource?.__typename || !docSource.doc || !item) {
              // eslint-disable-next-line no-continue
              continue;
            }

            // Add newly created parent to insight
            if (item.newParentId) {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              changeDocParent({
                docId: item.docId,
                parentId: item.newParentId,
              });
            }

            const i = newInsightAiData.findIndex(d => d.id === docTarget.doc?.id);
            const aiData = newInsightAiData[i];
            if (i >= 0 && aiData) {
              newInsightAiData[i] = {
                ...aiData,
                isReadyToBeInserted: true,
              };
            }

            // Add insights in cache
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            addInsightsInCache({
              docId: docTarget.doc.id,
              feedbackId: docSource.doc.id,
              docTarget: {
                __typename: 'DocTarget',
                id: btoa(atob(docSource.id).replace(docSource.__typename, 'DocTarget')),
                content: docSource.content,
                blockId: docSource.blockId,
              },
            });
          }

          setInsightAiReady();
        });
      }
    },
  });

  const value = useMemo(() => {
    const addGeneratedInsightAi = (data: GeneratedInsightData[]) => {
      const newData = [...insightsAiData];
      data.forEach(d => {
        if (insightsAiData.find(d2 => d2.id === d.id)) return;
        newData.push(d);
      });
      setInsightsAiData(newData);
      setInsightsAiGenerating();
    };

    const onInsightsAiInserted = () => {
      setInsightsAiData([]);
      setInsightsAiStopGenerating();
      setInsightAiNotReady();
    };

    return {
      docId,
      doc,
      threadsCount,
      setThreadsCount,
      editor,
      setEditor,
      loadingBulkDocTargets,
      setLoadingBulkDocTargets,
      insightsAiData,
      isInsightsReadyToBeInsertedInDoc,
      isInsightsAiGenerating,
      addGeneratedInsightAi,
      onInsightsAiInserted,
    };
  }, [
    docId, doc, threadsCount, editor, loadingBulkDocTargets,
    insightsAiData, isInsightsReadyToBeInsertedInDoc, isInsightsAiGenerating,
    setInsightsAiGenerating, setInsightsAiStopGenerating, setInsightAiNotReady,
  ]);

  return (
    <DocPanelContext.Provider value={value}>
      {children}
    </DocPanelContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const useDocPanelContext = <T extends unknown = DocPanelContextValue>(selector?: ContextSelector<DocPanelContextValue, T>) => {
  const isWrappedWithContext = useHasParentContext(DocPanelContext);
  if (!isWrappedWithContext) throw new Error('useDocPanelContext must be used within a DocPanelProvider');
  return useContextSelector(DocPanelContext, selector ?? (ctx => ctx as T));
};
