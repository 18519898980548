import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MiroIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M0 0H28V28H0V0Z" fill="#FFD02F" />
    <path d="M19.0796 3.73328H16.0906L18.7442 7.84578L13.1342 3.73328H10.1452L12.7988 9.12211L7.18884 3.73328H4.19983L6.80968 10.6901L4.19983 24.2666H7.18884L12.7988 9.70953L10.1452 24.2666H13.1342L18.7442 8.43378L16.0906 24.2666H19.0796L24.7333 6.87453L19.0796 3.73328Z" fill="#050038" />
  </Svg>
);
