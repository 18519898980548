import { FC } from 'react';

import { SvgProps } from '../../components/Svg/Svg.styles';
import confluence from './confluence.png';

export const ConfluenceIcon: FC<React.PropsWithChildren<SvgProps>> = ({
  className, size,
}) => (
  <img className={className} width={size || 16} src={confluence} alt="Confluence" />
);
