import { ThemeType } from '@cycle-app/graphql-codegen';
import { InboxIcon, BulbIcon, ReleaseIcon, RoadmapIcon } from '@cycle-app/ui/icons';
import { useTheme } from 'styled-components';

import {
  StyledTooltip, Content, LeftLabels, RightLabels, Label, SvgContainer,
} from './FeedbackLifecycle.styles';

export type FeedbackLifecycleStep = 'feedback' | 'insight' | 'roadmap' | 'release';

const rotation: Record<FeedbackLifecycleStep, number> = {
  feedback: 0,
  insight: 90,
  roadmap: 180,
  release: 270,
};

export const FeedbackLifecycle = ({ step }: { step: FeedbackLifecycleStep }) => {
  const theme = useTheme();
  const isDark = theme.themeType === ThemeType.Nightfall;

  const activeColor = isDark ? '#E6E6E6' : '#999999';
  const defaultColor = isDark ? '#999999' : '#E6E6E6';

  return (
    <StyledTooltip
      withPortal
      withWrapper={false}
      placement="bottom-end"
      interactive
      width={450}
      offset={[16, 10]}
      content={(
        <Content>
          <LeftLabels>
            <Label $active={step === 'feedback'}>
              <InboxIcon />
              Feedback
            </Label>
            <Label $active={step === 'release'}>
              <ReleaseIcon />
              Releases
            </Label>
          </LeftLabels>

          <SvgContainer>
            <SvgLifecycle step={step} />
          </SvgContainer>

          <RightLabels>
            <Label $active={step === 'insight'}>
              <BulbIcon />
              Insights
            </Label>
            <Label $active={step === 'roadmap'}>
              <RoadmapIcon />
              Roadmaps
            </Label>
          </RightLabels>
        </Content>
      )}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          transform: `rotate(${rotation[step]}deg)`,
        }}
      >
        <path fill={activeColor} d="M13.0319 3.32797C12.9658 3.6693 12.8335 3.99167 12.6445 4.27611C12.4649 4.55107 12.2287 4.77863 11.9546 4.96825C11.6995 5.13892 11.4065 5.26218 11.0946 5.30959C11.0474 5.30959 11.0001 5.33803 10.9623 5.35699C10.8206 5.37595 10.6883 5.39492 10.5465 5.42336C9.00612 5.72677 7.61693 6.58957 6.66245 7.84112C5.92533 8.80822 5.47172 9.95547 5.34886 11.1501C5.28271 11.0743 5.20711 10.9984 5.13151 10.9226C4.50779 10.3158 3.67616 9.98392 2.80674 9.9934C2.37202 9.9934 1.93731 10.0503 1.5215 10.1925C1.10568 10.3347 0.737122 10.5433 0.406362 10.8278C0.255158 10.9605 0.122854 11.1027 0 11.2544C0 11.226 0 11.207 0 11.1786C0.113403 8.85563 0.907228 6.60854 2.28697 4.7407C3.66671 2.86338 5.56622 1.45065 7.74924 0.654217C8.68481 0.312886 9.64874 0.094814 10.6221 0C11.1419 0.0284442 11.6333 0.208591 12.0491 0.530958C12.5972 0.957622 12.9469 1.56443 13.0319 2.26605C13.0886 2.61687 13.0792 2.97716 13.013 3.32797H13.0319Z" />
        <path fill={defaultColor} d="M20.6974 13.1322C20.3587 13.0655 20.0388 12.9322 19.7565 12.7418C19.4837 12.5608 19.2579 12.3227 19.0697 12.0466C18.9003 11.7895 18.778 11.4942 18.7309 11.18C18.7309 11.1324 18.7027 11.0848 18.6839 11.0467C18.6651 10.9038 18.6463 10.7705 18.618 10.6276C18.3169 9.0754 17.4607 7.67552 16.2187 6.7137C15.259 5.97091 14.1205 5.51381 12.935 5.39001C13.0103 5.32335 13.0855 5.24716 13.1608 5.17098C13.763 4.54246 14.0923 3.70444 14.0829 2.82833C14.0829 2.39027 14.0264 1.95221 13.8853 1.5332C13.7442 1.11419 13.5372 0.742793 13.2549 0.409488C13.1232 0.257121 12.982 0.123799 12.8315 0C12.8597 0 12.8785 0 12.9068 0C15.212 0.114276 17.4419 0.914206 19.2955 2.30456C21.1585 3.69492 22.5604 5.60904 23.3508 7.80885C23.6895 8.75162 23.9059 9.72296 24 10.7038C23.9718 11.2276 23.793 11.7228 23.4731 12.1418C23.0497 12.6941 22.4475 13.0465 21.7512 13.1322C21.4031 13.1893 21.0456 13.1798 20.6974 13.1131V13.1322Z" />
        <path fill={defaultColor} d="M10.9681 20.672C11.0342 20.3307 11.1665 20.0083 11.3555 19.7239C11.5351 19.4489 11.7713 19.2214 12.0454 19.0317C12.3005 18.8611 12.5935 18.7378 12.9054 18.6904C12.9526 18.6904 12.9999 18.662 13.0377 18.643C13.1794 18.624 13.3117 18.6051 13.4535 18.5766C14.9939 18.2732 16.3831 17.4104 17.3375 16.1589C18.0747 15.1918 18.5283 14.0445 18.6511 12.8499C18.7173 12.9257 18.7929 13.0016 18.8685 13.0774C19.4922 13.6842 20.3238 14.0161 21.1933 14.0066C21.628 14.0066 22.0627 13.9497 22.4785 13.8075C22.8943 13.6653 23.2629 13.4567 23.5936 13.1722C23.7448 13.0395 23.8771 12.8973 24 12.7456C24 12.774 24 12.793 24 12.8214C23.8866 15.1444 23.0928 17.3915 21.713 19.2593C20.3333 21.1366 18.4338 22.5493 16.2508 23.3458C15.3152 23.6871 14.3513 23.9052 13.3779 24C12.8581 23.9716 12.3667 23.7914 11.9509 23.469C11.4028 23.0424 11.0531 22.4356 10.9681 21.7339C10.9114 21.3831 10.9208 21.0228 10.987 20.672H10.9681Z" />
        <path fill={defaultColor} d="M3.30257 10.8678C3.64129 10.9345 3.9612 11.0678 4.24347 11.2582C4.51633 11.4392 4.74215 11.6773 4.93033 11.9534C5.09969 12.2105 5.22201 12.5058 5.26905 12.82C5.26905 12.8676 5.29728 12.9152 5.3161 12.9533C5.33492 13.0962 5.35373 13.2295 5.38196 13.3724C5.68305 14.9246 6.53927 16.3245 7.78126 17.2863C8.74098 18.0291 9.87947 18.4862 11.065 18.61C10.9897 18.6767 10.9145 18.7528 10.8392 18.829C10.237 19.4575 9.9077 20.2956 9.91711 21.1717C9.91711 21.6097 9.97357 22.0478 10.1147 22.4668C10.2558 22.8858 10.4628 23.2572 10.7451 23.5905C10.8768 23.7429 11.018 23.8762 11.1685 24C11.1403 24 11.1215 24 11.0932 24C8.78803 23.8857 6.55809 23.0858 4.70451 21.6954C2.84153 20.3051 1.43958 18.391 0.649223 16.1912C0.310498 15.2484 0.0940902 14.277 0 13.2962C0.0282271 12.7724 0.206999 12.2772 0.526905 11.8582C0.950311 11.3059 1.55249 10.9535 2.24876 10.8678C2.59689 10.8107 2.95443 10.8202 3.30257 10.8869V10.8678Z" />
      </svg>
    </StyledTooltip>
  );
};

const SvgLifecycle = ({ step }: { step: FeedbackLifecycleStep }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="197" height="111" fill="none">
      <path fill="url(#lifecycle-feedback)" d="M102.067 28.984c.015 0 .026.013.023.028a7.825 7.825 0 0 1-1.157 2.816c-.538.825-1.247 1.508-2.07 2.077a6.428 6.428 0 0 1-2.448 1.002c-.087.016-.178.018-.262.043-.186.056-.364.134-.557.16-.319.046-.63.095-.956.16a19.406 19.406 0 0 0-11.653 7.253 19.743 19.743 0 0 0-3.821 8.99c-.054.355-.518.509-.772.255a9.82 9.82 0 0 0-6.974-2.788c-1.304 0-2.608.17-3.856.598a10.036 10.036 0 0 0-3.345 1.905 10.13 10.13 0 0 0-1.074 1.105c-.05.058-.145.024-.145-.052.34-6.97 2.722-13.71 6.86-19.314 4.14-5.632 9.839-9.87 16.388-12.26A35.073 35.073 0 0 1 93.96 19.1c.602-.074 1.214-.101 1.813.002a7.592 7.592 0 0 1 3.374 1.49 7.687 7.687 0 0 1 2.949 5.206 9.254 9.254 0 0 1-.052 3.158c-.002.015.009.028.023.028Z" />
      <path fill="#000" fillOpacity=".3" d="M102.067 28.984c.015 0 .026.013.023.028a7.825 7.825 0 0 1-1.157 2.816c-.538.825-1.247 1.508-2.07 2.077a6.428 6.428 0 0 1-2.448 1.002c-.087.016-.178.018-.262.043-.186.056-.364.134-.557.16-.319.046-.63.095-.956.16a19.406 19.406 0 0 0-11.653 7.253 19.743 19.743 0 0 0-3.821 8.99c-.054.355-.518.509-.772.255a9.82 9.82 0 0 0-6.974-2.788c-1.304 0-2.608.17-3.856.598a10.036 10.036 0 0 0-3.345 1.905 10.13 10.13 0 0 0-1.074 1.105c-.05.058-.145.024-.145-.052.34-6.97 2.722-13.71 6.86-19.314 4.14-5.632 9.839-9.87 16.388-12.26A35.073 35.073 0 0 1 93.96 19.1c.602-.074 1.214-.101 1.813.002a7.592 7.592 0 0 1 3.374 1.49 7.687 7.687 0 0 1 2.949 5.206 9.254 9.254 0 0 1-.052 3.158c-.002.015.009.028.023.028Z" />
      <path fill="url(#lifecycle-insights)" d="M125.092 58.368a.023.023 0 0 1-.028.023 7.703 7.703 0 0 1-2.794-1.166c-.819-.543-1.496-1.257-2.061-2.085a6.533 6.533 0 0 1-.995-2.469c-.015-.087-.017-.178-.042-.263-.056-.188-.133-.367-.16-.56a16.36 16.36 0 0 0-.158-.965c-.903-4.657-3.472-8.856-7.198-11.742a19.437 19.437 0 0 0-8.915-3.85c-.357-.055-.513-.521-.259-.778a9.977 9.977 0 0 0 2.767-7.028c0-1.314-.17-2.628-.593-3.885a10.177 10.177 0 0 0-1.891-3.372c-.345-.398-.71-.753-1.097-1.083-.058-.05-.024-.145.052-.145a34.834 34.834 0 0 1 19.166 6.914c5.589 4.17 9.795 9.913 12.166 16.512a35.782 35.782 0 0 1 1.851 7.792c.072.594.098 1.196-.001 1.786a7.713 7.713 0 0 1-1.483 3.421c-1.27 1.657-3.076 2.715-5.165 2.972a9.042 9.042 0 0 1-3.134-.052.024.024 0 0 0-.028.023Z" />
      <path fill="#000" fillOpacity=".6" d="M125.092 58.368a.023.023 0 0 1-.028.023 7.703 7.703 0 0 1-2.794-1.166c-.819-.543-1.496-1.257-2.061-2.085a6.533 6.533 0 0 1-.995-2.469c-.015-.087-.017-.178-.042-.263-.056-.188-.133-.367-.16-.56a16.36 16.36 0 0 0-.158-.965c-.903-4.657-3.472-8.856-7.198-11.742a19.437 19.437 0 0 0-8.915-3.85c-.357-.055-.513-.521-.259-.778a9.977 9.977 0 0 0 2.767-7.028c0-1.314-.17-2.628-.593-3.885a10.177 10.177 0 0 0-1.891-3.372c-.345-.398-.71-.753-1.097-1.083-.058-.05-.024-.145.052-.145a34.834 34.834 0 0 1 19.166 6.914c5.589 4.17 9.795 9.913 12.166 16.512a35.782 35.782 0 0 1 1.851 7.792c.072.594.098 1.196-.001 1.786a7.713 7.713 0 0 1-1.483 3.421c-1.27 1.657-3.076 2.715-5.165 2.972a9.042 9.042 0 0 1-3.134-.052.024.024 0 0 0-.028.023Z" />
      <path fill="url(#lifecycle-roadmaps)" d="M95.933 81.016a.023.023 0 0 1-.023-.028 7.832 7.832 0 0 1 1.157-2.816c.538-.825 1.247-1.508 2.07-2.077a6.43 6.43 0 0 1 2.448-1.002c.087-.016.178-.018.262-.043.186-.056.364-.134.557-.16.319-.046.629-.094.956-.16a19.408 19.408 0 0 0 11.653-7.253 19.746 19.746 0 0 0 3.821-8.99c.054-.355.518-.509.771-.255a9.824 9.824 0 0 0 6.975 2.788c1.304 0 2.608-.17 3.856-.598a10.036 10.036 0 0 0 3.345-1.905c.395-.347.747-.715 1.074-1.105.049-.058.145-.024.145.052-.34 6.97-2.722 13.71-6.861 19.314-4.139 5.632-9.838 9.87-16.387 12.26a35.078 35.078 0 0 1-7.712 1.862c-.602.074-1.214.101-1.812-.002a7.59 7.59 0 0 1-3.375-1.49 7.688 7.688 0 0 1-2.949-5.206 9.244 9.244 0 0 1 .052-3.158.024.024 0 0 0-.023-.028Z" />
      <path fill="#000" fillOpacity=".6" d="M95.933 81.016a.023.023 0 0 1-.023-.028 7.832 7.832 0 0 1 1.157-2.816c.538-.825 1.247-1.508 2.07-2.077a6.43 6.43 0 0 1 2.448-1.002c.087-.016.178-.018.262-.043.186-.056.364-.134.557-.16.319-.046.629-.094.956-.16a19.408 19.408 0 0 0 11.653-7.253 19.746 19.746 0 0 0 3.821-8.99c.054-.355.518-.509.771-.255a9.824 9.824 0 0 0 6.975 2.788c1.304 0 2.608-.17 3.856-.598a10.036 10.036 0 0 0 3.345-1.905c.395-.347.747-.715 1.074-1.105.049-.058.145-.024.145.052-.34 6.97-2.722 13.71-6.861 19.314-4.139 5.632-9.838 9.87-16.387 12.26a35.078 35.078 0 0 1-7.712 1.862c-.602.074-1.214.101-1.812-.002a7.59 7.59 0 0 1-3.375-1.49 7.688 7.688 0 0 1-2.949-5.206 9.244 9.244 0 0 1 .052-3.158.024.024 0 0 0-.023-.028Z" />
      <path fill="url(#lifecycle-releases)" d="M72.908 51.632c0-.015.013-.026.028-.023 1.006.202 1.955.6 2.794 1.166.819.543 1.496 1.257 2.061 2.085.484.736.84 1.575.995 2.469.015.087.017.178.042.263.056.188.133.367.16.56.044.323.093.635.158.965.903 4.657 3.472 8.856 7.198 11.742a19.44 19.44 0 0 0 8.915 3.85c.357.055.513.521.259.778a9.978 9.978 0 0 0-2.767 7.028c0 1.314.17 2.628.593 3.885a10.153 10.153 0 0 0 1.891 3.371c.345.399.71.754 1.097 1.084.058.05.024.145-.052.145a34.838 34.838 0 0 1-19.166-6.914c-5.59-4.17-9.795-9.913-12.166-16.513a35.793 35.793 0 0 1-1.85-7.791c-.073-.594-.099-1.196 0-1.786a7.709 7.709 0 0 1 1.483-3.421c1.27-1.657 3.076-2.715 5.165-2.972a9.039 9.039 0 0 1 3.134.052.024.024 0 0 0 .028-.023Z" />
      <path fill="#000" fillOpacity=".6" d="M72.908 51.632c0-.015.013-.026.028-.023 1.006.202 1.955.6 2.794 1.166.819.543 1.496 1.257 2.061 2.085.484.736.84 1.575.995 2.469.015.087.017.178.042.263.056.188.133.367.16.56.044.323.093.635.158.965.903 4.657 3.472 8.856 7.198 11.742a19.44 19.44 0 0 0 8.915 3.85c.357.055.513.521.259.778a9.978 9.978 0 0 0-2.767 7.028c0 1.314.17 2.628.593 3.885a10.153 10.153 0 0 0 1.891 3.371c.345.399.71.754 1.097 1.084.058.05.024.145-.052.145a34.838 34.838 0 0 1-19.166-6.914c-5.59-4.17-9.795-9.913-12.166-16.513a35.793 35.793 0 0 1-1.85-7.791c-.073-.594-.099-1.196 0-1.786a7.709 7.709 0 0 1 1.483-3.421c1.27-1.657 3.076-2.715 5.165-2.972a9.039 9.039 0 0 1 3.134.052.024.024 0 0 0 .028-.023Z" />
      <path fill="#fff" fillOpacity={step === 'insight' ? 0.7 : 0.4} d="M130 33.355a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
      <path fill="#fff" fillOpacity={step === 'insight' ? 0.7 : 0.2} d="M157.918 2.355a.749.749 0 0 1-.164.246l-1.134 1.135a.75.75 0 0 1-1.061-1.06l1.134-1.135a.751.751 0 0 1 .355-.199.749.749 0 0 1 .702-.487h1.604a.75.75 0 0 1 0 1.5h-1.436ZM162.562.855a.75.75 0 0 0 0 1.5h3.209a.75.75 0 0 0 0-1.5h-3.209ZM168.979.855a.75.75 0 0 0 0 1.5h3.209a.75.75 0 0 0 0-1.5h-3.209ZM175.396.855a.75.75 0 0 0 0 1.5h3.208a.75.75 0 0 0 0-1.5h-3.208ZM181.812.855a.75.75 0 0 0 0 1.5h3.209a.75.75 0 0 0 0-1.5h-3.209ZM188.229.855a.75.75 0 0 0 0 1.5h3.209a.75.75 0 0 0 0-1.5h-3.209ZM194.646.855a.75.75 0 0 0 0 1.5h1.604a.75.75 0 0 0 0-1.5h-1.604ZM129.47 28.764a.75.75 0 1 0 1.06 1.061l1.135-1.134a.75.75 0 1 0-1.061-1.061l-1.134 1.134ZM132.873 25.361a.75.75 0 0 0 1.06 1.061l2.269-2.269a.75.75 0 1 0-1.061-1.06l-2.268 2.268ZM137.41 20.824a.75.75 0 1 0 1.061 1.06l2.268-2.268a.75.75 0 0 0-1.06-1.06l-2.269 2.268ZM141.947 16.287a.75.75 0 1 0 1.061 1.06l2.269-2.268a.75.75 0 0 0-1.061-1.06l-2.269 2.268ZM146.485 11.75a.75.75 0 0 0 1.06 1.06l2.269-2.268a.75.75 0 1 0-1.061-1.061l-2.268 2.269ZM151.022 7.212a.75.75 0 0 0 1.06 1.061l2.269-2.269a.75.75 0 1 0-1.061-1.06l-2.268 2.268Z" />
      <path fill="#fff" fillOpacity={step === 'feedback' ? 0.7 : 0.4} d="M68 33.355a4 4 0 1 0 8 0 4 4 0 0 0-8 0Z" />
      <path fill="#fff" fillOpacity={step === 'feedback' ? 0.7 : 0.2} d="M40.082 2.355c.037.09.091.174.164.246l1.134 1.135a.75.75 0 1 0 1.06-1.06L41.308 1.54a.747.747 0 0 0-.355-.199.75.75 0 0 0-.702-.487h-1.604a.75.75 0 0 0 0 1.5h1.436ZM35.438.855a.75.75 0 0 1 0 1.5h-3.209a.75.75 0 0 1 0-1.5h3.209ZM29.02.855a.75.75 0 0 1 0 1.5h-3.207a.75.75 0 0 1 0-1.5h3.208ZM22.604.855a.75.75 0 0 1 0 1.5h-3.208a.75.75 0 0 1 0-1.5h3.208ZM16.188.855a.75.75 0 0 1 0 1.5h-3.209a.75.75 0 1 1 0-1.5h3.209ZM9.77.855a.75.75 0 0 1 0 1.5H6.564a.75.75 0 1 1 0-1.5H9.77ZM3.354.855a.75.75 0 0 1 0 1.5H1.75a.75.75 0 1 1 0-1.5h1.604ZM68.53 28.764a.75.75 0 1 1-1.06 1.061l-1.135-1.134a.75.75 0 0 1 1.06-1.061l1.135 1.134ZM65.127 25.361a.75.75 0 0 1-1.06 1.061l-2.269-2.269a.75.75 0 1 1 1.06-1.06l2.27 2.268ZM60.59 20.824a.75.75 0 0 1-1.06 1.06l-2.27-2.268a.75.75 0 0 1 1.061-1.06l2.269 2.268ZM56.053 16.287a.75.75 0 1 1-1.06 1.06l-2.27-2.268a.75.75 0 0 1 1.061-1.06l2.269 2.268ZM51.515 11.75a.75.75 0 0 1-1.06 1.06l-2.269-2.268a.75.75 0 1 1 1.06-1.061l2.27 2.269ZM46.978 7.212a.75.75 0 1 1-1.06 1.061l-2.27-2.269a.75.75 0 0 1 1.061-1.06l2.27 2.268Z" />
      <path fill="#fff" fillOpacity={step === 'roadmap' ? 0.7 : 0.4} d="M130 77.645a4 4 0 1 0-8 0 4 4 0 0 0 8 0Z" />
      <path fill="#fff" fillOpacity={step === 'roadmap' ? 0.7 : 0.2} d="M157.918 108.645a.737.737 0 0 0-.164-.246l-1.134-1.135a.75.75 0 1 0-1.061 1.061l1.134 1.134a.744.744 0 0 0 .355.199.75.75 0 0 0 .702.487h1.604a.75.75 0 0 0 0-1.5h-1.436ZM162.562 110.145a.75.75 0 0 1 0-1.5h3.209a.75.75 0 1 1 0 1.5h-3.209ZM168.979 110.145a.75.75 0 0 1 0-1.5h3.209a.75.75 0 1 1 0 1.5h-3.209ZM175.396 110.145a.75.75 0 0 1 0-1.5h3.208a.75.75 0 1 1 0 1.5h-3.208ZM181.812 110.145a.75.75 0 0 1 0-1.5h3.209a.75.75 0 1 1 0 1.5h-3.209ZM188.229 110.145a.75.75 0 0 1 0-1.5h3.209a.75.75 0 1 1 0 1.5h-3.209ZM194.646 110.145a.75.75 0 0 1 0-1.5h1.604a.75.75 0 1 1 0 1.5h-1.604ZM129.47 82.236a.75.75 0 0 1 1.06-1.061l1.135 1.134a.75.75 0 1 1-1.061 1.061l-1.134-1.134ZM132.873 85.639a.75.75 0 1 1 1.06-1.061l2.269 2.269a.75.75 0 1 1-1.061 1.06l-2.268-2.268ZM137.41 90.176a.75.75 0 1 1 1.061-1.06l2.268 2.268a.75.75 0 1 1-1.06 1.06l-2.269-2.268ZM141.947 94.713a.75.75 0 1 1 1.061-1.06l2.269 2.268a.75.75 0 0 1-1.061 1.06l-2.269-2.268ZM146.485 99.25a.75.75 0 1 1 1.06-1.06l2.269 2.268a.75.75 0 1 1-1.061 1.061l-2.268-2.269ZM151.022 103.788a.75.75 0 0 1 1.06-1.061l2.269 2.269a.749.749 0 1 1-1.061 1.06l-2.268-2.268Z" />
      <path fill="#fff" fillOpacity={step === 'release' ? 0.7 : 0.4} d="M67 77.645a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
      <path fill="#fff" fillOpacity={step === 'release' ? 0.7 : 0.2} d="M39.082 108.645a.737.737 0 0 1 .164-.246l1.134-1.135a.75.75 0 1 1 1.06 1.061l-1.133 1.134a.74.74 0 0 1-.355.199.751.751 0 0 1-.702.487h-1.604a.75.75 0 0 1 0-1.5h1.436ZM34.438 110.145a.75.75 0 0 0 0-1.5h-3.209a.75.75 0 1 0 0 1.5h3.209ZM28.02 110.145a.75.75 0 0 0 0-1.5h-3.207a.75.75 0 1 0 0 1.5h3.208ZM21.604 110.145a.75.75 0 0 0 0-1.5h-3.208a.75.75 0 1 0 0 1.5h3.208ZM15.188 110.145a.75.75 0 0 0 0-1.5h-3.209a.75.75 0 1 0 0 1.5h3.209ZM8.77 110.145a.75.75 0 0 0 0-1.5H5.564a.75.75 0 1 0 0 1.5H8.77ZM2.354 110.145a.75.75 0 0 0 0-1.5H.75a.75.75 0 1 0 0 1.5h1.604ZM67.53 82.236a.75.75 0 1 0-1.06-1.061l-1.135 1.134a.75.75 0 0 0 1.06 1.061l1.135-1.134ZM64.127 85.639a.75.75 0 0 0-1.06-1.061l-2.269 2.269a.75.75 0 1 0 1.06 1.06l2.27-2.268ZM59.59 90.176a.75.75 0 0 0-1.06-1.06l-2.27 2.268a.75.75 0 0 0 1.061 1.06l2.269-2.268ZM55.053 94.713a.75.75 0 1 0-1.06-1.06l-2.27 2.268a.75.75 0 0 0 1.061 1.06l2.269-2.268ZM50.515 99.25a.75.75 0 0 0-1.06-1.06l-2.269 2.268a.75.75 0 0 0 1.06 1.061l2.27-2.269ZM45.978 103.788a.75.75 0 0 0-1.06-1.061l-2.27 2.269a.75.75 0 1 0 1.061 1.06l2.27-2.268Z" />
      <defs>
        <linearGradient id="lifecycle-feedback" x1="-97.781" x2="197.203" y1="79.179" y2="71.576" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7098" />
          <stop offset="1" stopColor="#A990FF" />
        </linearGradient>
        <linearGradient id="lifecycle-insights" x1="0" x2="196.995" y1="60.965" y2="64.036" gradientUnits="userSpaceOnUse">
          <stop stopColor="#A990FF" />
          <stop offset="1" stopColor="#72BBF5" />
        </linearGradient>
        <linearGradient id="lifecycle-roadmaps" x1="0" x2="196.995" y1="60.965" y2="64.036" gradientUnits="userSpaceOnUse">
          <stop stopColor="#57CFEF" />
          <stop offset="1" stopColor="#7FEA96" />
        </linearGradient>
        <linearGradient id="lifecycle-releases" x1="0" x2="196.995" y1="60.965" y2="64.036" gradientUnits="userSpaceOnUse">
          <stop stopColor="#81EA96" />
          <stop offset="1" stopColor="#FFE975" />
        </linearGradient>
      </defs>
    </svg>
  );
};
