import { Button, typo, ActionButton, ShyScrollbarCss, truncate } from '@cycle-app/ui';
import { Color } from '@cycle-app/ui/theme/baseColors';
import styled from 'styled-components';

import { DocParentDropdown } from 'src/components/DocParentDropdown';
import { EditParentBtnContainer } from 'src/components/DocParentDropdown/DocPanelParent.styles';
import { PortalModal } from 'src/components/PortalModal';

export const StyledPortalModal = styled(PortalModal)`
  display: flex;
  flex-direction: column;
  width: 720px;
  padding: 0;
  border: 0;
`;

export const Header = styled.div`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 24px;
  border-bottom: 1px solid ${p => p.theme.colors.border.primary};
`;

export const CloseButton = styled(ActionButton)`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const ListContainer = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const List = styled.div`
  height: 100%;
  ${ShyScrollbarCss}
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 12px 24px 24px;
  margin-right: 12px;
`;

export const Footer = styled.div`
  border-top: 1px solid ${p => p.theme.colors.border.primary};
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  }
`;

export const ListItem = styled.div<{ $selected: boolean }>`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${p => (p.$selected ? 'hsla(var(--cycle), 0.08)' : p.theme.colors.border.hover)};
  background: ${p => (p.$selected ? 'hsla(var(--cycle), 0.08)' : p.theme.colors.background.secondary)};
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  ${p => p.theme.isDark && `
    border-color: transparent;
    background: ${p.$selected ? 'hsla(var(--cycle500), 0.2)' : Color.Grey850};
  `};
`;

export const SubmitButton = styled(Button)<{ $disabled: boolean }>`
  ${p => p.$disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const SelectButton = styled(Button).attrs({
  size: 'M',
  variant: 'light',
})`
  --bg: transparent;
  font-weight: 500;
  height: 32px;
  padding: 0 12px;
  color: ${p => (p.theme.isDark ? p.theme.colors.text.primary : p.theme.colors.text.blue)};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GroupHeader = styled.div`
  color: ${p => p.theme.colors.text.secondary};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const GroupTitle = styled.div`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const GroupInfo = styled.div`
  margin-left: 8px;
  ${truncate}
`;

export const StyledDocParentDropdown = styled(DocParentDropdown)`
  ${EditParentBtnContainer} {
    height: 100%
  }
`;
