import { AddNewDocAttributeValue, DocChildFragment, DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Emoji, Badge } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { useMemo, VFC } from 'react';

import InputLinkDoc from 'src/components/InputLinkDoc/InputLinkDoc';
import LoadMore from 'src/components/LoadMore/LoadMore';
import { useCustomerDocs } from 'src/hooks/api/queries/customers/useCustomerDocs';
import { useDocChildren } from 'src/hooks/api/useDoc';
import { useDoctype } from 'src/hooks/api/useDocType';
import { isInsight } from 'src/utils/docType.util';
import { SIZE_CUSTOMER_DOCS, SIZE_ITEMS_HIERARCHY } from 'src/utils/pagination.util';

import DocHierarchyItem from '../DocHierarchyItem/DocHierarchyItem';
import { DocHierarchySkeletons } from '../DocHierarchyItem/DocHierarchySkeletons';
import {
  DoctypeInfo,
  DoctypeLabel,
  AddButton,
  Items,
} from './DocHierarchyGroup.styles';
import { DocHierarchyGroupInsightItem } from './DocHierarchyGroupInsightItem';

import type { DocHierarchyGroupInsightItemProps } from './DocHierarchyGroupInsightItem';

export interface DocHierarchyGroupProps {
  parentId?: string;
  doctypeChildren: DoctypeFragment;
  showInput: boolean;
  setInputDoctypeId?: (doctypeId: string | null) => void;
  onDocLinked: (childrenId: string, doctypeId: string, created: boolean, doc?: DocChildFragment) => Promise<void>;
  createChildrenEnabled?: boolean;
  docAttributes: AddNewDocAttributeValue[];
  sourceId?: string;
  customerId?: string;
  filterByCustomer?: boolean;
  skeleton?: JSX.Element;
  onDocRemove?: (doc: DocChildFragment) => void;
  refefresDeleteQueries?: boolean;
  onClickItem?: DocHierarchyGroupInsightItemProps['onClickItem'];
  showTab?: boolean;
}

const DocHierarchyGroup: VFC<DocHierarchyGroupProps> = ({
  parentId,
  doctypeChildren,
  showInput,
  setInputDoctypeId,
  onDocLinked,
  createChildrenEnabled = true,
  docAttributes,
  sourceId,
  customerId,
  filterByCustomer,
  skeleton,
  onDocRemove,
  refefresDeleteQueries,
  onClickItem,
  showTab = true,
}) => {
  const shouldFilterByCustomer = filterByCustomer && !!customerId;

  const docType = useDoctype(doctypeChildren.id);
  const {
    docs: docChildren,
    pageInfo: docChildrenPageInfo,
    isPaginationLoading: isChildrenPaginationLoading,
    loading: isDocChildrenLoading,
    loadMore: loadMoreDocChildren,
    count: docChildrenCount,
  } = useDocChildren({
    doctypeId: doctypeChildren.id,
    docId: parentId,
  }, {
    skip: shouldFilterByCustomer,
  });

  const {
    docs: customerDocs,
    count: docCustomersCount,
    loadMore: loadMoreCustomerDocs,
    pageInfo: docCustomersPageInfo,
    isLoading: isCustomerDocLoading,
    isPaginationLoading: isCustomerPaginationLoading,
  } = useCustomerDocs({
    doctypeType: doctypeChildren.type,
    id: customerId ?? '',
  }, !shouldFilterByCustomer);

  const loadMore = shouldFilterByCustomer ? loadMoreCustomerDocs : loadMoreDocChildren;
  const loading = shouldFilterByCustomer ? isCustomerDocLoading : isDocChildrenLoading;
  const isPaginationLoading = isCustomerPaginationLoading || isChildrenPaginationLoading;
  const pageInfo = shouldFilterByCustomer ? docCustomersPageInfo : docChildrenPageInfo;
  const count = shouldFilterByCustomer ? docCustomersCount : docChildrenCount;

  const docs = useMemo(() => (
    shouldFilterByCustomer ? customerDocs : docChildren), [shouldFilterByCustomer, docChildren, customerDocs]);

  const hasChildren = count > 0;
  const showInputDoc = showInput || hasChildren;

  const docTypesAttributeIds: string[] = docType?.attributeDefinitions.edges.map(({ node }) => node.id) ?? [];

  const inheritedAttributes: AddNewDocAttributeValue[] = docAttributes
    .filter((attr) => docTypesAttributeIds.includes(attr.attributeDefinitionId));

  return (
    <div key={doctypeChildren.id}>
      {showTab && (
        <DoctypeInfo>
          <Emoji emoji={doctypeChildren.emoji} />
          <DoctypeLabel>
            {doctypeChildren.name}
          </DoctypeLabel>
          {hasChildren && (
            <Badge>
              <span>{count}</span>
            </Badge>
          )}
          {!showInputDoc && !loading && createChildrenEnabled && (
            <AddButton
              tooltip={`Add a new ${doctypeChildren.name}`}
              tooltipPlacement="top"
              onClick={e => {
                e.stopPropagation();
                setInputDoctypeId?.(doctypeChildren.id);
              }}
            >
              <AddIcon />
            </AddButton>
          )}
        </DoctypeInfo>
      )}
      <Items>
        {docs.map(doc => (customerId && isInsight(doctypeChildren)
          ? (
            <DocHierarchyGroupInsightItem
              doc={doc}
              key={doc.id}
              onClickItem={onClickItem}
              canUpdateQuote={false}
            />
          )
          : (
            <DocHierarchyItem
              key={doc.id}
              doc={doc}
              parentId={parentId}
              onDocRemove={onDocRemove}
              refefresDeleteQueries={refefresDeleteQueries}
              hideDocCustomer={doc.customer?.id === customerId}
              onClick={onClickItem}
              showDocId
              showStatus
            />
          )))}

        {loading && skeleton}
        {(loading || isPaginationLoading) && !skeleton && (
          <DocHierarchySkeletons
            count={3}
            showDocId
          />
        )}

        {!isPaginationLoading && pageInfo?.hasNextPage && (
          <LoadMore
            onClick={() => loadMore(pageInfo.endCursor ?? '')}
            count={Math.min((count - docs.length), shouldFilterByCustomer ? SIZE_CUSTOMER_DOCS : SIZE_ITEMS_HIERARCHY)}
          />
        )}

        {createChildrenEnabled && !pageInfo?.hasNextPage && showInputDoc && (
          <InputLinkDoc
            autoFocus={!hasChildren}
            label="Add"
            placeholder={`Add a new ${doctypeChildren.name}`}
            hideInput={!hasChildren ? () => setInputDoctypeId?.(null) : undefined}
            onDocLinked={onDocLinked}
            doctype={doctypeChildren}
            parentId={parentId}
            inheritedAttributes={inheritedAttributes}
            sourceId={sourceId}
            customerId={customerId}
            {...shouldFilterByCustomer && {
              docFilter: (node: DocChildFragment) => !node.customer,
            }}
          />
        )}
      </Items>
    </div>
  );
};

export default DocHierarchyGroup;
