import { UpdateProductSupportAccessDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';

import useSafeMutation from '../useSafeMutation';

export const useProductUpdateSupportAccess = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [mutation, { loading: isLoading }] = useSafeMutation(UpdateProductSupportAccessDocument);

  const updateSupportAccess = (allowSupportAccess: boolean) => mutation({
    variables: {
      productId,
      allowSupportAccess,
    },
    optimisticResponse: {
      updateProduct: {
        id: productId,
        allowSupportAccess,
      },
    },
  });

  return {
    updateSupportAccess,
    isLoading,
  };
};
