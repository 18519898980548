import { Button } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { CustomerEmail } from './CustomerEmail';

export const Container = styled.div`
  align-items: center;
  grid-area: details;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mobile} {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const StyledCustomerEmail = styled(CustomerEmail)`
  min-height: 28px;
  cursor: pointer;
`;

export const CustomerEmailButton = styled(Button).attrs({
  size: 'S',
  useUserColor: false,
  variant: 'outlined',
})`
  color: ${p => p.theme.colors.text.primary};
  background: none;
  

  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }
  &:active {
    background-color: ${p => p.theme.colors.background.hover};
    box-shadow: none;
  }
`;
