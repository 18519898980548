import { PusherProvider } from '@harelpls/use-pusher';
import { MotionConfig } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Board from 'src/app/Main/Board/Board';
import DocPanel, { DocPanelFull } from 'src/app/Main/Board/DocPanel/DocPanel';
import { Settings } from 'src/app/Main/Settings';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import { ErrorPage } from 'src/components/ErrorPage/ErrorPage';
import { MobileMobileNavigation } from 'src/components/MobileNavigation';
import PageTitle from 'src/components/PageTitle/PageTitle';
import { ReleasesPage } from 'src/components/ReleasesPage';
import { EmptyStateNotAllowed } from 'src/components/Views/EmptyState/EmptyStateNotAllowed';
import { HomeView } from 'src/components/Views/Home';
import { Inbox, InboxView } from 'src/components/Views/Inbox';
import { Insight, InsightView } from 'src/components/Views/Insight';
import { RequestMakerAccessView } from 'src/components/Views/RequestMakerAccessView';
import { Roadmap, RoadmapView } from 'src/components/Views/Roadmap';
import { PageId, routing } from 'src/constants/routing.constant';
import { BoardConfigContextProvider } from 'src/contexts/boardConfigContext';
import { useIsOnboarding, useIsRoadmapsEnabled, useProductAddOn } from 'src/hooks';
import { useInitialQuery } from 'src/hooks/api/useInitial';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { usePageId } from 'src/hooks/usePageId';
import { useBoardSlug, useProductSlug } from 'src/hooks/usePathParams';
import { useUrl } from 'src/hooks/useUrl';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { pusherConfig } from 'src/utils/pusher.util';
import { isUserOnboarded } from 'src/utils/users.util';

import { ChangelogView } from '../../components/Views/Changelog';
import BoardSkeleton from './Board/BoardSkeleton';
import MainLayout from './MainLayout/MainLayout';
import { MainListeners } from './MainListeners';

const AnalysesPage = lazy(() => import('src/components/AnalysesPage'));

export const Main = () => {
  const productSlug = useProductSlug();
  const boardSlug = useBoardSlug();
  const {
    hasError,
    productSlug: productSlugInitial,
    initialMe,
  } = useInitialQuery();
  const pageId = usePageId();
  const getUrl = useUrl();
  const { isEnabled: isInsightEnabled } = useFeatureFlag('insight-view');
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const { canReadSettings } = useGetPermission();
  const isOnboarding = useIsOnboarding();

  const isReleasesEnabled = useIsReleasesEnabled();
  const { isEnabled: isAnalysesEnabled } = useProductAddOn('ANALYSES');

  const { isEnabled: isInboxEnabled } = useFeatureFlag('inbox');

  // Redirect to current product provided by api
  // if product slug not available in URL
  if (!productSlug && productSlugInitial && pageId !== PageId.Welcome) {
    return (
      <Redirect to={getUrl(PageId.Main, { productSlug: productSlugInitial })} />
    );
  }

  if (
    !isOnboarding &&
    initialMe?.me && (
      !initialMe.productSlug ||
      !isUserOnboarded(initialMe.me)
    )
  ) {
    return <EmptyStateNotAllowed />;
  }

  if (hasError) {
    return <ErrorPage showBackButton />;
  }

  return (
    <>
      <MainListeners />
      <PusherProvider {...pusherConfig()}>
        <MotionConfig reducedMotion="user">
          <MobileMobileNavigation />
          <MainLayout>
            <PageTitle />
            <Switch>
              <Route path={routing[PageId.Board]}>
                <Board />
              </Route>

              {isInboxEnabled && (
                <Route path={routing[PageId.Inbox]}>
                  <Inbox>
                    <Switch>
                      <Route path={routing[PageId.InboxView]}>
                        <InboxView>
                          <Switch>
                            <Route path={routing[PageId.InboxDoc]}>
                              <DocPanel />
                            </Route>
                          </Switch>
                        </InboxView>
                      </Route>
                    </Switch>
                  </Inbox>
                </Route>
              )}

              {isInsightEnabled && (
                <Route path={routing[PageId.Insight]}>
                  <Insight>
                    <Switch>
                      <Route path={routing[PageId.InsightView]}>
                        <InsightView>
                          <Switch>
                            <Route path={routing[PageId.InsightDoc]}>
                              <DocPanel />
                            </Route>
                          </Switch>
                        </InsightView>
                      </Route>
                    </Switch>
                  </Insight>
                </Route>
              )}

              {isRoadmapsEnabled && (
                <Route path={routing[PageId.Roadmap]}>
                  <Roadmap>
                    <Switch>
                      <Route path={routing[PageId.RoadmapView]}>
                        <RoadmapView>
                          <Switch>
                            <Route path={routing[PageId.RoadmapDoc]}>
                              <DocPanel />
                            </Route>
                          </Switch>
                        </RoadmapView>
                      </Route>
                    </Switch>
                  </Roadmap>
                </Route>
              )}

              {isReleasesEnabled && (
                <Route path={routing[PageId.Releases]}>
                  <ReleasesPage />
                </Route>
              )}

              {isAnalysesEnabled && (
                <Route path={routing[PageId.Analyses]}>
                  <Insight>
                    <Switch>
                      <Route path={routing[PageId.AnalysesDoc]}>
                        <DocPanel />
                      </Route>
                    </Switch>
                    <InsightView>
                      <ErrorBoundary>
                        <Suspense fallback={null}>
                          <AnalysesPage />
                        </Suspense>
                      </ErrorBoundary>
                    </InsightView>
                  </Insight>
                </Route>
              )}

              <Route path={routing[PageId.DocFullPage]}>
                <DocPanelFull />
              </Route>
              <Route path={routing[PageId.RequestMakerAccess]} exact>
                <RequestMakerAccessView />
              </Route>
              <Route path={routing[PageId.Changelog]}>
                <ChangelogView />
              </Route>
              <Route path={routing[PageId.Settings]}>
                {
                // canReadSettings was initialized
                canReadSettings !== undefined &&
                  (canReadSettings ? <Settings /> : <Redirect to={getUrl(PageId.Main)} />)
              }
              </Route>
              <Route path={routing[PageId.Welcome]}>
                <Board />
              </Route>
              <Route path={routing[PageId.Main]}>
                <BoardConfigContextProvider>
                  <HomeView>
                    <Switch>
                      <Route path={routing[PageId.HomeDoc]}>
                        <DocPanel />
                      </Route>
                    </Switch>
                  </HomeView>
                </BoardConfigContextProvider>
              </Route>
              <Route>
                {productSlug && !boardSlug
                  ? <Redirect to={getUrl(PageId.Main)} />
                  : <BoardSkeleton />}
              </Route>
            </Switch>
          </MainLayout>
        </MotionConfig>
      </PusherProvider>
    </>
  );
};
