import { ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import { body400, caption400 } from '@cycle-app/ui/theme/typo';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const SlotContainer = styled.div<{ $isSpaced?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${p => (p.$isSpaced ? 'space-between' : 'flex-start')};
  width: 100%;
`;

export const StyledActionButton = styled(ActionButton)`
  height: 24px;
`;

export const ActionMenuContent = styled.div`
  padding: 4px 0;
  flex: 1;
  text-align: left;
`;

export const ActionMenuIcon = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  min-width: 16px;
  color: ${p => p.theme.colors.text.primary};

  svg {
    min-width: 14px;
    min-height: 14px;
  }
`;

export const ActionMenuTextTitle = styled.h3`
  ${body400}
  color: ${p => p.theme.colors.text.primary};
`;

export const ActionMenuTextDescription = styled.p`
  ${caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const SlotActionBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${p => p.theme.colors.text.secondary};
`;

export const HideButton = styled(ActionButton)`
  width: 24px;
  height: 24px;
`;
