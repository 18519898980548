import {
  typo, ActionButton, Input, truncate, textGradient, TEXT_GRADIENT_COLORS,
} from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { Color } from '@cycle-app/ui/theme/baseColors';
import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { PortalModal } from 'src/components/PortalModal';

export const Modal = styled(PortalModal)`
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled.div`
  ${typo.headerLight}
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const Quote = styled.div`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${p => p.theme.colors.border.primary};
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  background: ${p => (p.theme.isDark ? Color.Grey850 : Color.White)};
  width: 100%;
  .ProseMirror {
    padding: 0 !important;
    p {
      line-height: 24px;
    }
  }
`;

export const Content = styled.div`
  padding-left: 12px;
  border-left: 2px solid ${p => p.theme.colors.border.primary};
  word-break: break-word;
  &, * {
    cursor: pointer !important;
  }
  :hover {
    background: ${p => (p.theme.isDark ? darken(0.02, Color.Grey800) : Color.Grey100)};
  }
`;

export const ParentButton = styled.button`
  padding: 0;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
`;

export const ParentOptionsButton = styled(ActionButton)`
  --bgHover: ${p => (p.theme.isDark ? Color.Grey750 : Color.Grey300)};
  --bgActive: ${p => (p.theme.isDark ? lighten(0.03, Color.Grey750) : darken(0.02, Color.Grey300))};
  opacity: 0;
  margin-right: -2px;
`;

export const ParentButtonContainer = styled.div<{ $active: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  height: 24px;
  padding: 0 4px;
  border-radius: 6px;
  cursor: pointer;
  align-self: flex-start;
  max-width: 100%;
  :hover, :has(.force-focus) {
    background: ${p => (p.theme.isDark ? Color.Grey800 : Color.Grey200)};
    ${ParentOptionsButton} {
      opacity: 1;
    }
  }
  :active:not(:has(.options:active)) {
    background: ${p => (p.theme.isDark ? Color.Grey750 : Color.Grey300)};
  }
  ${p => p.$active && `
    background: ${p.theme.isDark ? Color.Grey800 : Color.Grey200};
    ${ParentOptionsButton} {
      opacity: 1;
    }
  `}
`;

export const ParentLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  ${typo.caption500};
  color: ${p => p.theme.colors.text.secondary};
  svg {
    flex: none;
  }
`;

export const ParentText = styled.div<{ $gradient?: boolean }>`
  ${truncate}
  ${p => p.$gradient && textGradient}
`;

export const StyledAddIcon = styled(AddIcon)`
  color: ${TEXT_GRADIENT_COLORS[0]};
`;

export const OptionIcon = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ParentInput = styled(Input)`
  margin: -7px 0 0 -7px;
  input {
    width: 280px;
    height: 32px;
  }
`;
