import { PropertiesFragment, FilterPropertyRuleEdgesFragment } from '@cycle-app/graphql-codegen';
import { AddIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import Filter from 'src/components/FilterRules/Filter/Filter';
import { Layer } from 'src/types/layers.types';

import { useBoardConfigFilters } from '../../hooks/boards/useBoardConfigFilters';
import { Label, FiltersList, AddAnotherFilterBtn, LightButton } from './BoardConfigForm.styles';

interface Props {
  boardConfigId: string;
  boardID?: string;
  disabled?: boolean;
  filterableProperties: PropertiesFragment;
  filterProperties: FilterPropertyRuleEdgesFragment;
  label?: ReactNode;
  onComplete?: VoidFunction;
}
const BoardConfigFormFilters: FC<React.PropsWithChildren<Props>> = ({
  boardConfigId,
  boardID,
  disabled = false,
  filterableProperties: allFilterableProperties,
  filterProperties,
  label,
  onComplete,
}) => {
  const {
    loadingChangeOperator,
    filterableProperties,
    filters,
    onAttributeUpdated,
    onValueAdded,
    onValueRemoved,
    onOperatorUpdated,
    onFilterDeleted,
    addFirstFilterAvailable,
  } = useBoardConfigFilters({
    boardConfigId,
    boardID,
    onComplete,
    allFilterableProperties,
    filterProperties,
  });

  return (
    <>
      {Boolean(label) && <Label>{label}</Label>}

      {filters.length > 0 && (
        <FiltersList>
          {filters.map((filter) => (
            <Filter
              key={filter.id}
              filter={filter}
              dropdownLayer={Layer.DropdownModalZ1}
              filterableProperties={filterableProperties}
              onAttributeUpdated={onAttributeUpdated(filter.id)}
              onValueAdded={onValueAdded(filter.id)}
              onValueRemoved={onValueRemoved(filter.id)}
              onOperatorUpdated={onOperatorUpdated(filter.id)}
              onDeleteFilter={onFilterDeleted(filter.id)}
              loadingChangeOperator={loadingChangeOperator}
              disabled={disabled}
            />
          ))}
        </FiltersList>
      )}

      <LightButton
        onClick={disabled ? undefined : addFirstFilterAvailable}
        disabled={disabled || filterableProperties.length === 0}
        {...filters.length > 0 && {
          as: AddAnotherFilterBtn,
        }}
        iconStart={<AddIcon size={filters.length > 0 ? 10 : 11} />}
      >
        Add filter
      </LightButton>
    </>
  );
};
export default BoardConfigFormFilters;
