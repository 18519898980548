import { FC } from 'react';

import { SvgProps } from '../../components/Svg/Svg.styles';
import freshDesk from './fresh-desk.png';

export const FreshDeskIcon: FC<React.PropsWithChildren<SvgProps>> = ({
  className, size,
}) => (
  <img className={className} width={size || 16} src={freshDesk} alt="FreshDesk" />
);
