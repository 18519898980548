import { HIGHLIGHT_EXTENSION_NAME } from '@cycle-app/editor-extensions';
import { Editor } from '@tiptap/core';
import { format } from 'date-fns';
import groupBy from 'lodash/groupBy';

import {
  BLOCKQUOTE,
  HEADING_TYPE,
  BULLET_LIST_TYPE,
  LIST_ITEM_TYPE,
  PARAGRAPH_TYPE,
  HORIZONTAL_RULE,
} from 'src/constants/editor.constants';
import { MARK_TYPE } from 'src/editorExtensions/HighlightMark/HighlightViewPlugin';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { getNativeEmoji } from 'src/utils/emoji.util';

import type { GeneratedInsightData } from 'src/contexts/docPanelContext';

export const insertSummaryQuotes = (
  editor: Editor,
  insightsData: GeneratedInsightData[],
) => {
  if (insightsData.length === 0) return;

  const date = format(new Date(), 'MMMM d, yyyy - hh:mm a');
  const categories = groupBy(insightsData, 'parentDocTypeId');

  editor
    .chain()
    .focus()
    .insertContentAt(0, [
      {
        type: HEADING_TYPE,
        attrs: {
          level: 3,
        },
        content: [{
          type: 'text',
          text: 'Summary of quotes',
        }],
      },
      ...Object.entries(categories)
        .map(([docTypeId, data]) => {
          const docType = getDocType(docTypeId);
          return [
            {
              type: PARAGRAPH_TYPE,
              content: [{
                type: 'text',
                text: docType ? `${getNativeEmoji(docType.emoji)} ${docType.name}` : 'Category',
                marks: [{
                  type: 'bold',
                }],
              }],
            },
            {
              type: BULLET_LIST_TYPE,
              content: data
                .filter(d => d.isReadyToBeInserted && d.quote)
                .map(d => ({
                  type: LIST_ITEM_TYPE,
                  content: [{
                    type: PARAGRAPH_TYPE,
                    content: [{
                      type: 'text',
                      marks: [{
                        type: HIGHLIGHT_EXTENSION_NAME,
                        attrs: {
                          id: d.blockId,
                          type: MARK_TYPE,
                          isDraft: false,
                          isResolved: null,
                        },
                      }],
                      text: d.quote,
                    }],
                  }],
                })),
            },
          ];
        })
        .flat(),
      {
        type: BLOCKQUOTE,
        content: [{
          type: PARAGRAPH_TYPE,
          content: [{
            type: 'text',
            text: `Created on ${date}`,
          }],
        }],
      },
      { type: PARAGRAPH_TYPE },
      { type: HORIZONTAL_RULE },
    ])
    .createParagraphNear()
    .run();
};
