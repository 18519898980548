// Free plan limits
export const BILLING_LIMIT_ACTIVE_INSIGHTS = 20;

// Pro plan limits
export const BILLING_LIMIT_CUSTOM_DOC_TYPES = 5;

// Other
export const CALENDLY_URL = 'https://calendly.com/james-cycle/30min';
export const CALENDLY_URL_JEFFREY = 'https://calendly.com/jeffrey-cycle/30-min';

export const STRIPE_PORTAL_EXPIRATION_DELAY = 5 * 60_000; // 5 minutes
