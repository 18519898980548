import { boxShadowZ1 } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import DocParent from '../../DocParent/DocParent';

export const StyledLink = styled(Link)`
  display: inline-flex;
`;

export const StyledDocParent = styled(DocParent)`
  ${boxShadowZ1}
  height: 20px;
  background-color: ${p => p.theme.colors.background.primary};
  border: 1px solid ${p => p.theme.colors.border.primary};
  padding-right: 0;
  overflow: hidden;
`;

export const EditIconContainer = styled.div`
  margin-left: 4px;
  padding-left: 4px;
  padding-right: 4px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};

  &:hover {
    background: ${p => p.theme.colors.background.hover};
    color: ${p => p.theme.colors.text.primary};
  }

  > [data-popover] {
    height: 100%;
  }
`;

export const StyledPenFilledIcon = styled(PenFilledIcon)`
  width: 12px;
  height: 12px;
`;
