import { Tag, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const TagAttributeStyled = styled(Tag)`
  ${typo.body500}
  border: none;
  display: inline-flex;
  height: auto;
`;

export const AttributeTagIconContainer = styled.div`
  svg {
    vertical-align: text-top;
  }
`;

export const DocTypeLabel = styled.span`
  font-weight: 500;
  white-space: nowrap;
`;
