import { useEffect, useState } from 'react';

import { AnswerTextContainer } from './Ask.styles';
import { formatAnswer } from './Ask.utils';

type Props = {
  text: string;
  simulateTyping?: boolean;
};

export const AnswerText = ({
  text, simulateTyping = false,
}: Props) => {
  const [stream, setStream] = useState('');

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    if (simulateTyping) {
      const callback = (count = 0) => {
        // Complete the typing if the tab is hidden to avoid sync issues
        if (document.visibilityState === 'hidden') {
          clearInterval(timeoutId);
          setStream(text);
          return;
        }

        if (text[count] === undefined) {
          clearInterval(timeoutId);
          return;
        }
        setStream(prev => prev + text[prev.length]);
        const delay = Math.random() * 40 + 20;
        timeoutId = setTimeout(() => callback(count + 1), delay);
      };
      callback();
    }

    return () => {
      clearInterval(timeoutId);
    };
  }, [text, simulateTyping]);

  return (
    <AnswerTextContainer
      $withCursor={simulateTyping && stream.length < text.length}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: formatAnswer(simulateTyping ? stream : text),
      }}
    />
  );
};
