import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: ${p => (p.theme.isDark ? p.theme.colors.background.primary : p.theme.colors.background.secondary)};
  height: 100%;
`;

export const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
