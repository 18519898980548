import { DoctypeRelativeWithChildrenFragment } from '@cycle-app/graphql-codegen';
import { nodeToArray } from '@cycle-app/utilities';
import { Position } from 'react-flow-renderer';

import { isInsight } from 'src/utils/docType.util';
import { FlowData } from 'src/utils/flow.util';

import { defaultOptions } from './defaultOptions';

interface GetChildrenParams {
  parentDoctype: DoctypeRelativeWithChildrenFragment | undefined;
  level: number;
  parentElementId: string;
  targetPosition?: Position;
  indexColumn?: number;
  asLink?: boolean;
  isLastRank?: boolean;
  sourceAdd?: string;
  iteration?: number;
  rootId?: string;
  data?: FlowData;
}

export const getChildren = ({
  parentDoctype,
  level,
  parentElementId,
  targetPosition,
  indexColumn,
  asLink,
  isLastRank,
  sourceAdd,
  iteration,
  rootId,
  data = {},
}: GetChildrenParams) => {
  const parentDoctypeChildren = parentDoctype
    ? nodeToArray(parentDoctype.children).filter(d => !isInsight(d))
    : [];

  const addChildrenId = `add-children-${parentElementId}`;

  const placeholderAdd = sourceAdd ? [
    {
      ...defaultOptions,
      id: addChildrenId,
      type: 'custom',
      data: {
        asPlaceholder: true,
        indexColumn,
        level: 2,
        target: 'children',
        indexChild: parentDoctypeChildren.length,
        rootId,
        ...data,
      },
      targetPosition: Position.Left,
    },
    {
      id: `link-${addChildrenId}`,
      source: sourceAdd,
      target: addChildrenId,
      type: 'customEdge',
      data: {
        rootId,
        asPlaceholder: true,
        ...data,
      },
    },
  ] : [];

  if (parentDoctypeChildren.length) {
    return [
      ...parentDoctypeChildren.map((doctypeChildren, index) => {
        const childrenElementId = `${doctypeChildren.id}-${level}-${parentElementId}`;

        return [
          {
            ...defaultOptions,
            id: childrenElementId,
            type: 'custom',
            data: {
              doctypeId: doctypeChildren.id,
              isLastRank,
              level,
              indexColumn,
              indexChild: index,
              asLink,
              target: 'children',
              iteration,
              rootId,
              ...data,
            },
            targetPosition,
          },
          {
            id: `link-${childrenElementId}-${parentElementId}`,
            source: parentElementId,
            target: childrenElementId,
            type: 'customEdge',
            data: {
              rootId,
              ...data,
            },
          },
        ];
      }),
      ...placeholderAdd,
    ].flat();
  }

  if (sourceAdd) {
    return placeholderAdd;
  }

  // Phantom elements
  const doctypeChildrenId = crypto.randomUUID();
  const childrenElementId = `${doctypeChildrenId}-${level}-${parentElementId}`;

  return [
    {
      ...defaultOptions,
      id: childrenElementId,
      type: 'custom',
      data: {
        doctypeId: doctypeChildrenId,
        phantom: true,
        level,
        ...data,
      },
      targetPosition,
    },
    {
      id: `link-${childrenElementId}-${parentElementId}`,
      source: parentElementId,
      target: childrenElementId,
      type: 'customEdge',
      data: {
        phantom: true,
        ...data,
      },
    },
  ];
};
