import { Details } from '@tiptap-pro/extension-details';
import DetailsContent from '@tiptap-pro/extension-details-content';
import DetailsSummary from '@tiptap-pro/extension-details-summary';

import { DETAILS_EXTENSION_CLASS_NAME } from '../constants';

import type { Extension } from '@tiptap/core';

type GetDetailsExtensionParams = {
  extensions: Extension[];
};

export const getDetailsExtension = ({ extensions }: GetDetailsExtensionParams) => [
  Details.configure({
    persist: true,
    HTMLAttributes: {
      class: DETAILS_EXTENSION_CLASS_NAME,
    },
  }),
  DetailsSummary,
  DetailsContent,
  ...extensions,
];
