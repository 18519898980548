import { Flex, Button } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { isEqual, omit } from 'lodash';

import { useChangelog } from 'src/hooks/releases/useChangelog';
import { ChangelogBuilderFormData } from 'src/hooks/releases/useChangelogBuilderForm';
import { useChangelogBuilderFormContext } from 'src/hooks/releases/useChangelogBuilderFormContext';
import { useChangelogUpdate } from 'src/hooks/releases/useChangelogUpdate';

export const ChangelogBuilderSubmit = () => {
  const {
    formState, watch, handleSubmit, reset,
  } = useChangelogBuilderFormContext();
  const { changelog } = useChangelog();
  const {
    changelogUpdate, isLoading,
  } = useChangelogUpdate();
  // Content
  const title = watch('title');
  const subtitle = watch('subtitle');
  const socialLinkText = watch('socialLinkText');
  const socialLinkURL = watch('socialLinkURL');
  // Logo
  const logoUrl = watch('logo_url');
  const faviconUrl = watch('favicon_url');
  const shipBadgeColor = watch('shipBadgeColor');
  // Typography
  const typography = watch('typography');
  // Text
  const heroSection = watch('heroSection');
  const contentSection = watch('contentSection');
  // Colors
  const headersColor = watch('headersColor');
  const bodyColor = watch('bodyColor');
  const backgroundColor = watch('backgroundColor');
  const dividerColor = watch('dividerColor');
  const linkColor = watch('linkColor');
  // Release tags
  const releaseTagStyle = watch('releaseTagStyle');
  const releaseTagColor = watch('releaseTagColor');
  const tags = watch('tags');

  const values = {
    title,
    subtitle,
    socialLinkText,
    socialLinkURL,
    logo_url: logoUrl,
    favicon_url: faviconUrl,
    shipBadgeColor,
    typography,
    heroSection,
    contentSection,
    headersColor,
    bodyColor,
    backgroundColor,
    dividerColor,
    linkColor,
    releaseTagStyle,
    releaseTagColor,
    tags,
  };

  const defautValues = omit(formState.defaultValues, 'logo_file', 'favicon_file', 'template');

  const onSubmit = async (data: ChangelogBuilderFormData) => {
    if (!isLoading && changelog?.id) {
      const result = await changelogUpdate({
        id: changelog.id,
        title: data.title,
        subtitle: data.subtitle,
        socialLinkText: data.socialLinkText || '',
        socialLinkURL:
          // eslint-disable-next-line no-nested-ternary
          data.socialLinkURL
            ? isUrl(data.socialLinkURL)
              ? data.socialLinkURL
              : `https://${data.socialLinkURL}`
            : '',
        ...data.logo_file && data.logo_url !== formState.defaultValues?.logo_url && {
          logo: {
            file: data.logo_file,
            url: data.logo_url,
          },
        },
        ...data.favicon_file && data.favicon_url !== formState.defaultValues?.favicon_url && {
          favicon: {
            file: data.favicon_file,
            url: data.favicon_url,
          },
        },
        shipBadgeColor: data.shipBadgeColor,
        typography: data.typography,
        heroSection: data.heroSection,
        contentSection: data.contentSection,
        headersColor: data.headersColor,
        bodyColor: data.bodyColor,
        backgroundColor: data.backgroundColor,
        dividerColor: data.dividerColor,
        linkColor: data.linkColor,
        releaseTagStyle: data.releaseTagStyle,
        releaseTagColor: data.releaseTagColor,
        tags: data.tags,
      });
      if (result?.data?.updateChangelog?.id) {
        const { updateChangelog } = result.data;
        reset({
          template: null,
          title: updateChangelog.title,
          subtitle: updateChangelog.subtitle,
          socialLinkText: updateChangelog.socialLinkText || '',
          socialLinkURL: updateChangelog.socialLinkURL || '',
          logo_url: updateChangelog.logo?.url || '',
          logo_file: null,
          favicon_url: updateChangelog.favicon?.url || '',
          favicon_file: null,
          shipBadgeColor: updateChangelog.shipBadgeColor,
          typography: updateChangelog.typography,
          heroSection: updateChangelog.heroSection,
          contentSection: updateChangelog.contentSection,
          headersColor: updateChangelog.headersColor,
          bodyColor: updateChangelog.bodyColor,
          backgroundColor: updateChangelog.backgroundColor,
          dividerColor: updateChangelog.dividerColor,
          linkColor: updateChangelog.linkColor,
          releaseTagStyle: updateChangelog.releaseTagStyle,
          releaseTagColor: updateChangelog.releaseTagColor,
          tags: updateChangelog.releaseNoteTagValues,
        });
      }
    }
  };

  return (
    !isEqual(defautValues, values) ? (
      <Flex $grow={1}>
        <Flex style={{ marginLeft: 'auto' }} $gap={16} $align="center">
          <Button size="M" variant="outlined-alt" onClick={() => reset()}>Discard</Button>
          <Button isLoading={isLoading} size="M" onClick={handleSubmit(onSubmit)}>Save</Button>
        </Flex>
      </Flex>
    ) : null
  );
};
