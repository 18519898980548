import { HeroSection, ContentSection, Typography } from '@cycle-app/graphql-codegen';
import { Flex, SelectPanel, SelectOption } from '@cycle-app/ui';
import { ReactNode } from 'react';
import { Controller } from 'react-hook-form';

import { useChangelogBuilderFormContext } from '../../hooks/releases/useChangelogBuilderFormContext';
import { ToggleDropdown } from '../DropdownLayer';
import { Section } from './ChangelogBuilderSidebar.styles';
import { Caret, ContentLabel, ContentSelectContainer, StyledButton } from './ChangelogBuilderTypography.styles';
import { LabelWithReset } from './LabelWithReset';

const typoOptions: SelectOption[] = [
  {
    value: Typography.Inter,
    label: 'Inter',
  },
  {
    value: Typography.Montserrat,
    label: 'Montserrat',
  },
  {
    value: Typography.Roboto,
    label: 'Roboto',
  },
  {
    value: Typography.SourceSans_3,
    label: 'Source Sans 3',
  },
  {
    value: Typography.Lora,
    label: 'Lora',
  },
  {
    value: Typography.Merriweather,
    label: 'Merriweather',
  },
  {
    value: Typography.PlayfairDisplay,
    label: 'Playfair Display',
  },
  {
    value: Typography.LibreBaskerville,
    label: 'Libre Baskerville',
  },
  {
    value: Typography.WorkSans,
    label: 'Work Sans',
  },
  {
    value: Typography.Hind,
    label: 'Hind',
  },
  {
    value: Typography.Mulish,
    label: 'Mulish',
  },
  {
    value: Typography.JetbrainsMono,
    label: 'Jetbrains Mono',
  },
  {
    value: Typography.Asap,
    label: 'Asap',
  },
  {
    value: Typography.Sora,
    label: 'Sora',
  },
  {
    value: Typography.Manrope,
    label: 'Manrope',
  },
  {
    value: Typography.DmSans,
    label: 'DM Sans',
  },
].sort((a, b) => a.value.localeCompare(b.value));

const heroOptions: SelectOption[] = [
  {
    value: HeroSection.S,
    label: 'Small',
  },
  {
    value: HeroSection.M,
    label: 'Medium',
  },
  {
    value: HeroSection.L,
    label: 'Large',
  },
  {
    value: HeroSection.Xl,
    label: 'Extra large',
  },
];

const contentOptions: SelectOption[] = [
  {
    value: ContentSection.Classic,
    label: 'Classic',
  },
  {
    value: ContentSection.Discreet,
    label: 'Discreet',
  },
  {
    value: ContentSection.ShowOff,
    label: 'Show-off',
  },
  {
    value: ContentSection.Sober,
    label: 'Sober',
  },
  {
    value: ContentSection.Uniform,
    label: 'Uniform',
  },
  {
    value: ContentSection.Unique,
    label: 'Unique',
  },
];

type SelectProps = {
  children: ReactNode;
  options: SelectOption[];
  onChange: (option: SelectOption) => void;
  value: string;
};

const Select = ({
  value, options, children, onChange,
}: SelectProps) => {
  return (
    <ToggleDropdown
      content={contentProps => (
        <SelectPanel
          options={options}
          selectedValue={value}
          onOptionChange={option => {
            onChange(option);
            contentProps.hide();
          }}
          hideSearch
          style={{ width: `${contentProps.buttonRect?.width}px` }}
        />
      )}
      button={(buttonProps) => (
        <StyledButton
          ref={buttonProps.ref}
          onClick={buttonProps.trigger}
          variant="outlined-alt"
          full
          size="L"
          iconEnd={(
            <Caret
              direction={buttonProps['data-active'] ? 'right' : 'left'}
            />
          )}
        >
          {children}
        </StyledButton>
      )}
    />
  );
};

export const ChangelogBuilderTypography = () => {
  const {
    control, watch, formState, resetField,
  } = useChangelogBuilderFormContext();
  const heroSection = watch('heroSection');
  const typography = watch('typography');
  const contentSection = watch('contentSection');
  const isTextDirty =
    heroSection !== formState.defaultValues?.heroSection ||
    contentSection !== formState.defaultValues?.contentSection;
  return (
    <>
      <Section>
        <LabelWithReset
          label="Typography"
          showReset={typography !== formState.defaultValues?.typography}
          reset={() => resetField('typography')}
        />
        <div style={{ marginTop: '8px' }}>
          <Controller
            control={control}
            name="typography"
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={option => field.onChange(option.value)}
                options={typoOptions}
              >
                {typoOptions.find(o => o.value === field.value)?.label}
              </Select>
            )}
          />
        </div>
      </Section>
      <Section>
        <LabelWithReset
          label="Text"
          showReset={isTextDirty}
          reset={() => {
            resetField('heroSection');
            resetField('contentSection');
          }}
        />
        <div style={{ marginTop: '14px' }}>
          <Flex $justify="space-between">
            <ContentLabel>Hero section</ContentLabel>
            <ContentSelectContainer>
              <Controller
                control={control}
                name="heroSection"
                render={({ field }) => (
                  <Select
                    value={field.value}
                    onChange={option => field.onChange(option.value)}
                    options={heroOptions}
                  >
                    {heroOptions.find(o => o.value === field.value)?.label}
                  </Select>
                )}
              />
            </ContentSelectContainer>
          </Flex>
          <Flex style={{ marginTop: '8px' }} $justify="space-between">
            <ContentLabel>Content</ContentLabel>
            <ContentSelectContainer>
              <Controller
                control={control}
                name="contentSection"
                render={({ field }) => (
                  <Select
                    value={field.value}
                    onChange={option => field.onChange(option.value)}
                    options={contentOptions}
                  >
                    {contentOptions.find(o => o.value === field.value)?.label}
                  </Select>
                )}
              />
            </ContentSelectContainer>
          </Flex>
        </div>
      </Section>
    </>
  );
};
