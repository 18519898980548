import {
  AddBoardConfigSortByPropertyDocument,
  BoardConfigSortByFragment,
  ChangeSortByPropertyOrderDocument,
  Order,
  PropertiesFragment,
  RemoveBoardConfigSortByDocument,
} from '@cycle-app/graphql-codegen';
import { SelectOption } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import { produce } from 'immer';
import { useCallback, useMemo } from 'react';

import { SoonBadge } from 'src/components/SoonBadge';
import { getFilterAttributeOption } from 'src/utils/boardConfig/boardConfig.util';

import useSafeMutation from '../useSafeMutation';

interface Props {
  boardConfigId: string;
  sortByProperty: BoardConfigSortByFragment['sortByProperty'];
  sortableProperties: BoardConfigSortByFragment['sortableProperties'];
  filterableProperties: PropertiesFragment;
}

export const useBoardConfigSortBy = ({
  boardConfigId, sortByProperty, sortableProperties, filterableProperties,
}: Props) => {
  const [addSortByMutation] = useSafeMutation(AddBoardConfigSortByPropertyDocument);
  const [changeSortByPropertyOrderMutation] = useSafeMutation(ChangeSortByPropertyOrderDocument);
  const [removeSortByMutation, removeSortByMutationState] = useSafeMutation(RemoveBoardConfigSortByDocument);

  const properties = useMemo(() => nodeToArray(sortableProperties), [sortableProperties]);

  const sortBy = useMemo(() => {
    if (sortByProperty?.property) {
      const {
        icon, label, value,
      } = getFilterAttributeOption(sortByProperty.property);
      return {
        icon,
        label,
        order: sortByProperty.order,
        value,
      };
    }
    return null;
  }, [sortByProperty]);

  const sortByAdd = useCallback((propertyId: string) => {
    const property = properties.find(sortableProperty => sortableProperty.id === propertyId);
    return property && addSortByMutation({
      variables: {
        boardConfigId,
        propertyId,
      },
      optimisticResponse: {
        addBoardConfigSortByProperty: {
          // __typename is union of SortByPropertyCreatedAt only. Update when we have other sorts.
          __typename: 'SortByPropertyCreatedAt',
          id: 'temp-SortByPropertyCreatedAt',
          order: Order.Asc,
          property,
        },
      },
      update: (cache, { data }) => {
        if (data?.addBoardConfigSortByProperty) {
          cache.modify({
            id: boardConfigId,
            fields: {
              sortByProperty: () => data.addBoardConfigSortByProperty,
              sortableProperties: (current: BoardConfigSortByFragment['sortableProperties'], { readField }) => {
                return produce(current, draft => {
                  // eslint-disable-next-line no-param-reassign
                  draft.edges = draft.edges.filter(edge => readField('id', edge.node) !== propertyId);
                });
              },
            },
          });
        }
      },
    });
  }, [addSortByMutation, boardConfigId, properties]);

  const sortByOrderChange = (order: Order) => sortByProperty?.property && changeSortByPropertyOrderMutation({
    variables: {
      sortByConfigId: sortByProperty.id,
      order,
    },
    optimisticResponse: {
      changeSortByPropertyOrder: {
        ...sortByProperty,
        order,
      },
    },
    update: (cache, { data }) => {
      if (data?.changeSortByPropertyOrder) {
        cache.modify({
          id: boardConfigId,
          fields: {
            sortByProperty: () => data.changeSortByPropertyOrder,
          },
        });
      }
    },
  });

  const sortByRemove = () => removeSortByMutation({
    variables: { boardConfigId },
    optimisticResponse: {
      removeBoardConfigSortBy: {
        id: boardConfigId,
        sortableProperties,
        sortByProperty: null,
      },
    },
  });

  const sortableOptions = useMemo<SelectOption[]>(() => {
    const options = properties
      .filter(sortableProperty => sortableProperty.id !== sortByProperty?.property.id)
      .map(sortableProperty => ({
        ...getFilterAttributeOption(sortableProperty),
        onSelect: () => sortByAdd(sortableProperty.id),
      }));

    const comingSoonOptions = nodeToArray(filterableProperties)
      .map(property => ({
        ...getFilterAttributeOption(property),
        disabled: true,
        end: <SoonBadge />,
      }));

    return [...options, ...comingSoonOptions];
  }, [filterableProperties, properties, sortByAdd, sortByProperty?.property.id]);

  const sortByAddFirstAvailable = () => sortableOptions[0]?.onSelect && sortByAdd(sortableOptions[0].value);

  return {
    sortBy,
    sortByAdd,
    sortByOrderChange,
    sortByRemove,
    sortableOptions,
    sortByAddFirstAvailable,
    isRemoveLoading: removeSortByMutationState.loading,
  };
};
