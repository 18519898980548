import { mobile } from '@cycle-app/utilities';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: inherit;
`;

export const Header = styled.header`
  grid-area: header;
  margin-top: 12px;
`;

export const HeaderContainer = styled.div<{ $isCentered?: boolean }>`
  margin-bottom: 16px;
  align-items: center;
  column-gap: 24px;
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-areas:
    "avatar header"
    "avatar details";

  ${p => p.$isCentered && css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `}

  ${mobile} {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;
