import { IntegrationType } from '@cycle-app/graphql-codegen';
import { SyncIcon, SyncStrikeIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useIntegration, useOptimizedBooleanState } from 'src/hooks';
import { useIntegrationSync } from 'src/hooks/integration/useIntegrationSync';
import { useGetPermission } from 'src/reactives';
import { useGetIntegrationSync } from 'src/reactives/integrationSync.reactive';
import { setIntegrationSyncModal } from 'src/reactives/integrationSyncModal.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { integrationNameTitles } from 'src/utils/integrations.utils';

import DialogModal from '../DialogModal/DialogModal';
import { StyledActionButton } from './IntegrationSyncAction.styles';

export type IntegrationSyncActionProps = {
  className?: string;
  integrationType: IntegrationType;
};

export const IntegrationSyncAction: FC<React.PropsWithChildren<IntegrationSyncActionProps>> = ({
  className, integrationType,
}) => {
  const { isCustomerSynced } = useIntegration({ type: integrationType });
  const integrationSync = useIntegrationSync();
  const [isWarningVisible, {
    setTrueCallback: showWarning, setFalseCallback: hideWarning,
  }] = useOptimizedBooleanState(false);
  const integrations = useGetIntegrationSync();
  const { canReadSettings } = useGetPermission();

  const isSyncing = integrations[integrationType]?.isSyncing;

  // eslint-disable-next-line no-nested-ternary
  const tooltip = isSyncing
    ? 'Customer sync in progress'
    : isCustomerSynced
      ? 'Disable customer sync'
      : 'Enable customer sync';

  const title = integrationNameTitles[integrationType];
  return (
    <>
      <StyledActionButton
        className={className}
        tooltip={tooltip}
        tooltipPlacement="top"
        isLoading={isSyncing}
        disabled={isSyncing}
        size="L"
        onClick={() => {
          if (!canReadSettings) {
            setLimitationsModal({ action: 'SETTINGS_READ' });
            return;
          }
          if (isCustomerSynced) {
            showWarning();
          } else {
            setIntegrationSyncModal({ integrationType });
          }
        }}
      >
        {isCustomerSynced ? <SyncStrikeIcon /> : <SyncIcon />}
      </StyledActionButton>
      {isWarningVisible && (
        <DialogModal
          autoHide={false}
          title="Disable customer sync"
          info={`Are you sure you want to disable ${title} customer sync? All the ${title} customers without feedback will be removed from Cycle.`}
          onCancel={hideWarning}
          onConfirm={async () => {
            await integrationSync.customerUnsync(integrationType);
            hideWarning();
          }}
          loading={integrationSync.isLoading}
          hide={hideWarning}
          confirmLabel="Disable"
        />
      )}
    </>
  );
};
