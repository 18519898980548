import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const PenIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.42578 3.11621C9.88434 2.65764 10.5063 2.40002 11.1548 2.40002C11.8033 2.40002 12.4253 2.65764 12.8838 3.11621C13.3424 3.57478 13.6 4.19673 13.6 4.84524C13.6 5.49375 13.3424 6.1157 12.8838 6.57427L6.03382 13.4243C5.9213 13.5368 5.76868 13.6 5.60955 13.6H3.00002C2.66865 13.6 2.40002 13.3314 2.40002 13V10.3905C2.40002 10.2314 2.46324 10.0788 2.57576 9.96623L9.42578 3.11621ZM11.1548 3.60002C10.8246 3.60002 10.5078 3.73122 10.2743 3.96474L3.60002 10.639V12.4H5.36103L12.0353 5.72574C12.2688 5.49222 12.4 5.17549 12.4 4.84524C12.4 4.51499 12.2688 4.19826 12.0353 3.96474C11.8018 3.73122 11.4851 3.60002 11.1548 3.60002Z" fill="#242424" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.77339 3.76863C9.00771 3.53432 9.38761 3.53432 9.62192 3.76863L12.2315 6.37816C12.4658 6.61248 12.4658 6.99238 12.2315 7.22669C11.9971 7.461 11.6172 7.461 11.3829 7.22669L8.77339 4.61716C8.53908 4.38284 8.53908 4.00295 8.77339 3.76863Z" fill="#242424" />
  </Svg>
);
