import { ComponentProps, forwardRef } from 'react';

import askIcon from './ask-icon.png';

type Props = ComponentProps<'img'> & {
  size?: number;
};

export const AskIcon = forwardRef<HTMLImageElement, Props>(({
  size = 16,
  style,
  ...props
}, ref) => (
  <img
    ref={ref}
    src={askIcon}
    alt=""
    width={size}
    height={size}
    style={{
      filter: 'drop-shadow(0px 1.75px 1.49px #7895DF47)',
      ...style,
    }}
    {...props}
  />
));
