import { CompanyFragment } from '@cycle-app/graphql-codegen/generated';

import { CompanyDomain } from 'src/components/CompanyDomain';

import { CompanyName } from './CompanyName';
import { StyledHeader } from './CompanyProfile.styles';

export interface CustomerProfileHeaderProps {
  className?: string;
  company: CompanyFragment;
}

export const CompanyProfileHeader = ({
  className, company,
}: CustomerProfileHeaderProps) => (
  <StyledHeader className={className}>
    <CompanyName company={company} />
    {!company.isDefault && (
      <CompanyDomain
        companyId={company.id}
        name={company.name}
        domain={company.domain}
        logo={company.logo}
      />
    )}
  </StyledHeader>
);
