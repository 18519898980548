import { QuoteFragment } from '@cycle-app/graphql-codegen';
import { CheckboxInput, Tooltip } from '@cycle-app/ui';
import { HTMLMotionProps, motion } from 'framer-motion';
import { ComponentProps, useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { QuoteContentEditable } from 'src/components/QuotesSummary/QuoteContentEditable';
import { QuoteParentDropdown } from 'src/components/QuotesSummary/QuoteParentDropdown';
import { Quote } from 'src/components/QuotesSummary/VerifyQuoteModal.styles';

import { ListItem } from './VerifyQuotes.styles';

type Props = Pick<ComponentProps<'div'>, 'onClick' | 'onMouseEnter' | 'onMouseLeave'> & {
  quote: QuoteFragment;
  selected: boolean;
};

export const QuoteListItem = ({
  quote, selected, ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <AnimatedListItem
      onAnimationComplete={definition => {
        if (!ref.current || definition === 'hidden') return;
        scrollIntoView(ref.current, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
        });
      }}
    >
      <ListItem
        ref={ref}
        $selected={selected}
        {...props}
      >
        <Tooltip
          content="Unselected AI-generated quotes will be discarded"
          withPortal
          placement="top"
        >
          <CheckboxInput
            id={`quote-${quote.id}`}
            checked={selected}
            onClick={e => e.stopPropagation()}
            onChange={() => {}}
          />
        </Tooltip>

        <Quote onClick={e => e.stopPropagation()}>
          <QuoteParentDropdown quote={quote} />
          <QuoteContentEditable quote={quote} />
        </Quote>
      </ListItem>
    </AnimatedListItem>
  );
};

export const AnimatedListItem = (props: HTMLMotionProps<'div'>) => {
  return (
    <motion.div
      variants={{
        hidden: {
          opacity: 0,
          height: 0,
        },
        visible: {
          opacity: 1,
          height: 'auto',
        },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      style={{
        overflow: 'hidden',
      }}
      {...props}
    />
  );
};
