import {
  UpdateReleaseNoteDocument, UpdateReleaseNoteMutationVariables, ReleaseNoteBaseFragment,
  UpdateReleaseNoteGroupDocument,
} from '@cycle-app/graphql-codegen';

import useSafeMutation from 'src/hooks/useSafeMutation';

import { useReleaseNotesCache } from './useReleaseNotesCache';

export const useUpdateReleaseNote = () => {
  const releaseCache = useReleaseNotesCache();
  const [mutate, { loading }] = useSafeMutation(UpdateReleaseNoteDocument, {
    errorPolicy: 'all',
  });

  const updateReleaseNote = (
    releaseNote: Omit<ReleaseNoteBaseFragment, 'position'>,
    variables: Partial<UpdateReleaseNoteMutationVariables>,
  ) => mutate({
    variables: {
      id: releaseNote.id,
      ...variables,
    },
    optimisticResponse: {
      updateReleaseNote: {
        ...releaseNote,
        title: variables.title ?? releaseNote.title,
        isOther: variables.isOther ?? releaseNote.isOther,
        isPublished: variables.isPublished ?? releaseNote.isPublished,
        release: {
          id: variables.releaseId ?? releaseNote.release.id,
        },
      },
    },
    update: () => {
      if (variables.releaseId !== releaseNote.release.id) {
        const note = {
          // Add position to make the data compliant with the fragment in the releases view.
          position: 0,
          ...releaseNote,
        };
        const isOther = variables.isOther ?? releaseNote.isOther;
        const mainReleaseNotes = isOther ? [] : [note];
        const otherReleaseNotes = isOther ? [note] : [];
        if (variables.releaseId) {
          // Add to new release
          releaseCache.prenpendReleaseNotes({
            releaseId: variables.releaseId,
            mainReleaseNotes,
            otherReleaseNotes,
          });
        }
        if (releaseNote.release.id) {
          // Remove from current release
          releaseCache.removeReleaseNotes({
            releaseId: releaseNote.release.id,
            mainReleaseNotes,
            otherReleaseNotes,
          });
        }
      }
    },
  });

  return {
    updateReleaseNote,
    isUpdatingReleaseNote: loading,
  };
};

export const useUpdateReleaseNoteGroup = () => {
  const [mutate, { loading }] = useSafeMutation(UpdateReleaseNoteGroupDocument, {
    errorPolicy: 'all',
  });

  const updateReleaseNoteGroup = (id: string, isOther:boolean) => mutate({
    variables: {
      id,
      isOther,
    },
  });

  return {
    updateReleaseNoteGroup,
    isUpdatingReleaseNoteGroup: loading,
  };
};
