import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const VolumeMutedIcon = ({ className = '' }: Props) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="mute, sound off">
      <g id="Icon">
        <path d="M11.3323 3.72529V3.00151C11.3323 2.17772 10.3918 1.70748 9.73281 2.20175L6.62264 4.53438C6.50728 4.6209 6.36696 4.66768 6.22276 4.66768H5.33414C4.22991 4.66768 3.33475 5.56283 3.33475 6.66707V9.33293C3.33475 9.98463 3.64654 10.5635 4.1291 10.9285L2.19702 12.8606C1.93675 13.1209 1.93675 13.5428 2.19702 13.8031C2.45729 14.0634 2.87927 14.0634 3.13954 13.8031L13.803 3.13968C14.0633 2.87941 14.0633 2.45743 13.803 2.19716C13.5427 1.93688 13.1207 1.93688 12.8605 2.19716L11.3323 3.72529Z" />
        <path d="M7.04519 11.7825L11.3323 7.4954V12.9985C11.3323 13.8223 10.3918 14.2925 9.73281 13.7982L7.04519 11.7825Z" />
      </g>
    </g>
  </Svg>
);
