import useDoctypesMutations from 'src/hooks/api/mutations/useDoctypesMutations';
import { getDocType } from 'src/reactives/docTypes.reactive';

import SettingsDocTypeProperties from './SettingsDocTypeProperties/SettingsDocTypeProperties';

export const DocTypeProperties = ({
  doctypeId, isDraft,
}: {
  doctypeId: string;
  isDraft?: boolean;
}) => {
  const doctype = getDocType(doctypeId);
  const {
    addDoctypeAttribute,
    addDoctypeNewAttribute,
    removeDoctypeAttribute,
  } = useDoctypesMutations(doctypeId);
  if (!doctype) return null;
  return (
    <SettingsDocTypeProperties
      disabled={isDraft}
      doctype={doctype}
      onAddProperty={addDoctypeAttribute}
      onAddNewProperty={addDoctypeNewAttribute}
      onRemoveProperty={removeDoctypeAttribute}
    />
  );
};
