import { BillingPlan } from '@cycle-app/graphql-codegen';
import {
  ActivityIcon,
  AiExtendIcon,
  AiIcon,
  AiTextIcon,
  BubbleIcon,
  BulbLight,
  CodeBorderIcon,
  CursorIcon,
  DiamondIcon,
  FileDownloadIcon,
  GearIcon,
  GiftIcon,
  InboxIcon,
  LayerIcon,
  LayoutIcon,
  LockIcon,
  NoteIcon,
  PhoneIcon,
  ReleaseNoteIcon,
  RescueRing,
  RoadmapIcon,
  ShapesIcon,
  SlackIcon,
  TagIcon,
  UnionBoxIcon,
  UsersIcon,
} from '@cycle-app/ui/icons';
import { ReactNode, useState } from 'react';

import { mapAddOnsData } from 'src/constants/addOn.constants';
import { useProductAddOn } from 'src/hooks';
import { setLimitationsModal } from 'src/reactives';

import { EXTRA_CARD_ID } from './BillingUpdateFeatures.constants';
import {
  Container,
  Card,
  CardTitle,
  CardText,
  CardNewBadge,
  CardIcons,
  AddOnButton,
  AddIconStyled,
  CheckIconStyled,
  IconContainer,
  LogoContainer,
  Dropdown,
} from './BillingUpdateFeatures.styles';

import type { AddOn } from 'src/types/addOn.types';

type AddOnReference = AddOn['name'] | AddOn['name'][];

type Feature = {
  id: string;
  icon: ReactNode;
  title: string;
  text: string;
  isNew?: boolean;
  addOn?: AddOnReference;
};

const proFeatures: Feature[] = [
  {
    id: 'feature-sources',
    icon: <InboxIcon />,
    title: 'Centralize your feedback',
    text: 'Capture feedback from Intercom, Email, Google Chrome and thousands of other sources with Zapier (survey results, recordings, sheets, etc)',
  },
  {
    id: 'feature-insight',
    icon: <BulbLight />,
    title: 'Extract insights',
    text: 'Extract insights & link them to your product roadmap to get the full context when writing your PRDs and close the loops.',
  },
  {
    id: 'feature-roadmap',
    icon: <RoadmapIcon />,
    title: 'Advanced roadmaps',
    text: 'Pick your favorite hierarchy & create multi-level roadmaps.',
  },
  {
    id: 'feature-release',
    icon: <NoteIcon />,
    title: 'Manage your releases',
    text: 'Write your product marketing content in context and close the loop with stakeholders who gave feedback.',
  },
  {
    id: 'feature-collab',
    icon: <CursorIcon />,
    title: 'Real-time collaboration',
    text: 'Work together in real-time, @mention teammates, and communicate through in-line comments.',
  },
  {
    id: 'feature-users',
    icon: <UsersIcon />,
    title: 'Unlimited free collaborators',
    text: 'Invite sales, customer success, design & engineering teams for free.',
  },
  {
    id: 'feature-api',
    icon: <UnionBoxIcon />,
    title: 'Public API',
    text: 'Query your Cycle docs and create custom integrations & automation.',
  },
  {
    id: 'feature-inte',
    icon: <CodeBorderIcon />,
    title: 'Integrate with collaboration tools',
    text: 'Embed Notion, Linear, GitHub, Figma & Miro doc.',
  },
  {
    id: 'feature-transcript',
    icon: <AiExtendIcon />,
    title: 'Unlimited transcripts',
    text: 'Turn call recordings or images into text to make them actionable for the product team.',
  },
  {
    id: EXTRA_CARD_ID,
    icon: <BubbleIcon />,
    title: 'Premium support',
    text: 'Shared Slack channel with us, max 12h response time, Cycle swag, personalized best practices & more.',
  },
];

const businessPlusFeatures: Feature[] = [
  {
    id: 'feature-integrations',
    icon: <DiamondIcon width="18" />,
    title: 'Premium integrations',
    addOn: ['slack-advanced', 'HUBSPOT', 'SALESFORCE', 'microsoft-teams', 'jira', 'confluence', 'attio', 'plan-hat', 'zendesk', 'fresh-desk', 'vitally', 'grain-sight', 'snow-flake'],
    text: "Install one of Cycle's premium integrations: HubSpot, Salesforce, Microsoft Teams, Jira, Zendesk, Confluence & more.",
  },
  {
    id: 'feature-tools',
    icon: <LockIcon width="18" height="18" />,
    title: 'Admin tools & private teams',
    addOn: 'admin-tools',
    text: 'Control who are the admins and let them restrict access to private teams',
  },
  {
    id: 'feature-attrs',
    icon: <TagIcon width="18" height="18" />,
    title: 'Customer attributes',
    addOn: 'CUSTOMER_ATTRIBUTES',
    text: 'Add attributes to your customers, sync them with your sources, and use them for prioritization',
  },
  {
    id: 'feature-ai',
    icon: <AiTextIcon width="18" height="18" />,
    title: mapAddOnsData.UNLIMITED_AI_QUERIES.name,
    addOn: 'UNLIMITED_AI_QUERIES',
    text: mapAddOnsData.UNLIMITED_AI_QUERIES.description,
  },
  {
    id: 'feature-log',
    icon: <ActivityIcon width="18" height="18" />,
    title: mapAddOnsData['activity-log'].name,
    addOn: 'activity-log',
    text: mapAddOnsData['activity-log'].description,
  },
  {
    id: 'feature-sso',
    icon: <CodeBorderIcon />,
    title: 'SAML-based SSO',
    addOn: 'saml',
    text: 'Scale administration with secure Single Sign On',
  },
  {
    id: 'feature-slack',
    icon: <SlackIcon fill />,
    title: mapAddOnsData['slack-advanced'].name,
    addOn: 'slack-advanced',
    text: mapAddOnsData['slack-advanced'].description,
  },
  {
    id: 'feature-views',
    icon: mapAddOnsData.CUSTOM_SECTIONS.logo,
    title: mapAddOnsData.CUSTOM_SECTIONS.name,
    addOn: 'CUSTOM_SECTIONS',
    text: mapAddOnsData.CUSTOM_SECTIONS.description,
  },
  {
    id: 'feature-ws',
    icon: <LayerIcon />,
    title: mapAddOnsData['unlimited-workspaces'].name,
    addOn: 'unlimited-workspaces',
    text: mapAddOnsData['unlimited-workspaces'].description,
  },
  {
    id: 'feature-doctype',
    icon: <ShapesIcon size={20} />,
    title: 'Unlimited doc types',
    addOn: 'UNLIMITED_DOCTYPES',
    text: 'Drop the doc types limit of 5 and create as many doc types as you want with unlimited flexibility.',
  },
  {
    id: 'feature-properties',
    icon: <TagIcon width="18" height="18" />,
    title: 'Unlimited properties',
    addOn: 'UNLIMITED_PROPERTIES',
    text: 'Drop the limit of 15 and create unlimited custom properties for advanced tagging, clustering & prioritization.',
  },
  {
    id: 'feature-service',
    icon: <RescueRing width="18" height="18" />,
    title: "The best service you've ever had",
    text: 'CSM pack, personalized trainings, custom invoicing, usage reports, ROI cases & more',
  },
  {
    id: 'feature-meeting-recording',
    icon: <PhoneIcon width="20" height="20" />,
    addOn: 'MEETINGS',
    title: mapAddOnsData.MEETINGS.name,
    text: 'Use Cycle to record & transcribe meetings from Zoom, Meet & Teams.',
  },
];

const makerFeatures: Feature[] = [
  {
    id: 'feature-process-feedback',
    icon: <InboxIcon />,
    title: 'Process feedback & create insights',
    text: 'Extract insights from your feedback documents and mark them as processed',
  },
  {
    id: 'feature-settings',
    icon: <GearIcon />,
    title: 'Workspace settings',
    text: 'Manage properties, workflows, content templates, integrations & more',
  },
  {
    id: 'feature-releases',
    icon: <NoteIcon />,
    title: 'Releases',
    text: 'Create releases, write release notes, and publish change log',
  },
  {
    id: 'feature-views',
    icon: <LayoutIcon width="14" />,
    title: 'Views',
    text: 'Create, edit & duplicate any view in feedback, insights & roadmaps sections',
  },
  {
    id: 'feature-ai',
    icon: <AiIcon noFill />,
    title: 'AI commands',
    isNew: true,
    text: 'Use AI to automatically find insights in feedback and features or write PRDs',
  },
  {
    id: 'feature-transcript',
    icon: <ReleaseNoteIcon />,
    title: 'Transcripts',
    isNew: true,
    text: 'Use AI to turn video, audio, or image files into text.',
  },
  {
    id: 'feature-csv',
    icon: <FileDownloadIcon width="14" />,
    title: 'CSV exports',
    text: 'Export any view in CSV to perform analyses of your choice',
  },
  {
    id: 'feature-swag',
    icon: <GiftIcon />,
    title: 'Cycle swag',
    text: 'Stand out from the crowd with some (really) cool Cycle swag',
  },
];

type BillingUpdateFeaturesProps = {
  plan: BillingPlan | 'maker';
};

export const BillingUpdateFeatures = ({ plan }: BillingUpdateFeaturesProps) => {
  const features = plan === BillingPlan.Standard ? proFeatures : businessPlusFeatures;
  const featuresToShow = plan === 'maker' ? makerFeatures : features;

  return (
    <Container>
      {featuresToShow.map(f => <FeatureCard key={f.id} feature={f} />)}
    </Container>
  );
};

const FeatureCard = ({ feature }: { feature: Feature }) => {
  return (
    <Card $id={feature.id}>
      <CardIcons>
        {feature.icon}
        {!!feature.addOn && <FeatureCardAddOn addOn={feature.addOn} />}
      </CardIcons>
      <CardTitle>
        {feature.title}
        {feature.isNew && <CardNewBadge>New</CardNewBadge>}
      </CardTitle>
      <CardText>{feature.text}</CardText>
    </Card>
  );
};

const FeatureCardAddOn = ({ addOn }: { addOn: AddOnReference }) => {
  if (Array.isArray(addOn)) return <FeatureCardAddOnArray addOns={addOn} />;

  return <FeatureCardAddOnItem addOn={addOn} />;
};

const FeatureCardAddOnItem = ({ addOn }: { addOn: AddOn['name'] }) => {
  const addOnValue = useProductAddOn(addOn, { enableForSuperAdmin: false });
  const icon = addOnValue.isEnabled ? <CheckIconStyled /> : <AddIconStyled />;
  const label = addOnValue.isEnabled ? 'Active as add-on' : 'Available as add-on';
  return (
    <AddOnButton
      $isActive={addOnValue.isEnabled}
      onClick={() => {
        setLimitationsModal({
          action: 'USE_ADD_ON',
          brand: addOn,
        });
      }}
    >
      <IconContainer $isActive={addOnValue.isEnabled}>
        {icon}
      </IconContainer>
      {label}
    </AddOnButton>
  );
};

const FeatureCardAddOnArray = ({ addOns }: { addOns: AddOn['name'][] }) => {
  const [isVisible, setIsVisible] = useState(false);
  const options = addOns.map(a => ({
    label: a === 'slack-advanced' ? 'Slack' : mapAddOnsData[a].name,
    value: a,
    icon: (<LogoContainer>{mapAddOnsData[a].logo}</LogoContainer>),
  }));
  return (
    <Dropdown
      options={options}
      placement="bottom-end"
      hideSearch
      visible={isVisible}
      hide={() => setIsVisible(false)}
      onChange={(option) => {
        setLimitationsModal({
          action: 'USE_ADD_ON',
          brand: option.value as AddOn['name'],
        });
        setIsVisible(false);
      }}
    >
      <AddOnButton
        $isActive={false}
        onClick={() => setIsVisible(true)}
        forceFocus={isVisible}
      >
        <IconContainer $isActive={false}>
          <AddIconStyled />
        </IconContainer>
        Available as add-on
      </AddOnButton>
    </Dropdown>
  );
};
