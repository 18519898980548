import { GithubIssueStatus, getColorByGithubStatus, capitalize } from '@cycle-app/utilities';
import { FC } from 'react';

import { StatusBullet } from '../StatusBullet/StatusBullet';
import { Tooltip } from '../Tooltip/Tooltip';
import { Container } from './GithubStatus.styles';

export type GithubStatusProps = {
  status: GithubIssueStatus;
};

export const GithubStatus: FC<React.PropsWithChildren<GithubStatusProps>> = ({ status = 'open' }) => (
  <Container>
    <Tooltip content={capitalize(status)} placement="top">
      <StatusBullet color={getColorByGithubStatus(status)} />
    </Tooltip>
  </Container>
);
