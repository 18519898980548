import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { useHotkeys } from '@cycle-app/utilities';
import { useEditor, EditorContent } from '@tiptap/react';
import { FC, memo } from 'react';
import { useTheme } from 'styled-components';

import Bubble from 'src/components/Editor/Bubble/Bubble';
import { Content } from 'src/components/Editor/Editors/Editor.styles';
import { EditorContextProvider } from 'src/contexts/editorContext';
import { fullEditorExtensions } from 'src/editorExtensions/editorExtensions';
import { useProduct } from 'src/hooks';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { ActionId, templateDisabledActions } from 'src/services/editor/editorActions';
import { reorderProsemirrorPlugins } from 'src/utils/editor/editor.utils';
import { clearColors } from 'src/utils/html.utils';

import { EditorBubbleContainer } from './EditorBubbleContainer';
import { EditorQuickActions } from './EditorQuickActions';
import { useEditorTemplate } from './useEditorTemplate';

const disabledActions = [
  ...templateDisabledActions,
  ActionId.TurnTextIntoGithubIssueMention,
  ActionId.TurnTextIntoLinearMention,
  ActionId.Notion,
  ActionId.Linear,
  ActionId.GithubIssue,
  ActionId.Image,
  ActionId.File,
  ActionId.Embed,
];

export type BasicEditorProps = {
  className?: string;
  docType?: DoctypeFragment;
  onUpdate?: (p: { html?: string; json?: string; text?: string }) => void;
  emptyPlaceholder?: string;
  initialValue?: string;
  hideQuickActions?: boolean;
};

/**
 * Editor with only text formatting and docType template features
 */
export const BasicEditor: FC<React.PropsWithChildren<BasicEditorProps>> = memo(({
  className,
  onUpdate,
  emptyPlaceholder,
  docType,
  initialValue,
  hideQuickActions,
}) => {
  const theme = useTheme();
  const { isEnabled: isDnDEnabled } = useFeatureFlag('dnd-editor-node');
  const editor = useEditor({
    immediatelyRender: true,
    content: initialValue,
    onCreate: params => reorderProsemirrorPlugins(params.editor),
    onUpdate: params => {
      onUpdate?.({
        json: JSON.stringify(params.editor.getJSON()),
      });
    },
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
      transformPastedHTML: clearColors,
    },
    extensions: fullEditorExtensions({
      disabledActions: [
        ...templateDisabledActions,
      ],
      userColor: theme.userColors.main,
      emptyPlaceholder,
      isDnDEnabled,
    }),
  }, []);

  useHotkeys('tab', e => {
    if (editor?.isFocused) e.preventDefault();
  });

  const {
    applyTemplate,
    previewTemplateModal,
    openPreviewTemplateModal,
    closePreviewTemplateModal,
  } = useEditorTemplate({
    editor,
    docType,
    onUpdate,
  });

  const { product } = useProduct();

  if (!editor) return null;

  return (
    <EditorContextProvider
      editor={editor}
      disabledActions={disabledActions}
      nbAiQueries={product?.nbAiQueries}
    >
      <EditorBubbleContainer className={className}>
        {onMouseDown => (
          <>
            <Bubble disabledActions={disabledActions} />
            <Content
              className="content-editor"
              onMouseDown={onMouseDown}
            >
              <EditorContent editor={editor} />
              {editor.isEmpty && !hideQuickActions && (
                <EditorQuickActions
                  applyTemplate={applyTemplate}
                  disabledActions={disabledActions}
                  docType={docType}
                  onHidePreviewTemplate={closePreviewTemplateModal}
                  onShowPreviewTemplate={openPreviewTemplateModal}
                />
              )}
            </Content>
          </>
        )}
      </EditorBubbleContainer>

      {previewTemplateModal}
    </EditorContextProvider>
  );
});
