import { useApolloClient, useQuery } from '@apollo/client';
import { NotificationTriggersDocument } from '@cycle-app/graphql-codegen';
import { useEffect } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { extract } from 'src/types/graphql.types';

export const useNotificationTriggers = () => {
  const { productId } = useWorkspaceContext();
  const query = useQuery(NotificationTriggersDocument, {
    variables: {
      productId,
    },
  });
  return {
    ...query,
    notificationTriggers: extract('Product', query.data?.node)?.notificationTriggers,
  };
};

export const usePrefetchNotificationTriggers = () => {
  const client = useApolloClient();
  const { productId } = useWorkspaceContext();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    client.query({
      query: NotificationTriggersDocument,
      variables: {
        productId,
      },
    });
  }, [client, productId]);
};
