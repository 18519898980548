import { Shortcut, ShyScrollbarCss, typo } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import { CreateDocIconContainer } from 'src/app/Main/Sidebar/SidebarActions/SidebarActions.styles';

import LoadMore from '../LoadMore/LoadMore';

export const StyledCreateDocIconContainer = styled(CreateDocIconContainer)`
  padding: 0px;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 680px;
  max-width: 100%;
  height: 510px;
  border-radius: 12px;
  border: ${p => p.theme.colors.commandbar.borderFullProp};
  color: ${p => p.theme.colors.commandbar.color};
  background: ${p => p.theme.colors.commandbar.bg};
  backdrop-filter: ${p => p.theme.colors.commandbar.bgFilter};

  /* Safari issue: do not use border-radius and outline simultaneously */
  box-shadow: ${p => p.theme.colors.commandbar.shadow};

  ${mobile} {
    height: 100%;
    width: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none;
    outline: none;
    backdrop-filter: none;
    background-color: ${p => p.theme.colors.home.card.bg};
  }
`;

export const Categories = styled.div`
  padding-bottom: 16px;
  ${ShyScrollbarCss};
`;

export const Category = styled.div`
  margin-top: 12px;
`;

export const CategoryName = styled.div`
  top: 0;
  z-index: 1;
  color: ${p => p.theme.colors.commandbar.category.color};
  padding: 8px 24px;
  ${typo.caption500}
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
`;

export const ShortcutStyled = styled(Shortcut)`
  margin-left: auto;
  opacity: 0.5;
`;

export const NoResult = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 32px;
  font-size: 18px;
  font-weight: 500;
`;

export const QuickFilters = styled.div`
  flex: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 48px;
  padding: 0 16px;
  background-color: ${p => p.theme.colors.commandbar.input.bg};
  border-bottom: 1px solid ${p => p.theme.colors.border.surprisingGreyDark};
`;

export const StyledLoadMore = styled(LoadMore)`
  margin-top: 4px;
  --bgHover: ${p => p.theme.colors.commandbar.action.bgHover};
  --bgActive: ${p => p.theme.colors.commandbar.action.bgHover};
  --colorHover: ${p => p.theme.colors.commandbar.action.colorHover};
  :focus:not(:hover) {
    background-color: unset;
  }
`;
