import { ColorPicker, ShyScrollbarCss, SelectPanel, TextButton, typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PropertyLine = styled.div`
  display: flex;
  align-items: center;
  height: 48px;

  gap: 4px;

  padding: 0 24px 0 0;
`;

export const StyledColorPicker = styled(ColorPicker)`
  height: 11px;
  width: 11px;
`;

export const PropertyIcon = styled.span`
  display: flex;
  margin-left: 16px;
`;

export const PropertyName = styled.span`
  flex: 1;
`;

export const AddNewLine = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;

  & svg {
    cursor: pointer;
  }
`;

export const StyledSelectPanel = styled(SelectPanel)`
  ${ShyScrollbarCss};
  max-height: 49vh;
`;

export const NameButton = styled(TextButton)`
  ${typo.body400}
  color: ${p => p.theme.colors.text.primary};
  margin-left: 16px;
  margin-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: auto;
`;
