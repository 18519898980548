import { MeFragment, ProductJoinDocument, UpdateMeDocument, UserJourney } from '@cycle-app/graphql-codegen';
import { Button, Avatar, Flex, TextSecondarySpan, TextSecondary } from '@cycle-app/ui';
import { plural } from '@cycle-app/utilities';

import defaultLogo from 'src/assets/workspace-default-logo.png';
import { PageId } from 'src/constants/routing.constant';
import { useNavigate, useSafeMutation } from 'src/hooks';
import { resetOnboarding } from 'src/reactives/lightOnboarding.reactive';

import { AsideApp } from '../OnboardingLayout/AsideApp';
import { Logo } from '../OnboardingLayout/Logo';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Name, StyledCreateButton, WorkspaceBox } from './WorkspaceJoin.styles';

interface Props {
  me: MeFragment;
  product: {
    id: string;
    name: string;
    userCount: number;
    logo?: {
      url: string;
    } | null;
  };
  onShowCreate: VoidFunction;
}

export const WorkspaceJoin = ({
  me, product, onShowCreate,
}: Props) => {
  const [join, { loading: isLoading }] = useSafeMutation(ProductJoinDocument);
  const { navigate } = useNavigate();
  const [updateMe] = useSafeMutation(UpdateMeDocument);
  const logo = product.logo?.url || defaultLogo;
  return (
    <OnboardingLayout
      title="Workspace"
      subtitle="Are you looking to join Workspace an existing workspace ?"
      aside={(
        <AsideApp
          color={me.color}
          logo={<Logo src={logo} name={product.name} />}
          avatar={(
            <Avatar
              user={me}
              size={18}
              userColor={me.color}
              src={me.avatar?.url}
              pending={false}
            />
          )}
        />
      )}
      main={(
        <div>
          <WorkspaceBox>
            <Flex
              $gap={12}
              style={{
                height: '36px',
                overflow: 'hidden',
              }}
            >
              <img style={{ objectFit: 'cover' }} src={logo} width={36} height={36} alt="logo" />
              <Flex
                $column
                $align="flex-start"
                style={{
                  overflow: 'hidden',
                }}
              >
                <Name>{product.name}</Name>
                <TextSecondarySpan $typo="caption">
                  {plural(`${product.userCount} user`, product.userCount)}
                </TextSecondarySpan>
              </Flex>
            </Flex>
            <Button
              style={{ whiteSpace: 'nowrap' }}
              size="M"
              isLoading={isLoading}
              onClick={async () => {
                const { data } = await join({ variables: { productId: product.id } });
                if (data?.joinProduct?.slug) {
                  await updateMe({ variables: { userJourney: UserJourney.Done } });
                  navigate(PageId.Main, {
                    productSlug: data.joinProduct.slug,
                  });
                  resetOnboarding();
                }
              }}
            >
              Join this workspace
            </Button>
          </WorkspaceBox>
          <TextSecondary $mt="24px">
            If you don&apos;t want to join you can create a new workspace
          </TextSecondary>
          <StyledCreateButton
            onClick={onShowCreate}
            variant="outlined-alt"
            size="M"
            full
          >
            Create a new workspace
          </StyledCreateButton>
        </div>
      )}
    />
  );
};
