import { FC } from 'react';

import { useCustomer } from 'src/hooks/api/queries/customers/useCustomer';
import { openCompanyModal } from 'src/hooks/modals/useCompanyModal';
import { isDefaultCustomer } from 'src/utils/customers.util';

import { CustomerChildrenTabs } from '../CustomerChildrenTabs/CustomerChildrenTabs';
import { CustomerAvatarEditable } from './CustomerAvatarEditable';
import { CustomerDetails } from './CustomerDetails';
import { HeaderContainer, Container } from './CustomerProfile.styles';
import { CustomerProfileHeader } from './CustomerProfileHeader';

interface Props {
  customerId: string;
  className?: string;
  hideShareLink?: boolean;
  hideSeparator?: boolean;
  isHeaderCentered?: boolean;
  docsSkeleton?: JSX.Element;
}

export const CustomerProfile: FC<React.PropsWithChildren<Props>> = ({
  className, isHeaderCentered, hideShareLink, customerId,
}) => {
  const { customer } = useCustomer(customerId);

  if (!customer) return null;

  return (
    <Container className={className}>
      <HeaderContainer $isCentered={isHeaderCentered}>
        <CustomerAvatarEditable
          customer={customer}
          isReadOnly={isDefaultCustomer(customer)}
          onCompanyClick={openCompanyModal}
        />
        <CustomerProfileHeader customer={customer} />
        <CustomerDetails
          customer={customer}
          hideShareLink={hideShareLink}
          isReadOnly={isDefaultCustomer(customer)}
          onCompanyClick={openCompanyModal}
        />
      </HeaderContainer>
      <CustomerChildrenTabs customerId={customerId} />
    </Container>
  );
};
