import { useMeasure } from '@cycle-app/utilities';
import { useMemo } from 'react';

import FlowDoctypes from 'src/components/FlowDoctypes/FlowDoctypes';
import { useDoctypeHierarchyElements } from 'src/hooks/hierarchy/useHierarchy';
import { getLayoutedElementsDoctype } from 'src/utils/flow.util';

type SettingsDoctypeHierarchyProps = {
  docTypeId?: string;
};

export const SettingsDoctypeHierarchy = ({ docTypeId }: SettingsDoctypeHierarchyProps) => {
  const initialElements = useDoctypeHierarchyElements(docTypeId);

  const layoutedElements = useMemo(
    () => getLayoutedElementsDoctype(initialElements),
    [initialElements],
  );

  const {
    rect,
    ref,
  } = useMeasure<HTMLDivElement>();

  return (
    <div
      ref={ref}
      style={{ height: '100%' }}
    >
      {rect && (
        <FlowDoctypes
          elements={layoutedElements}
          style={{ height: rect.height }}
        />
      )}
    </div>
  );
};
