import { Tooltip } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';

import { Container } from './SettingsDocType.styles';

export const CustomersLink = () => {
  return (
    <Container>
      <LinkIcon />
      <span>
        {'Linked to '}
        <Tooltip
          content="Assignees are responsible for closing the feedback loop with customers"
          placement="bottom"
          withWrapper={false}
          withPortal
        >
          <strong>Assignee</strong>
        </Tooltip>
        {', '}
        <Tooltip
          content="Customers are people who give product feedback – they can be team members, users or prospects"
          placement="bottom"
          withWrapper={false}
          withPortal
        >
          <strong>Customer</strong>
        </Tooltip>
        {' & '}
        <Tooltip
          content="Source is the tool from which the feedback has been captured. Connected sources are: Cycle, Slack, Zapier, Chrome."
          placement="bottom"
          withWrapper={false}
          withPortal
        >
          <strong>Source</strong>
        </Tooltip>
      </span>
    </Container>
  );
};
