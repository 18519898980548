import { FC, ReactNode } from 'react';

import { CloseIcon } from '../../icons';
import {
  Container, Content, Icon, SideActions, BottomActions, CloseButton,
  Action, Title, Message, StyledShortcut, ContentBody, Body,
} from './Toaster.styles';

export { Action as ToasterAction };
export { StyledShortcut as ToasterShortcut };
export { Title as ToasterTitle };
export { Message as ToasterDescription };

export type ToasterProps = {
  id?: string;
  icon?: ReactNode;
  title?: ReactNode;
  message?: ReactNode | ((props: { close: () => void }) => ReactNode);
  actions?: ReactNode;
  children?: ReactNode;
  onClose?: (id: string) => void;
  onAfterClosed?: () => void;
  isCompact?: boolean;
};

export const Toaster: FC<React.PropsWithChildren<ToasterProps>> = ({
  id, icon, title, message, actions, children, onClose, isCompact = true, onAfterClosed,
}) => {
  const actionsOnSide = !message && !children && isCompact;

  return (
    <Container
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={{
        visible: {
          scale: 1,
          height: 'auto',
          opacity: 1,
        },
        hidden: {
          scale: 0.9,
          height: 0,
          opacity: 0,
        },
      }}
      transition={{
        duration: 0.15,
      }}
    >
      <Content>
        {(onClose || (actions && actionsOnSide)) && (
          <SideActions>
            {actionsOnSide && actions}
            {onClose && id && (
              <CloseButton onClick={() => {
                onClose(id);
                onAfterClosed?.();
              }}
              >
                <CloseIcon />
              </CloseButton>
            )}
          </SideActions>
        )}

        <ContentBody>
          {icon && <Icon>{icon}</Icon>}
          <Body>
            {title && (
              <Title>
                {title}
              </Title>
            )}

            {(message || children) && (
              <Message>
                {typeof message === 'function' ? message({
                  close: () => {
                    if (onClose && id) onClose(id);
                  },
                }) : message}
                {children}
              </Message>
            )}

            {actions && !actionsOnSide && (
              <BottomActions>
                {actions}
              </BottomActions>
            )}
          </Body>
        </ContentBody>

      </Content>
    </Container>
  );
};
