import {
  useEffect,
  forwardRef,
  MouseEvent,
  ReactNode,
  useRef,
  useState,
  useImperativeHandle,
  ComponentPropsWithRef,
} from 'react';

import { DuplicateIcon, CheckIcon } from '../../icons';
import { Tooltip } from '../Tooltip';
import {
  Container,
  Context,
  ContextContent,
  Footer,
  ContextBlock,
  ShowMore,
  ShowLess,
  CopyContainer,
  CopyButton,
  CopyButtonContainer,
  SlotOption,
  BulkContainer,
  BulkCheckbox,
  BuklButtonContainer,
} from './InsightCard.styles';
import { InsightCardCover } from './InsightCardCover';
import { CoverLoader } from './InsightCardCover.styles';

export type InsightCardProps = {
  blockId?: string | null;
  className?: string;
  context: ReactNode;
  contextFooter?: ReactNode;
  contextText?: string;
  contextUpdate?: ReactNode;
  coverProps?: ComponentPropsWithRef<'img'>;
  coverUrl?: string;
  footerRight?: ReactNode;
  hasBorder?: boolean;
  hasSmallMaxHeight?: boolean;
  hasSelection?: boolean;
  headerSlot?: ReactNode;
  hideCopy?: boolean;
  isCoverLoading?: boolean;
  isHighlighted?: boolean;
  isSelected?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onContextCopied?: (data: string) => void;
  onContextMouseEnter?: VoidFunction;
  onContextMouseLeave?: VoidFunction;
  onCoverRemove?: VoidFunction;
  onCoverUpdate?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  onMouseMove?: VoidFunction;
  onSelect?: VoidFunction;
  options?: ReactNode;
  parentSlot?: ReactNode;
  properties?: ReactNode;
  title: string;
};

export const InsightCard = forwardRef<HTMLDivElement, InsightCardProps>(({
  blockId,
  className,
  context,
  contextFooter,
  contextText,
  contextUpdate,
  coverProps,
  coverUrl,
  footerRight,
  hasBorder = false,
  hasSmallMaxHeight = false,
  hasSelection,
  headerSlot,
  hideCopy,
  isCoverLoading,
  isHighlighted,
  isSelected,
  onClick,
  onContextCopied,
  onContextMouseEnter,
  onContextMouseLeave,
  onCoverRemove,
  onCoverUpdate,
  onMouseEnter,
  onMouseLeave,
  onMouseMove,
  onSelect,
  options,
  parentSlot,
  properties,
  title,
}, forwardedRef) => {
  const ref = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(forwardedRef, () => ref.current!, [ref]);
  return (
    <Container
      ref={ref}
      $isHighlighted={isHighlighted}
      $isSelected={isSelected}
      $withBorder={hasBorder}
      $hasSelection={hasSelection}
      className={`${className || ''} doc-item`}
      data-block-id={blockId}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
    >
      {headerSlot}

      {parentSlot && (
        <SlotOption>
          {parentSlot}
          {options}
        </SlotOption>
      )}

      {properties && (
        <SlotOption>
          {properties}
          {!parentSlot && options}
        </SlotOption>
      )}

      <InsightContext
        context={context || title}
        contextFooter={contextFooter}
        contextText={contextText}
        contextUpdate={contextUpdate}
        onContextCopied={!hideCopy ? onContextCopied : undefined}
        hasMaxHeight={!contextUpdate}
        hasSmallMaxHeight={hasSmallMaxHeight}
        cover={coverUrl && (
          <InsightCardCover
            onRemove={onCoverRemove}
            onUpdate={onCoverUpdate}
            src={coverUrl}
            coverProps={coverProps}
          />
        )}
        isSelectable={!!onSelect}
        isSelected={isSelected}
        onSelect={onSelect}
        {...!contextUpdate && {
          onMouseEnter: onContextMouseEnter,
          onMouseLeave: onContextMouseLeave,
        }}
      />

      {!!footerRight && <Footer>{footerRight}</Footer>}

      {ref.current && isCoverLoading && (
        <CoverLoader
          width={ref.current.clientWidth + 2}
          height={ref.current.clientHeight + 2}
        />
      )}
    </Container>
  );
});

/* eslint-disable @typescript-eslint/indent */
type InsightContextProps = Pick<
  InsightCardProps,
  'context' |
  'contextFooter' |
  'contextText' |
  'contextUpdate' |
  'hasSmallMaxHeight' |
  'onContextCopied'
> & {
  cover?: ReactNode;
  hasMaxHeight?: boolean;
  isInline?: boolean;
  isReadOnly?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  onSelect?: VoidFunction;
  className?: string;
};
/* eslint-enable @typescript-eslint/indent */

export const InsightContext = ({
  context,
  contextFooter,
  contextText,
  contextUpdate,
  cover,
  hasMaxHeight = true,
  hasSmallMaxHeight = false,
  isInline,
  isReadOnly = false,
  isSelectable,
  isSelected,
  onContextCopied,
  onMouseEnter,
  onMouseLeave,
  onSelect,
  className,
}: InsightContextProps) => {
  const ref = useRef<HTMLQuoteElement>(null);
  const [canExpand, setCanExpand] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  // Get the ref of the callback without calling the effect.
  const onUnmount = useRef<VoidFunction | undefined>();
  onUnmount.current = onMouseLeave;

  useEffect(() => {
    // Wait for the doc right panel animation to complete
    setTimeout(() => {
      if (!ref.current || isInline) return;
      setCanExpand(ref.current.scrollHeight > ref.current.clientHeight);
    }, 500);
  }, [isInline]);

  useEffect(() => {
    return () => onUnmount.current?.();
  }, []);

  return (
    <Context $isInline={isInline} className={className}>
      <ContextBlock
        $isInline={isInline}
        $hasHover={!!onMouseEnter}
      >
        {isSelectable && (
          <BulkContainer>
            <BuklButtonContainer>
              <Tooltip
                content={isSelected ? 'Unselect' : 'Select'}
                placement="top"
                withPortal
              >
                <BulkCheckbox
                  $isSelected={isSelected}
                  onClick={e => {
                    e.stopPropagation();
                    onSelect?.();
                  }}
                >
                  <CheckIcon />
                </BulkCheckbox>
              </Tooltip>
            </BuklButtonContainer>
          </BulkContainer>
        )}
        {cover}
        <ContextContent
          ref={ref}
          $isInline={isInline}
          $isExpand={isExpand}
          $isReadOnly={isReadOnly}
          $shouldHaveMaxHeight={hasMaxHeight}
          $hasSmallHeight={hasSmallMaxHeight}
          onMouseLeave={onMouseLeave}
          onMouseEnter={onMouseEnter}
        >
          {contextUpdate}
          {!contextUpdate && context}
          {!contextUpdate && canExpand && !isExpand && (
            <ShowMore
              onClickCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsExpand(true);
              }}
            >
              Show more…
            </ShowMore>
          )}
          {canExpand && isExpand && (
            <ShowLess
              onClickCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsExpand(false);
              }}
            >
              Show less
            </ShowLess>
          )}
        </ContextContent>
        {!contextUpdate && onContextCopied && context && (
          <CopyContainer>
            <CopyButtonContainer>
              <Tooltip
                content="Copy quote"
                placement="top"
                withPortal
                withWrapper={false}
                popperOptions={{
                  strategy: 'fixed',
                }}
              >
                <CopyButton
                  size="M"
                  onClickCapture={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (contextText) onContextCopied(contextText);
                  }}
                >
                  <DuplicateIcon />
                </CopyButton>
              </Tooltip>
            </CopyButtonContainer>
          </CopyContainer>
        )}
      </ContextBlock>
      {contextFooter}
    </Context>
  );
};
