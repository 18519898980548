import { DotsMenu } from '@cycle-app/ui';
import { PenIcon, TrashIcon } from '@cycle-app/ui/icons';
import { ComponentProps, useMemo } from 'react';

import { ReleaseRemoveModal } from 'src/components/ReleaseModals';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openUpdateRelease, openRemoveRelease } from 'src/reactives/releases.reactive';

type Props = Omit<ComponentProps<typeof DotsMenu>, 'options'> & {
  releaseId: string;
  actions?: string[];
};

export const ReleaseActionsMenu = ({
  releaseId, actions,
}: Props) => {
  const {
    canUpdateRelease, canDeleteRelease,
  } = useGetPermission();

  const options = useMemo(() => {
    const result = [];

    if (!actions || actions?.includes('edit')) {
      result.push({
        value: 'edit',
        label: 'Edit',
        icon: <PenIcon />,
        onSelect: canUpdateRelease ? openUpdateRelease(releaseId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
      });
    }

    if (!actions || actions?.includes('delete')) {
      result.push({
        value: 'delete',
        label: 'Delete',
        icon: <TrashIcon />,
        onSelect: canDeleteRelease ? openRemoveRelease(releaseId) : () => setLimitationsModal({ action: 'RELEASE_UPDATE' }),
      });
    }

    return result;
  }, [actions, canDeleteRelease, canUpdateRelease, releaseId]);

  if (options.length === 0) return null;

  return (
    <>
      <DotsMenu
        placement="bottom-end"
        options={options}
      />
      <ReleaseRemoveModal releaseId={releaseId} />
    </>
  );
};
