import { DoctypeType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';
import { Size } from '@cycle-app/ui/components/Emoji/Emoji.styles';
import { BulbIcon, InboxIcon } from '@cycle-app/ui/icons';
import { ComponentProps, forwardRef } from 'react';
import styled from 'styled-components';

type Props = Pick<ComponentProps<'div'>, 'className' | 'style'> & {
  doctype?: { type: DoctypeType; emoji: string };
  size?: Size;
};

export const DocTypeIcon = forwardRef<HTMLDivElement, Props>(({
  doctype, size, ...props
}, ref) => {
  if (!doctype) return null;
  if (doctype.type === DoctypeType.Feedback) return <InboxIcon ref={ref} {...props} size={size} />;
  if (doctype.type === DoctypeType.Insight) return <BulbIcon ref={ref} {...props} size={size} />;
  return <Emoji ref={ref} {...props} emoji={doctype.emoji} size={size} />;
});

export const DocTypeIconInline = styled(DocTypeIcon)`
  display: inline-block;
  margin-top: -2px;
  vertical-align: middle;
`;
