import { useEffect } from 'react';

import { useMaybeMe } from 'src/hooks/api/useMe';
import { useProductBase } from 'src/hooks/api/useProduct';
import { groupAnalytics, identifyAnalytics, loadAnalytics } from 'src/utils/analytics/analytics';

import { useRole } from '../hooks/useRoles';
import { isUserOnboarded } from '../utils/users.util';

/**
 * The useEffect hook need to be isolated outside of the App to avoid
 * trigerring a re-render each time me changes
 */
export const Analytics = () => {
  const me = useMaybeMe();
  const productRole = useRole();
  const product = useProductBase();

  useEffect(() => {
    loadAnalytics();
  }, []);

  useEffect(() => {
    if (product && me && me.email !== 'archiveduser@cycle.app') {
      const {
        id, email, firstName, lastName,
      } = me;
      identifyAnalytics(id, {
        email,
        firstName,
        lastName,
        jobTitle: me.jobTitle ? me.jobTitle : null,
        onboarded: isUserOnboarded(me),
        company: {
          id: product.id,
          name: product.name,
        },
        role: productRole.role ?? productRole.globalRole,
        productSlug: product.slug,
        productId: product.id,
        productName: product.name,
      });
      groupAnalytics(product.id, {
        productId: product.id,
        name: product.name,
        slug: product.slug,
      });
    }
  }, [me?.id, me?.email, me?.firstName, me?.lastName, me?.userJourney, product?.name, product?.id, product?.slug, productRole.role]);

  return null;
};
