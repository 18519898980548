import { UpdateCompanyMutationVariables } from '@cycle-app/graphql-codegen';
import { Spinner } from '@cycle-app/ui';
import { isUrl } from '@cycle-app/utilities';
import { useForm } from 'react-hook-form';

import { useFetchCompanyLogo } from 'src/hooks';
import { useCompanyUpdate } from 'src/hooks/api/mutations/customers/useCompanyUpdate';

import { DomainInput } from './CompanyDomain.styles';

export const CompanyDomainForm = ({
  companyId, name, domain, hide, logo,
}: {
  companyId: string;
  name: string;
  domain?: string | null;
  hide: VoidFunction;
  logo?: string | null;
}) => {
  const { updateCompany } = useCompanyUpdate();

  const {
    handleSubmit, reset, register, formState: {
      errors, isSubmitting,
    },
  } = useForm<Pick<UpdateCompanyMutationVariables, 'domain'>>({
    defaultValues: { domain },
  });

  const { fetch: fetchLogo } = useFetchCompanyLogo();

  return (
    <form onSubmit={handleSubmit(async data => {
      if (isSubmitting) return;

      // Fetch the company logo if it has not yet been defined
      let avatarUrl = '';
      if (!logo && data.domain) {
        const response = await fetchLogo({ domain: data.domain });
        avatarUrl = response?.companyLogo ?? '';
      }

      await updateCompany({
        companyId,
        name,
        domain: data.domain,
        avatarInput: avatarUrl ? { avatarUrl } : undefined,
      });
      hide();
    })}
    >
      <DomainInput
        autoFocus
        placeholder="https://www.figma.com/"
        iconAfter={isSubmitting && <Spinner />}
        error={errors.domain?.message}
        {...register('domain', {
          setValueAs: (value) => {
            if (!value) return value;
            let result = value.trim();
            if (!isUrl(result)) result = `https://${result}`;
            return result;
          },
          validate: value => !value || isUrl(value, { strict: false }) || 'URL format is incorrect',
          onBlur: () => {
            hide();
            reset();
          },
        })}
        onKeyUp={e => {
          if (e.key === 'Escape') {
            e.stopPropagation();
            hide();
            reset();
          }
        }}
      />
    </form>
  );
};
