import { AiState, StatusCategory, ViewType } from '@cycle-app/graphql-codegen';
import { Emoji, TooltipProps, Tooltip, StatusIcon } from '@cycle-app/ui';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { FC, MouseEvent, ReactNode, useState } from 'react';
import { Instance } from 'tippy.js';

import { AiStateTag } from 'src/components/AiStateTag';
import { useIsRoadmapsEnabled } from 'src/hooks';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { getStatus } from 'src/reactives/productStatus.reactive';
import { getDocKey } from 'src/utils/doc.util';

import {
  Container,
  Title,
  PublicId,
  ParentIcon,
  MainButton,
  EditButton,
} from './DocParent.styles';

interface Props {
  className?: string;
  doc?: {
    id: string;
    publicId?: number | null;
    title: string;
    doctype: {
      id: string;
    };
    status?: {
      category: StatusCategory;
      id: string;
    } | null;
    aiState?: AiState | null;
  } | null;
  showTitle?: boolean;
  showIcon?: boolean;
  showStatus?: boolean;
  showEmoji?: boolean;
  showPublicId?: boolean;
  tooltipProps?: TooltipProps;
  onClick?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  minimal?: boolean;
  readonly?: boolean;
  size?: 'XS' | 'S' | 'M';
  context?: 'doc-panel' | 'doc-item';
  viewType?: ViewType;
  onClickEdit?: (e: MouseEvent) => void;
  defaultLabel?: ReactNode;
  forceFocus?: boolean;
}
const DocParent: FC<React.PropsWithChildren<Props>> = ({
  doc,
  children,
  showTitle,
  minimal,
  showIcon = true,
  showEmoji = true,
  showStatus = false,
  showPublicId = true,
  readonly = false,
  size = 'S',
  tooltipProps,
  context = 'doc-panel',
  viewType,
  onClickEdit,
  defaultLabel,
  forceFocus,
  ...containerProps
}) => {
  const emoji = useGetDocType(doc?.doctype.id)?.emoji;
  const product = useProductBase();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const [tippyInstance, setTippyInstance] = useState<Instance | null>(null);

  const render = () => (
    <>
      {showIcon && <ParentIcon size={14} />}
      {showEmoji && <Emoji size={size === 'S' ? 12 : 14} emoji={emoji} />}

      {doc && isRoadmapsEnabled && showStatus && doc.aiState !== AiState.AiCreated && doc.status && (
        <Tooltip
          content={getStatus(doc.status.id)?.value}
          withPortal
          withWrapper={false}
          placement="top"
        >
          <StatusIcon
            category={doc.status.category}
            withBackground
            style={{ flex: 'none' }}
            onMouseOver={() => {
              tippyInstance?.hide();
            }}
            onMouseLeave={() => {
              tippyInstance?.show();
            }}
          />
        </Tooltip>
      )}

      {doc && doc.aiState === AiState.AiCreated && (
        <AiStateTag
          docId={doc.id}
          docTypeId={doc.doctype.id}
          aiState={doc.aiState}
          onMouseOver={() => {
            tippyInstance?.hide();
          }}
          onMouseLeave={() => {
            tippyInstance?.show();
          }}
        />
      )}

      {doc && showPublicId && !showTitle && doc.publicId && product?.key && (
        <PublicId>{getDocKey(product?.key, doc.publicId)}</PublicId>
      )}
      {doc && showTitle && (<Title $viewType={viewType}>{doc.title}</Title>)}

      {!doc && defaultLabel}
    </>
  );

  return (
    <Container
      $context={context}
      viewType={viewType}
      readonly={readonly}
    >
      <MainButton
        {...containerProps}
        size={size}
        $minimal={minimal}
        readonly={readonly}
        $context={context}
        $forceFocus={forceFocus}
      >
        {tooltipProps ? (
          <Tooltip
            placement="bottom"
            popperOptions={{ strategy: 'fixed' }}
            {...tooltipProps}
            onMount={setTippyInstance}
          >
            {render()}
          </Tooltip>
        ) : render()}
        {children}

      </MainButton>

      {onClickEdit && doc && (
        <EditButton
          onClick={onClickEdit}
          tooltip="Edit"
          tooltipPlacement="top"
          $forceFocus={forceFocus}
        >
          <PenFilledIcon size={11} />
        </EditButton>
      )}
    </Container>
  );
};

export default DocParent;
