import { ShyScrollbar, typo, Button } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

import SavingLabel from 'src/components/SavingLabel/SavingLabel';

export const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const ScrollableContent = styled(ShyScrollbar)`
  flex: 1;
  background-color: ${p => p.theme.colors.settings.bg};
  max-height: 100vh;
  padding: 0 32px 32px;
`;

export const ContentPage = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  min-width: 780px;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  gap: 24px;
`;

export const headerMarginBottom = '45px';

export const Header = styled.header`
  margin-bottom: ${headerMarginBottom};

  display: flex;
  gap: 8px;
  align-items: center;

  h1 {
    font-size: 26px;
    font-weight: 600;
    outline: none;

    display: flex;
    align-items: center;
    gap: 10px;

    small {
      font-size: 14px;
      line-height: 20px;
      color: ${(props) => props.theme.colors.text.secondary};
      font-weight: normal;
    }
  }

  padding: 0 8px 0 0;

  ${mobile} {
    margin-top: 32px;
  }
`;

export const SavingLabelStyled = styled(SavingLabel)`
  margin-left: auto;
  color: ${p => p.theme.colors.text.disabled};
  ${typo.body400}
`;

export const Content = styled.div`
  /* 83px is the height of the Header, inclunding its margins */
  height: calc(100% - 83px);
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const HeaderAction = styled(Button)`
  margin-left: 8px;
  gap: 10px;
  padding: 4px 12px;
  height: 32px;
`;
