import { Reference } from '@apollo/client';
import { RemoveWebhookDocument } from '@cycle-app/graphql-codegen';

import { useProduct } from 'src/hooks/api/useProduct';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useRemoveWebhook = (webhookId: string) => {
  const { product } = useProduct();
  const [deleteClient] = useSafeMutation(RemoveWebhookDocument);
  return () => deleteClient({
    variables: { id: webhookId },
    optimisticResponse: { removeWebhook: { id: webhookId } },
    update: (cache, { data }) => {
      if (!product || !data?.removeWebhook) return;
      cache.modify({
        id: cache.identify(product),
        fields: {
          webhooks: (refs, { readField }) => ({
            ...refs,
            edges: refs.edges.filter(
              (edge: { node: Reference }) => readField('id', edge.node) !== data.removeWebhook?.id,
            ),
          }),
        },
      });
    },
  });
};
