import styled, { css } from 'styled-components';

import { caption400 } from '../../theme/typo';

interface Props {
  // margin top
  $mt?: string;
  $typo?: 'caption';
}

const styles = css<Props>`
  color: ${p => p.theme.colors.text.secondary};
  ${p => p.$mt && `margin-top: ${p.$mt};`}
  ${p => p.$typo === 'caption' && caption400};
`;

export const TextSecondary = styled.p<Props>`
  ${styles}
`;

export const TextSecondarySpan = styled.span<Props>`
  ${styles}
`;
