import { Avatar, typo, transitionCss } from '@cycle-app/ui';
import styled from 'styled-components';

import { Name as ThemeName, Preview as ThemePreview } from 'src/components/ThemePicker/ThemePicker.styles';
import {
  COLLABORATION_CURSOR_CARET_CLASS,
  COLLABORATION_CURSOR_LABEL_CLASS,
  collaborationCursorCss,
} from 'src/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledAvatar = styled(Avatar)`
  margin-top: 12px;
`;

export const SectionTitle = styled.h1`
  ${typo.body500};
  margin-bottom: 16px;
`;

export const ColorSection = styled.section``;

export const Preview = styled.div`
  border-radius: 8px;
  background-color: ${p => p.theme.colors.background.secondary};
  margin-top: 24px;
  padding: 12px 16px;

  display: flex;
  gap: 30px;

  &, * {
    ${transitionCss(['background', 'color'])}
  }
`;

export const PreviewItem = styled.div``;

export const PreviewLabel = styled.h2`
  ${typo.caption400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const PreviewHighlight = styled.div<{ $highlightColor: string }>`
  margin-top: 18px;
  line-height: 25px;
  color: ${p => p.theme.colors.text.primary};

  mark {
    background-color: ${p => p.$highlightColor};
  }
`;

export const CollaborationCursorCaret = styled.span`
  height: 19px;
`;

export const CollaborationCursorCaretWithName = styled.p``;

export const CursorsPreview = styled.div<{ $color: string }>`
  margin-top: 24px;
  display: flex;
  align-items: baseline;
  gap: 89px;
  color: ${p => p.$color};

  ${collaborationCursorCss};

  ${CollaborationCursorCaret}.${COLLABORATION_CURSOR_CARET_CLASS} {
    &:hover::before {
      opacity: 1;
    }
  }
  ${CollaborationCursorCaretWithName} .${COLLABORATION_CURSOR_CARET_CLASS} {
    &::before {
      opacity: 0;
    }

    .${COLLABORATION_CURSOR_LABEL_CLASS} {
      background-color: ${p => p.$color};
      opacity: 1;
      top: -15px;
    }
  }
`;

export const ThemeSection = styled.section`
  margin-top: 24px;
  width: 366px;
  max-width: 100%;

  ${ThemePreview} {
    height: 144px;
  }

  ${ThemeName} {
    text-align: center;
    ${typo.body500}
  }
`;

export const Buttons = styled.section`
  margin-top: auto;
  margin-left: auto;
`;
