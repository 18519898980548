import { useApolloClient } from '@apollo/client';
import {
  UpdateDocTitleDocument,
  UpdateDocTitleMutationVariables,
  ChangeDocAssigneeDocument,
  ChangeDocAssigneeMutationVariables,
  RemoveDocAssigneeDocument,
  RemoveDocAssigneeMutationVariables,
  UpdateDocDoctypeDocument,
  DoctypeWithAttributeDefinitionsFragmentDoc,
  RemoveDocDocument,
  BulkChangeDocParentDocument,
  DocChildrensDocument,
  DocChildrensQuery,
  DocChildrensQueryVariables,
  RemoveDocsDocument,
} from '@cycle-app/graphql-codegen';
import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Events } from 'src/constants/analytics.constants';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useBoardConfig } from 'src/contexts/boardConfigContext';
import { useRemoveDocFromBoardDocsList, useRemoveDocsFromCache } from 'src/hooks/api/cache/cacheBoardDocsList';
import { useRemoveDocImportFromCache } from 'src/hooks/api/cache/cacheDoc';
import { useGetDocGroup, useGetGroup, useUpdateDocsGroup } from 'src/hooks/api/cache/cacheGroupHooks';
import { useUpdateChildCache } from 'src/hooks/api/cache/cacheHierarchy';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useLoader } from 'src/hooks/useLoader';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { deleteDraftComments } from 'src/reactives/draftComments.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';
import { getDocFromCache } from 'src/utils/cache.utils';
import { getDocKey } from 'src/utils/doc.util';
import { defaultHierarchyPagination } from 'src/utils/pagination.util';
import { addToaster } from 'src/utils/toasters.utils';

import { isReleasePublished } from '../../../utils/release.utils';
import { useLazyQueryAsync } from '../../useLazyQueryAsync';
import { useCustomerDocFromCache } from '../cache/cacheCustomerDoc';
import { useBuiltIntSpecificAttributes } from '../useAttributes';
import { useBoardGroups } from '../useBoardGroups';
import { useMe } from '../useMe';
import { useMoveDocs } from './useMoveDoc';

export const useUpdateDocTitle = () => {
  const [updateDocTitle, { loading }] = useSafeMutation(UpdateDocTitleDocument);

  // TODO: plug saving content when it's implemented in realtime protocol
  useLoader({ loading });

  const update = useCallback((variables: UpdateDocTitleMutationVariables) => updateDocTitle({
    variables,
    optimisticResponse: {
      updateDocTitle: {
        __typename: 'Doc',
        id: variables.docId,
        title: variables.title,
      },
    },
  }), [updateDocTitle]);

  return {
    updateDocTitle: update,
    updateDocTitleDebounced: useDebouncedCallback(update, INPUT_ONCHANGE_DEBOUNCE),
    loading,
  };
};

export const useUpdateDocAssignee = () => {
  const { me } = useMe();
  const { moveDocs } = useMoveDocs();
  const { groups } = useBoardGroups();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const [changeDocAssignee, { loading }] = useSafeMutation(ChangeDocAssigneeDocument);

  const getGroup = useGetGroup();
  const updateDocsGroup = useUpdateDocsGroup();
  const removeDocFromBoard = useRemoveDocFromBoardDocsList();

  const updateDocAssignee = useCallback(async (variables: ChangeDocAssigneeMutationVariables, notCompatible: boolean) => {
    const shouldMoveToAnotherColumn =
      boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' &&
      boardConfig?.docQuery.groupbyConfig.property.__typename === 'AssigneeDefinition';

    const doc = getDocFromCache(variables.docId);

    if (!doc?.isDraft && shouldMoveToAnotherColumn && !notCompatible) {
      if (!doc || !groups) return;

      const groupIdOfPreviousValue = Object.keys(groups).find(groupId => groups[groupId]?.attributeValueId === doc.assignee?.id);
      const groupIdOfNewValue = Object.keys(groups).find(groupId => groups[groupId]?.attributeValueId === variables.userId);

      if (!groupIdOfNewValue || !groupIdOfPreviousValue) return;

      const docsFromNewGroup = Object.keys(groups[groupIdOfNewValue]?.docs ?? {}).map(docId => groups[groupIdOfNewValue]?.docs[docId]);

      await moveDocs({
        groupId: groupIdOfNewValue,
        previousGroupIds: [groupIdOfPreviousValue],
        docsMoved: [doc],
        docsListUpdated: [
          doc,
          ...docsFromNewGroup,
        ],
        position: {
          before: docsFromNewGroup.length ? (docsFromNewGroup[0]?.id ?? '') : '',
        },
        boardConfigId: boardConfig.id,
      });
    } else {
      await changeDocAssignee({
        variables,
        optimisticResponse: {
          changeDocAssignee: {
            __typename: 'Doc',
            id: variables.docId,
            assignee: {
              id: variables.userId,
              __typename: me.id === variables.userId
                ? 'Me'
                : 'OtherUser',
            },
          },
        },
        update: () => {
          if (notCompatible && doc && groups) {
            if (boardConfig?.docQuery.__typename === 'BoardQuery') {
              removeDocFromBoard(boardConfig.id, variables.docId);
            } else if (
              boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
              boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
            ) {
              const groupIdOfPreviousValue = Object.keys(groups)
                .find(
                  gId => Object.values(groups[gId]?.docs ?? {}).map(d => d.id).includes(doc.id),
                );
              if (!groupIdOfPreviousValue) return;

              const previousGroup = getGroup(groupIdOfPreviousValue);
              if (!previousGroup) return;

              updateDocsGroup({
                groupData: previousGroup,
                updatedDocs: previousGroup.node.docs.edges
                  .filter(edge => variables.docId !== edge.node.id)
                  .map(edge => edge.node),
                boardConfigId: boardConfig.id,
              });
            }
          }
        },
      });
    }
  }, [
    changeDocAssignee,
    me.id,
    boardConfig,
    groups,
    moveDocs,
    getGroup,
    updateDocsGroup,
    removeDocFromBoard,
  ]);

  return {
    updateDocAssignee,
    loading,
  };
};

export const useRemoveDocAssignee = () => {
  const { groups } = useBoardGroups();
  const getGroup = useGetGroup();
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const updateDocsGroup = useUpdateDocsGroup();
  const removeDocFromBoard = useRemoveDocFromBoardDocsList();

  const [removeDocAssignee, { loading }] = useSafeMutation(RemoveDocAssigneeDocument);

  const remove = useCallback((variables: RemoveDocAssigneeMutationVariables, notCompatible?: boolean) => removeDocAssignee({
    variables,
    optimisticResponse: {
      removeDocAssignee: {
        __typename: 'Doc',
        id: variables.docId,
        assignee: null,
      },
    },
    update: () => {
      if (notCompatible && groups) {
        if (boardConfig?.docQuery.__typename === 'BoardQuery') {
          removeDocFromBoard(boardConfig.id, variables.docId);
        } else if (
          boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' ||
          boardConfig?.docQuery.__typename === 'BoardQueryWithSwimlaneBy'
        ) {
          const groupIdOfPreviousValue = Object.keys(groups)
            .find(
              gId => Object.values(groups[gId]?.docs ?? {}).map(d => d.id).includes(variables.docId),
            );
          if (!groupIdOfPreviousValue) return;

          const previousGroup = getGroup(groupIdOfPreviousValue);
          if (!previousGroup) return;

          updateDocsGroup({
            groupData: previousGroup,
            updatedDocs: previousGroup.node.docs.edges.filter(edge => variables.docId !== edge.node.id).map(edge => edge.node),
            boardConfigId: boardConfig.id,
          });
        }
      }
    },
  }), [removeDocAssignee, boardConfig, groups, getGroup, removeDocFromBoard, updateDocsGroup]);

  return {
    removeDocAssignee: remove,
    loading,
  };
};

interface UpdateDocDoctypeArgs {
  docId: string;
  doctypeId: string;
  isNotCompatible?: boolean;
}
export const useUpdateDocDoctype = () => {
  const { cache } = useApolloClient();
  const { moveDocs } = useMoveDocs();
  const { groups } = useBoardGroups();
  const { customerAttribute } = useBuiltIntSpecificAttributes();
  const groupByProperty = useBoardConfig(ctx => ctx.groupByProperty);
  const boardConfig = useBoardConfig(ctx => ctx.boardConfig);
  const [updateDoctypeMutation, { loading }] = useSafeMutation(UpdateDocDoctypeDocument);
  const {
    removeCustomerDoc, addCustomerDoc,
  } = useCustomerDocFromCache();
  const getDocGroup = useGetDocGroup();
  const updateGroup = useUpdateDocsGroup();
  const updateChildCache = useUpdateChildCache();

  const updateDocDoctype = useCallback(async (variables: UpdateDocDoctypeArgs) => {
    const doc = getDocFromCache(variables.docId);
    const currentDoc = { ...doc };
    if (!doc?.isDraft && doc?.customer?.id) {
      removeCustomerDoc({
        customer: doc.customer,
        doc,
        doctypeId: doc.doctype.id,
      });
      if (customerAttribute?.doctypes.edges.find(({ node }) => node.id === variables.doctypeId)) {
        addCustomerDoc({
          customer: doc.customer,
          doc,
          doctypeId: variables.doctypeId,
        });
      }
    }
    const shouldMoveToAnotherColumn =
      boardConfig?.docQuery.__typename === 'BoardQueryWithGroupBy' &&
      boardConfig?.docQuery.groupbyConfig.property.__typename === 'DoctypeDefinition';

    const doctypeFromCache = cache.readFragment({
      id: variables.doctypeId,
      fragmentName: 'DoctypeWithAttributeDefinitions',
      fragment: DoctypeWithAttributeDefinitionsFragmentDoc,
    });

    if (shouldMoveToAnotherColumn) {
      if (!doc || !groups) return;

      const groupIdOfPreviousValue = Object.keys(groups).find(groupId => groups[groupId]?.attributeValueId === doc.doctype.id);
      const groupIdOfNewValue = Object.keys(groups).find(groupId => groups[groupId]?.attributeValueId === variables.doctypeId);

      if (!groupIdOfNewValue || !groupIdOfPreviousValue) {
        removeDocFromBoardIfNecessary();
        await updateDoctype();
        return;
      }

      const docsFromNewGroup = Object.keys(groups[groupIdOfNewValue]?.docs ?? {}).map(docId => groups[groupIdOfNewValue]?.docs[docId]);

      await moveDocs({
        groupId: groupIdOfNewValue,
        previousGroupIds: [groupIdOfPreviousValue],
        docsMoved: [doc],
        docsListUpdated: [
          doc,
          ...docsFromNewGroup,
        ],
        position: {
          before: docsFromNewGroup.length ? (docsFromNewGroup[0]?.id ?? '') : '',
        },
        groupByProperty,
        boardConfigId: boardConfig.id,
      });
      await updateDoctype();
    } else {
      removeDocFromBoardIfNecessary();
      await updateDoctype();
    }
    // Add to the new doctype children after the mutations.
    if (doc?.parent?.id) {
      await updateChildCache({
        docs: [{
          docId: doc.id,
          parentId: currentDoc?.parent?.id,
        }],
        newParentId: doc.parent.id,
      });
    }

    function removeDocFromBoardIfNecessary() {
      if (!doc || !variables.isNotCompatible || !boardConfig) return;
      const originalGroup = getDocGroup(doc.id);
      if (originalGroup) {
        // Remove doc from board
        updateGroup({
          groupData: originalGroup,
          updatedDocs: originalGroup.node.docs.edges
            .filter(edge => edge.node.id !== doc.id)
            .map(edge => edge.node),
          boardConfigId: boardConfig.id,
        });
      }
    }

    function updateDoctype() {
      return updateDoctypeMutation({
        variables,
        ...doctypeFromCache && {
          optimisticResponse: {
            updateDocDoctype: {
              __typename: 'Doc',
              id: variables.docId,
              doctype: doctypeFromCache,
            },
          },
        },
      });
    }
  }, [updateDoctypeMutation, cache, boardConfig, groups, moveDocs, customerAttribute, removeCustomerDoc, addCustomerDoc,
    getDocGroup, updateGroup, updateChildCache, groupByProperty]);

  return {
    updateDocDoctype,
    loading,
  };
};

export const useRemoveDoc = (options?: { toaster?: boolean }) => {
  const product = useProductBase();
  const removeDocsFromCache = useRemoveDocsFromCache();
  const removeDocImportFromCache = useRemoveDocImportFromCache();

  const [removeDocMutation, { loading }] = useSafeMutation(RemoveDocDocument, {
    onCompleted: (data) => {
      const docId = data?.removeDoc?.id;
      if (docId) deleteDraftComments([docId]);
      trackAnalytics(Events.DocDeleted);
    },
  });

  // Cache updates are done in useDeletedDocSubscription.
  const removeDoc = useCallback(async (docId: string) => {
    const doc = getDocFromCache(docId);
    // This should be done before showing the dialog deletion.
    // But we can still keep this here in case we forget to do it.
    if (isReleasePublished(doc?.releaseNote?.release)) {
      addToaster({
        title: 'Operation not allowed on published release',
        message: 'Unpublish the release to make changes',
      });
      return null;
    }
    const result = await removeDocMutation({
      variables: { docId },
      optimisticResponse: {
        removeDoc: {
          id: docId,
        },
      },
      update: () => {
        // Remove the doc from those imported with the dropzone
        removeDocImportFromCache(doc);
        return removeDocsFromCache([docId]);
      },
    });
    const docKey = getDocKey(product?.key, doc?.publicId);
    if (options?.toaster) {
      addToaster({
        title: 'Successfully deleted',
        message: `${docKey ?? 'Your doc'} was successfully deleted`,
      });
    }

    return result;
  }, [removeDocMutation, product?.key, options?.toaster, removeDocImportFromCache, removeDocsFromCache]);

  return {
    loading,
    removeDoc,
  };
};

export const useRemoveDocs = () => {
  const removeDocsFromCache = useRemoveDocsFromCache();
  const [removeDocsMutation, { loading }] = useSafeMutation(RemoveDocsDocument, {
    onCompleted: () => trackAnalytics(Events.DocDeleted),
  });

  // Cache updates are done in useDeletedDocSubscription.
  const removeDocs = useCallback(async (docIds: string[]) => {
    await removeDocsMutation({
      variables: { docIds },
      // Still immediately update the cache immediately to avoid visual glitches.
      update: () => removeDocsFromCache(docIds),
    });

    addToaster({
      title: 'Successfully deleted',
      message: 'Your docs were successfully deleted',
    });

    deleteDraftComments(docIds);
  }, [removeDocsFromCache, removeDocsMutation]);

  return {
    loading,
    removeDocs,
  };
};

interface BulkChangeParentParams {
  parentId: string | undefined;
  doctypesChildId?: Array<string>;
  docIds: Array<string>;
  childsToRemove?: Record<string, string>;
}

export const useBulkChangeParent = () => {
  const updateChildCache = useUpdateChildCache();
  const [bulkChangeParentMutation] = useSafeMutation(BulkChangeDocParentDocument);

  const getChildren = useLazyQueryAsync<DocChildrensQuery, DocChildrensQueryVariables>(DocChildrensDocument);

  const queryChildrenToHaveThemInCache = useCallback(async ({
    parentId,
    doctypesChildId = [],
  }: BulkChangeParentParams) => {
    if (!parentId) return null;
    return Promise.all(
      doctypesChildId.map((doctypeId) => getChildren({
        docId: parentId,
        doctypeId,
        ...defaultHierarchyPagination,
      })),
    );
  }, [getChildren]);

  const bulkChangeParent = useCallback(async (args: BulkChangeParentParams) => {
    const {
      parentId,
      docIds,
    } = args;

    if (args.doctypesChildId) await queryChildrenToHaveThemInCache(args);

    await bulkChangeParentMutation({
      variables: {
        parentId,
        docIds,
      },
      update: (_, { data }) => {
        if (data?.changeDocsParent) {
          const docs = docIds.map(docId => ({
            docId,
            parentId: getDocFromCache(docId)?.parent?.id,
          }));
          updateChildCache({
            newParentId: parentId,
            docs,
          });
        }
      },
    });
  }, [bulkChangeParentMutation, queryChildrenToHaveThemInCache, updateChildCache]);

  return bulkChangeParent;
};
