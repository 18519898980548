import { Skeleton, Flex } from '@cycle-app/ui';

export const LinearImportSkeletons = () => (
  <Flex $column $gap={16}>
    {[...Array(8).keys()].map(i => (
      <Skeleton
        key={i}
        height={20}
      />
    ))}
  </Flex>
);
