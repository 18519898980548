import { typo, Button } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

export const Container = styled.div<{ $isDisabled?: boolean }>`
  flex: 1;
  ${p => p.$isDisabled && `
    cursor: not-allowed;
  `}
`;

export const Label = styled.h2`
  ${typo.body500};
  margin-bottom: 8px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 6px;
  gap: 6px;
`;

export const InputContainer = styled(ToggleContainer)<{ $disabled?: boolean }>`
  justify-content: space-between;
  border: 1px solid ${p => p.theme.colors.border.primary};
  padding: 0 12px;
  gap: 6px;
  cursor: pointer;

  ${p => p.$disabled && `
    pointer-events: none;
  `}

  &:hover {
    background-color: ${p => p.theme.colors.background.hoverSoft};
  }
`;

export const InputContent = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Caret = styled(CaretIcon)`
  height: 12px;
  width: 12px;
  color: ${p => p.theme.colors.text.disabled};
`;

export const LinearButton = styled(Button)<{
  $isEmpty: boolean;
}>`
  max-width: 100%;
  height: 28px;
  padding: 0 10px;
  ${typo.body400};

  ${p => p.$isEmpty && `
    background: ${p.theme.colors.background.tertiary};
    color: ${p.theme.colors.text.secondary};
  `}
`;
