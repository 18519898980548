import { useSubscription } from '@apollo/client';
import { AskDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { updateAskAnswer, getAskAnswers } from 'src/reactives/ask.reactive';

export const useAskSubscription = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  return useSubscription(AskDocument, {
    variables: {
      productId,
    },
    onSubscriptionData: (result) => {
      const data = result.subscriptionData.data?.ask;
      if (!data) return;
      const answers = Object.values(getAskAnswers().answers);
      const anwserId = answers.find(answer => answer.uuid === data.id)?.id;
      if (!anwserId) return;
      updateAskAnswer(anwserId, {
        id: anwserId,
        status: 'answered',
        uuid: data.id,
        content: {
          answer: data.answer,
          docs: data.docs,
        },
      });
    },
  });
};
