import { Language } from '@cycle-app/graphql-codegen';
import { DownIcon } from '@cycle-app/ui/icons';

import { getLanguageName, LANGUAGES_OPTIONS } from 'src/constants/languages.constants';
import { useUserLanguage } from 'src/hooks/user/useUserLanguage';
import { Layer } from 'src/types/layers.types';

import { ToggleDropdown } from '../DropdownLayer';
import { StyledButton, StyledSelectPanel } from './LanguageSelect.styles';

export const LanguageSelect = () => {
  const {
    language, setLanguage,
  } = useUserLanguage();

  return (
    <ToggleDropdown
      layer={
        // just make sure it's > SlashDropdown layer
        Layer.DialogModal
      }
      placement="bottom-end"
      button={props => (
        <StyledButton
          onClick={props?.onClick}
          {...props['data-active'] && {
            className: 'force-focus',
          }}
          iconEnd={<DownIcon />}
          variant="secondary"
          useCycleColor={false}
          size="S"
        >
          {language ? getLanguageName(language) : 'Auto'}
        </StyledButton>
      )}
      content={({ hide }) => (
        <StyledSelectPanel
          options={LANGUAGES_OPTIONS}
          onOptionChange={(option) => {
            hide();
            setLanguage(option.value as Language);
          }}
          onClearValue={() => {
            hide();
            setLanguage(null);
          }}
          clearLabel="Auto"
        />
      )}
    />
  );
};
