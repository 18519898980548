import { useEffect } from 'react';

import { useNetwork } from 'src/hooks';
import { useInitialQuery } from 'src/hooks/api/useInitial';
import { useMaybeMe } from 'src/hooks/api/useMe';
import { useStartGamification } from 'src/hooks/gamification/useStartGamification';
import { usePrefetchReleases } from 'src/hooks/releases/useReleases';
import { useCommandbarShortcutListener } from 'src/hooks/shortcuts/useCommandbarShortcutListener';
import { useGlobalShortcutListener } from 'src/hooks/shortcuts/useGlobalShortcutListener';
import { useFeatureFlagIdentifier } from 'src/hooks/useFeatureFlagIdentifier';
import { useSyncAppThemeWithSystem } from 'src/hooks/useHandleSystemTheme';
import { useModListener } from 'src/hooks/useModListener';
import { useProductSlug } from 'src/hooks/usePathParams';
import { useProductRole } from 'src/hooks/useRoles';
import { resetDocImport } from 'src/reactives/docImport.reactive';
import { setLastView } from 'src/reactives/lastView.reactive';
import { initFullStory } from 'src/services/fullStory';

export const MainListeners = () => {
  const { productId } = useInitialQuery();
  const productSlug = useProductSlug();
  usePrefetchReleases(productId);
  useModListener();
  useGlobalShortcutListener();
  useCommandbarShortcutListener();
  useFeatureFlagIdentifier();
  useStartGamification();
  useNetwork();
  useSyncAppThemeWithSystem();

  const me = useMaybeMe('cache-only');

  const {
    role, product,
  } = useProductRole();

  useEffect(() => {
    setLastView({ productSlug: productSlug ?? null });
    resetDocImport();
  }, [productSlug]);

  useEffect(() => {
    initFullStory({
      me,
      plan: product?.plan,
      productSlug: product?.slug || '',
      role,
    });
  }, [me, product?.plan, role, product?.slug]);

  return null;
};
