import { CreateReleaseNotePosition } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import { AddIcon, DownArrowIcon } from '@cycle-app/ui/icons';
import {
  useDroppable,
  DndContext,
  DragOverlay,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { useEffect, useRef, useState } from 'react';
import { isPresent } from 'ts-is-present';

import { RELEASE_NOTES_NEXT_PAGINATION_SIZE } from 'src/constants/releases.constants';
import { useReleaseNotesContext, GroupName } from 'src/contexts/releaseNotesContext';
import { useMoveReleaseNote } from 'src/hooks/releases/useMoveReleaseNote';
import { useUpdateReleaseNoteGroup } from 'src/hooks/releases/useUpdateReleaseNote';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import {
  getReleaseNotesDndItems, openCreateReleaseNote, setReleaseNotesDndItems,
  useGetReleaseNotesDndItems, useGetReleasesAction,
} from 'src/reactives/releases.reactive';

import { useFeatureFlag } from '../../hooks';
import { ReleaseBugImprovementsVisibility } from '../ReleaseBugImprovementsVisibility';
import { ReleaseNoteCard } from './ReleaseNoteCard';
import { ReleaseNoteCardSortable } from './ReleaseNoteCardSortable';
import { ReleaseNoteForm } from './ReleaseNoteForm';
import { Container, Title, TextButton, Section } from './ReleaseNotes.styles';
import { ReleaseNoteSkeleton } from './ReleaseNoteSkeleton';

export const ReleaseNotes = () => {
  const { moveReleaseNote } = useMoveReleaseNote();
  const { updateReleaseNoteGroup } = useUpdateReleaseNoteGroup();
  const getReleaseNote = useReleaseNotesContext(ctx => ctx.getReleaseNote);

  const notes = {
    main: useReleaseNotesContext(ctx => ctx.main.list),
    other: useReleaseNotesContext(ctx => ctx.other.list),
  };

  const refetch = {
    main: useReleaseNotesContext(ctx => ctx.main.refetch),
    other: useReleaseNotesContext(ctx => ctx.other.refetch),
  };

  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  }));

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const lock = useRef(false);

  useEffect(() => {
    if (lock.current) return;
    queueMicrotask(() => {
      // Use queueMicrotask take make sure <NotesList /> is updated after updating the cache.
      setReleaseNotesDndItems({ main: notes.main.map(note => note.id) });
    });
  }, [notes.main]);

  useEffect(() => {
    if (lock.current) return;
    queueMicrotask(() => {
      setReleaseNotesDndItems({ other: notes.other.map(note => note.id) });
    });
  }, [notes.other]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={e => setActiveId(e.active.id)}
      onDragOver={({
        active, over,
      }) => {
        const overId = over?.id;
        const items = getReleaseNotesDndItems();
        if (overId == null || active.id in items) return;

        const activeContainer = items.main.includes(active.id) ? 'main' : 'other';
        const overContainer = overId === 'main' || items.main.includes(overId) ? 'main' : 'other';

        if (!activeContainer || !overContainer || activeContainer === overContainer) return;

        const activeItems = items[activeContainer];
        const overItems = items[overContainer];
        const overIndex = overItems.indexOf(overId);
        const activeIndex = activeItems.indexOf(active.id);
        let newIndex: number;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top >
                over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;
          newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }

        setReleaseNotesDndItems({
          [activeContainer]: items[activeContainer].filter(
            (item) => item !== active.id,
          ).filter(isPresent),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(
              newIndex,
              items[overContainer].length,
            ),
          ].filter(isPresent),
        });
      }}
      onDragEnd={async ({
        active, over,
      }) => {
        setActiveId(null);

        const overId = over?.id;
        if (!overId) return;

        const items = getReleaseNotesDndItems();

        const group = items.main.includes(active.id) ? 'main' : 'other';
        const activeIndex = items[group].indexOf(active.id);
        const overIndex = items[group].indexOf(overId);
        if (activeIndex < 0 || overIndex < 0) return;

        // Update local state
        if (active.id !== overId) {
          setReleaseNotesDndItems({ [group]: arrayMove(items[group], activeIndex, overIndex) });
        }

        const releaseNote = getReleaseNote(active.id as string);
        const overReleaseNote = getReleaseNote(overId as string);
        if (!releaseNote || !overReleaseNote) return;

        const isMovingToFirstPositionOfOtherGroup = overIndex === 0 && releaseNote.isOther !== (group === 'other');
        const isMovingToLastPositionOfOtherGroup = overIndex === items[group].length - 1 && releaseNote.isOther !== (group === 'other');

        const shouldUpdateGroup =
          releaseNote.isOther !== overReleaseNote.isOther || isMovingToFirstPositionOfOtherGroup || isMovingToLastPositionOfOtherGroup;

        if (!shouldUpdateGroup && active.id === overId) return;

        const overItem = items[group][overIndex] as string;
        const afterOverItem = (items[group][overIndex - 1] ?? '') as string;

        // Move inside another group
        if (shouldUpdateGroup) {
          lock.current = true;
          // Update group in the database
          await updateReleaseNoteGroup(active.id as string, !releaseNote.isOther);

          // Update position in the database
          await moveReleaseNote({
            id: active.id as string,
            position: {
              before: (releaseNote.position < overReleaseNote.position && !isMovingToFirstPositionOfOtherGroup) ? overItem : afterOverItem,
            },
          });

          // Refetch to update the cache
          const result = await Promise.all([
            refetch.main(items.main.length),
            refetch.other(items.other.length),
          ]);

          setReleaseNotesDndItems({
            main: result[0].map(note => note.id),
            other: result[1].map(note => note.id),
          });

          lock.current = false;

        // Move inside the same group
        } else {
          lock.current = true;

          // Update position in the database
          await moveReleaseNote({
            id: active.id as string,
            position: {
              // eslint-disable-next-line no-nested-ternary
              before: activeIndex < overIndex ? overItem : afterOverItem,
            },
          });

          // Refetch to update the cache
          const result = await refetch[group](items[group].length);

          setReleaseNotesDndItems({
            [group]: result.map(note => note.id),
          });

          lock.current = false;
        }
      }}
    >
      <Container>
        <NotesList group="main" />
        <NotesList group="other" />
        <DragOverlay>
          {activeId && <ReleaseNoteCard noteId={activeId} isOverlay />}
        </DragOverlay>
      </Container>
    </DndContext>
  );
};

const NotesList = ({ group }: { group: GroupName }) => {
  const {
    canCreateReleaseNote, canUpdateRelease,
  } = useGetPermission();
  const releaseId = useReleaseNotesContext(ctx => ctx.releaseId);
  const isReadonly = useReleaseNotesContext(ctx => ctx.isReadonly);
  const isLoading = useReleaseNotesContext(ctx => ctx[group].isLoading);
  const isLoadingMore = useReleaseNotesContext(ctx => ctx[group].isLoadingMore);
  const hasNextPage = useReleaseNotesContext(ctx => ctx[group].hasNextPage);
  const loadMore = useReleaseNotesContext(ctx => ctx[group].loadMore);
  const showBugAndImprovements = useReleaseNotesContext(ctx => ctx.showBugAndImprovements);
  const publicStatus = useReleaseNotesContext(ctx => ctx.publicStatus);
  const items = useGetReleaseNotesDndItems()[group];
  const isChangelogEnabled = useFeatureFlag('changelog').isEnabled;

  const action = useGetReleasesAction();

  const { setNodeRef } = useDroppable({
    id: group,
    disabled: items.length > 0,
  });

  const isOther = group === 'other';

  const isCreateFormOpen = action.id === releaseId && (
    (isOther && action.type === 'createOtherReleaseNote') ||
    (!isOther && action.type === 'createReleaseNote'));

  const isEndPosition = 'position' in action && action.position === CreateReleaseNotePosition.End;
  const isStartPosition = 'position' in action && action.position === CreateReleaseNotePosition.Start;

  return (
    <>
      {isOther && (
        <Title>
          Other improvements & bugs
          {!isChangelogEnabled && (
            <ActionButton
              disabled={isLoading || (isCreateFormOpen && isEndPosition)}
              onClick={() => {
                if (!canCreateReleaseNote) {
                  setLimitationsModal({ action: 'RELEASE_UPDATE' });
                  return;
                }
                openCreateReleaseNote(releaseId, isOther, CreateReleaseNotePosition.End);
              }}
            >
              <AddIcon size={12} />
            </ActionButton>
          )}
          {isChangelogEnabled && publicStatus && (
            <ReleaseBugImprovementsVisibility
              publicStatus={publicStatus}
              releaseId={releaseId}
              showBugAndImprovements={!!showBugAndImprovements}
            />
          )}
        </Title>
      )}

      <Section ref={setNodeRef}>
        {isLoading && <ReleaseNoteSkeleton {...isChangelogEnabled && { style: { height: '40px' } }} />}

        {!isLoading && (
          <>
            {isEndPosition && <ReleaseNoteForm isOther={isOther} isOpen={isCreateFormOpen} position={action.position} />}

            <SortableContext
              id={group}
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map(id => (
                <ReleaseNoteCardSortable
                  key={id}
                  noteId={id as string}
                  isDisabled={isReadonly || !canUpdateRelease}
                  isChangelogEnabled={isChangelogEnabled}
                />
              ))}
            </SortableContext>

            {isStartPosition && <ReleaseNoteForm isOther={isOther} isOpen={isCreateFormOpen} position={action.position} />}

            {isLoadingMore && <ReleaseNoteSkeleton {...isChangelogEnabled && { style: { height: '40px' } }} />}

            {!isLoadingMore && hasNextPage && (
              <TextButton onClick={loadMore}>
                <DownArrowIcon size={12} />
                {`Load ${RELEASE_NOTES_NEXT_PAGINATION_SIZE} more`}
              </TextButton>
            )}

            {!(isCreateFormOpen && isStartPosition) && !isLoadingMore && !hasNextPage && (
              <TextButton
                onClick={() => {
                  if (!canCreateReleaseNote) {
                    setLimitationsModal({ action: 'RELEASE_UPDATE' });
                    return;
                  }
                  openCreateReleaseNote(releaseId, isOther, CreateReleaseNotePosition.Start);
                }}
                disabled={isReadonly}
              >
                <AddIcon size={10} />
                New
              </TextButton>
            )}
          </>
        )}
      </Section>
    </>
  );
};
