import { typo, fadeAnimationCss } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.button<{
  $isLoading?: boolean;
  $isDisabled?: boolean;
}>`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: inherit; 
  height: 40px;
  border-radius: 29px;
  --bg: ${p => p.theme.colors.docPanel.bg};
  background: var(--bg);
  border: 1px solid ${p => p.theme.colors.border.primary};
  box-shadow: 0px 6px 22px 0px #0000001A;
  padding: 0;
  cursor: pointer;
  :hover {
    background: ${p => p.theme.colors.background.hoverSoft};
  }
  :disabled {
    cursor: default;
    pointer-events: none;
    ${p => !p.$isLoading && `
      filter: grayscale(1);
      color: ${p.theme.colors.text.disabled};
    `}
  }
  ${p => p.$isDisabled && `
    color: ${p.theme.colors.text.disabled};
    cursor: default;
    
   :hover, :active {
      background: var(--bg);
    }
  `}
`;

export const Content = styled.div`
  inset: 0;
  height: 100%;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${typo.body500}
`;

export const Title = styled.span<{ $isLoading?: boolean }>`
  ${p => p.$isLoading && fadeAnimationCss}
`;

export const RetryButton = styled.div`
  color: hsl(var(--cycle));
  filter: none;
  cursor: pointer;
`;
