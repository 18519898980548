import { Color, DocBaseFragment, ThemeType } from '@cycle-app/graphql-codegen';

import { LocalKey } from 'src/types/localStorage.types';
import { OnboardingScreen } from 'src/types/onboarding.types';
import { make } from 'src/utils/reactives.util';

export interface OnboardingState {
  step: number;
  screen: OnboardingScreen | 'password' | 'account' | 'preferences' | 'workspace';
  userColor: Color;
  theme: ThemeType;
  createdDoc?: DocBaseFragment;
  createdDocId?: string;
  initialized: boolean;
}

export const {
  hookValue: useGetOnboarding,
  getValue: getOnboarding,
  setValue: setOnboarding,
  resetValue: resetOnboarding,
} = make<OnboardingState>({
  defaultState: {
    step: 1,
    screen: OnboardingScreen.ConfigureAccount,
    userColor: Color.A,
    theme: ThemeType.Sunrise,
    initialized: false,
  },
  localKey: LocalKey.Onboarding,
});
