import { Avatar, ShyScrollbarCss, InfiniteScroll, typo, truncate } from '@cycle-app/ui';
import styled from 'styled-components';

import { PortalModalStyled } from 'src/components/DialogModal/DialogModal.styles';

export const Modal = styled(PortalModalStyled)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Users = styled.div`
  margin-bottom: 16px;
  width: 100%;
  position: relative;
`;

export const UsersHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
  border-bottom: 1px solid ${p => p.theme.colors.releases.modal.border};
`;

export const UsersList = styled(InfiniteScroll)`
  ${ShyScrollbarCss}
  scroll-snap-type: y mandatory;
  max-height: 550px;
  position: relative;
  border-bottom: 1px solid ${p => p.theme.colors.releases.modal.border};
`;

export const UserItem = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
  :not(:first-of-type) {
    border-top: 1px solid ${p => p.theme.colors.releases.modal.border};
  }
`;

export const UserAvatar = styled(Avatar)`
  flex: none;
`;

export const UserLabel = styled.div`
  flex: 1;
  ${truncate}
`;

export const ConfirmOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: ${p => p.theme.colors.background.secondaryElevated};
  padding: 6px 12px;
  border-radius: 8px;
`;
