import { BillingPlan } from '@cycle-app/graphql-codegen';

import { PageId } from 'src/constants/routing.constant';
import { useProductStatus } from 'src/reactives/product.reactive';

import { useCurrentBilling } from '../api/useProduct';
import { useFeatureFlag } from '../useFeatureFlag';

export const useRouteAccess = (pageId: PageId) => {
  const { isEnabled: isBillingPlanEnabled } = useFeatureFlag('billing-plan');
  const billing = useCurrentBilling();
  const { productStatus } = useProductStatus();

  switch (pageId) {
    case PageId.SettingsBilling: return isBillingPlanEnabled && billing?.plan === BillingPlan.Standard && productStatus !== 'trial';
    default: return true;
  }
};
