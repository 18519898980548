import { ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div``;

interface StyledActionButtonProps {
  $publicStatus: ReleasePublicStatus;
}

export const StyledActionButton = styled(ActionButton)<StyledActionButtonProps>`
  --border: transparent;
  ${p => p.$publicStatus === ReleasePublicStatus.Unpublished && `
    --bg: transparent;
    --bgHover: ${p.theme.baseColors.Grey300};
    --border: ${p.theme.colors.border.primary};
    --color: ${p.theme.colors.text.primary};
    --colorHover: ${p.theme.colors.text.secondary};
  `}
  ${p => p.$publicStatus === ReleasePublicStatus.Published && `
    --bg: #DFF3DF;
    --bgHover: #DFF3DF;
    --color: ${p.theme.baseColors.Green900};
    --colorHover: ${p.theme.baseColors.Green900};
    cursor: default;
  `}
  ${p => p.$publicStatus === ReleasePublicStatus.Editing && `
    --bg: #FFEDBF;
    --bgHover: #FFEDBF;
    --color: ${p.theme.baseColors.Yellow900};
    --colorHover: ${p.theme.baseColors.Yellow900};
  `}
  border: 1px solid var(--border);
  border-radius: 6px;
  width: 28px;
  height: 28px;
`;
