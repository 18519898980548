import { SectionType } from '@cycle-app/graphql-codegen';
import { PlaceHolderIcon, RoadmapIcon, BulbLight, InboxIcon } from '@cycle-app/ui/icons';

interface Props {
  className?: string;
  sectionType: SectionType | undefined;
}

export const BoardDefaultIcon = ({
  className, sectionType,
}: Props) => {
  if (sectionType === SectionType.Feedback) return <InboxIcon className={className} />;
  if (sectionType === SectionType.Insights) return <BulbLight className={className} />;
  if (sectionType === SectionType.Roadmaps) return <RoadmapIcon className={className} />;
  return <PlaceHolderIcon className={className} />;
};
