import { produce } from 'immer';

import { useLocation } from 'src/hooks';
import { history } from 'src/providers';
import { Location } from 'src/types/routes.types';

const getState = (location: Location) => ({
  customerId: location.state?.customerModal?.customerId ?? '',
  isOpen: location.state?.customerModal?.isOpen ?? false,
});

export const useCustomerModal = () => getState(useLocation());

export const getCustomerModal = () => getState(history.location);

export const closeCustomerModal = () => {
  if (!getCustomerModal().isOpen) return;
  history.push(produce(history.location, draft => {
    // eslint-disable-next-line no-param-reassign
    delete draft.state?.customerModal;
  }));
};

export const openCustomerModal = (customerId: string) => {
  history.push(produce(history.location, draft => {
    /* eslint-disable no-param-reassign */
    delete draft.state?.isCommandBarOpen;
    delete draft.state?.companyModal;
    draft.state = {
      ...draft.state,
      customerModal: {
        isOpen: true,
        customerId,
      },
    };
  }));
};
