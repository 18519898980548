import { Input, TextArea, ToggleInput, Tooltip } from '@cycle-app/ui';
import { Label } from '@cycle-app/ui/components/Inputs/Input/Input.styles';
import { AiIcon, LockFillIcon } from '@cycle-app/ui/icons';
import { RefObject, MouseEvent, DragEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import ImageInput from 'src/components/ImageInput/ImageInput';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useWorkspaceLogoUpdate } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { useProductUpdateSso } from 'src/hooks/product/useProductUpdateSso';
import { useProductUpdateSupportAccess } from 'src/hooks/product/useProductUpdateSupportAccess';
import { useToggleCollaboratorPrivacy } from 'src/hooks/product/useToggleCollaboratorPrivacy';
import { useLoader } from 'src/hooks/useLoader';
import { useProductUpdate } from 'src/hooks/useProductUpdate';

import { Header, SavingLabelStyled } from '../Settings.styles';
import {
  LogoUploadLine,
  Inputs,
  Logo,
  LogoUploadButton,
  SsoContainer,
  SsoTitle,
  SsoDomain,
  Container,
} from './SettingsWorkspace.styles';

const SettingsWorkspace = () => {
  const { product } = useProduct();
  const {
    update: updateWorkspaceLogo,
    isLoading: isUpdateWorkspaceLogoLoading,
  } = useWorkspaceLogoUpdate();

  const mutation = useProductUpdate();
  const updateProduct = useDebouncedCallback(mutation.updateProduct, INPUT_ONCHANGE_DEBOUNCE);
  const { updateSso } = useProductUpdateSso();
  const { updateSupportAccess } = useProductUpdateSupportAccess();
  const { toggleCollaboratorPrivacy } = useToggleCollaboratorPrivacy(product?.collaboratorPrivacy);

  const loading = mutation.isUpdatingProduct || isUpdateWorkspaceLogoLoading;
  useLoader({ loading });

  if (!product) return null;

  return (
    <div>
      <Header>
        <h1>General</h1>
        <SavingLabelStyled />
      </Header>

      <Container>

        <Inputs>
          <Input
            id="name"
            label="Name"
            type="text"
            defaultValue={product?.name}
            onChange={e => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              updateProduct({
                variables: {
                  productId: product.id,
                  name: e.target.value.trim(),
                },
              });
            }}
            tooltipLabel="This impacts Cycle AI requests"
            tooltipIcon={<AiIcon size={12} />}
            tooltipProps={{ placement: 'top' }}
          />

          <div>
            <Label>Logo</Label>
            <ImageInput
              label="Logo"
              previewModalTitle="New logo preview"
              previewModalSubmitLabel="Set new product logo"
              renderPreview={(imageSrc) => (
                <Logo
                  src={imageSrc}
                  alt="logo"
                  $size={250}
                />
              )}
              onChange={onChangeLogo}
            >
              {((inputRef, chooseFile) => (
                <LogoUploadLine>
                  {product?.logo?.url && (
                    <Logo
                      src={product?.logo?.url}
                      alt="logo"
                      $size={42}
                    />
                  )}
                  <LogoUploadButton
                    onClick={openLogoFileInput(inputRef)}
                    onDrop={makeHandleDropLogo(chooseFile)}
                    onDragOver={(e: DragEvent) => e.preventDefault()}
                  >
                    Click to replace or drag and drop here
                  </LogoUploadButton>
                </LogoUploadLine>
              ))}
            </ImageInput>
          </div>

          <Input
            id="domain"
            label="Domain"
            type="text"
            placeholder="Some placeholder"
            defaultValue={product?.domain}
            readOnly
          />

          <Input
            id="key"
            label="Key"
            type="text"
            defaultValue={product?.key}
            readOnly
          />
        </Inputs>

        <TextArea
          id="description"
          label="Description"
          tooltipLabel="This impacts Cycle AI requests"
          tooltipIcon={<AiIcon size={12} />}
          tooltipProps={{ placement: 'top' }}
          defaultValue={product?.description ?? ''}
          onChange={e => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            updateProduct({
              variables: {
                productId: product.id,
                description: e.target.value.trim(),
              },
            });
          }}
        />

        <SsoContainer>
          <div>
            <SsoTitle>Allow users with the same email domain to join the workspace</SsoTitle>
            <SsoDomain>
              Current domain : @
              <Tooltip
                content="This domain is related to your email address. To update it, please contact our support"
                placement="top"
                style={{ display: 'inline-block' }}
              >
                {product.ssoDomain}
              </Tooltip>
              <LockFillIcon />
            </SsoDomain>
          </div>
          <div style={{ paddingTop: '4px' }}>
            <ToggleInput
              id="sso"
              checked={product.sso}
              onChange={() => updateSso(!product.sso)}
            />
          </div>
        </SsoContainer>

        <SsoContainer>
          <div>
            <SsoTitle>Collaborators can access only the feedback docs they are subscribed to</SsoTitle>
            <SsoDomain>
              Collaborators are subscribed to feedback docs for which they are assignee of or in which they have been tagged
            </SsoDomain>
          </div>
          <div style={{ paddingTop: '4px' }}>
            <ToggleInput
              id="collaborator-privacy"
              checked={product.collaboratorPrivacy}
              onChange={toggleCollaboratorPrivacy}
            />
          </div>
        </SsoContainer>

        <SsoContainer>
          <div>
            <SsoTitle>Authorize Cycle’s team to log into the workspace for support purposes</SsoTitle>
          </div>
          <div style={{ paddingTop: '4px' }}>
            <ToggleInput
              id="support"
              checked={!!product.allowSupportAccess}
              onChange={() => updateSupportAccess(!product.allowSupportAccess)}
            />
          </div>
        </SsoContainer>
      </Container>
    </div>
  );

  function openLogoFileInput(ref: RefObject<HTMLInputElement>) {
    return (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      ref.current?.click();
    };
  }

  async function onChangeLogo(logoFile: File) {
    if (product) {
      await updateWorkspaceLogo({
        variables: {
          productId: product.id,
          logo: {
            avatar: logoFile,
          },
        },
      });
    }
  }

  function makeHandleDropLogo(chooseFile: (file: File) => void) {
    return (e: DragEvent) => {
      e.preventDefault();
      const item = e.dataTransfer.items[0];
      if (item?.kind === 'file') {
        const file = item.getAsFile();
        if (file) {
          chooseFile(file);
        }
      }
    };
  }
};

export default SettingsWorkspace;
