import { ColorTheme } from '@cycle-app/utilities';
import { useTheme } from 'styled-components';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

type CycleStandardProps = Props & ({
  fill?: string;
  variant?: never;
} | {
  fill?: never;
  variant?: 'primary' | 'secondary';
});

export const CycleStandardIcon = ({
  className = '', variant, ...props
}: CycleStandardProps) => {
  const theme = useTheme();
  let fill = props.fill ?? '#EA1093';
  if (variant === 'primary') fill = ColorTheme.Cycle;
  if (variant === 'secondary') fill = theme.isDark ? ColorTheme.Grey100 : ColorTheme.Grey900;
  return (
    <Svg className={className} width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
      <path fillRule="evenodd" clipRule="evenodd" d="M24 11.4384C23.9982 11.6254 23.985 11.8028 23.8799 12.0852C23.7546 12.4399 23.5612 12.7646 23.3113 13.0417C23.0615 13.3187 22.7604 13.542 22.4249 13.6973C22.0894 13.8535 21.7274 13.9399 21.3592 13.9505C20.9911 13.9611 20.6247 13.8976 20.2812 13.7617C19.9378 13.6258 19.6243 13.4211 19.3586 13.1599C19.0928 12.8987 18.8818 12.5855 18.7361 12.2396C18.6611 12.0604 18.6125 11.8566 18.5825 11.6484C18.3997 8.17707 15.5259 5.41785 12.0066 5.41785C8.48736 5.41785 5.42104 8.36502 5.42104 11.9996C5.42104 15.6341 8.36905 18.5804 12.0057 18.5804C15.1886 18.5804 17.8435 16.3241 18.4571 13.3249C18.7079 13.6708 19.0028 13.9832 19.3559 14.2232C19.5661 14.3652 19.7938 14.5047 19.9731 14.5761C20.4225 14.7535 20.9019 14.8373 21.3831 14.8232C21.8643 14.8091 22.3384 14.6961 22.7763 14.4923C22.8575 14.4544 22.9502 14.3988 23.0491 14.3317C23.3652 14.1182 23.6574 13.8658 23.9091 13.5799C23.1356 19.4601 18.1013 24 12.0057 24C5.37512 23.9991 0 18.6263 0 11.9996C0 5.37285 5.37512 0 12.0066 0C18.6381 0 23.7069 5.07195 24 11.4384Z" fill={fill} />
    </Svg>
  );
};
