import { LinearStatus as LinearStatusType } from '@cycle-app/graphql-codegen';
import { IntegrationCardSkeleton, EditorIntegrationCard } from '@cycle-app/ui';
import { LinearIcon } from '@cycle-app/ui/icons';
import { NodeViewRendererProps } from '@tiptap/react';
import { FC, useEffect } from 'react';

import { ContainerNodeView, SlotText } from 'src/components/Integrations/IntegrationsCommon.styles';
import { LinearStatus as LinearStatusIcon } from 'src/components/LinearStatus/LinearStatus';
import { ErrorMessage } from 'src/constants/errors.constants';
import { DocLinearProvider } from 'src/contexts/docLinearContext';
import { useEditorContext } from 'src/contexts/editorContext';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useLinearIssue } from 'src/hooks/api/queries/integrations/useLinearIssue';
import { useLinearProject } from 'src/hooks/api/queries/integrations/useLinearProject';
import { LinearIssuesCommandAttributes } from 'src/types/editor.types';
import { addErrorToaster } from 'src/utils/errorToasters.utils';

import { IssueEstimate } from '../DocLinear/IssueEstimate';
import { IssuePriority } from '../DocLinear/IssuePriority';
import { IssueProject } from '../DocLinear/IssueProject';
import { EditorLinearDragMenu } from '../EditorLinearDragMenu';
import { GithubDescription } from '../GithubDescription/GithubDescription';
import { IssueAssignee } from './IssueAssignee';
import { IssueStatus } from './IssueStatus';
import { LinearEditorMentionProject } from './LinearEditorMentionProject';
import { InfosList } from './LinearEditorMentionView.styles';

type RenderPreTitleSlotParams = {
  publicId?: string;
  status?: LinearStatusType;
  teamKey?: string;
  teamName?: string;
};

interface Props extends NodeViewRendererProps {
  selected: boolean;
  updateAttributes: (attr: Partial<LinearIssuesCommandAttributes>) => void;
}
export const LinearEditorMentionView: FC<React.PropsWithChildren<Props>> = ({
  node, selected, getPos, updateAttributes,
}) => {
  const integrationId = useWorkspaceContext(ctx => ctx.linearIntegrationId);
  const isLinearInstalled = useWorkspaceContext(ctx => ctx.isLinearInstalled);
  const docId = useEditorContext(ctx => ctx.doc?.id);
  const isInert = useEditorContext(ctx => ctx.isInert);
  const isIssue = node.attrs.type === 'issue' || !node.attrs.type;
  const isProject = node.attrs.type === 'project';

  const issueId = isIssue ? node.attrs.id : null;
  const projectId = isProject ? node.attrs.id : null;

  const {
    issue, refetch,
  } = useLinearIssue({
    issueId,
    integrationId,
    skip: !isLinearInstalled,
    onError: () => addErrorToaster({ message: ErrorMessage._GENERIC }),
  });
  const {
    project, refetch: projectRefetch,
  } = useLinearProject(projectId, integrationId);

  const editor = useEditorContext(ctx => ctx.editor);

  useEffect(() => {
    if (issue) {
      queueMicrotask(() => {
        updateAttributes({
          id: issue.id,
          publicId: issue.publicId,
          url: issue.url,
          title: issue.title,
          teamKey: issue.team?.key || null,
          teamName: issue.team?.name || null,
          assignee: issue.assignee,
          status: issue.status,
          description: issue.description ?? null,
          type: 'issue',
        });
      });
    }
    if (project) {
      queueMicrotask(() => {
        updateAttributes({
          id: project.id,
          url: project.url,
          type: 'project',
          title: project.name,
          icon: project.icon,
          iconColor: project.color,
          projectProgress: project.progress,
          projectIssuesLength: project.issues.length,
        });
      });
    }
  }, [project, issue, updateAttributes]);

  return (
    <ContainerNodeView $isSelected={!isInert && selected}>
      <div style={{ position: 'relative' }}>
        {editor.isEditable && docId && (
          <EditorLinearDragMenu
            automationId={isIssue ? issue?.id : project?.id}
            docId={docId}
            editor={editor}
            getPos={getPos}
            node={node}
            onRefetch={isIssue ? refetch : projectRefetch}
            type={isIssue ? 'issue' : 'project'}
            url={isIssue ? issue?.url : project?.url}
          />
        )}
        <DocLinearProvider docId={docId || ''} integrationId={integrationId || ''} automationId={project?.id} automationUrl={project?.url}>
          {isIssue && renderIssue()}
          {isProject && renderProject()}
        </DocLinearProvider>
      </div>
    </ContainerNodeView>
  );

  function renderProject() {
    const {
      id, url, icon, iconColor, title, projectProgress, projectIssuesLength,
    } = node.attrs as LinearIssuesCommandAttributes;
    if (!docId || !id || !url) return <IntegrationCardSkeleton />;
    return (
      <LinearEditorMentionProject
        color={iconColor}
        icon={icon}
        id={id}
        isSelected={selected}
        issuesLength={projectIssuesLength}
        name={title}
        notFound={!project?.id}
        progress={projectProgress}
        refetch={projectRefetch}
        url={url}
      />
    );
  }

  function renderIssue() {
    const {
      publicId, url, status, title, assignee,
    } = node.attrs;

    const teamKey = node.attrs.teamKey ?? issue?.team?.key;
    const teamName = node.attrs.teamName ?? issue?.team?.name;

    const showSkeleton = !url && !title && !publicId;

    if (showSkeleton) return <IntegrationCardSkeleton />;

    const assigneeData = assignee || issue?.assignee;
    const statusData = status || issue?.status;
    return (
      <EditorIntegrationCard
        isSelected={selected}
        title={title ?? issue?.title ?? ''}
        preTitleSlot={renderPreTitleSlot({
          publicId,
          status,
          teamKey,
          teamName,
        })}
        description={isLinearInstalled && issue?.description && <GithubDescription description={issue.description} />}
        hasExpandContent={isLinearInstalled}
        assignees={issue && (
          <IssueAssignee
            issue={issue}
            assignee={assigneeData}
            updateAttributes={updateAttributes}
          />
        )}
        footerSlot={issue && (
          <InfosList>
            <IssueStatus
              issue={issue}
              status={statusData}
              updateAttributes={updateAttributes}
            />
            <IssuePriority issue={issue} />
            <IssueEstimate issue={issue} />
            <IssueProject issue={issue} />
          </InfosList>
        )}
        onClick={() => {
          const u = node.attrs.url || issue?.url;
          if (u) {
            window.open(u, '_blank');
          }
        }}
      />
    );
  }

  function renderPreTitleSlot({
    publicId, teamKey, teamName, status,
  }:RenderPreTitleSlotParams) {
    const text = teamKey && teamName && publicId ? `${teamKey}-${publicId} · ${teamName}` : null;
    const linearStatus = status || issue?.status || null;

    return (
      <>
        <LinearIcon />
        {!!text && <SlotText>{text}</SlotText> }
        {!!linearStatus && <LinearStatusIcon status={linearStatus} />}
      </>
    );
  }
};
