import { GenerateUserTokenDocument, JobTitle as JobTitleType, MateFragment, Role } from '@cycle-app/graphql-codegen';
import { InfiniteScroll, SelectOption, Badge, Tooltip, TooltipProps } from '@cycle-app/ui';
import { TrashIcon, PenIcon, AddIcon, CustomerIconOutline, LogoutIcon } from '@cycle-app/ui/icons';
import { useCallback, useEffect, useState } from 'react';

import DotsMenuLayer from 'src/components/DotsMenuLayer/DotsMenuLayer';
import { useMaybeMeV2, useSafeMutation } from 'src/hooks';
import useProductMembersMutations from 'src/hooks/api/mutations/useProductMembersMutations';
import { useProduct } from 'src/hooks/api/useProduct';
import { useUsers } from 'src/hooks/api/useUsers';
import useQueryParams from 'src/hooks/useQueryParams';
import { setAddMember } from 'src/reactives/addMember.reactive';
import { setAuth } from 'src/reactives/auth.reactive';
import { useGetPermission } from 'src/reactives/permission.reactive';
import { jobsLabel } from 'src/utils/jobs.util';
import { addToaster } from 'src/utils/toasters.utils';
import { getUserLabel } from 'src/utils/users.util';

import { Header, SavingLabelStyled, HeaderAction } from '../Settings.styles';
import { SettingsUserChangeProductRoleModal } from './SettingsUserChangeProductRoleModal';
import SettingsUserRemoveUserModal from './SettingsUserRemoveUserModal';
import { SettingsUserResend } from './SettingsUserResend';
import {
  JobTitle,
  Content,
  UsersListHeader,
  NameAndEmailContainer,
  ActionsColumnName,
  GridLine,
  UserAvatar,
  NameCell,
  FullName,
  Email,
  DotsMenuContainer,
  StyledShyScrollbar,
  ProductRole,
  DefaultAssigneeLabel,
  DefaultAssigneeLabelContent,
  MessageAvatar, UserLabel,
} from './SettingsUsers.styles';

const planMessage: Partial<SelectOption> = {
  tooltipContent: 'You need at least 1 maker',
  tooltipPlacement: 'bottom',
  tooltipOffset: [0, 20],
};

const SettingsUsers = () => {
  const { me } = useMaybeMeV2();
  const queryParams = useQueryParams();
  const permission = useGetPermission();
  const { product } = useProduct();
  const totalUsers = product && product.users.edges.length + product.notSignedUpUsers.edges.length;
  const {
    users,
    loading,
    pageInfo,
    fetchMore,
  } = useUsers();
  const [userToRemove, setUserToRemove] = useState<MateFragment | null>(null);
  const [userToEdit, setUserToEdit] = useState<MateFragment | null>(null);
  const { updateUserDefaultAssignee } = useProductMembersMutations();
  const loadMoreUsers = useCallback(async () => {
    if (pageInfo.endCursor) {
      await fetchMore(pageInfo.endCursor ?? '');
    }
  }, [fetchMore, pageInfo.endCursor]);

  const queryInvite = queryParams.get('invite');
  const queryMaker = queryParams.get('maker');

  useEffect(() => {
    if (queryInvite) {
      setAddMember({
        visible: true,
        initialEmail: queryInvite,
      });
    } else if (queryMaker) {
      const user = users.find(u => u.id === queryMaker);
      if (!user) return;
      setUserToEdit(user);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInvite, queryMaker]);

  const [generateUserToken] = useSafeMutation(GenerateUserTokenDocument, {
    onCompleted: (data) => {
      if (!data.generateUserToken) return;
      const user = data.generateUserToken.me;
      setAuth({
        token: data.generateUserToken.token,
        userId: user.id,
      });
      addToaster({
        title: 'Successfull authentication',
        message: () => (
          <>
            <span>You are now authenticated as </span>
            <MessageAvatar user={user} size={16} />
            <UserLabel>{getUserLabel(user)}</UserLabel>
          </>
        ),
      });
    },
  });

  return (
    <>
      <Header>
        <h1>
          Members
          {totalUsers !== null && <Badge>{totalUsers}</Badge>}
        </h1>
        <SavingLabelStyled />
        <HeaderAction
          size="L"
          iconStart={<AddIcon size={12} />}
          onClick={() => {
            setAddMember({ visible: true });
          }}
        >
          Add member
        </HeaderAction>
      </Header>

      <Content>
        <UsersListHeader>
          <div>Name</div>
          <div>Job</div>
          <div>
            <Tooltip
              placement="top"
              withWrapper={false}
              // eslint-disable-next-line max-len
              content="Makers can process feedback and create roadmaps & releases. Collaborators can create feedback and features and collaborate on roadmaps."
            >
              Role
            </Tooltip>
          </div>
          <ActionsColumnName> </ActionsColumnName>
        </UsersListHeader>

        <StyledShyScrollbar>
          <InfiniteScroll
            isLoading={loading}
            hasMoreData={pageInfo.hasNextPage}
            loadMore={loadMoreUsers}
          >
            {users.map(user => {
              const canDelete = user.email !== me?.email && user.productRole !== Role.Admin;
              const canUpdateRole =
              (user.productRole === Role.Maker && permission.canUpdateUserRoleMaker) ||
              (user.productRole === Role.Collaborator && permission.canUpdateUserRoleCollaborator);
              // Separate rule: button display is tested if canUpdateRole/canDelete,
              // this one is used to disable and add the tooltip message for minimum 1 maker.
              const allowUpdateOrDelete = user.productRole !== Role.Maker || (product?.nbMakers || 0) > 1;

              const options: SelectOption[] = [];

              // Authenticate as someone else (superadmin option)
              if (me?.role === Role.SuperAdmin && me.email !== user.email) {
                options.push({
                  value: 'authenticate-as',
                  label: 'Authenticate as',
                  icon: <LogoutIcon />,
                  disabled: user.isPending,
                  onSelect: () => generateUserToken({ variables: { userId: user.id } }),
                });
              }

              if (canUpdateRole) {
                options.push({
                  value: 'edit',
                  label: 'Edit role',
                  icon: <PenIcon />,
                  onSelect: () => setUserToEdit(user),
                  ...!allowUpdateOrDelete && {
                    disabled: true,
                    ...planMessage,
                  },
                });
              }
              if (!user.isPending) {
                options.push({
                  value: 'updateDefaultAssignee',
                  label: 'Make default assignee',
                  icon: <CustomerIconOutline />,
                  disabled: product?.defaultAssignee?.id === user?.id,
                  onSelect: () => user && updateUserDefaultAssignee(user),
                });
              }
              if (canDelete) {
                options.push({
                  value: 'remove',
                  label: 'Remove',
                  icon: <TrashIcon />,
                  onSelect: () => setUserToRemove(user),
                  ...!allowUpdateOrDelete && {
                    disabled: true,
                    ...planMessage,
                  },
                });
              }
              return (
                <GridLine key={user.id}>
                  <NameCell>
                    <UserAvatar user={user} />
                    <NameAndEmailContainer>
                      <FullName>{`${user.firstName} ${user.lastName}`}</FullName>
                      <Email>{user.email}</Email>
                    </NameAndEmailContainer>
                  </NameCell>

                  <JobTitle aria-disabled={user.isPending}>
                    {user.isPending
                      ? 'Not signed up yet'
                      : jobsLabel[user.jobTitle as JobTitleType]}
                  </JobTitle>

                  <ProductRole>
                    <Tooltip
                      placement="top"
                      withPortal
                      {...getRoleTooltipProps(user.productRole)}
                    >
                      {user.productRole}
                    </Tooltip>
                  </ProductRole>

                  {(canDelete || canUpdateRole) && (
                    <DotsMenuContainer>
                      {user.isPending && <SettingsUserResend userId={user.id} email={user.email} />}
                      {!user.isPending && user.id === product?.defaultAssignee?.id && (
                        <Tooltip
                          placement="top"
                          withPortal
                          content={(
                            <DefaultAssigneeLabelContent>
                              Default assignee is the member who is assigned on feedback by default when no assignee is found
                            </DefaultAssigneeLabelContent>
                          )}
                        >
                          <DefaultAssigneeLabel>Default assignee</DefaultAssigneeLabel>
                        </Tooltip>
                      )}
                      <DotsMenuLayer
                        tooltip="Member actions"
                        placement="bottom-start"
                        options={options}
                      />
                    </DotsMenuContainer>
                  )}
                </GridLine>
              );
            })}
          </InfiniteScroll>
        </StyledShyScrollbar>
      </Content>
      {userToRemove && (
        <SettingsUserRemoveUserModal
          user={userToRemove}
          onClose={() => setUserToRemove(null)}
        />
      )}
      {userToEdit && (
        <SettingsUserChangeProductRoleModal
          user={userToEdit}
          onClose={() => setUserToEdit(null)}
        />
      )}
    </>
  );
};

export default SettingsUsers;

const getRoleTooltipProps = (role?: Role | null): TooltipProps => {
  if (role === Role.Collaborator) {
    return {
      content: 'Collaborators can create feedback and features and collaborate on roadmaps.',
    };
  }

  if (role === Role.Maker) {
    return {
      content: 'Makers can process feedback and create roadmaps & releases.',
    };
  }

  return {
    content: '',
    disabled: true,
  };
};
