import { DoctypeTemplateCategory } from '@cycle-app/graphql-codegen';
import { Button } from '@cycle-app/ui';
import { FC, useMemo } from 'react';

import templateIllustration from 'src/assets/template-illustration.svg';
import useDoctypeTemplates from 'src/hooks/api/useDoctypeTemplates';
import { setTemplate } from 'src/reactives/template.reactive';

import {
  ContentTemplate,
  ContentTemplateActions,
  ContentTemplateEmpty,
  ContentTemplateEmptyWithFrame,
  ContentTemplateIllustration,
  DoctypeTemplates,
  SectionTitle,
} from './SettingsDocType.styles';

interface Props {
  docTypeId: string;
  isBuiltIn: boolean;
  isFeedback: boolean;
  templateTitle?: string;
  templateId?: string;
}

export const SettingsDoctypeTemplate: FC<React.PropsWithChildren<Props>> = ({
  isBuiltIn, isFeedback, templateTitle, templateId, docTypeId,
}) => {
  const { templates } = useDoctypeTemplates();

  const feedbackTemplate = useMemo(() => (
    isFeedback
      ? templates.find(template => template.category === DoctypeTemplateCategory.UserResearch && template.title.match(/feedback/i))
      : null),
  [isFeedback, templates]);

  if (templateId) {
    return (
      <DoctypeTemplates
        style={{
          gridColumnStart: isBuiltIn ? '1' : '2',
          gridColumnEnd: isBuiltIn ? '1' : '2',
        }}
      >
        <SectionTitle>Templates</SectionTitle>
        <ContentTemplate>
          {templateTitle || 'No content template'}
          <Button
            onClick={() => setTemplate({
              admin: false,
              selectedTemplateId: templateId,
              mode: 'edit',
              modalVisible: true,
              docTypeId,
            })}
          >
            Open
          </Button>
        </ContentTemplate>
      </DoctypeTemplates>
    );
  }

  const emptyState = (
    <>
      <ContentTemplateIllustration alt="template illustration" src={templateIllustration} />
      <p>Create one from scratch or browse our template library</p>
      <ContentTemplateActions>
        <Button
          variant="secondary"
          size="M"
          onClick={() => setTemplate({
            admin: false,
            docTypeId,
            initialView: 'creation',
            modalVisible: true,
            mode: 'list',
          })}
        >
          Create template
        </Button>
        <Button
          variant="secondary"
          size="M"
          onClick={() => setTemplate({
            admin: false,
            docTypeId,
            modalVisible: true,
            mode: 'list',
            selectedTemplateId: feedbackTemplate?.id,
          })}
        >
          Browse template library
        </Button>
      </ContentTemplateActions>
    </>
  );

  if (isBuiltIn) {
    return (
      <ContentTemplateEmptyWithFrame>
        <SectionTitle>Templates</SectionTitle>
        {emptyState}
      </ContentTemplateEmptyWithFrame>
    );
  }

  return <ContentTemplateEmpty>{emptyState}</ContentTemplateEmpty>;
};
