import { DoctypeFragment } from '@cycle-app/graphql-codegen';
import { Tooltip, ActionButton, Flex } from '@cycle-app/ui';
import { TrashIcon, PenIcon, QuestionOutlineIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { SettingsRoadmapsDocType } from 'src/app/Main/Settings/SettingsRoadmapsDocType';
import { variantsContentFromLeft } from 'src/components/DialogModal/DialogModal.motion';
import { InsightTypeDeleteModal } from 'src/components/DoctypeDeleteModal/InsightTypeDeleteModal';
import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { DoctypesEditCommonModal } from 'src/components/DoctypesEditCommonModal/DoctypesEditCommonModal';
import { PageId } from 'src/constants/routing.constant';
import { useOptimizedBooleanState } from 'src/hooks';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { hasInsightChildren, findInsight, getDocTypeName } from 'src/utils/docType.util';

import { AddDocTypeLinkedToRoadmaps } from './AddDocTypeLinkedToRoadmaps';
import {
  Container, Parent, DocTypeName, Actions, H2, PortalModalStyled, DocTypeSettingsContainer,
} from './LinkedToRoadmaps.styles';

export const insightTypeSettingTitle = (
  <H2>
    Insight types
    <Tooltip
      content="What kinds of insights do you want to look for in your feedback?"
      placement="top"
      width={390}
    >
      <QuestionOutlineIcon />
    </Tooltip>
  </H2>
);

export const LinkedToRoadmaps = () => {
  const { docTypes } = useGetDocTypes();
  const docTypesList = Object.values(docTypes);
  const parents = docTypesList.filter(hasInsightChildren);
  const insight = findInsight(docTypesList);
  const [selectedDocTypeId, setSelectedDocTypeId] = useState<string | undefined>(undefined);
  if (!insight) return null;
  return (
    <Container>
      <Flex $justify="space-between" $widthFull>
        {insightTypeSettingTitle}
        <AddDocTypeLinkedToRoadmaps variant="secondary" placement="bottom-end" />
      </Flex>

      {parents.map(parent => (
        <ParentDocType
          key={parent.id}
          parent={parent}
          isUnlinkable={parents.length > 1}
          onClick={(id) => setSelectedDocTypeId(id)}
        />
      ))}

      {selectedDocTypeId && (
        <PortalModalStyled
          hide={() => setSelectedDocTypeId(undefined)}
          motionVariants={variantsContentFromLeft}
          placement="right"
          invisibleMask
        >
          <DocTypeSettingsContainer>
            <SettingsRoadmapsDocType
              doctypeId={selectedDocTypeId}
              onClose={() => setSelectedDocTypeId(undefined)}
              noBorder
            />
          </DocTypeSettingsContainer>
        </PortalModalStyled>
      )}
    </Container>
  );
};

const ParentDocType = ({
  parent, isUnlinkable, onClick,
}: {
  parent: DoctypeFragment;
  isUnlinkable?: boolean;
  onClick?: (id: string)=> void;
}) => {
  const [isDeleteModalVisible, {
    setTrueCallback: setShowDeleteModal,
    setFalseCallback: setHideDeleteModal,
  }] = useOptimizedBooleanState(false);

  const { navigate } = useNavigateToSettings();

  const [isEditModalVisible, { toggleCallback: toggleEditModal }] = useOptimizedBooleanState(false);

  return (
    <>
      <Parent
        role="button"
        tabIndex={0}
        onClick={() => {
          if (onClick) {
            onClick(parent.id);
          } else {
            navigate(PageId.SettingsRoadmapsDocType, { doctypeId: parent.id });
          }
        }}
      >
        <DocTypeIcon doctype={parent} />
        <DocTypeName>
          {getDocTypeName(parent)}
        </DocTypeName>

        {isUnlinkable && (
          <Actions>
            <ActionButton
              tooltip="Edit doc type"
              tooltipPlacement="top"
              onClick={e => {
                e.stopPropagation();
                toggleEditModal();
              }}
            >
              <PenIcon size={14} />
            </ActionButton>

            <ActionButton
              tooltip="Delete"
              tooltipPlacement="top"
              onClick={e => {
                e.stopPropagation();
                setShowDeleteModal();
              }}
            >
              <TrashIcon size={12} />
            </ActionButton>
          </Actions>
        )}
      </Parent>

      {isEditModalVisible && (
        <DoctypesEditCommonModal
          doctype={parent}
          onHide={toggleEditModal}
        />
      )}

      {isDeleteModalVisible && (
        <InsightTypeDeleteModal
          doctype={parent}
          hide={setHideDeleteModal}
        />
      )}
    </>
  );
};
