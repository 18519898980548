import { PageId } from 'src/constants/routing.constant';
import { useBoardSections } from 'src/hooks/api/useBoardSections';
import { useNavigate } from 'src/hooks/useNavigate';
import { getLastInboxBoard } from 'src/reactives/lastView.reactive';
import { getBoardSlug } from 'src/utils/slug.util';

export const useGetInboxDefaultUrl = () => {
  const { inbox } = useBoardSections();
  const { getUrl } = useNavigate();
  const { boardSlug } = getLastInboxBoard();

  return () => getUrl(PageId.InboxView, {
    // the last viewed feedback view may be deleted or may be from another workspace
    // so we need to check if the view slug exists in the current workspace
    // if not, we use the first view in the feedback section
    boardSlug: inbox.some(view => boardSlug?.includes(view.id))
      ? boardSlug
      : getBoardSlug(inbox[0]),
  });
};
