import { ChangeProductLogoDocument } from '@cycle-app/graphql-codegen';
import { produce } from 'immer';

import { Events, Objects } from 'src/constants/analytics.constants';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { getInitial, setInitial } from 'src/reactives/initial.reactive';
import { trackAnalytics } from 'src/utils/analytics/analytics';

export const useWorkspaceLogoUpdate = () => {
  const [update, { loading }] = useSafeMutation(ChangeProductLogoDocument, {
    onCompleted: (data) => {
      trackAnalytics(Events.ProductUpdated, { object: Objects.ProductLogo });
      const logo = data.updateProduct?.logo;
      if (!logo) return;
      setInitial({
        products: produce(getInitial().products, draft => {
          const i = draft.findIndex(p => p.id === data.updateProduct?.id);
          const product = draft[i];
          if (product) product.logo = logo;
        }),
      });
    },
  });

  return {
    update,
    isLoading: loading,
  };
};
