import {
  NODE_TABLE_OF_CONTENT_NAME,
  GITHUB_EXTENSION_NAME,
  IFRAMELY_EXTENSION_NAME,
  LINEAR_EXTENSION_NAME,
  NOTION_EXTENSION_NAME,
  VIDEO_EXTENSION_NAME,
  AUDIO_EXTENSION_NAME,
  LINEAR_EXTENSION_PROJECT_NAME,
} from '@cycle-app/editor-extensions';
import { SelectLine, Popover } from '@cycle-app/ui';
import { MoreHorizIcon } from '@cycle-app/ui/icons';
import { useHotkeys } from '@cycle-app/utilities';
import { Editor, isTextSelection } from '@tiptap/react';
import { FC, useMemo, useState, useEffect, useRef } from 'react';
import { Instance } from 'tippy.js';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { Action } from 'src/components/Editor/Action';
import LinkBubble from 'src/components/Editor/LinkBubble/LinkBubble';
import { useEditorContext } from 'src/contexts/editorContext';
import { useFeatureFlag } from 'src/hooks';
import { useCheckMonthlyAiQueries } from 'src/hooks/billing/useCheckMonthlyAiQueries';
import useEditorLogic from 'src/hooks/editor/useEditorLogic';
import useDropdown from 'src/hooks/useDropdown';
import { useEditorAi, getPermission, setLimitationsModal } from 'src/reactives';
import { useThreadsPanel } from 'src/reactives/comments.reactive';
import { useEditorIsDraggingState } from 'src/reactives/editor.reactive';
import { useIsMobile, useIsSmallScreen } from 'src/reactives/responsive.reactive';
import { ActionCategory, toolbarActions, ActionId, ActionCategoryData } from 'src/services/editor/editorActions';
import { Layer } from 'src/types/layers.types';
import { posToDOMRect } from 'src/utils/editor/editor.utils';

import { ButtonAction } from '../Action/Action.styles';
import LinkDropdown from '../LinkDropdown/LinkDropdown';
import { LinkInput } from '../LinkInput';
import { motionProps } from './Bubble.motion';
import { Container, Category } from './Bubble.styles';

interface Props {
  className?: string;
  categories?: ActionCategory[];
  disabledActions?: ActionId[];
  isFeedbackDocType?: boolean;
}

const Bubble: FC<React.PropsWithChildren<Props>> = ({
  className,
  categories = ['ai', 'insight', 'comment', 'title', 'text', 'lists', 'elements', 'integrations'],
  disabledActions = [],
}) => {
  const isSmallScreen = useIsSmallScreen();
  const editor = useEditorContext(ctx => ctx.editor);
  const isDragging = useEditorIsDraggingState();
  const editorId = useEditorContext(ctx => ctx.id);
  const { getDocTitlesFromSelectedText } = useEditorLogic();
  const dropdownProps = useDropdown({ layer: Layer.Dropdown });
  const [{ visible: aiVisible }] = useEditorAi();

  const [isActive, setIsActive] = useState(true);

  const { section } = useThreadsPanel();
  const allowedActions = useMemo(
    () => toolbarActions.filter(action => categories.includes(action.category)),
    [categories],
  );
  const { isEnabled: isTextColorEnabled } = useFeatureFlag('editor-text-color');

  const hasSelectedMultipleDocs = useMemo(
    () => getDocTitlesFromSelectedText(editor.state).selectionValues.length > 1,
    [getDocTitlesFromSelectedText, editor.state],
  );

  const isActiveLink = editor.isActive('link');

  const isActiveBlockWithoutMenu =
    editor.isActive('codeBlock') ||
    editor.isActive('image') ||
    editor.isActive('file') ||
    editor.isActive(GITHUB_EXTENSION_NAME) ||
    editor.isActive(LINEAR_EXTENSION_NAME) ||
    editor.isActive(LINEAR_EXTENSION_PROJECT_NAME) ||
    editor.isActive(NOTION_EXTENSION_NAME) ||
    editor.isActive(IFRAMELY_EXTENSION_NAME) ||
    editor.isActive(NODE_TABLE_OF_CONTENT_NAME) ||
    editor.isActive('horizontalRule') ||
    editor.isActive(VIDEO_EXTENSION_NAME) ||
    editor.isActive(AUDIO_EXTENSION_NAME);

  const {
    state: {
      selection: {
        from, to, empty,
      },
    },
  } = editor;

  useEffect(() => {
    setIsActive(true);
  }, [from, to]);

  const popperInstance = useRef<Instance | null>(null);
  const [isChildOfPopper, setIsChildOfPopper] = useState(false);

  useEffect(() => {
    editor.on('blur', ({ event }) => {
      if (event.relatedTarget instanceof HTMLElement) {
        setIsChildOfPopper(!!popperInstance.current?.popper?.contains(event.relatedTarget));
      }
    });
  }, [editor]);

  const menu = useMemo(() => {
    if (from === to || isActiveBlockWithoutMenu) {
      return null;
    }
    if (isActiveLink) {
      setIsActive(true);
      return 'link';
    }
    setIsActive(true);
    return 'formatting';
  }, [from, to, isActiveLink, isActiveBlockWithoutMenu]);

  useHotkeys('escape', () => {
    setIsActive(false);
    dropdownProps.onHide?.();
  });

  const isMobile = useIsMobile();

  const hasFocus = editor.view.hasFocus();

  const shouldShow = useMemo(() => {
    // Copy/paste of "shouldShow"
    // https://github.com/ueberdosis/tiptap/blob/main/packages/extension-bubble-menu/src/bubble-menu-plugin.ts
    const isEmptyTextBlock =
      !editor.state.doc.textBetween(from, to).length &&
      isTextSelection(editor.state.selection);
    const isChildOfMenu = popperInstance.current?.popper?.contains(document.activeElement);

    const hasEditorFocus = isChildOfMenu || isChildOfPopper || hasFocus;

    if (
      !hasEditorFocus ||
      empty ||
      isEmptyTextBlock ||
      !editor.isEditable ||
      isDragging
    ) {
      return false;
    }
    return true;
  }, [
    editor.state.doc, editor.state.selection, editor.isEditable,
    from, to, hasFocus, empty, isChildOfPopper, isDragging,
  ]);

  const {
    maxAiQueriesReached,
    openUnlimitedAiPrompt,
  } = useCheckMonthlyAiQueries();

  return isMobile ? null : (
    <Popover
      content={renderMenu()}
      getReferenceClientRect={() => posToDOMRect(editor.view, from, to)}
      interactive
      placement="top"
      ref={popperInstance}
      visible={shouldShow}
    />
  );

  function renderMenu() {
    if (!isActive || !!aiVisible) {
      return null;
    }
    if (menu === 'link') {
      return <LinkBubble />;
    }
    if (menu === 'formatting') {
      return renderFormattingMenu();
    }
    return null;
  }

  function renderFormattingMenu() {
    const dropdownCategories = ['elements', 'lists'];

    const mainActions = isSmallScreen
      ? allowedActions.filter(category => !dropdownCategories.includes(category.category))
      : allowedActions;

    const dropdownActions = isSmallScreen
      ? allowedActions.filter(category => dropdownCategories.includes(category.category))
      : [];

    return (
      <Container tabIndex={-1} {...motionProps} className={className}>
        {mainActions.map(({
          category, actions,
        }) => !!actions.filter(action => !disabledActions.includes(action.id)).length && (
          <Category key={category}>
            {actions
              .filter(action => !disabledActions.includes(action.id) && (action.id !== ActionId.Comments || section !== 'closed'))
              .map(action => {
                if (action.id === ActionId.Colors && !isTextColorEnabled) return null;
                return (
                  <Action
                    key={action.id}
                    action={action.id === ActionId.TurnTextIntoDocMention && hasSelectedMultipleDocs
                      ? {
                        ...action,
                        label: action.labelAlt ?? action.label,
                      }
                      : action}
                    onClick={() => {
                      if (action.closeBubbleOnClick) setIsActive(false);
                      if (action.id === ActionId.Ai && !getPermission().canUseAi) {
                        setLimitationsModal({ action: 'USE_AI' });
                        return;
                      }
                      if (action.id === ActionId.Ai && maxAiQueriesReached) {
                        openUnlimitedAiPrompt();
                        return;
                      }
                      action.toggle?.(editor, 'bubble-menu', editorId);
                    }}
                    active={!!action.node && editor.isActive(action.node, action.nodeParams)}
                    onClose={() => setIsActive(false)}
                  />
                );
              })}
          </Category>
        ))}

        {isSmallScreen && (
          <Category>
            <ToggleDropdown
              offsetY={12}
              placement="bottom"
              button={props => (
                <ButtonAction {...props}>
                  <MoreHorizIcon />
                </ButtonAction>
              )}
              content={props => (
                <MoreActions
                  actions={dropdownActions}
                  disabledActions={disabledActions}
                  hide={props.hide}
                  editor={editor}
                />
              )}
            />
          </Category>
        )}
      </Container>
    );
  }
};

export default Bubble;

const MoreActions = ({
  actions, disabledActions, hide, editor,
}: {
  actions: ActionCategoryData[];
  disabledActions: ActionId[];
  hide: VoidFunction;
  editor: Editor;
}) => {
  return (
    <div style={{ padding: '8px 0' }}>
      {actions
        .flatMap(category => category.actions)
        .filter(action => !disabledActions.includes(action.id))
        .map(action => {
          if (action.id === ActionId.Link && disabledActions?.includes(ActionId.MentionDoc)) {
            return (
              <LinkInput
                key={action.id}
                button={props => (
                  <SelectLine
                    startSlot={action.icon}
                    label={action.label}
                    onClick={props.onClick}
                  />
                )}
              />
            );
          }

          if (action.id === ActionId.Link && !disabledActions?.includes(ActionId.MentionDoc)) {
            return (
              <LinkDropdown key={action.id}>
                <SelectLine
                  startSlot={action.icon}
                  label={action.label}
                />
              </LinkDropdown>
            );
          }

          return (
            <SelectLine
              key={action.id}
              startSlot={action.icon}
              label={action.label}
              onClick={() => {
                hide();
                action.toggle?.(editor, 'bubble-menu');
              }}
            />
          );
        })}
    </div>
  );
};
