import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const MicrosoftTeamsIcon: FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <g>
      <path d="M11.1603 5.58142H15.2931C15.6836 5.58142 16.0001 5.89794 16.0001 6.28839V10.0529C16.0001 11.4879 14.8368 12.6512 13.4018 12.6512H13.3895C11.9545 12.6514 10.791 11.4882 10.7908 10.0532C10.7908 10.0531 10.7908 10.053 10.7908 10.0528V5.95091C10.7908 5.74685 10.9562 5.58142 11.1603 5.58142Z" fill="#5059C9" />
      <path d="M13.9536 4.83723C14.8783 4.83723 15.628 4.08757 15.628 3.16282C15.628 2.23806 14.8783 1.4884 13.9536 1.4884C13.0288 1.4884 12.2792 2.23806 12.2792 3.16282C12.2792 4.08757 13.0288 4.83723 13.9536 4.83723Z" fill="#5059C9" />
      <path d="M8.74429 4.8372C10.0801 4.8372 11.1629 3.75436 11.1629 2.4186C11.1629 1.08284 10.0801 0 8.74429 0C7.40853 0 6.32568 1.08284 6.32568 2.4186C6.32568 3.75436 7.40853 4.8372 8.74429 4.8372Z" fill="#7B83EB" />
      <path d="M11.9692 5.58142H5.14719C4.76139 5.59097 4.4562 5.91114 4.46515 6.29696V10.5905C4.41127 12.9058 6.24299 14.8271 8.55817 14.8837C10.8734 14.8271 12.7051 12.9058 12.6512 10.5905V6.29696C12.6601 5.91114 12.355 5.59097 11.9692 5.58142Z" fill="#7B83EB" />
      <path opacity="0.1" d="M8.93028 5.58142V11.5982C8.92843 11.8741 8.76123 12.1219 8.5061 12.227C8.42486 12.2613 8.33756 12.2791 8.24935 12.2791H4.7926C4.74423 12.1563 4.69958 12.0335 4.66237 11.907C4.53212 11.48 4.46566 11.0362 4.46516 10.5898V6.29584C4.45621 5.91064 4.7609 5.59097 5.14609 5.58142H8.93028Z" fill="black" />
      <path opacity="0.2" d="M8.55819 5.58142V11.9702C8.55818 12.0584 8.54046 12.1458 8.5061 12.227C8.40104 12.4821 8.15317 12.6493 7.87726 12.6512H4.96749C4.90423 12.5284 4.8447 12.4056 4.7926 12.2791C4.74051 12.1526 4.69958 12.0335 4.66237 11.907C4.53212 11.48 4.46566 11.0362 4.46516 10.5898V6.29584C4.45621 5.91064 4.7609 5.59097 5.14609 5.58142H8.55819Z" fill="black" />
      <path opacity="0.2" d="M8.55818 5.58142V11.2261C8.55535 11.601 8.25214 11.9042 7.87725 11.907H4.66237C4.53212 11.48 4.46566 11.0362 4.46516 10.5898V6.29584C4.45621 5.91064 4.7609 5.59097 5.14609 5.58142H8.55818Z" fill="black" />
      <path opacity="0.2" d="M8.18609 5.58142V11.2261C8.18325 11.601 7.88005 11.9042 7.50516 11.907H4.66237C4.53212 11.48 4.46566 11.0362 4.46516 10.5898V6.29584C4.45621 5.91064 4.7609 5.59097 5.14609 5.58142H8.18609Z" fill="black" />
      <path opacity="0.1" d="M8.93038 3.65773V4.82982C8.86712 4.83354 8.80759 4.83726 8.74433 4.83726C8.68107 4.83726 8.62154 4.83354 8.55828 4.82982C8.43268 4.82148 8.30812 4.80156 8.18619 4.77029C7.4327 4.59185 6.8102 4.06341 6.51177 3.3489C6.46042 3.22891 6.42056 3.10431 6.3927 2.97681H8.24944C8.62492 2.97823 8.92895 3.28225 8.93038 3.65773Z" fill="black" />
      <path opacity="0.2" d="M8.55823 4.0298V4.8298C8.43263 4.82146 8.30807 4.80154 8.18614 4.77027C7.43265 4.59183 6.81014 4.06339 6.51172 3.34888H7.8773C8.25277 3.3503 8.5568 3.65433 8.55823 4.0298Z" fill="black" />
      <path opacity="0.2" d="M8.55823 4.0298V4.8298C8.43263 4.82146 8.30807 4.80154 8.18614 4.77027C7.43265 4.59183 6.81014 4.06339 6.51172 3.34888H7.8773C8.25277 3.3503 8.5568 3.65433 8.55823 4.0298Z" fill="black" />
      <path opacity="0.2" d="M8.18614 4.02981V4.77027C7.43265 4.59183 6.81014 4.06339 6.51172 3.34888H7.50521C7.88069 3.35031 8.18471 3.65433 8.18614 4.02981Z" fill="black" />
      <path d="M0.682165 3.34888H7.50412C7.8808 3.34888 8.18616 3.65424 8.18616 4.03092V10.8529C8.18616 11.2295 7.88079 11.5349 7.50412 11.5349H0.682165C0.30548 11.5349 0.00012207 11.2295 0.00012207 10.8529V4.03092C0.00012207 3.65424 0.305487 3.34888 0.682165 3.34888Z" fill="url(#paint0_linear_498_8189)" />
      <path d="M5.88805 5.94534H4.52432V9.65882H3.65549V5.94534H2.2981V5.22498H5.88805V5.94534Z" fill="white" />
    </g>
  </Svg>
);
