import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { BulbIcon, AiIcon } from '@cycle-app/ui/icons';
import { useEffect } from 'react';

import DocAction from 'src/components/DocAction/DocAction';
import { OpenInsightCreateModal } from 'src/components/DocHierarchy/DocHierarchyGroup/OpenInsightCreateModal';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { refetchAiGenetatedInsights } from 'src/hooks/insight/useDocInsights.cache';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setDocItem } from 'src/reactives/docItem.reactive';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { Layer } from 'src/types/layers.types';
import { isFeedback } from 'src/utils/docType.util';

import { StyledDocHierarchyInsight } from './DocInsights.styles';

type Props = {
  doc: DocBaseFragment;
  context?: 'doc-item';
  // eslint-disable-next-line react/no-unused-prop-types
  isReadOnly?: boolean;
};

export const DocInsights = (props: Props) => {
  const {
    doc, isReadOnly = false,
  } = props;
  const docType = useGetDocType(doc.doctype.id);
  const count = isFeedback(docType) ? doc.docTargetsCount : doc.insightsCount;
  const aiCount = isFeedback(docType) ? doc.docTargetsAiCount : null;
  if (count === 0 && isReadOnly) return null;
  if (count > 0) {
    return (
      <DocInsightsList
        {...props}
        count={count}
        aiCount={aiCount}
      />
    );
  }
  return <DocInsightsEmpty {...props} />;
};

const DocInsightsEmpty = ({
  doc, context,
}: Props) => {
  return (
    <OpenInsightCreateModal
      layer={Layer.Dropdown}
      parentDoc={doc}
      button={buttonProps => (
        <DocAction
          forceFocus={buttonProps.isOpen}
          tooltipPlacement="top"
          label="Add insight"
          buttonIcon={<BulbIcon size={14} />}
          counter={0}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            buttonProps.onClick();
          }}
          context={context}
        />
      )}
    />
  );
};

type DocInsightsListProps = Props & {
  count: number;
  aiCount: number | null;
};

const DocInsightsList = ({
  doc,
  context,
  count,
  aiCount,
  isReadOnly = false,
}: DocInsightsListProps) => {
  const [isDropdownVisible, {
    setFalseCallback: hideDropdown,
    toggleCallback: toggleDropdown,
  }] = useOptimizedBooleanState(false);

  useEffect(() => {
    if (isDropdownVisible) {
      setDocItem({ hoverDocId: null });
    }
  }, [isDropdownVisible]);

  const loadAiInsights = () => {
    if (aiCount) {
      refetchAiGenetatedInsights(doc.id);
    }
  };

  return (
    <DropdownLayer
      visible={isDropdownVisible}
      hide={hideDropdown}
      layer={Layer.DocItemDropdown}
      disableDocPreview={false}
      placement="bottom-start"
      exitAnimation={false}
      content={(
        <StyledDocHierarchyInsight
          docId={doc.id}
          count={count}
          onClickInsight={hideDropdown}
          isReadOnly={isReadOnly}
        />
      )}
    >
      <DocAction
        forceFocus={isDropdownVisible}
        tooltipPlacement="top"
        label={aiCount ? 'Some AI-generated, not verified insights are suggested' : 'Insights'}
        buttonIcon={<BulbIcon size={15} />}
        counter={count}
        onMouseEnter={loadAiInsights}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          loadAiInsights();
          toggleDropdown();
        }}
        context={context}
        iconEnd={aiCount ? <AiIcon size={14} /> : null}
      />
    </DropdownLayer>
  );
};
