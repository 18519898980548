import { useQuery } from '@apollo/client';
import { Maybe, MateFragment, UserWithProductRoleDocument, MateWithProductRoleFragment } from '@cycle-app/graphql-codegen';
import { Avatar, Tooltip } from '@cycle-app/ui';
import { PenIcon, InfoIconOutline } from '@cycle-app/ui/icons';

import { PageId } from 'src/constants/routing.constant';
import { useProduct, useNavigate } from 'src/hooks';

import {
  Container,
  Top,
  Title,
  TopContent,
  Bottom,
  AvatarContainer,
  InfoContainer,
  Role,
  CTA,
  Name,
  Email,
  UserInfo,
} from './AssigneeTooltip.styles';

export const AssigneeTooltip = ({
  assignee, isReadOnly, onUpdate, tooltip,
}: {
  assignee: MateFragment;
  isReadOnly?: boolean;
  onUpdate?: VoidFunction;
  tooltip?: string;
}) => {
  const { navigate } = useNavigate();
  const { product } = useProduct();

  const { data } = useQuery(UserWithProductRoleDocument, {
    skip: !assignee || !product?.id,
    variables: {
      id: assignee?.id as string,
      productId: product?.id as string,
    },
  });

  const role = (data?.node as Maybe<MateWithProductRoleFragment>)?.productRole;

  return (
    <Container onClick={e => e.stopPropagation()}>
      <Top>
        <Title>
          Assignee
          {tooltip && (
            <Tooltip
              withPortal
              placement="top"
              content={tooltip}
            >
              <InfoIconOutline size={14} />
            </Tooltip>
          )}
        </Title>

        <TopContent>
          <UserInfo>
            <AvatarContainer>
              <Avatar
                user={assignee}
                size={28}
              />
            </AvatarContainer>
            <InfoContainer>
              {(assignee?.firstName || assignee.lastName) && (
                <Name>{`${assignee.firstName} ${assignee.lastName}`}</Name>
              )}
              {assignee?.email && <Email>{assignee.email}</Email>}
            </InfoContainer>
          </UserInfo>

          {role && (
            <Tooltip
              content="Explore maker features"
              placement="top"
              withPortal
              withWrapper={false}
            >
              <Role onClick={() => navigate(PageId.RequestMakerAccess)}>
                {role}
              </Role>
            </Tooltip>
          )}
        </TopContent>
      </Top>

      <Bottom>
        {!isReadOnly && (
          <CTA onClick={e => {
            e.stopPropagation();
            onUpdate?.();
          }}
          >
            <PenIcon />
            Change assignee
          </CTA>
        )}
      </Bottom>
    </Container>
  );
};
