import { StatusCategory } from '@cycle-app/graphql-codegen';
import { Flex, StatusIcon } from '@cycle-app/ui';
import { Hierarchy2Icon, DashCircleIcon, LinearIcon, CycleStandardIcon, CaretIcon } from '@cycle-app/ui/icons';
import { ColorTheme } from '@cycle-app/utilities';
import range from 'lodash/range';

import { AddOnLogoContainer } from 'src/components/LimitationModal/LimitationModal.styles';

import {
  ModalItem,
  StatusDescription,
  StatusText,
  StatusItem,
  IconBox,
  IconsGrid,
  CaretContainer,
} from './EnableRoadmapModal.styles';

export const STEP_TITLE = ['Roadmaps', 'Hierarchy', 'Statuses', 'Sync with Linear'];

export const STEP_DESCRIPTION = [
  null,
  'Start from a template and configure your hierarchy to create multi-level roadmaps',
  'Manage progress with statuses, from start to finish. Customize and re-order your statuses to create unique workflows.',
  'Integrate with your favorite issue tracking tool. Import Linear issues and projects, create automations, and sync the work.',
];

export const STEP_CTA = [
  'Enable',
  'Next',
  'Next',
  'Done',
];

export const step1Content = [
  <ModalItem key={1}>
    <div>
      <Hierarchy2Icon />
    </div>
    <div>
      <h5>Hierarchy</h5>
      <p>Start from a template and configure your hierarchy to create multi-level roadmaps</p>
    </div>
  </ModalItem>,
  <ModalItem key={2}>
    <div>
      <DashCircleIcon />
    </div>
    <div>
      <h5>Statuses</h5>
      <p>Manage progress with statuses, from start to finish.</p>
    </div>
  </ModalItem>,
  <ModalItem key={3}>
    <div>
      <LinearIcon overwriteColor="currentColor" />
    </div>
    <div>
      <h5>Linear integration</h5>
      <p>Integrate with your favorite issue tracking tool. Import Linear issues and projects, create automations, and sync the work.</p>
    </div>
  </ModalItem>,
];

export const step3Content = [
  <StatusItem key={1}>
    <StatusIcon category={StatusCategory.NotStarted} withBackground />
    <StatusText>Not started</StatusText>
    <StatusDescription>To link, To do</StatusDescription>
  </StatusItem>,
  <StatusItem key={2}>
    <StatusIcon category={StatusCategory.Started} withBackground />
    <StatusText>Started</StatusText>
    <StatusDescription>In progress, Doing</StatusDescription>
  </StatusItem>,
  <StatusItem key={3}>
    <StatusIcon category={StatusCategory.Completed} withBackground />
    <StatusText>Completed</StatusText>
    <StatusDescription>Shipped</StatusDescription>
  </StatusItem>,
  <StatusItem key={4}>
    <StatusIcon category={StatusCategory.Completed} withBackground />
    <StatusText>Loop closed</StatusText>
    <StatusDescription>Loop closed</StatusDescription>
  </StatusItem>,
  <StatusItem key={5}>
    <StatusIcon category={StatusCategory.Canceled} withBackground />
    <StatusText>Canceled</StatusText>
    <StatusDescription>Canceled</StatusDescription>
  </StatusItem>,
];

export const step4Content = [
  <Flex
    key={1}
    style={{
      position: 'relative',
      margin: '66px 0',
    }}
  >
    <AddOnLogoContainer
      style={{
        position: 'absolute',
        zIndex: -1,
        transform: 'scale(1.8) translate(-18px, -1px)',
        left: '50%',
        opacity: 0.5,
      }}
    />
    <IconBox>
      <CycleStandardIcon fill={ColorTheme.Cycle} />
    </IconBox>
    <IconsGrid>
      {range(6).map(n => (
        <CaretContainer
          key={n}
          style={{
            // eslint-disable-next-line no-nested-ternary
            opacity: n === 0 || n === 5
              ? 1
              : n === 1 || n === 4
                ? 0.5
                : 0.25,
            marginBottom: n < 3 ? '8px' : '0',
          }}
        >
          <CaretIcon direction={n <= 2 ? 'left' : 'right'} />
        </CaretContainer>
      ))}
    </IconsGrid>
    <IconBox>
      <LinearIcon size={64} />
    </IconBox>
  </Flex>,
];
