import { Avatar, Button } from '@cycle-app/ui';
import { mobile } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  ${mobile} {
    grid-template-columns: 1fr;
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin-top: 8px;
`;

export const InputContent = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const UploadNewAvatarBtn = styled(Button).attrs({ size: 'M' })`
  white-space: nowrap;
`;

export const Buttons = styled.div`
  margin-top: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;
