import { DocStatus, DocStatusProps } from 'src/components/DocStatus';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';

export const ReleaseDocStatus = ({
  noteId,
  isEnabled,
  isReadOnly,
  ...props
}: Pick<DocStatusProps, 'hideLabel'> & {
  noteId: string;
  isEnabled: boolean;
  isReadOnly?: boolean;
}) => {
  const { releaseNote } = useReleaseNote(noteId);
  if (!releaseNote?.doc?.status) return null;
  return (
    <DocStatus
      isDisabled={isReadOnly}
      enableShortcut={isEnabled}
      docId={releaseNote.doc.id}
      docTypeId={releaseNote.doc.doctype.id}
      statusId={releaseNote.doc.status.id}
      {...props}
    />
  );
};
