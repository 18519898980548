import { BoardWithMinimalConfigFragment, DoctypeType, SectionType } from '@cycle-app/graphql-codegen';
import { getDocSlug } from '@cycle-app/utilities';
import { useCallback } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { RouteParams } from 'src/types/routes.types';
import { isInsight } from 'src/utils/docType.util';
import { createUrl } from 'src/utils/routing.utils';
import { getBoardSlug } from 'src/utils/slug.util';

import { useParentPage } from './usePageId';
import { useParams, useDocSlug } from './usePathParams';

export { PageId };

export const useUrl = () => {
  const urlParams = useParams();
  return useCallback(
    (pageId: PageId, params: RouteParams = {}) => createUrl(pageId, params, urlParams),
    [urlParams],
  );
};

export interface DocData {
  title: string;
  id: string;
}

export interface DocDataView {
  doc: DocData;
  doctype?: { id: string };
  docDocSource?: DocData | null;
}

export const useDocUrl = () => {
  const parentPageId = useParentPage();
  const getUrl = useUrl();

  const getDocFullPageUrl = useCallback(
    (docData: DocData) => getUrl(PageId.DocFullPage, { docSlug: getDocSlug(docData) }),
    [getUrl],
  );

  const getDocPanelUrl = useCallback(
    (docData: DocData) => {
      if (parentPageId === 'releases') return getDocFullPageUrl(docData);
      const pageIds: Partial<Record<typeof parentPageId, PageId>> = {
        inbox: PageId.InboxDoc,
        home: PageId.HomeDoc,
        insight: PageId.InsightDoc,
        roadmap: PageId.RoadmapDoc,
      };
      return getUrl(pageIds[parentPageId] || PageId.Doc, { docSlug: getDocSlug(docData) });
    },
    [getDocFullPageUrl, getUrl, parentPageId],
  );

  const getDocItemUrlFromView = useCallback(
    (params: DocDataView) => {
      const {
        doc, docDocSource, doctype,
      } = params;
      if (!doctype?.id) return undefined;
      const doctypeData = getDocType(doctype?.id);
      // docDocSource is the feedback.
      // Insight card -> opens the feedback.
      // eslint-disable-next-line no-nested-ternary
      return !!docDocSource && isInsight(doctypeData) ? getDocPanelUrl(docDocSource) : !isInsight(doctypeData) ? getDocPanelUrl(doc) : undefined;
    }, [getDocPanelUrl],
  );

  return {
    getDocPanelUrl,
    getDocFullPageUrl,
    getDocItemUrlFromView,
  };
};

export const useDocTypeUrl = () => {
  const getUrl = useUrl();
  return useCallback((doctype: { id: string; type: DoctypeType }) => {
    if (doctype.type === DoctypeType.Feedback) return getUrl(PageId.SettingsFeedback);
    if (doctype.type === DoctypeType.Insight) return getUrl(PageId.SettingsInsights);
    return getUrl(PageId.SettingsRoadmapsDocType, { doctypeId: doctype.id });
  }, [getUrl]);
};

export const useCustomerUrl = () => {
  const getUrl = useUrl();

  const getCustomerPageUrl = useCallback(
    (customerId: string) => getUrl(PageId.SettingsCustomer, { customerId }),
    [getUrl],
  );

  return {
    getCustomerPageUrl,
  };
};

export const useBoardUrl = (board: BoardWithMinimalConfigFragment, { withDocSlug }: { withDocSlug: boolean }) => {
  const getUrl = useUrl();
  const docSlug = useDocSlug();
  const params = {
    boardSlug: getBoardSlug(board),
    docSlug: withDocSlug ? docSlug : undefined,
  };
  if (board.section?.type === SectionType.Feedback) return getUrl(params.docSlug ? PageId.InboxDoc : PageId.InboxView, params);
  if (board.section?.type === SectionType.Insights) return getUrl(params.docSlug ? PageId.InsightDoc : PageId.InsightView, params);
  if (board.section?.type === SectionType.Roadmaps) return getUrl(params.docSlug ? PageId.RoadmapDoc : PageId.RoadmapView, params);
  // We should never reach here as view has now one of these three sections.
  if (params.docSlug) return getUrl(PageId.DocFullPage, params);
  // Worst case return main.
  return getUrl(PageId.Main);
};
