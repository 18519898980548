import {
  BellActiveIcon,
  BellIcon,
  HomeIcon,
  InboxIcon,
  ReleaseIcon,
  BulbLight,
  RoadmapIcon,
  SearchIcon,
  MoreHorizCircleIcon,
} from '@cycle-app/ui/icons';
import { ComponentProps, memo } from 'react';

import NotificationCenter from 'src/app/Main/Board/NotificationCenter/NotificationCenter';
import { CollapsableNavigationItem } from 'src/components/CollapsableNavigationItem';
import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';
import { SectionContextMenu } from 'src/components/SectionContextMenu';
import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useIsRoadmapsEnabled, useProductAddOn } from 'src/hooks';
import { useProduct } from 'src/hooks/api/useProduct';
import { useGetInboxDefaultUrl } from 'src/hooks/inbox';
import { useGetInsightDefaultUrl } from 'src/hooks/insight/useGetInsightDefaultUrl';
import { openCommandBar } from 'src/hooks/modals/useCommandBarModal';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useGetRoadmapDefaultUrl } from 'src/hooks/roadmap/useGetRoadmapDefaultUrl';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { useIsOnboarding } from 'src/hooks/useIsOnboarding';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useParentPage } from 'src/hooks/usePageId';
import { useSidebarCollapsed } from 'src/hooks/useSidebarCollapsed';
import { getPermission, setLimitationsModal } from 'src/reactives';
import { toggleNotifications } from 'src/reactives/notifications.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { Shortcut, ShortcutCommandbar, ShortcutSidebar } from 'src/types/shortcuts.types';
import { getUrl } from 'src/utils/routing.utils';

export const SidebarActions = memo(() => {
  const { isEnabled: isInboxEnabled } = useFeatureFlag('inbox');
  const { isEnabled: isInsightEnabled } = useFeatureFlag('insight-view');
  const { isEnabled: isAnalysesEnabled } = useProductAddOn('ANALYSES');
  const isReleasesEnabled = useIsReleasesEnabled();
  const isRoadmapsEnabled = useIsRoadmapsEnabled();
  const getInboxDefaultUrl = useGetInboxDefaultUrl();
  const getInsightDefaultUrl = useGetInsightDefaultUrl();
  const getRoadmapDefaultUrl = useGetRoadmapDefaultUrl();

  const isOnboarding = useIsOnboarding();
  const { product } = useProduct();
  const collapsed = useSidebarCollapsed();
  const isMobile = useIsMobile();
  const { navigate } = useNavigateToSettings();

  const { notificationsNotRead } = product || {};

  const tryMoreOptions = [
    ...(!isRoadmapsEnabled ? [{
      label: 'Try roadmaps',
      value: 'roadmap',
      icon: <RoadmapIcon />,
    }] : []),
    ...(!isReleasesEnabled ? [{
      label: 'Try releases',
      value: 'release',
      icon: <ReleaseIcon />,
    }] : []),
  ];

  return (
    <>
      {!isMobile && (
        <CollapsableNavigationItem
          icon={<SearchIcon />}
          label="Search"
          isSidebarColor
          shortcut={shortcuts[ShortcutCommandbar.ToggleCommandBar]}
          onClick={() => openCommandBar()}
          animate
          collapsed={collapsed}
        />
      )}

      <NavItem
        linkId="home"
        asPlaceholder={isOnboarding}
        icon={<HomeIcon />}
        label="Home"
        linkTo={getUrl(PageId.Main)}
        isSidebarColor
        placeholderScale={[0.5, 0.3]}
        activeAlpha={isOnboarding ? 1 : undefined}
        parent="home"
        shortcut={shortcuts[Shortcut.GoToHome]}
        isChild
        animate
        collapsed={collapsed}
      />

      {isInboxEnabled && (
        <SectionContextMenu
          sectionParent="inbox"
          target={props => (
            <NavItem
              linkId="inbox"
              asPlaceholder={isOnboarding}
              icon={<InboxIcon />}
              label="Feedback"
              linkTo={getInboxDefaultUrl()}
              isSidebarColor
              placeholderScale={[0.5, 0.3]}
              activeAlpha={isOnboarding ? 1 : undefined}
              parent="inbox"
              shortcut={shortcuts[Shortcut.GoToFeedback]}
              isChild
              animate
              collapsed={collapsed}
              {...props}
            />
          )}
        />
      )}

      {isInsightEnabled && (
        <SectionContextMenu
          sectionParent="insight"
          target={props => (
            <NavItem
              linkId="insights"
              icon={<BulbLight />}
              label="Insights"
              linkTo={isAnalysesEnabled
                ? getUrl(PageId.Analyses)
                : getInsightDefaultUrl()}
              isSidebarColor
              placeholderScale={[0.5, 0.3]}
              parent="insight"
              shortcut={shortcuts[Shortcut.GoToInsights]}
              isChild
              animate
              collapsed={collapsed}
              {...props}
            />
          )}
        />
      )}

      {isRoadmapsEnabled && (
        <SectionContextMenu
          sectionParent="roadmap"
          target={props => (
            <NavItem
              linkId="roadmap"
              icon={<RoadmapIcon />}
              label="Roadmaps"
              linkTo={getRoadmapDefaultUrl()}
              isSidebarColor
              placeholderScale={[0.5, 0.3]}
              parent="roadmap"
              shortcut={shortcuts[Shortcut.GoToRoadmap]}
              isChild
              animate
              collapsed={collapsed}
              {...props}
            />
          )}
        />
      )}

      {isReleasesEnabled && (
        <NavItem
          linkId="releases"
          asPlaceholder={isOnboarding}
          icon={<ReleaseIcon />}
          label="Releases"
          linkTo={getUrl(PageId.Releases)}
          isSidebarColor
          placeholderScale={[0.5, 0.3]}
          activeAlpha={isOnboarding ? 1 : undefined}
          parent="releases"
          shortcut={shortcuts[Shortcut.GoToReleases]}
          isChild
          animate
          collapsed={collapsed}
        />
      )}

      {!!tryMoreOptions.length && (
        <DropdownSelectLayer
          options={tryMoreOptions}
          hideSearch
          placement={collapsed ? 'right-start' : 'bottom-start'}
          triggerStyle={{ width: '100%' }}
          panelStyles={{ minWidth: '170px' }}
          onChange={(newOption) => {
            if (!getPermission().canReadSettings) {
              setLimitationsModal({ action: 'SETTINGS_READ' });
              return;
            }
            const settingPage = newOption.value === 'roadmap'
              ? PageId.SettingsRoadmaps
              : PageId.SettingsReleases;
            navigate(settingPage);
          }}
        >
          {(isVisible) => (
            <CollapsableNavigationItem
              isActive={isVisible}
              linkId="more"
              asPlaceholder={isOnboarding}
              icon={(<MoreHorizCircleIcon />)}
              label="More"
              isSidebarColor
              placeholderScale={[0.5, 0.3]}
              activeAlpha={isOnboarding ? 1 : undefined}
              isChild
              animate
            />
          )}
        </DropdownSelectLayer>
      )}

      {collapsed && !isMobile && (
        <NotificationCenter>
          <CollapsableNavigationItem
            icon={notificationsNotRead ? <BellActiveIcon /> : <BellIcon />}
            label="Notifications"
            tagWithCount={isOnboarding ? 0 : notificationsNotRead}
            shortcut={shortcuts[ShortcutSidebar.OpenNotifCenter]}
            onClick={toggleNotifications}
            isSidebarColor
            asPlaceholder={isOnboarding}
            placeholderScale={[0.45, 0.3]}
            activeAlpha={isOnboarding ? 1 : undefined}
            collapsed={collapsed}
          />
        </NotificationCenter>
      )}
    </>
  );
});

type ActionItemProps = ComponentProps<typeof CollapsableNavigationItem> & {
  parent: string;
};

const NavItem = ({
  parent,
  ...props
}: ActionItemProps) => {
  const isActive = useParentPage(parentPage => parentPage === parent);
  return (
    <CollapsableNavigationItem
      {...props}
      isActive={isActive}
    />
  );
};
