import { typo, Button } from '@cycle-app/ui';
import { SortHorizontalIcon } from '@cycle-app/ui/icons';
import styled from 'styled-components';

import { PortalModalStyled } from '../DialogModal/DialogModal.styles';

export const StyledPortalModal = styled(PortalModalStyled)`
  width: 537px;
  padding: 54px 24px 30px;
  text-align: center;
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  justify-content: center;
  color: ${p => p.theme.colors.background.active};
`;

export const StyledSortHorizontalIcon = styled(SortHorizontalIcon)`
  width: 18px;
`;

export const Title = styled.div`
  ${typo.headerLight}
  margin-top: 45px;
`;

export const Description = styled.div`
  ${typo.body400}
  margin: 8px auto 0;
  max-width: 420px;
`;

export const Actions = styled.div`
  margin-top: 24px;
`;

export const SyncButton = styled(Button)`
  margin: 24px auto 0;
`;

export const CancelButton = styled(Button)`
  margin: 16px auto 0;
`;
