import { useLazyQuery, QueryLazyOptions, LazyQueryResult, LazyQueryHookOptions } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useEffect, useRef } from 'react';

import { addErrorToaster } from 'src/utils/errorToasters.utils';

interface Options<D, V> extends LazyQueryHookOptions<D, V> {
  showTooltipOnError?: boolean;
}

type UseSafeAsyncLazyQueryReturn<D, V> = [
  (options?: QueryLazyOptions<V> | undefined) => Promise<D | null>,
  LazyQueryResult<D, V>,
];

export const useSafeAsyncLazyQuery = <TData, TVariables>(
  document: DocumentNode,
  {
    showTooltipOnError = false,
    onCompleted,
    onError,
    ...options
  }: Options<TData, TVariables> = {},
): UseSafeAsyncLazyQueryReturn<TData, TVariables> => {
  const resolveRef = useRef<(value: TData | null) => void>();
  const promiseRef = useRef<Promise<TData | null>>();

  const [query, result] = useLazyQuery<TData, TVariables>(document, {
    fetchPolicy: 'no-cache',
    onError: (data) => {
      onError?.(data);
      resolveRef.current?.(null);
      if (showTooltipOnError) {
        addErrorToaster({ message: 'Server temporary unavailable, please try again later' });
      }
    },
    ...options,
  });

  useEffect(() => {
    if (!result.loading && result.data) {
      onCompleted?.(result.data);
      resolveRef.current?.(result.data);
      if (showTooltipOnError && ('error' in result.data || 'errors' in result.data)) {
        addErrorToaster({ message: 'Server temporary unavailable, please try again later' });
      }
    }
  }, [onCompleted, result, showTooltipOnError]);

  const asyncQuery = (params?: QueryLazyOptions<TVariables>) => {
    promiseRef.current = new Promise(resolve => {
      resolveRef.current = resolve;
    });
    query(params);
    return promiseRef.current;
  };

  return [asyncQuery, result];
};
