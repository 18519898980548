import { CloseIcon } from '@cycle-app/ui/icons';

import { closeEditAppModal, useGetSettingsApi } from 'src/reactives/settingsApi.reactive';

import { StyledModal, ModalTitle, CloseButton } from '../SettingsAPI.styles';
import { OAuthAppForm } from './OAuthAppForm';

export const OAuthAppModal = () => {
  const clientId = useGetSettingsApi().openedEditAppModal;
  if (clientId === null) return null;
  return (
    <StyledModal hide={closeEditAppModal}>
      <ModalTitle>
        {!clientId ? 'Create new OAuth application' : 'Edit OAuth application'}
      </ModalTitle>

      <OAuthAppForm clientId={clientId} />

      <CloseButton onClick={closeEditAppModal}>
        <CloseIcon size={14} />
      </CloseButton>
    </StyledModal>
  );
};
