import { Badge } from '@cycle-app/ui';

import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { getCommentsCount } from 'src/utils/comments.utils';

export const CommentsCount = ({ doc }: { doc: FullDocWithPublicId }) => {
  const isMobile = useIsMobile();
  const threadsCount = useDocPanelContext(ctx => ctx.threadsCount);
  const commentsCount = isMobile ? getCommentsCount(doc) : threadsCount;
  if (commentsCount === undefined) return null;
  return <Badge>{commentsCount}</Badge>;
};
