import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const EmbedIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0495 2.52492C10.1379 2.17109 9.92279 1.81255 9.56897 1.7241C9.21514 1.63564 8.8566 1.85077 8.76814 2.20459L5.95054 13.475C5.86208 13.8288 6.07721 14.1874 6.43103 14.2758C6.78486 14.3643 7.1434 14.1492 7.23186 13.7953L10.0495 2.52492ZM4.94494 4.71545C5.20283 4.97335 5.20283 5.39147 4.94494 5.64937L2.59429 8.00002L4.94494 10.3507C5.20283 10.6086 5.20283 11.0267 4.94494 11.2846C4.68704 11.5425 4.26892 11.5425 4.01102 11.2846L1.19342 8.46697C0.935527 8.20908 0.935527 7.79095 1.19342 7.53306L4.01102 4.71545C4.26892 4.45756 4.68704 4.45756 4.94494 4.71545ZM11.0551 4.71545C11.313 4.45756 11.7311 4.45756 11.989 4.71545L14.8066 7.53306C15.0645 7.79095 15.0645 8.20908 14.8066 8.46697L11.989 11.2846C11.7311 11.5425 11.313 11.5425 11.0551 11.2846C10.7972 11.0267 10.7972 10.6086 11.0551 10.3507L13.4058 8.00002L11.0551 5.64937C10.7972 5.39147 10.7972 4.97335 11.0551 4.71545Z" fill="#171717" />
  </Svg>
);
