import { typo, ActionButton, ring } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import { FloatingPenButtonContainer, StyledFloatingPenButton } from '../FloatingPenButton/FloatingPenButton.styles';

export const Container = styled.div`
  position: relative;
`;

export const InputBorder = styled.div`
  ${ring}
  align-items: center;
  border-radius: 6px;
  display: inline-flex;
  gap: 4px;
  padding: 2px;
`;

export const EditButton = styled(ActionButton) <{ $isFilled?: boolean }>`
  position: relative;
  ${p => p.$isFilled && css`
    --color: ${p.theme.colors.text.primary};
  `}

  ${StyledFloatingPenButton} {
    background-color: ${p => p.theme.colors.actionButton.bgHover};
  }
`;

export const EditButtonContainer = styled(FloatingPenButtonContainer)`
  display: inline-flex;

  &:hover ${EditButton}:not([disabled]) {
    color: ${p => p.theme.colors.actionButton.colorHover};
    background-color: ${p => p.theme.colors.actionButton.bgHover};
  }
`;

export const InputName = styled.input`
  ${typo.body400};
  border: none;
  width: 100%;
  height: 24px;
  outline: none;
  background-color: ${p => p.theme.colors.background.primary};
  padding: 0 6px;
  ${p => p.readOnly && css`
    pointer-events: none;
    cursor: inherit;
  `};
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  bottom: 2px;
  background-color: ${p => p.theme.colors.background.primary};
  display: flex;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 24px;
`;
