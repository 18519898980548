import { ResendInviteDocument } from '@cycle-app/graphql-codegen';
import { FC } from 'react';

import { useProductBase, useSafeMutation } from 'src/hooks';
import { addToaster } from 'src/utils/toasters.utils';

import { ResendButton } from './SettingsUsers.styles';

export const SettingsUserResend: FC<React.PropsWithChildren<{ userId: string; email: string }>> = ({
  userId, email,
}) => {
  const product = useProductBase();
  const [resendUserMutation, { loading }] = useSafeMutation(ResendInviteDocument, {
    onCompleted: (data) => {
      if (data.resendInvite) {
        addToaster({
          message: `Invite was sent to ${email}`,
        });
      }
    },
  });
  return (
    <ResendButton
      isLoading={loading}
      variant="secondary"
      size="M"
      onClick={() => product && resendUserMutation({
        variables: {
          userId,
          productId: product.id,
        },
      })}
    >
      Resend invitation
    </ResendButton>
  );
};
