/* eslint-disable jsx-a11y/label-has-associated-control */
import { UploadIcon } from '@cycle-app/ui/icons';
import { isFileSystemAccessAPIEnabled } from '@cycle-app/utilities';
import { forwardRef } from 'react';
import { DropzoneInputProps } from 'react-dropzone';

import { MainButton, Label } from './DropzoneMini.styles';

type Props = DropzoneInputProps & {
  disabled?: boolean;
  open: VoidFunction;
};

export const UploadButton = forwardRef<HTMLButtonElement, Props>(({
  open, disabled, ...props
}, ref) => {
  if (isFileSystemAccessAPIEnabled) {
    return (
      <MainButton
        ref={ref}
        disabled={disabled}
        onClick={e => {
          e.stopPropagation();
          open();
        }}
      >
        <UploadIcon size={16} />
      </MainButton>
    );
  }

  return (
    <>
      <Label htmlFor="dropisland-input">
        <UploadIcon size={18} />
      </Label>

      <input
        {...props}
        id="dropisland-input"
        disabled={disabled}
        type="file"
        style={{ display: 'none' }}
      />
    </>
  );
});
