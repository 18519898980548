// import { DiscoverSection } from './DiscoverSection';
import { ApiKeysSection } from './ApiKeysSection';
import { AuthorizedAppSection } from './AuthorizedAppSection';
import { GraphQLSection } from './GraphQLSection';
import { OAuthAppSection } from './OAuthAppSection';
import { Container, Header } from './SettingsAPI.styles';
import { WebhooksSection } from './WebhooksSection';

export const SettingsAPI = () => (
  <div>
    <Header>
      <h1>API</h1>
    </Header>

    <Container>
      {/* <DiscoverSection /> */}
      <GraphQLSection />
      <WebhooksSection />
      <ApiKeysSection />
      <OAuthAppSection />
      <AuthorizedAppSection />
    </Container>
  </div>
);
