import { nodeToArray } from '@cycle-app/utilities';
import { Elements, Position } from 'react-flow-renderer';

import { useDoctype } from 'src/hooks/api/useDocType';
import { useProductDoctypesFull } from 'src/hooks/api/useProductDoctypes';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { isFeedback, isInsight } from 'src/utils/docType.util';

import { defaultOptions } from './defaultOptions';
import { getChildren } from './getChildren';
import { getFeedbackHierarchyElements } from './getFeedbackHierarchyElements';
import { getInsightHierarchyElements } from './getInsightHierarchyElements';

type UseDoctypeHierarchyElementsParams = {
  docTypeId?: string;
};

export const useDoctypeHierarchyElements = (
  docTypeId: UseDoctypeHierarchyElementsParams['docTypeId'],
): Elements => {
  const { doctypes } = useProductDoctypesFull();
  const docType = useDoctype();
  const docTypeFromProps = getDocType(docTypeId);
  const d = docTypeFromProps || docType;

  if (!d) return [];

  if (isFeedback(d)) return getFeedbackHierarchyElements(d, doctypes);
  if (isInsight(d)) return getInsightHierarchyElements(d, doctypes);

  const parents = nodeToArray(d?.parents);
  const lastIndex = nodeToArray(d?.parents).length;
  const currentId = 'current';

  // eslint-disable-next-line no-nested-ternary
  const placeHolder = [
    {
      ...defaultOptions,
      id: 'add-parent',
      type: 'custom',
      data: {
        root: true,
        hasChildren: true,
        asPlaceholder: true,
        indexColumn: lastIndex,
        level: 0,
        target: 'parent',
      },
    },
    {
      ...defaultOptions,
      id: 'current',
      type: 'custom',
      data: {
        doctypeId: d.id,
        asPlaceholder: parents.length > 0,
        indexColumn: lastIndex + 0.1,
        level: 1,
      },
      targetPosition: Position.Left,
    },
    {
      id: 'link-add-parent',
      source: 'add-parent',
      target: currentId,
      type: 'customEdge',
      data: {
        root: true,
        rootId: d.id,
        asPlaceholder: true,
      },
    },
    ...parents.length === 0 ? [
      ...getChildren({
        parentDoctype: d,
        level: 2,
        parentElementId: currentId,
        targetPosition: Position.Left,
        indexColumn: lastIndex + 0.2,
        isLastRank: true,
        sourceAdd: currentId,
        rootId: 'add-parent',
      }),
    ] : [],
  ];

  const parentsGraph = parents.map((doctypeParent, index) => {
    const doctypeParentId = `${doctypeParent.id}-0`;
    const parentCurrentId = `${doctypeParentId}-${docType?.id}`;

    return [
      {
        ...defaultOptions,
        id: doctypeParentId,
        type: 'custom',
        data: {
          root: true,
          doctypeId: doctypeParent.id,
          indexColumn: index,
          level: 0,
          target: 'parent',
        },
      },
      {
        ...defaultOptions,
        id: parentCurrentId,
        type: 'custom',
        data: {
          doctypeId: docType?.id,
          indexColumn: index + 0.06,
          level: 1,
          iteration: parents.length > 1 ? index + 1 : 0,
          rootId: doctypeParent.id,
        },
        targetPosition: Position.Left,
      },
      {
        id: `link-${doctypeParentId}-${currentId}`,
        source: doctypeParentId,
        target: parentCurrentId,
        type: 'customEdge',
      },
      ...getChildren({
        parentDoctype: docType,
        level: 2,
        parentElementId: parentCurrentId,
        targetPosition: Position.Left,
        indexColumn: index + 0.12,
        isLastRank: true,
        sourceAdd: parentCurrentId,
        iteration: parents.length > 1 ? index + 1 : 0,
        rootId: doctypeParent.id,
      }),
    ];
  }).flat();

  return [
    // Map parents
    ...parentsGraph,

    // Add empty nodes
    ...placeHolder,
  ];
};
