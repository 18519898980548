import { Tooltip, Shortcut, Flex } from '@cycle-app/ui';
import { ClipboardIcon } from '@cycle-app/ui/icons';
import { useEffect, useState } from 'react';

import { useClipboardReadPermission } from 'src/hooks/useClipboardReadPermission';

import { MainButton } from './DropzoneMini.styles';

export const ClipboardButton = ({
  isDisabled,
  setImageBlob,
}: {
  isDisabled: boolean;
  setImageBlob: (blob: Blob | null) => void;
}) => {
  const [clipboardImage, setClipboardImage] = useState<ClipboardItem | null>(null);

  const readClipboard = async (): Promise<ClipboardItem | null> => {
    try {
      if (!document.hasFocus()) return null;
      const contents = await navigator.clipboard.read();
      const imageContent = contents.find(content => content.types.includes('image/png'));
      if (imageContent) setClipboardImage(imageContent);
      return imageContent ?? null;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error reading clipboard', error);
    }
    return null;
  };

  const createImageBlob = async (clipboardItem: ClipboardItem) => {
    try {
      const blob = await clipboardItem.getType('image/png');
      setImageBlob(blob);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error creating image blob', error);
    }
  };

  const clipboardPermission = useClipboardReadPermission();

  /** Read clipboard on hover */
  useEffect(() => {
    if (!isDisabled && clipboardPermission === 'granted') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      readClipboard();
    } else {
      setClipboardImage(null);
    }
  }, [isDisabled, clipboardPermission]);

  /** Create a file from clipboard image and open the preview */
  useEffect(() => {
    const handlePaste = async () => {
      try {
        if (!clipboardImage) return;
        const blob = await clipboardImage.getType('image/png');
        setImageBlob(blob);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating image blob', error);
      }
    };
    if (!isDisabled && clipboardImage) {
      window.addEventListener('paste', handlePaste);
    }
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, clipboardImage]);

  if (clipboardImage) {
    return (
      <Tooltip
        withPortal
        withWrapper={false}
        placement="bottom"
        content={(
          <Flex $gap={8}>
            <span>Paste from clipboard</span>
            <Shortcut keys={['mod', 'v']} />
          </Flex>
                    )}
      >
        <MainButton
          disabled={isDisabled}
          onClick={(e) => {
            e.stopPropagation();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            createImageBlob(clipboardImage);
          }}
        >
          <ClipboardIcon size={16} />
        </MainButton>
      </Tooltip>
    );
  }

  if (clipboardPermission === 'granted') {
    return (
      <Tooltip
        withPortal
        withWrapper
        placement="bottom"
        content="No image in clipboard"
      >
        <MainButton disabled>
          <ClipboardIcon size={16} />
        </MainButton>
      </Tooltip>
    );
  }

  if (clipboardPermission === 'denied') {
    return (
      <Tooltip
        withPortal
        withWrapper
        placement="bottom"
        content="No permission to read clipboard"
      >
        <MainButton
          disabled
          style={{ cursor: 'not-allowed' }}
        >
          <ClipboardIcon size={16} />
        </MainButton>
      </Tooltip>
    );
  }

  if (clipboardPermission === 'prompt') {
    return (
      <Tooltip
        withPortal
        withWrapper={false}
        placement="bottom"
        content="Click to grant permission to read clipboard"
      >
        <MainButton
          onClick={async e => {
            e.stopPropagation();
            const clipboardItem = await readClipboard();
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            if (clipboardItem) createImageBlob(clipboardItem);
          }}
        >
          <ClipboardIcon size={16} />
        </MainButton>
      </Tooltip>
    );
  }

  return null;
};
