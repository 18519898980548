import { DoctypeRelativeFragment } from '@cycle-app/graphql-codegen';
import { PenFilledIcon } from '@cycle-app/ui/icons';
import { ReactNode } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { DocTypeIcon } from 'src/components/DocTypeIcon';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { useOptimizedBooleanState } from 'src/hooks';
import useDoctypesMutations from 'src/hooks/api/mutations/useDoctypesMutations';
import { useEscape } from 'src/hooks/useEscape';
import { useGetDocTypes } from 'src/reactives/docTypes.reactive';
import { isCustom } from 'src/utils/docType.util';

import {
  List, Item, Header, Title, DescriptionContainer, EditButton, DescriptionTextArea,
} from './DocTypesList.styles';

export const DocTypesList = ({
  filter = isCustom,
  isItemActive,
  onItemClick,
  labelSuffix,
}: {
  filter?: (docType: DoctypeRelativeFragment) => boolean;
  isItemActive?: (doctypeId: string) => boolean;
  onItemClick?: (doctypeId: string) => void;
  labelSuffix?: (docType: DoctypeRelativeFragment) => ReactNode;
}) => {
  const docTypes = Object.values(useGetDocTypes().docTypes).filter(filter).reverse();
  return (
    <List>
      {docTypes.map(docType => (
        <Item
          key={docType.id}
          $isClickable={!!onItemClick}
          $isActive={isItemActive?.(docType.id)}
          onClick={() => onItemClick?.(docType.id)}
        >
          <Header>
            <DocTypeIcon doctype={docType} />
            <Title>{docType.name}</Title>
            {labelSuffix?.(docType)}
          </Header>
          <Description
            description={docType.description?.trim() ?? ''}
            doctypeId={docType.id}
          />
        </Item>
      ))}
    </List>
  );
};

const Description = ({
  description, doctypeId,
}: {
  description: string;
  doctypeId: string;
}) => {
  const [isEditing, {
    setFalseCallback: closeForm,
    setTrueCallback: openForm,
  }] = useOptimizedBooleanState(false);

  if (isEditing) {
    return (
      <DescriptionForm
        close={closeForm}
        value={description}
        doctypeId={doctypeId}
      />
    );
  }

  return (
    <DescriptionContainer $isEmpty={!description}>
      {description || 'No description'}
      <EditButton
        onClick={e => {
          e.stopPropagation();
          openForm();
        }}
      >
        <PenFilledIcon size={14} />
      </EditButton>
    </DescriptionContainer>
  );
};

const DescriptionForm = ({
  value, close, doctypeId,
}: {
  value: string;
  close: VoidFunction;
  doctypeId: string;
}) => {
  const { updateDoctype } = useDoctypesMutations(doctypeId);

  const submit = (description: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateDoctype({ description });
  };

  const debouncedSubmit = useDebouncedCallback(submit, INPUT_ONCHANGE_DEBOUNCE);
  useEscape(close);

  return (
    <DescriptionTextArea
      rows={6}
      defaultValue={value}
      onBlur={close}
      onChange={e => debouncedSubmit(e.target.value)}
      autoResize
    />
  );
};
