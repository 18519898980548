import {
  IntegrationType,
  UpdateHubspotAutopilotDocument,
  UpdateHubspotAutopilotMutationVariables,
  UpdateIntercomAutopilotDocument,
  UpdateIntercomAutopilotMutationVariables,
  UpdateMailAutopilotDocument,
  UpdateMailAutopilotMutationVariables,
  UpdateMeetingIntegrationDocument,
  UpdateMeetingIntegrationMutationVariables,
  UpdateSlackAutopilotDocument,
  UpdateSlackAutopilotMutationVariables,
  UpdateZapierIntegrationDocument,
  UpdateZapierIntegrationMutationVariables,
  UpdateExtensionIntegrationDocument,
  UpdateExtensionIntegrationMutationVariables,
  UpdateGongIntegrationDocument,
  UpdateGongIntegrationMutationVariables,
  UpdateZendeskIntegrationDocument,
  UpdateZendeskIntegrationMutationVariables,
  UpdateSalesforceIntegrationDocument,
  UpdateSalesforceIntegrationMutationVariables,
} from '@cycle-app/graphql-codegen';

import { useSafeMutation } from 'src/hooks';
import { Integration } from 'src/types/integrations.types';

type OptimisticData = {
  id: string;
  extractInsights: boolean;
  markFeedbackAsProcessed: boolean;
  linkToRoadmap: boolean;
  createParent: boolean;
};

export const useUpdateFeedbackAutoPilot = (type: Integration) => {
  const [updateHubspotAutopilot] = useSafeMutation(UpdateHubspotAutopilotDocument);
  const [updateMailAutopilot] = useSafeMutation(UpdateMailAutopilotDocument);
  const [updateIntercomAutopilot] = useSafeMutation(UpdateIntercomAutopilotDocument);
  const [updateSlackAutopilot] = useSafeMutation(UpdateSlackAutopilotDocument);
  const [updateMeetingAutopilot] = useSafeMutation(UpdateMeetingIntegrationDocument);
  const [updateZapierAutopilot] = useSafeMutation(UpdateZapierIntegrationDocument);
  const [updateExtensionAutopilot] = useSafeMutation(UpdateExtensionIntegrationDocument);
  const [updateGongAutopilot] = useSafeMutation(UpdateGongIntegrationDocument);
  const [updateZendeskAutopilot] = useSafeMutation(UpdateZendeskIntegrationDocument);
  const [updateSalesforceAutopilot] = useSafeMutation(UpdateSalesforceIntegrationDocument);

  if (type === IntegrationType.Hubspot) {
    return (variables: UpdateHubspotAutopilotMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateHubspotAutopilot({
        variables,
        optimisticResponse: {
          updateHubspotIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Mail) {
    return (variables: UpdateMailAutopilotMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateMailAutopilot({
        variables,
        optimisticResponse: {
          updateMailIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Intercom) {
    return (variables: UpdateIntercomAutopilotMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateIntercomAutopilot({
        variables,
        optimisticResponse: {
          updateIntercomIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Slack) {
    return (variables: UpdateSlackAutopilotMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateSlackAutopilot({
        variables,
        optimisticResponse: {
          updateSlackIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Meeting) {
    return (variables: UpdateMeetingIntegrationMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateMeetingAutopilot({
        variables,
        optimisticResponse: {
          updateMeetingIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Zapier) {
    return (variables: UpdateZapierIntegrationMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateZapierAutopilot({
        variables,
        optimisticResponse: {
          updateZapierIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Extension) {
    return (variables: UpdateExtensionIntegrationMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateExtensionAutopilot({
        variables,
        optimisticResponse: {
          updateExtensionIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Gong) {
    return (variables: UpdateGongIntegrationMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateGongAutopilot({
        variables,
        optimisticResponse: {
          updateGongIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Zendesk) {
    return (variables: UpdateZendeskIntegrationMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateZendeskAutopilot({
        variables,
        optimisticResponse: {
          updateZendeskIntegration: optimisticData,
        },
      });
    };
  }

  if (type === IntegrationType.Salesforce) {
    return (variables: UpdateSalesforceIntegrationMutationVariables, optimisticData: OptimisticData) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateSalesforceAutopilot({
        variables,
        optimisticResponse: {
          updateSalesforceIntegration: optimisticData,
        },
      });
    };
  }

  return null;
};
