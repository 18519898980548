import { ActionButton } from '@cycle-app/ui';
import {
  CloseIcon, UserIcon, BellIcon, PaletteIcon, PasswordIcon, BotIcon, CommentChatIcon,
} from '@cycle-app/ui/icons';
import { AnimatePresence } from 'framer-motion';

import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import {
  useGetSettingsModal,
  closeSettingsModal,
  openSettingsAccount,
  openSettingsAppearance,
  openSettingsNotifications,
  openSettingsSecurity,
  openSettingsRecording,
  openMeetingsEventsRecording,
} from 'src/reactives/settingsModal.reactive';
import { Tab } from 'src/types/notification.types';

import { useProductAddOn } from '../../hooks';
import { usePrefetchNotificationTriggers } from '../../hooks/api/queries/useNotificationTriggers';
import AccountTab from './AccountTab/AccountTab';
import AppearanceTab from './AppearanceTab/ApprearanceTab';
import { MeetingsTab } from './MeetingsTab/MeetingsTab';
import { NotificationsTab } from './NotificationsTab/NotificationsTab';
import { RecordingTab } from './RecordingTab/RecordingTab';
import SecurityTab from './SecurityTab/SecurityTab';
import {
  StyledPortalModal,
  LeftPanel,
  Menu,
  MenuItem,
  RightPanel,
  Header,
  Title,
  TabContent,
  Separator,
} from './SettingsModal.styles';

const TAB_TITLES: Record<Tab, string> = {
  account: 'Account Settings',
  appearance: 'Appearance',
  security: 'Security',
  notifications: 'Notifications',
  recording: 'Call recording',
  'meetings-events': 'My meetings',
};

const SettingsModal = () => {
  const { visible } = useGetSettingsModal();
  return (
    <AnimatePresence>
      {visible && <SettingsModalVisible />}
    </AnimatePresence>
  );
};

const SettingsModalVisible = () => {
  const { tab } = useGetSettingsModal();
  const { isEnabled: isMeetingsFeatureEnabled } = useProductAddOn('MEETINGS');
  const { isInstalled: isMeetingsIntegrationInstalled } = useMeetingsIntegration();
  usePrefetchNotificationTriggers();
  return (
    <StyledPortalModal hide={closeSettingsModal}>
      <LeftPanel>
        <Menu>
          <MenuItem
            icon={<UserIcon />}
            label={TAB_TITLES.account}
            isActive={tab === 'account'}
            onClick={openSettingsAccount}
          />
          <MenuItem
            icon={<PaletteIcon />}
            label={TAB_TITLES.appearance}
            isActive={tab === 'appearance'}
            onClick={openSettingsAppearance}
          />
          <MenuItem
            icon={<PasswordIcon />}
            label={TAB_TITLES.security}
            isActive={tab === 'security'}
            onClick={openSettingsSecurity}
          />
          <MenuItem
            icon={<BellIcon />}
            label={TAB_TITLES.notifications}
            isActive={tab === 'notifications'}
            onClick={openSettingsNotifications}
          />
          <Separator />
          <MenuItem
            icon={(
              <BotIcon
                style={{
                  marginLeft: '1px',
                  marginRight: '1px',
                }}
              />
                )}
            label={TAB_TITLES.recording}
            isActive={tab === 'recording'}
            onClick={openSettingsRecording}
          />
          {(isMeetingsFeatureEnabled && isMeetingsIntegrationInstalled) && (
            <MenuItem
              icon={<CommentChatIcon />}
              label={TAB_TITLES['meetings-events']}
              isActive={tab === 'meetings-events'}
              onClick={openMeetingsEventsRecording}
            />
          )}
        </Menu>
      </LeftPanel>

      <RightPanel>
        <Header>
          <Title>{TAB_TITLES[tab]}</Title>
          <ActionButton onClick={closeSettingsModal}>
            <CloseIcon />
          </ActionButton>
        </Header>

        <TabContent>
          {tab === 'account' && <AccountTab />}
          {tab === 'appearance' && <AppearanceTab />}
          {tab === 'security' && <SecurityTab />}
          {tab === 'notifications' && <NotificationsTab />}
          {tab === 'recording' && <RecordingTab />}
          {tab === 'meetings-events' && <MeetingsTab />}
        </TabContent>
      </RightPanel>
    </StyledPortalModal>
  );
};

export default SettingsModal;
