import { CloseIcon } from '@cycle-app/ui/icons';

import { useFeatureFlag } from 'src/hooks';
import {
  useOnboardingSlack,
  stopOnboardingSlack,
  useGetPermission,
} from 'src/reactives';
import { Layer } from 'src/types/layers.types';
import { OnboardingSlackStep } from 'src/types/onboardingSlack.type';

import { Modal, CloseButton } from './OnboardingSlack.styles';
import { StepCreateFeedback } from './StepCreateFeedback';
import { StepFeedbackCreated } from './StepFeedbackCreated';
import { StepInstall } from './StepInstall';
import { StepReacji } from './StepReacji';

export const OnboardingSlack = () => {
  const { canReadSettings } = useGetPermission();

  if (!canReadSettings) return null;

  return <OnboardingSlackModal />;
};

const OnboardingSlackModal = () => {
  const { isEnabled } = useFeatureFlag('slack-onboarding-2-0');
  const [{ step }] = useOnboardingSlack();

  if (!isEnabled || !step) return null;

  return (
    <Modal
      hide={stopOnboardingSlack}
      layer={Layer.ModalZ3}
    >
      <CloseButton onClick={stopOnboardingSlack}>
        <CloseIcon size={12} />
      </CloseButton>
      <OnboardingSlackContent step={step} />
    </Modal>
  );
};

type OnboardingSlackContentProps = {
  step: OnboardingSlackStep;
};

const OnboardingSlackContent = ({ step }: OnboardingSlackContentProps) => {
  switch (step) {
    case OnboardingSlackStep.ExtractInsight: // retro-compat
    case OnboardingSlackStep.FeedbackCreated:
      return <StepFeedbackCreated />;
    case OnboardingSlackStep.Reactji:
      return <StepReacji />;
    case OnboardingSlackStep.CreateFeedback:
      return <StepCreateFeedback />;
    case OnboardingSlackStep.Install:
    default:
      return <StepInstall />;
  }
};
