import { CloseIcon } from '@cycle-app/ui/icons';

import { useProductYcDeal } from '../../hooks/product/useProductYcDeal';
import { setUpgradeModal, useGetUpgradeModal } from '../../reactives/upgradeModal.reactive';
import { UpgradeBox } from '../UpgradeBox';
import { StyledPortalModal, StyledActionButton } from './UpgradeModal.styles';

export const UpgradeModal = ({ productId }: { productId: string }) => {
  const { isOpened } = useGetUpgradeModal();
  const { isYcDeal } = useProductYcDeal({ productId });
  return isOpened ? (
    <UpgradeModalContent isYc={!!isYcDeal} />
  ) : null;
};

export const UpgradeModalContent = ({ isYc }: { isYc: boolean }) => {
  return (
    <StyledPortalModal
      hide={() => setUpgradeModal({ isOpened: false })}
    >
      <StyledActionButton onClick={() => setUpgradeModal({ isOpened: false })}>
        <CloseIcon />
      </StyledActionButton>
      <UpgradeBox
        subtitle="Upgrade to go further"
        description="Cycle is the fastest way for your team to capture product feedback and share customer insights – without the busywork."
        isYC={isYc}
      />
    </StyledPortalModal>
  );
};
