import { typo, ShyScrollbarCss, ActionButton, Flex } from '@cycle-app/ui';
import { RightIcon, ArrowUpCircleIcon } from '@cycle-app/ui/icons';
import { mobile, small } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 32px;

  ${mobile} {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const Title = styled.h2`
  ${typo.headerLight}
  color: ${p => p.theme.colors.text.primary};
`;

export const SliderScroll = styled.div`
  ${ShyScrollbarCss}
  margin-top: 16px;
  overflow-y: hidden;
  overscroll-behavior: contain;
  scroll-snap-type: x mandatory;
`;

export const SliderContainer = styled.div`
  --margin: 32px;
  margin-left: var(--margin);

  ${mobile} {
    --margin: 16px;
  }
`;

export const Slider = styled.div`
  display: flex;
  margin-left: calc(-1 * var(--margin));

  /* we have spaces on the sides, pseudo element fill the gap */
  &::before, &::after {
    content: "";
    flex: none;
  }

  &::before {
    width: 16px;
  }

  &::after {
    width: var(--margin);
  }

  ${mobile} {
    &::before {
      display: none;
    }
  }
`;

export const SliderItem = styled.div`
  background-color: ${p => p.theme.colors.home.card.bg};
  border-radius: 12px;
  display: flex;
  flex: none;
  gap: 32px;
  margin-left: 16px;
  padding: 20px 24px;
  scroll-margin-left: var(--margin);
  scroll-snap-align: start;

  /* displays two items */
  /* hardcoded for the home, until we need to use it elsewhere to make it generic */
  width: calc(100% / 2 - 40px);

  ${small} {
    /* displays one item */
    width: calc(100% / 1 - 64px);
  }
`;

export const Icon = styled.div`
  --bg: white;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 1.8125px 4.53125px 0px rgba(0, 0, 0, 0.05);
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
              linear-gradient(180deg, rgba(0, 0, 0, 0.0324) 0%, rgba(0, 0, 0, 0.0546) 100%) border-box;
  border: 1.81px solid transparent;
  display: flex;
  flex: none;
  height: 58px;
  justify-content: center;
  width: 58px;

  ${p => p.theme.isDark && `--bg: ${p.theme.baseColors.Grey850};`}
`;

export const ItemTitle = styled.h4`
  ${typo.body500}
`;

export const ItemDesc = styled.p`
  color: ${p => p.theme.colors.text.secondary};
  line-height: 20px;
  margin-top: 2px;
`;

export const Link = styled.a`
  color: ${p => p.theme.colors.text.secondary};
  margin-top: 10px;
  display: inline-block;

  svg {
    color: ${p => p.theme.colors.text.disabled};
    vertical-align: text-bottom;
    margin-left: 4px;
  }
`;

export const LeftIcon = styled(RightIcon)`
  transform: rotate(-180deg);
`;

export const SliderNav = styled(ActionButton)`
  color: ${p => p.theme.colors.text.secondary};

  &[disabled] {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const DropIconContainer = styled(Flex)`
  background: radial-gradient(110.17% 145.29% at 5.49% 8.27%, #A990FF 0%, #57CFEF 52.39%, #6DDEBD 78.97%, rgba(127, 234, 150, 0.43) 100%);
  border-radius: 50%;
  height: 32px;
  width: 32px;
`;

export const DropIcon = styled(ArrowUpCircleIcon)`
  color: #fff;
`;
