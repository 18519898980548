import { getDocSlug } from '@cycle-app/utilities';
import { useMemo, useCallback } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { useDocsSelection } from 'src/hooks/useDocsSelection';
import { useHotkeyListener } from 'src/hooks/useHotkeyListener';
import { useIsWelcomePage } from 'src/hooks/useIsWelcomePage';
import { useNavigate } from 'src/hooks/useNavigate';
import { usePageId } from 'src/hooks/usePageId';
import { getBoardGroupState, useGetBoardGroupEditing } from 'src/reactives/boardGroup.reactive';
import { getBoardNewDocPositionState } from 'src/reactives/boardNewDoc/newDocPosition.reactive';
import { getCreateDoc } from 'src/reactives/createDoc.reactive';
import { getDocItem } from 'src/reactives/docItem.reactive';
import { Layer } from 'src/types/layers.types';
import { ShortcutBoard } from 'src/types/shortcuts.types';
import { getDocFromCache } from 'src/utils/cache.utils';

export const useBoardShortcutListener = () => {
  const isWelcomePage = useIsWelcomePage();
  const pageId = usePageId();
  const callbacks = useBoardShortcutCallbacks();
  useHotkeyListener({
    callbacks,
    shortcuts: Object.values(ShortcutBoard),
    enabled: () => ![PageId.DocFullPage].includes(pageId) && !isWelcomePage,
    disableOnLayers: [Layer.DocPanel, Layer.Dropdown, Layer.Modal, Layer.ModalZ2, Layer.ModalZ3],
    preventCallback: () => (
      getBoardNewDocPositionState().draftPosition !== null ||
      getCreateDoc().modalVisible
    ),
  });
};

export const useBoardShortcutCallbacks = () => {
  const { navigate } = useNavigate();
  const {
    toggleSelectDoc, selectAllDocsInGroup,
  } = useDocsSelection();
  const boardGroupEditing = useGetBoardGroupEditing();

  const handleOpenDoc = useCallback(() => {
    if (boardGroupEditing.id) return;
    const { hoverDocId } = getDocItem();
    if (!hoverDocId) return;
    const doc = getDocFromCache(hoverDocId);
    if (doc) {
      navigate(PageId.Doc, { docSlug: getDocSlug(doc) });
    }
  }, [boardGroupEditing.id, navigate]);

  const handleOpenDocFullPage = useCallback(() => {
    const { hoverDocId } = getDocItem();
    if (!hoverDocId) return;
    const doc = getDocFromCache(hoverDocId);
    if (hoverDocId && doc) {
      navigate(PageId.DocFullPage, { docSlug: getDocSlug(doc) });
    }
  }, [navigate]);

  const handleSelectDoc = useCallback(() => {
    const { hoverDocId } = getDocItem();
    if (hoverDocId) {
      toggleSelectDoc(hoverDocId);
    }
  }, [toggleSelectDoc]);

  const handleSelectAllDocs = useCallback(() => {
    const { hoverGroupId } = getBoardGroupState();
    if (hoverGroupId) {
      selectAllDocsInGroup(hoverGroupId);
    }
  }, [selectAllDocsInGroup]);

  return useMemo(() => ({
    [ShortcutBoard.OpenDoc]: handleOpenDoc,
    [ShortcutBoard.OpenDocFullPage]: handleOpenDocFullPage,
    [ShortcutBoard.SelectDoc]: handleSelectDoc,
    [ShortcutBoard.SelectAllDocs]: handleSelectAllDocs,
  }), [handleOpenDoc, handleOpenDocFullPage, handleSelectAllDocs, handleSelectDoc]);
};
