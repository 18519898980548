import { PenIcon } from '@cycle-app/ui/icons';

import { DocParentPanel } from 'src/components/DocParentDropdown/DocParentPanel';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { getDocType } from 'src/reactives/docTypes.reactive';
import { requestInsightUpdate } from 'src/utils/requestInsightUpdate';

import { ButtonItem } from './QuotesSummary.styles';

export const ChangeQuoteFeature = ({ insightId }: { insightId: string }) => {
  return (
    <ToggleDropdown
      placement="bottom-start"
      withPortal={false}
      withWrapper={false}
      controlled={false}
      animation={false}
      content={props => (
        <Content
          hide={props.hide}
          insightId={insightId}
        />
      )}
      button={(props) => (
        <ButtonItem
          active={props['data-active']}
          onClick={() => {
            if (!requestInsightUpdate()) return;
            props.toggle();
          }}
        >
          <PenIcon size={14} />
          Change feature
        </ButtonItem>
      )}
    />
  );
};

type ContentProps = {
  hide: VoidFunction;
  insightId: string;
};

const Content = ({
  hide, insightId,
}: ContentProps) => {
  const insight = useDocPanelContext(ctx => ctx.doc?.docTargets.edges.find(edge => edge.node?.id === insightId)?.node);
  const docType = getDocType(insight?.doc?.doctype.id);
  if (!docType || !insight?.doc) return null;
  return (
    <DocParentPanel
      docId={insight.doc.id}
      docType={docType}
      showNoneOption={!!insight.doc?.parent}
      hide={hide}
      onChange={hide}
    />
  );
};
