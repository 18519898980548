import { GraphQLIcon } from '@cycle-app/ui/icons';

import { DOCS_URL } from 'src/constants/docs.constants';
import { openIntercom } from 'src/utils/intercom.utils';

import { Section, SectionTitle, Info, Button, DocLink } from './SettingsAPI.styles';

export const GraphQLSection = () => (
  <Section>
    <header>
      <SectionTitle>
        <GraphQLIcon />
        GraphQL API
      </SectionTitle>
      <Info>
        Cycle’s GraphQL API provides a programmable interface to your data.
        You can use it to build public or private apps, workflows, and integrations on top of Cycle.
        For help and questions, contact us.
      </Info>
    </header>

    <footer>
      <DocLink href={DOCS_URL} target="_blank">
        Documentation
      </DocLink>
      <Button variant="secondary" onClick={() => openIntercom()}>
        Contact us
      </Button>
    </footer>
  </Section>
);
