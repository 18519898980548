import { Color as ColorApi } from '@cycle-app/graphql-codegen';
import { FC, MouseEvent } from 'react';

import { CheckIcon } from '../../icons';
import { Container, CheckContainer } from './CheckedDot.styles';

interface Props {
  className?: string;
  color: ColorApi;
  hasIcon?: boolean;
  small?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  variant?: 'user' | 'property';
}

export const CheckedDot: FC<React.PropsWithChildren<Props>> = ({
  hasIcon = false,
  color,
  small,
  variant = 'user',
  ...otherProps
}) => (
  <Container
    $color={color}
    $hasIcon={hasIcon}
    $small={small}
    $variant={variant}
    {...otherProps}
  >
    {hasIcon ? <CheckContainer><CheckIcon /></CheckContainer> : null}
  </Container>
);
