import styled from 'styled-components';

import type { AddOn } from 'src/types/addOn.types';

interface ContainerProps {
  $name: AddOn['name'];
}

// In figma: colors == --bg
// In figma: borders == --borders
export const Container = styled.div<ContainerProps>`
  --bg: linear-gradient(352deg, #FFFFFF 6.96%, #F6F6F6 89.73%);
  --borders: linear-gradient(180deg, #FFFFFF 0%, #DADADA 100%);
  --color: ${p => (p.theme.isDark ? p.theme.colors.text.black : p.theme.colors.text.primary)};
  --shadow: 0px 0px 1.5px 0px rgba(0, 0, 0, 0.15);
  --border: 1px;
  --radius: 6px;

  align-items: center;
  color: var(--color);
  display: flex;
  flex: none;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;
  z-index: 1;

  &::before, &::after {
    border-radius: var(--radius);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &::before {
    border: var(--border) solid transparent;
    z-index: 3;
    background: var(--bg) padding-box, var(--borders) border-box;
  }

  &::after {
    z-index: 2;
    background: var(--bg);
    box-shadow: var(--shadow);
  }

  > svg, > img {
    flex: none;
    z-index: 10;
    width: 12px;
    height: auto;
  }

  ${p => p.$name === 'UNLIMITED_AI_QUERIES' && `
    --color: #FFF;
    --box-shadow: none;
    --bg: linear-gradient(128.26deg, #AE8FFF 4.82%, #00D2F3 52.52%, #4DED8D 95.87%);
    --borders: linear-gradient(180deg, rgba(255, 255, 255, 0.37) 0%, rgba(0, 0, 0, 0.16) 100%);
  `}

  ${p => p.$name === 'CUSTOM_SECTIONS' && `
    --color: #FFF;
    --box-shadow: none;
    --bg: linear-gradient(91.49deg, #2345F0 11.21%, #556EF5 96.17%);
    --borders: linear-gradient(180deg, rgba(179, 190, 252, 0.4) 0%, rgba(14, 50, 224, 0.4) 100%);
  `}

  ${p => p.$name === 'activity-log' && `
    --color: #FFF;
    --bg: linear-gradient(91.49deg, #F49800 11.21%, #F3CB3E 96.17%);
    --borders: linear-gradient(180deg, rgba(255, 211, 134, 0.4) 0%, rgba(224, 120, 0, 0.4) 100%);
    --box-shadow: none;
  `}

  ${p => (p.$name === 'admin-tools' || p.$name === 'saml') && `
    --color: #FFF;
    --bg: linear-gradient(90.45deg, #6A6A6A 7.39%, #A0A0A0 99.7%);
    --borders: linear-gradient(180deg, rgba(170, 170, 170, 0.4) 0%, rgba(67, 67, 67, 0.4) 100%);
    --box-shadow: none;
  `}

  ${p => p.$name === 'UNLIMITED_PROPERTIES' && `
    --color: #FFF;
    --bg: linear-gradient(90deg, #D431FA 0%, #E783FD 100%);
    --borders: linear-gradient(180deg, rgba(247, 206, 255, 0.4) 0%, rgba(198, 0, 247, 0.4) 100%);
    --box-shadow: none;
  `}

  ${p => p.$name === 'CUSTOMER_ATTRIBUTES' && `
    --color: #FFF;
    --bg: linear-gradient(120.7deg, #00AA4E 12.22%, #1DD16C 97.09%);
    --borders: linear-gradient(180deg, rgba(0, 226, 117, 0.4) 0%, rgba(0, 130, 52, 0.4) 100%);
    --box-shadow: none;
  `}

  ${p => p.$name === 'ANALYSES' && `
    --color: #FFF;
    --bg: linear-gradient(90.45deg, #FE2083 7.39%, #FF7DB1 99.7%);
    --borders: linear-gradient(180deg, rgba(255, 165, 200, 0.4) 0%, rgba(179, 0, 79, 0.4) 100%);
    --box-shadow: none;
  `}

  ${p => p.$name === 'MEETINGS' && `
    --color: #FFF;
    --bg: linear-gradient(91.49deg, #009BEE 11.21%, #12C3F1 96.17%);
    --borders: linear-gradient(180deg, rgba(131, 219, 252, 0.4) 0%, rgba(0, 119, 190, 0.4) 100%);
    --box-shadow: none;
  `}

  ${p => p.$name === 'UNLIMITED_DOCTYPES' && `
    --color: #FFF;
    --bg: linear-gradient(91.49deg, #FC0018 11.21%, #FF4952 96.17%);
    --borders: linear-gradient(180deg, rgba(255, 157, 160, 0.4) 0%, rgba(184, 0, 0, 0.4) 100%);
    --box-shadow: none;
  `}

  ${p => p.$name === 'file-size-unlimited' && `
    --color: #FFF;
    --bg: linear-gradient(91.49deg, #FA4A0A 11.21%, #FF764E 96.17%);
    --borders: linear-gradient(180deg, rgba(255, 180, 158, 0.4) 0%, rgba(183, 36, 0, 0.4) 100%);
    --box-shadow: none;
  `}
`;
