import { IntegrationType } from '@cycle-app/graphql-codegen';
import { Tooltip, SelectPanel } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import orderBy from 'lodash/orderBy';
import { FC, useMemo, useState } from 'react';

import { UninstallIntegrationModal } from 'src/app/Main/Settings/SettingsIntegrations/UninstallIntegrationModal';
import { ToggleDropdown } from 'src/components/DropdownLayer';
import { ACTIVE_INTEGRATIONS, CUSTOMERS_INTEGRATIONS, integrationsDataMap } from 'src/constants/integrations.constants';
import { useProductIntegrations, useInstallIntegration, useCurrentBilling } from 'src/hooks';
import { useGetLastView } from 'src/reactives/lastView.reactive';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { useGetIntegrationPermission } from 'src/reactives/permission.reactive';
import { FrontEndIntegration } from 'src/types/integrations.types';
import { executeIntegration } from 'src/utils/integrations.utils';

import { ListContainer } from '../ViewDocTypes/ViewDocTypes.styles';
import { HeaderActionButton } from '../Views/Roadmap/Roadmap.styles';
import { Container, Info, SourceButton } from './InboxSources.styles';

const openLimitationsModal = () => setLimitationsModal({ action: 'INTEGRATION_ADD' });

type InboxSourcesProps = {
  /**
   * onlyCustomers means to show only sources where customer sync is possible
   */
  onlyCustomers?: boolean;
};

export const InboxSources: FC<React.PropsWithChildren<InboxSourcesProps>> = ({ onlyCustomers = false }) => {
  const { isNewInbox } = useGetLastView();
  const install = useInstallIntegration();
  const { sourcesByStatus } = useProductIntegrations();
  const { canAddIntegration } = useGetIntegrationPermission();
  const billing = useCurrentBilling();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const INTEGRATIONS = onlyCustomers ? CUSTOMERS_INTEGRATIONS : ACTIVE_INTEGRATIONS;
  const uninstalled = {
    active: sourcesByStatus.uninstalled.filter(i => INTEGRATIONS.includes(i.integrationType)),
    inactive: sourcesByStatus.uninstalled.filter(i => !INTEGRATIONS.includes(i.integrationType)),
  };

  const installed = useMemo(() => orderBy(
    sourcesByStatus.installed
      .filter(i => (onlyCustomers
        ? CUSTOMERS_INTEGRATIONS.includes(i.integrationType as IntegrationType)
        : true))
      .filter(i => (!(billing?.plan === 'FREE' && ['ZAPIER', 'CHROME'].includes(i.integrationType))))
      .map(i => ({
        ...i,
        order: integrationsDataMap[i.integrationType].order,
      })),
    ['order'],
  ), [sourcesByStatus.installed, onlyCustomers, billing?.plan]);
  const showAddButton = sourcesByStatus.uninstalled.length > 0;

  return (
    <Container>
      {installed.length > 0 && (
        <ListContainer $isNewInbox={isNewInbox}>
          {installed
            .map(source => {
              const data = integrationsDataMap[source.integrationType];
              return (
                <Tooltip
                  key={source.integrationType}
                  content={source.integrationType === IntegrationType.Meeting
                    ? `Click to ${source.integration?.provider?.id ? 'remove' : 'install'}`
                    : data.action ?? data.label}
                  title={source.integrationType === IntegrationType.Meeting
                    ? data.action ?? data.label
                    : undefined}
                  placement="bottom"
                  withPortal
                >
                  <SourceButton
                    onClick={() => {
                      if (source.integrationType === IntegrationType.Meeting) {
                        if (source.integration?.provider) {
                          setIsModalVisible(true);
                        } else {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          install(IntegrationType.Meeting, source.integration);
                        }
                        return;
                      }
                      executeIntegration(source.integrationType, source.integration);
                    }}
                  >
                    {data.icon}
                  </SourceButton>
                  {source.integrationType === IntegrationType.Meeting && source.integration?.id && (
                    <UninstallIntegrationModal
                      type={IntegrationType.Meeting}
                      id={source.integration.id}
                      isVisible={isModalVisible}
                      hide={() => setIsModalVisible(false)}
                    />
                  )}
                </Tooltip>
              );
            })}
        </ListContainer>
      )}

      {showAddButton && (
        <ToggleDropdown
          button={props => (
            <HeaderActionButton
              tooltip="Add source"
              tooltipPlacement="bottom"
              onClick={canAddIntegration ? props.onClick : openLimitationsModal}
              ref={props.ref}
              $isNewInbox={isNewInbox}
            >
              <AddIcon />
            </HeaderActionButton>
          )}
          content={props => (
            <SelectPanel
              hideSearch
              onOptionChange={async option => {
                props.hide();
                if (option.value === IntegrationType.Extension || option.value === IntegrationType.Zapier) {
                  executeIntegration(option.value);
                  return;
                }
                const integration = uninstalled.active.find(source => source.integrationType === option.value)?.integration;
                await install(integration?.type || option.value as FrontEndIntegration, integration);
              }}
              options={[
                ...uninstalled.active.map(source => {
                  const value = source.integrationType;
                  const data = integrationsDataMap[value];
                  return {
                    value,
                    label: data.label,
                    icon: data.icon,
                  };
                }),
                ...uninstalled.inactive.map(source => {
                  const value = source.integrationType;
                  const data = integrationsDataMap[value];
                  return {
                    value,
                    label: data.label,
                    icon: data.icon,
                    disabled: true,
                    end: <Info>Coming soon</Info>,
                  };
                }),
              ]}
            />
          )}
        />
      )}
    </Container>
  );
};
