import {
  ChangelogBaseFragment, Color, ContentSection, HeroSection, ReleaseTagStyle, Typography,
} from '@cycle-app/graphql-codegen';
import { useForm } from 'react-hook-form';

type Templates =
| 'cycle_light'
| 'cycle_dark'
| 'fey'
| 'figma'
| 'loops'
| 'attio'
| 'june'
| 'linear'
| 'gumroad'
| 'framer';

export type ChangelogBuilderFormData = Pick<ChangelogBaseFragment,
// Content
| 'socialLinkText'
| 'socialLinkURL'
| 'subtitle'
| 'title'
// Ship
| 'shipBadgeColor'
// Typography
| 'typography'
// Text
| 'heroSection'
| 'contentSection'
// Colors
| 'headersColor'
| 'bodyColor'
| 'backgroundColor'
| 'dividerColor'
| 'linkColor'
// Release tags
| 'releaseTagStyle'
| 'releaseTagColor'
> & {
  template: Templates | null;
  logo_url: string;
  logo_file: File | null;
  tags: { id: string; value: string; color: string }[];
  favicon_url: string;
  favicon_file: File | null;
};

export type ChangelogBuilderTemplateValue = {
  value: Templates;
  label: string;
  icon: JSX.Element;
  theme: {
    heroSection: HeroSection;
    contentSection: ContentSection;
    backgroundColor: ChangelogBaseFragment['backgroundColor'];
    headersColor: ChangelogBaseFragment['headersColor'];
    linkColor: ChangelogBaseFragment['linkColor'];
    bodyColor: ChangelogBaseFragment['bodyColor'];
    shipBadgeColor: ChangelogBaseFragment['shipBadgeColor'];
    releaseTagStyle: ChangelogBaseFragment['releaseTagStyle'];
    releaseTagColor: ChangelogBaseFragment['releaseTagColor'];
    dividerColor: ChangelogBaseFragment['dividerColor'];
    // Can be just string[], but array of 8 strings make the type consistent.
    tagsColors: [
      string, string, string, string,
      string, string, string, string,
    ];
  };
};

type Props = {
  changelog: ChangelogBaseFragment;
};

export const useChangelogBuilderForm = ({ changelog }: Props) => {
  return useForm<ChangelogBuilderFormData>({
    defaultValues: {
      template: null,
      // Content
      socialLinkText: changelog.socialLinkText || '',
      socialLinkURL: changelog.socialLinkURL || '',
      subtitle: changelog.subtitle || '',
      title: changelog.title || '',
      // Logo
      logo_file: null,
      logo_url: changelog.logo?.url || '',
      // Favicon
      favicon_file: null,
      favicon_url: changelog.favicon?.url || '',
      // Ship
      shipBadgeColor: changelog.shipBadgeColor || Color.A,
      // Typography
      typography: changelog.typography || Typography.Inter,
      // Text
      heroSection: changelog.heroSection || HeroSection.L,
      contentSection: changelog.contentSection || ContentSection.Unique,
      // Colors
      headersColor: changelog.headersColor || '#000000',
      bodyColor: changelog.bodyColor || '#000000',
      backgroundColor: changelog.backgroundColor || '#FFFFFF',
      dividerColor: changelog.dividerColor || '#E6E6E6',
      linkColor: changelog.linkColor || '#2B44E7',
      // Release tags
      releaseTagStyle: changelog.releaseTagStyle || ReleaseTagStyle.Background,
      releaseTagColor: changelog.releaseTagColor || '#000000',
      tags: changelog.releaseNoteTagValues.map(tag => ({
        id: tag.id,
        value: tag.value,
        color: tag.color,
      })),
    },
  });
};
