import { typo } from '@cycle-app/ui';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${p => (p.theme.isDark ? p.theme.baseColors.Grey850 : p.theme.colors.background.secondary)};
  border-radius: 12px;
  display: flex;
  gap: 12px;
  padding: 12px;
`;

export const Name = styled.p`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;
