import { Color, SectionType } from '@cycle-app/graphql-codegen';
import { Emoji } from '@cycle-app/ui';

import { BoardDefaultIcon } from 'src/components/BoardDefaultIcon';
import { IconDropdown } from 'src/components/IconDropdown';
import { useSidebarCollapsed } from 'src/hooks';
import { useChangeBoardEmojiMutation } from 'src/hooks/api/mutations/useBoardMutations';
import { setLimitationsModal } from 'src/reactives';
import { getPermission } from 'src/reactives/permission.reactive';

import { IconButton } from './SidebarItemBoard.styles';

interface Props {
  viewId: string;
  color: Color | null | undefined;
  emoji: string | null | undefined;
  sectionType: SectionType | undefined;
}

export const CustomViewIcon = ({
  emoji, color, sectionType, ...props
}: Props) => {
  const isCollapsed = useSidebarCollapsed();

  if (!isCollapsed) {
    return (
      <CustomViewIconEditable
        emoji={emoji}
        color={color}
        sectionType={sectionType}
        {...props}
      />
    );
  }

  if (emoji) {
    return (
      <Emoji
        emoji={emoji}
        size={16}
        color={color}
      />
    );
  }

  return (
    <BoardDefaultIcon
      sectionType={sectionType}
    />
  );
};

export const CustomViewIconEditable = ({
  viewId,
  color,
  emoji,
  sectionType,
}: Props) => {
  const { changeBoardEmoji } = useChangeBoardEmojiMutation();
  return (
    <IconDropdown
      color={color}
      onIconChange={value => changeBoardEmoji(viewId, value, color)}
      onColorChange={value => changeBoardEmoji(viewId, emoji, value)}
      button={(buttonProps) => (
        <IconButton
          ref={buttonProps.ref}
          forceFocus={buttonProps['data-active']}
          onClick={e => {
            e.stopPropagation();
            if (!getPermission().canUpdateView) {
              setLimitationsModal({ action: 'VIEW_UPDATE' });
              return;
            }
            buttonProps.onClick(e);
          }}
        >
          {emoji
            ? <Emoji emoji={emoji} size={16} color={color} />
            : <BoardDefaultIcon sectionType={sectionType} />}
        </IconButton>
      )}
    />
  );
};
