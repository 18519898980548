import { AiIcon } from '@cycle-app/ui/icons';
import { useMeasure } from '@cycle-app/utilities';
import { Editor } from '@tiptap/core';
import { FC, RefObject, useRef, useCallback, useEffect } from 'react';

import DocEditor from 'src/components/DocEditor/DocEditor';
import { DocReleaseNoteSection } from 'src/components/DocReleaseNoteSection';
import { AiIsWriting } from 'src/components/EditorAiMenu/EditorAiMenu.styles';
import { FeedbackPreviewSection } from 'src/components/FeedbackPreviewSection';
import { QuotesSummary } from 'src/components/QuotesSummary';
import { VerifyQuotes } from 'src/components/VerifyQuotes';
import { RIGHT_PANEL_WIDTH } from 'src/constants/docPanel.constants';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useDocDocSource, useWindowSize, useIsOffline, useIsRoadmapsEnabled } from 'src/hooks';
import useDocCoverDropzone from 'src/hooks/doc/useCoverDropzone';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';
import { useGetDocPanel } from 'src/reactives/docRightPanel.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetSidebarCollapsed } from 'src/reactives/sidebar.reactive';
import { FullDocWithPublicId } from 'src/types/doc.types';
import { isFeedback, isInsight } from 'src/utils/docType.util';

import { DocPanelBaseProps } from '../DocPanel.types';
import DocPanelCover from '../DocPanelCover/DocPanelCover';
import {
  HeaderContainer,
  ScrollableContent,
  DocPanelActions,
  DocPanelDocAttributes,
  CoverContainer,
  DocPanelEditableTitle,
  EditorWrapper,
  DocParentTitle,
  StyledDocParentDropdown,
  PreTitle,
  InsightAiLoadingState,
  InsightAiLoadingStateContainer,
  QuotesSummarySection,
  MainContent,
} from './DocPanelContent.styles';
import { insertSummaryQuotes } from './DocPanelContent.utils';

const quotesEnabled = import.meta.env.VITE_EXTRACT_QUOTES === 'on';

/** Dirty fix to simulate a singleton for the quote summary insertion */
const insertedQuote: string[] = [];

interface Props extends Omit<DocPanelBaseProps, 'getDropzonePropsTopBar'> {
  containerRef: RefObject<HTMLDivElement>;
  doc: FullDocWithPublicId;
  isDraggingCover: boolean;
  isUploadingCover: boolean;
  onEscapeEdition?: VoidFunction;
  focusOnMount?: boolean;
  disabled?: boolean;
}

export const DocPanelContent: FC<React.PropsWithChildren<Props>> = ({
  containerRef,
  doc,
  getDropzonePropsCover,
  mode = 'edit-doc',
  onOpenCoverInputFile,
  onTitleUpdated,
  onEscapeEdition,
  focusOnMount,
  isDraggingCover: isDraggingCoverFromTopOrCover,
  isUploadingCover: isUploadingCoverFromTopOrCover,
  disabled = false,
}) => {
  // useWindowSize is necessary to trigger a re-render when the window size changes: we might need to hide or show the floating actions
  useWindowSize();
  const {
    blockId,
    docSource,
    isLoading: isDocSourceLoading,
  } = useDocDocSource({
    // Empty string is used to skip the query in case is not a built-in insight
    docId: isInsight(doc.doctype) ? doc.id : '',
  });
  const {
    getRootProps: getDropzonePropsTitleAndAttrs,
    isDragActive: isDragActiveFromTitleAndAttrs,
    isUploadingCover: isUploadingCoverTitleAndAttrs,
  } = useDocCoverDropzone({ docId: doc.id });
  const isOffline = useIsOffline();
  const insightsAiData = useDocPanelContext(ctx => ctx.insightsAiData);
  const isInsightsAiGenerating = useDocPanelContext(ctx => ctx.isInsightsAiGenerating);
  const isInsightsReadyToBeInsertedInDoc = useDocPanelContext(ctx => ctx.isInsightsReadyToBeInsertedInDoc);
  const onInsightsAiInserted = useDocPanelContext(ctx => ctx.onInsightsAiInserted);
  const isRoadmapsEnabled = useIsRoadmapsEnabled();

  const isDraggingCover = isDraggingCoverFromTopOrCover || isDragActiveFromTitleAndAttrs;
  const isUploadingCover = isUploadingCoverFromTopOrCover || isUploadingCoverTitleAndAttrs;
  const showStatus = isFeedback(doc.doctype) ? true : isRoadmapsEnabled;

  const focusToContent = useCallback(() => {
    const editor = containerRef.current?.querySelector('.ProseMirror');
    if (!(editor instanceof HTMLElement)) return;
    editor?.focus();
  }, [containerRef]);

  const isMobile = useIsMobile();

  // The TipTap component
  const docEditor = useRef<Editor>();
  // The ProseMirror element
  const proseMirrorElement = useRef<HTMLDivElement | null>(null);

  const isReleasesEnabled = useIsReleasesEnabled();

  const setEditor = useDocPanelContext(ctx => ctx.setEditor);
  const docPanelEditor = useDocPanelContext(ctx => ctx.editor);

  useEffect(() => {
    queueMicrotask(() => {
      const strData = JSON.stringify(insightsAiData);
      if (isInsightsReadyToBeInsertedInDoc && docEditor.current && !insertedQuote.includes(strData)) {
        insertedQuote.push(strData);
        insertSummaryQuotes(docEditor.current, insightsAiData);
        onInsightsAiInserted();
      }
    });
  }, [insightsAiData, isInsightsReadyToBeInsertedInDoc, onInsightsAiInserted]);

  const isSidebarOpen = !useGetSidebarCollapsed().collapsed;
  const isRightPanelOpen = useGetDocPanel().isExpanded;
  const {
    ref: scrollableRef,
    rect: scrollableRect,
  } = useMeasure<HTMLDivElement>([isSidebarOpen]);

  const scrollableWidth = scrollableRect ? (scrollableRect?.width - (isRightPanelOpen ? RIGHT_PANEL_WIDTH : 0)) : null;

  return (
    <ScrollableContent
      ref={scrollableRef}
      $disabled={disabled}
    >
      <MainContent>
        {doc.cover?.url && (
          <CoverContainer>
            <DocPanelCover
              docId={doc.id}
              coverUrl={doc.cover.url}
              isDragActive={isDraggingCover}
              getDropzoneProps={getDropzonePropsCover}
              onUpdateCoverClicked={onOpenCoverInputFile}
            />
          </CoverContainer>
        )}

        <HeaderContainer
          {...getDropzonePropsTitleAndAttrs()}
          // Intentional empty function to prevent the dropzone to open the finder on `Enter`
          onKeyDown={undefined}
          coloredTopBorder={!doc?.cover?.url && (isDraggingCover || isUploadingCover)}
        >
          <PreTitle>
            {isMobile && (
              <StyledDocParentDropdown
                docId={doc.id}
                docTypeId={doc.doctype.id}
                size="S"
                showIcon
                showParentTitle={false}
                minimal
                absoluteEdit
                showLinearAutoCreate
              >
                {doc.parent?.title && (
                  <DocParentTitle>
                    {` · ${doc.parent.title}`}
                  </DocParentTitle>
                )}
              </StyledDocParentDropdown>
            )}
            <DocPanelActions
              doc={doc}
              onAddCoverClicked={onOpenCoverInputFile}
              isDraggingCover={isDraggingCover}
              isUploadingCover={isUploadingCover}
              docSource={docSource}
            />
          </PreTitle>
          <DocPanelEditableTitle
            id={doc.id}
            title={doc.title}
            onTitleUpdated={onTitleUpdated}
            onNext={() => containerRef.current?.click()}
            onEscape={onEscapeEdition}
            onEnter={focusToContent}
            focusEndOnMount={focusOnMount}
          />
          <DocPanelDocAttributes
            doc={doc}
            showDoctype={!isFeedback(doc.doctype)}
            showDocId={!isFeedback(doc.doctype)}
            showAssignee
            editor={docPanelEditor}
            showStatus={showStatus}
          />
        </HeaderContainer>

        {quotesEnabled && isFeedback(doc.doctype) && (
          <QuotesSummarySection>
            <QuotesSummary docId={doc.id} />
          </QuotesSummarySection>
        )}

        {isReleasesEnabled && doc.doctype.release && doc.releaseNote && (
          <DocReleaseNoteSection
            releaseNote={doc.releaseNote}
            docId={doc.id}
            docStatusType={doc.status?.type}
          />
        )}
        {isInsight(doc.doctype) && docSource && (
          <FeedbackPreviewSection
            docId={doc.id}
            blockId={blockId}
            docSource={docSource}
            isLoading={isDocSourceLoading}
          />
        )}

        {isInsightsAiGenerating && (
          <InsightAiLoadingState>
            <InsightAiLoadingStateContainer>
              <AiIcon hasGradient isAnimated />
              <AiIsWriting>Cycle AI is writing…</AiIsWriting>
            </InsightAiLoadingStateContainer>
          </InsightAiLoadingState>
        )}

        <EditorWrapper
          ref={el => {
            proseMirrorElement.current = el?.querySelector('.ProseMirror') ?? null;
          }}
          $isDisabled={isOffline}
          onClick={
        undefined
        // () => {
        /**
         * We deactivate this cause is cause some unwanted editor focus when
         * user clicks on some elements like modal to add file/images for
         * instance.
         */
        // Create a new paragraph when clicking on div elements below the editor
        // TODO: we should find a better way to do this
        // if (!(e.target instanceof HTMLElement) || e.target.tagName !== 'DIV') return;
        // ProseMirror handles clicks itself
        // if (proseMirrorElement.current?.contains(e.target)) return;
        // docEditor.current?.chain().focus('end').createParagraphNear().run();
        // }
      }
        >
          <DocEditor
            docId={doc.id}
            parentRef={containerRef}
            displayLoader={mode === 'edit-doc'}
            onEscape={onEscapeEdition}
            showAddTemplate
            showIntegrations
            onEditorReady={editor => {
              docEditor.current = editor;
              setEditor(editor);
            }}
          />
        </EditorWrapper>
      </MainContent>

      {scrollableWidth && <VerifyQuotes doc={doc} fullWidth={scrollableWidth < 800} />}
    </ScrollableContent>
  );
};
