import { DocBaseFragment } from '@cycle-app/graphql-codegen';
import { Skeleton } from '@cycle-app/ui';
import { nodeToArray } from '@cycle-app/utilities';
import keyBy from 'lodash/keyBy';
import { FC } from 'react';

import { DropdownLayerProps } from 'src/components/DropdownLayer/DropdownLayer';
import { InsightCreateForm } from 'src/components/InsightCreateForm';
import { useMe, useProduct } from 'src/hooks';
import { useDocCommonInsightProperties } from 'src/hooks/doc/useDocCommonProperties';
import { Layer } from 'src/types/layers.types';

import { Loader, StyledDropdownLayer } from './InsightCreateModal.styles';

export type InsightCreateModalProps = {
  blockId?: string;
  createFeedback?: boolean;
  defaultAssignee?: string;
  defaultContent?: string;
  defaultCover?: string;
  defaultCustomerId?: string;
  dropdownLayer?: Layer;
  dropdownProps?: Partial<DropdownLayerProps>;
  feedbackDoc?: DocBaseFragment;
  formLayer?: Layer;
  hide: VoidFunction;
  hideParentDoc?: boolean;
  hideTitleField?: boolean;
  inheritPropertiesFrom?: 'parent' | 'feedback';
  isAssigneeReadonly?: boolean;
  isCustomerReadonly?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  onBack?: VoidFunction;
  onInsightCreated?: (props: {
    parentDoc: DocBaseFragment | null | undefined;
  }) => void;
  onInsightCreating?: VoidFunction;
  parentDoc?: DocBaseFragment | null;
  withoutParent?: boolean;
  linearId?: string;
  linearUrl?: string;
  linearDisabled?: boolean;
};

export const InsightCreateModal: FC<React.PropsWithChildren<InsightCreateModalProps>> = ({
  blockId,
  children,
  createFeedback,
  defaultAssignee,
  defaultContent,
  defaultCover,
  defaultCustomerId,
  dropdownLayer = Layer.DropdownModalZ2,
  dropdownProps,
  feedbackDoc,
  formLayer,
  hide,
  hideParentDoc,
  hideTitleField,
  inheritPropertiesFrom = 'feedback',
  isAssigneeReadonly,
  isCustomerReadonly,
  isLoading,
  isOpen,
  onInsightCreated,
  onInsightCreating,
  parentDoc,
  withoutParent,
  linearId,
  linearUrl,
  linearDisabled,
}) => {
  const { me } = useMe();

  const attributes = inheritPropertiesFrom === 'parent' ? parentDoc?.attributes : feedbackDoc?.attributes;
  const customPropertiesData = useDocCommonInsightProperties(attributes || null);
  const { product } = useProduct();
  const customAttributesDefinitions = Object.keys(keyBy(nodeToArray(product?.companyAttributeDefinitions), 'id'));

  return (
    <StyledDropdownLayer
      visible={isOpen}
      layer={dropdownLayer}
      hide={hide}
      placement="bottom-end"
      content={isOpen && (isLoading ? (
        <Loader>
          <Skeleton height={24} />
          <Skeleton height={24} />
        </Loader>
      ) : (
        <InsightCreateForm
          hide={hide}
          onInsightCreated={() => {
            onInsightCreated?.({ parentDoc });
            hide();
          }}
          onInsightCreating={onInsightCreating}
          parentDoc={parentDoc}
          withoutParent={withoutParent}
          feedbackDoc={feedbackDoc}
          customCommonPropertiesData={customPropertiesData.common}
          customSpecificPropertiesData={customPropertiesData.specific.filter(x => !customAttributesDefinitions.includes(x.id))}
          defaultAssignee={feedbackDoc?.assignee?.id || defaultAssignee || me.id}
          defaultContent={defaultContent}
          defaultCover={defaultCover}
          defaultCustomerId={feedbackDoc?.customer?.id || defaultCustomerId}
          blockId={blockId}
          hideParentDoc={hideParentDoc}
          isAssigneeReadonly={isAssigneeReadonly}
          isCustomerReadonly={isCustomerReadonly}
          hideTitleField={hideTitleField}
          createFeedback={createFeedback}
          layer={formLayer}
          linearId={linearId}
          linearUrl={linearUrl}
          linearDisabled={linearDisabled}
        />
      ))}
      {...dropdownProps}
      popperOptions={{
        ...(dropdownProps?.popperOptions || {}),
        modifiers: [...(dropdownProps?.popperOptions?.modifiers || []), {
          name: 'flip',
          enabled: false,
        }, {
          name: 'offsetAdapter',
          enabled: true,
          phase: 'read',
          requires: ['popperOffsets'],
          options: {
            offset: 24,
          },
          fn: ({
            state, options,
          }) => {
            const { rects } = state;
            const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
            const overflowY = options.offset + rects.reference.y + rects.reference.height + rects.popper.height - viewportHeight;
            if (overflowY > 0 && state.modifiersData?.popperOffsets?.y) {
              // eslint-disable-next-line operator-assignment, no-param-reassign
              state.modifiersData.popperOffsets.y = Math.max(options.offset, state.modifiersData.popperOffsets.y - overflowY);
            }
            if (viewportHeight <= rects.popper.height) {
              state.elements.popper.setAttribute('data-scroll', 'true');
            }
          },
        }],
      }}
    >
      {children}
    </StyledDropdownLayer>
  );
};
