import {
  PropertyFragment,
  Operator,
  FilterValueInput,
} from '@cycle-app/graphql-codegen';
import { Avatar, SelectOption, Spinner, OnSelectOptionChange, ActionButton } from '@cycle-app/ui';
import { CloseIcon } from '@cycle-app/ui/icons';
import React, {
  FC, ReactNode, useCallback, useMemo, useState, useEffect,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { FilterRule, isFilterPropertyRuleFragment } from 'src/types/filters.types';
import { Layer } from 'src/types/layers.types';
import { FILTER_RULE_ICON_MAPPING, getFilterAttributeOption, getFilterRuleName } from 'src/utils/boardConfig/boardConfig.util';
import {
  isLeafOperator,
  selectableUserToOption,
  DateFilterData,
  MultiSelectFilterData,
  NumberFilterData,
  TextFilterData,
  UserFilterData,
  DocParentFilterData,
  GetFilterMultiValuesInput,
  getFilterData,
  isBooleanFilterData,
  isTextValueFilterData,
  isNumberValueFilterData,
  isDateValueFilterData,
  isMultiValuesFilterData,
  isUsersFilterData,
  isDocParentFilterData,
  isCustomerFilterData,
  CustomerFilterData,
  CompanyFilterData,
  isCompanyFilterData,
  StatusFilterData,
  isStatusFilterData,
  selectableStatusToOption,
  isAiStateFilterData,
} from 'src/utils/boardConfig/filtersData.util';
import { Truncate } from 'src/utils/css.styles';

import {
  Attribute,
  Container,
  FakeInput,
  FilterInput,
  Placeholder,
  User,
  DeleteBtnContainer,
} from './Filter.styles';
import CompanyFilterElements from './FilterElement/CompanyFilterElements/CompanyFilterElements';
import CustomerFilterElements from './FilterElement/CustomerFilterElements/CustomerFilterElements';
import DocParentFilterElements from './FilterElement/DocParentFilterElements/DocParentFilterElements';
import MultiSelectFilterElement from './FilterElement/MultiSelectFilterElement';
import SingleSelectFilterElement from './FilterElement/SingleSelectFilterElement';

const INPUT_DEBOUNCE = 1000;

interface Props {
  className?: string;
  filter?: FilterRule;
  dropdownLayer?: Layer;
  filterableProperties: Array<PropertyFragment>;
  loadingChangeOperator?: boolean;
  loadingChangeProperty?: boolean;
  onAttributeUpdated: (selectedOption: SelectOption) => void;
  onValueAdded?: (filterValueInput: FilterValueInput) => void;
  onValueRemoved?: (valueId: string) => void;
  onDeleteFilter: VoidFunction;
  onOperatorUpdated?: (operator: Operator) => void;
  disabled?: boolean;
}

const Filter: FC<React.PropsWithChildren<Props>> = ({
  className,
  filter,
  dropdownLayer,
  filterableProperties,
  loadingChangeOperator,
  loadingChangeProperty,
  onAttributeUpdated,
  onOperatorUpdated,
  onValueAdded = () => ({}),
  onValueRemoved = () => ({}),
  onDeleteFilter,
  disabled = false,
}) => {
  const filterData = useMemo(() => getFilterData(filter), [filter]);
  const debouncedOnValueUpdated = useDebouncedCallback(onValueAdded, INPUT_DEBOUNCE);
  const [isLoading, setIsLoading] = useState(true);

  const onChangeOperator = useCallback<OnSelectOptionChange>(({ value }) => {
    setIsLoading(true);
    onOperatorUpdated?.(value as Operator);
  },
  [onOperatorUpdated]);

  useEffect(() => {
    if (!loadingChangeOperator) {
      setIsLoading(false);
    }
  }, [loadingChangeOperator]);

  const isDeletable = !disabled && filter && (!isFilterPropertyRuleFragment(filter) || filter.isDeletable);
  const isEditable = !disabled && filter && (!isFilterPropertyRuleFragment(filter) || filter.isEditable);
  const filterName = filter ? getFilterRuleName(filter) : null;

  return (
    <Container className={className}>
      <FakeInput>
        <SingleSelectFilterElement
          dropdownLayer={dropdownLayer}
          value={filter?.id}
          options={filterableProperties.map(getFilterAttributeOption)}
          onChange={onAttributeUpdated}
          loading={loadingChangeProperty}
          disabled={!isDeletable}
          style={{ flexShrink: filterName && filterName.length < 15 ? 0 : 1 }}
        >
          {filter
            ? (
              <Attribute>
                {FILTER_RULE_ICON_MAPPING[filter.__typename]}
                <Truncate>{filterName}</Truncate>
              </Attribute>
            ) : (
              <Placeholder>Select attribute</Placeholder>
            )}
        </SingleSelectFilterElement>

        {filterData && (
          <>
            {isBooleanFilterData(filterData) && renderOperator(filterData.checkboxOperator, filterData.operatorOptions)}
            {isTextValueFilterData(filterData) && renderTextValueFilterOperatorAndValue(filterData)}
            {isNumberValueFilterData(filterData) && renderNumberValueFilterOperatorAndValue(filterData)}
            {isDateValueFilterData(filterData) && renderDateValueFilterOperatorAndValue(filterData)}
            {isMultiValuesFilterData(filterData) && renderMultipleValuesFilterOperatorAndValue(filterData)}
            {isUsersFilterData(filterData) && renderUsersFilterOperatorAndValue(filterData)}
            {isDocParentFilterData(filterData) && renderDocParentFilterOperatorAndValue(filterData)}
            {isCustomerFilterData(filterData) && renderCustomersFilterOperatorAndValue(filterData)}
            {isCompanyFilterData(filterData) && renderCompaniesFilterOperatorAndValue(filterData)}
            {isStatusFilterData(filterData) && renderStatusFilterOperatorAndValue(filterData)}
            {isAiStateFilterData(filterData) && renderOperator(filterData.aiStateOperator, filterData.operatorOptions)}
          </>
        )}

        {isDeletable && (
          <DeleteBtnContainer>
            <ActionButton
              tooltip="Remove filter"
              tooltipPlacement="top"
              onClick={onDeleteFilter}
            >
              <CloseIcon />
            </ActionButton>
          </DeleteBtnContainer>
        )}
      </FakeInput>
    </Container>
  );

  function renderTextValueFilterOperatorAndValue(params: TextFilterData): ReactNode {
    const {
      textFilterValue,
      operator,
      operatorOptions,
      getFilterValueInput,
    } = params;

    if (!operator) {
      return null;
    }

    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <FilterInput
            key={operator}
            defaultValue={textFilterValue?.value}
            onChange={e => {
              const input = getFilterValueInput(e.target.value, operator);
              debouncedOnValueUpdated(input);
            }}
            placeholder="Value"
            disabled={!isEditable}
          />
        )}
      </>
    );
  }

  function renderNumberValueFilterOperatorAndValue(params: NumberFilterData): ReactNode {
    const {
      numberFilterValue,
      operator,
      operatorOptions,
      getFilterValueInput,
    } = params;

    if (!operator) {
      return null;
    }

    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <FilterInput
            key={operator}
            defaultValue={numberFilterValue?.value}
            type="number"
            onChange={e => {
              const input = getFilterValueInput(parseInt(e.target.value, 10), operator);
              debouncedOnValueUpdated(input);
            }}
            placeholder="Value"
            disabled={!isEditable}
          />
        )}
      </>
    );
  }

  function renderDateValueFilterOperatorAndValue(params: DateFilterData): ReactNode {
    const { operator } = params;

    if (!operator) {
      return null;
    }

    return (
      <FilterInput
        key={operator}
        placeholder="Coming soon"
        disabled
      />
    );
  }

  function renderMultipleValuesFilterOperatorAndValue(params: MultiSelectFilterData) {
    const {
      operator,
      operatorOptions,
      textOptions,
      getFilterValueInput,
    } = params;

    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <MultiSelectFilterElement
            dropdownLayer={dropdownLayer}
            panelTitle="Selected values"
            placeholder="Select values"
            options={textOptions}
            onOptionsAdded={onMultiSelectValueAdded(getFilterValueInput, operator)}
            onOptionRemoved={onValueRemoved}
            disabled={!isEditable}
          />
        )}
      </>
    );
  }

  function renderCustomersFilterOperatorAndValue(params: CustomerFilterData) {
    const {
      operator,
      operatorOptions,
      customerOptions,
      selectedCustomers,
      getFilterValueInput,
    } = params;

    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <CustomerFilterElements
            filterPropertyRuleId={filter?.id}
            dropdownLayer={dropdownLayer}
            panelTitle="Selected customers"
            placeholder="Select customers"
            options={customerOptions}
            selectedOptions={selectedCustomers}
            onOptionsAdded={onMultiSelectValueAdded(getFilterValueInput, operator)}
            onOptionRemoved={onValueRemoved}
            disabled={!isEditable}
          />
        )}
      </>
    );
  }

  function renderCompaniesFilterOperatorAndValue(params: CompanyFilterData) {
    const {
      operator,
      operatorOptions,
      companyOptions,
      selectedCompanies,
      getFilterValueInput,
    } = params;

    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <CompanyFilterElements
            filterPropertyRuleId={filter?.id}
            dropdownLayer={dropdownLayer}
            panelTitle="Selected companies"
            placeholder="Select companies"
            options={companyOptions}
            selectedOptions={selectedCompanies}
            onOptionsAdded={onMultiSelectValueAdded(getFilterValueInput, operator)}
            onOptionRemoved={onValueRemoved}
            disabled={!isEditable}
          />
        )}
      </>
    );
  }

  function renderStatusFilterOperatorAndValue(params: StatusFilterData) {
    const {
      operator,
      operatorOptions,
      statusOptions,
      getFilterValueInput,
    } = params;
    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <MultiSelectFilterElement
            dropdownLayer={dropdownLayer}
            panelTitle="Selected statuses"
            placeholder="Select statuses"
            options={statusOptions.map(selectableStatusToOption)}
            onOptionsAdded={onMultiSelectValueAdded(getFilterValueInput, operator)}
            onOptionRemoved={onValueRemoved}
            disabled={!isEditable}
          />
        )}
      </>
    );
  }

  function renderUsersFilterOperatorAndValue(params: UserFilterData): ReactNode {
    const {
      operator,
      operatorOptions,
      userOptions,
      getFilterValueInput,
    } = params;
    const firstSelectedUser = userOptions.find(u => u.selected)?.value;
    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <MultiSelectFilterElement
            dropdownLayer={dropdownLayer}
            panelTitle="Selected users"
            placeholder="Select users"
            options={userOptions.map(selectableUserToOption)}
            onOptionsAdded={onMultiSelectValueAdded(getFilterValueInput, operator)}
            onOptionRemoved={onValueRemoved}
            disabled={!isEditable}
          >
            {firstSelectedUser && (
              <User>
                <Avatar size={16} user={firstSelectedUser} />
                {firstSelectedUser.firstName}
              </User>
            )}
          </MultiSelectFilterElement>
        )}
      </>
    );
  }

  function renderDocParentFilterOperatorAndValue(params: DocParentFilterData): ReactNode {
    const {
      operator,
      operatorOptions,
      selectedParents,
      parentOptions,
      getFilterValueInput,
    } = params;

    return (
      <>
        {operator && renderOperator(operator, operatorOptions)}
        {operator && !isLeafOperator(operator) && (
          <DocParentFilterElements
            dropdownLayer={dropdownLayer}
            filterPropertyRuleId={filter?.id}
            selectedParents={selectedParents}
            defaultDocParentOptions={parentOptions}
            onOptionsAdded={onMultiSelectValueAdded(getFilterValueInput, operator)}
            onOptionRemoved={onValueRemoved}
            disabled={!isEditable}
          />
        )}
      </>
    );
  }

  function renderOperator(operator: string | undefined, operatorOptions: SelectOption[]): ReactNode {
    return (
      <SingleSelectFilterElement
        dropdownLayer={dropdownLayer}
        value={operator}
        options={operatorOptions}
        onChange={onChangeOperator}
        disabled={!isEditable}
      >
        {isLoading
          ? <Spinner />
          : operatorOptions.find(o => o.value === operator)?.label}
      </SingleSelectFilterElement>
    );
  }

  function onMultiSelectValueAdded(getFilterValueInput: GetFilterMultiValuesInput, operator: string) {
    return (newOptions: SelectOption[]) => onValueAdded(
      getFilterValueInput(newOptions.map(o => o.value), operator),
    );
  }
};

export default Filter;
