import { CompanyLogo, Badge } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { FC } from 'react';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useCompany } from 'src/hooks/api/queries/customers/useCompany';
import { openCustomerModal } from 'src/hooks/modals/useCustomerModal';

import useOptimizedBooleanState from '../../hooks/useOptimizedBooleanState';
import { Layer } from '../../types/layers.types';
import { CompanyAttributes } from '../CompanyAttributes';
import { CustomersAddCustomerModal } from '../CustomersList/CustomersAddCustomerModal';
import { CompanyAvatarEditable } from './CompanyAvatarEditable';
import { CompanyDetails } from './CompanyDetails';
import {
  StyledCustomersListPeople, AddPeopleContainer, AddPeopleAction, AddPeopleLabel, PeopleList, ProfileWrapper,
} from './CompanyProfile.styles';
import { CompanyProfileHeader } from './CompanyProfileHeader';
import { HeaderContainer } from './CustomerProfile.styles';

interface Props {
  companyId: string;
  className?: string;
  hideShareLink?: boolean;
  isHeaderCentered?: boolean;
  shyScrollDisabled?: boolean;
  scrollableElement?: HTMLElement | null;
}

export const CompanyProfile: FC<React.PropsWithChildren<Props>> = ({
  className,
  companyId,
  hideShareLink,
  isHeaderCentered,
  scrollableElement,
  shyScrollDisabled,
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const {
    company, customers, isLoading, loadMoreCustomers, pageInfo, customersCount,
  } = useCompany(companyId);
  const [isCreateCustomerOpend, { toggleCallback: toggleCreateCustomer }] = useOptimizedBooleanState(false);

  return (
    <ProfileWrapper>
      <HeaderContainer className={className} $isCentered={isHeaderCentered}>
        {company?.isDefault
          ? <CompanyLogo company={company} />
          : <CompanyAvatarEditable company={company} />}
        {company && <CompanyProfileHeader company={company} />}
        {company && <CompanyDetails company={company} hideShareLink={hideShareLink} />}
        <CompanyAttributes companyId={companyId} />
      </HeaderContainer>
      <PeopleList>
        <AddPeopleContainer>
          <AddPeopleLabel>
            People
            <Badge>
              {customersCount}
            </Badge>
          </AddPeopleLabel>
          <AddPeopleAction tooltip="Add someone" tooltipPlacement="top" onClick={() => toggleCreateCustomer()}>
            <AddIcon />
          </AddPeopleAction>
        </AddPeopleContainer>
      </PeopleList>

      <StyledCustomersListPeople
        isLoading={isLoading}
        hiddenData={{
          companies: true,
          header: true,
        }}
        loadMore={loadMoreCustomers}
        hasNextPage={pageInfo?.hasNextPage}
        customers={customers ?? []}
        searchText=""
        $hideSeparator
        onCustomerClick={openCustomerModal}
        scrollableElement={scrollableElement}
        shyScrollDisabled={shyScrollDisabled}
        nameCellSize={300}
        emailCellSize={200}
        actionsCellSize={50}
        isFullPage={false}
      />
      {isCreateCustomerOpend && company && (
        <CustomersAddCustomerModal
          productId={productId}
          layer={Layer.ModalZ2}
          defaultCompany={company}
          onClose={() => toggleCreateCustomer()}
        />
      )}
    </ProfileWrapper>
  );
};
