import { ActionButton } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';
import { FC, ReactNode } from 'react';

import { copyToClipboard } from 'src/utils/clipboard.utils';

interface Props {
  tooltip?: ReactNode;
  className?: string;
  url: string;
}

export const CustomerShareButton: FC<React.PropsWithChildren<Props>> = ({
  className, children, tooltip, url,
}) => {
  return (
    <ActionButton
      className={className}
      tooltipPlacement="top"
      tooltip={tooltip}
      onClick={() => copyToClipboard({
        text: [window.location.origin, url].join(''),
        notification: 'Link successfully copied to clipboard',
      })}
      size="L"
    >
      <LinkIcon />
      {children}
    </ActionButton>
  );
};
