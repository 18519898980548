import { Color, Feature, ReleasePublicStatus } from '@cycle-app/graphql-codegen';
import { ActionButton, Flex, Button, Tag, InfiniteScroll } from '@cycle-app/ui';
import { ReleaseIcon, CloseIcon, CursorIcon, ReleaseNoteIcon, LinkArrowIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { useTheme } from 'styled-components';

import releasesPreview from 'src/assets/release-preview.png';
import { FeatureEmptyState } from 'src/components/FeatureEmptyState';
import { FeedbackLifecycle } from 'src/components/FeedbackLifecycle';
import { NewBadge } from 'src/components/NewBadge';
import { ReleaseCreateButton } from 'src/components/ReleaseCreateButton';
import { ReleaseEditModal } from 'src/components/ReleaseModals';
import { ReleasesEmpty } from 'src/components/ReleasesEmpty/';
import { SettingsViewHeader } from 'src/components/SettingsViewHeader';
import { HELP_URL_RELEASES } from 'src/constants/help.constants';
import { PageId } from 'src/constants/routing.constant';
import { ReleasesProvider, useReleasesContext } from 'src/contexts/releasesContext';
import {
  useOptimizedBooleanState, useProductBase, useFeatureFlagUpdate, useFeatureFlagReload, useFeatureFlag, navigate,
} from 'src/hooks';
import { useIsReleasesEnabled } from 'src/hooks/releases/useIsReleasesEnabled';

import { Page } from '../SettingsWorkflows/SettingsWorkflows.styles';
import imageDark from './public-changelog-dark.png';
import imageLight from './public-changelog-light.png';
import { SettingsChangelog } from './SettingsChangelog';
import {
  PublicChangeLogContainer,
  Title, Description, Date,
  ReleasesListContainer,
  ReleaseItems, ReleaseItem,
  CloseButton,
  Modal, ModalTitle, ModalItem,
  Header,
  PublicChangelogImage,
  RequestButton,
  PublicChangelogTitle,
  PublicChangelogDescription,
} from './SettingsReleases.styles';

export const SettingsReleases = () => {
  const [isModalOpen, {
    setFalseCallback, setTrueCallback,
  }] = useOptimizedBooleanState(false);
  const isReleasesEnabled = useIsReleasesEnabled();
  const isChangelogEnabled = useFeatureFlag('changelog').isEnabled;
  const product = useProductBase();
  const { isDark } = useTheme();
  if (!product) return null;
  return (
    <Page>
      <Header>
        <SettingsViewHeader
          title="Releases"
          description="Communicate your releases"
          helpUrl={HELP_URL_RELEASES}
        />

        <FeedbackLifecycle step="release" />
      </Header>

      {isReleasesEnabled
        ? (
          <>
            {isChangelogEnabled ? (
              <SettingsChangelog />
            ) : (
              <PublicChangeLogContainer>
                <PublicChangelogImage src={isDark ? imageDark : imageLight} />
                <PublicChangelogTitle>
                  <Title>Public changelog</Title>
                  <div>
                    <NewBadge size="M" />
                  </div>
                </PublicChangelogTitle>
                <PublicChangelogDescription>
                  Create a public changelog and update it with the latest features.
                  <br />
                  Fetch the release notes, preview, and publish. It&apos;s that easy.
                </PublicChangelogDescription>
                <RequestButton onClick={() => window.open('https://tally.so/r/w8qo7r', '_blank')}>
                  Request access
                </RequestButton>
              </PublicChangeLogContainer>
            )}
            <ReleasesProvider productId={product.id}>
              <ReleasesList />
            </ReleasesProvider>
          </>
        )
        : (
          <>
            <FeatureEmptyState
              onClick={setTrueCallback}
              src={releasesPreview}
              title="Releases"
            // eslint-disable-next-line max-len
              description="Enable Releases to manage your and communicate your releases, write your release notes, and publish a beautiful changelog."
            />
            <EnableReleaseModal isOpen={isModalOpen} hide={setFalseCallback} />
          </>
        )}
    </Page>
  );
};

const ReleasesList = () => {
  const releasesCount = useReleasesContext(ctx => ctx.releasesList.length);
  const releasesList = useReleasesContext(ctx => ctx.releasesList);
  const hasNextPage = useReleasesContext(ctx => ctx.hasNextPage);
  const loadMore = useReleasesContext(ctx => ctx.loadMore);
  const isLoadingMore = useReleasesContext(ctx => ctx.isLoadingMore);
  const hasReleases = !!releasesCount;
  return (
    <>
      <ReleasesListContainer>
        {hasReleases && (
          <Flex $justify="space-between">
            <Title>Releases</Title>
            <ReleaseCreateButton secondary label="Add" />
          </Flex>
        )}
        <InfiniteScroll
          hasMoreData={hasNextPage}
          loadMore={loadMore}
          isLoading={isLoadingMore}
        >
          <ReleaseItems $hasMinDimensions={!hasReleases}>
            {hasReleases ? releasesList.filter(release => !!release.date).map(release => (
              <ReleaseItem key={release.id}>
                <Flex $gap={8} $align="flex-start">
                  <Date>
                    {toShortLocaleDateString(release.date)}
                  </Date>
                  {!!release.title && (
                    <>
                      <Description>·</Description>
                      <Description>{release.title}</Description>
                    </>
                  )}
                </Flex>
                <Flex $gap={8}>
                  {release.publicStatus === ReleasePublicStatus.Published && (
                    <Tag color={Color.C}>
                      Published
                    </Tag>
                  )}
                  <ActionButton
                    onClick={() => {
                      navigate(PageId.Release, { releaseId: release.id });
                    }}
                  >
                    <LinkArrowIcon size={16} />
                  </ActionButton>
                </Flex>
              </ReleaseItem>
            )) : <ReleasesEmpty fromSettings />}
          </ReleaseItems>
        </InfiniteScroll>
      </ReleasesListContainer>

      <ReleaseEditModal />
    </>
  );
};

type EnableReleaseModalProps = {
  isOpen: boolean;
  hide: VoidFunction;
};

const EnableReleaseModal = ({
  isOpen, hide,
}:EnableReleaseModalProps) => {
  const {
    activateFeature, loading,
  } = useFeatureFlagUpdate();
  const reloadFlags = useFeatureFlagReload();
  if (!isOpen) return null;
  return (
    <Modal hide={hide}>
      <CloseButton onClick={hide}>
        <CloseIcon />
      </CloseButton>
      <ModalTitle>
        Releases
      </ModalTitle>
      <ModalItem>
        <div>
          <ReleaseIcon />
        </div>
        <div>
          <h5>Manage your releases</h5>
          <p>Organize your features into releases to communicate progress</p>
        </div>
      </ModalItem>
      <ModalItem>
        <div>
          <ReleaseNoteIcon />
        </div>
        <div>
          <h5>Write your release notes</h5>
          <p>For each new feature, write a release note that explains its value.</p>
        </div>
      </ModalItem>
      <ModalItem>
        <div>
          <CursorIcon />
        </div>
        <div>
          <h5>Publish your changelog</h5>
          <p>Publish a beautiful changelog to show off your shipping velocity.</p>
        </div>
      </ModalItem>
      <Button
        size="L"
        style={{
          width: '100%',
          marginTop: '20px',
          maxHeight: '32px',
        }}
        onClick={async () => {
          const featureFlagUpdated = await activateFeature(Feature.Release);
          if (featureFlagUpdated) {
            reloadFlags();
            navigate(PageId.Releases);
          }
        }}
        isLoading={loading}
      >
        Enable
      </Button>
    </Modal>
  );
};
