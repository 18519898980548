import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
