import { FC } from 'react';

import { Svg } from '../../components/Svg/Svg.styles';
import { Props } from '../../types/icon.types';

export const ShortcutAppIcon: FC<React.PropsWithChildren<Props>> = ({ className = '' }) => (
  <Svg className={className} width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill>
    <path d="M24.4621 6.1156C24.4621 2.73816 21.7243 5.64248e-06 18.3468 5.64248e-06H6.11618C5.31295 -0.00108717 4.51741 0.156568 3.77529 0.463916C3.03317 0.77127 2.3591 1.22225 1.79181 1.79096C-0.5962 4.17937 -0.596199 8.0528 1.79181 10.44L3.96971 12.618C1.65075 13.4873 0 15.724 0 18.3466C0 21.724 2.73797 24.4622 6.11541 24.4622H18.3468C19.15 24.4632 19.9455 24.3055 20.6876 23.9981C21.4297 23.6907 22.1037 23.2397 22.6709 22.6709C25.0592 20.2825 25.0592 16.4091 22.6709 14.0219L20.493 11.8439C22.8115 10.9748 24.4621 8.73792 24.4621 6.1156ZM3.46954 20.9936C2.9557 20.4796 2.60264 19.827 2.45346 19.1157C2.30427 18.4043 2.36543 17.6649 2.62946 16.9877C2.89348 16.3106 3.34896 15.7249 3.94027 15.3024C4.53158 14.8797 5.2331 14.6385 5.95927 14.608L13.4408 22.0903H6.11519C5.6236 22.0914 5.1367 21.9951 4.68256 21.8069C4.22845 21.6186 3.81616 21.3422 3.46954 20.9936ZM20.9932 15.7005C21.6951 16.4024 22.0894 17.3543 22.0894 18.347C22.0894 19.3397 21.6951 20.2917 20.9932 20.9936C20.2913 21.6955 19.3394 22.0899 18.3468 22.0899C17.3542 22.0899 16.4023 21.6955 15.7004 20.9936L3.46954 8.76219C2.772 8.0593 2.3814 7.10868 2.38325 6.11838C2.38513 5.12814 2.77931 4.17896 3.47949 3.47874C4.17967 2.77851 5.12877 2.3843 6.11896 2.38243C7.10915 2.38058 8.05977 2.7712 8.76255 3.46879L20.9932 15.7005ZM20.9932 8.76219C20.3311 9.42763 19.4417 9.81785 18.5037 9.85441L11.0219 2.37259H18.3476C19.0877 2.37279 19.8112 2.59245 20.4265 3.0038C21.0418 3.41515 21.5214 3.99972 21.8045 4.6836C22.0877 5.36746 22.1618 6.11997 22.0174 6.84591C21.8729 7.57191 21.5165 8.23873 20.9932 8.76219Z" fill="#58B1E4" />
  </Svg>
);
