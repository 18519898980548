import { StatusFragment, StatusCategory, StatusType, DoctypeFragment } from '@cycle-app/graphql-codegen';

import { isInsight, isParentOfInsight } from 'src/utils/docType.util';

export const getIsUnlinkable = (status: StatusFragment, docType: DoctypeFragment) => {
  // LoopClosed can't be unlinked
  if (status.type === StatusType.LoopClosed) return false;

  // No status can be unlinked from insight
  if (isInsight(docType)) return false;

  // The last status of a doc type can't be unlinked
  const linkedStatusCount = docType.status.edges.filter(e => e.node.category === status.category).length;
  const isLastStatus = isParentOfInsight(docType) && status.category === StatusCategory.Completed
    ? linkedStatusCount < 3
    : linkedStatusCount < 2;

  return !isLastStatus;
};
