import { round } from 'lodash';
import { RefCallback, useCallback, useEffect, useRef } from 'react';
import { ColorPicker, ColorService } from 'react-color-palette';
import 'react-color-palette/css';

import { AddIcon } from '../../icons';
import { Button } from '../Buttons';
import { Root, SavedColorButton, SaveList, SaveTitle } from './ColorPalettePicker.styles';

type Props = {
  color: string;
  onChange: (color: string) => void;
  hideSavedColors?: boolean;
  savedColors?: string[];
  onAddColor?: (color: string) => void;
};

export const ColorPalettePicker = ({
  color, onChange, hideSavedColors, savedColors, onAddColor,
}: Props) => {
  const {
    rgb, hsv,
  } = ColorService.convert('hex', color);
  const alphaCursor = useRef<HTMLDivElement | null>(null);
  const setRef: RefCallback<HTMLDivElement> = useCallback((node) => {
    const el = node?.querySelector<HTMLDivElement>('.rcp-alpha-cursor');
    if (el) {
      alphaCursor.current = el;
    }
  }, []);
  const { a } = hsv;

  useEffect(() => {
    alphaCursor.current?.setAttribute('data-alpha', `${round(a * 100)}%`);
  }, [a]);

  return (
    <Root ref={setRef}>
      <ColorPicker
        color={{
          hex: color,
          rgb,
          hsv,
        }}
        height={152}
        hideInput={['rgb', 'hsv', 'hex']}
        onChange={value => onChange(value.hex)}
      />
      {!hideSavedColors && (
        <div>
          <SaveTitle>
            {savedColors?.length ? 'Saved colors:' : 'No saved colors'}
            <Button
              iconStart={<AddIcon />}
              variant="nospace"
              onClick={() => onAddColor?.(color)}
            >
              Add
            </Button>
          </SaveTitle>
          {!!savedColors?.length && (
            <SaveList>
              {savedColors.map(savedColor => (
                <li key={savedColor}>
                  <SavedColorButton
                    $isActive={savedColor === color}
                    type="button"
                    style={{
                      backgroundColor: savedColor,
                    }}
                    onClick={() => onChange(savedColor)}
                  >
                    {savedColor}
                  </SavedColorButton>
                </li>
              ))}
            </SaveList>
          )}
        </div>
      )}
    </Root>
  );
};
