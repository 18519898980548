import { Button, TextSecondary, Flex } from '@cycle-app/ui';

import { useProductAddOn } from 'src/hooks';
import { useProductAddOnRequest } from 'src/hooks/useProductAddOnRequest';

import { MeetingButtonInstall } from '../MeetingButtonInstall';
import classes from './HomeViewMeetings.module.css';

export const HomeViewMeetingsEmpty = () => {
  const meetingsAddon = useProductAddOn('MEETINGS');
  const {
    request, isRequesting,
  } = useProductAddOnRequest();
  return (
    <div>
      {
        !meetingsAddon.isEnabled && (
          <Button
            isLoading={isRequesting}
            full
            size="S"
            onClick={() => request('MEETINGS')}
          >
            Install
          </Button>
        )
      }
      {
        meetingsAddon.isEnabled && (
          <Flex
            $align="center"
            $gap={16}
          >
            <TextSecondary
              $typo="caption"
              className={classes.info}
            >
              Enable to view your meetings
            </TextSecondary>
            <MeetingButtonInstall size="S" />
          </Flex>
        )
      }
    </div>
  );
};
