import { Role } from '@cycle-app/graphql-codegen';
import { InfiniteScroll } from '@cycle-app/ui';
import { WheelIcon, AddIcon } from '@cycle-app/ui/icons';
import { useListNav } from '@cycle-app/utilities';
import { FC, useMemo } from 'react';

import { PageId } from 'src/constants/routing.constant';
import { shortcuts } from 'src/constants/shortcuts.constants';
import { useMaybeMeV2, useProductAddOn } from 'src/hooks';
import { useProductBase } from 'src/hooks/api/useProduct';
import { useProducts } from 'src/hooks/api/useProducts';
import { useNavigate } from 'src/hooks/useNavigate';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import { useUrl } from 'src/hooks/useUrl';
import { setLimitationsModal, useGetPermission } from 'src/reactives';
import { setSettingsFromUrl } from 'src/reactives/lastView.reactive';
import { useIsMobile } from 'src/reactives/responsive.reactive';
import { useGetSidebarWidth } from 'src/reactives/sidebar.reactive';
import { Shortcut } from 'src/types/shortcuts.types';

import { openCommandBar } from '../../hooks/modals/useCommandBarModal';
import ProductItem from './ProductItem/ProductItem';
import {
  Container,
  Footer,
  ProductMenuShortcut,
  MenuItem,
  SettingsLink,
  List,
} from './ProductsMenu.styles';

interface Props {
  hide: () => void;
}

const ProductsMenu: FC<React.PropsWithChildren<Props>> = ({ hide }) => {
  const { me } = useMaybeMeV2();
  const { navigate } = useNavigate();
  const { navigate: navigateToSettings } = useNavigateToSettings();
  const getUrl = useUrl();
  const currentProduct = useProductBase();
  const {
    products, loading, hasNextPage, fetchNextPage,
  } = useProducts();
  const { width: sidebarWidth } = useGetSidebarWidth();
  const isMobile = useIsMobile();

  const optionsValues = useMemo(() => [
    ...products.map(product => product.slug),
    'settings',
    'add-new-workspace',
  ], [products]);

  const {
    listProps,
    itemProps,
    selected,
  } = useListNav({
    optionsValues,
    onSelect: onItemSelected,
  });

  const {
    isPermissionInit, canReadSettings,
  } = useGetPermission();
  const unlimitedWorkspacesAddon = useProductAddOn('unlimited-workspaces');

  const handleCreateWorkspace = () => {
    if (!unlimitedWorkspacesAddon.isEnabled && me?.role !== Role.SuperAdmin) {
      setLimitationsModal({
        action: 'USE_ADD_ON',
        brand: 'unlimited-workspaces',
      });
      return;
    }

    openCommandBar('create-workspace');
  };

  return (
    <Container $width={sidebarWidth + 10} {...listProps}>
      <List>
        <InfiniteScroll
          isLoading={loading}
          hasMoreData={hasNextPage}
          loadMore={fetchNextPage}
        >
          {products.map(product => (
            <ProductItem
              key={product.id}
              product={product}
              selected={product.slug === selected}
              isActive={product.id === currentProduct?.id}
              {...itemProps(product.slug)}
            />
          ))}
        </InfiniteScroll>
      </List>

      <Footer>
        {!isMobile && isPermissionInit && (
          <SettingsLink
            to={getUrl(PageId.Settings)}
            {...itemProps('settings')}
            $isSelected={selected === 'settings'}
            onClick={e => {
              if (!canReadSettings) {
                e.preventDefault();
                setLimitationsModal({ action: 'SETTINGS_READ' });
                hide();
                return;
              }
              setSettingsFromUrl();
            }}
          >
            <WheelIcon />
            <span>Workspace settings</span>
            {canReadSettings && <ProductMenuShortcut keys={shortcuts[Shortcut.GoToSettings]} />}
          </SettingsLink>
        )}

        <MenuItem
          {...itemProps('add-new-workspace')}
          $isSelected={selected === 'add-new-workspace'}
          onClick={() => {
            hide();
            handleCreateWorkspace();
          }}
        >
          <AddIcon style={{ height: 12 }} />
          Add new workspace
        </MenuItem>
      </Footer>
    </Container>
  );

  function onItemSelected(itemId: string | null) {
    if (!itemId) return;

    hide();

    if (itemId === 'settings') {
      navigateToSettings(PageId.Settings);
      return;
    }

    if (itemId === 'add-new-workspace') {
      handleCreateWorkspace();
      return;
    }

    navigate(PageId.Main, { productSlug: itemId });
  }
};

export default ProductsMenu;
