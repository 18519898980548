import { PenFilledIcon } from '@cycle-app/ui/icons';
import { useState } from 'react';

import { Name, EditButton, AddButton } from './CompanyDomain.styles';
import { CompanyDomainForm } from './CompanyDomainForm';

export const CompanyDomain = ({
  companyId, name, domain, logo,
}: {
  companyId: string;
  name?: string | null;
  domain?: string | null;
  logo?: string | null;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <div>
      {!isEditing && !!domain && (
        <Name>
          {domain}
          <EditButton
            tooltip="Edit domain"
            tooltipPlacement="top"
            onClick={() => setIsEditing(true)}
          >
            <PenFilledIcon size={14} />
          </EditButton>
        </Name>
      )}

      {!isEditing && !domain && (
        <AddButton
          iconEnd={<PenFilledIcon size={18} />}
          tooltip="Adding a domain URL will automatically fetch the company logo"
          tooltipPlacement="top"
          onClick={() => setIsEditing(true)}
        >
          Add domain
        </AddButton>
      )}

      {isEditing && (
        <CompanyDomainForm
          companyId={companyId}
          name={name ?? ''}
          domain={domain}
          logo={logo}
          hide={() => setIsEditing(false)}
        />
      )}
    </div>
  );
};
