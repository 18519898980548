import { Tag } from '@cycle-app/ui';
import { ArrowRightUpIcon } from '@cycle-app/ui/icons';
import { getDocSlug } from '@cycle-app/utilities';

import { useNavigate } from 'src/hooks';
import { useRelease } from 'src/hooks/releases/useRelease';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { getDocKey } from 'src/utils/doc.util';

import { Content } from './ReleaseDocTag.styles';

export const ReleaseDocTag = ({
  releaseId, noteId,
}: {
  releaseId: string;
  noteId: string;
}) => {
  const { navigateToReleaseDoc } = useNavigate();
  const { release } = useRelease(releaseId);
  const { releaseNote } = useReleaseNote(noteId);
  if (!release || !releaseNote?.doc) return null;
  const docKey = getDocKey(release.product.key, releaseNote?.doc?.publicId);
  const docSlug = getDocSlug(releaseNote.doc);
  return (
    <Tag
      color="grey"
      onClick={e => {
        navigateToReleaseDoc(releaseId, noteId, docSlug, {}, e.metaKey);
      }}
    >
      <Content>
        <ArrowRightUpIcon size={10} />
        {docKey}
      </Content>
    </Tag>
  );
};
