import { FC, ReactNode } from 'react';

import { ViewType } from '../../../../../graphql-codegen/generated';
import { DraggableCardProps } from '../DraggableCard/DraggableCard';
import {
  Cover,
  StyledDraggableCard,
  Title,
  Info,
  Toolbar,
  ToolbarSection,
  Actions,
  InfoContent,
} from './ViewCard.styles';

export interface ViewCardProps extends DraggableCardProps {
  title?: ReactNode;
  coverUrl?: string;
  preTitle?: ReactNode;
  leftTitle?: ReactNode;
  preToolbar?: ReactNode;
  sectionStart?: ReactNode;
  sectionEnd?: ReactNode;
  viewType: ViewType;
  actions?: ReactNode;
  showActions?: boolean;
  isHover?: boolean;
}

export const ViewCard: FC<React.PropsWithChildren<ViewCardProps>> = ({
  className,
  title,
  coverUrl,
  preTitle,
  leftTitle,
  preToolbar,
  sectionStart,
  sectionEnd,
  viewType,
  actions,
  showActions,
  isHover,
  children,
  ...draggableProps
}) => (
  <StyledDraggableCard
    className={className}
    isHover={isHover}
    viewType={viewType}
    {...draggableProps}
  >
    <Info>
      {viewType === ViewType.Kanban && coverUrl && (
        <Cover src={coverUrl} />
      )}

      {preTitle}

      <InfoContent viewType={viewType}>

        {leftTitle}

        <Title isEmpty={!title}>
          {title ?? 'Untitled'}
        </Title>

        {preToolbar}

        <Toolbar>
          {sectionStart && <ToolbarSection>{sectionStart}</ToolbarSection>}
          {sectionEnd && <ToolbarSection>{sectionEnd}</ToolbarSection>}
          {actions && (
            <Actions
              forceVisible={showActions}
              style={{
                position: preTitle || viewType === ViewType.Kanban ? 'absolute' : 'relative',
              }}
            >
              {actions}
            </Actions>
          )}
        </Toolbar>
      </InfoContent>
    </Info>

    {children}
  </StyledDraggableCard>
);
