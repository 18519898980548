import { useQuery } from '@apollo/client';
import { FilterPropertyRuleCustomerWithSearchDocument } from '@cycle-app/graphql-codegen';
import { SelectPanelProps, SelectPanel, SelectOption, TextHighlighter } from '@cycle-app/ui';
import { OpenIcon, AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray, isEmail } from '@cycle-app/utilities';
import { FC, useMemo, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { CustomersAddCustomerModal } from 'src/components/CustomersList/CustomersAddCustomerModal';
import { INPUT_ONCHANGE_DEBOUNCE } from 'src/constants/inputs.constant';
import { PageId } from 'src/constants/routing.constant';
import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import { useNavigateToSettings } from 'src/hooks/useNavigateToSettings';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { Layer } from 'src/types/layers.types';
import { CustomerFromFilter } from 'src/utils/boardConfig/filtersData.util';
import { getCustomerOption, renderCustomerOptionLabel } from 'src/utils/customers.util';

import { SelectedOptionTagContainer, SelectedOptionTag, SelectedOptionCloseIcon } from '../FilterElements.styles';
import { CreateCustomerLine, OpenCustomerButton } from './CustomerFilterElementsDropdownContent.styles';

interface Props extends Pick<SelectPanelProps, 'onSelectOption' | 'onUnselectOption'>{
  selectedCount: number;
  defaultOptions: CustomerFromFilter[];
  selectedOptions: CustomerFromFilter[];
  filterPropertyRuleId: string | undefined;
}

const CustomerFilterElementsDropdownContent: FC<React.PropsWithChildren<Props>> = ({
  selectedCount,
  defaultOptions,
  selectedOptions,
  filterPropertyRuleId,
  onSelectOption,
  onUnselectOption,
}) => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const { navigate } = useNavigateToSettings({ useLimitationModal: true });
  const {
    data, refetch,
  } = useQuery(FilterPropertyRuleCustomerWithSearchDocument, {
    fetchPolicy: 'cache-first',
    skip: !filterPropertyRuleId,
    variables: {
      id: filterPropertyRuleId ?? '',
      searchText: '',
    },
  });
  const [searchText, setSearchText] = useState('');
  const searchCustomersDebounced = useDebouncedCallback(refetch, INPUT_ONCHANGE_DEBOUNCE);
  const [isAddCustomerVisible, { toggleCallback: toggleAddCustomer }] = useOptimizedBooleanState(false);

  const displayedCustomerFilters: CustomerFromFilter[] = useMemo(() => {
    const optionsFromSearch =
      data?.node?.__typename === 'FilterPropertyRuleCustomer' &&
        data.node.rule.__typename === 'RuleCustomerMultipleValues'
        ? nodeToArray(data.node.rule.values)
        : undefined;

    return optionsFromSearch ?? defaultOptions;
  }, [data, defaultOptions]);

  const options: SelectOption[] = useMemo(() => displayedCustomerFilters.map(({ value: customer }) => ({
    ...getCustomerOption(customer),
    renderLabel: (filterText) => (
      renderCustomerOptionLabel({
        customer,
        filterText,
        lineHover: (
          <OpenCustomerButton
            size="S"
            onClick={e => {
              e.stopPropagation();
              navigate(PageId.SettingsCustomer, { customerId: customer.id });
            }}
          >
            <OpenIcon />
            Open
          </OpenCustomerButton>
        ),
      })
    ),
    selected: !!selectedOptions.find(s => s.value.id === customer.id),
    end: null,
  })), [displayedCustomerFilters, selectedOptions, navigate]);

  const onSearchChange = useCallback(async (search: string) => {
    if (!filterPropertyRuleId) return;
    setSearchText(search);
    await searchCustomersDebounced({
      searchText: search,
    });
  }, [filterPropertyRuleId, searchCustomersDebounced]);

  return (
    <>
      <SelectPanel
        isMulti
        selectedCount={selectedCount}
        options={options}
        onSelectOption={onSelectOption}
        onUnselectOption={onUnselectOption}
        onSearchChange={onSearchChange}
        filterOptionsOnInputChange={false}
        hideChecks
      >
        <CreateCustomerLine
          startSlot={<AddIcon />}
          label={(
            <>
              Create
              {' '}
              {searchText ? (
                <TextHighlighter
                  searchWords={[searchText]}
                  textToHighlight={searchText}
                  className="highlight"
                />
              ) : 'new customer'}
            </>
          )}
          onClick={toggleAddCustomer}
        />
        {!!selectedOptions.length && (
          <SelectedOptionTagContainer>
            {selectedOptions.map(({ value }) => (
              <SelectedOptionTag
                key={value.id}
                variant="ternary"
                size="S"
                onClick={() => onUnselectOption?.(getCustomerOption(value), options.findIndex(o => o.value === value.id))}
              >
                {value.name?.trim() || value.email}
                <SelectedOptionCloseIcon />
              </SelectedOptionTag>
            ))}
          </SelectedOptionTagContainer>
        )}
      </SelectPanel>
      {isAddCustomerVisible && (
        <CustomersAddCustomerModal
          productId={productId}
          layer={Layer.ModalZ2}
          onClose={toggleAddCustomer}
          defaultValues={{
            name: isEmail(searchText) ? '' : searchText,
            email: isEmail(searchText) ? searchText : '',
          }}
          onCreated={customer => {
            onSelectOption?.({
              value: customer.id,
              label: customer.displayName,
            }, options.length - 1);
          }}
          refetchQueries={[{
            query: FilterPropertyRuleCustomerWithSearchDocument,
            variables: {
              id: filterPropertyRuleId ?? '',
              searchText,
            },
          }]}
        />
      )}
    </>
  );
};

export default CustomerFilterElementsDropdownContent;
