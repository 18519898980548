import { CallSourceType, IntegrationType, Language, RecordingMode, RecordingOption } from '@cycle-app/graphql-codegen';
import { TextSecondary, SelectPanel, Button } from '@cycle-app/ui';
import { ZoomIcon, GoogleMeetIcon, MicrosoftTeamsIcon } from '@cycle-app/ui/icons';
import { isPresent } from 'ts-is-present';

import { getLanguageName, LANGUAGES_OPTIONS } from 'src/constants/languages.constants';
import { recordingModeOptions, recordingOptions, recordingOptionsLabel, recordingModeLabel } from 'src/constants/recording.constants';
import { useInstallIntegration, useOptimizedBooleanState } from 'src/hooks';
import { useRemoveIntegration } from 'src/hooks/api/mutations/integrations/useRemoveIntegration';
import { useMeetingsIntegration } from 'src/hooks/integration/useMeetingsIntegration';
import { useCallSettingsUpdate } from 'src/hooks/user/useCallSettingsUpdate';
import { Layer } from 'src/types/layers.types';

import { ToggleDropdown } from '../../DropdownLayer';
import { FieldButton } from '../../Form/Form.styles';
import {
  Main, Section, Heading, SourcesBox, SourceItem, SourceLabel, SourceIcon, StyledCaretIcon,
} from './RecordingTabSettings.styles';

export const RecordingTabSettings = () => {
  const {
    updateRecordingLanguage,
    updateRecordingMode,
    updateRecordingOption,
    updateSources,
  } = useCallSettingsUpdate();
  const { remove: removeCalendar } = useRemoveIntegration();
  const {
    integration, provider,
  } = useMeetingsIntegration();

  const sources = provider?.sources.filter(isPresent);

  const installCalendar = useInstallIntegration();
  const [isUpdateCalendarLoading, { toggleCallback: toggleUpdateCalendar }] = useOptimizedBooleanState(false);

  const toggleSource = (source: CallSourceType) => {
    return async () => {
      if (!sources) return;
      const isChecked = !!sources?.includes(source);
      await updateSources(
        isChecked ? sources.filter(s => s !== source) : [...sources, source],
      );
    };
  };

  if (!integration) return null;
  return (
    <Main>
      <Section>
        <Heading>Sources</Heading>
        <SourcesBox>
          <SourceItem
            id="zoom"
            checked={!!sources?.includes(CallSourceType.Zoom)}
            onChange={toggleSource(CallSourceType.Zoom)}
            label={(
              <SourceLabel>
                <SourceIcon><ZoomIcon /></SourceIcon>
                Zoom
              </SourceLabel>
            )}
          />
          <SourceItem
            id="meet"
            checked={!!sources?.includes(CallSourceType.GoogleMeet)}
            onChange={toggleSource(CallSourceType.GoogleMeet)}
            label={(
              <SourceLabel>
                <SourceIcon><GoogleMeetIcon /></SourceIcon>
                Google Meet
              </SourceLabel>
            )}
          />
          <SourceItem
            id="teams"
            checked={!!sources?.includes(CallSourceType.MicrosoftTeams)}
            onChange={toggleSource(CallSourceType.MicrosoftTeams)}
            label={(
              <SourceLabel>
                <SourceIcon><MicrosoftTeamsIcon /></SourceIcon>
                Microsoft Teams
              </SourceLabel>
            )}
          />
        </SourcesBox>
        <TextSecondary $typo="caption">Choose the meeting sources for Cycle to automatically join and record.</TextSecondary>
      </Section>
      <Section>
        <Heading>Auto Recording meetings</Heading>
        <ToggleDropdown
          layer={Layer.DropdownModal}
          button={buttonProps => (
            <FieldButton
              {...buttonProps}
              iconEnd={<StyledCaretIcon direction={buttonProps['data-active'] ? 'top' : 'bottom'} />}
            >
              {provider?.recordingOption ? recordingOptionsLabel[provider.recordingOption] : ''}
            </FieldButton>
          )}
          content={contentProps => (
            <SelectPanel
              style={{ width: contentProps.buttonRect?.width || 'auto' }}
              options={recordingOptions}
              hideSearch
              selectedValue={provider?.recordingOption}
              onOptionChange={async (option) => {
                contentProps.hide();
                await updateRecordingOption(option.value as RecordingOption);
              }}
            />
          )}
        />
        <TextSecondary $typo="caption">Choose the meeting types for Cycle to automatically join and record.</TextSecondary>
      </Section>
      <Section>
        <Heading>Transcription</Heading>
        <ToggleDropdown
          layer={Layer.DropdownModal}
          button={buttonProps => (
            <FieldButton
              {...buttonProps}
              iconEnd={<StyledCaretIcon direction={buttonProps['data-active'] ? 'top' : 'bottom'} />}
            >
              {provider?.recordingLanguage ? getLanguageName(provider.recordingLanguage) : 'Auto'}
            </FieldButton>
          )}
          content={contentProps => (
            <SelectPanel
              style={{ width: contentProps.buttonRect?.width || 'auto' }}
              options={LANGUAGES_OPTIONS}
              selectedValue={provider?.recordingLanguage || undefined}
              onOptionChange={async (option) => {
                contentProps.hide();
                await updateRecordingLanguage(option.value as Language);
              }}
              clearLabel="Auto"
              onClearValue={async () => {
                contentProps.hide();
                await updateRecordingLanguage(null);
              }}
            />
          )}
        />
        <TextSecondary $typo="caption">Select the default language your meetings will be transcribed in.</TextSecondary>
      </Section>
      <Section>
        <Heading>Screen Share Recording</Heading>
        <ToggleDropdown
          layer={Layer.DropdownModal}
          button={buttonProps => (
            <FieldButton
              {...buttonProps}
              iconEnd={<StyledCaretIcon direction={buttonProps['data-active'] ? 'top' : 'bottom'} />}
            >
              {provider?.recordingMode ? recordingModeLabel[provider.recordingMode] : ''}
            </FieldButton>
          )}
          content={contentProps => (
            <SelectPanel
              style={{ width: contentProps.buttonRect?.width || 'auto' }}
              options={recordingModeOptions}
              hideSearch
              selectedValue={provider?.recordingMode}
              onOptionChange={async (option) => {
                contentProps.hide();
                await updateRecordingMode(option.value as RecordingMode);
              }}
            />
          )}
        />
        <TextSecondary $typo="caption">Choose how Cycle captures active speaker video when a user is screen-sharing.</TextSecondary>
      </Section>
      <Section>
        <Heading>Calendar account</Heading>
        <div style={{ display: 'flex' }}>
          <Button
            size="M"
            variant="nospace-primary"
            isLoading={isUpdateCalendarLoading}
            onClick={async () => {
              toggleUpdateCalendar();
              await removeCalendar(integration.id);
              await installCalendar(IntegrationType.Meeting, integration);
              toggleUpdateCalendar();
            }}
          >
            Update calendar account
          </Button>
        </div>
        <TextSecondary $typo="caption">
          Meetings synced come from the Google Calendar account used during the installation of this integration.
        </TextSecondary>
      </Section>
      <Section>
        <div style={{ display: 'flex' }}>
          <Button
            size="M"
            variant="nospace-primary"
            onClick={() => removeCalendar(integration.id)}
          >
            Disable call recording
          </Button>
        </div>
      </Section>
    </Main>
  );
};
