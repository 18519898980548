import { Emoji } from '@cycle-app/ui';
import { DraggableAttributes } from '@dnd-kit/core';
import { ComponentProps, forwardRef, useState } from 'react';

import { routing, PageId } from 'src/constants/routing.constant';
import { useReleaseNoteBase, useReleaseNotesContext } from 'src/contexts/releaseNotesContext';
import { useFeatureFlag, useNavigate, useRouteMatch } from 'src/hooks';
import { useGetDocType } from 'src/reactives/docTypes.reactive';
import { resetReleasesAction, useGetReleasesAction } from 'src/reactives/releases.reactive';

import { useGetPermission } from '../../reactives';
import { ReleaseDocInsights } from './ReleaseDocInsights';
import { ReleaseDocStatus } from './ReleaseDocStatus';
import { ReleaseNoteActionsMenu } from './ReleaseNoteActionMenu';
import {
  Card, CardNew, Title, ToggleNoteEditor, Content, Tag,
} from './ReleaseNoteCard.styles';
import { ReleaseNoteDraftTag } from './ReleaseNoteDraftTag';
import { ReleaseNoteTitleForm } from './ReleaseNoteTitleForm';

type Props = {
  noteId: string;
  isOverlay?: boolean;
  isPlaceholder?: boolean;
  handleDragProps?: DraggableAttributes;
  isChangelogEnabled: boolean;
};

export const ReleaseNoteCard = forwardRef<HTMLDivElement, ComponentProps<typeof Card> & Props>(({
  noteId,
  isOverlay,
  isPlaceholder,
  handleDragProps,
  isChangelogEnabled,
  ...props
}, ref) => {
  const matchReleaseNote = useRouteMatch(routing[PageId.ReleaseNote]);
  const isActive = matchReleaseNote?.params.noteId === noteId;
  const content = !isPlaceholder && (
    <ReleaseNoteCardContent
      noteId={noteId}
      isOverlay={isOverlay}
      isActive={isActive}
      handleDragProps={handleDragProps}
    />
  );
  return isChangelogEnabled ? (
    <CardNew
      ref={ref}
      {...props}
      $isActive={isActive}
      $isPlaceholder={isPlaceholder}
      $isOverlay={isOverlay}
    >
      {content}
    </CardNew>
  ) : (
    <Card
      ref={ref}
      {...props}
      $isActive={isActive}
      $isPlaceholder={isPlaceholder}
      $isOverlay={isOverlay}
    >
      {content}
    </Card>
  );
});

export const ReleaseNoteCardContent = ({
  noteId, isOverlay, isActive, handleDragProps,
}: {
  noteId: string;
  isActive: boolean;
  isOverlay?: boolean;
  handleDragProps?: DraggableAttributes;
}) => {
  const { canUpdateReleaseNote } = useGetPermission();
  const action = useGetReleasesAction();
  const releaseId = useReleaseNotesContext(ctx => ctx.releaseId);
  const isReadonly = useReleaseNotesContext(ctx => ctx.isReadonly);
  const note = useReleaseNoteBase(noteId);
  const docType = useGetDocType(note?.doc?.doctype.id);
  const [isHovered, setIsHovered] = useState(false);
  const {
    navigateToRelease, navigateToReleaseNote,
  } = useNavigate();
  const isChangelogEnabled = useFeatureFlag('changelog').isEnabled;

  if (!note) return null;

  const isRenaming = action.type === 'updateReleaseNote' && action.id === noteId;

  return (
    <>
      {!isOverlay && !isRenaming && (
        <ToggleNoteEditor
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onBlur={() => setIsHovered(false)}
          onClick={() => {
            resetReleasesAction();
            return isActive ? navigateToRelease(releaseId) : navigateToReleaseNote(releaseId, noteId);
          }}
          {...handleDragProps}
        />
      )}

      <Content>
        {!isChangelogEnabled && docType && (
          <Tag>
            <Emoji emoji={docType.emoji} size={14} />
          </Tag>
        )}

        {!isOverlay && isRenaming
          ? <ReleaseNoteTitleForm noteId={noteId} />
          : <Title>{note.title}</Title>}

        {!isChangelogEnabled && !isOverlay && !isRenaming && (
          <ReleaseDocStatus
            noteId={noteId}
            isEnabled={isHovered}
            isReadOnly={!canUpdateReleaseNote}
            hideLabel
          />
        )}

        {!isChangelogEnabled && !isRenaming && (
          <ReleaseNoteDraftTag
            noteId={noteId}
            isReadOnly={!canUpdateReleaseNote}
          />
        )}

        {!isChangelogEnabled && !isOverlay && !isRenaming && (
          <ReleaseDocInsights
            noteId={noteId}
            isReadOnly={!canUpdateReleaseNote}
          />
        )}

        {!isOverlay && !isRenaming && (
          <ReleaseNoteActionsMenu noteId={noteId} disabled={isChangelogEnabled && isReadonly} />
        )}
      </Content>
    </>
  );
};
