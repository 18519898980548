import { SelectOption } from '@cycle-app/ui';
import { TrashIcon, PenIcon, CheckIcon, DuplicateIcon } from '@cycle-app/ui/icons';
import { useMemo, useState } from 'react';

import DialogModal from 'src/components/DialogModal/DialogModal';
import { useDocPanelContext } from 'src/contexts/docPanelContext';
import { useDeleteComment, useResolveThread } from 'src/hooks/api/mutations/comments';
import { useUnresolveThread } from 'src/hooks/api/mutations/comments/useUnresolveThread';
import { useCopyCommentLink } from 'src/hooks/useCopyCommentLink';
import { useShowResolveThreadButton } from 'src/hooks/usShowResolveThreadButton';
import { useThreadsPanel } from 'src/reactives/comments.reactive';
import { Layer } from 'src/types/layers.types';

import { ResolveButton, ThreadMenu } from './ThreadItem.styles';

export const ThreadActions = ({
  threadId, docId, blockId, firstCommentId, isCreatedByMe, onEdit, isResolved,
}: {
  threadId: string;
  docId: string;
  blockId: string | null;
  firstCommentId: string;
  isCreatedByMe?: boolean;
  onEdit?: VoidFunction;
  isResolved: boolean;
}) => {
  const {
    onDelete, onResolve,
  } = useThreadsPanel();

  const doc = useDocPanelContext(ctx => ctx.doc);
  const copyCommentLink = useCopyCommentLink();
  const showResolveButton = useShowResolveThreadButton(docId, blockId, threadId);

  const {
    resolveThread, isResolvingThread,
  } = useResolveThread();
  const {
    unresolveThread, isUnresolvingThread,
  } = useUnresolveThread();
  const { deleteComment } = useDeleteComment({ isFirstComment: true });
  const [isDialogVisible, setDialogVisible] = useState(false);

  const options = useMemo<SelectOption[]>(() => {
    const result: SelectOption[] = [];

    if (isCreatedByMe) {
      result.push({
        label: 'Edit',
        value: 'edit',
        icon: <PenIcon />,
        onSelect: onEdit,
      });
    }

    result.push({
      label: 'Copy link',
      value: 'copy',
      icon: <DuplicateIcon />,
      onSelect: () => copyCommentLink(doc, firstCommentId),
    });

    if (isCreatedByMe) {
      result.push({
        label: 'Delete thread',
        value: 'delete',
        icon: <TrashIcon />,
        variant: 'danger',
        onSelect: () => setDialogVisible(true),
      });
    }

    return result;
  }, [copyCommentLink, doc, firstCommentId, onEdit, isCreatedByMe]);

  return (
    <>
      {showResolveButton && (
        <ResolveButton
          tooltip={isResolved ? 'Unresolve thread' : 'Resolve thread'}
          tooltipPlacement="top"
          $resolved={isResolved}
          isLoading={isResolvingThread || isUnresolvingThread}
          onClick={async (e) => {
            e.stopPropagation();
            if (isResolved) {
              await unresolveThread(docId, { commentId: firstCommentId });
            } else {
              await resolveThread(docId, blockId, { commentId: firstCommentId });
            }
            onResolve?.(blockId, !isResolved);
          }}
        >
          <CheckIcon
            size={16}
            style={{ color: isResolved ? 'hsl(var(--cycle))' : 'unset' }}
          />
        </ResolveButton>
      )}

      <ThreadMenu
        layer={Layer.DropdownZ1}
        placement="bottom-end"
        options={options}
      />

      {isCreatedByMe && isDialogVisible && (
        <DialogModal
          title="Delete thread"
          info="Are you sure you want to delete this thread? This will delete all of its replies."
          confirmLabel="Delete"
          hide={() => setDialogVisible(false)}
          onConfirm={async () => {
            await deleteComment(docId, blockId, { commentId: firstCommentId });
            onDelete?.(blockId);
          }}
        />
      )}
    </>
  );
};
