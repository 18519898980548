import { typo, Button } from '@cycle-app/ui';
import styled from 'styled-components';

import { AiGenetaredInsights } from 'src/components/AiGeneratedInsights';

export const OptionsContainer = styled.div`
  border-radius: 4px;
  width: 24px;
  height: 24px;
  z-index: 1;
`;

export const AddButton = styled(Button).attrs({ variant: 'secondary' })`
  ${typo.body500};
`;

export const DoctypeInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  color: ${p => p.theme.colors.text.primary};
  ${typo.body500};
`;

export const DoctypeLabel = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledAiGenetaredInsights = styled(AiGenetaredInsights)`
  padding: 0;
`;
