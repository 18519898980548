import { InputHTMLAttributes, forwardRef, ReactNode } from 'react';

import { CheckIcon } from '../../../icons';
import { Container, Label, CheckboxInputText, CheckboxSquare, StyledInput } from './CheckboxInput.styles';

export {
  CheckboxInputText, CheckboxSquare,
};
export interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  value?: string | number;
  label?: ReactNode;
  hideLabel?: boolean;
  stopPropagation?: boolean;
}
export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(({
  className,
  label: labelFromProps,
  hideLabel,
  stopPropagation,
  ...inputProps
}, ref) => {
  const label = labelFromProps ?? inputProps.value;
  return (
    <Container
      className={className}
      $isDisabled={!!inputProps.disabled}
      {...stopPropagation && {
        onClick: (e) => {
          e.stopPropagation();
        },
      }}
    >
      <StyledInput
        ref={ref}
        type="checkbox"
        {...inputProps}
      />
      <Label htmlFor={inputProps.id}>
        <CheckboxSquare>
          <CheckIcon />
        </CheckboxSquare>
        {!!label && !hideLabel && (
          <CheckboxInputText>{label}</CheckboxInputText>
        )}
      </Label>
    </Container>
  );
});
