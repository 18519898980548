import { Avatar, ShyScrollbar, typo, Button } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

const avatarSize = 24;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* 83px is the height of the Header, inclunding its margins */
  max-height: calc(100% - 83px);
`;

const GridLineCSS = css`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: minmax(200px, 3fr) minmax(140px, 2fr) minmax(100px, 2fr) minmax(60px, 1fr);

  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
`;

export const UsersListHeader = styled.div`
  ${GridLineCSS};
  flex-shrink: 0;
  margin-bottom: 24px;
  color: ${p => p.theme.colors.text.secondary};
  overflow: hidden;
`;

export const StyledShyScrollbar = styled(ShyScrollbar)`
  margin-right: -8px;
  padding-right: 8px;
  padding-bottom: 32px;
`;

export const ActionsColumnName = styled.div`
  text-align: right;
  padding-right: 8px;
`;

export const GridLine = styled.div`
  ${GridLineCSS};
  padding-top: 12px;
  align-items: center;

  &:first-child {
    padding-top: 0;
  }
  &:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid ${p => p.theme.colors.border.secondary};
  }
`;

export const NameCell = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const NameAndEmailContainer = styled.div``;

export const FullName = styled.div`
  ${typo.body500};
`;

export const Email = styled.div`
  ${typo.body500};
  color: ${p => p.theme.colors.text.secondary};
`;

export const JobTitle = styled.div`
  &[aria-disabled='true'] {
    color: ${p => p.theme.colors.text.disabled};
  }
`;

export const UserAvatar = styled(Avatar)`
  width: ${avatarSize}px;
  height: ${avatarSize}px;
`;

export const DotsMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-right: 8px;
`;

export const ProductRole = styled.span`
  text-transform: lowercase;
  :first-letter {
    text-transform: uppercase;
  }
`;

export const ResendButton = styled(Button)`
  white-space: nowrap;
`;

export const DefaultAssigneeLabel = styled.span`
  display: inline-block;
  padding: 3px 16px;
  white-space: nowrap;
  color: ${p => p.theme.colors.text.secondary};
`;

export const DefaultAssigneeLabelContent = styled.div`
  text-align: center;
  max-width: 220px;
`;

export const MessageAvatar = styled(Avatar)`
  display: inline-flex;
  margin-right: 4px;
  vertical-align: middle;
`;

export const UserLabel = styled.span`
  ${typo.body500};
`;
