import { CreateReleaseNotePosition } from '@cycle-app/graphql-codegen';
import { ActionButton, Flex } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { toShortLocaleDateString } from '@cycle-app/utilities';
import { AnimatePresence, Variants } from 'framer-motion';
import { FC, useRef } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';

import { ReleasesPageTitle } from 'src/components/PageTitle/PageTitle';
import { useReleaseBase } from 'src/contexts/releasesContext';
import { useReleaseSubscription } from 'src/hooks/api/useReleaseSubscription';
import { useNavigate } from 'src/hooks/useNavigate';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';
import { openCreateReleaseNote, resetReleasesAction, useGetReleasesAction } from 'src/reactives/releases.reactive';

import { useFeatureFlag } from '../../hooks';
import { ReleaseActionsMenu as ReleaseActionsMenuNew } from '../ReleaseActionsMenu';
import { ReleaseStatus } from '../ReleaseStatus';
import { ReleaseActionsMenu } from './ReleaseActionsMenu';
import {
  Container, Header, Toggle, Content, Caret,
  TextContent, ReleaseDate, ReleaseTitle, Separator,
  ReleaseNotesContainer,
} from './ReleaseItem.styles';

export const ReleaseItem: FC<React.PropsWithChildren<{
  releaseId: string;
  isActive: boolean;
}>> = ({
  releaseId, isActive, children,
}) => {
  useReleaseSubscription(releaseId);
  const { canCreateReleaseNote } = useGetPermission();
  const release = useReleaseBase(releaseId);
  const {
    navigateToReleases, navigateToRelease,
  } = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const scrollItemIntoView = () => {
    if (!ref.current) return;
    scrollIntoView(ref.current, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
    });
  };

  const isChangelogEnabled = useFeatureFlag('changelog').isEnabled;
  const action = useGetReleasesAction();
  const isCreateFormOpen = action.id === releaseId && action.type === 'createReleaseNote';
  const isEndPosition = 'position' in action && action.position === CreateReleaseNotePosition.End;
  const date = release && release.date ? toShortLocaleDateString(release.date) : null;
  const isNoRelease = !release?.date;
  return (
    <>
      {isActive && date && <ReleasesPageTitle releaseDate={date} />}
      <Container $isLight={isChangelogEnabled} $isActive={isActive} ref={ref}>
        <Header>
          <Toggle
            onClick={() => {
              resetReleasesAction();
              return isActive ? navigateToReleases() : navigateToRelease(releaseId);
            }}
          />

          <Content>
            <TextContent>
              <Caret direction={isActive ? 'bottom' : 'right'} />
              {date && <ReleaseDate>{date}</ReleaseDate>}
              {release?.title && (
                <>
                  {date && <Separator />}
                  <ReleaseTitle>{release.title}</ReleaseTitle>
                </>
              )}
            </TextContent>

            {!isChangelogEnabled && !(isCreateFormOpen && isEndPosition) && isActive && (
              <ActionButton
                onClick={() => {
                  if (!canCreateReleaseNote) {
                    setLimitationsModal({ action: 'RELEASE_UPDATE' });
                    return;
                  }
                  openCreateReleaseNote(releaseId, false, CreateReleaseNotePosition.End);
                }}
                tooltip="Create release note"
                tooltipPlacement="top"
              >
                <AddIcon size={12} />
              </ActionButton>
            )}

            {isChangelogEnabled && !isNoRelease && release?.publicStatus && (
              <Flex $gap={4}>
                <ReleaseStatus publicStatus={release.publicStatus} releaseId={release.id} />
                <ReleaseActionsMenuNew publicStatus={release.publicStatus} releaseId={release.id} />
              </Flex>
            )}

            {!isChangelogEnabled && !isNoRelease && isActive && (
              <ReleaseActionsMenu
                style={{ alignSelf: 'flex-start' }}
                releaseId={releaseId}
              />
            )}
          </Content>
        </Header>

        <AnimatePresence initial={false} presenceAffectsLayout>
          {isActive && (
            <ReleaseNotesContainer
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{ bounce: 0 }}
              onAnimationStart={def => def === 'visible' && scrollItemIntoView()}
              onAnimationComplete={def => def === 'visible' && scrollItemIntoView()}
            >
              {children}
            </ReleaseNotesContainer>
          )}
        </AnimatePresence>
      </Container>
    </>
  );
};

const variants: Variants = {
  hidden: {
    opacity: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    height: 'auto',
  },
};
