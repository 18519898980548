import { CloseIcon } from '@cycle-app/ui/icons';

import { closeLinearModal, useGetLinearModal, getLinearModal } from 'src/reactives/linear.reactive';
import { Layer } from 'src/types/layers.types';
import { getDocFromCache } from 'src/utils/cache.utils';

import { getNewDocTitle } from '../../reactives/boardNewDoc/newDocTitle.reactive';
import { StyledModal, ModalTitle, CloseButton } from './DocLinear.styles';
import { LinearIssueForm } from './LinearIssueForm';
import { LinearProjectForm } from './LinearProjectForm';

export const LinearModal = () => {
  const { type } = useGetLinearModal();
  if (type === null) return null;
  return (
    <StyledModal
      layer={Layer.ModalZ2}
      hide={closeLinearModal}
    >
      <ModalContent />
    </StyledModal>
  );
};

const ModalContent = () => {
  const { type } = useGetLinearModal();
  const modal = getLinearModal();

  // Spec: default title is the search text from the linear dropdown, or the doc title
  let defaultTitle = modal.searchText;
  if (!defaultTitle && modal.docId) {
    const doc = getDocFromCache(modal.docId);
    if (doc?.title) defaultTitle = doc.title;
    if (doc?.isDraft) defaultTitle = getNewDocTitle().title;
  }

  return (
    <>
      <ModalTitle>
        {type === 'project'
          ? 'Create new Linear project'
          : 'Create new Linear issue'}
      </ModalTitle>

      <CloseButton onClick={closeLinearModal}>
        <CloseIcon size={12} />
      </CloseButton>

      {type === 'project'
        ? (
          <LinearProjectForm
            defaultTitle={defaultTitle ?? ''}
            defaultTeamIds={modal.teamIds}
            {...modal.onCreate && { onCreate: modal.onCreate }}
          />
        )
        : (
          <LinearIssueForm
            defaultTitle={defaultTitle ?? ''}
            {...modal.onCreate && { onCreate: modal.onCreate }}
          />
        )}
    </>
  );
};
