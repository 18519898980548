import { useFeatureFlag } from 'src/hooks';
import { useAskSubscription } from 'src/hooks/api/useAskSubscription';
import { useIsMobile } from 'src/reactives';
import { closeAskPanel, useGetAskPanel } from 'src/reactives/ask.reactive';
import { useGetSidebarCollapsed } from 'src/reactives/sidebar.reactive';

import { StyledDropdown } from './Ask.styles';
import { AskPanel } from './AskPanel';

export const Ask = () => {
  const isAskEnabled = useFeatureFlag('ask').isEnabled;
  const isAskOpen = useGetAskPanel().isOpen;
  const isSidebarCollapsed = useGetSidebarCollapsed().collapsed;
  const isMobile = useIsMobile();
  useAskSubscription();

  if (!isAskEnabled) return null;
  // eslint-disable-next-line no-nested-ternary
  const offsetX = isMobile ? 24 : (isSidebarCollapsed ? 88 : 184);
  return (
    <StyledDropdown
      withPortal
      placement="right-end"
      visible={isAskOpen}
      hide={closeAskPanel}
      $offsetX={offsetX}
      offset={[-24, offsetX]}
      getReferenceClientRect={() => new DOMRect(0, window.innerHeight, 0, 0)}
      content={<AskPanel />}
    />
  );
};
