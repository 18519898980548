import { Tag, typo } from '@cycle-app/ui';
import styled, { css } from 'styled-components';

import DropdownSelectLayer from 'src/components/DropdownSelectLayer/DropdownSelectLayer';

export const StyledDropdownSelectLayer = styled(DropdownSelectLayer)`
  max-height: calc(50vh / 2 - 30px);
`;

export const DoctypeTag = styled(Tag)`
  svg {
    margin-left: 5px;
    margin-right: 4px;
  }
`;

export const DoctypeTagContent = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  ${p => p.$disabled && css`
    pointer-events: none;
  `};
`;

export const UpdatePreview = styled.div`
  align-items: center;
  border-radius: 6px;
  background-color: ${p => p.theme.colors.background.secondary};
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 14px 16px;
  height: 50px;
`;

export const UpdatePreviewDoctypes = styled.div`
  align-items: center;
  color: ${p => p.theme.colors.text.disabled};
  display: flex;
`;

export const UpdatePreviewDoctypeTag = styled(Tag)`
  ${typo.body400}
  border: none;
  padding: 4px 8px;
`;

export const UpdateInfo = styled.p`
  margin-top: 16px;
`;

export const DocTag = styled(Tag)`
  ${typo.caption500}
  background-color: ${p => p.theme.colors.background.tertiary};
  border: none;
  display: inline-flex;
`;
