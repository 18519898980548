import { ThemeType } from '@cycle-app/graphql-codegen/generated';
import { SelectPanel } from '@cycle-app/ui';
import { CaretIcon } from '@cycle-app/ui/icons';

import { ToggleDropdown } from 'src/components/DropdownLayer';
import { useGetThemeConfig, setThemeConfig } from 'src/reactives/theme.reactive';
import { Layer } from 'src/types/layers.types';

import { ItemButton } from './Settings.styles';

const options = {
  [String(ThemeType.Sunrise)]: {
    value: ThemeType.Sunrise,
    label: '🌞 Light',
  },
  [String(ThemeType.Nightfall)]: {
    value: ThemeType.Nightfall,
    label: '🌚 Dark',
  },
};

export const ThemeSelect = () => {
  const config = useGetThemeConfig();
  const colorTheme = config.colorTheme === ThemeType.Eclipse ? ThemeType.Sunrise : config.colorTheme.toUpperCase();

  return (
    <ToggleDropdown
      layer={Layer.DropdownModalZ2}
      button={props => (
        <ItemButton {...props}>
          {options[colorTheme]?.label || ''}
          <CaretIcon
            size={8}
            direction={props['data-active'] ? 'top' : 'bottom'}
          />
        </ItemButton>
      )}
      content={props => (
        <SelectPanel
          hideSearch
          selectedValue={colorTheme}
          options={Object.values(options)}
          onOptionChange={option => {
            props.hide();
            const newColorTheme = options[option.value]?.value;
            if (!newColorTheme) return;
            setThemeConfig({ colorTheme: newColorTheme });
          }}
        />
      )}
    />
  );
};
