import { ReactNode, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import useQueryParams from 'src/hooks/useQueryParams';

import { FormContainer } from './Form.styles';
import { FormData } from './Form.types';
import { useSubmitForm } from './useSubmitForm';

export type FormProps = {
  children: ReactNode;
};

export const Form = ({ children }: FormProps) => {
  const queryParams = useQueryParams();
  const submit = useSubmitForm();

  const form = useForm<FormData>({
    values: {
      title: '',
      assignee: queryParams.get('assignee') ?? undefined,
      customer: queryParams.get('customer') ? { id: queryParams.get('customer') ?? '' } : undefined,
      description: '',
      custom: {},
    },
  });

  useEffect(() => {
    const paramsTitle = queryParams.get('title');
    if (!paramsTitle) return;
    // Set title field as non-dirty to allow auto-fill
    form.setValue('title', paramsTitle, { shouldDirty: false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...form}>
      <FormContainer
        onSubmit={form.handleSubmit(submit)}
        onReset={() => form.reset()}
      >
        {children}
      </FormContainer>
    </FormProvider>
  );
};
