import { SelectLine } from '@cycle-app/ui';
import { nextFrame } from '@cycle-app/utilities/src/utils/async.utils';
import { RefObject } from 'react';

import { ToggleDropdown, ToggleDropdownProps } from 'src/components/DropdownLayer';
import { useReleaseNote } from 'src/hooks/releases/useReleaseNote';
import { useUpdateReleaseNote } from 'src/hooks/releases/useUpdateReleaseNote';
import { useGetPermission } from 'src/reactives';
import { setLimitationsModal } from 'src/reactives/limitationsModal.reactive';

import { DraftTag, SelectPanel } from './ReleaseNoteCard.styles';

export const ReleaseNoteDraftTag = ({
  noteId, isReadOnly = false,
}: {
  noteId: string;
  isReadOnly?: boolean;
}) => {
  const { canUpdateReleaseNote } = useGetPermission();
  const { releaseNote } = useReleaseNote(noteId);
  if (!releaseNote) return null;
  const { isPublished } = releaseNote;

  if (isReadOnly) {
    return (
      <DraftTag $isPublished={isPublished} role="button">
        {isPublished ? 'Ready' : 'Draft'}
      </DraftTag>
    );
  }

  return (
    <ReleaseNoteDraftDropdown
      noteId={noteId}
      placement="bottom-end"
      button={({
        ref, ...props
      }) => (
        <DraftTag
          ref={ref as unknown as RefObject<HTMLDivElement>}
          $isPublished={isPublished}
          role="button"
          {...props}
          onClick={canUpdateReleaseNote ? props.onClick : () => setLimitationsModal({ action: 'RELEASE_UPDATE' })}
        >
          {isPublished ? 'Ready' : 'Draft'}
        </DraftTag>
      )}
    />
  );
};

export const ReleaseNoteDraftDropdown = ({
  noteId, button, placement,
}: Pick<ToggleDropdownProps, 'button' | 'placement'> & {
  noteId: string;
}) => {
  return (
    <ToggleDropdown
      placement={placement}
      button={button}
      content={props => (
        <ReleaseNoteDraftPanel
          noteId={noteId}
          hide={props.hide}
        />
      )}
    />
  );
};

const ReleaseNoteDraftPanel = ({
  noteId, hide,
}: {
  noteId: string;
  hide: VoidFunction;
}) => {
  const { releaseNote } = useReleaseNote(noteId);
  const { updateReleaseNote } = useUpdateReleaseNote();
  if (!releaseNote) return null;
  return (
    <SelectPanel>
      <SelectLine
        label={<DraftTag>Draft</DraftTag>}
        onClick={async () => {
          hide();
          await nextFrame();
          if (!releaseNote.isPublished) return;
          await updateReleaseNote(releaseNote, { isPublished: false });
        }}
      />
      <SelectLine
        label={<DraftTag $isPublished>Ready</DraftTag>}
        onClick={async () => {
          hide();
          await nextFrame();
          if (releaseNote.isPublished) return;
          await updateReleaseNote(releaseNote, { isPublished: true });
        }}
      />
    </SelectPanel>
  );
};
