import { JobTitle, MeFragment, SetupMyAccountInfosDocument } from '@cycle-app/graphql-codegen';
import { Input, RadioInput } from '@cycle-app/ui';
import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { FieldLabel } from 'src/components/Form/Form.styles';
import useSafeMutation from 'src/hooks/useSafeMutation';
import { jobsLabel } from 'src/utils/jobs.util';

import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Footer, Form, FormGrid, NextButton } from '../OnboardingLayout/OnboardingLayout.styles';
import { OnboardingQuote } from '../OnboardingLayout/OnboardingQuote';
import { JobsInput, RadioContainer } from './OnboardingStepPassword.styles';

interface Props {
  me: MeFragment;
  onSuccess: VoidFunction;
}

type FormData = {
  firstname: string;
  lastname: string;
  job: JobTitle;
};

const orderedJobs = [
  JobTitle.ProductManagement,
  JobTitle.Tech,
  JobTitle.Design,
  JobTitle.Business,
  JobTitle.FounderCLevel,
];

export const InfosForm: FC<React.PropsWithChildren<Props>> = ({
  me, onSuccess,
}) => {
  const [setupAccount, { loading: isAccountLoading }] = useSafeMutation(SetupMyAccountInfosDocument);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      firstname: me.firstName || '',
      lastname: me.lastName || '',
      job: me.jobTitle || undefined,
    },
  });
  const {
    firstname, lastname, job,
  } = watch();

  const onSubmit = async (data: FormData) => {
    const accountResult = await setupAccount({
      variables: {
        firstName: data.firstname,
        lastName: data.lastname,
        jobTitle: data.job,
      },
    });
    if (accountResult?.data?.updateMe?.id) {
      onSuccess();
    }
  };

  return (
    <OnboardingLayout
      title="Let’s configure your account"
      aside={<OnboardingQuote user="aurelien" />}
      main={(
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGrid>
            <Input
              autoFocus
              type="text"
              label="First name"
              placeholder="John"
              required
              {...register('firstname', { required: true })}
            />
            <Input
              id="lastName"
              type="text"
              label="Last name"
              placeholder="Doe"
              required
              {...register('lastname', { required: true })}
            />
            <RadioContainer>
              <FieldLabel>What best describes your role?</FieldLabel>
              <JobsInput>
                {orderedJobs.map(jobTitle => (
                  <RadioInput
                    key={jobTitle}
                    id={jobTitle}
                    label={jobsLabel[jobTitle]}
                    value={jobTitle}
                    checked={jobTitle === job}
                    onChange={() => setValue('job', jobTitle)}
                  />
                ))}
              </JobsInput>
            </RadioContainer>
          </FormGrid>
          <Footer>
            <NextButton
              disabled={!lastname.trim() || !firstname.trim() || !job || !!Object.keys(errors).length}
              type="submit"
              isLoading={isAccountLoading}
              size="M"
            >
              Next
            </NextButton>
          </Footer>
        </Form>
      )}
    />
  );
};
