import { getDocSlug } from '@cycle-app/utilities';

import { ALL_DOCS_BOARD_SLUG, MainPageId, PageId } from 'src/constants/routing.constant';
import { history } from 'src/providers';
import { LocationState, RouteParams } from 'src/types/routes.types';
import { getParams, getParentPage, getUrl, getPageId } from 'src/utils/routing.utils';

const getAuthorizedUrl = (pageId: PageId, params?: RouteParams) => {
  if (
    getParams().productSlug && // Make sure we are in the app.
    pageId === PageId.Board &&
    params?.boardSlug === ALL_DOCS_BOARD_SLUG
  ) {
    return getUrl(PageId.Main);
  }
  return getUrl(pageId, params);
};

export const navigate = (
  pageId: MainPageId,
  params?: RouteParams,
  state?: LocationState,
  metaKey?: boolean,
) => {
  const fromStarredBoard = history.location.state?.fromStarredBoard;
  const newState = fromStarredBoard ? ({
    fromStarredBoard,
    ...state,
  }) : state;

  if (metaKey) {
    window.open(getAuthorizedUrl(pageId, params), '_blank');
  } else {
    history.push(getAuthorizedUrl(pageId, params), newState);
  }
};

export const navigateToDocFullPage = (
  params: { title: string; id: string; hash?: string },
  state?: LocationState,
  metaKey?: boolean,
) => {
  const {
    hash, ...doc
  } = params;
  navigate(PageId.DocFullPage, {
    docSlug: getDocSlug(doc),
    hash,
  }, state, metaKey);
};

export const navigateToDocPanelPage = (
  params: { title: string; id: string; hash?: string },
  state?: LocationState,
  metaKey?: boolean,
) => {
  const currentParams = getParams();
  const {
    hash, ...doc
  } = params;
  const parentPageId = getParentPage();
  const pageIds: Partial<Record<typeof parentPageId, MainPageId>> = {
    inbox: PageId.InboxDoc,
    insight: PageId.InsightDoc,
    roadmap: PageId.RoadmapDoc,
    home: PageId.HomeDoc,
  };

  const fromStarredBoard = history.location.state?.fromStarredBoard;

  const newState = fromStarredBoard ? ({
    fromStarredBoard,
    ...state,
  }) : state;

  navigate(pageIds[parentPageId] || PageId.Doc, {
    docSlug: getDocSlug(doc),
    boardSlug: currentParams?.boardSlug,
    hash,
  }, newState, metaKey);
};

export const navigateToInbox = (
  boardSlug?: string,
  state?: LocationState,
  metaKey?: boolean,
) => {
  if (boardSlug) {
    navigate(PageId.InboxView, { boardSlug }, state, metaKey);
  } else {
    navigate(PageId.Inbox, {}, state, metaKey);
  }
};

export const navigateToReleases = (
  state?: LocationState,
  metaKey?: boolean,
) => {
  navigate(PageId.Releases, {}, state, metaKey);
};

export const navigateToInsightView = (
  boardSlug?: string,
  state?: LocationState,
  metaKey?: boolean,
) => {
  if (boardSlug) {
    navigate(PageId.InsightView, { boardSlug }, state, metaKey);
  } else {
    navigate(PageId.Insight, {}, state, metaKey);
  }
};

export const navigateToRoadmapView = (
  boardSlug?: string,
  state?: LocationState,
  metaKey?: boolean,
) => {
  if (boardSlug) {
    navigate(PageId.RoadmapView, { boardSlug }, state, metaKey);
  } else {
    navigate(PageId.Roadmap, {}, state, metaKey);
  }
};

export const navigateToRelease = (
  releaseId: string,
  state?: LocationState,
  metaKey?: boolean,
) => {
  navigate(PageId.Release, { releaseId }, state, metaKey);
};

export const navigateToReleaseNote = (
  releaseId: string,
  noteId: string,
  state?: LocationState,
  metaKey?: boolean,
) => {
  navigate(PageId.ReleaseNote, {
    releaseId,
    noteId,
  }, state, metaKey);
};

export const navigateToReleaseDoc = (
  releaseId: string,
  noteId: string,
  docSlug: string,
  state?: LocationState,
  metaKey?: boolean,
) => {
  navigate(PageId.ReleaseDoc, {
    releaseId,
    noteId,
    docSlug,
  }, state, metaKey);
};

export const navigateBack = () => {
  const previousLocation = history.location.state?.previousLocation;
  if (previousLocation) {
    history.push(previousLocation);
  } else {
    navigate(PageId.Main);
  }
};

export const navigateToDocPanelParent = () => {
  const parentPageId = getParentPage();
  switch (parentPageId) {
    case 'inbox': {
      navigate(PageId.InboxView);
      return;
    }
    case 'insight': {
      const isFromAnalyse = getPageId() === PageId.AnalysesDoc;
      navigate(isFromAnalyse ? PageId.Analyses : PageId.InsightView);
      return;
    }
    case 'roadmap': {
      navigate(PageId.RoadmapView);
      return;
    }
    case 'home': {
      navigate(PageId.Main);
      return;
    }
    case 'releases': {
      const currentParams = getParams();
      const releaseId = currentParams?.releaseId;
      const noteId = currentParams?.noteId;
      if (!releaseId || !noteId) return;
      navigateToReleaseNote(releaseId, noteId);
      return;
    }
    default:
      navigate(PageId.Board);
  }
};

// TODO: remove these hooks and import the functions directly

export const useNavigate = () => {
  return {
    getUrl: getAuthorizedUrl,
    navigate,
    navigateToDocFullPage,
    navigateToDocPanelPage,
    navigateToDocPanelParent,
    navigateToInbox,
    navigateToReleases,
    navigateToRelease,
    navigateToReleaseNote,
    navigateToReleaseDoc,
    navigateBack,
    navigateToInsightView,
    navigateToRoadmapView,
  };
};

export const useNavigateToDocFullOrPanel = () => {
  const shouldRedirectToDocPanel = !!getParams()?.boardSlug || getPageId() === PageId.Analyses;
  return shouldRedirectToDocPanel
    ? navigateToDocPanelPage
    : navigateToDocFullPage;
};
