import { copyToClipboard } from 'src/utils/clipboard.utils';

import type { Insight } from 'src/hooks/insight/useDocInsights.types';

export const copyQuotesToClipboard = (insights: Insight[]) => {
  copyToClipboard({
    text: insights.map(i => {
      const customerName = i?.doc?.customer?.displayName || 'Unknown';
      const company = i?.doc?.customer?.company?.name || 'No company';
      const quote = i?.content;
      return `<p><i>"${decodeHtmlEntities(quote || '')}"</i><br><b>${customerName}</b> from <b>${company}</b></p>`;
    }).join('\n'),
    notification: 'Quotes copied to clipboard!',
    asHtml: true,
  });
};

function decodeHtmlEntities(str: string) {
  const element = document.createElement('div');
  element.innerHTML = str;
  return element.textContent || '';
}
