import { ContentSection } from '@cycle-app/graphql-codegen';
import styled from 'styled-components';

interface RootProps {
  $contentSection: ContentSection;
}

export const Root = styled.div<RootProps>`
  margin: 115px auto 0;
  max-width: 680px;
  padding-bottom: 80px;

  ${p => p.$contentSection === ContentSection.Discreet && `
    h2 { font-size: 32px; }
    h3 { font-size: 24px; }
    h4 { font-size: 18px; }
  `}
  ${p => p.$contentSection === ContentSection.Uniform && `
    h2 { font-size: 28px; }
    h3 { font-size: 21px; }
    h4 { font-size: 18px; }
  `}
  ${p => p.$contentSection === ContentSection.Classic && `
    h2 { font-size: 24px; }
    h3 { font-size: 18px; }
    h4 { font-size: 16px; }
  `}
  ${p => p.$contentSection === ContentSection.Sober && `
    h2 { font-size: 22px; }
    h3 { font-size: 18px; }
    h4 { font-size: 18px; }
  `}
  ${p => p.$contentSection === ContentSection.ShowOff && `
    h2 { font-size: 46px; }
    h3 { font-size: 36px; }
    h4 { font-size: 27px; }
  `}
  ${p => p.$contentSection === ContentSection.Unique && `
    h2 { font-size: 28px; }
    h3 { font-size: 24px; }
    h4 { font-size: 18px; }
  `}
`;
