import { Flex } from '@cycle-app/ui';

import {
  Container, Title, Description, EnableButton, Image, Top, Bottom,
} from './FeatureEmptyState.styles';

type FeatureEmptyStateProps = {
  onClick: VoidFunction;
  title: string;
  description: string;
  src: string;
};

export const FeatureEmptyState = ({
  onClick, title, description, src,
}: FeatureEmptyStateProps) => (
  <Container>
    <Top>
      <Image alt="" src={src} />
    </Top>
    <Bottom>
      <Flex $column $gap={8} $align="flex-start" $grow={1}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Flex>
      <Flex $justify="center">
        <EnableButton onClick={onClick}>Enable</EnableButton>
      </Flex>
    </Bottom>
  </Container>
);
