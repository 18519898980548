import { typo, ShyScrollbarCss, Input, ActionButton } from '@cycle-app/ui';
import styled from 'styled-components';

export const Grid = styled.div`
  ${ShyScrollbarCss}
  padding: 32px;
  flex: 1;
  overflow: auto;
  display: grid;
  align-content: flex-start;
  align-items: stretch;
  gap: 32px;
  width: 100%;
  justify-content: center;
  margin: 0 auto;

  grid-template-columns: repeat(3, minmax(350px, 410px));

  @media (max-width: 1250px) {
    grid-template-columns: repeat(2, minmax(300px, 410px));
  }

  @media (max-width: 780px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export const Title = styled.h2`
  ${typo.headerSmall};
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: ${p => p.theme.colors.text.primary};
`;

export const SubTitle = styled.h3`
  ${typo.body400};
  color: ${p => p.theme.colors.text.secondary};
`;

export const AddOnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 8px;
`;

export const AddOnsInput = styled(Input)`
  input {
    padding: 0px 12px 0 32px !important;
    height: 36px;
  }
`;

export const AddOnsClose = styled(ActionButton)`
  padding: 8px;
  svg {
    width: 16px;
    height: 16px;
  }
`;
