import { DoctypeFragment, CustomAttributeDefinitionFragment } from '@cycle-app/graphql-codegen';
import { TextButton, SelectOption } from '@cycle-app/ui';
import { AddIcon } from '@cycle-app/ui/icons';
import { nodeToArray } from '@cycle-app/utilities';
import { FC, useCallback, useState, useMemo } from 'react';

import { AttributeUnlinkDoctypeModal } from 'src/components/AttributeUnlinkDoctypeModal/AttributeUnlinkDoctypeModal';
import DropdownLayer from 'src/components/DropdownLayer/DropdownLayer';
import { ATTRIBUTES_LIMIT } from 'src/constants/attributes.constants';
import { useProductAddOn } from 'src/hooks';
import { useAttributes } from 'src/hooks/api/useAttributes';
import useOptimizedBooleanState from 'src/hooks/useOptimizedBooleanState';
import { setLimitationsModal } from 'src/reactives';
import { Layer } from 'src/types/layers.types';
import { getCustomAttributeTypeData } from 'src/utils/attributes.util';

import { SettingsAttributeEditModal } from '../../SettingsAttributes/SettingsAttributeEditModal';
import {
  Container,
  AddNewLine,
  StyledSelectPanel,
} from './SettingsDocTypeProperties.styles';
import { SettingsDocTypePropertyLine } from './SettingsDocTypePropertyLine';

interface Props {
  doctype?: DoctypeFragment;
  disabled?: boolean;
  onAddProperty: (propertyId: string) => void;
  onAddNewProperty: (property: CustomAttributeDefinitionFragment) => void;
  onRemoveProperty: (propertyId: string) => void;
}

const SettingsDocTypeProperties: FC<React.PropsWithChildren<Props>> = ({
  doctype,
  disabled = false,
  onAddProperty,
  onAddNewProperty,
  onRemoveProperty,
}) => {
  const allAttributes = useAttributes();

  const [isNewPropertyDropdownVisible, {
    toggleCallback: toggleNewPropertyDropdown,
    setFalseCallback: hideNewPropertyDropdown,
  }] = useOptimizedBooleanState(false);
  const [propertyIdToRemove, setPropertyIdToRemove] = useState<string | null>(null);

  const onOptionChange = useCallback((selectedOption: SelectOption) => {
    hideNewPropertyDropdown();
    onAddProperty(selectedOption.value);
  }, [hideNewPropertyDropdown, onAddProperty]);

  const linkedProperties = useMemo(
    () => nodeToArray(doctype?.attributeDefinitions).filter(node => !!node.__typename),
    [doctype?.attributeDefinitions],
  );

  const linkedPropertyIds = linkedProperties.map(p => p.id);
  const unlinkedProperties = allAttributes.filter(p => !linkedPropertyIds.includes(p.id));

  const resetPropertyIdToRemove = useCallback(() => setPropertyIdToRemove(null), []);

  const attribute = useMemo(() => linkedProperties.find(property => property.id === propertyIdToRemove), [linkedProperties, propertyIdToRemove]);

  const [newPropertyName, setNewPropertyName] = useState<string | null>(null);

  const addOnUnlimitedProperties = useProductAddOn('UNLIMITED_PROPERTIES');

  return (
    <>
      <Container>
        {linkedProperties.length > 0 && (
          <div>
            {linkedProperties.map(property => (
              <SettingsDocTypePropertyLine
                key={property.id}
                property={property}
                unlink={() => setPropertyIdToRemove(property.id)}
              />
            ))}
          </div>
        )}

        <AddNewLine>
          <DropdownLayer
            layer={Layer.DropdownModal}
            visible={isNewPropertyDropdownVisible}
            hide={hideNewPropertyDropdown}
            placement="bottom-start"
            content={(
              <StyledSelectPanel
                title="Property"
                options={unlinkedProperties.map(a => ({
                  value: a.id,
                  label: a.name,
                  icon: getCustomAttributeTypeData(a.__typename).icon,
                }))}
                onOptionChange={onOptionChange}
                onCreateOption={async (text) => {
                  if (!addOnUnlimitedProperties.isEnabled && allAttributes.length >= ATTRIBUTES_LIMIT) {
                    setLimitationsModal({
                      action: 'USE_ADD_ON',
                      brand: 'UNLIMITED_PROPERTIES',
                    });
                    return;
                  }
                  hideNewPropertyDropdown();
                  setNewPropertyName(text);
                }}
                showCreateOptionIfEmpty
                defaultCreateOptionLabel="Create new property"
              />
          )}
          >
            <TextButton
              disabled={disabled}
              onClick={toggleNewPropertyDropdown}
            >
              <AddIcon />
              Link new
            </TextButton>
          </DropdownLayer>
        </AddNewLine>
        {propertyIdToRemove && doctype && attribute && (
          <AttributeUnlinkDoctypeModal
            doctype={doctype}
            attribute={attribute}
            hide={resetPropertyIdToRemove}
            onConfirm={() => onRemoveProperty(propertyIdToRemove)}
          />
        )}
      </Container>

      {newPropertyName !== null && (
        <SettingsAttributeEditModal
          initialName={newPropertyName}
          onHide={() => setNewPropertyName(null)}
          onCreated={onAddNewProperty}
        />
      )}
    </>
  );
};

export default SettingsDocTypeProperties;
