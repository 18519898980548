import {
  OAuthClientFragment,
  UpdateOAuthClientDocument,
  UpdateOAuthClientMutation,
  UpdateOAuthClientMutationVariables,
} from '@cycle-app/graphql-codegen';
import castArray from 'lodash/castArray';
import { isPresent } from 'ts-is-present';

import useSafeMutation from 'src/hooks/useSafeMutation';

export type UpdateOAuthClientVariables = Omit<UpdateOAuthClientMutationVariables, 'id'>;

export const useUpdateOAuthClient = () => {
  const [updateClient] = useSafeMutation(UpdateOAuthClientDocument);
  return (
    variables: UpdateOAuthClientVariables,
    client: OAuthClientFragment,
  ) => updateClient({
    variables: {
      id: client.id,
      ...variables,
    },
    optimisticResponse: getOptimisticResponse(variables, client),
  });
};

const getOptimisticResponse = (
  variables: UpdateOAuthClientVariables,
  client: OAuthClientFragment,
): UpdateOAuthClientMutation => {
  const updateOAuthClient = { ...client };
  if ('name' in variables) updateOAuthClient.name = variables.name;
  if ('description' in variables) updateOAuthClient.description = variables.description;
  if ('developerName' in variables) updateOAuthClient.developerName = variables.developerName;
  if ('developerUrl' in variables) updateOAuthClient.developerUrl = variables.developerUrl;
  if ('webhookRessources' in variables) updateOAuthClient.webhookRessources = castArray(variables.webhookRessources).filter(isPresent);
  if ('webhookUrl' in variables) updateOAuthClient.webhookUrl = variables.webhookUrl;
  if ('callbackUrls' in variables) updateOAuthClient.callbackUrls = castArray(variables.callbackUrls).filter(isPresent);
  if ('isPublic' in variables) updateOAuthClient.isPublic = variables.isPublic;
  return { updateOAuthClient };
};
