import { Selection } from '@tiptap/pm/state';

import { make } from 'src/utils/reactives.util';

import type { EditorAiAction } from 'src/types/editor.types';

interface EditorAiState {
  action: EditorAiAction | null;
  isLanguageDropdownOpen: boolean;
  isLoading: boolean;
  isPromptCommand: boolean;
  isToneDropdownOpen: boolean;
  isWarningVisible: boolean;
  /**
   * Store the overlay DOM element in other to be used for the tippy instance
   * reference to be appended
  */
  overlay: HTMLElement | null;
  /**
   * Will be filled with the last AI response, is empty, no AI call made yet
  */
  snapshotSelection: Selection | null;
  suggestion: string;
  /**
  * To show the ai bubble menu on the editor
  * The value is the editor id via the context which is adding a unique
  * id to each editor
  * This helps to control multi editor behavior and make sure only one AI
  * dropdown is visible at the time.
  */
  visible: string;
}

export const {
  hookValue: useEditorAiValue,
  hookState: useEditorAi,
  setValue: setEditorAi,
  getValue: getEditorAi,
} = make<EditorAiState>({
  defaultState: {
    action: null,
    isLanguageDropdownOpen: false,
    isLoading: false,
    isPromptCommand: false,
    isToneDropdownOpen: false,
    isWarningVisible: false,
    overlay: null,
    snapshotSelection: null,
    suggestion: '',
    visible: '',
  },
});

export const resetEditorAi = () => setEditorAi({
  action: null,
  isLanguageDropdownOpen: false,
  isLoading: false,
  isPromptCommand: false,
  isToneDropdownOpen: false,
  isWarningVisible: false,
  overlay: getEditorAi().overlay,
  snapshotSelection: null,
  suggestion: '',
  visible: '',
});
