import { FC } from 'react';

import { Svg, SvgProps } from '../../components/Svg/Svg.styles';

export const SyncStrikeIcon: FC<React.PropsWithChildren<SvgProps>> = props => (
  <Svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-no-fill {...props}>
    <g clipPath="url(#clip0_2279_14643)">
      <path d="M12.25 4.66667L10.9316 3.34833C9.87641 2.32821 8.46767 1.75552 6.99997 1.75C6.41664 1.75 5.85081 1.84333 5.32581 2.02417" stroke="currentcolor" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.66667 9.33334H1.75V12.25" stroke="currentcolor" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.75 6.99999C1.75 5.54749 2.33333 4.23499 3.29 3.28999" stroke="currentcolor" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.75 9.33334L3.06833 10.6517C4.12357 11.6718 5.53231 12.2445 7 12.25C8.4525 12.25 9.765 11.6667 10.71 10.71" stroke="currentcolor" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 7C12.25 7.58333 12.1567 8.14917 11.9758 8.67417" stroke="currentcolor" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.25 1.75V4.66667H9.33337" stroke="currentcolor" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8333 12.8333L1.16663 1.16666" stroke="currentcolor" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_2279_14643">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
