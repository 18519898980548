import { UpdateAutoLinkToRoadmapDocument } from '@cycle-app/graphql-codegen';

import { useWorkspaceContext } from 'src/contexts/workspaceContext';
import useSafeMutation from 'src/hooks/useSafeMutation';

export const useUpdateAutoLinkToRoadmap = () => {
  const productId = useWorkspaceContext(ctx => ctx.productId);
  const [updateAutoLinkToRoadmap] = useSafeMutation(UpdateAutoLinkToRoadmapDocument);

  return (autoLinkToRoadmap: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateAutoLinkToRoadmap({
      variables: {
        productId,
        autoLinkToRoadmap,
      },
      optimisticResponse: {
        updateProduct: {
          id: productId,
          autoLinkToRoadmap,
        },
      },
    });
  };
};
