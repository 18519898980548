/* eslint-disable no-nested-ternary */
import { IntegrationCardSkeleton, IntegrationCard, IntegrationCardProps } from '@cycle-app/ui';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';

import { SourcesEmptyState } from 'src/components/SourcesEmptyState';
import { mappingZindex } from 'src/constants/zIndex.constant';
import { Layer } from 'src/types/layers.types';

import { IntegrationItem, IntegrationItemProps } from './IntegrationItem/IntegrationItem';
import { IntegrationsDropdown } from './IntegrationsDropdown';
import {
  IntegrationsContainer,
  IntegrationsTitle,
  IntegrationsList,
} from './SettingsIntegrations.styles';

export type Offsets = {
  width: number;
  height: number;
  center: number;
  handles: number[];
};

type IntegrationsSectionProps = {
  title: string;
  preInstalled?: IntegrationCardProps['logos'];
  installed: IntegrationItemProps[];
  uninstalled: IntegrationItemProps[];
  loading: boolean;
  onRender: (offsets: Offsets) => void;
  defaultIntegration?: ReactNode;
  isEmpty?: boolean;
};

const SKELETON_ARRAY = [...Array(4).keys()];

export const IntegrationsSection = ({
  title, preInstalled, installed, uninstalled, loading, onRender, defaultIntegration, isEmpty,
}: IntegrationsSectionProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!containerRef.current || !listRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const listRect = listRef.current.getBoundingClientRect();

    onRender({
      width: containerRect.width,
      height: containerRect.height,
      center: listRect.top - containerRect.top + listRect.height / 2,
      handles: [...listRef.current.children].map((child) => {
        const rect = child.getBoundingClientRect();
        return rect.top - containerRect.top + rect.height / 2;
      }),
    });
  }, [loading, installed.length, onRender]);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  return (
    <IntegrationsContainer
      ref={containerRef}
      $showFooter={dropdownVisible}
    >
      <IntegrationsTitle>
        {title}

        {!isEmpty && !loading && uninstalled.length > 0 && (
          <IntegrationsDropdown
            integrations={uninstalled}
            visible={dropdownVisible}
            setVisible={setDropdownVisible}
            zIndex={mappingZindex[Layer.DropdownZ1]}
          />
        )}
      </IntegrationsTitle>

      <IntegrationsList ref={listRef}>
        {loading
          ? SKELETON_ARRAY.map((number) => <IntegrationCardSkeleton key={number} />)
          : isEmpty
            ? <SourcesEmptyState />
            : (
              <>
                {defaultIntegration}

                {installed.map((item) => (
                  <IntegrationItem
                    key={item.integrationType}
                    integration={item.integration}
                    integrationType={item.integrationType}
                  />
                ))}

                {preInstalled && (
                  <IntegrationCard
                    logos={preInstalled}
                    information="Installed by default"
                  />
                )}
              </>
            )}
      </IntegrationsList>
    </IntegrationsContainer>
  );
};
