import { Button, typo, card } from '@cycle-app/ui';
import { ColorTheme } from '@cycle-app/utilities';
import styled from 'styled-components';

export const Container = styled.div`
  ${card}
  max-width: 780px;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: 'center';
  background-color: ${p => (p.theme.isDark ? ColorTheme.Blue300 : ColorTheme.Blue100)};
`;

export const Bottom = styled.div`
  display: flex;
  gap: 24px;
  padding: 20px;
`;

export const Title = styled.h5`
  ${typo.body500}
  color: ${p => p.theme.colors.text.primary};
`;

export const Description = styled.p`
  ${typo.body400}
  color: ${p => p.theme.colors.text.secondary};
`;

export const EnableButton = styled(Button)`
  height: 32px;
  padding: 0 12px;
  margin-right: 12px;
  ${typo.body500}
`;

export const Image = styled.img`
  width: 154px;
  height: 108px;
  margin-top: 40px;
  margin-bottom: 40px;
`;
