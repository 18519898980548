import { TextSecondary, Button } from '@cycle-app/ui';
import { LinkIcon } from '@cycle-app/ui/icons';
import { format } from 'date-fns';
import { useTheme } from 'styled-components';

import { useNavigate } from 'src/hooks';
import { useMeetingToggleBot } from 'src/hooks/integration/useMeetingToggleBot';
import { closeSettingsModal } from 'src/reactives/settingsModal.reactive';
import { MaybeUndefined } from 'src/utils/utils';

import { Meeting, MeetingAction, MeetingHeading, StyledLink, StyledToggleInput } from './MeetingEvent.styles';

type Props = {
  botScheduled: boolean;
  endTime: string;
  id: string;
  name: MaybeUndefined<string>;
  startTime: string;
  url: MaybeUndefined<string>;
} & ({
  docId: MaybeUndefined<string>;
  docTitle: MaybeUndefined<string>;
  isPast: true;
} | {
  docId?: never;
  docTitle?: never;
  isPast?: never;
});

const formatTime = (date: Date) => {
  // We may have 9:0, remove the :0.
  return format(date, 'h:m').replace(':0', '');
};

export const MeetingEvent = ({
  endTime, startTime, name, botScheduled, url, isPast, id, docId, docTitle,
}: Props) => {
  const { isDark } = useTheme();
  const { toggleBot } = useMeetingToggleBot();
  const { navigateToDocFullPage } = useNavigate();
  const start = new Date(startTime);
  const end = new Date(endTime);
  return (
    <Meeting>
      <div>
        {name && <MeetingHeading>{name}</MeetingHeading>}
        <TextSecondary $mt="4px" $typo="caption">
          {format(start, 'PP')}
          {' · '}
          {`${formatTime(start)} ${format(start, 'a')}`}
          {' - '}
          {`${formatTime(end)} ${format(end, 'a')}`}
        </TextSecondary>
        {url && (
          <StyledLink target="_blank" rel="noreferrer" href={url}>
            <LinkIcon />
            {url}
          </StyledLink>
        )}
      </div>
      <MeetingAction>
        {isPast && docId && (
          <Button
            size="M"
            onClick={() => {
              closeSettingsModal();
              navigateToDocFullPage({
                id: docId,
                title: docTitle || '',
              });
            }}
            variant={isDark ? 'ternary' : 'secondary'}
          >
            Open Doc
          </Button>
        )}
        {!isPast && url && (
          <StyledToggleInput
            id={id}
            checked={botScheduled}
            onChange={() => toggleBot({
              eventId: id,
              scheduled: !botScheduled,
            })}
            label="Record"
          />
        )}
      </MeetingAction>
    </Meeting>
  );
};
